import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyReportGroupList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyReportGroupList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Report Group"
        appInsightsPageName="MyReportGroupListPage"
        leftNavUrl={leftNavUrls.management.report}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadReportGroups,
  deleteConfigItem: actionCreator.deleteReportGroup,
  publishConfigItem: actionCreator.publishReportGroupConfigItem,
  unpublishConfigItem: actionCreator.unpublishReportGroupConfigItem,
  revertConfigItem: actionCreator.revertReportGroupConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportGroupList);
