import React, { CSSProperties } from "react";
import { ContentDisplayType } from "../../utilities/miscHelper";

export enum LineMarkerType {
  none,
  arrowEnd,
  arrowStart,
  arrowBoth,
}

export enum LineType {
  regular,
  curve,
  dotted,
}

export enum Position {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right",
  topLeft = "upperLeft",
  topRight = "upperRight",
  bottomLeft = "lowerLeft",
  bottomRight = "lowerRight",
}

export interface IClassNames {
  [key: string]: string;
}

export interface IDiagramObject {
  id?: string;
  name?: string;
  shortName?: string;
  className?: string;
  classNames?: IClassNames;
  style?: React.CSSProperties;
  tooltip?: string | JSX.Element;
  [key: string]: any;
}

export interface ISizable {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface IDraggable {
  draggable?: boolean;
}

export interface IResizable {
  resizable?: boolean;
}

export interface ISelectable {
  selected?: boolean;
  highlighted?: boolean;
}

export interface IIcon extends ISizable {
  imageUrl?: string;
  iconName?: string;
  style?: React.CSSProperties;
}

export interface IMetric {
  id?: string;
  name?: string;
  shortName?: string;
  value?: any;
  displayType?: ContentDisplayType;
  showInTooltip?: boolean | string;
  showInGraph?: boolean;
  color?: string;
  template?: string;
  hidden?: boolean;
  useDistinct?: boolean;
  sortOrder?: number;
}

export interface IInfoPane {
  content?: JSX.Element;
  position?: Position;
}

export interface INode extends IDiagramObject, ISizable, IResizable, IDraggable, ISelectable {
  content?: JSX.Element;
  icon?: IIcon;
  infoPane?: IInfoPane;
  metrics?: IMetric[];
  onClick?: Function;
  tabIndex?: number;
}

export interface ILink extends IDiagramObject, ISelectable {
  fromNodeId?: string;
  toNodeId?: string;
  x1?: number;
  y1?: number;
  x2?: number;
  y2?: number;
  content?: JSX.Element;
  text?: string;
  textStyle?: CSSProperties;
  icon?: IIcon;
  lineMarkerType?: LineMarkerType;
  lineType?: LineType;
  animation?: boolean;
  fromValue?: string;
  fromValueTooltip?: JSX.Element;
  fromPosition?: Position;
  toValue?: string;
  toValueTooltip?: JSX.Element;
  toPosition?: Position;
  tabIndex?: number;
}

export interface IDiagram extends IDiagramObject, ISizable {
  nodes?: INode[];
  links?: ILink[];
  defaultNodeProps?: INode;
  defaultLinkProps?: ILink;
  autoSizing?: boolean;
}
