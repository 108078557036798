import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "Run ID",
      fieldName: "id",
      minWidth: 240,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "scheduleId",
      name: "Schedule ID",
      fieldName: "scheduleId",
      minWidth: 240,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "teamName",
      fieldName: "teamName",
      name: "Team Name",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
    },
    {
      key: "scheduleName",
      name: "Schedule Name",
      fieldName: "scheduleName",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "streamId",
      fieldName: "streamId",
      name: "Stream Id",
      minWidth: 80,
      maxWidth: 140,
    },
    {
      key: "state",
      fieldName: "state",
      name: "State",
      minWidth: 80,
      maxWidth: 80,
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
  ];
};
