import React from "react";
import { Nav, INavLink, INavLinkGroup } from "@fluentui/react/lib/Nav";
import classNames from "./MyServiceContentEdit.module.scss";

export type MenuClick = (name: string, path: string, key: string) => void;

let keyCounter = 0;
let getKey = () => {
  return `menuKey${keyCounter++}`;
};

function createFieldObject(obj: object, name: string, path: string, onClick: MenuClick): INavLink {
  const onMenuClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    onClick(name, path, item?.key);
  };

  let links: INavLink[] = [];
  let tooltip = !Array.isArray(obj)
    ? `Sample value of ${name} was ${obj.toString() === "[object Object]" ? "an object" : obj}`
    : "Sample value was an array";
  let link = {
    name,
    ariaLabel: `Select ${name}`,
    key: getKey(),
    title: tooltip,
    onClick: onMenuClick,
  } as INavLink;

  if (typeof obj === "object") {
    for (const property in obj) {
      const newObj = obj[property];
      links.push(createFieldObject(newObj, property, `${path}.${property}`, onClick));
    }
    link.links = links;
  }

  return link;
}

function convertObjectToFields(parentObject: object, onClick: MenuClick, path: string = "$"): INavLinkGroup[] {
  const groups: INavLinkGroup[] = [];

  if (!Array.isArray(parentObject) && typeof parentObject === "object") {
    let links: INavLink[] = [];
    for (const property in parentObject) {
      const newObj = parentObject[property];
      links.push(createFieldObject(newObj, property, `${path}.${property}`, onClick));
    }
    groups.push({
      links,
    });
  }
  return groups;
}

interface IAddAttributesProps {
  sampleData: object;
  onClick: MenuClick;
}

export const MyServiceContentsAddAttributes: React.FunctionComponent<IAddAttributesProps> = ({
  sampleData,
  onClick,
}) => {
  const nav = React.useMemo(() => {
    const links: INavLinkGroup[] = convertObjectToFields(sampleData, onClick);

    return <Nav groups={links} className={`LeftNav_nav ${classNames.nav}`} />;
  }, [sampleData, onClick]);

  return nav;
};
