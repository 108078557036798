import React from "react";
import { connect } from "react-redux";
import classNames from "./GraphHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { SelectionMode, CheckboxVisibility, DetailsList } from "@fluentui/react/lib/DetailsList";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { getEntityTestColumns } from "./EntityTests.helper";
import { IEntity, IEntityTest } from "../common/interfaces";

export interface IEntityTestsStateProps {
  selectedEntity: IEntity;
  entityTests: IEntityTest[];
  loading: boolean;
}

export interface IEntityTestsDispatchProps {
  loadEntityTests: (entityType: string, entityId: string, version: string) => void;
}

export class EntityTests extends React.Component<IEntityTestsStateProps & IEntityTestsDispatchProps> {
  render() {
    const { selectedEntity, entityTests, loading } = this.props;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading validation tests..."
        />
      );
    }

    if (!selectedEntity) {
      return <div className={classNames.message}>No related validation test is available.</div>;
    }

    if (!entityTests || !entityTests.length) {
      return <div className={classNames.message}>No related validation test is found for the selected entity.</div>;
    }

    return (
      <div className={classNames.entityRelationships}>
        <DetailsList
          items={entityTests}
          columns={getEntityTestColumns()}
          className={classNames.selectable}
          compact={true}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  selectedEntity: state.modules.selected_entity,
  entityTests: state.modules.entity_tests,
  loading: state.modules.loading_entity_tests,
});

const mapDispatchToProps = {
  loadEntityTests: actionCreator.loadEntityTests,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityTests);
