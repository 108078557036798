import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 240,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "teamName",
      fieldName: "teamName",
      name: "Team Name",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
    },
    {
      key: "name",
      name: "Schedule Name",
      fieldName: "name",
      minWidth: 160,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "streamId",
      fieldName: "streamId",
      name: "Stream Id",
      minWidth: 80,
      maxWidth: 140,
    },
    {
      key: "scheduledStartTime",
      fieldName: "scheduledStartTime",
      name: "Scheduled Start Time",
      minWidth: 100,
      maxWidth: 120,
    },
    {
      key: "scheduledEndTime",
      fieldName: "scheduledEndTime",
      name: "Scheduled End Time",
      minWidth: 100,
      maxWidth: 120,
    },
    {
      key: "sla",
      fieldName: "sla",
      name: "SLA",
      minWidth: 100,
      maxWidth: 120,
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
  ];
};
