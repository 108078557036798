import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultEventSubscription, getFields } from "./MyEventSubscriptionEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IEvent, IIcMConnector } from "../../common/interfaces";
import { IState } from "../../../reducers/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

export interface IMyEventSubscriptionEditStateProps extends IConfigItemEditProps {
  events: IEvent[];
  icmConnectors: IIcMConnector[];
}

export interface IMyEventSubscriptionEditDispatchProps extends IConfigItemEditDispatchProps {
  loadEventConfigItems: (refreshData?: boolean, doNotUpdateConfigItems?: boolean) => void;
  loadIcmConnectors: (refresh?: boolean, doNotUpdateConfigValue?: boolean) => void;
}

export class MyEventSubscriptionEdit extends React.Component<
  IMyEventSubscriptionEditDispatchProps & IMyEventSubscriptionEditStateProps
> {
  private generalEditForm: React.RefObject<any> = React.createRef();

  componentDidMount() {
    const { events, icmConnectors, loadEventConfigItems, loadIcmConnectors } = this.props;

    if (!events || !events.length) {
      loadEventConfigItems(true, true);
    }

    if (!icmConnectors || !icmConnectors.length) {
      loadIcmConnectors(true, true);
    }
  }

  render() {
    const { configValue, icmConnectors, editConfigItem } = this.props;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Event Subscription"
        configItemType={ConfigItemType.Subscriptions}
        appInsightsPageName="MyEventSubscriptionEditPage"
        leftNavUrl={leftNavUrls.management.eventSubscription}
        defaultConfigItem={defaultEventSubscription}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem, icmConnectors)}
              editConfigItem={editConfigItem}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    return this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
  };
}

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  events: state.modules.radar_events,
  icmConnectors: state.modules.icm_connectors,
});

const mapDispatchToProps: IMyEventSubscriptionEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadSubscriptionConfigItem,
  updateConfigItem: actionCreator.updateSubscriptionConfigItem,
  loadEventConfigItems: actionCreator.loadEventConfigItems,
  loadIcmConnectors: actionCreator.loadIcmConnectors,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEventSubscriptionEdit);
