export enum TileType {
  SimpleGrouping = "SimpleGrouping",
  StandardGrouping = "StandardGrouping",
  Visual = "Visual",
}

export const getColumnOptionsButton = (onClick: () => void) => {
  return {
    key: "columnOptions",
    title: "Column Options",
    ariaLabel: "Column Options",
    iconProps: {
      iconName: "Repair",
    },
    iconOnly: false,
    onClick,
  };
};

export const getFilterButton = (onClick: () => void, activeFilters?: boolean) => {
  return {
    key: "filterButton",
    title: "Show Filters",
    ariaLabel: "Show Filters",
    iconProps: {
      iconName: activeFilters ? "FilterSolid" : "Filter",
    },
    iconOnly: false,
    onClick,
  };
};
