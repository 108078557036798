import React from "react";
import { connect } from "react-redux";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import classNames from "./EntityViewFilters.module.scss";
import { IEntityView } from "../common/interfaces";

export interface IEntityViewFiltersStateProps {
  entityViews: IEntityView[];
  selectedEntityViewId: string;
}

export interface IEntityViewFiltersDispatchProps {
  changeEntityView: (entityViewId: string) => void;
}

export class EntityViewFilters extends React.Component<IEntityViewFiltersStateProps & IEntityViewFiltersDispatchProps> {
  render() {
    const { entityViews, selectedEntityViewId } = this.props;

    if (!entityViews || !entityViews.length) {
      return null;
    }

    let filterOptions = entityViews.map((view) => ({ text: view.name, key: view.id }));

    return (
      <div className={classNames.root}>
        <Dropdown
          className={classNames.filters}
          placeholder="Select a view"
          ariaLabel="Select a view"
          selectedKey={selectedEntityViewId}
          options={filterOptions}
          responsiveMode={ResponsiveMode.large}
          onChange={this.onFilterChange}
        />
      </div>
    );
  }

  onFilterChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    const { changeEntityView } = this.props;

    let entityViewId = item.key || "";

    changeEntityView(entityViewId.toString());
  };
}

const mapStateToProps = (state: IState): IEntityViewFiltersStateProps => ({
  entityViews: state.modules.entity_views,
  selectedEntityViewId: state.modules.selected_entity_view_id,
});

const mapDispatchToProps = {
  changeEntityView: actionCreator.changeEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityViewFilters);
