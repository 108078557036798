import React, { CSSProperties } from "react";
import { Tile } from "./Tile";
import { ContentDisplayType } from "../../utilities/miscHelper";
import { getFormatContent } from "../../utilities/miscHelper";
import classNames from "./KpiTile.module.scss";

const maxValueFontSize = 40;
const valueFontSizeFactor = 1.5;

export interface IKpiTileProps {
  className?: string;
  style?: CSSProperties;
  title?: string;
  titleStyle?: CSSProperties;
  category?: string;
  value?: string | number;
  valueColor?: string;
  valueStyle?: CSSProperties;
  valueDisplayType?: ContentDisplayType;
  suffixContent?: string;
}

export interface IKpiTileState {
  width: number;
}

export class KpiTile extends React.Component<IKpiTileProps, IKpiTileState> {
  tile: Tile = null;

  state: IKpiTileState = {
    width: 0,
  };

  componentDidMount() {
    if (this.tile) {
      let width = this.tile.getWidth();

      if (width && width !== this.state.width) {
        this.setState({ width });
      }
    }
  }

  render() {
    const {
      title,
      titleStyle,
      category,
      value,
      valueColor,
      valueStyle,
      className,
      style,
      valueDisplayType,
      suffixContent = category,
    } = this.props;
    const { width } = this.state;
    const valueLength = value?.toString()?.length || 1;

    const finalValueStyle = {
      color: valueColor,
      ...valueStyle,
      fontSize: Math.min(maxValueFontSize, Math.round(width / valueLength) * valueFontSizeFactor),
    };

    return (
      <Tile
        className={`${classNames.root} ${className}`}
        classNames={classNames}
        style={style}
        ref={(tile) => {
          this.tile = tile;
        }}
      >
        {title && (
          <div className={classNames.title} style={titleStyle}>
            {title}
          </div>
        )}
        <div className={classNames.value} style={finalValueStyle}>
          {getFormatContent(value, valueDisplayType)}
        </div>
        {suffixContent && (
          <div className={classNames.suffixContent} dangerouslySetInnerHTML={{ __html: suffixContent }} />
        )}
      </Tile>
    );
  }
}

export default KpiTile;
