import { IField, FieldType } from "../../../shared/components/DataForm";
import { getTilesFields, getTileDataSourceFields } from "../common/helper";
import { IReportGroup, ReportType, IReport } from "../../common/interfaces";
import { ContentType } from "../../common/PowerbiReport";

export const defaultReportGroup: IReportGroup = {
  id: "new",
  name: "",
  soxCompliance: true,
};

export const getReportTypeOptions = () => {
  let reportTypes = [{ key: "", text: "" }];

  for (var reportType in ReportType) {
    reportTypes.push({ key: reportType, text: reportType });
  }

  return reportTypes;
};

export const getContentTypeOptions = () => {
  let contentTypes = [];

  for (var contentType in ContentType) {
    contentTypes.push({ key: contentType, text: contentType });
  }

  return contentTypes;
};

export const getFields = (reportGroup: IReportGroup, selectedReport: IReport): IField[] => {
  if (!reportGroup) return null;

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "Group ID",
      value: reportGroup.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "hideGroupHeader",
      label: "Hide Report Group Header",
      fieldType: FieldType.boolean,
    },
    {
      fieldName: "hideReportHeader",
      label: "Hide Report Header",
      fieldType: FieldType.boolean,
      hints: "Hides the tab strip. Should only be used if there is a single report.",
    },
    {
      fieldName: "hideLeftPaneOnLoad",
      label: "Hide Left Pane",
      fieldType: FieldType.boolean,
      hints: "Hides the left navigation pane on load to give more viewing space for the report.",
    },
    {
      fieldName: "useReportGroupNameAsHeader",
      label: "Use Report Group Name As Header",
      fieldType: FieldType.boolean,
      hints:
        "By default, associated left nav name is used as report group header.  Select this option to always use the report group name as report group header.",
      lastInRow: true,
    },
    {
      fieldName: "name",
      label: "Report Group Name",
      value: reportGroup.name,
      required: true,
      lastInRow: true,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: reportGroup.owner,
      required: true,
      hints: "Alias of report owner",
    },
    {
      fieldName: "owningTeam",
      label: "Owning Team",
      value: reportGroup.owningTeam,
      required: true,
      hints: "Alias of report group owning team",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: reportGroup.editors,
      required: true,
      width: "400px",
      allowFullEditor: true,
      hints: "Comma separated list of aliases who can edit this report",
    },
    {
      fieldName: "viewers",
      label: "Viewers",
      fieldType: FieldType.textArray,
      value: reportGroup.viewers,
      allowFullEditor: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can view this report",
    },
    {
      fieldName: "description",
      label: "Description",
      fieldType: FieldType.html,
      value: reportGroup.description,
      width: "100%",
    },
    {
      fieldName: "accessErrorMessage",
      label: "Access Error Message",
      fieldType: FieldType.html,
      width: "100%",
    },
    {
      fieldName: "reports",
      label: "Reports",
      fieldType: FieldType.items,
      value: reportGroup.reports,
      itemTypeName: "Report",
      collapsed: false,
      fields: [
        {
          fieldName: "id",
          label: "ID",
          fieldType: FieldType.displayOnly,
        },
        {
          fieldName: "type",
          label: "Report Type",
          fieldType: FieldType.enumeration,
          options: getReportTypeOptions(),
          width: "120px",
          hints:
            "Choose between embedded PowerBI dashboard/report and Commerce Radar report.  By default, Commerce Radar report.",
        },
        {
          fieldName: "name",
          label: "Name",
          width: "300px",
          hints: "Used as tab name if short name is not defined.",
        },
        {
          fieldName: "shortName",
          label: "Short Name",
          width: "200px",
          hints: "Used as tab name.",
        },
        {
          fieldName: "incidentViewId",
          label: "Incident View ID",
          width: "120px",
          valueWidth: "60px",
          visible: "this.type === 'incidentView'",
          hints: "Specify the target incident view ID as report.",
        },
        {
          fieldName: "hideForViewers",
          label: "Hide For Viewers",
          fieldType: FieldType.boolean,
          hints: "Hide this report for viewers.",
        },
        {
          fieldName: "isRestricted",
          label: "Is Restricted",
          fieldType: FieldType.boolean,
          hints: "Indicates if this object requires a viewer to have a full MS Sales WW access",
        },
        {
          fieldName: "contentType",
          label: "Content Type",
          fieldType: FieldType.enumeration,
          options: getContentTypeOptions(),
          visible: "this.type === 'powerbi'",
        },
        {
          fieldName: "groupId",
          label: "Group ID",
          width: "300px",
          visible: "this.type === 'powerbi'",
          hints:
            "PowerBI group ID can be identified from the URL of your PowerBI dashboard/report, usually the GUID after the 'groups' in the URL.",
        },
        {
          fieldName: "contentId",
          label: "Report/Dashboard ID",
          width: "300px",
          visible: "this.type === 'powerbi'",
          hints:
            "Depending on the selected content type, it should be the PowerBI dashboard ID or report ID, usually the 2nd GUID in the PowerBI URL.",
        },
        {
          label: "Advanced",
          fieldType: FieldType.container,
          collapsible: true,
          compact: true,
          visible: "this.type !== 'powerbi'",
          fields: [
            {
              fieldName: "description",
              label: "Description",
              fieldType: FieldType.html,
              width: "100%",
            },
            {
              fieldName: "useSubmitButton",
              label: "Use Submit Button",
              fieldType: FieldType.boolean,
              hints:
                "If checked, this report will not be reloaded when the value of any input field is changed.  The report should then include a 'submit' button (i.e. Visual Type of 'submit') and the report will only be reloaded if the submit button is clicked.",
            },
            ...getTileDataSourceFields(),
          ],
        },
        {
          ...getTilesFields(selectedReport && selectedReport.tiles),
          compact: true,
          collapsed: false,
          visible: "this.type !== 'powerbi'",
        },
      ],
    },
  ];
};
