import React from "react";

export interface IProgressBarProps {
  progressPercent: number;
  className?: string;
  height?: number;
  width?: number;
  progressColor?: string;
  progressBackgroundColor?: string;
}

export const ProgressBar = (props: IProgressBarProps) => {
  const {
    progressPercent,
    className,
    height = 8,
    width = 40,
    progressColor = "#2b88d8",
    progressBackgroundColor = "#e0e0e0",
  } = props;
  return (
    <div
      className={className}
      style={{
        width,
        height,
        backgroundColor: progressBackgroundColor,
        display: "inline-block",
        border: "1px solid " + progressColor,
      }}
    >
      <div style={{ width: `${progressPercent}%`, height: "100%", backgroundColor: progressColor }} />
    </div>
  );
};

export default ProgressBar;
