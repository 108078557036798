import React from "react";
import BasicModal from "./BasicModal";
import { TextField } from "@fluentui/react/lib/TextField";
import { IComment } from "./interfaces";
import classNames from "./CommentModal.module.scss";

export interface ICommentModalProps {
  comment: IComment;
  saving?: boolean;
  error?: string;
  onCommit: (comment: IComment) => void;
  onCancel: () => void;
}

export interface ICommentModalState {
  text: string;
}

export class CommentModal extends React.Component<ICommentModalProps> {
  state = {
    text: this.props.comment.text || "",
  };

  render() {
    const { comment, saving, error } = this.props;
    const { commentId } = comment;
    const { text } = this.state;

    const title = commentId ? "Update Comment" : "Create New Comment";

    return (
      <BasicModal
        modalRootClassName={classNames.modalRoot}
        title={title}
        onCommit={this.onCommit}
        onCancel={this.onClose}
        saving={saving}
        error={error}
      >
        <div className={`${classNames.entryField} ${classNames.fullWidth}`}>
          <div className={classNames.fieldLabel}>Comment Text (plaintext for now)</div>
          <div className={classNames.fieldValue}>
            <TextField
              multiline
              resizable={false}
              rows={6}
              cols={80}
              onChange={this.onCommentTextChange}
              value={text}
            />
          </div>
        </div>
      </BasicModal>
    );
  }

  onCommentTextChange = (ev, newValue: string) => {
    this.setState({ text: newValue });
  };

  onCommit = () => {
    this.props.onCommit({
      ...this.props.comment,
      ...this.state,
    });
  };

  onClose = () => {
    this.props.onCancel();
  };
}

export default CommentModal;
