import React from "react";
import { Callout, ICalloutProps, DirectionalHint } from "@fluentui/react/lib/Callout";
import { Toggle } from "@fluentui/react/lib/Toggle";
import classNames from "./Callout.module.scss";
import { stagingQueryStringKey } from "../api/apiBase";

export interface ISettingCalloutProps extends ICalloutProps {
  isStaging: boolean;
  onDismiss?: () => void;
}

export class SettingCallout extends React.Component<ISettingCalloutProps> {
  constructor(props: ISettingCalloutProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  render() {
    const { isStaging, onDismiss } = this.props;

    return (
      <Callout
        className={classNames.root}
        {...this.props}
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomRightEdge}
        onDismiss={onDismiss}
        setInitialFocus={true}
        gapSpace={10}
        tabIndex={0}
      >
        <h4>App Settings</h4>
        <Toggle label="Staging" defaultChecked={isStaging} onText="On" offText="Off" onChange={this.onStagingChange} />
      </Callout>
    );
  }

  onStagingChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
    if (checked) {
      let delimitor = window.location.hash.indexOf("?") >= 0 ? "&" : "?";
      window.location.hash = window.location.hash + delimitor + stagingQueryStringKey;
    } else {
      let index = window.location.hash.indexOf(stagingQueryStringKey);

      if (index >= 1) {
        let delimitor = window.location.hash[index - 1];
        window.location.hash = window.location.hash.replace(delimitor + stagingQueryStringKey, "");
      }
    }

    localStorage[stagingQueryStringKey] = checked;

    // Adding a delay so that the change can be announced
    setTimeout(() => window.location.reload(), 500);
  };
}

export default SettingCallout;
