import React from "react";
import {
  DatePicker as UIFabricDatePicker,
  IDatePickerProps as UIFabricDatePickerProps,
} from "@fluentui/react/lib/DatePicker";
import { IVisualProps } from "./Visual";
import { getDateFromDayDiff } from "../../utilities/miscHelper";
import moment from "moment";

export interface IDatePickerVisualProps extends UIFabricDatePickerProps {}

export interface IDatePickerProps extends IVisualProps {
  defaultDateDiff?: number;
  maxDateString?: string;
  minDateString?: string;
  maxDateDiff?: number;
  minDateDiff?: number;
  valueFormat?: string;
  onValueSelect?: (value: string) => void;
}

export class DatePicker extends React.Component<IDatePickerProps> {
  componentDidMount() {
    let { defaultDateDiff, selectedValue, valueFormat, onValueSelect } = this.props;

    if (defaultDateDiff !== undefined && !selectedValue) {
      var date = getDateFromDayDiff(defaultDateDiff);
      selectedValue = this.getFormattedDate(date, valueFormat);
      onValueSelect && onValueSelect(selectedValue);
    }
  }

  render() {
    const {
      label,
      visualProps = {},
      width,
      maxDateString,
      maxDateDiff,
      minDateString,
      minDateDiff,
      placeholder,
      selectedValue,
    } = this.props;

    let maxDate = undefined,
      minDate = undefined;

    if (maxDateString) {
      maxDate = new Date(maxDateString);
    } else if (maxDateDiff !== undefined) {
      maxDate = getDateFromDayDiff(maxDateDiff);
    }

    if (minDateString) {
      minDate = new Date(minDateString);
    } else if (minDateDiff !== undefined) {
      minDate = getDateFromDayDiff(minDateDiff);
    }

    return (
      <UIFabricDatePicker
        {...(visualProps as IDatePickerVisualProps)}
        label={label}
        style={{ width }}
        value={getDateValue(selectedValue)}
        maxDate={maxDate}
        minDate={minDate}
        placeholder={placeholder}
        onSelectDate={this.onSelectDate}
      />
    );
  }

  onSelectDate = (date: Date | null | undefined) => {
    const { onValueSelect, valueFormat } = this.props;
    let dateString = "";

    if (date) {
      dateString = this.getFormattedDate(date, valueFormat);
    }

    onValueSelect && onValueSelect(dateString);
  };

  getFormattedDate = (date, valueFormat) => {
    valueFormat = valueFormat || "YYYY-MM-DD";
    return moment(date).format(valueFormat);
  };
}

export default DatePicker;

const getDateValue = (selectedValue: string): Date => {
  if (!selectedValue) {
    return undefined;
  }

  let date = new Date(selectedValue);

  date.setUTCHours(8, 0, 0, 0);

  return date;
};
