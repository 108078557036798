import React from "react";
import VisualTileList from "../../shared/components/Tile/VisualTileList";
import { ITile } from "../common/interfaces";
import classNames from "./EntityDetail.module.scss";
import { getDefaultTileMargin } from "./helper";

const defaultTileHeight = 160;
const chartHeightAdjust = 35;
export interface IEntityDetailTilesProps {
  tiles: ITile[];
}

export const EntityDetailTiles = ({ tiles }: IEntityDetailTilesProps) => {
  tiles.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

  let tilesCopy = tiles
    .filter((tile) => tile.data?.length)
    .map((tile) => ({
      ...tile,
      compact: true,
      height: tile.compactHeight || defaultTileHeight,
      visualProps: {
        height: (tile.compactHeight || defaultTileHeight) - chartHeightAdjust,
        width: 260,
        margin: getDefaultTileMargin(tile),
        ...tile.visualProps,
      },
      style: { marginRight: "0px" },
    }));
  return (
    <div className={classNames.entityTiles}>
      <div className={classNames.fieldLabel}>Analytics</div>
      <VisualTileList tiles={tilesCopy} />
    </div>
  );
};
