import React from "react";
import AppModuleHeader from "../common/AppModuleHeader";
import { Icon } from "@fluentui/react/lib/Icon";
import { appInsights } from "../../app/telemetryHelper";
import classNames from "./UnderConstructionPage.module.scss";

export class UnderConstructionPage extends React.Component {
  componentDidMount() {
    appInsights.trackPageView({ name: "UnderConstructionPage" });
  }

  render() {
    return (
      <div className={classNames.root + " " + classNames.appModuleContent}>
        <AppModuleHeader />
        <h1>Under Construction</h1>
        <div className={classNames.content}>
          <Icon className={classNames.icon} iconName="ConstructionConeSolid" />
        </div>
      </div>
    );
  }
}

export default UnderConstructionPage;
