import React, { CSSProperties } from "react";
import EntityNode from "./EntityNode";
import {
  EntityRelationshipType,
  entityBackgroundColor,
  entityBorderColor,
  relatedEntityBackgroundColor,
  dynamicEntityViewId,
  SearchItemKey,
} from "./helper";
import { IDiagram, IMetric, Position } from "../../shared/components/Diagram";
import { ContentDisplayType } from "../../shared/utilities/miscHelper";
import { IEntity, IEntityMetric, IEntityTest } from "../common/interfaces";
import { IEntityView } from "../common/interfaces";
import { ILink, INode } from "../../shared/components/Diagram";
import { IAppModuleState } from "../interfaces";

const nodeWidth = 240;
const nodeHeight = 50;
const nodeWidthGapFactor = 1.2;
const nodeHeightGapFactor = 1.5;
const nodeMultiGap = 5;

const selectedNodeStyle: CSSProperties = {
  backgroundColor: entityBackgroundColor,
  borderWidth: "3px",
  borderStyle: "solid",
  borderColor: entityBorderColor,
  animation: "nodeBorderBlink 2s infinite",
};

var minX;
var minY;

const defaultDiagram: IDiagram = {
  defaultNodeProps: {
    draggable: false,
    height: nodeHeight,
    width: nodeWidth,
    style: {
      fontSize: "12px",
      backgroundColor: "#777",
      color: "white",
      borderWidth: "0px",
      borderRadius: "0px",
      boxShadow: "0 2px 3px #ccc",
      textOverflow: "hidden",
    },
    infoPane: { position: Position.top },
  },
  defaultLinkProps: {
    style: {
      strokeWidth: "1px",
    },
    lineMarkerType: 1,
    animation: true,
  },
  autoSizing: true,
};

const financialEntitiesDiagram: IDiagram = {
  ...defaultDiagram,
  defaultNodeProps: {
    ...defaultDiagram.defaultNodeProps,
    width: 140,
    height: 40,
    style: {
      ...defaultDiagram.defaultNodeProps.style,
      backgroundColor: entityBackgroundColor,
    },
  },
};

export const getEntityDiagram = (selectedEntity: IEntity, entityTests: IEntityTest[]): IDiagram => {
  let diagram = { ...defaultDiagram, nodes: [], links: [] },
    entityX = 0,
    entityY = 0,
    entityList = [];

  minX = 0;
  minY = 0;

  if (selectedEntity && selectedEntity.entity && entityTests && entityTests.length) {
    selectedEntity.entity["validationResult"] = getValidationResult(entityTests);
  }

  createEntityNodeAndRelationships(diagram, selectedEntity, entityX, entityY, entityList);

  adjustNodePositions(diagram);

  return diagram;
};

export const getFinancialEntitiesDiagram = (
  entityMetrics: IEntityMetric[],
  entityView: IEntityView,
  selectedEntityType: string
): IDiagram => {
  if (!entityView) {
    return null;
  }

  let { nodes, links } = entityView;
  let diagram: IDiagram = {
    ...entityView,
    ...financialEntitiesDiagram,
  };

  if (entityView.id === dynamicEntityViewId) {
    // For dynamic view, search entity type must be selected to display the diagram.
    if (!selectedEntityType) {
      nodes = null;
      links = null;
    } else {
      // Show only the source and target nodes of the search entity type.
      nodes = generateDynamicViewNodes(selectedEntityType, nodes, links);
    }
  } else {
    nodes =
      nodes &&
      nodes.map((node) => {
        if (node.id === selectedEntityType) {
          return {
            ...node,
            selected: true,
            style: {
              ...selectedNodeStyle,
              ...node.style,
            },
          };
        } else {
          return {
            ...node,
            selected: false,
          };
        }
      });
  }

  diagram.nodes = nodes;
  diagram.links = links;

  // Associate the related metrics to the entity nodes.
  diagram.nodes &&
    diagram.nodes.forEach((node) => {
      let targetEntity = entityMetrics && entityMetrics.find((e) => e.entityType === node.id);

      if (targetEntity && targetEntity.metrics && targetEntity.metrics.length) {
        node.metrics = targetEntity.metrics.filter((m) => !m.hidden);
      } else {
        node.metrics = null;
      }
    });

  return diagram;
};

export const generateDynamicViewNodes = (selectedEntityType: string, nodes: INode[], links: ILink[]): INode[] => {
  let result = [],
    sourceTypes = [],
    targetTypes = [];

  links &&
    links.forEach((link) => {
      if (link.toNodeId === selectedEntityType) {
        sourceTypes.push(link.fromNodeId);
      }
      if (link.fromNodeId === selectedEntityType) {
        targetTypes.push(link.toNodeId);
      }
    });

  let minX = 0,
    minY = 0,
    sourceIndexAvg = Math.round(sourceTypes.length / 2),
    sourceYPadding = sourceTypes.length % 2 ? 0 : nodeHeight, // Add padding Y position for even number of source types.
    targetIndexAvg = Math.round(targetTypes.length / 2),
    targetYPadding = targetTypes.length % 2 ? 0 : nodeHeight,
    sourceNodeCount = 0,
    targetNodeCount = 0;

  nodes &&
    nodes.forEach((origNode) => {
      var node: INode = {
        ...origNode,
        selected: false,
        style: {
          ...origNode.style,
          borderWidth: "0px",
        },
        width: nodeWidth,
      };

      if (node.id === selectedEntityType) {
        node.x = 0;
        node.y = 0;
        node.selected = true;
        node.style = selectedNodeStyle;
        result.push(node);
      }

      var sourceIndex = sourceTypes.indexOf(node.id),
        sourceYFactor = sourceIndex <= sourceIndexAvg ? -1 : 1;

      if (sourceIndex >= 0) {
        node.x = -1 * nodeWidthGapFactor * nodeWidth;
        node.y = sourceYFactor * sourceNodeCount * nodeHeightGapFactor * nodeHeight + sourceYPadding;
        node.infoPane = {
          position:
            sourceNodeCount + 1 === sourceTypes.length
              ? Position.top
              : sourceNodeCount === 0
              ? Position.bottom
              : Position.left,
        };
        result.push(node);
        sourceNodeCount++;
      }

      var targetIndex = targetTypes.indexOf(node.id),
        targetYFactor = targetIndex <= targetIndexAvg ? -1 : 1;

      if (targetIndex >= 0) {
        node.x = nodeWidthGapFactor * nodeWidth;
        node.y = targetYFactor * targetNodeCount * nodeHeightGapFactor * nodeHeight + targetYPadding;
        node.infoPane = {
          position:
            targetNodeCount + 1 === targetTypes.length
              ? Position.top
              : targetNodeCount === 0
              ? Position.bottom
              : Position.right,
        };
        result.push(node);
        targetNodeCount++;
      }

      if (node.x) {
        minX = Math.min(minX, node.x);
      }
      if (node.y) {
        minY = Math.min(minY, node.y);
      }
    });

  if (minX < 0 || minY < 0) {
    result.forEach((node) => {
      node.x -= minX;
      node.y -= minY;
    });
  }

  return result;
};

// =========================================================================================================================
// Function to create entity node and its immediate relationship.
const createEntityNodeAndRelationships = (
  diagram: IDiagram,
  selectedEntity: IEntity,
  entityX: number,
  entityY: number,
  entityList: IEntity[]
) => {
  if (!selectedEntity || !selectedEntity.entity || !selectedEntity.entity.id) {
    return;
  }

  let entity = selectedEntity.entity,
    sources = selectedEntity.sources,
    targets = selectedEntity.targets,
    existingEntity = entityList.find((e) => e.entityType === entity.entityType && e.id === entity.id),
    relatedEntities = [];

  if (!existingEntity) {
    createEntityNode(diagram, entity, entityX, entityY, entityList);

    if (!selectedEntity.id && entity.id) {
      selectedEntity.id = entity.id;
      selectedEntity.entityType = entity.entityType;
    }
  } else if (!existingEntity.targets && selectedEntity.targets) {
    // This is needed for node metric creation.  Some entity objects are repeated. Some with targets and some don't.
    // This makes sure the entityList item has the targets defined.
    existingEntity.targets = selectedEntity.targets;
  }

  // Create the source nodes.
  if (sources && sources.length) {
    let entitiesByTypes = getEntitiesByTypes(sources, entityList),
      sourceEntityTypes = Object.keys(entitiesByTypes);

    sourceEntityTypes.forEach((entityType) => {
      let entities = entitiesByTypes[entityType],
        entityCountOfSameSide = entityList?.filter((el) => sourceEntityTypes.indexOf(el.entityType) >= 0)?.length || 0,
        relationshipType = entities[0].relationshipType,
        x = relationshipType === EntityRelationshipType.Parent ? entityX : entityX - nodeWidth * nodeWidthGapFactor,
        y = nodeHeight * nodeHeightGapFactor * entityCountOfSameSide;

      if (entities.length === 1) {
        createRelationshipNodeAndLink(diagram, entity, entities[0], x, y, entityList, false, true);
        relatedEntities.push({ entity: entities[0], x, y });
      } else if (entities.length === 2) {
        createRelationshipNodeAndLink(diagram, entity, entities[0], x, y, entityList, false, true);
        createRelationshipNodeAndLink(
          diagram,
          entity,
          entities[1],
          x,
          y + nodeHeight * nodeHeightGapFactor,
          entityList,
          false,
          true
        );
      } else {
        createMultiEntitiesNode(diagram, entity, entities, x, y, entityList, true);
      }
    });
  }

  // Create the target nodes.
  if (targets && targets.length) {
    let entitiesByTypes = getEntitiesByTypes(targets, entityList),
      targetEntityTypes = Object.keys(entitiesByTypes);

    targetEntityTypes.forEach((entityType) => {
      let entities = entitiesByTypes[entityType],
        entityCountOfSameSide = entityList?.filter((el) => targetEntityTypes.indexOf(el.entityType) >= 0)?.length || 0,
        relationshipType = entities[0].relationshipType,
        x = relationshipType === EntityRelationshipType.Parent ? entityX : entityX + nodeWidth * nodeWidthGapFactor,
        y = nodeHeight * nodeHeightGapFactor * entityCountOfSameSide;

      if (entities.length === 1) {
        createRelationshipNodeAndLink(diagram, entity, entities[0], x, y, entityList, false);
        relatedEntities.push({ entity: entities[0], x, y });
      } else if (entities.length === 2) {
        createRelationshipNodeAndLink(diagram, entity, entities[0], x, y, entityList, false);
        createRelationshipNodeAndLink(
          diagram,
          entity,
          entities[1],
          x,
          y + nodeHeight * nodeHeightGapFactor,
          entityList,
          false
        );
      } else {
        createMultiEntitiesNode(diagram, entity, entities, x, y, entityList);
      }
    });
  }

  // Go through the next level of relationships.
  relatedEntities.length &&
    relatedEntities.forEach((entityInfo) => {
      createEntityNodeAndRelationships(diagram, entityInfo.entity, entityInfo.x, entityInfo.y, entityList);
    });
};

// =========================================================================================================================
const createEntityNode = (diagram: IDiagram, entity: IEntity, x: number, y: number, entityList: IEntity[]) => {
  let style: CSSProperties = { backgroundColor: relatedEntityBackgroundColor, borderWidth: "0px" },
    iconProps,
    metrics;

  if (entityList.length === 0) {
    style = {
      ...style,
      ...selectedNodeStyle,
    };
  }

  // Create the entity node.
  diagram.nodes.push({
    id: entity.id,
    entityType: entity.entityType,
    version: entity.version,
    content: <EntityNode type={entity.entityType} id={entity.id} version={entity.version} iconProps={iconProps} />,
    x,
    y,
    style,
    metrics,
  });

  minX = Math.min(minX, x);
  minY = Math.min(minY, y);

  entityList.push(entity);
};

// =========================================================================================================================
// Function to create relationship node and related link.
const createRelationshipNodeAndLink = (
  diagram: IDiagram,
  originalEntity: IEntity,
  relatedEntity: IEntity,
  x: number,
  y: number,
  entityList: IEntity[],
  doNotCreateNodeOfSameType: boolean = true,
  isRelatedEntitySource: boolean = false
) => {
  var existingEntity = doNotCreateNodeOfSameType
    ? entityList.find((e) => e.entityType === relatedEntity.entityType)
    : entityList.find((e) => e.entityType === relatedEntity.entityType && e.id === relatedEntity.id);

  if (existingEntity) {
    let existingNode = diagram.nodes?.find(
      (node) =>
        node.id === existingEntity.id &&
        node.entityType === existingEntity.entityType &&
        node.version === existingEntity.version
    );
    if (existingNode) {
      existingNode.x = x;
      existingNode.y = y;
    }
  } else {
    createEntityNode(diagram, relatedEntity, x, y, entityList);
  }

  var existingLink =
    diagram.links &&
    diagram.links.find(
      (link) =>
        (link.fromNodeId === originalEntity.id && link.toNodeId === relatedEntity.id) ||
        (link.fromNodeId === relatedEntity.id && link.toNodeId === originalEntity.id)
    );

  !existingLink &&
    diagram.links.push({
      fromNodeId: isRelatedEntitySource ? relatedEntity.id : originalEntity.id,
      toNodeId: isRelatedEntitySource ? originalEntity.id : relatedEntity.id,
      fromPosition: Position.right,
      toPosition: Position.left,
    });
};

// =========================================================================================================================
const createMultiEntitiesNode = (
  diagram: IDiagram,
  originalEntity: IEntity,
  relatedEntities: IEntity[],
  x: number,
  y: number,
  entityList: IEntity[],
  areRelatedEntitiesSources: boolean = false
) => {
  var relatedEntityType = relatedEntities?.length && relatedEntities[0].entityType,
    relatedEntityCount = relatedEntities?.length,
    multiEntitiesNodeId = `${originalEntity.entityType}|${originalEntity.id}|${relatedEntityType}`,
    existingEntityCount = 0;

  relatedEntities?.forEach((relatedEntity) => {
    if (
      entityList?.find(
        (existingEntity) =>
          existingEntity.entityType === relatedEntity.entityType &&
          existingEntity.id === relatedEntity.id &&
          existingEntity.version === relatedEntity.version
      )
    ) {
      existingEntityCount++;
    }
  });

  if (existingEntityCount > 0) {
    y += nodeHeight * existingEntityCount;
    relatedEntityCount -= existingEntityCount;
  }

  relatedEntityCount > 2 &&
    diagram.nodes.push({ x: x + nodeMultiGap * 2, y: y + nodeMultiGap * 2, draggable: false, onClick: null });

  relatedEntityCount > 1 &&
    diagram.nodes.push({ x: x + nodeMultiGap, y: y + nodeMultiGap, draggable: false, onClick: null });

  diagram.nodes.push({
    id: multiEntitiesNodeId,
    entityType: relatedEntityType,
    content: <EntityNode type={relatedEntityType} id={`Total: ${relatedEntityCount}`} idHighlight />,
    x,
    y,
    draggable: false,
    onClick: null,
    tooltip: `Select one of the ${relatedEntityCount} items from the Related Entities table below.`,
  });

  minX = Math.min(minX, x);
  minY = Math.min(minY, y);

  entityList.push(relatedEntities[0]);

  diagram.links.push({
    fromNodeId: areRelatedEntitiesSources ? multiEntitiesNodeId : originalEntity.id,
    toNodeId: areRelatedEntitiesSources ? originalEntity.id : multiEntitiesNodeId,
    fromPosition: Position.right,
    toPosition: Position.left,
  });
};

// =========================================================================================================================
const getEntitiesByTypes = (entities: IEntity[], existingEntities: IEntity[]) => {
  var result = {};

  entities.forEach((entity) => {
    var entityType = entity.entityType;
    var entityExists = existingEntities?.find((ee) => ee.entityType === entityType && ee.id === entity.id);

    if (!entityExists) {
      if (!result.hasOwnProperty(entityType)) {
        result[entityType] = [];
      }

      result[entityType].push(entity);
    }
  });

  return result;
};

// =========================================================================================================================
const adjustNodePositions = (diagram: IDiagram) => {
  diagram.nodes.forEach((node) => {
    minX < 0 && (node.x -= minX);
    minY < 0 && (node.y -= minY);
  });
};

// =========================================================================================================================
// Function to generate node metrics. Not currently used but saving for later.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateNodeMetrics = (diagram: IDiagram, entityList: IEntity[]) => {
  diagram.nodes.forEach((node) => {
    let entity = entityList.find((e) => e.entityType === node.entityType && e.id === node.id);

    if (entity) {
      var metrics = getEntityMetrics(entity);

      if (metrics && metrics.length) {
        node.metrics = metrics;
      }
    }
  });
};

// =========================================================================================================================
// Function to generate node metrics.
const getEntityMetrics = (entity: IEntity): IMetric[] => {
  var targets = entity.targets;

  if (targets && targets.length && entity.entityType && !entity.entityType.endsWith("LineItem")) {
    var lineItems = targets.filter((target) => target.entityType.endsWith("LineItem"));

    if (lineItems && lineItems.length) {
      return [{ name: "Line Items", value: lineItems.length.toString(), displayType: ContentDisplayType.number }];
    }
  }

  return undefined;
};

const getValidationResult = (entityTests: IEntityTest[]) => {
  let pass = 0,
    fail = 0;

  if (entityTests) {
    for (var i = 0; i < entityTests.length; i++) {
      var test = entityTests[i];
      if (test["validationResult"] && test["validationResult"].toLowerCase() !== "pass") {
        fail++;
      } else {
        pass++;
      }
    }
  }

  return { pass, fail };
};

export const getGraphEntityMetrics = (appState: IAppModuleState): IEntityMetric[] => {
  const isEntitySearch = appState.search_item_key === SearchItemKey.entitySearch,
    selectedMetricIds = appState.selected_metric_ids,
    entityMetricsForGraph = appState.entity_metrics_for_graph,
    entityMetricsByView = appState.entity_metrics_by_selected_view,
    entitySearchMetricsForGraph = appState.entity_search_metrics_for_graph;

  if (isEntitySearch) {
    return entitySearchMetricsForGraph;
  } else if (selectedMetricIds && selectedMetricIds.length) {
    return (
      entityMetricsByView &&
      entityMetricsByView.map((em) => ({
        ...em,
        metrics: em.metrics.filter((m) => selectedMetricIds.indexOf(m.id) >= 0),
      }))
    );
  } else {
    return entityMetricsForGraph;
  }
};
