import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import AppModuleHeader from "../common/AppModuleHeader";
import GraphSearch from "./GraphSearch";
import EntityDiagram from "./EntityDiagram";
import EntityTraceDiagram from "./EntityTraceDiagram";
import EntityDetail from "./EntityDetail";
import EntityRelationships from "./EntityRelationships";
import EntityTests from "./EntityTests";
import EntityTiles from "./EntityTiles";
import EntityMetrics from "./EntityMetrics";
import EntityMetricFilter from "./EntityMetricFilter";
import EntityDocModal from "./EntityDocModal";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { actionCreator as appActionCreator } from "../../app/duck";
import { getCommandBarProps, EntitySubPaneKey, SearchItemKey } from "./helper";
import { leftNavUrls } from "../../app/LeftNav.helper";
import classNames from "./GraphHome.module.scss";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { ActionButton } from "@fluentui/react/lib/Button";
import { IEntity, IFinancialEntity, IFinancialEntitySearch, IEntityTest, IEntityMetric } from "../common/interfaces";
import { CollapseToggleIcon } from "../common/CollapseToggleIcon";
import { IEntityView } from "../common/interfaces";
import EntityViewFilters from "./EntityViewFilters";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { errorType } from "../interfaces";
import { IServiceConfig } from "../common/serviceContentInterfaces";

const sideContentWidth = 320;

export interface IGraphHomeStateProps {
  isSmallScreen: boolean;
  selectedEntity: IEntity;
  entitySelectionHistory: (IEntity | IFinancialEntitySearch)[];
  loading: boolean;
  loadingSupplimentaryData: boolean;
  entityTests: IEntityTest[];
  startTime?: Date;
  endTime?: Date;
  financialEntities: IFinancialEntity[];
  entityViews?: IEntityView[];
  selectedEntityViewId?: string;
  selectedEntityType?: string;
  selectedMetricIds?: string[];
  subPaneSelectedKey?: string;
  searchItemKey: SearchItemKey;
  error?: string;
  queryTimestamp?: string;
  queryTime?: number;
  serviceContentConfigs: IServiceConfig[];
  entityMetrics: IEntityMetric[];
  isProduction: boolean;
}

export interface IMatchParams {
  entityType?: string;
  entityId?: string;
  entityVersion?: string;
  viewId?: string;
}

export interface IGraphHomeDispatchProps {
  loadEntity: (entityType: string, entityId: string, entityVersion?: string, refresh?: boolean) => void;
  selectLeftNavLinkByUrl: (url: string) => void;
  setSearchEntityType: (entityType: string) => void;
  setSearchEntityId: (entityId: string) => void;
  setSearchEntityVersion: (entityVersion: string) => void;
  changeSubPaneSelectedKey: (selectedKey: string) => void;
  changeEntityView: (entityViewId: string) => void;
  changeSearchEntitySelectedKey: (key: string) => void;
  loadPreviousEntitySelection: () => void;
  loadEntityViews: (loadMetrics?: boolean, selectedEntityViewId?: string) => void;
  loadEntityMetrics: (startTime?: Date, endTime?: Date, refresh?: boolean) => void;
  loadServiceContentConfigItems: (refresh?: boolean, callback?: Function) => void;
  loadEntityTraceViewConfigItems: (refresh?: boolean) => void;
  loadFinancialEntities: (
    startTime: Date,
    endTime: Date,
    entityType?: string,
    metricId?: string,
    refresh?: boolean
  ) => void;
}

export interface IGraphHomeState {
  isSideContentCollapsed?: boolean;
}

export class GraphHome extends React.Component<
  IGraphHomeStateProps & IGraphHomeDispatchProps & RouteComponentProps<IMatchParams>,
  IGraphHomeState
> {
  state = { isSideContentCollapsed: false };

  componentDidMount() {
    const {
      selectLeftNavLinkByUrl,
      loadEntity,
      setSearchEntityType,
      setSearchEntityId,
      setSearchEntityVersion,
      loadEntityViews,
      loadServiceContentConfigItems,
      loadEntityTraceViewConfigItems,
      changeEntityView,
      loadEntityMetrics,
      changeSearchEntitySelectedKey,
      match,
      entityViews,
      selectedEntityViewId,
      serviceContentConfigs,
      loading,
      entityMetrics,
    } = this.props;

    // No need to run mount logic again if it's already mounted before.
    if (serviceContentConfigs?.length && entityViews?.length && entityMetrics) {
      return;
    }

    loadServiceContentConfigItems(false, () => {
      var viewId = match?.params?.viewId;
      // Make sure service content configs have finished loading before loading the view.
      if (!entityViews) {
        loadEntityViews(!loadingSelectedEntity && !loading, viewId);
      } else if (viewId && viewId !== selectedEntityViewId) {
        changeEntityView(viewId);
      } else if (!entityMetrics) {
        loadEntityMetrics();
      }
    });

    loadEntityTraceViewConfigItems();

    var isEntityLookup = window.location.hash.substring(1) !== leftNavUrls.all.graph;

    if (isEntityLookup) {
      changeSearchEntitySelectedKey(SearchItemKey.entityLookup);
      selectLeftNavLinkByUrl(leftNavUrls.all.graphEntityLookup);
    } else {
      selectLeftNavLinkByUrl(leftNavUrls.all.graph);
    }

    let loadingSelectedEntity = false,
      isViewUrl = window.location.hash.substr(1).startsWith(leftNavUrls.all.graph + "/viewId=");

    if (match && match.params) {
      if (!isViewUrl) {
        let { entityType, entityId, entityVersion } = match.params;

        if (entityType && entityId) {
          entityId = decodeURIComponent(entityId);
          entityType && setSearchEntityType(entityType);
          entityId && setSearchEntityId(entityId);
          entityVersion && setSearchEntityVersion(entityVersion);
          loadEntity(entityType, entityId, entityVersion);
          loadingSelectedEntity = true;
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { searchItemKey, selectedEntity, history, selectedEntityViewId } = this.props;

    if (
      prevProps.searchItemKey !== searchItemKey ||
      prevProps.selectedEntity !== selectedEntity ||
      prevProps.selectedEntityViewId !== selectedEntityViewId
    ) {
      const currentGraphUrl = window.location.hash.substr(1);
      if (searchItemKey === SearchItemKey.entityLookup && selectedEntity && selectedEntity.entity) {
        const { entityType, id, version } = selectedEntity.entity;
        let graphUrl = `${leftNavUrls.all.graph}/${entityType}/${encodeURIComponent(id)}${version && "/"}${version}`;

        graphUrl !== currentGraphUrl && history.push(graphUrl);
      } else if (searchItemKey !== SearchItemKey.entityLookup && selectedEntityViewId) {
        let graphUrl = `${leftNavUrls.all.graph}/viewId=${selectedEntityViewId}`;

        graphUrl !== currentGraphUrl && history.push(graphUrl);
      }
    }
  }

  render() {
    const {
      selectedEntity,
      entitySelectionHistory,
      financialEntities,
      loadPreviousEntitySelection,
      changeSubPaneSelectedKey,
      isSmallScreen,
      subPaneSelectedKey,
      loading,
      loadingSupplimentaryData,
      searchItemKey,
      error,
      history,
      queryTimestamp,
      queryTime,
      entityViews,
      selectedEntityViewId,
      isProduction,
    } = this.props;

    const { isSideContentCollapsed } = this.state;

    const hasSelectedEntity = selectedEntity && selectedEntity.entity,
      hasFinancialEntities = financialEntities && financialEntities.length > 0,
      hasSelectionHistory = entitySelectionHistory && entitySelectionHistory.length > 0,
      showBackButton = false, // Temporarily disabled this for now. //hasSelectionHistory && entitySelectionHistory.length > 1,
      showSupplimentaryPane = !loading && (hasSelectedEntity || hasFinancialEntities || hasSelectionHistory);

    const showSideContent = !isSideContentCollapsed,
      mainContentStyle = { right: showSideContent ? sideContentWidth : 0 },
      sideContentStyle = {
        width: isSmallScreen ? "auto" : showSideContent ? sideContentWidth : 0,
        opacity: showSideContent ? 1 : 0,
      };

    const selectedEntityView = entityViews?.find((view) => view.id === selectedEntityViewId),
      entityViewDesc = selectedEntityView?.description;

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader commandBarProps={getCommandBarProps(this.props)} />
        {error && <div className={classNames.error}>{error}</div>}
        {!error && (
          <>
            <div className={classNames.content}>
              <div className={classNames.mainContentPane} style={mainContentStyle} data-is-scrollable="true">
                {!isProduction && <EntityViewFilters />}
                <GraphSearch className={classNames.searchPane} />
                {searchItemKey !== SearchItemKey.entityLookup && entityViewDesc && (
                  <div className={classNames.descPane}>{entityViewDesc}</div>
                )}
                <div className={classNames.diagramPane}>
                  {showBackButton && (
                    <ActionButton
                      iconProps={{ iconName: "ChevronLeft" }}
                      title="Back To Previous Graph Selection"
                      className={classNames.backButton}
                      onClick={loadPreviousEntitySelection}
                    >
                      Back
                    </ActionButton>
                  )}
                  <EntityMetricFilter />
                  <EntityDiagram ariaLabel={entityViewDesc || "Financial Entity Diagram"} />
                  {searchItemKey === SearchItemKey.metricSearch && !loading && queryTimestamp && (
                    <div className={classNames.queryTimestamp}>
                      Data Last Refreshed:
                      <span className={classNames.time}>{new Date(queryTimestamp).toLocaleString()}</span>
                      <span className={classNames.queryTime}>
                        Query Time:
                        <span className={classNames.time}>{queryTime?.toLocaleString("en-us")}ms</span>
                      </span>
                    </div>
                  )}
                </div>
                {showSupplimentaryPane && (
                  <Pivot
                    className={classNames.supplimentaryPane}
                    selectedKey={subPaneSelectedKey}
                    onLinkClick={(item: PivotItem) => changeSubPaneSelectedKey(item.props.itemKey)}
                  >
                    {searchItemKey === SearchItemKey.metricSearch && (
                      <PivotItem
                        className={classNames.pivotItem}
                        headerText="Metrics"
                        itemKey={EntitySubPaneKey.Metrics}
                      >
                        <EntityMetrics />
                      </PivotItem>
                    )}
                    {searchItemKey === SearchItemKey.metricSearch && (
                      <PivotItem
                        className={classNames.pivotItem}
                        headerText="Analytics"
                        itemKey={EntitySubPaneKey.Analytics}
                      >
                        <EntityTiles />
                      </PivotItem>
                    )}
                    {searchItemKey === SearchItemKey.entityLookup && (
                      <PivotItem
                        className={classNames.pivotItem}
                        headerText="Validation Tests"
                        itemKey={EntitySubPaneKey.Tests}
                      >
                        <EntityTests />
                      </PivotItem>
                    )}
                    {searchItemKey === SearchItemKey.entityLookup && (
                      <PivotItem
                        className={classNames.pivotItem}
                        headerText="Entity Trace"
                        itemKey={EntitySubPaneKey.TraceMetrics}
                      >
                        <EntityTraceDiagram />
                      </PivotItem>
                    )}
                    <PivotItem
                      className={classNames.pivotItem}
                      headerText="Related Entities"
                      itemKey={EntitySubPaneKey.Entities}
                    >
                      <EntityRelationships />
                    </PivotItem>
                  </Pivot>
                )}
                {!showSupplimentaryPane && loadingSupplimentaryData && (
                  <Spinner className={classNames.spinner} label="Loading entity data..." />
                )}
              </div>
              <CollapseToggleIcon
                isCollapsed={isSideContentCollapsed}
                className={classNames.collapseToggleIcon}
                onClick={() => this.setState({ isSideContentCollapsed: !isSideContentCollapsed })}
              />
              <div className={classNames.detailPane} style={sideContentStyle}>
                <EntityDetail history={history} />
              </div>
            </div>
            <EntityDocModal />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState): IGraphHomeStateProps => ({
  isSmallScreen: state.app.is_small_screen,
  isProduction: state.app.is_production,
  selectedEntity: state.modules.selected_entity,
  entitySelectionHistory: state.modules.entity_selection_history,
  entityTests: state.modules.entity_tests,
  startTime: state.modules.search_entity_start_time,
  endTime: state.modules.search_entity_end_time,
  financialEntities: state.modules.metric_search_entities,
  loadingSupplimentaryData: state.modules.loading_entity_relationships || state.modules.loading_financial_entities,
  loading:
    state.modules.loading_entity ||
    state.modules.loading_entity_tests ||
    state.modules.loading_financial_entities ||
    state.modules.loading_entity_metrics ||
    state.modules.loading_config_items,
  entityViews: state.modules.entity_views,
  selectedEntityViewId: state.modules.selected_entity_view_id,
  selectedEntityType: state.modules.search_entity_type,
  selectedMetricIds: state.modules.selected_metric_ids,
  subPaneSelectedKey: state.modules.graph_subpane_selected_key,
  searchItemKey: state.modules.search_item_key,
  error: state.modules.errors[errorType.config],
  queryTimestamp: state.modules.entity_metrics_query_timestamp,
  queryTime: state.modules.entity_metrics_query_time,
  serviceContentConfigs: state.modules.service_content_configs,
  entityMetrics: state.modules.entity_metrics_raw,
});

const mapDispatchToProps: IGraphHomeDispatchProps = {
  loadEntity: actionCreator.loadEntity,
  setSearchEntityType: actionCreator.setSearchEntityType,
  setSearchEntityId: actionCreator.setSearchEntityId,
  setSearchEntityVersion: actionCreator.setSearchEntityVersion,
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  loadFinancialEntities: actionCreator.loadFinancialEntities,
  loadPreviousEntitySelection: actionCreator.loadPreviousEntitySelection,
  loadEntityViews: actionCreator.loadEntityViewConfigItems,
  loadEntityMetrics: actionCreator.loadEntityMetrics,
  changeSubPaneSelectedKey: actionCreator.changeSubPaneSelectedKey,
  loadServiceContentConfigItems: actionCreator.loadServiceContentConfigItems,
  changeEntityView: actionCreator.changeEntityView,
  loadEntityTraceViewConfigItems: actionCreator.loadEntityTraceViewConfigItems,
  changeSearchEntitySelectedKey: actionCreator.changeSearchEntitySelectedKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GraphHome));
