import React from "react";
import { connect } from "react-redux";
import AppModuleHeader from "./AppModuleHeader";
import { actionCreator as appActionCreator } from "../../app/duck";
import { actionCreator } from "../../appModules/duck";
import { IState } from "../../reducers/interfaces";
import { getUserId, getBasicPageCommandBarItems } from "./helper";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import ReportGroup from "./ReportGroup";
import { errorType } from "../interfaces";
import AlertModal from "./AlertModal";
import classNames from "./ReportGroupAppModule.module.scss";
import { IReportGroup } from "./interfaces";

export interface IReportGroupAppModuleStateProps {
  userId: string;
  isSmallScreen: boolean;
  selectedReportId: string;
  reportExcelDownloading: boolean;
  reportExcelDownloadError: string;
  reportGroup: IReportGroup;
}

export interface IReportGroupAppModuleDispatchProps {
  selectLeftNavLinkByUrl: (url: string) => void;
  getReportGroup: (reportGroupId: string, reportId?: string, refreshData?: boolean) => void;
  downloadReportDataToExcel: (reportId: string) => void;
  downloadReportDataToExcelReset: () => void;
  selectReport: (reportId: string) => void;
}

export interface IReportGroupAppModuleProps {
  reportGroupId: string;
  reportId?: string;
  leftNavLinkUrl?: string;
}

export class ReportGroupAppModule extends React.Component<
  IReportGroupAppModuleProps & IReportGroupAppModuleStateProps & IReportGroupAppModuleDispatchProps
> {
  componentDidMount() {
    const { selectLeftNavLinkByUrl, leftNavLinkUrl, reportId, selectReport } = this.props;

    reportId && selectReport(reportId);

    selectLeftNavLinkByUrl(leftNavLinkUrl);
  }

  render() {
    const {
      reportGroupId,
      reportExcelDownloading,
      reportExcelDownloadError,
      reportGroup,
      downloadReportDataToExcelReset,
    } = this.props;

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader
          hideHeader={!reportGroup || reportGroup.hideGroupHeader}
          headerTextOverride={reportGroup?.useReportGroupNameAsHeader && reportGroup?.name}
          commandBarProps={getCommandBarProps(this.props)}
        />
        <ReportGroup reportGroupId={reportGroupId} />
        {!reportExcelDownloading && reportExcelDownloadError && (
          <AlertModal
            content={reportExcelDownloadError}
            title="Excel Export Failed"
            onCommit={() => downloadReportDataToExcelReset()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState): IReportGroupAppModuleStateProps => ({
  userId: getUserId(state.app.login_user),
  isSmallScreen: state.app.is_small_screen,
  selectedReportId: state.modules.selected_report_id,
  reportExcelDownloading: state.modules.report_excel_downloading,
  reportExcelDownloadError: state.modules.errors[errorType.reportExcelDownload],
  reportGroup: state.modules.selected_report_group,
});

const mapDispatchToProps: IReportGroupAppModuleDispatchProps = {
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  getReportGroup: actionCreator.getReportGroup,
  downloadReportDataToExcel: actionCreator.downloadReportDataToExcel,
  downloadReportDataToExcelReset: actionCreator.downloadReportDataToExcelReset,
  selectReport: actionCreator.selectReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportGroupAppModule);

const getCommandBarProps = (
  props: IReportGroupAppModuleProps & IReportGroupAppModuleStateProps & IReportGroupAppModuleDispatchProps
): ICommandBarProps => {
  const {
    getReportGroup,
    isSmallScreen,
    reportGroupId,
    selectedReportId,
    downloadReportDataToExcel,
    reportExcelDownloading,
    reportGroup,
  } = props;

  const basicPageCommandBarActions = {
    refresh: () => getReportGroup(reportGroupId, selectedReportId, true),
  };

  const commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [
      {
        key: "getHelp",
        name: "Get Help",
        title: "Get Help From Report Owner",
        iconProps: {
          iconName: "Info",
        },
        iconOnly: isSmallScreen,
        onClick: (ev: any) => {
          let receipient = reportGroup && (reportGroup.owningTeam || reportGroup.owner),
            reportGroupName = reportGroup && reportGroup.name;
          window.location.href = `mailto:${receipient}?subject=Commerce Radar Report Group #${reportGroupId}: ${reportGroupName}&body=${window.location.href}`;
        },
      },
      {
        key: "excelExport",
        name: "Excel Export",
        title: "Export the current report data to Excel",
        iconProps: {
          iconName: "ExcelDocument",
        },
        iconOnly: isSmallScreen,
        disabled: reportExcelDownloading || !selectedReportId,
        onClick: (ev: any) => selectedReportId && downloadReportDataToExcel(selectedReportId),
      },
      ...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions),
    ],
  };

  return commandBarProps;
};
