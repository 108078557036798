import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getValidationFields } from "./ValidationTestEdit.helper";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import ValidateServiceDataForConfig from "./ValidateServiceDataForConfig";
import { IPivotItemProps, PivotItem } from "@fluentui/react";
import classNames from "./MyServiceContentEdit.module.scss";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import { IState as IStatePropsBase } from "../../../reducers/interfaces";
import {
  getBasicFields,
  getSourceEntitiesField,
  defaultServiceContent,
  getFilterSetField,
  getSlaRulesFields,
  getMetricsFields,
  getTileFields,
  getAlternateKeysFields,
  getStatsFields,
} from "./MyServiceContentsEdit.helper";
import { MyServiceContentAttributesEdit } from "./MyServiceContentAttributesEdit";
import { IServiceConfig } from "../../common/serviceContentInterfaces";
import { ConfigItemType } from "../../../api/radarApi";

export interface IMyServiceContentsStateProps {
  loadingConfigServices: boolean;
  configItems: any[];
}

interface IDispatchProps extends IConfigItemEditDispatchProps {
  loadConfigItems?: (refresh?: boolean) => void;
}
type Props = IConfigItemEditProps & IMyServiceContentsStateProps & IDispatchProps;

interface IState {
  configSelected: string;
  renderEverything: boolean;
  generalErrors: number;
  attributeErrors: number;
  validationErrors: number;
  enableUiValidation: boolean;
}

const renderBadge = (errorCount: number) => {
  return (props: IPivotItemProps) => {
    const { headerText } = props;
    return (
      <span>
        {headerText}
        {errorCount > 0 ? (
          <span
            className={classNames.errorBadge}
            title={`${headerText} has ${errorCount} ${errorCount === 1 ? "error" : "errors"}`}
          >
            {errorCount}
          </span>
        ) : null}
      </span>
    );
  };
};

export class MyServiceContentEdit extends React.Component<Props, IState> {
  private generalEditForm: React.RefObject<any> = React.createRef();
  private attributesEditForm: React.RefObject<any> = React.createRef();
  private validationEditForm: React.RefObject<any> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      renderEverything: false,
      enableUiValidation: true,
    } as IState;
  }

  componentDidMount() {
    const { configItems, loadConfigItems } = this.props;

    if (!configItems?.length) {
      loadConfigItems();
    }
  }

  onConfigItemEdit = (configValue: string) => {
    const { renderEverything, generalErrors, attributeErrors, validationErrors } = this.state;
    const { editConfigItem } = this.props;

    editConfigItem(configValue);

    if (!renderEverything && (generalErrors || attributeErrors || validationErrors)) {
      this.getFieldErrorsAsync();
    }
  };

  render() {
    const { configItems, configValue } = this.props;
    const { renderEverything, generalErrors, attributeErrors, validationErrors } = this.state;

    let serviceConfig: IServiceConfig = null;
    try {
      serviceConfig = JSON.parse(configValue);
    } catch {}

    let configItemId = this.props?.match?.params?.configItemId;

    const generalBadge = renderBadge(generalErrors);
    const attributeBadge = renderBadge(attributeErrors);
    const validationBadge = renderBadge(validationErrors);

    return (
      <ConfigItemEdit
        {...this.props}
        pivotItems={[
          <PivotItem
            key="general"
            className={classNames.pivotItem}
            headerText="General"
            onRenderItemLink={generalBadge}
            alwaysRender={renderEverything}
          >
            <ConfigItemEditForm
              configItem={serviceConfig}
              fields={[
                ...getBasicFields(serviceConfig, configItems),
                ...getSourceEntitiesField(serviceConfig, configItems),
                ...getFilterSetField(serviceConfig, configItems),
                ...getAlternateKeysFields(serviceConfig, configItems),
                ...getSlaRulesFields(serviceConfig, configItems),
                ...getMetricsFields(serviceConfig, configItems),
                ...getStatsFields(serviceConfig, configItems),
                getTileFields(serviceConfig, configItems),
              ]}
              editConfigItem={this.onConfigItemEdit}
              ref={this.generalEditForm}
            />
          </PivotItem>,
          <PivotItem
            key="attributes"
            className={classNames.pivotItem}
            headerText="Attributes"
            alwaysRender={renderEverything}
            onRenderItemLink={attributeBadge}
          >
            <MyServiceContentAttributesEdit
              serviceConfig={serviceConfig}
              serviceConfigs={configItems}
              editConfigItem={this.onConfigItemEdit}
              ref={this.attributesEditForm}
            />
          </PivotItem>,
          <PivotItem
            key="validations"
            className={classNames.pivotItem}
            headerText="Validations"
            alwaysRender={renderEverything}
            onRenderItemLink={validationBadge}
          >
            <ConfigItemEditForm
              configItem={serviceConfig}
              fields={getValidationFields(serviceConfig, configItems)}
              editConfigItem={this.onConfigItemEdit}
              ref={this.validationEditForm}
            />
          </PivotItem>,
        ]}
        pivotItemsAfter={[
          <PivotItem key="ValidateConfig" className={classNames.pivotItem} headerText="Validate">
            <ValidateServiceDataForConfig
              {...this.props}
              configItem={serviceConfig}
              configItemId={configItemId}
            ></ValidateServiceDataForConfig>
          </PivotItem>,
        ]}
        configItem={serviceConfig}
        configItemName="Service Content"
        configItemType={ConfigItemType.ServiceContent}
        appInsightsPageName="MyServiceContentEditPage"
        leftNavUrl={leftNavUrls.management.serviceContent}
        defaultConfigItem={defaultServiceContent}
        getFieldErrors={this.getFieldErrors}
      />
    );
  }

  getFieldErrorsAsync = () => {
    let resolveAsync;
    const callback = new Promise((resolve) => {
      resolveAsync = resolve;
    });
    this.setState({ renderEverything: true }, () => {
      var generalEditFormErrors =
        this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
      var attributesEditFormErrors =
        this.attributesEditForm && this.attributesEditForm.current && this.attributesEditForm.current.getErrors();
      var validationEditFormErrors =
        this.validationEditForm && this.validationEditForm.current && this.validationEditForm.current.getErrors();

      this.setState({
        renderEverything: false,
        generalErrors: generalEditFormErrors ? Object.keys(generalEditFormErrors).length : 0,
        attributeErrors: attributesEditFormErrors ? Object.keys(attributesEditFormErrors).length : 0,
        validationErrors: validationEditFormErrors ? Object.keys(validationEditFormErrors).length : 0,
      });
      resolveAsync({ ...generalEditFormErrors, ...attributesEditFormErrors, ...validationEditFormErrors });
    });
    return callback;
  };

  getFieldErrors = () => {
    const { enableUiValidation } = this.state;
    return enableUiValidation ? this.getFieldErrorsAsync() : {};
  };
}

export const mapStateToProps = (state: IStatePropsBase): IMyServiceContentsStateProps => ({
  ...mapStateToPropsBase(state),
  configItems: state.modules.config_items,
  loadingConfigServices: state.modules.loading_config_items,
});

const mapDispatchToProps: IDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadServiceContentConfigItems,
  updateConfigItem: actionCreator.updateServiceContentConfigItem,
  loadConfigItem: actionCreator.loadServiceContentConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyServiceContentEdit);
