import React from "react";
import {
  Dropdown as UIFabricDropdown,
  IDropdownOption,
  IDropdownProps as UIFabricDropdownProps,
} from "@fluentui/react/lib/Dropdown";
import { IVisualProps } from "./Visual";

export interface IDropdownVisualProps extends UIFabricDropdownProps {
  selectedValue?: string;
  doNotInsertEmptyItem?: boolean;
  doNotDefaultSelectedValue?: boolean;
}

export interface IDropdownProps extends IVisualProps {
  onValueSelect?: (value: string) => void;
}

export class Dropdown extends React.Component<IDropdownProps> {
  componentDidMount() {
    const { filterName, onValueInit } = this.props;

    const options = this.getDataOptions();
    const finalSelectedKey = this.getSelectedKey(options);

    if (finalSelectedKey !== undefined) {
      onValueInit && onValueInit(filterName, finalSelectedKey);
    }
  }

  render() {
    const { label, visualProps = {}, width, selectedValue, placeholder } = this.props;
    const { multiSelect } = visualProps as IDropdownVisualProps;

    const options = this.getDataOptions();
    const finalSelectedKey = this.getSelectedKey(options);

    return (
      <UIFabricDropdown
        {...(visualProps as IDropdownVisualProps)}
        label={label}
        options={options}
        selectedKey={!multiSelect && finalSelectedKey ? finalSelectedKey : undefined}
        selectedKeys={multiSelect && selectedValue ? selectedValue.split(",") : []}
        dropdownWidth={Number(width)}
        style={{ width }}
        placeholder={placeholder}
        onChange={this.onChange}
      />
    );
  }

  onChange = (ev, selectedOption: IDropdownOption) => {
    const { onValueSelect, visualProps = {}, selectedValue: origValue } = this.props;
    const { multiSelect } = visualProps as IDropdownVisualProps;
    let selectedValue = selectedOption && selectedOption.key && selectedOption.key.toString();

    if (multiSelect && origValue) {
      let values = origValue.split(","),
        valueIndex = values.indexOf(selectedValue);

      if (valueIndex >= 0) {
        values.splice(valueIndex, 1);
      } else {
        values.push(selectedValue);
      }

      selectedValue = values.toString();
    }

    onValueSelect && origValue !== selectedValue && onValueSelect(selectedValue);
  };

  getDataOptions = (): IDropdownOption[] => {
    const {
      data,
      categoryFieldName = "key",
      valueFieldNames = ["text"],
      selectedFieldName = "selected",
      visualProps = {},
    } = this.props;
    const { doNotInsertEmptyItem, options: visualOptions } = visualProps as IDropdownVisualProps;

    if (visualOptions) return visualOptions;

    if (!data || !data.length) return [];

    var options = data.map((item) => ({
      ...item,
      key: item[categoryFieldName]?.toString(),
      text: item[valueFieldNames[0]],
      selected:
        item[selectedFieldName] &&
        (item[selectedFieldName]?.toLowerCase() === "true" || item[selectedFieldName] === "1"),
    }));

    !doNotInsertEmptyItem && options.unshift({ key: "", text: "" });

    return options;
  };

  getSelectedKey = (options: IDropdownOption[]) => {
    const { visualProps = {}, selectedValue } = this.props;
    const { doNotDefaultSelectedValue, multiSelect } = visualProps as IDropdownVisualProps;

    if (multiSelect) {
      return selectedValue;
    }

    const defaultSelectedKey = doNotDefaultSelectedValue ? undefined : options && options.length && options[0].key;

    const selectedOption = options?.find(
      (option) => option.key?.toString() === selectedValue?.toString() || option.selected
    );

    return selectedValue || selectedOption?.key || defaultSelectedKey;
  };
}

export default Dropdown;
