/// <reference path="../../../typings/global.d.ts" />

import React, { Component } from "react";
import Node from "./Node";
import Link from "./Link";
import { IDiagram, INode, ILink, IClassNames } from "./interfaces";
import * as helper from "./helper";
import classNames from "./Diagram.module.scss";

export interface IDiagramProps {
  ariaLabel?: string;
  diagram?: IDiagram;
  classNames?: IClassNames;
  selectedIds?: string[];
  onNodeClick?: (node: INode) => void;
  onNodeMoved?: (node: INode) => void;
  onLinkClick?: (link: ILink) => void;
}

export class Diagram extends Component<IDiagramProps> {
  render(): JSX.Element {
    const {
      ariaLabel,
      diagram,
      classNames: propsClassNames = {},
      selectedIds = [],
      onNodeClick,
      onNodeMoved,
      onLinkClick,
    } = this.props;

    if (!diagram) {
      return null;
    }

    const {
      id: diagramId = "",
      nodes,
      links,
      x,
      y,
      width,
      height,
      className,
      classNames: customClassNames = propsClassNames,
      defaultLinkProps = {},
      defaultNodeProps = {},
      style = {},
      autoSizing,
    } = diagram;

    const adjustedNodes = helper.calcNodePlacements(nodes, defaultNodeProps.width, defaultNodeProps.height);
    const adjustedLinks = helper.calcLinkPlacements(links, adjustedNodes);
    const contentStyle = helper.getContentStyle(adjustedNodes, adjustedLinks, autoSizing);
    const rootStyle = { ...helper.getStyleByMeasurements(x, y, width, height, contentStyle), ...style };
    // TODO: This accessibility fix has regressed the proper display of the Commerce Graph. Comment this out first and will let Jeremy to work on a better fix here.
    //const sortedCombinedNodesAndLinks = helper.setTabIndex(adjustedNodes, adjustedLinks);
    const svgStyle = { width: contentStyle.width, height: contentStyle.height };
    const selectionStyle = selectedIds?.length ? { opacity: 0.55 } : {};

    const isNode = (element: INode | ILink) => !element["fromNodeId"];

    const renderNode = (node: INode, index: number) => {
      node.classNames = customClassNames;
      let id = node.id || "n" + index;
      if (isNode(node))
        return (
          <Node
            key={id}
            node={{
              ...defaultNodeProps,
              ...node,
              id,
              style: {
                ...defaultNodeProps.style,
                ...node.style,
                ...selectionStyle,
              },
              selected: node.selected !== undefined ? node.selected : selectedIds.indexOf(id) >= 0,
            }}
            onClick={onNodeClick}
            onMoved={onNodeMoved}
          />
        );
    };

    const renderLink = (link: ILink, index: number) => {
      link.classNames = customClassNames;
      let id = link.id || "l" + index;
      if (!isNode(link)) {
        return (
          <Link
            diagramId={diagramId}
            key={id}
            link={{
              ...defaultLinkProps,
              ...link,
              id,
              selected: selectedIds.indexOf(id) >= 0,
              style: {
                ...defaultLinkProps.style,
                ...link.style,
                ...selectionStyle,
              },
            }}
            onClick={onLinkClick}
          />
        );
      }
    };

    const renderArrow = () => (
      <defs>
        <marker
          id={"diagram-arrow" + diagramId}
          viewBox="0 0 10 10"
          refX="8"
          refY="3"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 3 L 0 6 z" className={classNames.path} />
        </marker>
      </defs>
    );

    return (
      <div
        aria-label={ariaLabel}
        className={[classNames.root, customClassNames.diagram, className].join(" ")}
        style={rootStyle}
        tabIndex={0}
      >
        <div className={[classNames.content, customClassNames.content].join(" ")} style={contentStyle}>
          {adjustedNodes && !!adjustedNodes.length && adjustedNodes.map(renderNode)}
          <svg role="presentation" className={[classNames.svg, customClassNames.svg].join(" ")} style={svgStyle}>
            {renderArrow()}
            {adjustedLinks && !!adjustedLinks.length && adjustedLinks.map(renderLink)}
          </svg>
        </div>
        {!!selectedIds.length && (
          <div className={classNames.selectionTips}>Tips: Click selected item again to unselect.</div>
        )}
      </div>
    );
  }
}

export default Diagram;
