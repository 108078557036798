import React from "react";
import { IColumn, SelectionMode, IGroup, Selection, DetailsList } from "@fluentui/react/lib/DetailsList";
import { IVisualProps } from "./Visual";
import { onDetailsListColumnClick } from "../../utilities/tableHelper";
import classNames from "./TableVisual.module.scss";
import { IFilterItem } from "../../../appModules/common/interfaces";

export interface ITableVisualProps {
  isHeaderVisible?: boolean;
}

export interface ITableProps extends IVisualProps {
  selectable?: boolean;
  columnsAsSelectedValues?: string[];
}

export interface ITableVisualState {
  columns: IColumn[];
  items: any[];
  sortedColumnKey?: string;
  isSortedDescending?: boolean;
}

export class TableVisual extends React.Component<ITableProps, ITableVisualState> {
  private _selection: Selection;

  constructor(props: ITableProps) {
    super(props);

    this.state = {
      columns: this.updateColumns(props["columns"]),
      items: props.data,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        const { selectable, columnsAsSelectedValues, filterName, selectedValue, onValuesSelect } = this.props;

        if (!selectable || !filterName) return;

        const selectedIndexes = this._selection.getSelectedIndices(),
          selectedItems = this._selection.getSelection(),
          selectedItem = selectedItems && selectedItems.length && selectedItems[0];

        // On table row selection, create one filter for the selected row index and 1 or more filters based on the columnsAsSelectedValues.
        if (onValuesSelect) {
          let filters: IFilterItem[] = [];

          // Set the row index as selected value.
          if (selectedIndexes && selectedIndexes.length && selectedIndexes.indexOf(Number(selectedValue)) < 0) {
            filters.push({ name: filterName, value: selectedIndexes[0].toString() });
          }

          // Set other specified columns as additional filters if defined.
          if (columnsAsSelectedValues?.length) {
            columnsAsSelectedValues.forEach((fieldName) => {
              if (selectedItem.hasOwnProperty(fieldName)) {
                filters.push({ name: `${filterName}.${fieldName}`, value: selectedItem[fieldName] });
              }
            });
          }

          filters.length && onValuesSelect(filters);
        }
      },
    });
  }

  componentDidMount() {
    const { selectable, selectedValue, filters, columnsAsSelectedValues, data, filterName } = this.props;
    const selectedIndexes = this._selection.getSelectedIndices();

    if (selectable && data?.length) {
      let selectedItem = null;

      if (filters?.length && columnsAsSelectedValues?.length) {
        // Set the selected table row based on the filter values if columns are specified for selected values.
        var i = 0;
        for (; i < data.length; i++) {
          var dataItem = data[i],
            foundItemCount = 0;

          for (var j = 0; j < filters.length; j++) {
            var filter = filters[j];
            for (var k = 0; k < columnsAsSelectedValues.length; k++) {
              var fieldName = columnsAsSelectedValues[k];
              if (filter.name === `${filterName}.${fieldName}` && dataItem[fieldName] === filter.value) {
                foundItemCount++;
                break;
              }
            }
          }

          // All columns need to have matching filter values to consider a matching item.
          if (foundItemCount === columnsAsSelectedValues.length) {
            selectedItem = dataItem;
            break;
          }
        }

        if (selectedItem) {
          this._selection.setIndexSelected(i, true, false);
        }
      }

      if (!selectedItem && selectedValue && selectedIndexes.indexOf(Number(selectedValue)) < 0) {
        // Set the selected table row index based on the selected value.
        this._selection.setIndexSelected(Number(selectedValue), true, false);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.data !== nextProps.data || this.state.items !== nextState.items;
  }

  render() {
    const { data, visualProps, groupFieldNames, selectable, id } = this.props;

    if (!data || !data.length) return null;

    const { columns, items: origItems } = this.state;

    const { items, groups } = processGroups(origItems, groupFieldNames);

    const selectionMode = selectable ? SelectionMode.single : SelectionMode.none;

    const className = selectable ? classNames.selectable : undefined;

    const selectedIndexes = this._selection.getSelectedIndices(),
      initialFocusedIndex = selectable && selectedIndexes.length ? selectedIndexes[0] : undefined;

    return (
      <DetailsList
        {...(visualProps as ITableVisualProps)}
        key={`adt_${id}`}
        items={items}
        groups={groups}
        className={className}
        columns={columns}
        selectionMode={selectionMode}
        compact={true}
        selection={this._selection}
        initialFocusedIndex={initialFocusedIndex}
      />
    );
  }

  updateColumns = (columns: IColumn[]): IColumn[] => {
    return (
      columns &&
      columns.map((column) => ({
        ...column,
        onColumnClick: this.onColumnClick,
      }))
    );
  };

  onColumnClick = (ev: React.MouseEvent, column: IColumn) => {
    const newState = onDetailsListColumnClick(column, this.state);

    this.setState(newState as ITableVisualState);
  };
}

export default TableVisual;

const processGroups = (items: any[], groupFieldNames: string[]): any => {
  if (!groupFieldNames || !groupFieldNames.length) {
    return { items, groups: undefined };
  }

  // TODO: Add support for more than one level of grouping.
  var groupFieldName = groupFieldNames[0];

  // Sort items by group field.
  var sortedItems = items.sort((a, b) =>
    a[groupFieldName] > b[groupFieldName] ? 1 : a[groupFieldName] < b[groupFieldName] ? -1 : 0
  );

  var startIndex = 0,
    count = 0,
    groups: IGroup[] = [],
    prevGroupValue = "";

  sortedItems.forEach((item) => {
    var groupValue = item[groupFieldName];

    if (prevGroupValue !== "" && prevGroupValue !== groupValue) {
      groups.push({ key: "group" + groups.length, name: prevGroupValue, startIndex, count });
      startIndex += count;
      count = 0;
    }

    prevGroupValue = groupValue;
    count++;
  });

  groups.push({ key: "group" + groups.length, name: prevGroupValue, startIndex, count });

  return { items: sortedItems, groups };
};
