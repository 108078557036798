import { useContext, useEffect } from "react";
import { IIncident } from "./interfaces";
import { AppModuleContext } from "../common/AppModule";
import { CopilotChatDataTextMaxSize } from "../common/CopilotPane";
import { AppContext } from "../../app/App";

export interface IIncidentsReportCopilotProps {
  incidents: IIncident[];
}

// Note: This component is created only because we haven't migrated IncidentsReport to
// functional component yet and we can't leverage the useContext hook there.  Once IcidentsReport
// is converted into a functional component, we can merge these logics there instead.

export const IncidentsReportCopilot = (props: IIncidentsReportCopilotProps) => {
  const { incidents } = props;
  const { changeAppModuleState } = useContext(AppModuleContext);
  const { changeAppState } = useContext(AppContext);

  useEffect(() => {
    if (incidents?.length) {
      let copilotDataText = getIncidentDataText(incidents);
      changeAppState({ useCopilot: true });
      changeAppModuleState({ copilotDataText });
    }
  }, [incidents, changeAppState, changeAppModuleState]);

  return null;
};

export default IncidentsReportCopilot;

const getIncidentDataText = (incidents: IIncident[]): string => {
  // TODO: Due to OpenAI token limit, we are capping our data to return for now.
  let filteredIncidents = incidents
    ?.filter(
      (incident) => incident["status"]?.toLowerCase() === "active" || incident["status"]?.toLowerCase() === "mitigated"
    )
    .sort((a, b) => (a["createDate"] < b["createDate"] ? 1 : a["createDate"] > b["createDate"] ? -1 : 0));

  let result =
    "Use this data:\n\n" +
    "Status,Incident ID,Severity,Incident Title,Financial Impact Dollar Amount,Owner,Director,Owning Team,Impacted Line Of Business,Impact Started Date,Incident Created Date,Target Resolution Date,Mitigated Date,Resolved Date,Engineering Root Cause,Financial Reporting Impact,Compliance Review State,Root Cause Sub-Category,Causing Service\n";

  if (filteredIncidents?.length) {
    for (let i = 0; i < filteredIncidents.length; i++) {
      let incident = filteredIncidents[i];

      result += `${incident["status"]},${incident["incidentId"]},${incident["severity"]},${incident["title"]},${incident["financialImpact"]},${incident["owningContactAlias"]},${incident["engineeringDirector"]},${incident["owningTeamId"]},${incident["impactedLOB"]},${incident["impactStartDate"]},${incident["createDate"]},${incident["discoveredDate"]},${incident["targetResolutionDate"]},${incident["mitigateDate"]},${incident["resolveDate"]},${incident["engineeringRootCause"]},${incident["financialReportingImpact"]},${incident["complianceReview"]},${incident["rootCauseSubCategory"]},${incident["causingService"]}\n`;

      if (result.length >= CopilotChatDataTextMaxSize) {
        break;
      }
    }
  }

  return result;
};
