import React, { CSSProperties } from "react";
import { Hint, RadialChart, DiscreteColorLegend } from "react-vis";
import { DISCRETE_COLOR_RANGE, EXTENDED_DISCRETE_COLOR_RANGE } from "react-vis/dist/theme";
import { ContentDisplayType, getFormatContent } from "../../utilities/miscHelper";
import classNames from "./PieChart.module.scss";

export interface IPieChartProps {
  data: any[];
  width: number;
  height: number;
  categoryFieldName?: string;
  valueFieldName?: string;
  categoryDisplayType?: ContentDisplayType;
  valueDisplayType?: ContentDisplayType;
  labelsRadiusMultiplier?: number;
  labelsStyle?: CSSProperties;
  radius?: number;
  [propName: string]: any;
}

export interface IPieChartState {
  hoveredCell: any;
}

export class PieChart extends React.Component<IPieChartProps, IPieChartState> {
  state: IPieChartState = {
    hoveredCell: false,
  };

  buildValue = (hoveredCell) => {
    const { radius, angle, angle0 } = hoveredCell;
    const truedAngle = (angle + angle0) / 2;
    return {
      x: radius * Math.cos(truedAngle),
      y: radius * Math.sin(truedAngle),
    };
  };

  render() {
    const {
      data,
      width,
      height,
      categoryFieldName = "category",
      valueFieldName = "value",
      categoryDisplayType,
      valueDisplayType = ContentDisplayType.number,
      labelsRadiusMultiplier = 0.9,
      labelsStyle = {},
      radius,
      showLabels = true,
    } = this.props;

    const { hoveredCell } = this.state;

    const transformedData = transformData(
      data,
      categoryFieldName,
      valueFieldName,
      categoryDisplayType,
      valueDisplayType
    );

    return (
      <div className={classNames.flexstyle}>
        <span>
          <RadialChart
            {...this.props}
            data={transformedData}
            width={width / 2}
            height={height - 10}
            radius={radius}
            colorType="literal"
            style={{ stroke: "#fff", strokeWidth: "1" }}
            onValueMouseOver={(v) => this.setState({ hoveredCell: v.x && v.y ? v : false })}
            onValueMouseOut={() => this.setState({ hoveredCell: false })}
            showLabels={showLabels}
            labelsStyle={{ fontSize: "12px", ...labelsStyle }}
            labelsRadiusMultiplier={labelsRadiusMultiplier}
          >
            {hoveredCell ? (
              <Hint value={this.buildValue(hoveredCell)} align={{ horizontal: "right", vertical: "bottom" }}>
                <div className={classNames.tooltip}>
                  <div className={classNames.flexstyle}>
                    <div className={classNames.tooltipbox} style={{ background: hoveredCell.color }} />
                    <div className={classNames.tooltipText}>{hoveredCell.title}</div>
                  </div>
                  <div className={classNames.tooltipnumber}>
                    {hoveredCell.subTitle + " (" + hoveredCell.label + ")"}
                  </div>
                </div>
              </Hint>
            ) : null}
          </RadialChart>
        </span>
        <span className={classNames.legend}>
          <DiscreteColorLegend
            width={width / 2}
            items={transformedData.map((d) => ({
              title: `${d.title} (${d.label === null ? "0%" : d.label})`,
              color: d.color,
              strokeWidth: "12px",
              style: { verticalAlign: "middle" },
            }))}
          />
        </span>
      </div>
    );
  }
}

export default PieChart;

const transformData = (
  data: any[],
  categoryFieldName,
  valueFieldName,
  categoryDisplayType,
  valueDisplayType
): any[] => {
  var result = [],
    total = 0,
    colors = data.length > 5 ? EXTENDED_DISCRETE_COLOR_RANGE : DISCRETE_COLOR_RANGE;

  data && data.forEach((item) => (total += Number(item[valueFieldName])));

  data &&
    data.forEach((item, index) => {
      let category = item[categoryFieldName],
        value = item[valueFieldName];

      let percent = Math.round((value * 100) / total);
      result.push({
        angle: value,
        title: `${getFormatContent(category, categoryDisplayType)}`,
        subTitle: `${getFormatContent(value, valueDisplayType)}`,
        label: percent === 0 ? null : `${percent}%`,
        color: colors && colors.length > index && colors[index],
      });
    });

  return result;
};
