import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  backupDefaultColumns,
  getColumns,
  getCommandBarProps,
  getFilteredIncidentsByProps,
  hasAnyData,
} from "./IncidentsReport.helper";
import {
  onDetailsListColumnClick,
  IDetailsListItemsColumns,
  copyAndSortItems,
} from "../../shared/utilities/tableHelper";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { IIncident, IIncidentView } from "./interfaces";
import IncidentDetails from "./IncidentDetails";
import IncidentList from "./IncidentList";
import AppModuleHeader from "../common/AppModuleHeader";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { Announced } from "@fluentui/react/lib/Announced";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { errorType } from "../interfaces";
import { actionCreator as appActionCreator } from "../../app/duck";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { TimeSelectorValue } from "../../shared/components/TimeSelector";
import { appInsights } from "../../app/telemetryHelper";
import classNames from "./IncidentsReport.module.scss";
import { ISystemMessage } from "../../app/interfaces";
import { SystemMessages } from "../common/SystemMessages";
import IncidentsReportCopilot from "./IncidentsReportCopilot";

export interface MatchParams {
  viewId?: string;
  incidentId?: string;
  searchText?: string;
}

export interface IIncidentsReportStateProps {
  userLoggedIn: boolean;
  loading: boolean;
  incidents: IIncident[];
  filteredIncidents: IIncident[];
  selectedIncident: IIncident;
  selectedIncidentIndex: number;
  selectedFilters: string[];
  isSmallScreen: boolean;
  incidentsError: string;
  filteredIncidentIds: string[];
  selectedIncidentTime: TimeSelectorValue;
  isSortedDescending: boolean;
  sortedColumnKey: string;
  selectedViewId: string;
  selectedView: IIncidentView;
  incidentViews: IIncidentView[];
  loadingTiles: boolean;
  systemMessages: ISystemMessage[];
  searchText: string;
  selectedIncidentViewId: string;
  defaultColumns: IColumn[];
}

export interface IIncidentsReportDispatchProps {
  loadIncidentViews: (incidentId?: string, incidentViewId?: string, searchText?: string, refreshData?: boolean) => void;
  selectIncident: (incident: IIncident | number) => void;
  filterIncidents: (incidents: IIncident[]) => void;
  unselectIncident: () => void;
  loadIncidentDetails: (incident: IIncident, refreshData?: boolean) => void;
  selectLeftNavLinkByUrl: (url: string) => void;
  selectIncidentTime: (timeValue: TimeSelectorValue) => void;
  changeIncidents: (incidents: IIncident[], isSortedDescending: boolean, sortedColumnKey: string) => void;
  downloadIncidentsDataToExcel: (data: any[], incidentViewId: string) => void;
  loadSystemMessages: () => void;
  loadIncidentOpenAiSummaries: (incidentIds: string[]) => void;
  loadIncidentViewDefaultColumns: () => void;
}

export interface IIncidentsReportState extends IDetailsListItemsColumns {
  systemMessages: ISystemMessage[];
}

export interface IIncidentsReportProps
  extends IIncidentsReportStateProps,
    IIncidentsReportDispatchProps,
    RouteComponentProps<MatchParams> {
  displayAsReport?: boolean;
}

export class IncidentsReport extends React.Component<IIncidentsReportProps, IIncidentsReportState> {
  constructor(props: IIncidentsReportProps) {
    super(props);

    this.state = {
      columns: getColumns(this.onColumnClick, props.isSortedDescending, props.sortedColumnKey, props.selectedView),
      items: props.incidents,
      systemMessages: null,
      announcedMessage: "",
    };
  }

  componentDidMount() {
    const {
      userLoggedIn,
      incidents,
      loadIncidentViews,
      selectedIncident,
      unselectIncident,
      selectLeftNavLinkByUrl,
      loadSystemMessages,
      systemMessages,
      selectedViewId,
      defaultColumns,
      displayAsReport,
      loadIncidentViewDefaultColumns,
      match,
    } = this.props;

    if (!defaultColumns?.length) {
      loadIncidentViewDefaultColumns();
    }

    if (userLoggedIn) {
      let incidentViewId = match?.params?.viewId || selectedViewId,
        incidentId = match?.params?.incidentId,
        searchText = match?.params?.searchText,
        selectedIncidentId = selectedIncident && selectedIncident.incidentId;

      incidentViewId = incidentViewId === "incident" ? null : incidentViewId;

      if (!incidents || (incidentId && !selectedIncident) || Number(incidentId) !== Number(selectedIncidentId)) {
        if (!displayAsReport) {
          if (window.location.hash.substring(1) === leftNavUrls.all.financialIncidentsMonthEnd) {
            selectLeftNavLinkByUrl(leftNavUrls.all.financialIncidentsMonthEnd);
          } else {
            selectLeftNavLinkByUrl(leftNavUrls.all.financialIncidents);
          }
        }
        loadIncidentViews(incidentId, incidentViewId, searchText);
      } else if (incidentViewId !== selectedViewId) {
        loadIncidentViews(incidentId, incidentViewId, searchText);
      }

      if (!incidentId && selectedIncident) {
        unselectIncident();
      }

      if (incidentId) {
        appInsights.trackPageView({ name: "IncidentDetailPage", properties: { incidentId } });
      } else {
        appInsights.trackPageView({ name: "IncidentHomePage", properties: { incidentViewId } });
      }

      if (!systemMessages) {
        loadSystemMessages();
      }
    }
  }

  componentWillReceiveProps(nextProps: IIncidentsReportProps) {
    if (nextProps.incidents?.length && this.state.items !== nextProps.incidents) {
      let sortedColumn = this.state.columns?.find((column) => column.fieldName === nextProps.sortedColumnKey),
        items = copyAndSortItems(
          nextProps.incidents,
          nextProps.sortedColumnKey,
          nextProps.isSortedDescending,
          sortedColumn && (sortedColumn["displayType"] || sortedColumn["data"])
        );

      this.setState({
        items,
      });

      this.props.changeIncidents(items, nextProps.isSortedDescending, nextProps.sortedColumnKey);
    }

    if (
      this.props.selectedView?.showOpenAiSummary &&
      nextProps.filteredIncidentIds?.length &&
      this.props.filteredIncidentIds !== nextProps.filteredIncidentIds
    ) {
      this.props.loadIncidentOpenAiSummaries(nextProps.filteredIncidentIds);
    }

    if (
      this.props.selectedViewId !== nextProps.selectedViewId ||
      this.props.defaultColumns !== nextProps.defaultColumns ||
      this.props.defaultColumns?.length !== nextProps.defaultColumns?.length
    ) {
      this.setState({
        columns: getColumns(
          this.onColumnClick,
          nextProps.isSortedDescending,
          nextProps.sortedColumnKey,
          nextProps.selectedView,
          nextProps.defaultColumns
        ),
      });

      appInsights.trackPageView({
        name: "IncidentHomePage",
        properties: { incidentViewId: nextProps.selectedViewId, prevIncidentViewId: this.props.selectedViewId },
      });
    }

    if (this.props.systemMessages !== nextProps.systemMessages) {
      let systemMessages = nextProps.systemMessages.filter((message) => message.pageName === "IncidentHomePage");
      this.setState({ systemMessages });
    }
  }

  render() {
    const {
      loading,
      selectedIncident,
      selectedIncidentTime,
      incidentsError,
      history,
      loadIncidentDetails,
      selectIncidentTime,
      isSmallScreen,
      selectedView,
      loadingTiles,
      searchText,
      selectedIncidentViewId,
      defaultColumns,
      displayAsReport,
      downloadIncidentsDataToExcel,
    } = this.props;

    const { columns, items, systemMessages, announcedMessage } = this.state;

    const filteredIncidents = getFilteredIncidentsByProps(this.props, items);

    const finalColumns = columns?.filter(
      (column) =>
        !column["hideIfNoData"] || hasAnyData(column["dependentFieldName"] || column.fieldName, filteredIncidents)
    );

    const headerItems: IBreadcrumbItem[] = [];

    const isIncidentSelected = !!selectedIncident && window.location.hash.endsWith(selectedIncident.incidentId);

    isIncidentSelected &&
      headerItems.push({ text: "Incident " + selectedIncident.incidentId, key: selectedIncident.incidentId });

    const rootClassName = displayAsReport ? "" : classNames.appModuleContent;

    return (
      <div className={rootClassName}>
        {!displayAsReport && (
          <AppModuleHeader
            breadcrumbItems={headerItems}
            onBreadcrumbClick={selectedView && this.onBreadcrumbClick}
            commandBarProps={getCommandBarProps(this.props, filteredIncidents)}
            messageContent={<SystemMessages systemMessages={systemMessages} />}
          />
        )}
        {selectedView?.description && <div className={classNames.description}>{selectedView.description}</div>}
        {incidentsError && <div className={classNames.error}>{incidentsError}</div>}
        {/* Load the incident details page if a specific incident is selected */}
        {!incidentsError && isIncidentSelected && <IncidentDetails incident={selectedIncident} />}
        {!incidentsError && !isIncidentSelected && (
          <>
            <IncidentList
              incidents={filteredIncidents}
              columns={finalColumns}
              incidentsError={incidentsError}
              loading={loading}
              history={history}
              startTime={selectedIncidentTime}
              loadIncidentDetails={loadIncidentDetails}
              onTimeSelection={selectIncidentTime}
              isSmallScreen={isSmallScreen}
              view={selectedView}
              onSortOrderChange={this.onSortOrderChange}
              loadingTiles={loadingTiles}
              searchText={searchText}
              selectedIncidentViewId={selectedIncidentViewId}
              defaultColumns={defaultColumns || backupDefaultColumns}
              downloadIncidentsDataToExcel={downloadIncidentsDataToExcel}
              displayAsReport={displayAsReport}
            />
            {announcedMessage && <Announced message={announcedMessage} />}
            <IncidentsReportCopilot incidents={items} />
          </>
        )}
      </div>
    );
  }

  onBreadcrumbClick = () => {
    const { unselectIncident, history, selectedViewId } = this.props;

    unselectIncident();
    history.push(`${leftNavUrls.all.financialIncidents}/${selectedViewId}`);
  };

  onColumnClick = (ev: React.MouseEvent, column: IColumn) => {
    const { columns, items, isSortedDescending, sortedColumnKey } = onDetailsListColumnClick(column, this.state);

    this.setState({
      columns,
      items,
      announcedMessage: `Column ${column.name} is sorted ${isSortedDescending ? "descending" : "ascending"}`,
    });

    this.props.changeIncidents(items, isSortedDescending, sortedColumnKey);
  };

  onSortOrderChange = (key: string) => {
    let selectedColumn = this.state.columns.find((column) => column.key === key);
    this.onColumnClick(null, selectedColumn);
  };
}

const mapStateToProps = (state: IState): IIncidentsReportStateProps => ({
  userLoggedIn: !!state.app.login_user,
  loading: state.modules.loading_incidents || state.modules.loading_incident_views || state.modules.loading_incident,
  isSmallScreen: state.app.is_small_screen,
  incidents: state.modules.incidents,
  filteredIncidents: state.modules.filtered_incidents,
  filteredIncidentIds: state.modules.filtered_incident_ids,
  incidentsError: state.modules.errors[errorType.incidents],
  selectedFilters: state.modules.selected_incident_filters,
  selectedIncidentIndex: state.modules.selected_incident_index,
  selectedIncident:
    state.modules.selected_incident_id &&
    state.modules.incidents.find((i) => i.incidentId.toString() === state.modules.selected_incident_id.toString()),
  selectedIncidentTime: state.modules.selected_incident_time,
  isSortedDescending: state.modules.incidents_sorted_descending,
  sortedColumnKey: state.modules.incidents_sorted_column_key,
  selectedViewId: state.modules.selected_incident_view_id,
  selectedView:
    state.modules.incident_views &&
    state.modules.incident_views.find((view) => view.id === state.modules.selected_incident_view_id),
  incidentViews: state.modules.incident_views,
  loadingTiles: state.modules.loading_incident_tiles,
  systemMessages: state.app.system_messages,
  searchText: state.modules.incident_view_search_text,
  selectedIncidentViewId: state.modules.selected_incident_view_id,
  defaultColumns: state.modules.incidents_default_columns,
});

const mapDispatchToProps: IIncidentsReportDispatchProps = {
  loadIncidentViews: actionCreator.loadIncidentViews,
  loadIncidentDetails: actionCreator.loadIncidentDetails,
  loadIncidentOpenAiSummaries: actionCreator.loadIncidentOpenAiSummaries,
  selectIncident: actionCreator.selectIncident,
  filterIncidents: actionCreator.filterIncidents,
  unselectIncident: actionCreator.unselectIncident,
  selectIncidentTime: actionCreator.selectIncidentTime,
  changeIncidents: actionCreator.changeIncidents,
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  loadSystemMessages: appActionCreator.loadSystemMessages,
  downloadIncidentsDataToExcel: actionCreator.downloadIncidentsDataToExcel,
  loadIncidentViewDefaultColumns: actionCreator.loadIncidentViewDefaultColumns,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsReport);
