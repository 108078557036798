import React from "react";
import { connect } from "react-redux";
import classNames from "./EntityTiles.module.scss";
import { IState } from "../../reducers/interfaces";
import { IEntityTile, ITile } from "../common/interfaces";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { errorType } from "../interfaces";
import VisualTileList from "../../shared/components/Tile/VisualTileList";
import { getDefaultTileMargin } from "./helper";

export interface IEntityTilesStateProps {
  entityTiles: IEntityTile[];
  loading: boolean;
  selectedEntityType: string;
  error: string;
}

export class EntityTiles extends React.Component<IEntityTilesStateProps> {
  render() {
    const { entityTiles, loading, error, selectedEntityType } = this.props;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading entity tiles..."
        />
      );
    } else if (error) {
      return <div className={classNames.error}>{error}</div>;
    }

    let entityTilesCopy = entityTiles;

    if (selectedEntityType) {
      entityTilesCopy = entityTilesCopy?.filter((e) => e.entityType === selectedEntityType);
    }

    entityTilesCopy = entityTilesCopy?.filter((e) => e.tiles?.length && e.tiles.filter((t) => t.data?.length).length);

    if (!entityTilesCopy || !entityTilesCopy.length) {
      return <div className={classNames.message}>No related tile is available.</div>;
    }

    return (
      <div className={classNames.root}>
        {entityTilesCopy.map((entityTile, index) => {
          // Add default props to the tiles.
          let tiles: ITile[] = entityTile.tiles
            .filter((tile) => tile.data?.length)
            .map((tile) => ({
              ...tile,
              compact: true,
              height: tile.height || "200px",
              visualProps: { height: 165, margin: getDefaultTileMargin(tile), ...tile.visualProps },
            }));

          tiles.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

          return (
            <div className={classNames.entityTile} key={`entityTile-${entityTile.entityType}-${index}`}>
              <div className={classNames.header}>{entityTile.entityType}</div>
              <VisualTileList tiles={tiles} />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  entityTiles: state.modules.entity_tiles,
  loading: state.modules.loading_entity_tiles,
  selectedEntityType: state.modules.search_entity_type,
  error: state.modules.errors[errorType.entityTiles],
});

export default connect(mapStateToProps)(EntityTiles);
