import React from "react";
import classNames from "./ControlSdkDesigner.module.scss";
import { DataForm } from "../../../shared/components/DataForm";
import { getControlSdkActionFields } from "./MyControlEdit.helper";

export interface IControlSdkInputEditorProps {
  control: any;
  controlSdk: any;
}

export const ControlSdkActionEditor = (props: IControlSdkInputEditorProps) => {
  const { control, controlSdk } = props;
  return (
    <div className={classNames.panelContent}>
      <DataForm fields={getControlSdkActionFields(control, controlSdk)} context={{}} />
    </div>
  );
};

export default ControlSdkActionEditor;
