import React, { useEffect, useState } from "react";
import classNames from "./ControlSearchModal.module.scss";
import {
  CheckboxVisibility,
  CommandBar,
  Dropdown,
  IColumn,
  IDropdownOption,
  IModalProps,
  Icon,
  Modal,
  PrimaryButton,
  SelectionMode,
  Spinner,
} from "@fluentui/react";
import {
  ControlSearchType,
  getControlSearchTypeOptions,
  getStatusOptions,
  controlResultColumns,
  controlLineItemColumns,
  processLineItems,
} from "./ControlSearchModal.helper";
import { DateRangePicker } from "../../shared/components/DateRangePicker/DateRangePicker";
import { downloadDataToExcel, fetchControlAllLineItems, fetchControlAllResults } from "../../api/radarApi";
import moment from "moment";
import TileDetailsList from "../../shared/components/Tile/TileDetailsList";
import { getExcelDownloadCommandBarItem } from "../common/helper";

export interface IControlSearchModalProps extends IModalProps {
  controlId: string;
  controlTitle: string;
  lineItemColumns?: IColumn[];
  onDismss?: () => void;
}

export const ControlSearchModal = (props: IControlSearchModalProps) => {
  const { isOpen, controlId, controlTitle, lineItemColumns, onDismiss } = props;
  const [items, setItems] = useState<any[]>();
  const [columns, setColumns] = useState<IColumn[]>();
  const [selectedSearchType, setSelectedSearchType] = useState<ControlSearchType>(ControlSearchType.ControlLineItem);
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingExcelData, setLoadingExcelData] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const hasDateFilter = startDate && endDate;
  const title = selectedSearchType === ControlSearchType.ControlResult ? "Control Results" : "Control Line Items";

  useEffect(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setItems(undefined);
    setColumns(undefined);
  }, [isOpen]);

  const onDownloadDataToExcel = (items, worksheetName) => {
    setLoadingExcelData(true);
    downloadDataToExcel(items, worksheetName)
      .then(() => console.log("Control search data download success."))
      .catch((error) => {})
      .finally(() => setLoadingExcelData(false));
  };
  const commandBarItems = !loadingExcelData
    ? [getExcelDownloadCommandBarItem(onDownloadDataToExcel, items, title)]
    : undefined;

  const onSearchTypeChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    setSelectedSearchType(item.key as ControlSearchType);
  };

  const onStatusChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    setSelectedStatus(item.key as string);
  };

  const onStartDateChange = (newDate: Date) => {
    setStartDate(newDate);
  };

  const onEndDateChange = (newDate: Date) => {
    setEndDate(newDate);
  };

  const getFilters = () => ({
    startDate: moment(startDate).format("yyyyMMDD"),
    endDate: moment(endDate).format("yyyyMMDD"),
    status: selectedStatus,
  });

  const onSearchClick = () => {
    setLoadingData(true);
    setError(undefined);

    if (selectedSearchType === ControlSearchType.ControlResult) {
      fetchControlAllResults(controlId, getFilters())
        .then((result: any) => {
          setItems(result);
          setColumns(controlResultColumns);
        })
        .catch((error) => setError(error))
        .finally(() => setLoadingData(false));
    } else if (selectedSearchType === ControlSearchType.ControlLineItem) {
      fetchControlAllLineItems(controlId, getFilters())
        .then((result: any) => {
          let lineItems = processLineItems(result);
          setItems(lineItems);

          let finalLineItemColumns = controlLineItemColumns.concat(lineItemColumns);
          setColumns(finalLineItemColumns);
        })
        .catch((error) => setError(error))
        .finally(() => setLoadingData(false));
    }
  };

  return (
    <Modal className={classNames.modal} isOpen={isOpen}>
      <div className={classNames.contentRoot}>
        <div className={classNames.title}>
          <span>
            Search Control Info for <b>{controlTitle}</b>
          </span>
          <Icon
            className={classNames.closeIcon}
            iconName="ChromeClose"
            onClick={onDismiss}
            title="Close this modal window"
          />
        </div>
        <div className={classNames.actions}>
          <Dropdown
            className={classNames.searchTypeDropdown}
            label="Search Type"
            options={getControlSearchTypeOptions()}
            selectedKey={selectedSearchType}
            onChange={onSearchTypeChange}
          />
          <DateRangePicker
            className={classNames.dateFilter}
            label="Date Range"
            startDate={startDate}
            endDate={endDate}
            forceRangeSelection
            hideDateDropdowns={true}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
          <Dropdown
            className={classNames.statusDropdown}
            options={getStatusOptions()}
            label="Status"
            selectedKey={selectedStatus}
            onChange={onStatusChange}
          />
          <PrimaryButton
            className={classNames.searchButton}
            text="Search"
            disabled={!hasDateFilter}
            onClick={onSearchClick}
          />
          {!!items?.length && (
            <div className={classNames.commandBarPane}>
              {loadingExcelData && <Spinner className={classNames.excelSpinner} />}
              <CommandBar
                className={classNames.commandBar}
                items={commandBarItems}
                styles={{ root: { minWidth: 100 } }}
              />
            </div>
          )}
        </div>
        <div className={classNames.content}>
          {loadingData && <Spinner className={classNames.spinner} label="Loading data..." />}
          {!loadingData && error && <div className={classNames.error}>{error}</div>}
          {!loadingData && !error && !hasDateFilter && !items?.length && (
            <div className={classNames.info}>
              The required <b>Date Range</b> filter is not defined yet. Please select the date range and click the
              Search button.
            </div>
          )}
          {!loadingData && !error && hasDateFilter && !items?.length && (
            <div className={classNames.info}>No item is found. Please change the date range and try again.</div>
          )}
          {!loadingData && !error && hasDateFilter && !!items?.length && (
            <TileDetailsList
              title={title}
              className={classNames.table}
              commandBarItemKeys={[]}
              showSearchBox
              classNameForDetailsList={classNames.list}
              items={items}
              columns={columns}
              compact={false}
              selectionMode={SelectionMode.none}
              checkboxVisibility={CheckboxVisibility.hidden}
              noItemText={""}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ControlSearchModal;
