export interface IColorRgb {
  r: number;
  g: number;
  b: number;
}

export const getColor = (
  startColor: string,
  endColor: string,
  startValue: number,
  endValue: number,
  targetValue: number
): string => {
  let startRgb = hexToRgb(startColor),
    endRgb = hexToRgb(endColor),
    r = getPrimeColorNumber(startRgb.r, endRgb.r, startValue, endValue, targetValue),
    g = getPrimeColorNumber(startRgb.g, endRgb.g, startValue, endValue, targetValue),
    b = getPrimeColorNumber(startRgb.b, endRgb.b, startValue, endValue, targetValue);

  return rgbToHex(r, g, b);
};

export const getPrimeColorNumber = (
  startColor: number,
  endColor: number,
  startValue: number,
  endValue: number,
  targetValue: number
): number => {
  return (
    Math.min(endColor, startColor) +
    Math.round(Math.abs(((endColor - startColor) * targetValue) / (endValue - startValue)))
  );
};

export const hexToRgb = (hex: string): IColorRgb => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbToHex = (r: number, g: number, b: number): string =>
  "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

export const StandardColorStyles = {
  blueDark: {
    backgroundColor: "rgb(91,155,213)",
    borderColor: "rgb(65,113,156)",
    color: "white",
  },
  blueLight: {
    backgroundColor: "rgb(177,203,233)",
    borderColor: "rgb(91,155,213)",
    color: "black",
  },
  greenDark: {
    backgroundColor: "rgb(112,173,71)",
    borderColor: "rgb(80,126,50)",
    color: "white",
  },
  greenLight: {
    backgroundColor: "rgb(181, 213,167)",
    borderColor: "rgb(112,173,71)",
    color: "black",
  },
  orangeDark: {
    backgroundColor: "rgb(237, 125, 49)",
    borderColor: "rgb(147, 90, 33)",
    color: "white",
  },
  orangeLight: {
    backgroundColor: "rgb(247,189,164)",
    borderColor: "rgb(237,125,49)",
    color: "black",
  },
  yellowDark: {
    backgroundColor: "rgb(255,192,0)",
    borderColor: "rgb(188,140,0)",
    color: "white",
  },
  yellowLight: {
    backgroundColor: "rgb(255,221,156)",
    borderColor: "rgb(255,192,0)",
    color: "black",
  },
};

export const getColorFromText = (text: string) => {
  let hash = 0;
  text.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, "0");
  }
  return color;
};
