import React from "react";
import { DataForm } from "../../../shared/components/DataForm";
import { getControlSdkOutputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";

export interface IControlSdkOutputEditorProps {
  control: any;
  controlSdk: any;
}

export const ControlSdkOutputEditor = (props: IControlSdkOutputEditorProps) => {
  const { control, controlSdk } = props;
  return (
    <div className={classNames.panelContent}>
      <DataForm fields={getControlSdkOutputFields(control, controlSdk)} context={control?.controlSdkSettings} />
    </div>
  );
};

export default ControlSdkOutputEditor;
