import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyChecklistList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListStateProps,
  IConfigItemListDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";
import { IState } from "../../../reducers/interfaces";
import { errorType } from "../../interfaces";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IconButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import ConfirmModal from "../../common/ConfirmModal";
import ConfirmParametersModal from "../../common/ConfirmParametersModal";
import classNames from "../common/ConfigItemList.module.scss";
import { IChecklistDevOpsItem, IChecklist, ParameterDataType } from "../../common/interfaces";

export interface IMyChecklistListStateProps extends IConfigItemListStateProps {
  generatingWorkItems: boolean;
  generatedWorkItem: IChecklistDevOpsItem;
  workItemsError: string;
}

export interface IMyChecklistListDispatchProps extends IConfigItemListDispatchProps {
  generateDevOpsWorkItems: (checklistId: string, parameters?: object) => void;
}

export interface IMyChecklistListProps
  extends IConfigItemListBaseProps,
    IMyChecklistListStateProps,
    IMyChecklistListDispatchProps {}

export interface IMyChecklistListState {
  showGenerateWorkItemsModal: boolean;
  selectedItemId: string;
  parameterValues: object;
  generateWorkItemsError: string;
}

export class MyChecklistList extends React.Component<IMyChecklistListProps, IMyChecklistListState> {
  state = {
    showGenerateWorkItemsModal: false,
    selectedItemId: null,
    parameterValues: {},
    generateWorkItemsError: null,
  };

  render() {
    const { generatingWorkItems, generatedWorkItem, workItemsError, configItems } = this.props;
    const { showGenerateWorkItemsModal, selectedItemId, generateWorkItemsError } = this.state;

    let statusContent = null;

    if (generatingWorkItems) {
      statusContent = (
        <div className={classNames.spinnerPane}>
          <Spinner
            styles={{
              root: classNames.spinner,
              circle: classNames.spinnerCircle,
              label: classNames.spinnerLabel,
            }}
            label="Generating work items..."
          />
        </div>
      );
    } else if (workItemsError) {
      statusContent = <div className={classNames.error}>{workItemsError}</div>;
    } else if (generatedWorkItem) {
      statusContent = (
        <div className={classNames.successMessage}>
          <Icon iconName="SkypeCircleCheck" className={classNames.successIcon} />
          Deliverable{" "}
          <a href={`https://microsoft.visualstudio.com/OSGS/_workitems/edit/${generatedWorkItem.deliverableId}`}>
            {generatedWorkItem.deliverableId}
          </a>{" "}
          generated successfully for checklist {generatedWorkItem.checklistId}.
        </div>
      );
    }

    let selectedChecklist: IChecklist = configItems && configItems.find((item) => item.id === selectedItemId),
      parameters = [];

    if (selectedChecklist) {
      if (selectedChecklist.parameters && selectedChecklist.parameters.length) {
        parameters = selectedChecklist.parameters;
      }

      if (selectedChecklist.useAnchorDate) {
        parameters.push({ prompt: "Anchor Date", name: "anchorDate", dataType: ParameterDataType.date });
      }
    }

    return (
      <div>
        <ConfigItemList
          {...this.props}
          columns={getColumns(this.props, classNames)}
          configItemName="Checklist"
          appInsightsPageName="MyChecklistListPage"
          leftNavUrl={leftNavUrls.management.checklist}
          getCustomActions={(item) => this.getChecklistActions(item.id)}
          statusContent={statusContent}
        />
        {showGenerateWorkItemsModal &&
          (parameters && parameters.length ? (
            <ConfirmParametersModal
              content={`Please fill in the follow parameters to generate new DevOps work items based on this checklist.`}
              parameters={parameters}
              onParameterChange={this.onParameterChange}
              error={generateWorkItemsError}
              onCommit={this.onGenerateWorkItems}
              onCancel={() =>
                this.setState({
                  showGenerateWorkItemsModal: false,
                  selectedItemId: null,
                  generateWorkItemsError: null,
                  parameterValues: {},
                })
              }
            />
          ) : (
            <ConfirmModal
              content={`Are you sure you want to generate new DevOps work items based on this checklist?`}
              onCommit={this.onGenerateWorkItems}
              onCancel={() => this.setState({ showGenerateWorkItemsModal: false, selectedItemId: null })}
            />
          ))}
      </div>
    );
  }

  getChecklistActions = (itemId: string): JSX.Element => {
    const { isStaging } = this.props;
    return (
      !isStaging && (
        <IconButton
          key={"generateWIAction" + itemId}
          iconProps={{
            iconName: "WorkItem",
          }}
          aria-label="Generate Work Items"
          className={classNames.itemAction}
          title={`Generate Work Items`}
          tabIndex={0}
          onClick={() => this.onGenerateWorkItemsClick(itemId)}
          onKeyUp={(ev) => ev.which === 13 && this.onGenerateWorkItemsClick(itemId)}
        />
      )
    );
  };

  onGenerateWorkItemsClick = (itemId: string) =>
    this.setState({ showGenerateWorkItemsModal: true, selectedItemId: itemId, generateWorkItemsError: null });

  onGenerateWorkItems = () => {
    const { generateDevOpsWorkItems, configItems } = this.props;
    const { selectedItemId, parameterValues } = this.state;

    let selectedChecklist: IChecklist = configItems && configItems.find((item) => item.id === selectedItemId),
      parameters = selectedChecklist && selectedChecklist.parameters,
      parameterValueKeys = Object.keys(parameterValues),
      useAnchorDate = selectedChecklist && selectedChecklist.useAnchorDate;

    if ((parameters && parameters.length) || useAnchorDate) {
      let nonEmptyValues = parameterValueKeys.filter((key) => !!parameterValues[key]),
        expectedParameterCount = ((parameters && parameters.length) || 0) + (useAnchorDate ? 1 : 0);

      if (expectedParameterCount !== nonEmptyValues.length) {
        this.setState({ generateWorkItemsError: "Missing parameter values.  Please fill in all parameters." });
        return;
      }
    }

    generateDevOpsWorkItems(selectedItemId, parameterValues);
    this.setState({
      showGenerateWorkItemsModal: false,
      selectedItemId: null,
      generateWorkItemsError: null,
      parameterValues: {},
    });
  };

  onParameterChange = (parameterValues) => {
    this.setState({ parameterValues, generateWorkItemsError: null });
  };
}

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  generatingWorkItems: state.modules.generating_devops_workitems,
  generatedWorkItem: state.modules.generated_devops_workitem,
  workItemsError: state.modules.errors[errorType.devOpsWorkItems],
});

const mapDispatchToProps: IMyChecklistListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadChecklistConfigItems,
  deleteConfigItem: actionCreator.deleteChecklistConfigItem,
  publishConfigItem: actionCreator.publishChecklistConfigItem,
  unpublishConfigItem: actionCreator.unpublishChecklistConfigItem,
  generateDevOpsWorkItems: actionCreator.generateDevOpsWorkItems,
  revertConfigItem: actionCreator.revertChecklistConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyChecklistList);
