import React from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import classNames from "./GraphSearch.module.scss";
import { searchDateType } from "../interfaces";
import moment from "moment";
import { IEntityView } from "../common/interfaces";

export interface IGraphSearchDateFilterProps {
  selectedDateType: string;
  startTime: Date;
  endTime: Date;
  selectedEntityView: IEntityView;
  changeSearchEntityStartTime: (startTime: Date) => void;
  changeSearchEntityEndTime: (endTime: Date) => void;
  changeSearchEntityMonth: (date: Date) => void;
  changeSearchEntityDateType: (dateType: string) => void;
}

export const GraphSearchDateFilter = ({
  selectedDateType,
  startTime,
  endTime,
  selectedEntityView,
  changeSearchEntityDateType,
  changeSearchEntityStartTime,
  changeSearchEntityEndTime,
  changeSearchEntityMonth,
}: IGraphSearchDateFilterProps) => {
  const dateTypeOptions = Object.values(searchDateType)
    .filter((value) => {
      return value === searchDateType.thisMonth || value === searchDateType.lastMonth;

      // ***** The following codes are temporarily disabled due to design changes to pull metrics from metric snapshot.
      // if (selectedEntityView?.useMonthPicker) {
      //   return (
      //     value === searchDateType.thisMonth ||
      //     value === searchDateType.lastMonth ||
      //     value === searchDateType.customMonth
      //   );
      // } else {
      //   return (
      //     value === searchDateType.thisMonth ||
      //     value === searchDateType.lastMonth ||
      //     value === searchDateType.last7Days ||
      //     value === searchDateType.customDate
      //   );
      // }
    })
    .map((value) => {
      const text =
        value === searchDateType.thisMonth
          ? `${value} (${moment().format("MMMM YYYY")})`
          : value === searchDateType.lastMonth
          ? `${value} (${moment().date(0).format("MMMM YYYY")})`
          : value;

      return {
        key: value,
        text: text,
        ariaLabel: text,
      };
    });

  return (
    <>
      <Dropdown
        ariaLabel={selectedEntityView?.datePickerLabel || "Entity Timestamp"}
        label={selectedEntityView?.datePickerLabel || "Entity Timestamp"}
        className={classNames.dateTypeDropdown}
        options={dateTypeOptions}
        selectedKey={selectedDateType}
        onChange={(ev, option) => changeSearchEntityDateType(option.key as searchDateType)}
      />
      {/* The following codes are temporarily disabled due to design changes to pull metrics from metric snapshot. */
      /*
      {selectedDateType === searchDateType.customDate && (
        <>
          <DatePicker
            ariaLabel="Start Time"
            label="Start Time"
            className={classNames.dateField}
            value={new Date(startTime.getUTCFullYear(), startTime.getUTCMonth(), startTime.getUTCDate())}
            maxDate={endTime}
            onSelectDate={(date) => changeSearchEntityStartTime(date)}
          />
          <span className={classNames.dateText}>to</span>
          <DatePicker
            ariaLabel="End Time"
            label="End Time"
            className={classNames.dateField}
            value={new Date(endTime.getUTCFullYear(), endTime.getUTCMonth(), endTime.getUTCDate())}
            minDate={startTime}
            onSelectDate={(date) => changeSearchEntityEndTime(date)}
          />
        </>
      )}
      {selectedDateType === searchDateType.customMonth && (
        <DatePicker
          ariaLabel="Select Month"
          className={classNames.dateField}
          value={startTime}
          formatDate={(date) => moment(date).format("MMMM YYYY")}
          calendarProps={{
            strings: null,
            isDayPickerVisible: false,
            isMonthPickerVisible: true,
            showMonthPickerAsOverlay: true,
          }}
          onSelectDate={(date) => changeSearchEntityMonth(date)}
        />
      )} */}
    </>
  );
};
