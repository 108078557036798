import { IAnomalyTest } from "../../common/interfaces";
import { IField, FieldType } from "../../../shared/components/DataForm";

export const defaultAnomalyTest: IAnomalyTest = {
  id: "new",
};

export const getFields = (anomalyTest: IAnomalyTest): IField[] => {
  if (!anomalyTest) return null;

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "Control ID",
      value: anomalyTest.id,
    },
    {
      fieldName: "name",
      label: "Test Name",
      value: anomalyTest.name,
      lastInRow: true,
      required: true,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: anomalyTest.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      fieldType: FieldType.textArray,
      label: "Editors",
      value: anomalyTest.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: anomalyTest.description,
      width: "100%",
    },
    {
      fieldName: "statistic",
      fieldType: FieldType.enumeration,
      label: "Statistical Test",
      options: [
        { key: "SD", text: "Standard Deviation" },
        { key: "MAD", text: "Median Absolute Deviation" },
        { key: "FT", text: "Fixed Threshold" },
      ],
      value: anomalyTest.statistic,
      width: "240px",
      fillWidth: false,
      lastInRow: true,
    },
    {
      fieldName: "inputVariables",
      fieldType: FieldType.enumeration,
      label: "Input Variables",
      options: getDropdownOptions([
        "BillingGroupID",
        "SalesMotion",
        "TransactionType",
        "Market",
        "BillingCurrency",
        "ProductID",
        "SKU",
        "Audience",
        "Cloud",
        "$0 or Paid",
      ]),
      value: anomalyTest.inputVariables,
      multiSelect: true,
      width: "360px",
    },
    {
      fieldName: "observationValue",
      fieldType: FieldType.enumeration,
      label: "Observation Variable",
      options: getDropdownOptions([
        "Quantity",
        "ListPrice",
        "DiscountedPrice",
        "ProratedPrice",
        "Price",
        "ExtendedPrice",
        "PurchaseTermUnits",
      ]),
      value: anomalyTest.observationValue,
      width: "160px",
      fillWidth: false,
      lastInRow: true,
    },
    {
      fieldName: "frequency",
      fieldType: FieldType.enumeration,
      label: "Frequency",
      options: getDropdownOptions(["One Time", "Weekly", "Monthly", "Annually"]),
      value: anomalyTest.frequency,
      width: "100px",
    },
    {
      fieldName: "controlStatus",
      fieldType: FieldType.enumeration,
      label: "Status",
      options: getDropdownOptions(["Analysis", "Production"]),
      value: anomalyTest.controlStatus,
      width: "160px",
    },
    {
      fieldName: "controlThreshold",
      fieldType: FieldType.number,
      label: "Tolerence",
      value: anomalyTest.controlThreshold,
      valueWidth: "40px",
    },
  ];
};

const getDropdownOptions = (values: string[]) => values.map((value) => ({ key: value, text: value, ariaLabel: value }));
