import React from "react";
import { Calendar } from "@fluentui/react";
import classNames from "./DateRangePicker.module.scss";
import DateSelector from "./DateSelector";

export interface IDateRangeCalloutContentProps {
  startDate?: Date;
  endDate?: Date;
  forceRangeSelection?: boolean; // Force selection of both start and end date always.
  hideDateDropdowns?: boolean;
  onStartDateChange?: (newDate: Date) => void;
  onEndDateChange?: (newDate: Date) => void;
}

export const DateRangeCalloutContent = (props: IDateRangeCalloutContentProps) => {
  const { startDate, endDate, forceRangeSelection, hideDateDropdowns, onStartDateChange, onEndDateChange } = props;

  const onSelectDate = (
    prevDate: Date,
    newDate: Date,
    otherDate: Date,
    onDateChange: (newDate: Date) => void,
    onOtherDateChange: (newDate: Date) => void
  ) => {
    // Unselect the date if same date is selected.
    var dateToChange = Number(prevDate) === Number(newDate) ? undefined : newDate;
    onDateChange && onDateChange(dateToChange);

    if (forceRangeSelection && dateToChange && !otherDate) {
      onOtherDateChange(dateToChange);
    }
  };

  return (
    <div className={classNames.calloutPane}>
      <div className={classNames.calendarPane}>
        {!hideDateDropdowns && (
          <div className={classNames.dateSelection}>
            <span className={classNames.label}>Start</span>
            <DateSelector date={startDate} onDateChange={onStartDateChange} />
          </div>
        )}
        <Calendar
          isMonthPickerVisible={false}
          showGoToToday={false}
          value={startDate}
          maxDate={endDate}
          onSelectDate={(newDate) => onSelectDate(startDate, newDate, endDate, onStartDateChange, onEndDateChange)}
        />
      </div>
      <div className={classNames.calendarPane}>
        {!hideDateDropdowns && (
          <div className={classNames.dateSelection}>
            <span className={classNames.label}>End</span>
            <DateSelector date={endDate} onDateChange={onEndDateChange} />
          </div>
        )}
        <Calendar
          isMonthPickerVisible={false}
          showGoToToday={false}
          value={endDate}
          minDate={startDate}
          onSelectDate={(newDate) => onSelectDate(endDate, newDate, startDate, onEndDateChange, onStartDateChange)}
        />
      </div>
    </div>
  );
};

export default DateRangeCalloutContent;
