import React from "react";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { IIncident } from "./interfaces";
import { SelectionMode, IColumn, DetailsList } from "@fluentui/react/lib/DetailsList";
import classNames from "./IncidentDetails.module.scss";

export interface IIncidentLinkedItemsProps {
  incident: IIncident;
}

class IncidentLinkedItems extends React.Component<IIncidentLinkedItemsProps> {
  render() {
    const { incident } = this.props;
    const { linkedItems } = incident;

    if (!linkedItems || !linkedItems.length) {
      return null;
    }

    return (
      <Tile title="Linked Items" commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <DetailsList items={linkedItems} columns={this.getColumns(incident)} selectionMode={SelectionMode.none} />
      </Tile>
    );
  }

  getColumns = (incident: IIncident): IColumn[] => [
    {
      key: "status",
      name: "Status",
      fieldName: "Status",
      minWidth: 60,
      maxWidth: 70,
      isResizable: true,
    },
    {
      key: "incidentId",
      name: "Incident ID",
      fieldName: "Id",
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => (
        <a
          title={`Click here to go to the IcM site for incident ${item.Id}`}
          className={classNames.buttonAsHyperlink}
          aria-label={`Click here to go to the IcM site for incident ${item.Id}`}
          href={`https://portal.microsofticm.com/imp/v3/incidents/details/${item.Id}/home`}
          target="_blank'"
          tabIndex={0}
        >
          {item.Id}
        </a>
      ),
    },
    {
      key: "relationType",
      name: "Relation",
      fieldName: "relationType",
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
    },
    {
      key: "title",
      name: "Incident Title",
      fieldName: "Title",
      minWidth: 200,
      maxWidth: 840,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: "owningTeamId",
      name: "Owner",
      fieldName: "OwningTeamId",
      minWidth: 240,
      maxWidth: 260,
      isResizable: true,
    },
  ];
}

export default IncidentLinkedItems;
