import React from "react";
import classNames from "./Chat.module.scss";
import { Icon } from "@fluentui/react";

export interface IChatSuggestionProps {
  content: string;
  showIcon?: boolean;
  onSuggestionClick?: (suggestion: string) => void;
}

export const ChatSuggestion = (props: IChatSuggestionProps) => {
  const { content, showIcon, onSuggestionClick } = props;

  return (
    <div className={classNames.suggestionRow}>
      {showIcon && <Icon iconName="Unknown" className={classNames.systemIcon} title="Suggested Questions" />}
      <div
        className={[classNames.contentBox, classNames.suggestion].join(" ")}
        onClick={() => onSuggestionClick(content)}
      >
        {content}
      </div>
    </div>
  );
};

export default ChatSuggestion;
