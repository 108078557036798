import React, { useCallback, useEffect, useState } from "react";
import Tile, { BuiltInCommandBarItemKey, ITileProps } from "./Tile";
import {
  ConstrainMode,
  DetailsList,
  IColumn,
  IDetailsListProps,
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { ReactAnnounce } from "react-announce-a11y";
import classNames from "./TileDetailsList.module.scss";
import { IDetailsListItemsColumns, getSortedItemsColumns, onDetailsListColumnClick } from "../../utilities/tableHelper";

interface ITileDetailsListNewProps {
  classNameForDetailsList?: string;
  noItemText?: string;
  nameForSave?: string;
  disableStickyColumnHeaders?: boolean;
}

export type ITileDetailsListProps = ITileProps & ITileDetailsListNewProps & IDetailsListProps;

export const TileDetailsList = (props: ITileDetailsListProps) => {
  const {
    commandBarItemKeys = [
      BuiltInCommandBarItemKey.showFullScreen,
      BuiltInCommandBarItemKey.toggleContent,
      BuiltInCommandBarItemKey.showHideColumns,
    ],
    classNameForDetailsList,
    items,
    columns,
    noItemText,
    contentHeight = 58 + (items?.length ? Math.min(items.length * 44, 600) : 0) + "px",
    disableStickyColumnHeaders,
  } = props;

  const [selectedColumnKeys, setSelectedColumnKeys] = useState<string>();

  const onColumnClick = useCallback((ev: React.MouseEvent, column: IColumn) => {
    setItemsColumns((ic) => ({
      ...onDetailsListColumnClick(column, ic),
    }));
  }, []);

  const getNewState = useCallback(
    (props, searchText: string = ""): IDetailsListItemsColumns =>
      getSortedItemsColumns(props, onColumnClick, searchText),
    [onColumnClick]
  );

  const onSearchChange = (newValue) => setItemsColumns(getNewState(props, newValue));

  const onColumnSelectionChange = (selectedKeys: string) => {
    setSelectedColumnKeys(selectedKeys);
  };

  const [itemsColumns, setItemsColumns] = useState<IDetailsListItemsColumns>(getNewState(props));

  useEffect(() => {
    const selectedColumns = columns?.filter(
      (column) => !selectedColumnKeys || selectedColumnKeys.split(",").indexOf(column.fieldName) >= 0
    );

    setItemsColumns(getNewState({ items, columns: selectedColumns }));
  }, [items, columns, selectedColumnKeys, getNewState]);

  const renderFixedDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }

    if (disableStickyColumnHeaders) {
      return defaultRender(props);
    }

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender(props)}
      </Sticky>
    );
  };

  return !items || !items.length ? (
    <Tile {...props} contentHeight={contentHeight} key="NoItemsTile">
      <div className={classNames.message} aria-label={noItemText} role="alert">
        {noItemText}
      </div>
    </Tile>
  ) : (
    <Tile
      {...props}
      contentHeight={contentHeight}
      commandBarItemKeys={commandBarItemKeys}
      key="ItemsOnAdt"
      onSearchChange={onSearchChange}
      onColumnSelectionChange={onColumnSelectionChange}
      selectedColumnKeys={selectedColumnKeys}
    >
      <>
        <ReactAnnounce announceMessage={itemsColumns?.announcedMessage} />
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            {...props}
            columns={itemsColumns?.columns || []}
            items={itemsColumns?.items || []}
            className={[classNames.detailsList, classNameForDetailsList].join(" ")}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={renderFixedDetailsHeader}
          />
        </ScrollablePane>
      </>
    </Tile>
  );
};

export default TileDetailsList;
