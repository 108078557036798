import React, { useState, useCallback } from "react";
import Diagram, { LineMarkerType } from "../../../shared/components/Diagram";
import classNames from "./ControlSdkDesigner.module.scss";
import { getActionNodesAndLinks, getInputNodes, getOutputNodes } from "./ControlSdkDesigner.helper";
import { Panel, DefaultButton, PrimaryButton } from "@fluentui/react";
import ControlSdkInputEditor from "./ControlSdkInputEditor";
import ControlSdkActionEditor from "./ControlSdkActionEditor";
import ControlSdkOutputEditor from "./ControlSdkOutputEditor";

const defaultDiagramMinHeight = 40;

export interface IControlSdkDesignerProps {
  control: any;
  controlSdk: any;
}

export const ControlSdkDesigner = (props: IControlSdkDesignerProps): JSX.Element => {
  const { control, controlSdk } = props;
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [panelContent, setPanelContent] = useState<JSX.Element>();
  const [panelTitle, setPanelTitle] = useState<string>();

  const onEditInputsClick = useCallback(() => {
    setPanelTitle("Edit Input Settings");
    setPanelContent(<ControlSdkInputEditor control={control} controlSdk={controlSdk} />);
    setShowPanel(true);
  }, [control, controlSdk]);

  const onAddActionClick = useCallback(() => {
    setPanelTitle("Add New Action");
    setPanelContent(<ControlSdkActionEditor control={control} controlSdk={controlSdk} />);
    setShowPanel(true);
  }, [control, controlSdk]);

  const onAddOutputClick = useCallback(() => {
    setPanelTitle("Add New Output");
    setPanelContent(<ControlSdkOutputEditor control={control} controlSdk={controlSdk} />);
    setShowPanel(true);
  }, [control, controlSdk]);

  const onPanelDismiss = useCallback(() => setShowPanel(false), []);

  const onRenderPanelFooterContent = useCallback(
    () => (
      <div className={classNames.buttons}>
        <PrimaryButton className={classNames.button} onClick={onPanelDismiss}>
          Save
        </PrimaryButton>
        <DefaultButton className={classNames.button} onClick={onPanelDismiss}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [onPanelDismiss]
  );

  let inputNodes = getInputNodes(control?.controlSdkSettings, controlSdk);

  let [actionNodes, actionLinks, actionTrainCount] = getActionNodesAndLinks(control?.controlSdkSettings, controlSdk);

  let outputNodes = getOutputNodes(control?.controlSdkSettings, controlSdk);

  return (
    <>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Inputs</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Edit Inputs"
            iconProps={{ iconName: "Edit" }}
            onClick={onEditInputsClick}
          />
        </div>
        <Diagram classNames={{ diagram: classNames.diagram }} diagram={{ nodes: inputNodes }} />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Actions</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Add Action"
            iconProps={{ iconName: "Add" }}
            onClick={onAddActionClick}
          />
        </div>
        <Diagram
          classNames={{ diagram: classNames.diagram }}
          diagram={{
            nodes: actionNodes,
            links: actionLinks,
            height: actionTrainCount * (defaultDiagramMinHeight + 20),
            defaultLinkProps: { lineMarkerType: LineMarkerType.arrowEnd },
          }}
        />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Outputs</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Add Output"
            iconProps={{ iconName: "Add" }}
            onClick={onAddOutputClick}
          />
        </div>
        <Diagram classNames={{ diagram: classNames.diagram }} diagram={{ nodes: outputNodes }} />
      </div>
      <Panel
        isOpen={showPanel}
        headerText={panelTitle}
        onDismiss={onPanelDismiss}
        onRenderFooterContent={onRenderPanelFooterContent}
        isFooterAtBottom
      >
        {panelContent}
      </Panel>
    </>
  );
};

export default ControlSdkDesigner;
