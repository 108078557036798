import React from "react";
import { Submit } from "./Submit";
import { IVisualProps } from "./Visual";
import { VisualType } from "./Visual.helper";

export class ActionButton extends React.Component<IVisualProps> {
  render() {
    const { label = "Update" } = this.props;

    return <Submit {...this.props} label={label} filterName={VisualType.actionButton} />;
  }
}

export default ActionButton;
