import { UserAgentApplication, Account } from "msal";

export interface IAppConfig {
  aadClientID: string;
  aadAuthority: string;
  aadDefaultScopes?: string[];
}

export class AuthHelper {
  public appConfig: IAppConfig;
  public clientApp: UserAgentApplication;
  public onLogging: (message: string) => void;

  constructor(appConfig: IAppConfig, onLogging?: (message: string) => void) {
    this.appConfig = appConfig;
    this.clientApp = new UserAgentApplication({
      auth: {
        clientId: appConfig.aadClientID,
        authority: appConfig.aadAuthority,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    });

    this.clientApp.handleRedirectCallback(() => {
      window.location.reload();
    });

    this.onLogging = onLogging;
  }

  // Login is default to redirect if no user data (e.g. id token) is found locally.
  login = (scopes: string[] = this.appConfig.aadDefaultScopes): Promise<Account> => {
    var userAccount = this.clientApp.getAccount(),
      loginInProgress = this.clientApp.getLoginInProgress();
    if (!userAccount && !loginInProgress) {
      this.logMessage("loginRedirect is called");
      this.clientApp.loginRedirect({ scopes });
      return;
    }

    if (userAccount) {
      this.logMessage(`Successful login for user account ${userAccount.userName}`);
      return new Promise((resolve) => resolve(userAccount));
    }

    return new Promise((resolve) => resolve(null));
  };

  logout = (): Promise<void> => {
    return new Promise((resolve) => {
      this.logMessage(`logout started`);
      this.clientApp.logout();
      resolve();
    });
  };

  acquireAccessToken = (
    scopes: string[] = this.appConfig.aadDefaultScopes,
    secondTry: boolean = false
  ): Promise<string> => {
    return new Promise((resolve) => {
      var userAccount = this.clientApp.getAccount();

      if (!userAccount) {
        this.clientApp.loginRedirect({ scopes });
        return;
      }

      // Call acquireTokenSilent (iframe) to obtain a access token.
      this.clientApp
        .acquireTokenSilent({ scopes })
        .then((response) => resolve(response.accessToken))
        .catch((error) => {
          this.logMessage(`acquireTokenSilent fails with error '${error}'`);

          if (error.name === "InteractionRequiredAuthError") {
            this.clientApp
              .acquireTokenPopup({ scopes })
              .then((response) => resolve(response.accessToken))
              .catch((error) => {
                this.logMessage(`acquireTokenPopup fails with error '${error}'`);
                console.error(`acquireTokenPopup fails with error '${error}'`, error);
              });
          } else if (!secondTry && error.name === "ClientAuthError") {
            this.clientApp
              .acquireTokenPopup({ scopes })
              .then((response) => resolve(response.accessToken))
              .catch((error) => {
                this.logMessage(`acquireTokenPopup fails with error '${error}'. Attempting second try.`);
                this.acquireAccessToken(scopes, true).then((response) => resolve(response));
              });
          } else {
            // Failsafe if silent auth and popup both fail, then do a full login redirect
            this.logMessage(`login failed: '${error}. 'loginRedirect' is being called`);
            console.error(`login failed: '${error}. 'loginRedirect' is being called`, error);
            this.clientApp.loginRedirect({ scopes });
          }
        });
    });
  };

  logMessage = (message: string) => {
    this.onLogging && this.onLogging(message);
  };
}

export default AuthHelper;
