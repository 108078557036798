import React from "react";
import { connect } from "react-redux";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { CommandBar, ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IState } from "../../reducers/interfaces";
import { INavLink } from "@fluentui/react/lib/Nav";
import classNames from "./AppModuleHeader.module.scss";
import { ActionButton } from "@fluentui/react";

export interface IAppModuleHeaderProps {
  breadcrumbItems?: IBreadcrumbItem[];
  commandBarProps?: ICommandBarProps;
  commandBarContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  hideHeader?: boolean;
  messageContent?: React.ReactNode;
  headerTextOverride?: string;
  className?: string;
  onBreadcrumbClick?: (ev: React.MouseEvent, item: IBreadcrumbItem) => void;
}

export interface IAppModuleHeaderStateProps {
  selectedLeftNavLink?: INavLink;
  leftNavParents: string[];
}

export class AppModuleHeader extends React.Component<IAppModuleHeaderProps & IAppModuleHeaderStateProps> {
  static defaultProps: IAppModuleHeaderProps = {
    breadcrumbItems: [],
  };

  render() {
    const {
      breadcrumbItems: baseBreadcrumbItems,
      selectedLeftNavLink: baseLink,
      leftNavParents,
      onBreadcrumbClick,
      commandBarContent,
      messageContent,
      hideHeader,
      headerTextOverride,
      className,
      headerContent,
    } = this.props;

    let commandBarProps = this.props.commandBarProps;

    const currentBreadcrumb: IBreadcrumbItem = baseLink
      ? { text: baseLink.name, key: baseLink.key, href: `#${baseLink.key}` }
      : null;

    const nameOfNavLinkItem = baseLink?.name || "";

    let pageHeader = null;
    let enableBackButton = false;

    if (headerTextOverride) {
      pageHeader = headerTextOverride;
    } else if (baseBreadcrumbItems?.length) {
      pageHeader = baseBreadcrumbItems[0].text;
      enableBackButton = !!onBreadcrumbClick;
    } else if (nameOfNavLinkItem) {
      pageHeader = nameOfNavLinkItem;
    } else if (leftNavParents?.length) {
      pageHeader = leftNavParents[leftNavParents.length - 1];
    }

    const backButtonName = nameOfNavLinkItem ? `Back to ${nameOfNavLinkItem} List` : "Back to List";

    const backButton = (
      <ActionButton
        key="backButton"
        name={backButtonName}
        ariaLabel={backButtonName}
        iconProps={{
          iconName: "NavigateBack",
          title: backButtonName,
          className: classNames.backButtonIcon,
        }}
        className={classNames.backButton}
        onClick={(ev: any) => {
          ev.stopPropagation();
          onBreadcrumbClick(ev, currentBreadcrumb);
        }}
      />
    );

    let rootClassNames = [classNames.root, className],
      headerPaneClassNames = [classNames.headerPane];

    hideHeader && rootClassNames.push(classNames.tightPane);
    hideHeader && headerPaneClassNames.push(classNames.tightPane);

    return (
      <div className={rootClassNames.join(" ")}>
        {commandBarProps && (
          <div className={classNames.commandBar}>
            <div className={classNames.commandBarContent}>{commandBarContent}</div>
            <CommandBar {...commandBarProps} ariaLabel="Page Command Bar" />
          </div>
        )}
        {messageContent && <div className={classNames.messageContent}>{messageContent}</div>}
        <div className={headerPaneClassNames.join(" ")}>
          {enableBackButton && backButton}
          <h1 className={hideHeader ? classNames.srOnly : classNames.header}>{pageHeader}</h1>
          <div className={classNames.headerContent}>{headerContent}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState): IAppModuleHeaderStateProps => ({
  selectedLeftNavLink: state.app.selected_left_nav_link,
  leftNavParents: state.app.left_nav_parents,
});

export default connect(mapStateToProps)(AppModuleHeader);
