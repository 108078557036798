import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultChecklist, getFields } from "./MyChecklistEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { ConfigItemType } from "../../../api/radarApi";

export class MyChecklistEdit extends React.Component<IConfigItemEditProps> {
  private generalEditForm: React.RefObject<any> = React.createRef();

  render() {
    const { configValue, editConfigItem } = this.props;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Checklist"
        configItemType={ConfigItemType.ChecklistConfig}
        appInsightsPageName="MyChecklistEditPage"
        leftNavUrl={leftNavUrls.management.checklist}
        defaultConfigItem={defaultChecklist}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem)}
              editConfigItem={editConfigItem}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    return this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
  };
}

const mapDispatchToProps: IConfigItemEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadChecklistConfigItem,
  updateConfigItem: actionCreator.updateChecklistConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyChecklistEdit);
