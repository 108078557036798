import React from "react";
import { SelectionMode, IColumn, DetailsList } from "@fluentui/react/lib/DetailsList";
import { issueColumns } from "./helper";
import classNames from "./FmeCloseIssuesList.module.scss";
import { ITeam } from "../common/interfaces";
import {
  IDetailsListItemsColumns,
  onDetailsListColumnClick,
  getSortedItemsColumns,
} from "../../shared/utilities/tableHelper";

export interface IFmeCloseIssuesListProps {
  team: ITeam;
}

export class FmeCloseIssuesList extends React.Component<IFmeCloseIssuesListProps, IDetailsListItemsColumns> {
  constructor(props: IFmeCloseIssuesListProps) {
    super(props);

    this.state = this.getNewState(props.team?.fmeCloseIssues);
  }

  componentDidUpdate(prevProps: IFmeCloseIssuesListProps) {
    if (prevProps.team?.fmeCloseIssues !== this.props.team?.fmeCloseIssues) {
      this.setState(this.getNewState(this.props.team?.fmeCloseIssues));
    }
  }

  render() {
    const { items, columns } = this.state;

    if (!items?.length) {
      return <div className={classNames.error}>No issue is defined for this team.</div>;
    }

    return (
      <div className={classNames.root}>
        <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} />
      </div>
    );
  }

  onColumnClick = (ev: React.MouseEvent, column: IColumn) => {
    this.setState({
      ...onDetailsListColumnClick(column, this.state),
    });
  };

  getNewState = (issues): IDetailsListItemsColumns =>
    getSortedItemsColumns({ items: issues, columns: issueColumns }, this.onColumnClick);
}

export default FmeCloseIssuesList;
