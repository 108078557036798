import { IField, FieldType } from "../../../shared/components/DataForm";
import { IEntityTraceView } from "../../common/interfaces";
import { getDiagramNodeFields, getDiagramLinkFields, getDiagramAdvancedFields } from "../common/helper";

export const defaultEntityTraceView: IEntityTraceView = {
  id: "new",
};

export const getFields = (entityTraceView: IEntityTraceView): IField[] => {
  if (!entityTraceView) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      fieldType: FieldType.displayOnly,
      value: entityTraceView.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "name",
      label: "Name",
      value: entityTraceView.name,
      required: true,
      width: "300px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: entityTraceView.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: entityTraceView.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: entityTraceView.description,
      lastInRow: true,
    },
    getDiagramAdvancedFields(entityTraceView),
    getDiagramNodeFields(entityTraceView.nodes),
    getDiagramLinkFields(entityTraceView.links),
  ];
};
