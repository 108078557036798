import React from "react";
import { Tile, ITileProps, BuiltInCommandBarItemKey } from "./Tile";
import classNames from "./GroupingTile.module.scss";

export interface IGroupingTileProps extends ITileProps {}

export class GroupingTile extends React.Component<IGroupingTileProps> {
  render() {
    const { className: propClassName, classNames: propClassNames, ...otherProps } = this.props;

    return (
      <Tile
        className={`${classNames.root} ${propClassName}`}
        classNames={{ ...classNames, ...propClassNames }}
        {...otherProps}
        commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent, BuiltInCommandBarItemKey.showFullScreen]}
      >
        <div className={classNames.groupingTileContent}>{this.props.children}</div>
      </Tile>
    );
  }
}

export default GroupingTile;
