import React, { useMemo } from "react";
import { Dropdown } from "@fluentui/react";
import { IVisualProps } from "./Visual";
import moment from "moment";

export interface IMonthDropdownProps extends IVisualProps {
  selectedKey?: string | number;
  endingMonthDiff?: number;
  endingDate?: Date | string;
  startingDate?: Date | string;
  maxMonths?: number;
}

export const MonthDropdown = (props: IMonthDropdownProps) => {
  const {
    visualProps,
    label,
    selectedKey,
    style,
    startingDate,
    endingDate,
    endingMonthDiff = 0,
    maxMonths = 13,
    onValueSelect,
  } = props;
  const dropdownProps: any = {
    dropdownWidth: 160,
    ...visualProps,
    label,
    selectedKey,
  };
  const finalStyle = {
    width: 160,
    ...style,
  };

  const options = useMemo(() => {
    let options = [],
      now = new Date(),
      currMonth = endingDate ? new Date(endingDate) : new Date(now.getFullYear(), now.getMonth() + endingMonthDiff, 1),
      startingMonth = startingDate && new Date(startingDate);

    for (var i = 0; i < maxMonths; i++) {
      var key = currMonth.toISOString().substring(0, 10),
        text = moment(currMonth).format("MMMM YYYY");

      options.push({ key, text, ariaLabel: text });

      currMonth.setMonth(currMonth.getMonth() - 1);

      if (startingMonth && currMonth < startingMonth) {
        break;
      }
    }

    return options;
  }, [startingDate, endingDate, maxMonths, endingMonthDiff]);

  return (
    <Dropdown
      options={options}
      style={finalStyle}
      {...dropdownProps}
      onChange={(ev, option) => onValueSelect(option.text, option.key.toString())}
    />
  );
};

export default MonthDropdown;
