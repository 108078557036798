import React from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import classNames from "./CopyToClipboard.module.scss";
import { copyToClipboard } from "../../utilities/miscHelper";

export interface ICopyToClipboardProps {
  value: string;
}

export interface ICopyToClipboardState {
  showCallout: boolean;
}

export class CopyToClipboard extends React.Component<ICopyToClipboardProps> {
  state = {
    showCallout: false,
  };

  render() {
    const { showCallout } = this.state;

    return (
      <span className={classNames.root} onClick={this.onClick} onKeyUp={this.onKeyUp} tabIndex={0}>
        <Icon
          className={classNames.icon}
          iconName="Copy"
          title="Copy To Clipboard"
          aria-label="Copy this item to clipboard"
          role="button"
        />
        {showCallout && (
          <Callout
            target={`.${classNames.icon}`}
            directionalHint={DirectionalHint.rightCenter}
            beakWidth={8}
            gapSpace={4}
          >
            <div className={classNames.callout}>Item Copied To Clipboard</div>
          </Callout>
        )}
      </span>
    );
  }

  onKeyUp = (e: React.KeyboardEvent) => {
    e.which === 13 && this.onClick();
  };

  onClick = () => {
    const { value } = this.props;

    copyToClipboard(value);

    this.setState({ showCallout: true });
    setTimeout(() => this.setState({ showCallout: false }), 5000);
  };
}

export default CopyToClipboard;
