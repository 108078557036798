import { loadTheme } from "@fluentui/react";

export enum Theme {
  dark = "dark",
  light = "light",
}

export const setTheme = (selectedTheme: Theme = Theme.light) => {
  let theme = themeStyles[selectedTheme];

  loadTheme({ palette: theme });

  theme &&
    Object.keys(theme).forEach((prop) => {
      let key = `--${prop}`,
        value = theme[prop];
      document.body.style.setProperty(key, value);
    });
};

export const themeStyles = {
  dark: {
    neutralDarkest: "#fff",
    neutralDark: "#dedede",
    neutralPrimary: "#ddd",
    neutralPrimaryAlt: "#ccc",
    neutralSecondary: "#bbb",
    neutralSecondaryAlt: "#aaa",
    neutralTertiary: "#686868",
    neutralTertiaryAlt: "#555",
    neutralQuaternary: "#343434",
    neutralQuaternaryAlt: "#282828",
    neutralLight: "#282828",
    neutralLighter: "#242424",
    neutralLighterAlt: "#222",
    neutralLightest: "#181818",
    redDark: "#98FFFF",
    flaggedMessage: "#000337",
    richUserContentBackground: "#282828",
    composeNeutralBackground: "#343434",
    composeNeutralLighterBackground: "#222",
    themeDarker: "#deecf9",
    themeDark: "#eff6fc",
    themeDarkAlt: "#c7e0f4",
    themePrimary: "#1a86d9",
    themeSecondary: "#6bc8f8",
    themeTertiary: "#b1eff5",
    themeLight: "#106ebe",
    themeLighter: "#004578",
    themeLighterAlt: "#005a9e",
    warning: "#8e6b12",
    white: "#383838",
    black: "white",
  },
  light: {
    neutralDarkest: "#000",
    neutralDark: "#212121",
    neutralPrimary: "#333333",
    neutralPrimaryAlt: "#3c3c3c",
    neutralSecondary: "#666666",
    neutralSecondaryAlt: "#767676",
    neutralTertiary: "#a6a6a6",
    neutralTertiaryAlt: "#c8c8c8",
    neutralQuaternary: "#d0d0d0",
    neutralQuaternaryAlt: "#dadada",
    neutralLight: "#eaeaea",
    neutralLighter: "#f4f4f4",
    neutralLighterAlt: "#f8f8f8",
    neutralLightest: "#fff",
    redDark: "#a80000",
    flaggedMessage: "#fffdd9",
    richUserContentBackground: "#fff",
    composeNeutralBackground: "#fff",
    composeNeutralLighterBackground: "#f4f4f4",
    themeDarker: "#004578",
    themeDark: "#005a9e",
    themeDarkAlt: "#106ebe",
    themePrimary: "#0078d4",
    themeSecondary: "#2b88d8",
    themeTertiary: "#71afe5",
    themeLight: "#c7e0f4",
    themeLighter: "#deecf9",
    themeLighterAlt: "#eff6fc",
    warning: "#8e6b12",
    white: "white",
    black: "black",
  },
};
