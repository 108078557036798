import React from "react";
import { IIncident } from "./interfaces";
import { Tile } from "../../shared/components/Tile";
import { ContactLink } from "../../shared/components/ContactLink";
import { CopyToClipboard } from "../../shared/components/common/CopyToClipboard";
import { getContactMessageSubject, getImpactedLOB } from "./IncidentsReport.helper";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import classNames from "./IncidentHeader.module.scss";

export interface IIncidentHeaderProps {
  incident: IIncident;
}

class IncidentHeader extends React.Component<IIncidentHeaderProps> {
  render() {
    const { incident } = this.props;

    const statusStyle = {
        color: incident.statusColor,
      },
      headerPaneStyle = {
        borderLeftColor: incident.statusColor,
      };

    const impactedLOB = getImpactedLOB(incident);
    const icmLink = `https://portal.microsofticm.com/imp/v3/incidents/details/${incident.incidentId}/home`;
    const clipboardText = `<a href='${window.location.href}'>IcM ${incident.incidentId}</a>: ${incident.title}`;
    const clipboardIcMLinkText = `<a href='${icmLink}'>IcM ${incident.incidentId}</a>: ${incident.title}`;

    return (
      <Tile>
        <div className={classNames.detailsHeaderPane} style={headerPaneStyle}>
          <div className={classNames.statusPane}>
            <div className={classNames.id}>
              <a
                href={icmLink}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={`Click here to go to IcM site for incident ${incident.incidentId}`}
                title={`Click here to go to IcM site for incident ${incident.incidentId}`}
              >
                {incident.incidentId}
              </a>
              <CopyToClipboard value={clipboardIcMLinkText} />
            </div>
            <div className={classNames.status} style={statusStyle}>
              {incident.status}
            </div>
            <div className={classNames.severity}>Severity {incident.severity}</div>
          </div>
          <div className={classNames.headerContent}>
            <div className={classNames.title}>
              {incident.isPurged === 1 && <span className={classNames.archived}>Archived</span>}
              {incident.title} <CopyToClipboard value={clipboardText} />
            </div>
            <div className={classNames.dataFieldsPane}>
              {!!incident.owningContactAlias && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Owner:</span>
                  <span className={classNames.dataFieldValue}>
                    <ContactLink
                      alias={incident.owningContactAlias}
                      messageSubject={getContactMessageSubject(incident)}
                    />
                  </span>
                </div>
              )}
              {incident.engineeringDirector && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Engineering Director:</span>
                  <span className={classNames.dataFieldValue}>
                    <ContactLink
                      alias={incident.engineeringDirector}
                      messageSubject={getContactMessageSubject(incident)}
                    />
                  </span>
                </div>
              )}
              {incident.financialImpactUSD !== undefined && incident.financialImpactUSD !== null && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Financial Impact:</span>
                  <span className={classNames.dataFieldValue}>
                    {incident.financialImpactUSD === undefined
                      ? incident.financialImpact === 0
                        ? "$0"
                        : `$${incident.financialImpact}M`
                      : getFormatContent(incident.financialImpactUSD, ContentDisplayType.shortCurrency)}
                    {incident.financialImpactDescription && " - " + incident.financialImpactDescription}
                  </span>
                </div>
              )}
              {incident.owningTeamId && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Owning Team:</span>
                  <span className={classNames.dataFieldValue}>{incident.owningTeamId}</span>
                </div>
              )}
              {!!impactedLOB && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Impacted LOB:</span>
                  <span className={classNames.dataFieldValue}>{impactedLOB}</span>
                </div>
              )}
              {!!incident.detectionSourceType && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Detection Source:</span>
                  <span className={classNames.dataFieldValue}>
                    {incident.detectionSourceType}
                    {incident.detectionSource && " - " + incident.detectionSource}
                  </span>
                </div>
              )}
              {!!incident.financialReportingImpact && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Financial Reporting Impact:</span>
                  <span className={classNames.dataFieldValue}>{incident.financialReportingImpact}</span>
                </div>
              )}
              {!!incident.engineeringRootCause && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Engineering Root Cause:</span>
                  <span className={classNames.dataFieldValue}>{incident.engineeringRootCause}</span>
                </div>
              )}
              {!!incident.complianceReview && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Compliance Review:</span>
                  <span className={classNames.dataFieldValue}>{incident.complianceReview}</span>
                </div>
              )}
              {!!incident.rootCauseSubCategory && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Root Cause Sub-Category:</span>
                  <span className={classNames.dataFieldValue}>{incident.rootCauseSubCategory}</span>
                </div>
              )}
              {!!incident.causingService && (
                <div className={classNames.dataField}>
                  <span className={classNames.dataFieldName}>Causing Service:</span>
                  <span className={classNames.dataFieldValue}>{incident.causingService}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Tile>
    );
  }
}

export default IncidentHeader;
