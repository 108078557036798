import React from "react";
import { ITimelineMarker } from "./interfaces";
import { ITimelineChartProps } from "./TimelineChart";

export const ticksInOneDay = 86400000;

const extendEndTimeWidthThreshold = 600;
const extendEndTimeDayThreshold = 15;

export const getChartContent = (
  chartInfo: IChartInfo,
  props: ITimelineChartProps,
  classNames: any,
  contentWidth: number,
  contentHeight: number
) => {
  if (!props.groups || !props.groups.length || !contentWidth || !contentHeight) return null;

  return [
    ...getAxisLines(chartInfo, props, classNames),
    ...getDateTickLines(chartInfo, props, classNames),
    ...getTimelines(chartInfo, props, classNames),
  ];
};

export const getChartBottomContent = (chartInfo: IChartInfo, props: ITimelineChartProps, classNames: any) => {
  return [...getBottomXAxisLines(chartInfo, props, classNames), ...getDateTicksAndLabels(chartInfo, props, classNames)];
};

export enum TimeLabelType {
  day,
  month,
}

export const markerShapeList = [
  "marker-circle",
  "marker-triangle",
  "marker-square",
  "marker-diamond",
  "marker-pentagon",
];

export const markerShapeId = {
  circle: markerShapeList[0],
  triangle: markerShapeList[1],
  square: markerShapeList[2],
  diamond: markerShapeList[3],
  star: markerShapeList[4],
};

export enum MarkerMode {
  shapeOnly,
  colorOnly,
  shapeAndColor,
}

export interface ITimeRange {
  minTime: number;
  maxTime: number;
}

export const extendTimeRange = (
  timeRange: ITimeRange,
  contentWidth: number,
  extendEndTime: boolean = false,
  timeLabelType: TimeLabelType = TimeLabelType.month
): ITimeRange => {
  let startTime = new Date(timeRange.minTime),
    endTime = new Date(timeRange.maxTime),
    timeWidthInDays = (timeRange.maxTime - timeRange.minTime) / ticksInOneDay,
    smallChart = contentWidth < extendEndTimeWidthThreshold;

  if (timeLabelType === TimeLabelType.month) {
    let extendMonth = extendEndTime && (smallChart || endTime.getDate() > extendEndTimeDayThreshold) ? 2 : 1;

    if (extendEndTime) {
      // Add more extension if the time range is long so the marker flags at the end are less likely to be cut off.
      let smallChartExtendMonth = smallChart ? 6 : 2;
      extendMonth += timeWidthInDays > 365 ? smallChartExtendMonth : timeWidthInDays > 180 ? 1 : 0;
    }

    return {
      minTime: startTime.setDate(1),
      maxTime: new Date(endTime.setMonth(endTime.getMonth() + extendMonth)).setDate(1),
    };
  } else {
    return {
      minTime: startTime.setDate(startTime.getDate() - 1),
      maxTime: endTime.setDate(endTime.getDate() + 1),
    };
  }
};

export const getTickTimes = (
  minTime: number,
  maxTime: number,
  chartWidth: number,
  timeLabelType: TimeLabelType = TimeLabelType.month
): number[] => {
  if (chartWidth <= 0) return [];

  let tickTimes: number[] = [],
    tickTime = minTime,
    useDay = timeLabelType === TimeLabelType.day,
    totalDays = (maxTime - minTime) / ticksInOneDay,
    totalTicks = useDay ? totalDays : totalDays / 30,
    minLabelWidth = 60,
    tickInterval = Math.ceil(Math.abs(totalTicks / chartWidth) * minLabelWidth);

  while (tickTime < maxTime) {
    tickTimes.push(tickTime);
    let tickDate = new Date(tickTime);
    tickTime = useDay
      ? tickDate.setDate(tickDate.getDate() + tickInterval)
      : tickDate.setMonth(tickDate.getMonth() + tickInterval);
  }

  return tickTimes;
};

export const getMarkerTooltipText = (marker: ITimelineMarker, timelineName: string = ""): string => {
  let fieldsText = "";
  marker.fields &&
    marker.fields.forEach((field) => {
      fieldsText += `\n${field.name}: ${field.value}`;
    });

  return `${timelineName}${timelineName && "\n\n"}${marker.name}\n${new Date(
    marker.time
  ).toLocaleDateString()}${fieldsText}`;
};

interface IChartInfo {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  maxTime?: number;
  minTime?: number;
  timelineCount?: number;
  timeWidth?: number;
  width?: number;
  height?: number;
  tickTimes?: number[];
  labelFontSize?: number;
  timelineHeight?: number;
  timelineTop?: number;
  totalTimelineHeight?: number;
}

export const calcChartInfo = (props: ITimelineChartProps, contentWidth: number, contentHeight: number): IChartInfo => {
  const {
    groups,
    topXAxisPadding,
    bottomXAxisPadding,
    rightYAxisPadding,
    leftYAxisPadding,
    maxTimelineHeight,
    minTimelineHeight,
    timelineGap,
    labelFontSizeFactor,
    timeLabelType,
  } = props;

  let timelineCount = 0,
    minTime = new Date().valueOf(),
    maxTime = new Date().valueOf();

  // Calculate the overall time range.
  groups &&
    groups.forEach((group) => {
      group.timelines &&
        group.timelines.forEach((timeline) => {
          if (timeline.data && timeline.data.length) {
            timeline.data.forEach((dataItem) => {
              let endDate = new Date(dataItem.endTime),
                startDate = new Date(dataItem.startTime);

              endDate && (maxTime = Math.max(maxTime, endDate.valueOf()));
              props.endTime && (maxTime = Math.min(maxTime, new Date(props.endTime).valueOf()));
              startDate && (minTime = Math.min(minTime, startDate.valueOf()));
              props.startTime && (minTime = Math.max(minTime, new Date(props.startTime).valueOf()));
            });

            timelineCount++;
          }
        });
    });

  // Extend the time range to closest 1st of month.
  let timeRange = extendTimeRange({ minTime, maxTime }, contentWidth, false, timeLabelType);

  minTime = new Date(timeRange.minTime).setHours(0, 0, 0, 0);
  maxTime = new Date(timeRange.maxTime).setHours(23, 59, 59, 999);

  // Calculate the chart plotting area size.
  const chartHeight = contentHeight - topXAxisPadding - bottomXAxisPadding - 1,
    chartWidth = contentWidth - rightYAxisPadding - leftYAxisPadding,
    timelineHeight = Math.max(
      minTimelineHeight,
      Math.min(maxTimelineHeight, Math.floor((chartHeight / timelineCount - timelineGap) * 100) / 100)
    );

  // Get tick times based on minTime and maxTime.
  let tickTimes = getTickTimes(minTime, maxTime, chartWidth);

  // Set timelines to vertical align to bottom of the chart if all timelines height is less than chart height.
  let totalTimelineHeight = (timelineHeight + timelineGap) * timelineCount,
    timelineTop = topXAxisPadding;

  // Font size of the timeline label should be a factor smaller by the timeline height with a minimal.
  let labelFontSize = Math.max(minTimelineHeight * 0.85, Math.floor(timelineHeight * labelFontSizeFactor));

  return {
    top: topXAxisPadding,
    bottom: totalTimelineHeight - topXAxisPadding,
    left: leftYAxisPadding,
    right: contentWidth - rightYAxisPadding,
    timelineCount,
    maxTime,
    minTime,
    timeWidth: maxTime - minTime,
    width: chartWidth,
    height: chartHeight,
    labelFontSize,
    tickTimes,
    timelineTop,
    timelineHeight,
    totalTimelineHeight,
  };
};

const getAxisLines = (chart: IChartInfo, props: ITimelineChartProps, classNames: any): JSX.Element[] => {
  return [
    // Draw top x-axis line
    <line key="xl2" x1={chart.left} y1={chart.top} x2={chart.right} y2={chart.top} className={classNames.xAxis} />,
    // Draw left y-axis line
    <line key="yl1" x1={chart.left} y1={chart.top} x2={chart.left} y2={chart.bottom} className={classNames.yAxis} />,
    // Draw right y-axis line
    <line key="yl2" x1={chart.right} y1={chart.top} x2={chart.right} y2={chart.bottom} className={classNames.yAxis} />,
  ];
};

const getBottomXAxisLines = (chart: IChartInfo, props: ITimelineChartProps, classNames: any): JSX.Element[] => {
  return [
    // Draw bottom x-axis line
    <line key="xl1" x1={chart.left} y1={0} x2={chart.right} y2={0} className={classNames.xAxis} />,
  ];
};

const getDateTickLines = (chart: IChartInfo, props: ITimelineChartProps, classNames: any): JSX.Element[] => {
  const { leftYAxisPadding } = props;

  let content: JSX.Element[] = [],
    endMonth = new Date(chart.maxTime).getMonth();

  chart.tickTimes &&
    chart.tickTimes.forEach((tickTime, index) => {
      let tickLeft = (chart.width * (tickTime - chart.minTime)) / chart.timeWidth + leftYAxisPadding,
        tickDate = new Date(tickTime),
        tickMonth = tickDate.getMonth();

      if (
        (index !== 0 && index !== chart.tickTimes.length - 1) ||
        (index === chart.tickTimes.length - 1 && tickMonth !== endMonth && tickTime < chart.maxTime)
      ) {
        // Skip the first and last lines because they are axis lines.
        content.push(
          <line
            key={"tl" + index}
            x1={tickLeft}
            y1={chart.top}
            x2={tickLeft}
            y2={chart.bottom}
            className={classNames.tickLine}
          />
        );
      }
    });

  return content;
};

const getDateTicksAndLabels = (chart: IChartInfo, props: ITimelineChartProps, classNames: any): JSX.Element[] => {
  const { leftYAxisPadding, tickLength, xAxisLabelFontSize, xAxisLabelPadding } = props;

  let content: JSX.Element[] = [],
    prevTickYear = 0;

  chart.tickTimes &&
    chart.tickTimes.forEach((tickTime, index) => {
      let tickLeft = (chart.width * (tickTime - chart.minTime)) / chart.timeWidth + leftYAxisPadding,
        tickDate = new Date(tickTime);

      if (index !== 0) {
        content.push(
          <line
            key={"tlt" + index}
            x1={tickLeft}
            y1={0}
            x2={tickLeft}
            y2={tickLength}
            className={classNames.tickLine}
          />
        );
      }

      content.push(
        getTicklineLabel(
          prevTickYear,
          tickTime,
          tickLeft,
          0,
          index,
          xAxisLabelFontSize,
          xAxisLabelPadding,
          classNames.dateLabel
        )
      );

      prevTickYear = tickDate.getFullYear();
    });

  return content;
};

export const getTicklineLabel = (
  prevTickYear: number,
  tickTime: number,
  tickLeft: number,
  tickBottom: number,
  index: number,
  xAxisLabelFontSize: number,
  xAxisLabelPadding: number,
  className: string,
  timeLabelType: TimeLabelType = TimeLabelType.month
) => {
  let tickDate = new Date(tickTime),
    tickMonth = tickDate.getMonth(),
    label: string;

  if (timeLabelType === TimeLabelType.month) {
    let tickYear = tickDate.getFullYear();

    label = tickMonth ? getMonthLabel(tickDate, tickYear, prevTickYear) : tickDate.getFullYear().toString();
  } else {
    label = `${tickMonth + 1}/${tickDate.getDate()}`;
  }

  return (
    <text
      key={"tt" + index}
      x={
        tickLeft +
        xAxisLabelFontSize +
        (label.length > 3 && timeLabelType === TimeLabelType.month ? xAxisLabelFontSize : 0)
      }
      y={tickBottom + xAxisLabelFontSize + xAxisLabelPadding}
      className={className}
      style={{ fontSize: xAxisLabelFontSize + "px" }}
      textAnchor="end"
    >
      {label}
    </text>
  );
};

const getMonthLabel = (date: Date, tickYear: number, prevTickYear: number): string => {
  let year = prevTickYear && prevTickYear !== tickYear ? tickYear + " " : "";
  return (
    year +
    date.toLocaleString("en-us", {
      month: "short",
    })
  );
};

const getTimelines = (chart: IChartInfo, props: ITimelineChartProps, classNames: any): JSX.Element[] => {
  const {
    groups,
    yAxisLabelPadding,
    rightYAxisPadding,
    leftYAxisPadding,
    timelineGap,
    tickLength,
    markerSize,
    onDataClick,
    onGroupLabelClick,
  } = props;

  let content: JSX.Element[] = [],
    timelineTop = chart.timelineTop,
    groupTop = chart.timelineTop,
    labelStyle = {
      fontSize: chart.labelFontSize + "px",
      width: rightYAxisPadding + "px",
      lineHeight: chart.timelineHeight + "px",
    },
    timeZoneAdjustment = new Date().getTimezoneOffset() * 60000;

  groups &&
    groups.forEach((group, gIndex) => {
      // Handle each group.
      group.timelines &&
        group.timelines.forEach((timeline, tIndex) => {
          // Handle each timeline.
          timeline.data &&
            timeline.data.forEach((dataItem, dIndex) => {
              // Handle each timeline data item.
              let endDate = new Date(dataItem.endTime),
                endTime = Math.min(endDate.valueOf(), chart.maxTime),
                startDate = new Date(dataItem.startTime),
                startTime = Math.max(startDate.valueOf(), chart.minTime),
                markers = dataItem.markers,
                dataItemWidth = endTime - startTime;

              let width = Math.round((chart.width * dataItemWidth) / chart.timeWidth),
                timelineLeft = (chart.width * (startTime - chart.minTime - timeZoneAdjustment)) / chart.timeWidth,
                rectStyle = dataItem.color ? { fill: dataItem.color } : {},
                timelineTooltip = `${timeline.name}\n${startDate.toDateString()} - ${endDate.toDateString()}\n`;

              // Add markers info to tooltip.
              markers &&
                markers.forEach((marker) => {
                  timelineTooltip += `\n${marker.name}: ${new Date(marker.time).toLocaleDateString()}`;
                });

              // Draw the timeline data rect.
              props.markerMode === MarkerMode.shapeOnly &&
                content.push(
                  <rect
                    key={"g" + gIndex + "t" + tIndex + "d" + dIndex}
                    x={timelineLeft + chart.left}
                    y={timelineTop}
                    width={width}
                    height={chart.timelineHeight}
                    className={classNames.timelineRect}
                    style={rectStyle}
                    onClick={() => onDataClick && onDataClick(dataItem)}
                  >
                    <title>{timelineTooltip}</title>
                  </rect>
                );

              // Draw the timeline label.
              content.push(
                <foreignObject
                  key={"g" + gIndex + "t" + tIndex + "l" + dIndex}
                  x={chart.right + yAxisLabelPadding}
                  y={timelineTop}
                  width={rightYAxisPadding}
                  height={chart.timelineHeight}
                >
                  <div
                    className={classNames.timelineLabel}
                    style={labelStyle}
                    title={timelineTooltip}
                    tabIndex={0}
                    role="link"
                    onClick={() => onDataClick && onDataClick(dataItem)}
                    onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onDataClick && onDataClick(dataItem)}
                  >
                    {timeline.name}
                  </div>
                </foreignObject>
              );

              // Draw the markers if any.
              markers &&
                markers.forEach((marker, mIndex) => {
                  let markerOrigTime = new Date(marker.time).valueOf(),
                    markerTime = Math.max(markerOrigTime, chart.minTime),
                    timeAdjust = markerTime === chart.minTime ? 0 : timeZoneAdjustment,
                    markerLeft =
                      ((markerTime - chart.minTime - timeAdjust) * chart.width) / chart.timeWidth + chart.left,
                    markerTop = timelineTop + chart.timelineHeight / 2 - markerSize * 2,
                    markerColor = props.useMarkerColor ? marker.color : "grey",
                    markerShapeId =
                      props.defaultMarkerShapeId || marker.shapeId || markerShapeList[mIndex % markers.length],
                    markerTooltip = getMarkerTooltipText(marker, timeline.name),
                    halfHeight = chart.timelineHeight / 2;

                  if (props.markerMode !== MarkerMode.shapeOnly) {
                    let markerRectStyle = marker.color ? { fill: marker.color } : {},
                      nextMarkerTime = mIndex < markers.length - 1 ? markers[mIndex + 1].time : markers[mIndex].time,
                      markerTimeWidth = new Date(nextMarkerTime).valueOf() - markerTime,
                      markerWidth = Math.round((chart.width * markerTimeWidth) / chart.timeWidth);

                    if (markerTimeWidth >= 0) {
                      if (mIndex < markers.length - 1) {
                        // Show the marker rect with width up to the next marker.
                        content.push(
                          <rect
                            key={"g" + gIndex + "t" + tIndex + "l" + dIndex + "mr" + mIndex}
                            x={markerLeft}
                            y={timelineTop}
                            width={markerWidth}
                            height={chart.timelineHeight}
                            className={classNames.markerRect}
                            style={markerRectStyle}
                            onClick={() => onDataClick && onDataClick(dataItem)}
                          >
                            <title>{markerTooltip}</title>
                          </rect>
                        );
                      } else {
                        // Show diamond for the last marker.
                        content.push(
                          <polygon
                            key={"g" + gIndex + "t" + tIndex + "l" + dIndex + "mr" + mIndex}
                            className={`${classNames.timelineRect} ${classNames.lastMarkerRect}`}
                            style={markerRectStyle}
                            points={`${markerLeft} ${timelineTop}, 
                            ${markerLeft + halfHeight} ${timelineTop + halfHeight},
                            ${markerLeft} ${timelineTop + chart.timelineHeight},
                            ${markerLeft - halfHeight} ${timelineTop + halfHeight}`}
                            onClick={() => onDataClick && onDataClick(dataItem)}
                          >
                            <title>{markerTooltip}</title>
                          </polygon>
                        );
                      }
                    }
                  }

                  if (props.markerMode !== MarkerMode.colorOnly) {
                    content.push(
                      <use
                        key={"g" + gIndex + "t" + tIndex + "l" + dIndex + "m" + mIndex}
                        className={classNames.marker}
                        href={`#${markerShapeId}`}
                        x={markerLeft - markerSize}
                        y={markerTop}
                        style={{ fill: markerColor, stroke: markerColor }}
                      >
                        <title>{markerTooltip}</title>
                      </use>
                    );
                  }

                  // Show the "more" marker if the marker is started before the chart minimal time.
                  if (markerOrigTime < chart.minTime) {
                    let moreMarkerHeight = 10;
                    content.push(
                      <polygon
                        key={"g" + gIndex + "t" + tIndex + "l" + dIndex + "mrm" + mIndex}
                        className={classNames.moreMarker}
                        points={`${markerLeft + 2} ${timelineTop + halfHeight},
                                ${markerLeft + moreMarkerHeight} ${timelineTop + 2},
                                ${markerLeft + moreMarkerHeight} ${timelineTop + chart.timelineHeight - 2}`}
                      >
                        <title>More timeline data not shown in this chart</title>
                      </polygon>
                    );
                  }
                });

              timelineTop += chart.timelineHeight + timelineGap;
            });
        });

      // Draw the group label
      group.name &&
        content.push(
          <text
            key={"gt" + gIndex}
            x={chart.left - yAxisLabelPadding}
            y={groupTop + (timelineTop - groupTop + chart.labelFontSize) / 2}
            width={leftYAxisPadding}
            className={classNames.groupLabel}
            style={labelStyle}
            onClick={() => onGroupLabelClick && onGroupLabelClick(group)}
          >
            {group.name}
          </text>
        );

      // Draw the group line
      if (gIndex < groups.length - 1) {
        content.push(
          <line
            key={"gl" + gIndex}
            x1={chart.left - tickLength}
            y1={timelineTop}
            x2={chart.right + tickLength}
            y2={timelineTop}
            className={classNames.tickLine}
          />
        );
      }

      groupTop = timelineTop;
    });

  return content;
};
