import React from "react";
import { IEntityMetric, ITile } from "../common/interfaces";
import classNames from "./EntityDetail.module.scss";
import { ContentDisplayType, getFormatContent } from "../../shared/utilities/miscHelper";
import { IMetric } from "../../shared/components/Diagram";
import { IconButton, DefaultButton } from "@fluentui/react/lib/Button";
import { BuiltInMetricId, getMetricValues } from "./helper";
import { EntityDetailTiles } from "./EntityDetailTiles";

export interface IEntityDetailMetricsProps {
  entityMetrics: IEntityMetric[];
  tiles: ITile[];
  selectedMetricIds: string[];
  allowShowAllMetrics: boolean;
  onMetricSelect: (metricId: string) => void;
}

export const EntityDetailMetrics = ({
  entityMetrics,
  tiles,
  selectedMetricIds,
  allowShowAllMetrics,
  onMetricSelect,
}: IEntityDetailMetricsProps) => {
  function onShowAllMetricButtonClick() {
    onMetricSelect(null);
  }

  let entityTypeComponents = [];
  entityMetrics.forEach((em, index) => {
    let metrics = em.metrics || [],
      slaExpected = metrics.find((m) => m.id === BuiltInMetricId.SlaExpected),
      slaPendingIn = metrics.find((m) => m.id === BuiltInMetricId.SlaPendingIn),
      slaPendingOut = metrics.find((m) => m.id === BuiltInMetricId.SlaPendingOut),
      slaCompletedIn = metrics.find((m) => m.id === BuiltInMetricId.SlaCompletedIn),
      slaCompletedOut = metrics.find((m) => m.id === BuiltInMetricId.SlaCompletedOut),
      slaExpectedValue = (slaExpected && slaExpected.value) || 0,
      slaPendingInValue = (slaPendingIn && slaPendingIn.value) || 0,
      slaPendingOutValue = (slaPendingOut && slaPendingOut.value) || 0,
      slaCompletedInValue = (slaCompletedIn && slaCompletedIn.value) || 0,
      slaCompletedOutValue = (slaCompletedOut && slaCompletedOut.value) || 0,
      pendingInPercent = parseFloat(((slaPendingInValue * 100) / slaExpectedValue).toFixed(2)),
      pendingOutPercent = parseFloat(((slaPendingOutValue * 100) / slaExpectedValue).toFixed(2)),
      completedInPercent = parseFloat(((slaCompletedInValue * 100) / slaExpectedValue).toFixed(2)),
      completedOutPercent = parseFloat(((slaCompletedOutValue * 100) / slaExpectedValue).toFixed(2));

    let entityCount = metrics.find((m) => m.id === BuiltInMetricId.EntityCount),
      validationPass = metrics.find((m) => m.id === BuiltInMetricId.ValidationPass),
      validationFail = metrics.find((m) => m.id === BuiltInMetricId.ValidationFail),
      validationPassValue = (validationPass && validationPass.value) || 0,
      validationFailValue = (validationFail && validationFail.value) || 0,
      validationTestTotal = validationPassValue + validationFailValue,
      validationExpected =
        slaExpected && slaExpectedValue
          ? slaExpectedValue
          : entityCount && entityCount.value
          ? entityCount.value
          : validationTestTotal,
      validationUntestTotal = validationExpected - validationTestTotal,
      validationTestPercent = parseFloat(((validationTestTotal * 100) / validationExpected).toFixed(2)),
      validationUntestPercent = parseFloat(((validationUntestTotal * 100) / validationExpected).toFixed(2)),
      validationPassTotalPercent = parseFloat(((validationPassValue * 100) / validationExpected).toFixed(2)),
      validationFailTotalPercent = parseFloat(((validationFailValue * 100) / validationExpected).toFixed(2)),
      validationPassPercent = parseFloat(((validationPassValue * 100) / validationTestTotal).toFixed(2));

    if (!slaExpectedValue && !validationTestTotal && (!selectedMetricIds || !selectedMetricIds.length)) {
      return;
    }

    let metricValues = getMetricValues(em.metrics);

    let metricComponents = selectedMetricIds?.map((metricId) => {
      let metric = em.metrics.filter((m) => !m.hidden).find((m) => m.id === metricId);
      let value = metric?.template ? metricValues : metric;

      metric && (metric.value = metric?.value || 0);

      return (
        metric && (
          <div key={"entity-metric-" + metricId} className={classNames.metric}>
            <IconButton
              iconProps={{ iconName: "TextDocument" }}
              title="Show Entity Details"
              aria-label="Show Entity Details"
              className={classNames.itemAction}
              onClick={() => onMetricSelect(metric.id)}
            />
            <div className={classNames.metricLabel}>{metric.name}</div>
            <div className={classNames.metricValue} style={{ color: metric.color || "black" }}>
              {getFormatContent(value, metric.displayType, "value", metric.template)}
            </div>
          </div>
        )
      );
    });

    entityTypeComponents.push(
      <div className={classNames.entityMetric} key={"em" + em.entityType + index}>
        <div className={classNames.entityTitle}>{em.entityType}</div>
        {!!slaExpectedValue && (
          <div className={classNames.metricItem}>
            <div className={classNames.metricTitle}>{completedInPercent}% completed in SLA</div>
            <svg className={classNames.metricChart}>
              <EntityMetricBar
                className={classNames.completedInBar}
                percent={completedInPercent}
                x={0}
                metric={slaCompletedIn}
              />
              <EntityMetricBar
                className={classNames.completedOutBar}
                percent={completedOutPercent}
                x={completedInPercent}
                metric={slaCompletedOut}
              />
              <EntityMetricBar
                className={classNames.pendingOutBar}
                percent={pendingOutPercent}
                x={completedInPercent + completedOutPercent}
                metric={slaPendingOut}
              />
              <EntityMetricBar
                className={classNames.pendingInBar}
                percent={pendingInPercent}
                x={completedInPercent + completedOutPercent + pendingOutPercent}
                metric={slaPendingIn}
              />
            </svg>
          </div>
        )}
        {!!validationTestTotal && (
          <div className={classNames.metricItem}>
            <div className={classNames.metricTitle}>
              {validationPassPercent}% passed, {validationTestPercent}% tested in Validation
            </div>
            <svg className={classNames.metricChart}>
              <EntityMetricBar
                className={classNames.passBar}
                percent={validationPassTotalPercent}
                x={0}
                metric={validationPass}
              />
              <EntityMetricBar
                className={classNames.failBar}
                percent={validationFailTotalPercent}
                x={validationPassTotalPercent}
                metric={validationFail}
              />
              <EntityMetricBar
                className={classNames.pendingInBar}
                percent={validationUntestPercent}
                x={validationPassTotalPercent + validationFailTotalPercent}
                metric={{ name: "Untested", value: validationUntestTotal, displayType: ContentDisplayType.number }}
              />
            </svg>
          </div>
        )}
        {!!metricComponents?.length && (
          <div className={classNames.metrics}>
            <div className={classNames.fieldLabel}>Metrics</div>
            <div className={classNames.fieldValue}>{metricComponents}</div>
            {allowShowAllMetrics && (
              <DefaultButton
                className={classNames.showAllMetricsButton}
                ariaLabel="Show All Metrics"
                onClick={onShowAllMetricButtonClick}
              >
                Show All Metrics
              </DefaultButton>
            )}
          </div>
        )}
        {tiles?.length && <EntityDetailTiles tiles={tiles} />}
      </div>
    );
  });

  if (!entityTypeComponents.length) {
    return <div className={classNames.message}>No metric info is available.</div>;
  }
  return <div>{entityTypeComponents}</div>;
};

export interface IEntityMetricBar {
  className: string;
  percent: number;
  x: number;
  metric: IMetric;
}

export const EntityMetricBar = ({ className, percent, x, metric }: IEntityMetricBar) => (
  <rect className={`${classNames.metricBar} ${className}`} style={{ width: percent + "%" }} x={x + "%"}>
    <title>{`${metric.name}: ${getFormatContent(metric.value, metric.displayType)} (${percent}%)`}</title>
  </rect>
);
