import React from "react";
import { connect } from "react-redux";
import { errorType } from "../interfaces";
import { IIncident } from "./interfaces";
import { IState } from "../../reducers/interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import IncidentHeader from "./IncidentHeader";
import IncidentBugs from "./IncidentBugs";
import IncidentLinkedItems from "./IncidentLinkedItems";
import IncidentTimeline from "./IncidentTimeline";
import IncidentRootCause from "./IncidentRootCause";
import IncidentPostmortem from "./IncidentPostmortem";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import classNames from "./IncidentDetails.module.scss";

export interface IIncidentDetailsProps {
  incident: IIncident;
}

export interface IIncidentDetailsStateProps {
  loading: boolean;
  incidentDetailsError: string;
  isSmallScreen: boolean;
}

export class IncidentDetails extends React.Component<IIncidentDetailsProps & IIncidentDetailsStateProps> {
  render() {
    const { incident, loading, incidentDetailsError, isSmallScreen } = this.props;

    if (!incident) {
      return null;
    }

    return (
      <div className={classNames.root}>
        <IncidentHeader incident={incident} />
        {loading && (
          <Spinner
            size={SpinnerSize.large}
            styles={{
              root: classNames.spinner,
              circle: classNames.spinnerCircle,
              label: classNames.spinnerLabel,
            }}
            label="Loading incident..."
          />
        )}
        {!loading &&
          (incidentDetailsError ? (
            <pre className={classNames.error}>{incidentDetailsError}</pre>
          ) : (
            <div>
              <IncidentTimeline incident={incident} isSmallScreen={isSmallScreen} />
              {incident.summary && (
                <Tile
                  title="Summary"
                  applyContentStyle={true}
                  commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}
                >
                  <div dangerouslySetInnerHTML={{ __html: incident.summary }} />
                </Tile>
              )}
              <IncidentRootCause incident={incident} />
              <IncidentPostmortem incident={incident} />
              <IncidentBugs incident={incident} />
              <IncidentLinkedItems incident={incident} />
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: IState): IIncidentDetailsStateProps => ({
  loading: state.modules.loading_incident_details,
  incidentDetailsError: state.modules.errors[errorType.incidentDetails],
  isSmallScreen: state.app.is_small_screen,
});

export default connect(mapStateToProps)(IncidentDetails);
