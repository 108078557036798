import { ICommandBarProps } from "@fluentui/react";
import { convertUtcToLocalTime, getFormatContent } from "../../shared/utilities/miscHelper";
import { getRefreshButton } from "../common/helper";
import { ITeam, ITemplate } from "../common/interfaces";
import { IHealthMetric, IHealthMetricGroup, IHealthMetricSet, IHealthMetricType } from "./HealthDashboard.types";
import { fetchIncidentsOpenAiSummary } from "../../api/radarApi";
import { ChatRole, IChatMessage } from "../../shared/components/Chat/Chat.types";
import { IIncident } from "../incidents/interfaces";
import moment from "moment";

export const allTeamName = "All";
export const closeConfidenceIndexMetricName = "Close Confidence Index";
export const closeConfidenceIndexTypeId = 1000;
export const daysLeftMECloseMetricName = "Days Left for ME Close";
export const daysLeftMECloseTypeId = 1001;

export const getTeams = (teams: ITeam[], metricGroup: IHealthMetricGroup): ITeam[] =>
  teams
    ?.filter(
      (team) =>
        team.healthMetrics?.length &&
        team.healthMetrics.find((metric) => !metric.disabled && metric.metricGroup === metricGroup?.id)
    )
    .map((team) => {
      let teamSettings = metricGroup?.teams?.find((metricGroupTeam) => metricGroupTeam.id === team.id);
      return { ...team, ...teamSettings };
    })
    .sort((a, b) =>
      a["healthMetricsSortOrder"] > b["healthMetricsSortOrder"]
        ? 1
        : a["healthMetricsSortOrder"] < b["healthMetricsSortOrder"]
        ? -1
        : 0
    );

export const getMetricTypes = (
  teams: ITeam[],
  metricGroup: IHealthMetricGroup,
  healthMetricTypes: IHealthMetricType[],
  hasSubType: boolean
): IHealthMetricType[] => {
  let metricTypes: IHealthMetricType[] = [];

  teams.forEach((team) => {
    team.healthMetrics.forEach((metric) => {
      let healthMetricType = healthMetricTypes.find((metricType) => metricType.id === metric.metricType);

      if (
        !metric.disabled &&
        metric.metricGroup === metricGroup?.id &&
        healthMetricType &&
        !metricTypes.find((metricType) => metricType.id === healthMetricType.id) &&
        healthMetricType.id !== closeConfidenceIndexTypeId &&
        healthMetricType.name !== closeConfidenceIndexMetricName &&
        ((hasSubType && metric.metricSubType) || (!hasSubType && !metric.metricSubType))
      ) {
        metricTypes.push(healthMetricType);
      }
    });
  });

  return metricTypes;
};

export const getTeamTitle = (teams: ITeam[], metricGroup: IHealthMetricGroup): string => {
  // Only use the first team if more are defined.
  let team = teams?.length && teams[0],
    groupTeam = team && metricGroup?.teams?.find((groupTeam) => groupTeam.id === team.id);

  return groupTeam?.teamTitle || team?.name;
};

export const getMetricSubTypesForExcelExport = (metricSubTypes: IHealthMetricType[]): IHealthMetricType[] =>
  metricSubTypes?.filter((subType) => !subType.hideInExcelExport && !subType.disabled);

export const getStandardTemplate = (metric, standardTemplates: ITemplate[], fieldName: string): ITemplate =>
  metric && metric[fieldName] && standardTemplates?.find((template) => metric[fieldName] === template.name);

// Note: For backward compatibility, we are keeping the dash before the metric sub type even if it's not defined.
export const getMetricId = (metricDefinition, team: ITeam): string =>
  metricDefinition?.id ||
  `healthMetrics-${team.id}-${metricDefinition?.metricType || ""}-${metricDefinition?.metricSubType || ""}${
    metricDefinition?.metricChildType ? "-" + metricDefinition?.metricChildType : ""
  }`;

export const getTargetMetric = (teams: ITeam[], healthMetrics: IHealthMetric[], metricTypeId, metricTypeName) => {
  let result;

  teams?.forEach((team) => {
    if (team.name === allTeamName) {
      team.healthMetrics?.forEach((metricDefinition) => {
        if (
          (metricDefinition.metricType === metricTypeId || metricDefinition.name === metricTypeName) &&
          !metricDefinition.metricSubType
        ) {
          var metricId = getMetricId(metricDefinition, team),
            metricValues = healthMetrics?.find((metric) => metric.id === metricId);

          result = { ...metricDefinition, ...metricValues };
        }
      });
    }
  });

  return result;
};

export const getCloseConfidenceIndexMetric = (teams: ITeam[], healthMetrics: IHealthMetric[]) =>
  getTargetMetric(teams, healthMetrics, closeConfidenceIndexTypeId, closeConfidenceIndexMetricName);

export const getDaysLeftMECloseMetric = (teams: ITeam[], healthMetrics: IHealthMetric[]) =>
  getTargetMetric(teams, healthMetrics, daysLeftMECloseTypeId, daysLeftMECloseMetricName);

export const getCommandBarProps = (
  onExcelExportClick,
  downloadingExcel: boolean,
  onSubscribeClick,
  subscriptionUrl: string
): ICommandBarProps => {
  var commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [
      {
        key: "excelExport",
        name: downloadingExcel ? "Downloading" : "Excel Export",
        title: "Export the current data to Excel",
        iconProps: {
          iconName: "ExcelDocument",
        },
        disabled: downloadingExcel,
        onClick: onExcelExportClick,
      },
      getRefreshButton(() => {
        if (window.location.href.indexOf("?refresh") < 0) {
          window.location.href = `${window.location.href}?refresh`;
        }
        window.location.reload();
      }),
    ],
  };

  if (subscriptionUrl) {
    commandBarProps.farItems.unshift({
      key: "subscribe",
      name: "Subscribe",
      title: "Subscribe to email notification for this health dashboard",
      iconProps: {
        iconName: "Subscribe",
      },
      onClick: onSubscribeClick,
    });
  }

  return commandBarProps;
};

export const getDownloadMetricData = (metricGroupTables: any[]): any[] => {
  let result = [];

  metricGroupTables?.forEach((table) => {
    let tableData = [],
      tableHeaders = { c0: "Metric Name" },
      firstTeam = table.teams[0];

    table.metricTypes?.forEach((metricType) => {
      let tableRow = { c0: metricType.name },
        trendCharts = table.trendCharts;

      table.metricSubTypes?.length
        ? table.metricSubTypes
            .filter(
              (subType) =>
                !subType.teams?.length || subType.teams.find((team) => team?.id === firstTeam?.id)?.metricSet === 1
            )
            .forEach((metricSubType, index) => {
              tableHeaders[`c${index + 1}`] = metricSubType.name;

              setDownloadMetricCellData(
                table,
                firstTeam,
                tableRow,
                index,
                metricType.id,
                metricSubType.id,
                trendCharts
              );
            })
        : table.teams?.forEach((team, index) => {
            tableHeaders[`c${index + 1}`] = team.name;

            setDownloadMetricCellData(table, team, tableRow, index, metricType.id, undefined, trendCharts);
          });

      tableData.push(tableRow);
    });

    tableData.unshift(tableHeaders);
    result.push({ tableName: table.tableName, tableData });
  });

  return result;
};

const setDownloadMetricCellData = (table, team, tableRow, index, metricTypeId, metricSubTypeId?, trendCharts?) => {
  let metricDefinition = team.healthMetrics?.find(
      (metricDef) =>
        !metricDef.disabled &&
        metricDef.metricType &&
        metricDef.metricType === metricTypeId &&
        (!metricDef.metricSubType || metricDef.metricSubType === metricSubTypeId)
    ),
    metricId = getMetricId(metricDefinition, team),
    metricValues = table.healthMetrics?.find((metric) => metric.id === metricId),
    metric = { ...metricDefinition, ...metricValues },
    template = metric?.template || getStandardTemplate(metric, table.standardTemplates, "standardTemplateName")?.value;

  // eslint-disable-next-line no-template-curly-in-string
  template = template?.replace("${this.trendIndicator}", "");

  // Generate data cell content text based on the template.
  let metricText: string = template && getFormatContent({ item: metric, template }),
    metricContent: string = metricText
      ?.replace(/(<([^>]+)>)/gi, "")
      .replace("&nbsp;", " ")
      .replace("&#10004;", "No anomaly");

  if (metricContent?.indexOf("ms-Icon--Warning12") >= 0) {
    metricContent = "Anomaly exists";
  }

  // If no content text is detected, check if there is HTML tag with title attribute defined.
  if (!metricContent?.trim().length && metricText) {
    let matchedTitles = metricText.match(/.*<.*title='(.*)'.*/i);

    if (matchedTitles?.length >= 2) {
      metricContent = matchedTitles[1]; // Use only the first title match.
    }
  }

  // Generate data cell content based on the trend chart, if it's defined.
  let trendChart = trendCharts?.find((chart) => chart.teamId === team.id && chart.metricId === metricId),
    trendChartPlacement = metric["trendChartPlacement"] || "tooltip",
    showTrendChartInText = trendChartPlacement === "text" && !!trendChart;

  if (showTrendChartInText) {
    metricContent = trendChart.data;
  }

  tableRow[`c${index + 1}`] = metricContent;
};

export const getLastRunTooltipContent = (metric, tooltipText, classNames) =>
  `<div class='${classNames.tooltipRunOn}' style='${
    tooltipText ? "padding-top:10px" : ""
  }'>Last run : ${convertUtcToLocalTime(metric.runOn)}</div>`;

export const getMetricTemplates = (metric, standardTemplates) => {
  let template = metric?.template || getStandardTemplate(metric, standardTemplates, "standardTemplateName")?.value,
    badgeTemplate =
      metric?.badgeTemplate || getStandardTemplate(metric, standardTemplates, "badgeStandardTemplateName")?.value,
    tooltipTemplate =
      metric?.tooltipTemplate || getStandardTemplate(metric, standardTemplates, "tooltipStandardTemplateName")?.value;

  return [template, badgeTemplate, tooltipTemplate];
};

export const getControlDataTableText = (controls: any[]): string => {
  let result =
    "Control ID\\tControl Page Link\\tName\\tDelta Amount in USD\\tImpact Score\\tTeam\\tDescription\\tResult Status\\n";

  controls?.forEach((control) => {
    result += control.id + "\\t";
    result += `${window.location.origin}/#/control/${control.id}\\t`;
    result += control.name + "\\t";
    result += control.cumulativeDelta + "\\t";
    result += control.impactScore + "\\t";
    result += control.owningTeam + "\\t";
    result += control.description + "\\t";
    result += control.resultStatus + "\\n";
  });

  return result + "\\n";
};

export const getIncidentDataTableText = (incidents: IIncident[]): string => {
  let result =
    "Incident ID\\tLink\\tTitle\\tSeverity\\tFinancial Impact $\\tCurrent Impact $\\tOwning Team\\tOwner\\tSummary\\n";

  incidents.forEach((incident) => {
    if (incident.incidentId) {
      result += incident.incidentId + "\\t";
      result += `https://portal.microsofticm.com/imp/v3/incidents/details/${incident.incidentId}/home\\t`;
      result += incident.title + "\\t";
      result += incident.severity + "\\t";
      result += incident.financialImpactUSD + "\\t";
      result += incident.currentFinancialImpactUSD + "\\t";
      result += incident.owningTeamId + "\\t";
      result += incident.owningContactAlias + "\\t";
      result += (incident.openAiSummary || "No summary info is available for this incident.") + "\\n";
    }
  });

  return result + "\\n";
};

export const getMetricSets = (metricTypes: IHealthMetricType[], metricSets: IHealthMetricSet[]) =>
  metricTypes?.length &&
  metricTypes
    ?.filter((metricType) => metricType.metricSet)
    ?.map((metricDef) => metricDef.metricSet)
    ?.filter((metricSetId, index, array) => array.indexOf(metricSetId) === index)
    ?.filter((metricSetId) => metricSets?.find((metricSet) => !metricSet.disabled && metricSet.id === metricSetId))
    ?.map((metricSetId) => metricSets?.find((metricSet) => metricSet.id === metricSetId));

export const getCopilotDataText = (data): string => {
  let cciText = data?.closeConfidenceIndexValueText?.toLowerCase(),
    fmeStatus =
      cciText.indexOf("low") >= 0 ? "at risk" : cciText.indexOf("medium") >= 0 ? "at slight risk" : "on track",
    result = `The current date and time is ${moment().format("MMMM D, yyyy hh:mm A")}.\\n\\n`;

  result +=
    "You are a Month End Financial Close AI assistant that helps people know more about Financial Month Close activity based on the values of the Close metrics, their daily, monthly trends, and active incidents as given below.\\n\\n";

  result += `The ${data?.currentMonthName} Financial Month End Close (FME) Activities in Horizon and MS Sales are ${fmeStatus}.\\n\\n`;

  result +=
    "CCI is an indicator of how Financial Month End (FME) close activities in Horizon Revenue Recognition and MS Sales are going. It indicates how many of those activities are on track to ensure the successful closing of our financial books for the month or quarter or year with no or minimal impacting issues. It is calculated by the number of active livesite severity 0/1/2/25 incidents, failed automated revenue controls, failed MS Sales Engg audits, pending invoices $, Billing vs Recognized $ variance, Billing vs Deferral $ variance, and Deferral vs Recognized $ variance. The Financial Month End period is tracked until the end of the current month as well as the first 3 days of the next month. Month Ends such as that of March, June, September and December are considered as Financial Quarter Ends during which it is even more critical for all the metrics to be within target. Microsoft's Current Financial Year goes from July of the previous year to June of the current year. For example, Fiscal Year 2024 goes from July 2023 to June 2024. The Close Confidence Index is calculated only during the last 10 days of the month. When the Close Confidence Index is Very High, FME close activities are on track and we are likely to close without any major impacting issues. If the Close Confidence Index is High, FME close activities are on track and we likely have a few negligible numbers of impacting issues likely to be resolved by the month/quarter/year end. If the Close Confidence Index is Medium, FME close activities are slightly at risk and we likely have a few impacting issues that are necessary to be resolved by the month/quarter/year end. The lower the Confidence index, FME close activities are at risk and the more significant $ impacting issues we are experiencing for the Financial Close that we have a low confidence of resolution on before the month/quarter/year end.\\n\\n";

  result +=
    "In Financial context, the metric values within circular parenthesis, indicate negative values. The metrics may be shortened using shorthand notations such as 'k' for denoting thousands and 'M' for denoting Million.\n\n";

  result += "Horizon Revenue Recognition Metrics (also known as Financials Team metrics):\\n\\n";

  let financialCloseData = data?.metrics?.length > 0 && data.metrics[0]?.tableData;
  result += getMetricDataTableText(financialCloseData);

  result +=
    "\\nIncident free revenue % is the percentage of revenue that is processed without any open issues on it.\\n\\n";

  result +=
    "\\nBilling vs Recognized $ variance is abbreviated as BvR variance. Billing vs Deferral $ variance is abbreviated as BvD variance. Deferral vs Recognized $ variance is abbreviated as DvR variance. BvDvR variance in the Billing vs Deferral vs Recognized variance.\\n\\n";

  if (data?.horizonRevenueControls?.length) {
    result +=
      "\\nBelow is the list of all the ME automated Revenue controls. The revenue controls with ResultStatus as 'fail' are the revenue controls that are currently failing. In the Horizon Revenue Recognition Metrics table, the metric 'Percentage %Failed ME automated Revenue Control metric' is based on the Revenue Controls.\\n\\n";

    result += getControlDataTableText(data.horizonRevenueControls);

    result +=
      "\\nWhen displaying the above Revenue control info, always show the control ID, name and delta. Have the hyperlink around the control ID.\\n\\n";
  }

  result += "Revenue Reporting Metrics (also known as MS Sales Team metrics):\\n\\n";

  let msSalesCloseData = data?.metrics?.length > 1 && data.metrics[1]?.tableData;
  result += getMetricDataTableText(msSalesCloseData);

  result +=
    "\\nThe metric 'MS Sales Publish SLA Met' indicates the number of minutes MS Sales Publish SLA was met for that respective month close. The value should be positive or greater than 0 to say that it is meeting target. A negative value denotes the MS Sales Publish was after the SLA and not within target. This metric should only be mentioned in responses on the first or second day of the month.\\n\\n";

  if (data?.msSalesEngAuditFailedControls?.length) {
    result +=
      "\\nBelow is the list of all the MS Sales Engineering (Engg.) Audit Controls. The MS Sales audit controls with ResultStatus as 'fail' are the audit controls that are currently failing. In the Revenue Reporting Metrics table, the metric 'Percentage % Failed MS Sales Engg Audits controls' is based on MS Sales Audits or audit controls.\\n\\n";

    result += getControlDataTableText(data.msSalesEngAuditFailedControls);

    result +=
      "\\nWhen displaying the above Failed MS Sales control info, always show the control ID and name. Have the hyperlink around the control ID.\\n\\n";
  }

  if (data?.msSalesSeatCountFailedControls?.length) {
    result +=
      "\\nHere is a list of all the NCE Seat count Controls and the controls with ResultStatus field as 'fail' are the seat controls that are currently failing. In the Revenue Reporting Metrics table, the metric 'Percentage % NCE Failed seat count controls' is based on the Seat count controls.\\n\\n";

    result += getControlDataTableText(data.msSalesSeatCountFailedControls);

    result +=
      "\\nWhen displaying the above Failed Seats count control info, always show the control ID and name. Have the hyperlink around the control ID.\\n\\n";
  }

  if (data?.metricIncidents?.length) {
    result +=
      "\\nThe Alert Incident # column gives the IcM number that explains the issue being worked on, with what severity, what the resolution steps are. These may further be investigated on how those incidents are affecting financial Month Close.\\n\\n";

    var livesiteIncidents = data.metricIncidents.filter((incident) => !incident.isAlert);

    if (livesiteIncidents?.length) {
      result += "Enumerated list of Incidents from all Livesite Views are given below.\\n\\n";

      result += getIncidentDataTableText(livesiteIncidents);
    }

    var controlIncidents = data.metricIncidents.filter((incident) => incident.isAlert);

    if (controlIncidents?.length) {
      result += "Enumerated list of Incidents from all Controls are given below.\\n\\n";

      result += getIncidentDataTableText(controlIncidents);
    }

    result +=
      "\\n\\nWhenever incidents are listed, they are displayed with their incident ID with a hyperlink, their titles, their severity, Current Impact $ value and financial Impact $ value, that are likely to affect month end financial Close. The hyperlink is always on the incident ID. The Current Impact $ value and financial Impact $ value indicate how much impact the incidents currently has had and how much impact the incident is likely to have respectively.\\n\\n";

    result +=
      "\\n\\nIncidents may have the following abbreviations in its title or summary and here is what they mean -\\n";
    result +=
      "* MACC - Microsoft Azure Commit to Consume. It is a type of contractual commitment that customers make with Microsoft for specific amounts of Azure spend over time. Previously known as Commit to Consume.\\n";
    result +=
      "* MCA-E - Microsoft Customer Agreement or Modern Customer Agreement for Enterprise is a simplified purchase agreement that's presented, accepted, and stored in a completely digital experience. This evergreen agreement streamlines the customer buying process.\\n";
    result +=
      "* ACO - Azure Credit Offer. It is an initiative to allow more flexible use of Azure credits to win and accelerate Azure consumption. It is based on a nomination criterion after a few verifications.\\n";
    result +=
      "* CSP - Cloud Solution Provider (CSP) enables partners to provide 1-Tier (Direct) and 2-Tier (Indirect) Microsoft business models to end customers. This was in the legacy commerce stack. The new partner programs in the New Commerce or Modern Commerce stack is called the Partner Led Sales Motion.\\n";
    result +=
      "* Collector++: The OneCollector (also sometimes called 'Common Collector' or 'Collector++') is a single front end service for ingesting events into both the Asimov, Aria or Geneva pipelines.\\n";
    result +=
      "* FDL - Finance Data Lake (FDL) is the single source of truth for finance data. All finance data sources including SAP, C+E, Finance Engineering systems, etc. publish data into the lake for consumption by other engineering teams or end users.\\n";
    result +=
      "* Horizon - Horizon is the revenue recognition system for all sales of Microsoft and 3rd party products to our Commercial (Direct, Field, Partner, Enterprise, Gov) and Consumer markets in the New Commerce ecosystem.\\n";
    result +=
      "* The Limited Risk Distributor (LRD) model means that invoicing and taxation is supported by the local Microsoft sales entities. Which also means in the customer agreement, purchasing related terms are supported by the in-country local Microsoft sales entity, while licensing related terms are supported by the Microsoft licensing entities—Microsoft Ireland Operations Limited (MIOL) for Europe and Asia, and Microsoft Corporation for North America and Latin America.\\n";
  }

  return result;
};

export const getCopilotExtraChatMessages = (data): IChatMessage[] => [
  { role: ChatRole.user, content: `How is the ${data?.currentMonthName} month Close going?`, hidden: true },
  {
    role: ChatRole.assistant,
    hidden: true,
    content:
      `Begin this response by stating whether the ${data?.currentMonthName} FME Close activities in Horizon and MS Sales are on track, slightly at risk or at risk.\\n\\n` +
      "Follow that with how the following metrics are performing -\\n" +
      "1. Number of Active ME Livesite Incidents combined from both Revenue Recognition and Revenue Reporting tables.\\n" +
      "2. Billing vs Recognized $ variance\\n" +
      "3. Pending invoices or A/R variance\\n" +
      "4. % Failed ME automated revenue controls.\\n" +
      "5. % Failed MS Sales Engg Audits.\\n\\n" +
      "Apart from the above 5 metrics, if there are any other metrics that are not meeting target from either Horizon Revenue Recognition and Revenue Reporting tables, then continue the list with a short summary of those metrics not meeting target.\\n\\n" +
      "Follow that with a bulleted list of active ME Impacting incidents with severity 1 or 2 or 25. Always list the incidents with an Incident ID with a hyperlink, their severity, their titles, current impact $ value and financial Impact $ value, that are likely to affect month end financial Close.\\n\\n" +
      "Follow that with a bulleted list of all the ME automated Revenue Controls that are failing. Skip this paragraph if there are no ME automated revenue controls that are failing.\\n\\n" +
      "Follow that with a bulleted list of all the MS Sales Engg Audit controls that are failing. Skip this paragraph if there are no MS Sales Engg Audits that are failing.\\n\\n" +
      "Follow that with a bulleted list all the Seat count Controls that are not meeting target. Skip this paragraph if there are no seat controls that are failing.\\n\\n" +
      "Check if current date is the first of the month, then follow that with a sentence stating the expected start time of the MS Sales Publish indicated by the 'Expected start time' datetime field of the Revenue Reporting metric 'MS Sales Publish SLA Met'. If the current time is past the expected start time, state that the publish has already started at expected start time. Skip this section on days of the month other than first.\\n\\n" +
      "Check if current date is the second or third day of the month, then follow that with a sentence stating how many minutes before or after SLA target did the MS Sales Publish finish. This duration can be obtained from the 'MS Sales Publish SLA Met' metric of the Revenue Reporting table. Skip this section on days of the month other than second or third.\\n\\n" +
      "Finally, state the overall Close Confidence Index that would indicate how likely we are to close our financial month without any issues.",
  },
  { role: ChatRole.user, content: "What are the incidents with financial impact greater than $1M?", hidden: true },
  {
    role: ChatRole.assistant,
    hidden: true,
    content:
      "If there are any active incidents with financial impact $ value greater than $1M from the list of all enumerated incidents, then list them with their incident ID with a hyperlink, their titles, their severity, Current Impact $ value and financial Impact $ value, that are likely to affect month end financial Close. The hyperlink is always on the incident ID.",
  },
  { role: ChatRole.user, content: "What are the incidents with current impact greater than $1M?", hidden: true },
  {
    role: ChatRole.assistant,
    hidden: true,
    content:
      "If there are any active incidents with current impact $ value greater than $1M from the list of all enumerated incidents, then list them with their incident ID with a hyperlink, their titles, their severity, Current Impact $ value and financial Impact $ value, that are likely to affect month end financial Close. The hyperlink is always on the incident ID.",
  },
  { role: ChatRole.user, content: "What are the currently active ME impacting incidents?", hidden: true },
  {
    role: ChatRole.assistant,
    hidden: true,
    content:
      "If there are any active incidents with severity 1 or 2 or 25 from the list of all enumerated incidents, then list them with their incident ID with a hyperlink, their severity, their titles, Current Impact $ value and financial Impact $ value, that are likely to affect month end financial Close. The hyperlink is always on the incident ID.",
  },
];

const getMetricDataTableText = (metricDataTable: any[]): string => {
  let result = "";

  if (metricDataTable?.length > 1) {
    let dataText = "";

    Object.keys(metricDataTable[0]).forEach((key) => {
      dataText !== "" && (dataText += "\\t");
      dataText += metricDataTable[0][key];
    });

    result += dataText + "\\n";

    for (let i = 1; i < metricDataTable.length; i++) {
      let dataRow = metricDataTable[i],
        dataText = "";

      Object.keys(dataRow).forEach((key) => {
        dataText !== "" && (dataText += "\\t");
        dataText += typeof dataRow[key] === "string" ? dataRow[key] : JSON.stringify(dataRow[key]);
      });

      result += dataText + "\\n";
    }

    result += "\\n";
  } else {
    result += "No metric data is found.\\n\\n";
  }

  return result;
};

export const incidentIdPropNames = [
  "incidentId",
  "sev0Incidents",
  "sev1Incidents",
  "sev2Incidents",
  "sev3Incidents",
  "sev4Incidents",
];

export const getMetricIncidents = (healthMetrics, refresh: boolean): Promise<IIncident[]> =>
  new Promise((resolve, reject) => {
    let incidentIds = [],
      alertIncidentIds = [];

    healthMetrics
      ?.filter((healthMetric) => healthMetric.statsType === "Financials" || healthMetric.statsType === "MS Sales")
      .forEach((healthMetric) => {
        Object.keys(healthMetric)?.forEach((metricProp) => {
          if (incidentIdPropNames.indexOf(metricProp) >= 0 && healthMetric[metricProp]?.length) {
            healthMetric[metricProp].split(",").forEach((incidentId) => {
              if (incidentIds.indexOf(incidentId) < 0) {
                incidentIds.push(incidentId);

                if (healthMetric.incidentId) {
                  alertIncidentIds.push(incidentId);
                }
              }
            });
          }
        });
      });

    if (incidentIds.length) {
      fetchIncidentsOpenAiSummary(incidentIds.join(","), refresh)
        .then((result: any) => {
          result?.forEach((incident) => {
            if (alertIncidentIds.indexOf(incident.incidentId) >= 0) {
              incident.isAlert = true;
            }
          });

          resolve(result);
        })
        .catch((error) => reject(error));
    } else {
      resolve([]);
    }
  });

export const getHorizonRevenueControls = (healthMetrics: any[]): any[] =>
  healthMetrics
    ?.filter((metric) => metric.controlId && !metric.offline && /^healthMetrics-\d*-\d*-2$/.test(metric.id))
    .map((metric) => ({
      id: metric.controlId,
      name: metric.controlName,
      cumulativeDelta: metric.delta,
      impactScore: metric.impactScore,
      owningTeam: metric.owningTeam,
      resultStatus: metric.resultStatus,
    }));

export const getCurrentMonthName = (healthMetrics: any[]): string =>
  healthMetrics?.find((metric) => /^healthMetrics-\d*-1001-$/.test(metric.id))?.currentMonth || "current";
