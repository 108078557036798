import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Pivot, PivotItem, IPivotItemProps } from "@fluentui/react/lib/Pivot";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { actionCreator } from "../duck";
import { IState } from "../../reducers/interfaces";
import Report from "./Report";
import { IReportGroup, ReportType } from "./interfaces";
import { errorType } from "../interfaces";
import PowerbiReport from "./PowerbiReport";
import { getQueryParam } from "../../shared/utilities/urlHelper";
import { MSSalesAccessDeniedMessage } from "./MSSalesAccessDeniedMessage";
import classNames from "./ReportGroup.module.scss";
import { convertUtcToLocalTime } from "../../shared/utilities/miscHelper";
import IncidentViewReport from "./IncidentViewReport";

export interface IReportGroupStateProps {
  reportGroup: IReportGroup;
  loading: boolean;
  error: string;
  selectedReportId: string;
}

export interface IReportGroupDispatchProps {
  getReportGroup: (reportGroupId: string) => void;
  selectReport: (reportId: string) => void;
}

export interface IReportGroupProps {
  reportGroupId: string;
}

export class ReportGroup extends React.Component<
  IReportGroupProps & IReportGroupStateProps & IReportGroupDispatchProps & RouteComponentProps
> {
  componentDidMount() {
    const { reportGroupId, getReportGroup } = this.props;

    if (reportGroupId) {
      getReportGroup(reportGroupId);
    }
  }

  render() {
    const { reportGroup, loading, error, location, selectedReportId } = this.props;

    if (loading)
      return (
        <Spinner
          styles={{
            root: classNames.spinner,
            circle: classNames.spinnerCircle,
            label: classNames.spinnerLabel,
          }}
          label="Loading report group..."
          role="alert"
        />
      );

    if (error) return <div className={classNames.error} dangerouslySetInnerHTML={{ __html: error }} />;

    if (!reportGroup || !reportGroup.reports || !reportGroup.reports.length) return null;

    const { reports, description, owner, hideGroupHeader, hideReportHeader, doesUserHasFullMSSalesAccess } =
      reportGroup;

    const selectedKey = getQueryParam("reportId", location) || selectedReportId || undefined;

    let pivotItemClassNames = [classNames.pivotItem];
    hideReportHeader && pivotItemClassNames.push(classNames.headerLessPivotItem);

    var pivotItems = reports.map((report, index) => {
      const isUserRestricted = report.isRestricted && !doesUserHasFullMSSalesAccess;

      return (
        <PivotItem
          key={report.id}
          itemKey={report.id}
          className={pivotItemClassNames.join(" ")}
          headerText={report.shortName || report.name || `Report #${index + 1}`}
          itemIcon={report.hideForViewers && "UserOptional"}
          onRenderItemLink={report.hideForViewers && this.onRenderPivotItemLink}
        >
          {isUserRestricted ? (
            <MSSalesAccessDeniedMessage objectTypeName="report" />
          ) : report.type === ReportType.powerbi ? (
            <PowerbiReport
              contentType={report.contentType}
              contentId={report.contentId}
              groupId={report.groupId}
              className={classNames.reportContainer}
            />
          ) : report.type === ReportType.incidentView ? (
            <IncidentViewReport incidentViewId={report.incidentViewId} />
          ) : (
            <Report reportId={report.id} />
          )}
        </PivotItem>
      );
    });

    return (
      <div className={classNames.root}>
        {!hideGroupHeader && description && (
          <div className={classNames.reportDescription} dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <Pivot
          className={classNames.pivot}
          styles={{ root: hideReportHeader ? { display: "none" } : null }}
          linkSize="large"
          selectedKey={selectedKey}
          onLinkClick={this.onPivotLinkClick}
        >
          {pivotItems}
        </Pivot>
        <div className={classNames.footerInfo}>
          <div className={classNames.footerInfoItem}>Owner: {owner}</div>
          <div className={classNames.footerInfoItem}>
            Last Modified: {new Date(Number(reportGroup["_ts"]) * 1000).toLocaleString()} by{" "}
            {reportGroup["_modifiedBy"]}
          </div>
          {reportGroup["publishedOn"] && (
            <div className={classNames.footerInfoItem}>
              Last Published: {convertUtcToLocalTime(reportGroup["publishedOn"])} by {reportGroup["publishedBy"]}
            </div>
          )}
        </div>
      </div>
    );
  }

  onPivotLinkClick = (item: PivotItem) => {
    const { selectReport, history } = this.props;
    const selectedReportId = item.props.itemKey;

    selectReport(selectedReportId);
    history.push({ search: `?reportId=${selectedReportId}` });
  };

  onRenderPivotItemLink = (
    link: IPivotItemProps,
    defaultRenderer: (link: IPivotItemProps) => JSX.Element
  ): JSX.Element => <span title="Note: This report is currently hidden from viewers.">{defaultRenderer(link)}</span>;
}

const mapStateToProps = (state: IState): IReportGroupStateProps => ({
  reportGroup: state.modules.selected_report_group,
  loading: state.modules.loading_report_group,
  error: state.modules.errors[errorType.reportGroup],
  selectedReportId: state.modules.selected_report_id,
});

const mapDispatchToProps: IReportGroupDispatchProps = {
  getReportGroup: actionCreator.getReportGroup,
  selectReport: actionCreator.selectReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportGroup));
