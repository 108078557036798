import React, { useCallback, useEffect, useMemo, useState, memo, useContext } from "react";
import {
  ConfigItemType,
  fetchConfigItem,
  fetchConfigItems,
  fetchMeControls,
  fetchReport,
  fetchTeamHealthMetrics,
} from "../../api/radarApi";
import { IMetricFilter, IReport, ITeam, ITemplate } from "../common/interfaces";
import { IHealthMetric, IHealthMetricGroup, IHealthMetricSet, IHealthMetricType } from "./HealthDashboard.types";
import classNames from "./HealthDashboard.module.scss";
import { Pivot, PivotItem, Spinner, TooltipHost } from "@fluentui/react";
import {
  getCloseConfidenceIndexMetric,
  getDaysLeftMECloseMetric,
  getDownloadMetricData,
  getLastRunTooltipContent,
  getMetricSets,
  getMetricSubTypesForExcelExport,
  getMetricTemplates,
  getMetricTypes,
  getTeams,
  getTeamTitle,
  getMetricIncidents,
  getHorizonRevenueControls,
  getCurrentMonthName,
} from "./HealthDashboard.helper";
import HealthDashboardMetricsTable from "./HealthDashboardMetricsTable";
import { Gauge } from "../../shared/components/Visual/Gauge";
import { ReportBase } from "../common/ReportBase";
import { getFirstOfMonthISODate, getFormatContent } from "../../shared/utilities/miscHelper";
import HealthDashboardLinks from "./HealthDashboardLinks";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { History } from "react-router-dom/node_modules/history";
import { appInsights } from "../../app/telemetryHelper";
import { fetchTeamTrendCharts } from "../../api/radarApi";
import { getMetricTooltipProps } from "./HealthDashboardMetricsRow";
import MonthDropdown from "../../shared/components/Visual/MonthDropdown";
import HealthDashboardMetricSets from "./HealthDashboardMetricSets";
import { AppContext } from "../../app/App";
import { IIncident } from "../incidents/interfaces";
import AuditOfAudits from "./AuditOfAudits";
import CdtResults from "./CdtResults";

const monthFilterName = "selectedMonth";

export interface IHealthDashboardProps {
  selectedMetricGroupId: number;
  selectedMetricGroup: IHealthMetricGroup;
  healthMetricGroups: IHealthMetricGroup[];
  history?: History;
  hasMsSalesFullAccess?: boolean;
  onDownloadDataChange: (data) => void;
  onSelectMetricGroupId: (metricGroupId: number) => void;
}

export const HealthDashboard = (props: IHealthDashboardProps) => {
  const {
    selectedMetricGroupId,
    selectedMetricGroup,
    history,
    hasMsSalesFullAccess,
    healthMetricGroups,
    onDownloadDataChange,
    onSelectMetricGroupId,
  } = props;
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [healthMetrics, setHealthMetrics] = useState<IHealthMetric[]>([]);
  const [standardTemplates, setStandardTemplates] = useState<ITemplate[]>([]);
  const [healthMetricTypes, setHealthMetricTypes] = useState<IHealthMetricType[]>([]);
  const [healthMetricSets, setHealthMetricSets] = useState<IHealthMetricSet[]>([]);
  const [metricIncidents, setMetricIncidents] = useState<IIncident[]>([]);
  const [msSalesEngAuditFailedControls, setMsSalesEngAuditFailedControls] = useState<any[]>([]);
  const [msSalesSeatCountFailedControls, setMsSalesSeatCountFailedControls] = useState<any[]>([]);
  const [horizonRevenueControls, setHorizonRevenueControls] = useState<any[]>([]);
  const [currentMonthName, setCurrentMonthName] = useState<string>();
  const [report, setReport] = useState<IReport>(undefined);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [loadingTrendCharts, setLoadingTrendCharts] = useState<boolean>(false);
  const [loadingCopilotData, setLoadingCopilotData] = useState<boolean>(false);
  const [loadingAppSettings, setLoadingAppSettings] = useState<boolean>(false);
  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [error, setError] = useState();
  const [trendCharts, setTrendCharts] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<IMetricFilter[]>([
    { name: monthFilterName, value: getFirstOfMonthISODate(0) },
  ]);
  const { appState } = useContext(AppContext);
  const { useCopilot } = appState;

  const selectedMonth = useMemo(
    () => selectedFilters?.find((filter) => filter.name === monthFilterName)?.value,
    [selectedFilters]
  );

  const isCloseMetricGroup = selectedMetricGroup?.id === 1;

  const targetTeams = useMemo(() => getTeams(teams, selectedMetricGroup), [teams, selectedMetricGroup]),
    financialsTeam = useMemo(
      () => targetTeams.filter((team) => team.name.toLowerCase() === "financials"),
      [targetTeams]
    ),
    mssalesTeam = useMemo(() => targetTeams.filter((team) => team.name.toLowerCase() === "ms sales"), [targetTeams]);

  // This is used to reduce fetchTeamTrendCharts being called multiple times unnecessarily.
  const targetTeamIds =
    isCloseMetricGroup && financialsTeam?.length && mssalesTeam?.length
      ? `${financialsTeam[0].id},${mssalesTeam[0].id}`
      : targetTeams?.map((team) => team.id).join(",");

  const reportId = selectedMetricGroup?.reportId;
  const refresh = window.location.hash.indexOf("?refresh") >= 0;

  const financialsTableName = useMemo(
    () => getTeamTitle(financialsTeam, selectedMetricGroup),
    [financialsTeam, selectedMetricGroup]
  );
  const mssalesTableName = useMemo(
    () => getTeamTitle(mssalesTeam, selectedMetricGroup),
    [mssalesTeam, selectedMetricGroup]
  );

  const onMonthDropdownValueSelect = useCallback(
    (name: string, value: string) => {
      let selectedFiltersCopy = selectedFilters.slice(),
        monthFilter = selectedFiltersCopy?.find((filter) => filter.name === monthFilterName);

      if (monthFilter) {
        monthFilter.value = value;
      } else {
        selectedFiltersCopy.push({ name: monthFilterName, value });
      }

      setSelectedFilters(selectedFiltersCopy);
    },
    [selectedFilters]
  );

  // Load data from API.
  useEffect(() => {
    setLoadingAppSettings(true);
    setLoadingTeams(true);

    fetchConfigItem(ConfigItemType.AppSettings, "StandardTemplates", null, refresh).then((configItem: any) => {
      setStandardTemplates(configItem?.templates);

      fetchConfigItem(ConfigItemType.AppSettings, "HealthMetricTypes", null, refresh).then((configItem: any) => {
        setHealthMetricTypes(configItem?.settings);

        fetchConfigItem(ConfigItemType.AppSettings, "HealthMetricSets", null, refresh)
          .then((configItem: any) => setHealthMetricSets(configItem?.settings))
          .finally(() => setLoadingAppSettings(false));
      });
    });

    fetchConfigItems(ConfigItemType.Teams, refresh)
      .then((teams: any) => setTeams(teams))
      .finally(() => setLoadingTeams(false));
  }, [refresh]);

  // Load health metrics data from API.
  useEffect(() => {
    if (teams?.length) {
      setError(undefined);
      setReport(undefined);
      setHealthMetrics(undefined);
      setLoadingMetrics(true);

      let metricGroupFilters = selectedMetricGroup?.useMonthFilter ? selectedFilters : null;

      fetchTeamHealthMetrics(metricGroupFilters, refresh)
        .then((healthMetrics: any) => {
          setHorizonRevenueControls(getHorizonRevenueControls(healthMetrics));

          setCurrentMonthName(getCurrentMonthName(healthMetrics));

          setHealthMetrics(healthMetrics);

          // Capture user page view only if the user has access to the health metric data.
          appInsights.trackPageView({ name: "HealthDashboardPage" });

          if (useCopilot) {
            setLoadingCopilotData(true);

            getMetricIncidents(healthMetrics, refresh)
              .then((metricIncidents) => {
                metricIncidents?.length && setMetricIncidents(metricIncidents);
              })
              .finally(() => setLoadingCopilotData(false));

            fetchMeControls("21", refresh).then((meControls: any) => {
              if (meControls?.length) {
                let failedMeControls = meControls.filter((control) => control.resultStatus?.toLowerCase() === "fail");

                failedMeControls?.length && setMsSalesEngAuditFailedControls(failedMeControls);
              }
            });

            fetchMeControls("25", refresh).then((meControls: any) => {
              if (meControls?.length) {
                let failedMeControls = meControls.filter((control) => control.resultStatus?.toLowerCase() === "fail");

                failedMeControls?.length && setMsSalesSeatCountFailedControls(failedMeControls);
              }
            });
          }
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLoadingMetrics(false);
          setLoadingPage(false);
        });
    }
  }, [useCopilot, teams, selectedMetricGroup, selectedFilters, refresh]);

  // Load any trend chart data based on the selected metric groups, i.e. targetTeamIds.
  useEffect(() => {
    if (targetTeamIds?.length) {
      let charts = [];
      targetTeamIds?.split(",").forEach((teamId) => {
        setLoadingTrendCharts(true);
        fetchTeamTrendCharts(teamId, selectedMetricGroupId, refresh)
          .then((result: any) => {
            result?.length && (charts = charts.concat(result));
            charts.length && setTrendCharts(charts);
          })
          .catch((error) => console.log(error))
          .finally(() => setLoadingTrendCharts(false));
      });
    }
  }, [targetTeamIds, selectedMetricGroupId, refresh]);

  // Load report data if defined.
  useEffect(() => {
    setReport(undefined);

    if (reportId && healthMetrics?.length) {
      setLoadingReport(true);
      fetchReport(reportId.toString(), undefined, refresh)
        .then((report) => reportId && setReport(report))
        .finally(() => setLoadingReport(false));
    }
  }, [reportId, healthMetrics, refresh]);

  // Get the CCI metric
  const closeConfidneceIndexMetric = useMemo(
    () => getCloseConfidenceIndexMetric(teams, healthMetrics),
    [teams, healthMetrics]
  );

  const closeConfidenceIndexValueText =
    closeConfidneceIndexMetric &&
    getFormatContent({
      item: closeConfidneceIndexMetric,
      template: closeConfidneceIndexMetric?.template,
    });

  useEffect(() => {
    // These codes prepare the data for Excel download.  Delay this to give more priority to process data for immediate UI display.
    !loadingMetrics &&
      !loadingReport &&
      !loadingTrendCharts &&
      !loadingCopilotData &&
      closeConfidenceIndexValueText &&
      setTimeout(() => {
        let metrics = getDownloadMetricData(
          isCloseMetricGroup
            ? [
                {
                  tableName: financialsTableName,
                  teams: financialsTeam,
                  metricTypes: getMetricTypes(financialsTeam, selectedMetricGroup, healthMetricTypes, true),
                  metricSubTypes: getMetricSubTypesForExcelExport(selectedMetricGroup?.metricSubTypes),
                  healthMetrics,
                  standardTemplates,
                  trendCharts,
                },
                {
                  tableName: mssalesTableName,
                  teams: mssalesTeam,
                  metricTypes: getMetricTypes(mssalesTeam, selectedMetricGroup, healthMetricTypes, true),
                  metricSubTypes: getMetricSubTypesForExcelExport(selectedMetricGroup?.metricSubTypes),
                  healthMetrics,
                  standardTemplates,
                  trendCharts,
                },
              ]
            : [
                {
                  tableName: selectedMetricGroup?.name,
                  teams: targetTeams,
                  metricTypes: getMetricTypes(targetTeams, selectedMetricGroup, healthMetricTypes, false),
                  healthMetrics,
                  standardTemplates,
                  trendCharts,
                },
              ]
        );

        onDownloadDataChange({
          reportId,
          metrics,
          metricIncidents,
          msSalesEngAuditFailedControls,
          msSalesSeatCountFailedControls,
          horizonRevenueControls,
          currentMonthName,
          closeConfidenceIndexValueText,
        });
      }, 1000);
  }, [
    reportId,
    selectedMetricGroup,
    healthMetrics,
    standardTemplates,
    teams,
    healthMetricTypes,
    trendCharts,
    isCloseMetricGroup,
    targetTeams,
    financialsTeam,
    mssalesTeam,
    financialsTableName,
    mssalesTableName,
    loadingMetrics,
    loadingReport,
    loadingTrendCharts,
    loadingCopilotData,
    closeConfidenceIndexValueText,
    metricIncidents,
    msSalesEngAuditFailedControls,
    msSalesSeatCountFailedControls,
    horizonRevenueControls,
    currentMonthName,
    onDownloadDataChange,
  ]);

  // Change state when the metric group/tab is selected.
  const onPivotLinkClick = useCallback(
    (item: PivotItem) => {
      let metricGroupId = Number(item.props.itemKey);

      setLoadingPage(true);
      setHealthMetrics(undefined);
      setReport(undefined);
      onSelectMetricGroupId(metricGroupId);
      history.push(`${leftNavUrls.financialHealth.default}/${metricGroupId}`);
    },
    [history, onSelectMetricGroupId]
  );

  // Get the Days Left for ME Close metric
  const daysLeftMECloseMetric = useMemo(() => getDaysLeftMECloseMetric(teams, healthMetrics), [teams, healthMetrics]);

  // Get the Days Left for ME Close metric month text and remaining days.
  const [daysLeftMECloseMetricTitle, daysLeftMECloseRemainingDays, tooltipContent] = useMemo(() => {
    if (daysLeftMECloseMetric) {
      let [template, badgeTemplate, tooltipTemplate] = getMetricTemplates(daysLeftMECloseMetric, standardTemplates),
        metricTitle = template && getFormatContent({ item: daysLeftMECloseMetric, template }),
        remainingDays = badgeTemplate && getFormatContent({ item: daysLeftMECloseMetric, template: badgeTemplate }),
        tooltipContent =
          (tooltipTemplate && getFormatContent({ item: daysLeftMECloseMetric, template: tooltipTemplate })) || "";

      tooltipContent += getLastRunTooltipContent(daysLeftMECloseMetric, tooltipContent, classNames);

      return [metricTitle, remainingDays, tooltipContent];
    }
    return [undefined, undefined];
  }, [daysLeftMECloseMetric, standardTemplates]);

  // Get tooltipProps for Days Left for ME Close metric.
  const [daysLeftMECloseTooltipId, daysLeftMECloseTooltipProps] = useMemo(
    () => ["tooltip-daysLeftMEClose", getMetricTooltipProps(tooltipContent, classNames)],
    [tooltipContent]
  );

  // Get the CCI metric name and description as tooltip.
  const [closeConfidenceIndexMetricName, closeConfidenceIndexMetricDesc] = useMemo(() => {
    if (closeConfidneceIndexMetric) {
      let cciMetricType = healthMetricTypes.find(
        (metricType) => metricType?.id === closeConfidneceIndexMetric?.metricType
      );
      if (cciMetricType) {
        let description =
          cciMetricType.description +
          getLastRunTooltipContent(closeConfidneceIndexMetric, cciMetricType.description, classNames);
        return [cciMetricType.name, description];
      }
    }
    return [undefined, undefined];
  }, [healthMetricTypes, closeConfidneceIndexMetric]);

  if (loadingPage || loadingMetrics || loadingTeams || loadingAppSettings) {
    return <Spinner className={classNames.spinner} label="Loading health dashboard data..." labelPosition="right" />;
  } else {
    if (error) {
      return <div className={classNames.error}>{error}</div>;
    }

    if (!teams?.length) {
      return <div className={classNames.error}>Expected teams data are missing.</div>;
    }

    if (!healthMetricGroups?.length) {
      return <div className={classNames.error}>Expected healthMetricGroups data are missing.</div>;
    }

    if (!healthMetricTypes?.length) {
      return <div className={classNames.error}>Expected healthMetricTypes data are missing.</div>;
    }
  }

  return (
    <div className={classNames.root}>
      <Pivot
        linkSize="large"
        className={classNames.pivot}
        selectedKey={selectedMetricGroupId.toString()}
        onLinkClick={onPivotLinkClick}
      >
        {healthMetricGroups
          .filter(
            (metricGroup) =>
              !metricGroup.disabled &&
              (!metricGroup.independentPage || metricGroup.id === selectedMetricGroupId) &&
              (!selectedMetricGroup?.independentPage || metricGroup.id === selectedMetricGroupId)
          )
          .map((metricGroup) => {
            let metricGroupName = metricGroup.name,
              metricGroupId = metricGroup.id?.toString(),
              targetTeams = getTeams(teams, metricGroup),
              pageNote = metricGroup.pageNote,
              isRestrictedAccess = metricGroup.restrictedAccess && !hasMsSalesFullAccess;

            const includeMetricGroup = metricGroup.reportId || metricGroup.contentType;

            if (!targetTeams?.length && !includeMetricGroup) {
              return null;
            }

            const metricTypesWithSubTypes = getMetricTypes(targetTeams, metricGroup, healthMetricTypes, true);
            const metricTypesWithoutSubTypes = getMetricTypes(targetTeams, metricGroup, healthMetricTypes, false);

            if (!metricTypesWithSubTypes?.length && !metricTypesWithoutSubTypes?.length && !includeMetricGroup) {
              return null;
            }

            const metricSubTypes = metricGroup.metricSubTypes;
            const teamsWithSubTypes = targetTeams?.filter((team) =>
              team.healthMetrics?.find((metric) => metric.metricGroup === metricGroup.id && metric.metricSubType)
            );
            const teamsWithoutSubTypes = targetTeams?.filter((team) =>
              team.healthMetrics?.find(
                (metric) =>
                  !metric.disabled &&
                  metricTypesWithoutSubTypes.find((metricType) => metric.metricType === metricType.id) &&
                  metric.metricGroup === metricGroup.id &&
                  !metric.metricSubType
              )
            );

            const financialsTeam = targetTeams.filter((team) => team.name.toLowerCase() === "financials");
            const financialsCloseMetricTypes =
              isCloseMetricGroup && getMetricTypes(financialsTeam, metricGroup, healthMetricTypes, true);
            const financialsMetricSets = getMetricSets(financialsCloseMetricTypes, healthMetricSets);

            const mssalesTeam = targetTeams.filter((team) => team.name.toLowerCase() === "ms sales");
            const mssalesCloseMetricTypes =
              isCloseMetricGroup && getMetricTypes(mssalesTeam, metricGroup, healthMetricTypes, true);
            const mssalesMetricSets = getMetricSets(mssalesCloseMetricTypes, healthMetricSets);

            return (
              <PivotItem
                key={metricGroupId}
                itemKey={metricGroupId}
                headerText={metricGroupName}
                className={classNames.pivotItem}
              >
                {isRestrictedAccess ? (
                  <div className={classNames.error}>This page is restricted to those with MS Sales WW access only.</div>
                ) : metricGroup.contentType === "auditOfAudits" ? (
                  <AuditOfAudits />
                ) : metricGroup.contentType === "cdtResults" ? (
                  <CdtResults />
                ) : (
                  <>
                    <div className={classNames.metricGroupFilters}>
                      {metricGroup.useMonthFilter && (
                        <MonthDropdown
                          selectedKey={selectedMonth}
                          {...metricGroup.monthFilterProps}
                          onValueSelect={onMonthDropdownValueSelect}
                        />
                      )}
                    </div>
                    <div className={classNames.pivotContent}>
                      {isCloseMetricGroup && closeConfidneceIndexMetric && (
                        <div className={classNames.leftContent}>
                          {daysLeftMECloseMetricTitle && (
                            <div className={classNames.tile}>
                              <TooltipHost id={daysLeftMECloseTooltipId} tooltipProps={daysLeftMECloseTooltipProps}>
                                <div className={classNames.remainingDaysPane}>
                                  <div className={classNames.title}>{daysLeftMECloseMetricTitle}</div>
                                  <div className={classNames.remainingDays}>{daysLeftMECloseRemainingDays}</div>
                                </div>
                              </TooltipHost>
                            </div>
                          )}
                          <div className={classNames.tile}>
                            <Gauge
                              className={classNames.closeGauge}
                              value={getFormatContent({
                                item: closeConfidneceIndexMetric,
                                template: closeConfidneceIndexMetric.badgeTemplate,
                              })}
                              showValueText={false}
                              showMinMaxValueText={false}
                              color={
                                closeConfidneceIndexMetric.statusColorOverride || closeConfidneceIndexMetric.statusColor
                              }
                              colorDomain={
                                closeConfidneceIndexMetric.statusColorDomainOverride ||
                                closeConfidneceIndexMetric.statusColorDomain
                              }
                              topLabel={closeConfidenceIndexMetricName}
                              topLabelTooltip={closeConfidenceIndexMetricDesc}
                              bottomLabel={closeConfidenceIndexValueText}
                            />
                          </div>
                        </div>
                      )}
                      <div className={classNames.rightContent}>
                        {!!targetTeams?.length && (
                          <div className={classNames.rightInnerContent}>
                            {isCloseMetricGroup ? (
                              <div className={classNames.metricContents}>
                                <div className={classNames.metricContent}>
                                  {pageNote && (
                                    <div
                                      className={classNames.pageHeaderNote}
                                      dangerouslySetInnerHTML={{ __html: pageNote }}
                                    />
                                  )}
                                  <HealthDashboardMetricSets
                                    tableName={financialsTableName}
                                    teams={financialsTeam}
                                    metricSets={financialsMetricSets}
                                    metricTypes={financialsCloseMetricTypes}
                                    healthMetrics={healthMetrics}
                                    standardTemplates={standardTemplates}
                                    metricSubTypes={metricSubTypes}
                                    metricGroup={metricGroup}
                                    trendCharts={trendCharts}
                                  />
                                  {financialsTeam?.length && financialsTeam[0].teamNote && (
                                    <div
                                      className={classNames.teamNote}
                                      dangerouslySetInnerHTML={{ __html: financialsTeam[0].teamNote }}
                                    />
                                  )}
                                  <HealthDashboardLinks teams={financialsTeam} metricGroup={metricGroup} />
                                </div>
                                <div className={classNames.metricContent}>
                                  <HealthDashboardMetricSets
                                    tableName={mssalesTableName}
                                    teams={mssalesTeam}
                                    metricSets={mssalesMetricSets}
                                    metricTypes={mssalesCloseMetricTypes}
                                    healthMetrics={healthMetrics}
                                    standardTemplates={standardTemplates}
                                    metricSubTypes={metricSubTypes}
                                    metricGroup={metricGroup}
                                    trendCharts={trendCharts}
                                  />
                                  {mssalesTeam?.length && mssalesTeam[0].teamNote && (
                                    <div
                                      className={classNames.teamNote}
                                      dangerouslySetInnerHTML={{ __html: mssalesTeam[0].teamNote }}
                                    />
                                  )}
                                  <HealthDashboardLinks teams={mssalesTeam} metricGroup={metricGroup} />
                                </div>
                              </div>
                            ) : (
                              <div className={classNames.metricContent}>
                                {pageNote && (
                                  <div
                                    className={classNames.pageHeaderNote}
                                    dangerouslySetInnerHTML={{ __html: pageNote }}
                                  />
                                )}
                                {!!teamsWithoutSubTypes?.length && (
                                  <div className={classNames.metricContent}>
                                    <HealthDashboardMetricsTable
                                      teams={targetTeams}
                                      metricTypes={metricTypesWithoutSubTypes}
                                      healthMetrics={healthMetrics}
                                      standardTemplates={standardTemplates}
                                      metricGroup={metricGroup}
                                      trendCharts={trendCharts}
                                    />
                                  </div>
                                )}
                                {!!teamsWithSubTypes?.length &&
                                  teamsWithSubTypes.map((team) => {
                                    let teamTitle = getTeamTitle([team], selectedMetricGroup);

                                    return (
                                      <>
                                        <div className={classNames.metricContent}>
                                          <HealthDashboardMetricSets
                                            tableName={teamTitle}
                                            teams={[team]}
                                            metricTypes={metricTypesWithSubTypes}
                                            healthMetrics={healthMetrics}
                                            standardTemplates={standardTemplates}
                                            metricSubTypes={metricSubTypes}
                                            metricGroup={metricGroup}
                                            trendCharts={trendCharts}
                                          />
                                        </div>
                                        {team.teamNote && (
                                          <div
                                            className={classNames.teamNote}
                                            dangerouslySetInnerHTML={{ __html: team.teamNote }}
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                <HealthDashboardLinks metricGroup={metricGroup} />
                              </div>
                            )}
                          </div>
                        )}
                        <div className={classNames.rightInnerContent}>
                          <ReportBase report={report} loading={loadingReport} className={classNames.report} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </PivotItem>
            );
          })}
      </Pivot>
    </div>
  );
};

export default memo(HealthDashboard);
