import { IField, FieldType, IDataContext, IFieldStatus } from "../../../shared/components/DataForm";
import { EntityValidationType } from "../../common/interfaces";
import { IServiceConfig, IValidation, IValidationTest } from "../../common/serviceContentInterfaces";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { getSourceConfig, getUniqueAttributeOptions, getAttributeConfig } from "./MyServiceContentsEdit.helper";

export const getValidationTypeOptions = () => {
  let validationTypes = [];

  for (var validationType in EntityValidationType) {
    if (isNaN(Number(validationType))) {
      validationTypes.push({ key: validationType, text: validationType, ariaLabel: validationType });
    }
  }

  return validationTypes;
};

export const getValidationFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField[] => {
  if (!serviceConfig) return [];

  const defaultValueForAttribute = (dataContext: IDataContext) => {
    const validation: IValidation = dataContext.context;
    return validation.attribute;
  };

  const defaultValueForExportAttribute = (dataContext: IDataContext) => {
    const validation: IValidation = dataContext.context;
    return validation.exportAttribute;
  };

  const getSourceEntityList = (validationType: string, usedSourceEntities: string[]) => {
    const entityList = [];
    if (serviceConfig?.sourceEntities?.length) {
      serviceConfig.sourceEntities.forEach((sourceEntity) => {
        if (!usedSourceEntities.includes(`${validationType},${sourceEntity?.type}`)) {
          entityList.push({
            key: sourceEntity.type,
            text: sourceEntity.type,
            ariaLabel: sourceEntity.type,
          });
        }
      });
    }
    return entityList;
  };

  const getValidationTestName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const schema: IServiceConfig = dataContext?.context;
    const validationTests: IValidationTest[] = schema?.validationTests;
    const validationTest: IValidationTest = validationTests.length > index ? validationTests[index] : null;
    return validationTest?.validationType
      ? `${validationTest.validationType} on ${validationTest?.sourceEntityType || "[SourceEntityType]"}`
      : "";
  };

  const getValidationName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const validationTest: IValidationTest = dataContext?.context;
    const validations: IValidation[] = validationTest?.validations;
    const validation: IValidation = validations?.length > index ? validations[index] : null;
    const source = validation?.sourceAttribute || validation?.attribute || "[SourceAttribute]";
    const target = validation?.targetAttribute || validation?.attribute || "[TargetAttribute]";
    const text = source === target ? source : `${source} on ${target}`;
    return text;
  };

  const validateSourceTargetConfig = (
    dataContext: IDataContext,
    sourceAttributeName: string,
    targetAttributeName: string,
    configPropName: string
  ): IFieldStatus => {
    var sourceConfig = getSourceConfig(serviceConfigs, dataContext.parentDataContext?.context?.sourceEntityType);
    var sourceAttributeConfig = getAttributeConfig(sourceConfig, dataContext.context[sourceAttributeName]);
    var targetAttributeConfig = getAttributeConfig(serviceConfig, dataContext.context[targetAttributeName]);

    return {
      passed:
        sourceAttributeConfig === targetAttributeConfig ||
        sourceAttributeConfig[configPropName] === targetAttributeConfig[configPropName],
      message: `${configPropName} must be the same between ${sourceAttributeName} and ${targetAttributeName}`,
    };
  };

  const validationsObject: IField[] = [
    {
      fieldName: "validationTests",
      label: "Validation Tests",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.list,
      itemTypeName: "Test",
      customTitle: getValidationTestName,
      customTabHeader: getValidationTestName,
      fields: [
        {
          fieldName: "validationType",
          fieldType: FieldType.enumeration,
          label: "Validation Type",
          options: getValidationTypeOptions(),
          width: "200px",
        },
        {
          fieldName: "sourceEntityType",
          fieldType: FieldType.enumeration,
          label: "Source Entity",
          options: (dataContext: IDataContext) => {
            const validationTests: IValidationTest[] = dataContext?.parentDataContext.context.validationTests;
            const currentValidationTest: IValidationTest = dataContext?.context;
            const currentSourceEntity = currentValidationTest?.sourceEntityType;

            const usedEntities: string[] = [];
            validationTests.forEach((validationTest) => {
              if (validationTest?.sourceEntityType && validationTest.sourceEntityType !== currentSourceEntity) {
                usedEntities.push(`${validationTest?.validationType},${validationTest?.sourceEntityType}`);
              }
            });

            return getSourceEntityList(currentValidationTest.validationType, usedEntities);
          },
          hints:
            "To add a new Source Entity to the list press the + button next to 'Service Entities' " +
            "section at the bottom of the General tab and define the relationship. " +
            "If the Source Entity is added and does not appear in the list then it is already being used " +
            "with the same 'Validation Type' and must be added to the other entry.",
          width: "240px",
        },
        {
          fieldName: "slaTimestampAttribute",
          fieldType: FieldType.enumeration,
          label: "SLA Timestamp",
          options: getUniqueAttributeOptions(serviceConfig),
          visible: "this.validationType === 'TargetEntitySla'",
          width: "240px",
        },
        {
          fieldName: "validations",
          fieldType: FieldType.items,
          label: "Validations",
          collapsed: false,
          itemDisplayView: ItemDisplayView.tab,
          itemTypeName: "Attribute",
          visible: "this.validationType === 'AttributeRecon' || this.validationType === 'AggregateRecon'",
          customTabHeader: getValidationName,
          customTitle: getValidationName,
          onValueChange: (dataContext: IDataContext, newValue: any) => {
            const validations: IValidation[] = [...newValue];
            validations.forEach((validation) => {
              // Remove `attribute` node if `sourceAttribute` and `targetAttribute` are not the same
              const attributeValue = validation.attribute;
              if (validation.attribute && (validation.sourceAttribute || validation.targetAttribute)) {
                delete validation.attribute;
                if (!validation.targetAttribute) {
                  validation.targetAttribute = attributeValue;
                }
                if (!validation.sourceAttribute) {
                  validation.sourceAttribute = attributeValue;
                }
              }
              // Remove `exportAttribute` node if `sourceExportAttribute` and `targetExportAttribute` are not the same
              const attributeExportValue = validation.exportAttribute;
              if (
                validation.exportAttribute &&
                (validation.sourceExportAttribute || validation.targetExportAttribute)
              ) {
                delete validation.exportAttribute;
                if (!validation.targetExportAttribute) {
                  validation.targetExportAttribute = attributeExportValue;
                }
                if (!validation.sourceExportAttribute) {
                  validation.sourceExportAttribute = attributeExportValue;
                }
              }
            });
            return validations;
          },
          fields: [
            {
              fieldName: "sourceAttribute",
              fieldType: FieldType.enumeration,
              label: "Source Attribute",
              options: (dataContext: IDataContext) => {
                const sourceConfig = getSourceConfig(
                  serviceConfigs,
                  dataContext.parentDataContext?.context?.sourceEntityType
                );
                return getUniqueAttributeOptions(sourceConfig);
              },
              defaultValue: defaultValueForAttribute,
              width: "240px",
              checkIfValid: (dataContext: IDataContext) =>
                validateSourceTargetConfig(dataContext, "sourceAttribute", "targetAttribute", "dataType"),
            },
            {
              fieldName: "targetAttribute",
              fieldType: FieldType.enumeration,
              label: "Target Attribute",
              options: getUniqueAttributeOptions(serviceConfig),
              defaultValue: defaultValueForAttribute,
              width: "240px",
            },
            {
              fieldName: "sourceExportAttribute",
              fieldType: FieldType.enumeration,
              label: "Source Export Attribute",
              options: (dataContext: IDataContext) => {
                const sourceConfig = getSourceConfig(
                  serviceConfigs,
                  dataContext.parentDataContext?.context?.sourceEntityType
                );
                return getUniqueAttributeOptions(sourceConfig);
              },
              defaultValue: defaultValueForExportAttribute,
              width: "240px",
              checkIfValid: (dataContext: IDataContext) =>
                validateSourceTargetConfig(dataContext, "sourceExportAttribute", "targetExportAttribute", "dataType"),
            },
            {
              fieldName: "targetExportAttribute",
              fieldType: FieldType.enumeration,
              label: "Target Export Attribute",
              options: getUniqueAttributeOptions(serviceConfig),
              defaultValue: defaultValueForExportAttribute,
              width: "240px",
            },
          ],
        },
      ],
    },
  ];
  return validationsObject;
};
