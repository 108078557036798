import React from "react";
import DataFormContent, { IDataFormContentProps } from "./DataFormContent";

export interface IContextFieldProps extends IDataFormContentProps {}

export class ContextField extends React.Component<IContextFieldProps> {
  render() {
    return <DataFormContent {...this.props} />;
  }
}

export default ContextField;
