import React from "react";
import { ISearchFilter } from "../common/interfaces";
import { IVisualProps } from "../../shared/components/Visual";
import VisualTileList from "../../shared/components/Tile/VisualTileList";

export interface IGraphSearchViewFiltersProps {
  searchViewFields: IVisualProps[];
  searchViewFilters: ISearchFilter[];
  onSearchViewFilterChange: (filterName: string, value) => void;
}

export const GraphSearchViewFilters = ({
  searchViewFields,
  searchViewFilters,
  onSearchViewFilterChange,
}: IGraphSearchViewFiltersProps) => {
  if (!searchViewFields?.length) {
    return null;
  }

  var tiles = searchViewFields.map((viewField) => {
    var selectedValue = "";

    searchViewFilters.forEach((viewFilter) => {
      if (viewFilter.attribute === viewField.filterName) {
        selectedValue += selectedValue ? "," : "";
        selectedValue += viewFilter.value;
      } else if (viewFilter.filters?.length) {
        viewFilter.filters.forEach((subFilter) => {
          if (subFilter.attribute === viewField.filterName) {
            selectedValue += selectedValue ? "," : "";
            selectedValue += subFilter.value;
          }
        });
      }
    });

    return {
      ...viewField,
      selectedValue,
      onValueSelect: onSearchViewFilterChange,
    };
  });

  return <VisualTileList tiles={tiles} tileStyle={{ paddingBottom: "10px", paddingRight: "10px" }} />;
};
