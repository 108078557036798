import React from "react";
import { connect } from "react-redux";
import AppModuleHeader from "../common/AppModuleHeader";
import FmeCloseIssuesList from "./FmeCloseIssuesList";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { actionCreator } from "../duck";
import { actionCreator as appActionCreator } from "../../app/duck";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { Spinner } from "@fluentui/react/lib/Spinner";
import classNames from "./FmeCloseIssuesHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { ITeam } from "../common/interfaces";
import { getCommandBarProps } from "./helper";
import { getUserId } from "../common/helper";

export interface IFmeCloseIssuesHomeStateProps {
  userId: string;
  teams: ITeam[];
  selectedTeamId: string;
  loadingTeams: boolean;
  isSmallScreen: boolean;
}

export interface IFmeCloseIssuesHomeDispatchProps {
  selectLeftNavLinkByUrl: (url: string) => void;
  loadTeams: (refreshData?: boolean, callback?: Function) => void;
  changeTeamSelectedKey: (selectedTeamId: string) => void;
}

export interface IFmeCloseIssuesHomeProps extends IFmeCloseIssuesHomeStateProps, IFmeCloseIssuesHomeDispatchProps {}

export class FmeCloseIssuesHome extends React.Component<IFmeCloseIssuesHomeProps> {
  componentDidMount() {
    const { loadTeams, selectLeftNavLinkByUrl } = this.props;

    loadTeams();
    selectLeftNavLinkByUrl(leftNavUrls.all.fmecloseissues);
  }

  render() {
    const { teams, loadingTeams, selectedTeamId } = this.props;

    if (loadingTeams) {
      return <Spinner className={classNames.spinner} label="Loading teams data..." role="alert" />;
    }

    if (!teams?.length) {
      return <div className={classNames.info}>No team data is found.</div>;
    }

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader
          commandBarProps={getCommandBarProps(this.props)}
          headerTextOverride="Fiscal Month End Close Issues"
        />
        <Pivot className={classNames.pivot} selectedKey={selectedTeamId} onLinkClick={this.onPivotLinkClick}>
          {teams
            .filter((team: ITeam) => !team.ignoreFmeCloseIssues)
            .map((team: ITeam) => (
              <PivotItem key={team.id} itemKey={team.id} className={classNames.pivotItem} headerText={team.name}>
                <FmeCloseIssuesList team={team} />
              </PivotItem>
            ))}
        </Pivot>
      </div>
    );
  }

  onPivotLinkClick = (item: PivotItem) => {
    const { changeTeamSelectedKey } = this.props;
    const newSelectedTeamId = item.props.itemKey;

    changeTeamSelectedKey(newSelectedTeamId);
  };
}

const mapStateToProps = (state: IState): IFmeCloseIssuesHomeStateProps => ({
  userId: getUserId(state.app.login_user),
  teams: state.modules.teams,
  loadingTeams: state.modules.loading_config_items,
  selectedTeamId: state.modules.selected_team_id,
  isSmallScreen: state.app.is_small_screen,
});

const mapDispatchToProps: IFmeCloseIssuesHomeDispatchProps = {
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  loadTeams: actionCreator.loadTeamConfigItems,
  changeTeamSelectedKey: actionCreator.changeTeamSelectedKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(FmeCloseIssuesHome);
