import React from "react";
import classNames from "./DataForm.module.scss";
import { DataFormContent, IDataFormContentProps } from "./DataFormContent";

export interface IDataFormProps extends IDataFormContentProps {
  className?: string;
}

export class DataForm extends React.Component<IDataFormProps> {
  private dataFormContent: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.dataFormContent = React.createRef();
  }

  render() {
    const { fields, className } = this.props;

    if (!fields || !fields.length) return null;

    return (
      <div className={[classNames.root, className].join(" ")}>
        <DataFormContent {...this.props} ref={this.dataFormContent} />
      </div>
    );
  }

  getErrors = () => this.dataFormContent.current.getErrors();

  hasErrors = () => this.dataFormContent.current.hasErrors();
}

export default DataForm;
