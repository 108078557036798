import React from "react";
import { connect } from "react-redux";
import AlertModal from "../common/AlertModal";
import Diagram, { IDiagram, INode } from "../../shared/components/Diagram";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IState } from "../../reducers/interfaces";
import { getEntityTraceDiagram } from "./EntityTraceDiagram.helper";
import { actionCreator } from "../duck";
import { IEntity, IEntityTraceMetric, IEntityTraceView } from "../common/interfaces";
import classNames from "./EntityDiagram.module.scss";
import { SearchItemKey } from "./helper";
import { errorType } from "../interfaces";

export interface IEntityTraceDiagramStateProps {
  selectedEntity: IEntity;
  loading: boolean;
  notFound: boolean;
  entityTraceView: IEntityTraceView;
  entityTraceMetrics: IEntityTraceMetric[];
  searchItemKey: SearchItemKey;
  error: string;
}

export interface IEntityTraceDiagramState {
  diagram: IDiagram;
  showFailureModal: boolean;
  failureText: string | JSX.Element;
}

export interface IEntityTraceDiagramDispatchProps {
  loadEntityTraceMetrics: (entityType: string, entityId: string, version: string) => void;
}

export class EntityTraceDiagram extends React.Component<
  IEntityTraceDiagramStateProps & IEntityTraceDiagramDispatchProps,
  IEntityTraceDiagramState
> {
  state = {
    diagram: null,
    showFailureModal: false,
    failureText: "",
  };

  componentDidMount() {
    const { selectedEntity, entityTraceMetrics, entityTraceView } = this.props;

    const diagram = selectedEntity ? getEntityTraceDiagram(entityTraceMetrics, entityTraceView) : null;

    this.setState({ diagram });
  }

  componentDidUpdate(prevProps) {
    const { selectedEntity, entityTraceMetrics, entityTraceView, searchItemKey } = this.props;

    if (searchItemKey === SearchItemKey.entityLookup) {
      if (selectedEntity !== prevProps.selectedEntity || searchItemKey !== prevProps.searchItemKey) {
        this.setState({ diagram: getEntityTraceDiagram(entityTraceMetrics, entityTraceView) });
      }
    }
  }

  render() {
    const { loading, notFound, searchItemKey } = this.props;
    const { diagram, showFailureModal, failureText } = this.state;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading entity trace data..."
        />
      );
    }

    if (searchItemKey === SearchItemKey.entityLookup && notFound) {
      return <div className={classNames.message}>No entity trace found with the specific type and ID.</div>;
    }

    if (!diagram || searchItemKey !== SearchItemKey.entityLookup) {
      return null;
    }

    return (
      <div>
        <Diagram
          ariaLabel={"Entity Trace Diagram"}
          diagram={diagram}
          classNames={classNames}
          onNodeClick={this.onNodeClick}
          onNodeMoved={this.onNodeMoved}
        />
        {showFailureModal && (
          <AlertModal
            content={failureText}
            title="Failure Details"
            onCommit={() => this.setState({ showFailureModal: false })}
          />
        )}
      </div>
    );
  }

  onNodeClick = (node: INode) => {
    if (node.metrics) {
      let exception = node.metrics.find((m) => m.name === "ExceptionDetails");
      if (exception) {
        // TODO: using a custom popup dialog to show failure details
        this.setState({
          failureText: exception.value,
          showFailureModal: true,
        });
      }
    }
  };

  onNodeMoved = (selectedNode: INode) => {
    this.setState({
      diagram: {
        ...this.state.diagram,
        nodes: this.state.diagram.nodes.map((node) => (node.id === selectedNode.id ? selectedNode : node)),
      },
    });
  };
}

const mapStateToProps = (state: IState): IEntityTraceDiagramStateProps => ({
  selectedEntity: state.modules.selected_entity,
  loading: state.modules.loading_config_items || state.modules.loading_entity_trace_metrics,
  notFound: state.modules.no_entity_trace_found,
  entityTraceMetrics: state.modules.entity_trace_metrics,
  entityTraceView:
    state.modules.entity_trace_views &&
    state.modules.entity_trace_views.find((view) => view.id === state.modules.entity_trace_view_id),
  searchItemKey: state.modules.search_item_key,
  error: state.modules.errors[errorType.entityTraces],
});

const mapDispatchToProps = {
  loadEntityTraceMetrics: actionCreator.loadEntityTraceMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityTraceDiagram);
