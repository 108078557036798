import React from "react";
import {
  ComboBox as UIFabricComboBox,
  IComboBoxOption,
  IComboBoxProps as UIFabricComboBoxProps,
} from "@fluentui/react/lib/ComboBox";
import { IVisualProps } from "./Visual";

export interface IComboBoxVisualProps extends UIFabricComboBoxProps {
  searchTextDelay?: number;
}

export interface IComboBoxProps extends IVisualProps {
  onValueSelect?: (value: string) => void;
}

export interface IComboBoxState {
  text: string;
}

export class ComboBox extends React.Component<IComboBoxProps, IComboBoxState> {
  state = {
    text: "",
  };

  valueChangedTimer = null;

  render() {
    const { label, visualProps = {}, width, selectedValue, placeholder } = this.props;
    const { text } = this.state;
    const { options: visualOptions } = visualProps as IComboBoxVisualProps;

    const options = visualOptions || this.getDataOptions(),
      selectedOption =
        selectedValue && options && options.find((option) => option.key.toString() === selectedValue.toString()),
      selectedText = selectedOption && selectedOption.text;

    return (
      <UIFabricComboBox
        {...(visualProps as IComboBoxVisualProps)}
        label={label}
        options={options}
        selectedKey={selectedValue}
        style={{ width }}
        allowFreeform={true}
        text={text || selectedText}
        openOnKeyboardFocus={true}
        useComboBoxAsMenuWidth={true}
        placeholder={placeholder}
        onPendingValueChanged={this.onPendingValueChanged}
        onChange={this.onChange}
      />
    );
  }

  onChange = (ev, selectedOption: IComboBoxOption) => {
    const { onValueSelect } = this.props;
    let selectedValue = selectedOption && selectedOption.key && selectedOption.key.toString();

    onValueSelect && onValueSelect(selectedValue);
  };

  onPendingValueChanged = (options, index, value) => {
    const { onValueReload, visualProps = {} } = this.props;
    const { searchTextDelay = 500 } = visualProps as IComboBoxVisualProps;

    if (value !== undefined) {
      this.setState({ text: value });

      if (this.valueChangedTimer) {
        clearTimeout(this.valueChangedTimer);
        this.valueChangedTimer = null;
      }

      if (onValueReload) {
        this.valueChangedTimer = setTimeout(() => onValueReload(value), searchTextDelay);
      }
    }
  };

  getDataOptions = (): IComboBoxOption[] => {
    const { data, categoryFieldName = "key", valueFieldNames = ["text"] } = this.props;

    if (!data || !data.length) return [];

    var options = data.map((item) => ({
      key: item[categoryFieldName],
      text: item[valueFieldNames[0]],
    }));

    return options;
  };
}

export default ComboBox;
