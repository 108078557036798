import React, { useEffect, useState } from "react";
import { IconButton, Callout, IButtonProps, ICalloutProps } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classNames from "./CalloutButton.module.scss";

export interface ICalloutButtonProps {
  label?: string;
  className?: string;
  buttonClassName?: string;
  buttonProps?: IButtonProps;
  calloutProps?: ICalloutProps;
  doNotDismissOnFocusOut?: boolean;
  hideCallout?: boolean;
  valueText?: string;
  onClick?: (showCallout: boolean) => void;
  onDismiss?: () => void;
}

export interface IIconCalloutButtonProps extends ICalloutButtonProps {
  iconName: string;
  title?: string;
}

export const IconCalloutButton = (props: React.PropsWithChildren<IIconCalloutButtonProps>) => {
  const {
    label,
    iconName,
    title,
    className,
    buttonClassName,
    buttonProps,
    calloutProps,
    children,
    doNotDismissOnFocusOut,
    hideCallout,
    valueText,
    onClick,
    onDismiss,
  } = props;
  const [showCallout, setShowCallout] = useState(false);
  const buttonId = useId();
  const buttonClassNames = [classNames.button, buttonClassName];

  showCallout && buttonClassNames.push(classNames.buttonSelected);

  useEffect(() => {
    hideCallout && setShowCallout(false);
  }, [hideCallout]);

  const onButtonClick = () => {
    setShowCallout(!showCallout);
    onClick && onClick(!showCallout);
  };

  const onCalloutDismiss = () => {
    !doNotDismissOnFocusOut && setShowCallout(false);
    onDismiss && onDismiss();
  };

  return (
    <>
      <div className={`${classNames.buttonContainer} ${className}`}>
        {label && <label className={`ms-Label ${classNames.label}`}>{label}</label>}
        <div className={classNames.buttonPane}>
          <IconButton
            id={buttonId}
            iconProps={{ iconName: iconName, title: title }}
            className={buttonClassNames.join(" ")}
            onClick={onButtonClick}
            {...buttonProps}
          />
          {valueText && <div className={classNames.valueText}>{valueText}</div>}
        </div>
      </div>
      <Callout
        target={"#" + buttonId}
        hidden={!showCallout}
        isBeakVisible={false}
        gapSpace={12}
        className={classNames.callout}
        onDismiss={onCalloutDismiss}
        {...calloutProps}
      >
        {children}
      </Callout>
    </>
  );
};
