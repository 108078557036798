import React from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";

export const getEntityTestColumns = (): IColumn[] => [
  {
    key: "validationResult",
    fieldName: "validationResult",
    name: "Result",
    minWidth: 35,
    maxWidth: 40,
    isResizable: true,
    className: "columnAlignCenter",
    onRender: (item) => getFormatContent(item.validationResult, ContentDisplayType.status),
  },
  {
    key: "validationType",
    fieldName: "validationType",
    name: "Validation Type",
    minWidth: 160,
    maxWidth: 180,
    isResizable: true,
  },
  {
    key: "validationName",
    fieldName: "validationName",
    name: "Validation Name",
    minWidth: 240,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "validationTimeStamp",
    fieldName: "validationTimeStamp",
    name: "Validation Timestamp",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
    onRender: (item) => getFormatContent(item.validationTimeStamp, ContentDisplayType.datetime),
  },
  {
    key: "validationDetails",
    fieldName: "validationDetails",
    name: "Details",
    minWidth: 800,
    isResizable: true,
    onRender: (item) => getValidationDetails(item),
  },
];

const getValidationDetails = (item) => {
  let type: string = item.validationType,
    details = item.validationDetails,
    result = item.validationResult,
    detailsJson;

  try {
    detailsJson = JSON.parse(details);
  } catch {}
  if (detailsJson) {
    if (result.toLowerCase() === "timeout") {
      return getValidationTimeoutDetails(detailsJson);
    } else {
      switch (type.toLowerCase()) {
        case "attributerecon":
        case "aggregaterecon":
          return getAttributeReconDetails(detailsJson, result);

        case "attributeschemavalidation":
          return getSchemaValidationDetails(detailsJson, result);

        case "targetentitysla":
          return getTargetEntitySlaDetails(detailsJson, result);
      }
    }
  }

  return details;
};

const getAttributeReconDetails = (detailsJson, result) => {
  let mapping = detailsJson.targetAttributeMapping,
    value = detailsJson.targetAttributeValue;

  if (result && mapping && value !== undefined) {
    let isPassed = result.toLowerCase() === "pass",
      statusText = isPassed ? "Matching" : "Non-matching",
      style = { backgroundColor: isPassed ? "#CFC" : "#FCC", padding: "2px 4px" },
      sourceValue = detailsJson.sourceAttributeValue,
      sourceEntity = detailsJson.sourceEntity,
      expectedValueText =
        !isPassed && sourceValue !== undefined ? (
          <span>
            , expected value = <b style={{ backgroundColor: "#CFC", padding: "2px 4px" }}>{sourceValue}</b>
          </span>
        ) : (
          ""
        );

    return (
      <ValidationTooltipHost detailsJson={detailsJson}>
        {statusText} value <b style={style}>{value}</b> for field <b>{mapping}</b>
        {expectedValueText} against source entity type <b>{sourceEntity?.type}</b> with ID <b>{sourceEntity?.id}</b>, v
        <b>{sourceEntity?.version}</b>
      </ValidationTooltipHost>
    );
  }
};

const getValidationTimeoutDetails = (detailsJson) => {
  let expectedDate = detailsJson.expectedValidationCompleteDate,
    sourceEntity = detailsJson.sourceEntity;

  return (
    <ValidationTooltipHost detailsJson={detailsJson}>
      <b>Timeout</b>: Validation not completed by <b>{expectedDate}</b>{" "}
      {sourceEntity && (
        <span>
          with source <b>{sourceEntity.type}</b> <b>{sourceEntity.id}</b>, v<b>{sourceEntity.version}</b>
        </span>
      )}
    </ValidationTooltipHost>
  );
};

const getSchemaValidationDetails = (detailsJson, result) => {
  const count = detailsJson.length;

  if (!count) return <div>No detail info</div>;

  const detailsText = result.toLowerCase() === "pass" ? "Validated" : "Missing required";

  return (
    <ValidationTooltipHost detailsJson={detailsJson}>
      {detailsText} attribute{count > 1 ? "s" : ""}:{" "}
      {detailsJson.map((attr, index) => (
        <span key={"schema-validation-attr-" + index}>
          <b>{attr.name}</b>
          {count - 1 > index ? ", " : " "}
        </span>
      ))}
    </ValidationTooltipHost>
  );
};

const getTargetEntitySlaDetails = (detailsJson, result) => {
  let expectedTime = detailsJson.expectedTime,
    detailsText = result.toLowerCase() === "pass" ? "before" : "after";

  return (
    <ValidationTooltipHost detailsJson={detailsJson}>
      Entity arrived {detailsText} the expected SLA time of <b>{expectedTime}</b>
    </ValidationTooltipHost>
  );
};

interface IValidationTooltipHost {
  detailsJson: object;
}

const ValidationTooltipHost: React.FunctionComponent<IValidationTooltipHost> = (props) => (
  <TooltipHost content={<pre>{JSON.stringify(props.detailsJson, null, 2)}</pre>} tooltipProps={{ maxWidth: null }}>
    {props.children}
  </TooltipHost>
);
