import React, { useMemo } from "react";
import { ContentDisplayType, getFormatContent } from "../../utilities/miscHelper";
import classNames from "./CircleBadge.module.scss";

export interface ICircleBadgeProps {
  value: string | number;
  statusColor?: string;
  watermarkColor?: string;
  style?: React.CSSProperties;
  valueDisplayType?: ContentDisplayType;
  decimal?: number;
  size?: number;
  useWatermark?: boolean;
}

export const CircleBadge = (props: ICircleBadgeProps) => {
  const {
    value,
    statusColor = "#999",
    watermarkColor = "#deecf9",
    style = {},
    valueDisplayType,
    decimal = 0,
    size = 60,
    useWatermark,
  } = props;

  const isPercentage = valueDisplayType === ContentDisplayType.percentage,
    valueHasHtmlTags = /<\/?[a-z][\s\S]*>/.test(value.toString());

  const finalStyle = useMemo(() => {
    let styleObj = {
      color: statusColor,
      borderColor: statusColor,
      background: watermarkColor,
      height: size + "px",
      lineHeight: size + "px",
      minWidth: size + "px",
      borderRadius: size / 2 + 3 + "px",
      ...style,
    };

    if (isPercentage && useWatermark && !valueHasHtmlTags) {
      let counterValue = 100 - Number(value);
      styleObj.background = `linear-gradient(white ${counterValue}%, ${watermarkColor} ${counterValue}% ${Number(
        value
      )}%)`;
    } else {
      styleObj.animationName = "none";
      styleObj.background = "#eee";
    }

    return styleObj;
  }, [statusColor, watermarkColor, style, isPercentage, value, size, useWatermark, valueHasHtmlTags]);

  return (
    <div className={classNames.percentVisual} style={finalStyle}>
      {valueHasHtmlTags ? (
        <div className={classNames.htmlContent} dangerouslySetInnerHTML={{ __html: value.toString() }} />
      ) : valueDisplayType || decimal ? (
        getFormatContent({ item: value, displayType: valueDisplayType, decimal })
      ) : (
        value
      )}
    </div>
  );
};

export default CircleBadge;
