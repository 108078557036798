import React, { useMemo } from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import { parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { IState } from "../../../reducers/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

const defaultStreamRun = {};

export interface IMyStreamRunEditStateProps extends IConfigItemEditProps {}

export interface IMyStreamRunEditDispatchProps extends IConfigItemEditDispatchProps {}

export const MyStreamRunEdit = (props: IMyStreamRunEditStateProps & IMyStreamRunEditDispatchProps) => {
  const { configValue } = props;

  const configItem = useMemo(() => parseJsonSafe(configValue), [configValue]);

  return (
    <>
      <ConfigItemEdit
        {...props}
        configItem={configItem}
        configItemName="Stream Run"
        configItemType={ConfigItemType.StreamRuns}
        appInsightsPageName="MyStreamRunEditPage"
        leftNavUrl={leftNavUrls.management.streamRun}
        defaultConfigItem={defaultStreamRun}
        pivotItems={[]}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
});

const mapDispatchToProps: IMyStreamRunEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadStreamRunConfigItem,
  updateConfigItem: actionCreator.updateStreamRunConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStreamRunEdit);
