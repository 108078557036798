import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      data: "number",
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 300,
      isResizable: true,
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(item._modifiedBy, ContentDisplayType.contact),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(item.owner, ContentDisplayType.contact),
    },
    {
      key: "editors",
      name: "Editors",
      fieldName: "editors",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => getFormatContent(item.editors, ContentDisplayType.contact),
    },
  ];
};
