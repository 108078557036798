import React from "react";
import classNames from "./Chat.module.scss";
import { IIconProps, Icon } from "@fluentui/react";

export interface IChatMessageActionsProps {
  actionIcons: IIconProps[];
}

export const ChatMessageActions = (props: IChatMessageActionsProps) => {
  const { actionIcons } = props;

  if (!actionIcons?.length) {
    return null;
  }

  return (
    <div className={classNames.messageActions}>
      {actionIcons.map((actionIcon) => (
        <Icon className={classNames.actionIcon} {...actionIcon} />
      ))}
    </div>
  );
};
