import { ContentDisplayType } from "../../shared/utilities/miscHelper";
import { FilterOperator, LogicalOperator, IConfigItem, DataType, ITile } from "./interfaces";

export enum Types {
  EntityType,
  EntityId,
  EntityVersion,
  EntityCreatedTimestamp,
  EntityUpdatedTimestamp,
  blank,
}

export enum AttributeOperator {
  Count, //Count of values in an array returned by given path
  Min, //Min value in an array or multiple arrays returned by given path(s)
  Max, //Max value in an array or multiple arrays returned by given path(s)
  ArrayMax, //Expects single path with array of arrays, return max values from inner arrays
  Sum, //Sum values in an array or multiple arrays returned by given path(s)
  Subtract, //Subtract values from given two paths
  SubtractLastTwoValues, //Subtract last two values in an array returned by given path
  SubtractLastTwoValuesExcludeTax, //Subtract last two values in an array and exclude tax based on isTaxInclusive attribute
  Concat, //Concats all values (strings) using delimiter
  ConcatArray, //ConcatArray expects two paths string and array of strings, returns concatinated string of all using delimiter
  PathExists, //Checks a path(s) exists or not, returns bool
  ValueAtIndex, //Get a value at index from array
  Equals,
  NotEquals,
  Contains,
  NotContains,
  StartsWith,
  DateTimeMin,
  DateTimeMax,
  GreaterThan,
  LessThan,
  GreaterThanOrEquals,
  LessThanOrEquals,
  AddMonth, //Add Month (with month count) for the requested date.
  AddDay, //Add the number of days to the requested date.
  ReplaceDay, //Replace day (with derived day) for the requested date.
  Multiply, // Multipy values from the given paths.
  DateToString, // Convert a date time to specific format based on the format pattern of "operatorValue".
}

export enum SqlAggragateOperators {
  Count = "COUNT",
  Min = "MIN",
  Max = "MAX",
  Sum = "SUM",
  Average = "AVG",
}

export enum SqlDataTypeOptions {
  bit = "bit",
  dateTime = "dateTime",
  float = "float",
  varchar = "varchar",
}

export enum RollingIntervalOptions {
  Mtd = "MTD",
  Qtd = "QTD",
  Ytd = "YTD",
}

export interface IAlternateKey {
  alternateKeyType?: string;
  alternateKeyIdAttribute?: string;
  alternateKeyVersionAttribute?: string;
}

export interface IStats {
  id: string;
  attribute: string;
  timeInterval?: string;
  starTime?: string;
  rollingInterval?: string;
  groupedByAttributes?: string[];
  filters?: IAttributeFilter[];
}

export interface ISourceEntity extends IAlternateKey {
  type?: string;
  idAttribute?: string;
  versionAttribute?: string;
  valueAttribute?: string;
}

export interface IAttributeFilter {
  attribute: string;
  value: string;
  operator?: FilterOperator;
  logicalOperator?: LogicalOperator;
  filters?: IAttributeFilter[];
  filterSet?: string;
}

export interface IFilterSet {
  name: string;
  filters: IAttributeFilter[];
}

export interface IExternalConfig {
  sourceType: string;
  endpointUrl: string;
  tableName: string;
  sasKeyVaultSecretName: string;
  partitionKeyAttribute: string;
  rowKeyAttribute: string;
  type: string;
}

export interface IAttribute {
  name: string;
  path?: string;
  extendedPath?: string;
  delimiter?: string;
  operator?: string;
  operatorPropName?: string;
  operatorValue?: string;
  operatorValuePath?: string;
  sourceEntity?: ISourceEntity;
  filters?: IAttributeFilter[];
  required?: boolean;
  type?: string;
  value?: string | number | boolean;
  export?: boolean;
  dataType?: string;
  pathRegex?: string;
  pathRegexOperation?: string;
  pathRegexOperationValue?: string;
  displayColumn?: boolean;
  index?: number;
  indexed?: boolean;
  configSource?: {
    name: string;
    type: string;
  };
  externalConfig?: IExternalConfig;
}

export interface IAttributeMappings {
  schemaVersion?: string;
  attributes: IAttribute[];
}

export interface ISlaRule {
  name: string;
  targetEntityType: string;
  slaTimeframe: string;
  slaTimestampAttribute: string;
  filters: IAttributeFilter[];
  groupingKeys: string[];
}

export interface IValidation {
  name?: string;
  attribute?: string;
  sourceAttribute?: string;
  targetAttribute?: string;
  exportAttribute?: string;
  sourceExportAttribute?: string;
  targetExportAttribute?: string;
}

export interface IValidationTest {
  validationType: string;
  sourceEntityType?: string;
  slaTimestampAttribute?: string;
  validations?: IValidation[];
}

export interface IMetric {
  id: string;
  name: string;
  exportAttribute: string;
  operator: string;
  dataType: DataType;
  displayType: ContentDisplayType;
  showInGraph: boolean;
}

export interface IServiceConfig extends IConfigItem {
  attributeMappings?: IAttributeMappings[];
  sourceEntities?: ISourceEntity[];
  validationTests?: IValidationTest[];
  filterSets?: IFilterSet[];
  eventType?: string;
  isProcessValidation?: boolean;
  validationCompleteInDays?: number;
  alternateKeys?: IAlternateKey[];
  slaRules?: ISlaRule[];
  metrics?: IMetric[];
  stats?: IStats[];
  tiles?: ITile[];
  isCacheEnabled?: boolean;
  cacheTtl?: boolean;
}
