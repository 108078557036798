import React from "react";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { IIncident } from "./interfaces";
import { Selection, SelectionMode, IColumn, DetailsList } from "@fluentui/react/lib/DetailsList";
import { ContactLink } from "../../shared/components/ContactLink";
import { getContactMessageSubject } from "./IncidentsReport.helper";
import classNames from "./IncidentDetails.module.scss";

export interface IIncidentBugsProps {
  incident: IIncident;
}

class IncidentBugs extends React.Component<IIncidentBugsProps> {
  private selection: Selection;

  constructor(props: IIncidentBugsProps) {
    super(props);

    this.selection = new Selection({
      onSelectionChanged: () => {
        let selectedItems = this.selection.getSelection(),
          bug: any = selectedItems && selectedItems.length && selectedItems[0];

        if (bug && bug.externalId) {
          window.open(`//microsoft.visualstudio.com/OSGS/_workitems/edit/${bug.externalId}`, "_blank");
        }
      },
    });
  }

  render() {
    const { incident } = this.props;
    const { bugs } = incident;

    if (!bugs || !bugs.length) {
      return null;
    }

    return (
      <Tile title="Repair Items" commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <DetailsList
          items={bugs}
          columns={this.getColumns(incident)}
          className={classNames.selectable}
          selection={this.selection}
          selectionMode={SelectionMode.none}
        />
      </Tile>
    );
  }

  getColumns = (incident: IIncident): IColumn[] => [
    {
      key: "c1",
      name: "Work Item ID",
      fieldName: "externalId",
      minWidth: 60,
      maxWidth: 100,
      onRender: (item: any) => (
        <a
          href={`//microsoft.visualstudio.com/OSGS/_workitems/edit/${item.externalId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.externalId}
        </a>
      ),
    },
    {
      key: "c2",
      name: "Work Item Title",
      fieldName: "title",
      minWidth: 200,
    },
    {
      key: "c3",
      name: "Status",
      fieldName: "status",
      minWidth: 60,
      maxWidth: 100,
    },
    {
      key: "c4",
      name: "Owner",
      fieldName: "owner",
      minWidth: 220,
      maxWidth: 240,
      onRender: (item: any) => <ContactLink alias={item.owner} messageSubject={getContactMessageSubject(incident)} />,
    },
    {
      key: "c6",
      name: "Last Modified",
      fieldName: "modifiedDate",
      minWidth: 220,
      maxWidth: 260,
      onRender: (item: any) => new Date(item["modifiedDate"]).toLocaleString(),
    },
  ];
}

export default IncidentBugs;
