import React from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  LabelList,
} from "recharts";
import { IVisualProps } from "./Visual";
import { VisualType } from "./Visual.helper";
import { DISCRETE_COLOR_RANGE, EXTENDED_DISCRETE_COLOR_RANGE } from "react-vis/dist/theme";
import { IChartVisualBaseProps } from "./ChartVisual";
import { ContentDisplayType, getFormatContent, startCase } from "../../utilities/miscHelper";

const defaultWidth = 300;
const defaultHeight = 240;
const defaultTickLabelAngel = 0;
const defaultChartMargin = { left: 20, right: 20, top: 20, bottom: 10 };
const defaultColors = DISCRETE_COLOR_RANGE.concat(EXTENDED_DISCRETE_COLOR_RANGE.slice().reverse());
const defaultAxisStyle = {
  stroke: "#ccc",
  fontSize: "11px",
  strokeWidth: 1,
};
const defaultGridStyle = {
  stroke: "#f5f5f5",
};
const defaultLegendStyle = {
  wrapperStyle: {
    fontSize: "11px",
    bottom: "10px",
  },
};
const defaultTooltipStyle = {
  allowEscapeViewBox: { y: true },
  isAnimationActive: false,
  wrapperStyle: {
    fontSize: "11px",
    zIndex: 100,
  },
};
const defaultTickStyle = { style: { stroke: "#aaa", strokeWidth: 0, fontSize: "11px" } };

export interface IRechartVisualProps extends IChartVisualBaseProps {}

export class RechartVisual extends React.Component<IVisualProps> {
  render() {
    let {
      data,
      visualType = VisualType.column,
      visualProps = {},
      categoryFieldName = "category",
      valueFieldNames = ["value"],
      groupFieldNames,
      stacked = false,
      valueDisplayType,
      categoryDisplayType,
      doNotShowAxes,
    } = this.props;

    if (!data || !data.length) return null;

    let {
      tickLabelAngle = defaultTickLabelAngel,
      width = Number(this.props.width) || defaultWidth,
      height = Number(this.props.height) || defaultHeight,
      margin = {},
      colors = defaultColors,
      seriesProps = [],
      xAxisStyle,
      xAxisProps,
      yAxisStyle,
      yAxisProps = {},
      legendProps,
      tooltipProps,
      alwaysHideLegend,
      alwaysShowLegend,
      showValueLabels,
      valueLabelsProps,
    } = visualProps as IRechartVisualProps;

    var isVerticalLayout = visualType === VisualType.bar2;
    var isMultiYAxes = Array.isArray(yAxisProps) && yAxisProps.length > 1;
    xAxisProps = {
      tick: { angle: tickLabelAngle, ...defaultTickStyle },
      padding: doNotShowAxes ? {} : { left: isVerticalLayout ? 0 : 20, right: 20 },
      ...xAxisProps,
    };
    xAxisStyle = { ...defaultAxisStyle, ...xAxisStyle };
    yAxisStyle = { ...defaultAxisStyle, ...yAxisStyle };

    var transformedData = [],
      seriesInfo = [];

    if (groupFieldNames?.length && valueFieldNames?.length) {
      var dataLookup = {};

      data.forEach((item) => {
        var categoryValue = item[categoryFieldName];

        if (categoryValue) {
          let groupName = "";
          groupFieldNames.forEach((groupFieldName) => {
            groupName !== "" && (groupName += " ");
            groupName += item[groupFieldName];
          });

          groupName &&
            valueFieldNames.forEach((valueFieldName) => {
              var dataValue = item[valueFieldName];
              var seriesName = groupName + " " + startCase(valueFieldName);
              var seriesFieldName = seriesName.replace(/[^a-zA-Z0-9]/g, "");
              var newDataItem = {};

              if (dataLookup["c" + categoryValue]) {
                newDataItem = dataLookup["c" + categoryValue];
              } else {
                newDataItem[categoryFieldName] = categoryValue;
                dataLookup["c" + categoryValue] = newDataItem;
              }

              newDataItem[seriesFieldName] = dataValue;

              let series = seriesInfo.find((series) => series.seriesName === seriesFieldName);

              if (!series) {
                seriesInfo.push({ seriesName: seriesFieldName, maxValue: dataValue, minValue: dataValue });
              } else {
                series.maxValue = Math.max(series.maxValue, dataValue);
                series.minValue = Math.min(series.minValue, dataValue);
              }
            });
        }
      });

      Object.keys(dataLookup).forEach((categoryKey) => {
        transformedData.push(dataLookup[categoryKey]);
      });
    } else {
      transformedData = data;

      data.forEach((item) => {
        valueFieldNames.forEach((valueFieldName) => {
          var dataValue = item[valueFieldName];
          var seriesFieldName = valueFieldName;

          let series = seriesInfo.find((series) => series.seriesName === seriesFieldName);

          if (!series) {
            seriesInfo.push({ seriesName: seriesFieldName, maxValue: dataValue, minValue: dataValue });
          } else {
            series.maxValue = Math.max(series.maxValue, dataValue);
            series.minValue = Math.min(series.minValue, dataValue);
          }
        });
      });
    }

    const seriesNames = seriesInfo.map((series) => series.seriesName);
    const chartMargin = doNotShowAxes ? margin : { ...defaultChartMargin, ...margin };
    const chartLayout = isVerticalLayout ? "vertical" : "horizontal";

    const finalValueLabelsProps = {
      angle: isVerticalLayout ? 0 : 90,
      style: { fill: "white", strokeWidth: 0, fontSize: "11px", fontWeight: 200 },
      ...valueLabelsProps,
    };

    return (
      <ComposedChart width={width} height={height} data={transformedData} margin={chartMargin} layout={chartLayout}>
        {!doNotShowAxes && <CartesianGrid {...defaultGridStyle} />}
        <XAxis
          hide={doNotShowAxes}
          dataKey={isVerticalLayout ? valueFieldNames[0] : categoryFieldName}
          type={isVerticalLayout ? "number" : "category"}
          domain={isVerticalLayout && !doNotShowAxes ? seriesInfo?.length && getYAxisDomain(seriesInfo[0]) : undefined}
          style={xAxisStyle}
          {...xAxisProps}
          tickFormatter={(value) => getFormatContent(value, isVerticalLayout ? valueDisplayType : categoryDisplayType)}
        />
        {isMultiYAxes ? (
          [
            <YAxis
              hide={doNotShowAxes}
              key="left"
              yAxisId="left"
              style={yAxisStyle}
              stroke={colors[0]}
              domain={seriesInfo?.length && getYAxisDomain(seriesInfo[0])}
              tick={defaultTickStyle}
              {...yAxisProps[0]}
              tickFormatter={(value) => getFormatContent(value, yAxisProps[0]?.valueDisplayType || valueDisplayType)}
            />,
            <YAxis
              hide={doNotShowAxes}
              key="right"
              yAxisId="right"
              style={yAxisStyle}
              stroke={colors[1]}
              domain={seriesInfo?.length > 1 && getYAxisDomain(seriesInfo[1])}
              orientation="right"
              tick={defaultTickStyle}
              {...yAxisProps[1]}
              tickFormatter={(value) => getFormatContent(value, yAxisProps[1]?.valueDisplayType || valueDisplayType)}
            />,
          ]
        ) : (
          <YAxis
            hide={doNotShowAxes}
            yAxisId="left"
            style={yAxisStyle}
            domain={isVerticalLayout ? undefined : seriesInfo?.length && getYAxisDomain(seriesInfo[0])}
            dataKey={isVerticalLayout ? categoryFieldName : undefined}
            type={isVerticalLayout ? "category" : "number"}
            padding={{ top: 0, bottom: 0 }}
            tick={defaultTickStyle}
            {...yAxisProps}
            tickFormatter={(value) =>
              getFormatContent(value, isVerticalLayout ? categoryDisplayType : valueDisplayType)
            }
          />
        )}
        {
          <Tooltip
            {...defaultTooltipStyle}
            {...tooltipProps}
            formatter={(value) =>
              getFormatContent({ item: value, displayType: ContentDisplayType.number, ...tooltipProps })
            }
          />
        }
        {!alwaysHideLegend && (alwaysShowLegend || seriesNames.length > 1) && (
          <Legend {...defaultLegendStyle} {...legendProps} />
        )}
        {seriesNames?.map((seriesName, seriesIndex) => {
          var key = "rechartVisual" + seriesIndex;
          var visualSeriesProps = seriesIndex < seriesProps.length ? seriesProps[seriesIndex] : {};
          visualType = visualSeriesProps?.visualType || visualType;

          visualSeriesProps = {
            key,
            dataKey: seriesName,
            name: startCase(seriesName),
            fill: colors[seriesIndex],
            stroke: colors[seriesIndex],
            stackId: stacked ? "stack" : undefined,
            yAxisId: isMultiYAxes && seriesIndex > 0 ? "right" : "left",
            layout: chartLayout,
            ...visualSeriesProps,
          };

          if (visualType === VisualType.area2) {
            return <Area {...visualSeriesProps} />;
          } else if (visualType === VisualType.bar2 || visualType === VisualType.column2) {
            return (
              <Bar {...visualSeriesProps}>
                {transformedData?.map((dataItem, index) => {
                  let cellColor = dataItem["color"] || colors[seriesIndex];
                  return <Cell key={`cell-${index}`} fill={cellColor} stroke={cellColor} />;
                })}
                {showValueLabels && (
                  <LabelList
                    dataKey={seriesName}
                    {...finalValueLabelsProps}
                    formatter={(value) => getFormatContent(value, valueDisplayType)}
                  />
                )}
              </Bar>
            );
          } else if (visualType === VisualType.line2) {
            return <Line {...visualSeriesProps} />;
          }
          return null;
        })}
      </ComposedChart>
    );
  }
}

export default RechartVisual;

const getYAxisDomain = (seriesInfo) => {
  if (!seriesInfo) return undefined;

  let maxValue = seriesInfo.maxValue,
    base = Math.pow(10, Math.round(Math.abs(maxValue)).toString().length - 1),
    domainMax = seriesInfo.maxValue <= 0 ? 0 : Math.ceil(maxValue / base) * base,
    domainMin = seriesInfo.minValue >= 0 ? 0 : seriesInfo.minValue;

  if (domainMax <= 0.1) {
    domainMax = 1;
  }

  return [domainMin, domainMax];
};
