import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyAppSettingList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyAppSettingList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="App Setting"
        appInsightsPageName="MyAppSettingListPage"
        leftNavUrl={leftNavUrls.management.appSetting}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadAppSettingConfigItems,
  deleteConfigItem: actionCreator.deleteAppSettingConfigItem,
  publishConfigItem: actionCreator.publishAppSettingConfigItem,
  unpublishConfigItem: actionCreator.unpublishAppSettingConfigItem,
  revertConfigItem: actionCreator.revertAppSettingConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAppSettingList);
