import React, { CSSProperties } from "react";
import { IIcon } from "./interfaces";

export const defaultIconSize = 18;
export const defaultIconProps: IIcon = {
  x: undefined,
  y: undefined,
  height: defaultIconSize,
  width: defaultIconSize,
};

export interface IIconProps {
  icon: IIcon;
  className?: string;
  style?: CSSProperties;
}

export class Icon extends React.Component<IIconProps> {
  render() {
    const { icon, className, style } = this.props;
    const { iconName, style: iconStyle } = icon;
    return (
      <i
        className={`ms-Icon ms-Icon--${iconName} ${iconName} ${className}`}
        /* By default assume Office UI Fabric Icon but can be any other className-based icon library, like FontAwesome */
        style={{ ...style, ...iconStyle }}
      />
    );
  }
}

export default Icon;
