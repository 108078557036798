import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { IDevOpsView, IDevOpsViewFilter, IFilterItem, IDevOpsItem } from "../common/interfaces";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import classNames from "./DevOpsViewFilters.module.scss";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { DevOpsViewFilterType, getDropdownFilterOptions } from "./helper";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { SearchBox } from "@fluentui/react/lib/SearchBox";

export interface IDevOpsViewFiltersStateProps {
  devOpsViewId: string;
  devOpsViews: IDevOpsView[];
  selectedView: IDevOpsView;
  devOpsItems: IDevOpsItem[];
  selectedInlineFilters: string[];
}

export interface IDevOpsViewFiltersDispatchProps {
  loadDevOpsView: (devOpsViewId: string, filters?: IFilterItem[], refresh?: boolean) => void;
  changeDevOpsSearchText: (searchText: string) => void;
  selectDevOpsInlineFilter: (value: string) => void;
}

export interface IDevOpsViewFiltersProps {
  selectedFilters: IDevOpsViewFilter[];
  onFilterChange: (name: string, value: string) => void;
}

export class DevOpsViewFilters extends React.Component<
  IDevOpsViewFiltersProps & IDevOpsViewFiltersStateProps & IDevOpsViewFiltersDispatchProps & RouteComponentProps
> {
  render() {
    const { devOpsViewId, devOpsViews, selectedView, selectedFilters, devOpsItems, selectedInlineFilters } = this.props;

    const viewOptions =
        devOpsViews &&
        devOpsViews
          .filter((view) => selectedView && !!view.isFeatureRequests === !!selectedView.isFeatureRequests)
          .map((view) => ({ key: view.id, text: view.name, ariaLabel: view.name })),
      hasMoreThanOneView = viewOptions && viewOptions.length > 1,
      filters = selectedView && selectedView.filters;

    return (
      <div className={classNames.root}>
        <SearchBox
          ariaLabel="Search on this view"
          className={classNames.search}
          placeholder="Search on this view"
          onChange={this.onSearchChange}
          width={200}
          disableAnimation={true}
        />
        {filters && !!filters.length && (
          <div className={classNames.filters}>
            {filters.map((filter) => {
              let selectedFilter = selectedFilters && selectedFilters.find((f) => f.name === filter.fieldName),
                selectedFilterValue = selectedFilter && selectedFilter.value,
                defaultChecked =
                  selectedFilterValue !== undefined
                    ? filter.onValue === selectedFilterValue
                    : (filter.defaultValue as boolean),
                filterOptions = getDropdownFilterOptions(filter, devOpsItems);

              return (
                <div key={`devops-filter-${filter.fieldName}`} className={classNames.filter}>
                  {filter.filterType === DevOpsViewFilterType.Toggle && (
                    <Toggle
                      className={classNames.toggle}
                      onText={filter.onText}
                      offText={filter.offText}
                      defaultChecked={defaultChecked}
                      onChange={(ev, checked) => this.onToggleFilterChange(filter, checked)}
                      ariaLabel="Show Active Items"
                      tabIndex={0}
                    />
                  )}
                  {filter.filterType === DevOpsViewFilterType.Dropdown && filterOptions && !!filterOptions.length && (
                    <Dropdown
                      {...filter}
                      className={classNames.dropdown}
                      placeholder="Select filters"
                      selectedKeys={selectedInlineFilters}
                      options={filterOptions}
                      onChange={(ev, option) => this.onDropdownFilterChange(filter, option)}
                      label="Select filters"
                      ariaLabel="Select filters"
                      tabIndex={0}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
        {hasMoreThanOneView && (
          <Dropdown
            className={classNames.dropdown}
            options={viewOptions}
            selectedKey={devOpsViewId}
            onChange={this.onViewChange}
            label="Select a view"
            ariaLabel="Select a view"
            tabIndex={0}
          />
        )}
      </div>
    );
  }

  onSearchChange = (ev, newValue: string) => {
    this.props.changeDevOpsSearchText(newValue);
  };

  onViewChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    const { loadDevOpsView, history, selectedView } = this.props;

    const devOpsViewId = item.key.toString();

    history.push(
      `${selectedView?.isFeatureRequests ? leftNavUrls.works.featureRequests : leftNavUrls.works.home}/${devOpsViewId}`
    );
    loadDevOpsView(devOpsViewId);
  };

  onToggleFilterChange = (filter: IDevOpsViewFilter, checked: boolean) => {
    const { onFilterChange } = this.props;
    const { fieldName: name, onValue = "", offValue = "" } = filter;

    onFilterChange(name, checked ? onValue : offValue);
  };

  onDropdownFilterChange = (filter: IDevOpsViewFilter, item: IDropdownOption) => {
    const { onFilterChange, selectDevOpsInlineFilter } = this.props;
    const { fieldName: name, filterFields } = filter;
    const value = item.key.toString();

    filterFields && filterFields.length ? selectDevOpsInlineFilter(value) : onFilterChange(name, value);
  };
}

const mapStateToProps = (state: IState): IDevOpsViewFiltersStateProps => ({
  devOpsViewId: state.modules.selected_devops_view_id,
  selectedView: state.modules.selected_devops_view,
  devOpsViews: state.modules.devops_views,
  devOpsItems: state.modules.devops_items,
  selectedInlineFilters: state.modules.selected_devops_inline_filters,
});

const mapDispatchToProps = {
  loadDevOpsView: actionCreator.loadDevOpsView,
  changeDevOpsSearchText: actionCreator.changeDevOpsSearchText,
  selectDevOpsInlineFilter: actionCreator.selectDevOpsInlineFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevOpsViewFilters));
