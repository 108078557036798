import React from "react";
import BasicModal, { IBasicModalProps } from "./BasicModal";
import classNames from "./BasicModal.module.scss";

export interface IConfirmModalProps extends IBasicModalProps {
  content?: string | JSX.Element;
}

export class ConfirmModal extends React.Component<IConfirmModalProps> {
  render() {
    const { content, children, title = "Confirmation" } = this.props;

    return (
      // TODO: Task 37700847: AlertModal/ConfirmModal error: Type 'Ref<HTMLDivElement>' is not assignable to type 'LegacyRef<ConfirmModal>'
      <BasicModal
        {...(this.props as any)}
        className={`${classNames.confirmModal} ${this.props.className}`}
        title={title}
      >
        <div className={`${classNames.message} ${classNames.fullWidth}`}>{content || children}</div>
      </BasicModal>
    );
  }
}

export default ConfirmModal;
