import React from "react";
import ConfirmModal, { IConfirmModalProps } from "../common/ConfirmModal";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { ISearchField, DataType, LogicalOperator, ISearchFilter, DataTypeOptions } from "../common/interfaces";
import classNames from "./GraphSearch.module.scss";
import {
  dateFilterOperators,
  numberFilterOperators,
  stringFilterOperators,
  booleanFilterOperators,
  logicalOperators,
} from "./helper";

const defaultOperator = "=";

export interface IGraphSearchFilterModalProps extends IConfirmModalProps {
  searchFields: ISearchField[];
  searchFilters: ISearchFilter[];
  selectedFilter?: ISearchFilter;
}

export interface IGraphSearchFilterModalState {
  attribute?: string;
  operator?: string;
  operatorText?: string;
  value?: string;
  dataType?: DataType;
  logicalOperator?: LogicalOperator;
  error?: string;
  indexed?: boolean;
}

export class GraphSearchFilterModal extends React.Component<
  IGraphSearchFilterModalProps,
  IGraphSearchFilterModalState
> {
  constructor(props) {
    super(props);

    this.state = {
      attribute: "",
      operator: defaultOperator,
      operatorText: defaultOperator,
      value: "",
      dataType: undefined,
      logicalOperator: undefined,
      error: undefined,
      ...props.selectedFilter,
    };
  }

  render() {
    const { searchFields = [], searchFilters, onCancel } = this.props;
    const { attribute, operator, logicalOperator = "and", error, value } = this.state;

    const fieldOptions = searchFields?.map((field) => ({
      key: field.name,
      text: field.name,
      ariaLabel: field.name,
    }));
    const dataType = searchFields?.find((field) => field.name === attribute)?.dataType?.toLowerCase();
    const logicalOperatorOptions = logicalOperators.map((op) => ({ key: op, text: op }));
    const operatorOptions =
      dataType === DataTypeOptions.boolean
        ? booleanFilterOperators.slice()
        : dataType === DataTypeOptions.number
        ? numberFilterOperators.slice()
        : dataType === DataTypeOptions.date
        ? dateFilterOperators.slice()
        : stringFilterOperators.slice();

    return (
      <ConfirmModal
        className={classNames.searchFilterModal}
        title="Add Search Filter"
        content={
          <>
            <div className={classNames.content}>
              {searchFilters?.length > 0 && (
                <Dropdown
                  className={classNames.field + " " + classNames.logicalOpDropdown}
                  label="Logical"
                  options={logicalOperatorOptions}
                  selectedKey={logicalOperator}
                  onChange={this.onLogicalOperatorChange}
                />
              )}
              <Dropdown
                className={classNames.field + " " + classNames.fieldDropdown}
                label="Field"
                options={fieldOptions}
                selectedKey={attribute}
                dropdownWidth={280}
                onChange={this.onFieldChange}
              />
              <Dropdown
                className={classNames.field + " " + classNames.operatorDropdown}
                label="Operator"
                options={operatorOptions}
                selectedKey={operator}
                onChange={this.onOperatorChange}
              />
              <TextField
                className={classNames.field + " " + classNames.valueField}
                label="Value"
                value={value}
                onChange={this.onValueChange}
              />
            </div>
            {error && (
              <div className={classNames.error} role="alert">
                {error}
              </div>
            )}
          </>
        }
        disableCommit={!!error}
        onCommit={this.onCommit}
        onCancel={onCancel}
      />
    );
  }

  onFieldChange = (ev, option) => {
    const attribute = option.key;
    const { searchFields } = this.props;
    const { value } = this.state;
    const searchField = searchFields?.find((field) => field.name === attribute);
    const dataType = searchField?.dataType;
    const indexed = searchField?.indexed;

    this.setState({ attribute, operator: defaultOperator, operatorText: defaultOperator, dataType, indexed });

    const error = this.getValueError(value, dataType);
    this.setState({ error });
  };

  onOperatorChange = (ev, option) => {
    this.setState({ operator: option.key, operatorText: option.text });
  };

  onValueChange = (ev, newValue) => {
    const { dataType } = this.state;
    const error = this.getValueError(newValue, dataType);
    this.setState({ value: newValue, error });
  };

  onLogicalOperatorChange = (ev, option) => {
    this.setState({ logicalOperator: option.key });
  };

  onCommit = () => {
    const { onCommit } = this.props;
    const {
      attribute,
      operator = defaultOperator,
      operatorText = defaultOperator,
      value,
      dataType = "string",
      logicalOperator,
      indexed,
    } = this.state;

    onCommit && onCommit({ attribute, operator, operatorText, dataType, value, logicalOperator, indexed });
  };

  getValueError = (value, dataType): string => {
    if (value && dataType) {
      if (dataType === "number") {
        return isNaN(value) ? "Please enter numeric value for this field." : "";
      } else if (dataType === "date") {
        return Date.parse(value) ? "" : "Please enter a valid date or datetime format for this field.";
      }
    }

    return "";
  };
}

export default GraphSearchFilterModal;
