import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { IState } from "../../../reducers/interfaces";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyIncidentViewList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListStateProps,
  IConfigItemListDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyIncidentViewList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Incident View"
        appInsightsPageName="MyIncidentViewListPage"
        leftNavUrl={leftNavUrls.management.incidentView}
      />
    );
  }
}

const mapStateToProps = (state: IState): IConfigItemListStateProps => ({
  ...mapStateToPropsBase(state),
  configItems: state.modules.incident_views,
  loading: state.modules.loading_incident_views,
});

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadIncidentViewsOnly,
  deleteConfigItem: actionCreator.deleteIncidentView,
  publishConfigItem: actionCreator.publishIncidentViewConfigItem,
  unpublishConfigItem: actionCreator.unpublishIncidentViewConfigItem,
  revertConfigItem: actionCreator.revertIncidentViewConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIncidentViewList);
