import React from "react";
import VisualTileList from "../../shared/components/Tile/VisualTileList";
import { IReport, ReportType } from "./interfaces";
import PowerbiReport from "./PowerbiReport";
import { Spinner } from "@fluentui/react";
import classNames from "./Report.module.scss";

export interface IReportBaseProps {
  report?: IReport;
  tiles?: any[];
  loading?: boolean;
  className?: string;
}

export const ReportBase = (props: IReportBaseProps) => {
  const { report, tiles = report?.tiles, loading, className } = props;

  if (loading) {
    return <Spinner className={classNames.spinner} label="Loading data..." />;
  }

  if (!report) return null;

  return (
    <>
      {report.type === ReportType.powerbi ? (
        <PowerbiReport
          contentType={report.contentType}
          contentId={report.contentId}
          groupId={report.groupId}
          autoHeight={false}
          className={`${classNames.reportContainer} ${className}`}
        />
      ) : (
        tiles && <VisualTileList tiles={tiles} className={className} />
      )}
    </>
  );
};
