import { Icon } from "@fluentui/react";
import React, { useMemo } from "react";
import { ITeam, ITemplate } from "../common/interfaces";
import classNames from "./HealthDashboard.module.scss";
import { IHealthMetric, IHealthMetricGroup, IHealthMetricSet, IHealthMetricType } from "./HealthDashboard.types";
import HealthDashboardMetricsRow from "./HealthDashboardMetricsRow";

export interface IHealthDashboardMetricsTableProps {
  teams: ITeam[];
  metricTypes: IHealthMetricType[];
  healthMetrics: IHealthMetric[];
  standardTemplates: ITemplate[];
  hideColumnHeader?: boolean;
  metricSubTypes?: IHealthMetricType[];
  metricGroup?: IHealthMetricGroup;
  metricSet?: IHealthMetricSet;
  trendCharts?: any[];
}

export const HealthDashboardMetricsTable = (props: IHealthDashboardMetricsTableProps) => {
  const {
    teams,
    metricTypes,
    metricSubTypes,
    healthMetrics,
    standardTemplates,
    hideColumnHeader,
    metricGroup,
    trendCharts,
  } = props;

  let headerRowContent = useMemo(() => {
    let headerRowContent = [];

    metricSubTypes?.length
      ? metricSubTypes
          .filter((subType) => !subType.disabled)
          .forEach((subType) =>
            headerRowContent.push(
              <div
                key={"subType" + subType.id}
                className={classNames.teamHeader}
                title={subType.description}
                style={subType.healthMetricHeaderStyle}
              >
                {subType.name}
                {subType.description && <Icon iconName="Info" className={classNames.tooltipIcon} tabIndex={0} />}
              </div>
            )
          )
      : teams.forEach((team) => {
          let metricGroupTeamSettings = metricGroup?.teams?.find((metricGroupTeam) => metricGroupTeam.id === team.id),
            healthMetricHeaderStyle = metricGroupTeamSettings?.healthMetricHeaderStyle || team.healthMetricHeaderStyle;

          headerRowContent.push(
            <div
              key={"team" + team.id}
              className={classNames.teamHeader}
              title={team.description}
              style={healthMetricHeaderStyle}
            >
              {team.name}
              {team.description && <Icon iconName="Info" className={classNames.tooltipIcon} tabIndex={0} />}
            </div>
          );
        });

    return headerRowContent;
  }, [metricSubTypes, teams, metricGroup]);

  let metricTypeColumnsCount = metricGroup?.metricTypeColumns?.length;

  return (
    <table cellSpacing={0}>
      {!hideColumnHeader && (
        <tr className={classNames.teamHeaderRow}>
          {metricTypeColumnsCount ? (
            metricGroup.metricTypeColumns.map((column) => (
              <th className={classNames.teamHeader} style={metricGroup.metricTypeHeaderStyle}>
                {column.name}
              </th>
            ))
          ) : (
            <th className={classNames.teamHeaderEmpty} style={metricGroup.metricTypeHeaderStyle} />
          )}
          {headerRowContent}
        </tr>
      )}
      {metricTypes.map((metricType) => {
        let metricChildTypes = metricType.metricChildTypes || [undefined];
        return metricChildTypes.map((metricChildType, index) => (
          <HealthDashboardMetricsRow
            key={`healthMetricRow-${metricType.name}-${metricType.id}-${metricChildType}-${index}`}
            metricType={metricType}
            metricChildType={metricChildType}
            metricTypeRowspan={metricChildType && index === 0 ? metricChildTypes.length : undefined}
            metricTypeColspan={!metricChildType && metricTypeColumnsCount > 1 ? metricTypeColumnsCount : undefined}
            teams={teams}
            healthMetrics={healthMetrics}
            standardTemplates={standardTemplates}
            metricSubTypes={metricSubTypes}
            trendCharts={trendCharts}
          />
        ));
      })}
    </table>
  );
};

export default HealthDashboardMetricsTable;
