import React, { CSSProperties } from "react";
import ChartVisual, { IChartVisualProps } from "./ChartVisual";
import RechartVisual, { IRechartVisualProps } from "./RechartVisual";
import TableVisual, { ITableVisualProps } from "./TableVisual";
import TemplateVisual from "./TemplateVisual";
import TimeSelector, { ITimeSelectorProps } from "../TimeSelector";
import Textbox from "./Textbox";
import DiagramVisual, { IDiagramVisualProps } from "./DiagramVisual";
import Dropdown, { IDropdownVisualProps } from "./Dropdown";
import ComboBox, { IComboBoxVisualProps } from "./ComboBox";
import DatePicker, { IDatePickerVisualProps } from "./DatePicker";
import Label from "./Label";
import Submit from "./Submit";
import ActionButton from "./ActionButton";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { ContentDisplayType } from "../../utilities/miscHelper";
import { generateColumns, columnCellClassName, columnHeaderClassName } from "../../utilities/tableHelper";
import { StyleClass, IStyleClass } from "../StyleClass";
import { allowNoData, VisualType } from "./Visual.helper";
import classNames from "./Visual.module.scss";
import { IFilterItem } from "../common/interfaces";

export interface IVisualProps {
  id?: string;
  data?: any[];
  visualType?: VisualType;
  className?: string;
  style?: CSSProperties;
  selectedValue?: string;
  categoryFieldName?: string;
  valueFieldNames?: string[];
  groupFieldNames?: string[];
  selectedFieldName?: string;
  seriesSortOrder?: "asc" | "desc";
  highFieldName?: string;
  lowFieldName?: string;
  openFieldName?: string;
  closeFieldName?: string;
  noDataText?: string;
  valueDisplayType?: ContentDisplayType;
  categoryDisplayType?: ContentDisplayType;
  visualProps?:
    | IChartVisualProps
    | IRechartVisualProps
    | ITableVisualProps
    | ITimeSelectorProps
    | IComboBoxVisualProps
    | IDropdownVisualProps
    | IDiagramVisualProps
    | IDatePickerVisualProps;
  columns?: IColumn[];
  error?: string;
  lastInRow?: boolean;
  filterName?: string;
  width?: number | string;
  height?: number | string;
  label?: string;
  labelStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  stacked?: boolean;
  placeholder?: string;
  useSubmitButton?: boolean;
  ignoreSubmitButton?: boolean;
  includeUndefinedColumns?: boolean;
  filters?: IFilterItem[];
  operator?: string;
  decimal?: number;
  doNotShowAxes?: boolean;
  diagramNodesQueryData?: any[];
  diagramLinksQueryData?: any[];
  onValueInit?: (name: string, selectedValue: any) => void;
  onValueSelect?: (name: string, selectedValue: any) => void;
  onValuesSelect?: (filters: IFilterItem[]) => void;
  onValueReload?: (newValue: any) => void;
  onColumnValueSelect?: (name: string, selectedValue: any) => void;
  setOnClickColumnOptionsFunction?: (onClick: () => void) => void;
  setOnClickFilterBarFunction?: (onClick: () => void) => void;
}

export class Visual extends React.Component<IVisualProps> {
  static defaultProps = {
    visualType: VisualType.table,
  };

  componentDidCatch(error, info) {}

  render() {
    const {
      id,
      data,
      columns,
      visualType,
      error,
      noDataText = "No data",
      visualProps = {},
      filterName,
      label,
      categoryFieldName,
      selectedValue = visualProps["selectedValue"],
      useSubmitButton,
      includeUndefinedColumns,
      diagramNodesQueryData,
      diagramLinksQueryData,
    } = this.props;

    if (error) {
      return <div className={classNames.error}>{error}</div>;
    }

    if (!allowNoData(visualType) && (!data || !data.length)) {
      const style = visualProps && visualProps["width"] ? { width: visualProps["width"] - 24 } : undefined;
      return (
        <div className={classNames.noDataText} style={style}>
          {noDataText}
        </div>
      );
    }

    const props: any = {
        ...this.props,
        columns: generateColumns(data, columns, "visualColumn", id, includeUndefinedColumns, this.props),
      },
      columnStyle = this.getColumnStyle();

    let content,
      selectedValueName = filterName || categoryFieldName || visualType,
      rootClassNames = ["Visual_root", classNames.root],
      onValueSelect = (value) => this.props.onValueSelect(selectedValueName, value),
      onValueReload = (value) => this.props.onValueReload(value);

    if (visualType === VisualType.table) {
      content = <TableVisual {...props} />;
      rootClassNames.push(classNames.tableRoot);
    } else if (
      visualType === VisualType.area ||
      visualType === VisualType.bar ||
      visualType === VisualType.candlestick ||
      visualType === VisualType.column ||
      visualType === VisualType.line ||
      visualType === VisualType.pie
    ) {
      content = <ChartVisual {...props} />;
    } else if (
      visualType === VisualType.area2 ||
      visualType === VisualType.bar2 ||
      visualType === VisualType.column2 ||
      visualType === VisualType.line2
    ) {
      content = <RechartVisual {...props} />;
    } else if (visualType === VisualType.kpi || visualType === VisualType.template) {
      content = <TemplateVisual {...props} />;
      rootClassNames.push(classNames.templateRoot);
    } else if (visualType === VisualType.textbox) {
      content = (
        <Textbox
          {...props}
          {...visualProps}
          label={label}
          value={selectedValue || visualProps["value"]}
          showSearchIcon={!useSubmitButton}
          onValueSelect={onValueSelect}
        />
      );
    } else if (visualType === VisualType.timeAgoSelector) {
      content = (
        <TimeSelector
          {...props}
          {...visualProps}
          selectedValue={selectedValue}
          onValueSelect={onValueSelect}
          labelPosition="top"
        />
      );
    } else if (visualType === VisualType.diagram) {
      content = (
        <DiagramVisual
          {...(visualProps as IDiagramVisualProps)}
          data={data}
          diagramNodesQueryData={diagramNodesQueryData}
          diagramLinksQueryData={diagramLinksQueryData}
        />
      );
    } else if (visualType === VisualType.comboBox) {
      content = (
        <ComboBox
          {...props}
          onValueSelect={onValueSelect}
          onValueReload={onValueReload}
          selectedValue={selectedValue}
          width={visualProps["width"]}
        />
      );
    } else if (visualType === VisualType.dropdown) {
      content = (
        <Dropdown {...props} onValueSelect={onValueSelect} selectedValue={selectedValue} width={visualProps["width"]} />
      );
    } else if (visualType === VisualType.datePicker) {
      content = (
        <DatePicker
          {...props}
          {...(visualProps as IDatePickerVisualProps)}
          label={label}
          selectedValue={selectedValue}
          onValueSelect={onValueSelect}
        />
      );
    } else if (visualType === VisualType.label) {
      content = <Label {...props} />;
    } else if (visualType === VisualType.submit) {
      content = <Submit {...props} />;
    } else if (visualType === VisualType.actionButton) {
      content = <ActionButton {...props} />;
    } else {
      content = <div className={classNames.error}>Unsupported visual type: {visualType}</div>;
    }

    return (
      <div className={rootClassNames.join(" ")}>
        <StyleClass styleClass={columnStyle} />
        {content}
      </div>
    );
  }

  getColumnStyle = (): IStyleClass => {
    const { columns, id } = this.props;
    let style = {};

    columns &&
      columns.forEach((column: any, index: number) => {
        let fieldName = column["fieldName"];
        if (column.headerStyle) {
          style[columnHeaderClassName + id + fieldName + index] = column.headerStyle;
        }

        if (column.style) {
          style[columnCellClassName + id + fieldName + index] = column.style;
        }
      });

    return style;
  };
}

export default Visual;
