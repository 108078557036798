import React from "react";
import { IIncident } from "./interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import classNames from "./IncidentRootCause.module.scss";

export interface IIncidentRootCauseProps {
  incident: IIncident;
}

class IncidentRootCause extends React.Component<IIncidentRootCauseProps> {
  render() {
    const { incident } = this.props;

    if (
      !incident.rootCauseTitle &&
      !incident.rootCauseCategory &&
      !incident.rootCauseDescription &&
      !incident.rootCauseFix
    ) {
      return null;
    }

    return (
      <Tile title="Root Cause" applyContentStyle={true} commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <div className={classNames.root}>
          {incident.rootCauseTitle && (
            <div className={classNames.field}>
              <div className={classNames.name}>Title</div>
              <div className={classNames.value}>{incident.rootCauseTitle}</div>
            </div>
          )}
          {incident.rootCauseCategory && (
            <div className={classNames.field}>
              <div className={classNames.name}>Category</div>
              <div className={classNames.value}>{incident.rootCauseCategory}</div>
            </div>
          )}
          {incident.rootCauseDescription && (
            <div className={classNames.field}>
              <div className={classNames.name}>Description</div>
              <div className={classNames.value} dangerouslySetInnerHTML={{ __html: incident.rootCauseDescription }} />
            </div>
          )}
          {incident.rootCauseFix && (
            <div className={classNames.field}>
              <div className={classNames.name}>Fix</div>
              <div className={classNames.value} dangerouslySetInnerHTML={{ __html: incident.rootCauseFix }} />
            </div>
          )}
        </div>
      </Tile>
    );
  }
}

export default IncidentRootCause;
