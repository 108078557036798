import React from "react";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IConfigItemListProps } from "./ConfigItemList";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { IconButton } from "@fluentui/react/lib/Button";
import { getBasicPageCommandBarItems, isUserOwnerOrEditor } from "../../common/helper";
import { liveOnlyLinks } from "../../../app/LeftNav.helper";

export const getCommandBarProps = (props: IConfigItemListProps): ICommandBarProps => {
  const { isSmallScreen, isStaging, loadConfigItems, configItemName, leftNavUrl } = props,
    newId = "new";

  const basicPageCommandBarActions = {
    refresh: () => loadConfigItems(true),
  };

  const createButton = {
    key: `create${configItemName}`,
    name: `Create ${configItemName}`,
    title: `Create ${configItemName}`,
    iconProps: {
      iconName: "Add",
    },
    iconOnly: isSmallScreen,
    onClick: () => props.history.push(`${leftNavUrl}/${newId}`),
  };

  const isConfigTypeLiveOnly = liveOnlyLinks.indexOf(leftNavUrl) >= 0,
    allowCreation = isStaging || isConfigTypeLiveOnly;

  const commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions)],
  };

  allowCreation && commandBarProps.farItems.unshift(createButton);

  return commandBarProps;
};

export const getColumns = (
  columns: IColumn[],
  props: IConfigItemListProps,
  classNames: any,
  onTryConfigItemDelete,
  onTryConfigItemPublish,
  onTryConfigItemUnpublish,
  onTryConfigItemRevert,
  getCustomActions?: (item) => JSX.Element,
  customActionColumnWidth: number = 100
): IColumn[] => {
  const { history, userId, deleting, publishing, leftNavUrl, configItemName, isSuperAdmin, isStaging } = props,
    disable = deleting || publishing,
    allowAdminActions = isStaging || liveOnlyLinks.indexOf(leftNavUrl) >= 0,
    columnsCopy = columns.slice(),
    loadDetailsPage = (item) => history.push(`${leftNavUrl}/${item.id}`);

  columnsCopy.unshift({
    key: "actions",
    name: "Actions",
    fieldName: "actions",
    minWidth: customActionColumnWidth,
    maxWidth: customActionColumnWidth,
    onRender: (item) =>
      !disable && (isSuperAdmin || isUserOwnerOrEditor(item, userId)) ? (
        <div className={classNames.itemActionsPane} tabIndex={0}>
          {getCustomActions && <span className={classNames.itemAction}>{getCustomActions(item)}</span>}
          {isStaging && onTryConfigItemPublish && (
            <IconButton
              iconProps={{
                iconName: "Upload",
              }}
              aria-label="Publish"
              title={`Publish ${configItemName} to Live`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => onTryConfigItemPublish(item)}
              onKeyUp={(ev) => ev.which === 13 && onTryConfigItemPublish(item)}
            />
          )}
          {isStaging && onTryConfigItemUnpublish && (
            <IconButton
              iconProps={{
                iconName: "EntryDecline",
              }}
              aria-label="Unpublish"
              title={`Unpublish ${configItemName} from Live`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => onTryConfigItemUnpublish(item)}
              onKeyUp={(ev) => ev.which === 13 && onTryConfigItemUnpublish(item)}
            />
          )}
          {isStaging && onTryConfigItemRevert && (
            <IconButton
              iconProps={{
                iconName: "Download",
              }}
              aria-label="Revert"
              title={`Revert ${configItemName} from Live`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => onTryConfigItemRevert(item)}
              onKeyUp={(ev) => ev.which === 13 && onTryConfigItemRevert(item)}
            />
          )}
          {allowAdminActions && (
            <IconButton
              iconProps={{
                iconName: "Delete",
              }}
              aria-label="Delete"
              title={`Delete ${configItemName}`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => onTryConfigItemDelete(item)}
              onKeyUp={(ev) => ev.which === 13 && onTryConfigItemDelete(item)}
            />
          )}
          {allowAdminActions && (
            <IconButton
              iconProps={{
                iconName: "Edit",
              }}
              ariaLabel="Edit"
              title={`Edit ${configItemName}`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => loadDetailsPage(item)}
              onKeyUp={(ev) => ev.which === 13 && loadDetailsPage(item)}
            />
          )}
          {!allowAdminActions && (
            <IconButton
              iconProps={{
                iconName: "View",
              }}
              ariaLabel="View"
              title={`View ${configItemName}`}
              className={classNames.itemAction}
              tabIndex={0}
              onClick={() => loadDetailsPage(item)}
              onKeyUp={(ev) => ev.which === 13 && loadDetailsPage(item)}
            />
          )}
        </div>
      ) : null,
  });

  return columnsCopy;
};
