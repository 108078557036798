import React, { useEffect, useMemo, useState } from "react";
import { getDebounceMethod } from "../../utilities/miscHelper";
import { IField } from "./interfaces";
import RichTextField from "../RichTextEditor/RichTextEditor";

interface IProps {
  id: string;
  field: IField;
  fieldProps: object;
  onChange: (field: IField, newValue: any) => void;
  value: string;
}

export default function TextFieldContent(props: IProps) {
  const { id, value, field, fieldProps, onChange } = props;
  const [getText, setText] = useState(value);
  const onChangeDebounced = useMemo(() => getDebounceMethod(onChange, 500), [onChange]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeHandler = (value: string) => {
    setText(value);
    onChangeDebounced && onChangeDebounced(field, value);
  };

  return (
    <>
      <RichTextField id={id} value={getText} readonly={field?.disabled} onChange={onChangeHandler} {...fieldProps} />
    </>
  );
}
