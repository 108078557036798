import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyControlViewList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyControlViewList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Control View"
        appInsightsPageName="MyControlViewListPage"
        leftNavUrl={leftNavUrls.management.controlView}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadControlViewConfigItems,
  deleteConfigItem: actionCreator.deleteControlViewConfigItem,
  publishConfigItem: actionCreator.publishControlViewConfigItem,
  unpublishConfigItem: actionCreator.unpublishControlViewConfigItem,
  revertConfigItem: actionCreator.revertControlViewConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyControlViewList);
