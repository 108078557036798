import React from "react";
import { Icon, IIconProps } from "@fluentui/react/lib/Icon";
import classNames from "./EntityNode.module.scss";

export interface IEntityNodeProps {
  type: string;
  id?: string;
  version?: string;
  idHighlight?: boolean;
  iconProps?: IIconProps;
}

export class EntityNode extends React.Component<IEntityNodeProps> {
  render() {
    const { type, id, version, idHighlight, iconProps } = this.props;

    let idClassNames = [classNames.id];
    idHighlight && idClassNames.push(classNames.idHighlight);

    return (
      <div className={classNames.root}>
        {iconProps && <Icon className={classNames.icon} {...iconProps} />}
        <div className={classNames.type}>{type}</div>
        {id && (
          <div className={idClassNames.join(" ")}>
            {id}
            {version !== undefined ? `, v${version}` : ""}
          </div>
        )}
      </div>
    );
  }
}

export default EntityNode;
