import React from "react";
import { connect } from "react-redux";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { ResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IState } from "../../reducers/interfaces";
import { IFilter } from "../common/interfaces";
import { IIncidentView } from "./interfaces";
import { getFilterOptions } from "../common/helper";
import { actionCreator } from "../duck";
import { History } from "history";
import { leftNavUrls } from "../../app/LeftNav.helper";
import classNames from "./IncidentFilters.module.scss";

const allViewsSearchText = "(All Views Search)";

export interface IIncidentFiltersProps {
  history: History;
  displayAsReport?: boolean;
}

export interface IIncidentFiltersStateProps {
  filters: IFilter[];
  selectedFilters: string[];
  selectedView: string;
  incidentViews: IIncidentView[];
  searchText: string;
  loading: boolean;
}

export interface IIncidentFiltersDispatchProps {
  selectIncidentFilter: (filterKey: any) => void;
  selectIncidentView: (incidentView: string) => void;
  loadIncidents: (incidentView: string) => void;
  loadIncidentTiles: (incidentViewId: string) => void;
  loadIncident: (incidentId: string, withDetails: boolean) => void;
  changeIncidentSearchText: (searchText: string) => void;
}

export class IncidentFilters extends React.Component<
  IIncidentFiltersProps & IIncidentFiltersStateProps & IIncidentFiltersDispatchProps
> {
  private timeoutId: NodeJS.Timeout;

  render() {
    const { filters, selectedFilters, selectedView, incidentViews, searchText, loading, displayAsReport } = this.props;

    if (loading && !searchText) return null;

    let filterOptions = [];

    if (filters?.length) {
      filterOptions = getFilterOptions(filters);
    }

    let viewOptions = incidentViews?.map((view) => ({ key: view.id, text: view.name, ariaLabel: view.name }));

    viewOptions?.unshift({ key: "", text: allViewsSearchText, ariaLabel: allViewsSearchText });

    return (
      <div className={classNames.root}>
        <div className={classNames.search}>
          <SearchBox
            value={searchText}
            ariaLabel="Search on this view"
            placeholder="Search on this view"
            onChange={this.onSearchChange}
            width={200}
            disableAnimation={true}
          />
        </div>
        {viewOptions && viewOptions.length > 1 && !displayAsReport && (
          <Dropdown
            className={classNames.views}
            selectedKey={selectedView}
            options={viewOptions}
            responsiveMode={ResponsiveMode.large}
            onChange={this.onViewChange}
            label="Select a view"
            ariaLabel="Select a view"
          />
        )}
        <Dropdown
          className={classNames.filters}
          placeholder="Select filters"
          selectedKeys={selectedFilters}
          multiSelect
          options={filterOptions}
          responsiveMode={ResponsiveMode.large}
          disabled={!filters?.length}
          onChange={this.onFilterChange}
          label="Select filters"
          ariaLabel="Select Filters"
        />
      </div>
    );
  }

  onFilterChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    this.props.selectIncidentFilter(item.key);
  };

  onViewChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    const { loadIncidents, loadIncidentTiles, selectIncidentView, history } = this.props;
    const incidentViewId = item?.key?.toString() || "";

    history.push(`${leftNavUrls.all.financialIncidents}/${incidentViewId}`);
    selectIncidentView(incidentViewId);

    if (incidentViewId) {
      loadIncidents(incidentViewId);
      loadIncidentTiles(incidentViewId);
    }
  };

  onSearchChange = (ev, newValue: string) => {
    const { selectedView, loadIncident } = this.props;

    this.props.changeIncidentSearchText(newValue);

    newValue = newValue?.trim();

    clearInterval(this.timeoutId);
    this.timeoutId = setTimeout(() => !selectedView && loadIncident(newValue, false), 500);
  };
}

const mapStateToProps = (state: IState): IIncidentFiltersStateProps => {
  return {
    filters: state.modules.incident_filters,
    selectedFilters: state.modules.selected_incident_filters,
    selectedView: state.modules.selected_incident_view_id,
    incidentViews: state.modules.incident_views,
    searchText: state.modules.incident_view_search_text,
    loading: state.modules.loading_incident_views || state.modules.loading_incidents || state.modules.loading_incident,
  };
};

const mapDispatchToProps: IIncidentFiltersDispatchProps = {
  selectIncidentFilter: actionCreator.selectIncidentFilter,
  selectIncidentView: actionCreator.selectIncidentView,
  loadIncidents: actionCreator.loadIncidents,
  loadIncidentTiles: actionCreator.loadIncidentTiles,
  changeIncidentSearchText: actionCreator.changeIncidentSearchText,
  loadIncident: actionCreator.loadIncident,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentFilters);
