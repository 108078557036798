import React from "react";
import { IExtensibleStyle, getStyle, IClassNames } from "../common/styleHelper";
import { CommandBar, ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { TileFullScreen } from "./TileFullScreen";
import { VisualType } from "../Visual";
import classNames from "./Tile.module.scss";
import { ContentDisplayType } from "../../utilities/miscHelper";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import ItemsPickerDialog from "../ItemsPicker/ItemsPickerDialog";
import { IColumn } from "@fluentui/react";
import { IItem } from "../ItemsPicker/ItemsPicker";

export enum BuiltInCommandBarItemKey {
  changeVisualType = "changeVisualType",
  showHideColumns = "showHideColumns",
  showFullScreen = "showFullScreen",
  toggleContent = "toggleContent",
}

export interface ITileProps {
  title?: string;
  subtitle?: string;
  className?: string;
  classNames?: IClassNames;
  headerLevel?: number;
  style?: IExtensibleStyle;
  applyContentStyle?: boolean;
  menuContent?: JSX.Element;
  commandBarProps?: ICommandBarProps;
  commandBarItemKeys?: string[];
  columns?: IColumn[];
  compact?: boolean;
  contentHeight?: string;
  maxContentHeight?: string;
  categoryDisplayType?: ContentDisplayType;
  contentCollapsed?: boolean;
  showSearchBox?: boolean;
  selectedColumnKeys?: string;
  onVisualTypeChange?: (visualType: VisualType) => void;
  onSearchChange?: (newValue: string) => void;
  onResize?: () => void;
  onColumnSelectionChange?: (selectedKeys: string) => void;
}

export interface ITileState {
  showFullScreen?: boolean;
  contentCollapsed?: boolean;
  showColumnsPicker?: boolean;
}

const getHeader = (headerProps: ITileProps, title: string, headerLevel: number) => {
  /* eslint-disable jsx-a11y/heading-has-content */
  switch (headerLevel) {
    case 1:
      return <h1 {...headerProps}></h1>;
    case 3:
      return <h3 {...headerProps}></h3>;
    case 4:
      return <h4 {...headerProps}></h4>;
    case 5:
      return <h5 {...headerProps}></h5>;
    default:
      return <h2 {...headerProps}></h2>;
  }
  /* eslint-enable jsx-a11y/heading-has-content */
};

export class Tile extends React.Component<ITileProps, ITileState> {
  rootElement: HTMLElement = null;

  constructor(props) {
    super(props);

    this.state = {
      contentCollapsed: props.contentCollapsed === true,
    };
  }

  componentDidMount() {
    if (window.ResizeObserver) {
      const observer = new ResizeObserver(() => {
        this.onResize();
      });

      observer.observe(this.rootElement);
    }
  }

  render() {
    const {
      title,
      subtitle,
      menuContent,
      children,
      className,
      classNames: propClassNames = {},
      headerLevel = 2,
      style,
      applyContentStyle,
      commandBarProps,
      compact,
      showSearchBox,
      contentHeight = "100%",
      maxContentHeight = "100%",
      columns,
      selectedColumnKeys,
      onSearchChange,
      onColumnSelectionChange,
    } = this.props;

    const { showFullScreen, contentCollapsed, showColumnsPicker } = this.state;

    if (showFullScreen) {
      return (
        <TileFullScreen
          {...this.props}
          onClose={() =>
            this.setState({ showFullScreen: false }, () => {
              this.rootElement.focus();
            })
          }
        >
          {children}
        </TileFullScreen>
      );
    }

    const subtitleFinal = subtitle?.trim();
    const compactClassName = compact ? classNames.compact : "",
      contentClassNames = ["Tile_content", classNames.content, propClassNames.content, compactClassName];

    applyContentStyle && contentClassNames.push(classNames.extraContent);

    const finalCommandBarProps = this.getCommandBarProps();
    const hasCommandBarItems =
      finalCommandBarProps.items.length ||
      finalCommandBarProps.farItems?.length ||
      finalCommandBarProps.overflowItems.length > 0;

    let headerWithMenu = menuContent ? classNames.headerWithMenu : "";

    // Define animation (using CSS transition) for tile content collapse and expand state.
    let contentStyle = getStyle(style, "content") || {};
    contentStyle.transition = "height 0.4s, max-height 0.4s" + (contentCollapsed ? ", padding 0s linear 0.4s" : "");
    contentStyle.height = contentCollapsed ? "0px" : contentHeight;
    contentStyle.maxHeight = contentCollapsed ? "0px" : maxContentHeight;
    contentStyle.overflow = contentCollapsed ? "hidden" : "auto";
    contentCollapsed && (contentStyle.padding = "0px");

    if (subtitleFinal) {
      headerWithMenu += " " + classNames.headerTaller;
    }

    const headerProps = {
      className: `Tile_title ${classNames.title} ${propClassNames.title} ${compactClassName}`,
      style: getStyle(style, "title"),
      dangerouslySetInnerHTML: { __html: title },
      title: title,
    };

    let searchBoxClassNames = ["Tile_search", classNames.search, propClassNames.search, compactClassName];

    if (!menuContent && !hasCommandBarItems) {
      searchBoxClassNames.push(classNames.searchOnly);
    }

    const columnItems: IItem[] = columns?.map((column) => ({ key: column.fieldName, text: column.name }));

    return (
      <>
        <div
          className={`Tile_root ${classNames.root} ${propClassNames.tile} ${className}`}
          style={style}
          tabIndex={-1}
          ref={(element) => (this.rootElement = element)}
        >
          {(title || subtitleFinal || menuContent || commandBarProps) && (
            <div
              className={`Tile_header ${classNames.header} ${propClassNames.header} ${headerWithMenu} ${compactClassName}`}
            >
              <div
                className={`${classNames.headerTopRow} ${compactClassName}`}
                style={subtitleFinal ? { height: "24px" } : undefined}
              >
                {title && getHeader(headerProps, title, headerLevel)}
                {showSearchBox && (
                  <SearchBox
                    className={searchBoxClassNames.join(" ")}
                    onChange={(ev, newValue) => onSearchChange && onSearchChange(newValue)}
                    aria-label="Search this tile"
                    placeholder="Search this tile"
                    tabIndex={0}
                  />
                )}
                {menuContent && (
                  <div
                    className={`Tile_menu ${classNames.menu} ${propClassNames.menu} ${compactClassName}`}
                    style={getStyle(style, "menu")}
                  >
                    {menuContent}
                  </div>
                )}
                {!compact && hasCommandBarItems && (
                  <CommandBar
                    className={`Tile_commandBar ${classNames.commandBar} ${propClassNames.commandBar} ${compactClassName}`}
                    {...finalCommandBarProps}
                    ariaLabel="Tile Command Bar"
                    overflowButtonProps={{
                      ariaLabel: "More Commands",
                      title: "More Commands",
                    }}
                    shiftOnReduce={false}
                  />
                )}
              </div>
              {subtitleFinal && (
                <div
                  className={`Tile_subtitle ${classNames.subtitle} ${propClassNames.subtitle} ${compactClassName}`}
                  style={getStyle(style, "subTitle")}
                  dangerouslySetInnerHTML={{ __html: subtitleFinal }}
                  title={subtitleFinal}
                />
              )}
            </div>
          )}
          <div className={contentClassNames.join(" ")} style={contentStyle} data-is-scrollable="true">
            {children}
          </div>
        </div>
        {showColumnsPicker && !!columnItems?.length && (
          <ItemsPickerDialog
            hidden={!showColumnsPicker}
            label="Columns"
            items={columnItems}
            selectedKeys={selectedColumnKeys || columnItems?.map((item) => item.key)?.join(",")}
            onCommitClick={onColumnSelectionChange}
            onDismiss={() => this.setState({ showColumnsPicker: false })}
          />
        )}
      </>
    );
  }

  getCommandBarProps = (): ICommandBarProps => {
    const { commandBarProps, commandBarItemKeys } = this.props;

    var finalCommandBarProps = commandBarProps
      ? {
          ...commandBarProps,
          items: commandBarProps.items || [],
          overflowItems: commandBarProps.overflowItems || [],
        }
      : { items: [], overflowItems: [] };

    if (commandBarItemKeys && commandBarItemKeys.length) {
      let builtInItems = this.getBuiltInCommandBarItems(commandBarItemKeys);

      if (builtInItems && builtInItems.length) {
        finalCommandBarProps.items = finalCommandBarProps.items.concat(builtInItems);
      }

      let builtInOverflowItems = this.getBuiltInCommandBarOverflowItems(commandBarItemKeys);

      if (builtInOverflowItems && builtInOverflowItems.length) {
        finalCommandBarProps.overflowItems = finalCommandBarProps.overflowItems.concat(builtInOverflowItems);
      }
    }

    return finalCommandBarProps;
  };

  getBuiltInCommandBarItems = (selectedKeys: string[]) => {
    const { title } = this.props;
    const { contentCollapsed, showColumnsPicker } = this.state;
    var actionVerb = contentCollapsed ? "Expand" : "Collapse";

    return [
      {
        key: "toggleContent",
        name: `${actionVerb} Content`,
        ariaLabel: `${actionVerb} Content` + (title ? ` Of the ${title} Tile` : ""),
        iconProps: { iconName: contentCollapsed ? "ChevronDown" : "ChevronUp" },
        iconOnly: true,
        role: "menuitem",
        onClick: () => this.setState({ contentCollapsed: !contentCollapsed }),
      },
      {
        key: "showHideColumns",
        name: "Show/Hide Columns",
        ariaLabel: "Show/Hide Columns",
        iconProps: { iconName: "RedEye" },
        iconOnly: true,
        role: "menuitem",
        onClick: () => this.setState({ showColumnsPicker: !showColumnsPicker }),
      },
    ].filter((item) => selectedKeys && selectedKeys.indexOf(item.key) >= 0);
  };

  getBuiltInCommandBarOverflowItems = (selectedKeys: string[]) => {
    return [
      {
        key: "changeVisualType",
        name: "Change Visual Type",
        ariaLabel: "Change Visual Type",
        iconProps: { iconName: "Chart" },
        role: "button",
        subMenuProps: {
          items: [
            {
              key: VisualType.area,
              name: "Area Chart",
              iconProps: {
                iconName: "AreaChart",
              },
              onClick: () => this.onVisualTypeChange(VisualType.area),
            },
            {
              key: VisualType.bar,
              name: "Bar Chart",
              iconProps: {
                iconName: "BarChartHorizontal",
              },
              onClick: () => this.onVisualTypeChange(VisualType.bar),
            },
            {
              key: VisualType.column,
              name: "Column Chart",
              iconProps: {
                iconName: "BarChartVertical",
              },
              onClick: () => this.onVisualTypeChange(VisualType.column),
            },
            {
              key: VisualType.table,
              name: "Data Table",
              iconProps: {
                iconName: "Table",
              },
              onClick: () => this.onVisualTypeChange(VisualType.table),
            },
            {
              key: VisualType.diagram,
              name: "Diagram",
              iconProps: {
                iconName: "Dataflows",
              },
              onClick: () => this.onVisualTypeChange(VisualType.diagram),
            },
            {
              key: VisualType.line,
              name: "Line Chart",
              iconProps: {
                iconName: "LineChart",
              },
              onClick: () => this.onVisualTypeChange(VisualType.line),
            },
            {
              key: VisualType.pie,
              name: "Pie Chart",
              iconProps: {
                iconName: "PieSingle",
              },
              onClick: () => this.onVisualTypeChange(VisualType.pie),
            },
          ],
        },
      },
      {
        key: "showFullScreen",
        name: "Show Full Screen",
        ariaLabel: "Show Full Screen",
        title: "Show Full Screen",
        iconProps: { iconName: "TVMonitor" },
        onClick: () => this.setState({ showFullScreen: true }),
      },
    ].filter((item) => selectedKeys && selectedKeys.indexOf(item.key) >= 0);
  };

  onVisualTypeChange = (visualType: VisualType) => {
    const { onVisualTypeChange } = this.props;

    onVisualTypeChange && onVisualTypeChange(visualType);
  };

  onResize = () => {
    const { onResize } = this.props;

    onResize && onResize();
  };

  getWidth = (): number => this.rootElement && this.rootElement.clientWidth;
}

export default Tile;
