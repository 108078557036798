import React from "react";
import {
  extendTimeRange,
  getTickTimes,
  getMarkerTooltipText,
  getTicklineLabel,
  ticksInOneDay,
} from "../TimelineChart/TimelineChart.helper";
import { ITimelineProps } from "./Timeline";

export const getTimelineContent = (
  props: ITimelineProps,
  contentWidth: number,
  contentHeight: number,
  classNames: any
): JSX.Element[] => {
  if (!contentWidth || !contentHeight) return null;

  let timelineInfo: ITimelineInfo = getTimelineInfo(props, contentWidth, contentHeight);

  if (!timelineInfo.timeWidth)
    return [
      <text className={classNames.errorText} x={0} y={20} key="tlerror">
        {props.invalidTimelineDataMessage}
      </text>,
    ];

  return [
    ...getDateTickLinesAndLabels(timelineInfo, props, classNames),
    ...getTimelineArtifacts(timelineInfo, props, classNames),
  ];
};

interface ITimelineInfo {
  minTime?: number;
  maxTime?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  timeWidth?: number;
  width?: number;
  height?: number;
  tickTimes?: number[];
}

const getTimelineInfo = (props: ITimelineProps, contentWidth: number, contentHeight: number): ITimelineInfo => {
  const { markers, extendTimeRange: shouldExtendTimeRange, timeLabelType } = props;

  let initialTime = markers && markers.length ? new Date(markers[0].time).valueOf() : new Date().valueOf(),
    minTime = initialTime,
    maxTime = initialTime;

  // Calculate the min and max time range.
  markers &&
    markers.forEach((marker) => {
      let markerTime = new Date(marker.time).valueOf();
      minTime = Math.min(minTime, markerTime);
      maxTime = Math.max(maxTime, markerTime);
    });

  if (shouldExtendTimeRange) {
    // Extend the time range to closest 1st of month and last month
    let timeRange = extendTimeRange({ minTime, maxTime }, contentWidth, true, timeLabelType);

    minTime = timeRange.minTime;
    maxTime = timeRange.maxTime;
  }

  if (maxTime <= minTime) {
    // Extend maxTime to be at least one day greater than minTime.
    maxTime = minTime + ticksInOneDay;
    minTime = minTime - ticksInOneDay;
  }

  minTime = new Date(minTime).setHours(0, 0, 0, 0);
  maxTime = new Date(maxTime).setHours(23, 59, 59, 999);

  let width = contentWidth - props.headWidth - props.tailWidth;

  return {
    minTime,
    maxTime,
    top: 0,
    bottom: contentHeight - props.xAxisPadding,
    left: props.tailWidth,
    right: contentWidth - props.headWidth,
    width,
    height: contentHeight - props.xAxisPadding,
    timeWidth: maxTime - minTime,
    tickTimes: getTickTimes(minTime, maxTime, width, timeLabelType),
  };
};

const getTimelineArtifacts = (info: ITimelineInfo, props: ITimelineProps, classNames: any): JSX.Element[] => {
  const content: JSX.Element[] = [],
    markerContent: JSX.Element[] = [];

  const {
    markers,
    markerFlagStyle,
    timelineHeight,
    tailWidth,
    headWidth,
    markerFlagLineMinLength,
    markerHeight,
    showMarkerFlags,
    showMarkers,
    markerSize,
  } = props;

  // Draw the tail of the timeline.
  const halfHeight = props.timelineHeight / 2;

  content.push(
    <polygon
      key={"rtail"}
      className={classNames.rect}
      points={`${info.left} ${info.bottom}, 0 ${info.bottom}, ${halfHeight} ${info.bottom - halfHeight},
      0 ${info.bottom - timelineHeight}, ${tailWidth} ${info.bottom - timelineHeight}`}
    />
  );

  let timelineWidth = info.right - info.left,
    markerLeft = info.left,
    markerRectLeft = info.left,
    prevMarkerTime = info.minTime,
    nextStyle = {},
    nextValue = undefined,
    showValueText = false,
    markersCopy = markers && markers.slice();

  markersCopy &&
    markersCopy
      .sort((a, b) => (a.time > b.time ? 1 : a.time < b.time ? -1 : 0))
      .forEach((marker, mIndex) => {
        let markerTime = new Date(marker.time).valueOf(),
          markerWidth = Math.round(((markerTime - prevMarkerTime) / info.timeWidth) * timelineWidth),
          tooltipText = getMarkerTooltipText(marker);

        markerLeft = (timelineWidth * (markerTime - info.minTime)) / info.timeWidth + props.tailWidth;

        // Draw each segment of the timeline based on the markers.
        content.push(
          <rect
            key={"mr" + mIndex}
            className={classNames.rect}
            style={nextStyle}
            x={markerRectLeft}
            y={info.bottom - timelineHeight}
            width={markerWidth}
            height={timelineHeight}
          />
        );

        if (nextValue !== undefined && showValueText && markerWidth > 20) {
          content.push(
            <text
              key={"mvt" + mIndex}
              className={classNames.markerValueText}
              x={markerRectLeft + markerWidth / 2}
              y={info.bottom - (timelineHeight - markerSize) / 2}
              textAnchor="middle"
            >
              {nextValue}
            </text>
          );
        }

        markerRectLeft = markerLeft;
        prevMarkerTime = markerTime;
        nextStyle = marker.color ? { fill: marker.color } : {};
        nextValue = marker.value?.toString();
        showValueText = marker.showValueText;

        // Draw the marker flag.
        let markerFlagFinalStyle = {
            height: markerHeight,
            ...markerFlagStyle,
          },
          flagTextStyle = marker.color ? { backgroundColor: marker.color } : {},
          flagLineStyle = marker.color ? { stroke: marker.color } : {},
          markerBottom = info.bottom - markerFlagLineMinLength - markerHeight * (markers.length - mIndex);

        if (showMarkerFlags) {
          content.push(
            <foreignObject
              key={"mf" + mIndex}
              className={classNames.flag}
              style={markerFlagFinalStyle}
              x={markerLeft}
              y={markerBottom}
              height={markerHeight}
              width={120}
            >
              <div className={classNames.flagText} style={flagTextStyle} title={tooltipText}>
                {marker.name}
              </div>
            </foreignObject>
          );

          // Draw the marker flag connecting line.
          content.push(
            <line
              key={"mfl" + mIndex}
              className={classNames.flagLine}
              x1={markerLeft}
              y1={info.bottom}
              x2={markerLeft}
              y2={markerBottom}
              style={flagLineStyle}
            />
          );
        }

        if (showMarkers) {
          markerContent.push(
            <use
              key={"mm" + mIndex}
              className={classNames.marker}
              href={`#diamond`}
              x={markerLeft - markerSize}
              y={info.bottom - timelineHeight + markerSize / 2}
            >
              <title>{marker.name}</title>
            </use>
          );
        }
      });

  // Draw the last segment of the timeline.
  content.push(
    <rect
      key={"mrlast"}
      className={classNames.rect}
      style={nextStyle}
      x={markerLeft}
      y={info.bottom - timelineHeight}
      width={info.right - markerLeft}
      height={timelineHeight}
    />
  );

  if (nextValue !== undefined && showValueText) {
    content.push(
      <text
        key={"mvt-last"}
        className={classNames.markerValueText}
        x={markerLeft + markerSize * 2}
        y={info.bottom - (timelineHeight - markerSize) / 2}
      >
        {nextValue}
      </text>
    );
  }

  // Draw the head of the timeline.
  const headX = info.right;
  content.push(
    <polygon
      key="rhead"
      className={classNames.rect}
      style={nextStyle}
      points={`${headX - 1} ${info.bottom}, ${headX + headWidth - halfHeight} ${info.bottom},
      ${headX + headWidth} ${info.bottom - halfHeight},
      ${headX + headWidth - halfHeight} ${info.bottom - timelineHeight},
      ${headX - 1} ${info.bottom - timelineHeight}`}
    />
  );

  // Draw the current marker.
  if (props.currentMarker && props.currentMarker.time) {
    let markerTime = new Date(props.currentMarker.time).valueOf();
    markerLeft = (timelineWidth * (markerTime - info.minTime)) / info.timeWidth + props.tailWidth;

    content.push(
      <use
        key={"mcm"}
        className={classNames.marker}
        href={`#triangle`}
        x={markerLeft - markerSize}
        y={info.bottom - timelineHeight - markerSize * 3}
        style={{ fill: props.currentMarker.color || "auto" }}
      />
    );

    if (props.currentMarker.name) {
      content.push(
        <text
          key="mcmt"
          className={classNames.currentMarkerName}
          x={markerLeft}
          y={info.bottom - timelineHeight - markerSize * 3}
        >
          {props.currentMarker.name}
        </text>
      );
    }
  }

  if (markerContent.length) {
    return content.concat(markerContent);
  }
  return content;
};

const getDateTickLinesAndLabels = (info: ITimelineInfo, props: ITimelineProps, classNames: any): JSX.Element[] => {
  const { tickLength, xAxisLabelFontSize, xAxisLabelPadding, timelineHeight, timeLabelType } = props;

  let content: JSX.Element[] = [],
    prevTickYear = 0;

  info.tickTimes &&
    info.tickTimes.forEach((tickTime, index) => {
      let tickLeft = (info.width * (tickTime - info.minTime)) / info.timeWidth + props.tailWidth,
        tickDate = new Date(tickTime);

      content.push(
        <line
          key={"tl" + index}
          x1={tickLeft}
          y1={info.height - timelineHeight}
          x2={tickLeft}
          y2={info.height + tickLength}
          className={classNames.tickLine}
        />
      );

      content.push(
        getTicklineLabel(
          prevTickYear,
          tickTime,
          tickLeft,
          info.bottom,
          index,
          xAxisLabelFontSize,
          xAxisLabelPadding,
          classNames.dateLabel,
          timeLabelType
        )
      );

      prevTickYear = tickDate.getFullYear();
    });

  return content;
};
