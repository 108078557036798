import React, { CSSProperties } from "react";
import { ITimelineMarker } from "../TimelineChart/interfaces";
import { getTimelineContent } from "./Timeline.helper";
import { TimeLabelType } from "../TimelineChart/TimelineChart.helper";
import { IClassNames, IExtensibleStyle } from "../common/interfaces";
import classNames from "./Timeline.module.scss";

export { TimeLabelType } from "../TimelineChart/TimelineChart.helper";

export interface ITimelineProps {
  markers: ITimelineMarker[];
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  height?: number | string;
  minHeight?: number;
  maxHeight?: number;
  xAxisPadding?: number;
  xAxisLabelPadding?: number;
  timeLabelType?: TimeLabelType;
  xAxisLabelFontSize?: number;
  timelineHeight?: number;
  headWidth?: number;
  tailWidth?: number;
  className?: string;
  classNames?: IClassNames;
  style?: IExtensibleStyle;
  tickLength?: number;
  markerHeight?: number;
  markerFlagStyle?: CSSProperties;
  markerFlagLineMinLength?: number;
  showMarkerFlags?: boolean;
  showMarkers?: boolean;
  markerSize?: number;
  extendTimeRange?: boolean;
  invalidTimelineDataMessage?: string;
  currentMarker?: ITimelineMarker;
}

export interface ITimelineState {
  contentWidth: number;
  contentHeight: number;
}

export class Timeline extends React.Component<ITimelineProps, ITimelineState> {
  private element: HTMLElement;

  static defaultProps: ITimelineProps = {
    markers: [],
    width: "100%",
    height: "auto",
    minWidth: 200,
    minHeight: 40,
    maxHeight: 600,
    xAxisPadding: 24,
    xAxisLabelPadding: 5,
    xAxisLabelFontSize: 12,
    timelineHeight: 10,
    headWidth: 40,
    tailWidth: 40,
    tickLength: 3,
    markerHeight: 26,
    markerFlagStyle: {
      width: 120,
    },
    markerFlagLineMinLength: 20,
    showMarkerFlags: true,
    extendTimeRange: true,
    showMarkers: false,
    markerSize: 2,
    timeLabelType: TimeLabelType.month,
    invalidTimelineDataMessage: "Invalid timeline data are detected.",
  };

  constructor(props: ITimelineProps) {
    super(props);
    this.state = {
      contentWidth: 0,
      contentHeight: 0,
    };
  }

  componentDidMount() {
    this.onResize();

    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate() {
    this.onResize();
  }

  render() {
    const { width, height, minWidth, maxWidth, minHeight, maxHeight, markers, markerSize, className } = this.props;
    const { contentWidth, contentHeight } = this.state;

    if (!markers || !markers.length) {
      return null;
    }

    const rootStyle = {
      width,
      height: height === "auto" ? this.getAutoHeight() : height,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
    };

    const content: JSX.Element[] = getTimelineContent(this.props, contentWidth, contentHeight, classNames);

    return (
      <div className={`${classNames.root} ${className}`} style={rootStyle} ref={(el) => (this.element = el)}>
        <svg className={classNames.svg}>
          <symbol id="diamond" className={classNames.marker}>
            <polygon
              points={`${markerSize} 0, ${markerSize * 2} ${markerSize * 2}, ${markerSize} ${markerSize * 4}, 0 ${
                markerSize * 2
              }`}
            />
          </symbol>
          <symbol id="triangle" className={classNames.marker}>
            <polygon points={`${0} ${markerSize}, ${markerSize * 2} ${markerSize}, ${markerSize} ${markerSize * 3}`} />
          </symbol>
          {content}
        </svg>
      </div>
    );
  }

  onResize = () => {
    if (this.element) {
      let clientWidth = this.element.clientWidth,
        clientHeight = this.element.clientHeight;

      if (this.state.contentWidth !== clientWidth || this.state.contentHeight !== clientHeight) {
        this.setState({
          contentWidth: this.element.clientWidth,
          contentHeight: this.element.clientHeight,
        });
      }
    }
  };

  getAutoHeight = (): number => {
    const { maxHeight, markerHeight, markerFlagLineMinLength, xAxisPadding, markers, showMarkerFlags, timelineHeight } =
      this.props;

    if (showMarkerFlags) {
      const markerCount = markers ? markers.length : 0,
        calculatedHeight = markerHeight * markerCount + markerFlagLineMinLength + xAxisPadding;

      return Math.min(maxHeight, calculatedHeight);
    } else {
      return timelineHeight + xAxisPadding;
    }
  };
}

export default Timeline;
