// /// <reference path="../../../typings/global.d.ts" />

import React from "react";
import {
  getChartContent,
  getChartBottomContent,
  markerShapeId,
  MarkerMode,
  calcChartInfo,
  TimeLabelType,
} from "./TimelineChart.helper";
import { ITimelineGroup, ITimelineData, ITimeline } from "./interfaces";
import classNames from "./TimelineChart.module.scss";

export interface ITimelineChartProps {
  groups: ITimelineGroup[];
  width?: number | string;
  minChartWidth?: number;
  maxChartWidth?: number;
  height?: number | string;
  minChartHeight?: number;
  maxChartHeight?: number;
  topXAxisPadding?: number;
  bottomXAxisPadding?: number;
  rightYAxisPadding?: number;
  leftYAxisPadding?: number;
  xAxisLabelPadding?: number;
  yAxisLabelPadding?: number;
  timelineGap?: number;
  tickLength?: number;
  maxTimelineHeight?: number;
  minTimelineHeight?: number;
  labelFontSizeFactor?: number;
  xAxisLabelFontSize?: number;
  startTime?: string | number | Date;
  endTime?: string | number | Date;
  markerSize?: number;
  useMarkerColor?: boolean;
  defaultMarkerShapeId?: string;
  markerMode?: MarkerMode;
  className?: string;
  timeLabelType?: TimeLabelType;
  onDataClick?: (data: ITimelineData) => void;
  onGroupLabelClick?: (group: ITimelineGroup) => void;
  onTimelineLabelClick?: (timeline: ITimeline) => void;
}

export interface ITimelineChartState {
  contentWidth: number;
  contentHeight: number;
  groups: ITimelineGroup[];
}

export class TimelineChart extends React.Component<ITimelineChartProps, ITimelineChartState> {
  private element: HTMLElement;

  static defaultProps = {
    topXAxisPadding: 0,
    bottomXAxisPadding: 24,
    rightYAxisPadding: 200,
    leftYAxisPadding: 200,
    xAxisLabelPadding: 5,
    yAxisLabelPadding: 5,
    timelineGap: 1,
    tickLength: 3,
    maxTimelineHeight: 20,
    minTimelineHeight: 14,
    labelFontSizeFactor: 0.7,
    xAxisLabelFontSize: 12,
    width: "100%",
    height: "auto",
    markerSize: 3,
    minChartWidth: 200,
    minChartHeight: 20,
    maxChartHeight: 400,
    useMarkerColor: false,
    defaultMarkerShapeId: markerShapeId.diamond,
    markerMode: MarkerMode.colorOnly,
    timeLabelType: TimeLabelType.month,
  };

  constructor(props: ITimelineChartProps) {
    super(props);

    this.state = {
      contentHeight: 0,
      contentWidth: 0,
      groups: props.groups,
    };
  }

  static getDerivedStateFromProps(nextProps: ITimelineChartProps, state: ITimelineChartState) {
    if (state.groups !== nextProps.groups) {
      return {
        contentHeight: 0,
        contentWidth: 0,
        groups: nextProps.groups,
      };
    }
    return null;
  }

  componentDidMount() {
    this.onResize();

    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate() {
    this.onResize();
  }

  render() {
    const {
      width,
      height,
      minChartWidth,
      maxChartWidth,
      minChartHeight,
      maxChartHeight,
      markerSize,
      topXAxisPadding,
      bottomXAxisPadding,
      leftYAxisPadding,
      rightYAxisPadding,
      maxTimelineHeight,
      className,
    } = this.props;

    const { contentHeight, contentWidth } = this.state;

    let chartInfo = calcChartInfo(this.props, contentWidth, contentHeight),
      chartContent = getChartContent(chartInfo, this.props, classNames, contentWidth, contentHeight),
      chartBottomContent = getChartBottomContent(chartInfo, this.props, classNames),
      initialContentHeightEstimation =
        maxTimelineHeight * chartInfo.timelineCount + topXAxisPadding + bottomXAxisPadding,
      markerSize0_75 = markerSize * 0.75,
      markerSize1_25 = markerSize * 1.25,
      markerSize1_5 = markerSize * 1.5,
      markerSize2 = markerSize * 2,
      markerSize2_5 = markerSize * 2.5,
      markerSize3 = markerSize * 3,
      markerSize4 = markerSize * 4;

    let rootStyle = {
        width,
        height: height === "auto" ? this.getAutoHeight(contentHeight || initialContentHeightEstimation) : height,
        minWidth: minChartWidth ? minChartWidth + leftYAxisPadding + rightYAxisPadding : "auto",
        maxWidth: maxChartWidth ? maxChartWidth + leftYAxisPadding + rightYAxisPadding : "auto",
        minHeight: minChartHeight ? minChartHeight + topXAxisPadding + bottomXAxisPadding : "auto",
        maxHeight: maxChartHeight ? maxChartHeight + topXAxisPadding + bottomXAxisPadding : "auto",
      },
      contentStyle = {
        bottom: bottomXAxisPadding + "px",
      },
      bottomContentStyle = {
        height: bottomXAxisPadding + "px",
      };

    return (
      <div className={`${classNames.root} ${className}`} style={rootStyle} ref={(el) => (this.element = el)}>
        <div className={classNames.content} style={contentStyle}>
          <svg className={classNames.svg} style={{ height: chartInfo.totalTimelineHeight + "px" }}>
            <symbol id={markerShapeId.star} className={classNames.marker}>
              <polygon
                points={`${markerSize} 0, ${markerSize1_25} ${markerSize1_5}, ${markerSize2} ${markerSize2}, 
              ${markerSize1_25} ${markerSize2_5}, ${markerSize} ${markerSize4}, ${markerSize0_75} ${markerSize2_5},
              0 ${markerSize2}, ${markerSize0_75} ${markerSize1_5}`}
              />
            </symbol>
            <symbol id={markerShapeId.diamond} className={classNames.marker}>
              <polygon
                points={`${markerSize} 0, ${markerSize2} ${markerSize2}, ${markerSize} ${markerSize4}, 0 ${markerSize2}`}
              />
            </symbol>
            <symbol id={markerShapeId.square} className={classNames.marker}>
              <polygon
                points={`0 ${markerSize}, ${markerSize2} ${markerSize}, ${markerSize2} ${markerSize3}, 0, ${markerSize3}`}
              />
            </symbol>
            <symbol id={markerShapeId.triangle} className={classNames.marker}>
              <polygon points={`${markerSize} ${markerSize}, ${markerSize2} ${markerSize3}, 0 ${markerSize3}`} />
            </symbol>
            <symbol id={markerShapeId.circle} className={classNames.marker}>
              <circle cx={markerSize} cy={markerSize2} r={markerSize} />
            </symbol>
            {chartContent}
          </svg>
        </div>
        <div className={classNames.bottomContent} style={bottomContentStyle}>
          <svg className={classNames.svg}>{chartBottomContent}</svg>
        </div>
      </div>
    );
  }

  onResize = () => {
    if (this.element) {
      let clientWidth = this.element.clientWidth,
        clientHeight = this.element.clientHeight;

      if (this.state.contentWidth !== clientWidth || this.state.contentHeight !== clientHeight) {
        this.setState({
          contentWidth: this.element.clientWidth,
          contentHeight: this.element.clientHeight,
        });
      }
    }
  };

  getAutoHeight = (totalTimelinesHeight): number => {
    const { maxChartHeight, topXAxisPadding, bottomXAxisPadding } = this.props;
    const maxHeight = maxChartHeight + topXAxisPadding + bottomXAxisPadding;

    return Math.min(maxHeight, totalTimelinesHeight);
  };
}

export default TimelineChart;
