import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyStreamScheduleList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListStateProps,
  IConfigItemListDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";
import { IState } from "../../../reducers/interfaces";

export class MyStreamScheduleList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Stream Schedule"
        appInsightsPageName="MyStreamScheduleListPage"
        leftNavUrl={leftNavUrls.management.streamSchedule}
      />
    );
  }
}

const mapStateToProps = (state: IState): IConfigItemListStateProps => ({
  ...mapStateToPropsBase(state),
  loading: state.modules.loading_config_items,
  configItems: state.modules.config_items,
});

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadStreamScheduleConfigItems,
  deleteConfigItem: actionCreator.deleteStreamScheduleConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStreamScheduleList);
