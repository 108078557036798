import React from "react";
import { DataForm } from "../../../shared/components/DataForm";
import { getControlSdkInputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";

export interface IControlSdkInputEditorProps {
  control: any;
  controlSdk: any;
}

export const ControlSdkInputEditor = (props: IControlSdkInputEditorProps) => {
  const { control, controlSdk } = props;
  return (
    <div className={classNames.panelContent}>
      <DataForm fields={getControlSdkInputFields(control, controlSdk)} context={control?.controlSdkSettings} />
    </div>
  );
};

export default ControlSdkInputEditor;
