import React, { useCallback } from "react";
import classNames from "./HealthDashboard.module.scss";
import CircleBadge from "../../shared/components/Visual/CircleBadge";
import { getFormatContent, startCase } from "../../shared/utilities/miscHelper";
import { ITeam, ITemplate } from "../common/interfaces";
import { IHealthMetric, IHealthMetricType } from "./HealthDashboard.types";
import { Icon, TooltipHost } from "@fluentui/react";
import { getLastRunTooltipContent, getMetricId, getMetricTemplates } from "./HealthDashboard.helper";
import { Visual } from "../../shared/components/Visual";
import { appInsights } from "../../app/telemetryHelper";

export interface IHealthDashboardMetricsRowProps {
  metricType: IHealthMetricType;
  metricChildType?: IHealthMetricType;
  metricTypeRowspan?: number;
  metricTypeColspan?: number;
  teams: ITeam[];
  healthMetrics: IHealthMetric[];
  standardTemplates: ITemplate[];
  metricSubTypes?: IHealthMetricType[];
  trendCharts?: any[];
}

export const HealthDashboardMetricsRow = (props: IHealthDashboardMetricsRowProps) => {
  const {
    teams,
    healthMetrics,
    standardTemplates,
    trendCharts,
    metricType,
    metricChildType,
    metricTypeRowspan,
    metricTypeColspan,
    metricSubTypes = [{ id: undefined }] as IHealthMetricType[],
  } = props;

  const onMetricClick = useCallback((metric) => {
    if (metric.linkUrl) {
      appInsights.trackEvent({ name: "onMetricClick" }, { ...metric });
      window.open(metric.linkUrl, "_blank");
    }
  }, []);

  const onMetricHover = useCallback((metric) => {
    appInsights.trackEvent({ name: "onMetricHover" }, { ...metric });
  }, []);

  const getTrendIndicator = useCallback(
    (
      useTrend,
      trendIndicatorType,
      trendDirection,
      trendDirectionOverride,
      trendColor,
      trendColorOverride,
      trendShape,
      trendShapeOverride
    ) => {
      let trendDirectionFinal = trendDirectionOverride?.toLowerCase() || trendDirection?.toLowerCase(),
        isTrendDirectional = trendIndicatorType !== "signal",
        isSignalType = trendIndicatorType === "signal",
        isArrowType = trendIndicatorType === "arrow";

      if (
        !useTrend ||
        !trendIndicatorType ||
        (isTrendDirectional &&
          (!trendDirectionFinal ||
            (trendDirectionFinal !== "up" && trendDirectionFinal !== "down" && trendDirectionFinal !== "flat")))
      ) {
        return "";
      }

      let trendClasses = isSignalType
          ? [classNames.trendSignalIndicator]
          : isArrowType
          ? [classNames.trendArrowIndicator]
          : [classNames.trendTriangleIndicator],
        trendColorFinal = trendColorOverride || trendColor,
        trendShapeFinal = trendShapeOverride || trendShape;

      if (isTrendDirectional) {
        if (trendDirectionFinal === "flat") {
          trendClasses.push(classNames.trendShapeFlat);
        } else if (isArrowType) {
          trendClasses.push(trendDirectionFinal === "up" ? classNames.trendArrowUp : classNames.trendArrowDown);
        } else {
          trendClasses.push(trendDirectionFinal === "up" ? classNames.trendTriangleUp : classNames.trendTriangleDown);
        }
      }

      if (isSignalType) {
        trendClasses.push(
          trendShapeFinal === "square"
            ? classNames.trendShapeSquare
            : trendShapeFinal === "triangle"
            ? classNames.trendShapeTriangle
            : classNames.trendShapeCircle
        );
      }

      let trendClassesList = trendClasses.join(" ");

      return isSignalType
        ? `<div class='${trendClassesList}' style='${
            trendShapeFinal === "triangle" ? "border-bottom-color" : "background-color"
          }:${trendColorFinal || "#999"}' />`
        : trendDirectionFinal === "flat"
        ? `<div class='${trendClassesList}' style='background-color: ${trendColorFinal}'`
        : isArrowType
        ? `<div class='${trendClassesList}'><div class='line1' style='border-color:${trendColorFinal}'></div><div class='line2' style='border-color:${trendColorFinal}'></div></div>`
        : `<div class='${trendClassesList}' style='border-bottom-color:${trendColorFinal};border-top-color:${trendColorFinal}' />`;
    },
    []
  );

  if (!metricType) {
    return null;
  }

  let metricCells = [];

  teams.forEach((team) => {
    metricSubTypes
      .filter((metricSubType) => !metricSubType.disabled)
      .forEach((metricSubType) => {
        let metricDefinition = team.healthMetrics?.find(
            (metricDef) =>
              !metricDef.disabled &&
              metricDef.metricType &&
              metricDef.metricType === metricType.id &&
              (!metricDef.metricSubType || metricDef.metricSubType === metricSubType.id) &&
              (!metricDef.metricChildType || metricDef.metricChildType === metricChildType?.id)
          ),
          metricId = getMetricId(metricDefinition, team),
          metricValues = healthMetrics?.find((metric) => metric.id === metricId),
          metric: any = { ...metricDefinition, ...metricValues, metricId },
          tooltipId = "tooltip-" + metricId,
          trendIndicator =
            metric &&
            getTrendIndicator(
              metric.useTrend,
              metric.trendIndicatorType,
              metric.trendDirection,
              metric.trendDirectionOverride,
              metric.trendColor,
              metric.trendColorOverride,
              metric.trendShape,
              metric.trendShapeOverride
            ),
          trendIndicator2 =
            metric &&
            getTrendIndicator(
              metric.useTrend,
              metric.trendIndicatorType2,
              metric.trendDirection2,
              metric.trendDirectionOverride2,
              metric.trendColor2,
              metric.trendColorOverride2,
              metric.trendShape2,
              metric.trendShapeOverride2
            );

        let [template, badgeTemplate, tooltipTemplate] = getMetricTemplates(metric, standardTemplates);

        // Since trendIndicator contains React component, need to preprocess it before calling getFormatContent.
        // eslint-disable-next-line no-template-curly-in-string
        template = template?.replace("${this.trendIndicator}", trendIndicator);
        // eslint-disable-next-line no-template-curly-in-string
        template = template?.replace("${this.trendIndicator2}", trendIndicator2);

        // Handle trendChart logics.
        let trendChart = trendCharts?.find((chart) => chart.teamId === team.id && chart.metricId === metricId),
          trendChartPlacement = metric["trendChartPlacement"] || "tooltip",
          showTrendChartInTooltip = trendChartPlacement === "tooltip" && !!trendChart,
          showTrendChartInText = trendChartPlacement === "text" && !!trendChart,
          visualProps = {
            doNotShowAxes: true,
            ...trendChart,
            error: trendChart?.data?.length ? "" : "No data",
            visualProps: { width: 200, height: 100, alwaysHideLegend: true, ...trendChart?.visualProps },
          };

        // Show no metric text if no template is defined or template has value placeholder but no value is defined.
        if (!template && !badgeTemplate && !showTrendChartInText) {
          metricCells.push(
            <td key={metricId} className={classNames.metricCell}>
              No metric
            </td>
          );
          return;
        } else if (!badgeTemplate && template?.indexOf("${") >= 0 && !metricValues && !metric.ignoreMissingData) {
          metricCells.push(
            <td key={metricId} className={classNames.metricCell}>
              Data unavailable
            </td>
          );
          return;
        }

        let badgeValue = badgeTemplate && getFormatContent({ item: metric, template: badgeTemplate }),
          metricText = template && getFormatContent({ item: metric, template }),
          tooltipText: string =
            (tooltipTemplate && getFormatContent({ item: metric, template: tooltipTemplate })) || "",
          showTooltipIcon = metric?.disableTooltipIcon !== true && !!tooltipText?.trim();

        // Always add last run timestamp to tooltip.
        if (metric.runOn) {
          tooltipText += getLastRunTooltipContent(metric, tooltipText, classNames);
        }

        let metricCellClassNames = [classNames.metricCell];
        metric.linkUrl && metricCellClassNames.push(classNames.clickable);

        let textClassNames = [classNames.text],
          showTooltip = tooltipText || showTrendChartInTooltip;

        badgeValue && textClassNames.push(classNames.textWithBadge);
        metric.showTextAsLink && textClassNames.push(classNames.textAsLink);

        let tooltipProps =
          showTooltip && getMetricTooltipProps(tooltipText, classNames, showTrendChartInTooltip, visualProps);

        metricCells.push(
          <td key={metricId} className={metricCellClassNames.join(" ")} onClick={() => onMetricClick(metric)}>
            <TooltipHost id={tooltipId} tooltipProps={tooltipProps} onMouseEnter={() => onMetricHover(metric)}>
              <div className={classNames.content} aria-describedby={tooltipId}>
                {badgeValue && (
                  <div className={classNames.visual}>
                    <CircleBadge
                      value={badgeValue}
                      statusColor={metric.statusColorOverride || metric.statusColor}
                      valueDisplayType={metric.valueDisplayType}
                      useWatermark={metric.useWatermark}
                      decimal={metric.decimal}
                    />
                  </div>
                )}
                {showTrendChartInText && <Visual {...visualProps} />}
                {metricText && (
                  <div className={textClassNames.join(" ")} dangerouslySetInnerHTML={{ __html: metricText }} />
                )}
                {showTooltipIcon && <Icon iconName="Info" className={classNames.tooltipIcon} tabIndex={0} />}
              </div>
            </TooltipHost>
          </td>
        );
      });
  });

  const metricName = startCase(metricType.name),
    metricDesc = metricType.description,
    metricTypeTooltipProps = metricDesc && {
      onRenderContent: () => (
        <div className={classNames.metricTooltip} dangerouslySetInnerHTML={{ __html: metricDesc }} />
      ),
    };

  return (
    <tr className={classNames.metricRow}>
      {(!metricChildType || metricTypeRowspan) && (
        <td className={classNames.metricType} rowSpan={metricTypeRowspan} colSpan={metricTypeColspan}>
          {metricDesc ? (
            <TooltipHost id={`metricType-tooltip-${metricType.id}`} tooltipProps={metricTypeTooltipProps}>
              <div>
                <span>{metricName}</span>
                <Icon iconName="Info" className={classNames.tooltipIcon} tabIndex={0} />
              </div>
            </TooltipHost>
          ) : (
            metricName
          )}
        </td>
      )}
      {metricChildType && <td className={classNames.metricType}>{metricChildType.name}</td>}
      {metricCells}
    </tr>
  );
};

export default HealthDashboardMetricsRow;

export const getMetricTooltipProps = (
  tooltipContent,
  classNames,
  showTrendChartInTooltip = false,
  visualProps = null
) => ({
  onRenderContent: () => (
    <>
      {showTrendChartInTooltip && <Visual {...visualProps} />}
      <div className={classNames.metricTooltip}>
        <div className={classNames.tooltipTemplate} dangerouslySetInnerHTML={{ __html: tooltipContent }} />
      </div>
    </>
  ),
});
