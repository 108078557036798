import React from "react";
import { connect } from "react-redux";
import { IComment } from "../common/interfaces";
import { IState } from "../../reducers/interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { ContactLink } from "../../shared/components/ContactLink";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { Icon } from "@fluentui/react/lib/Icon";
import { actionCreator } from "../duck";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import ConfirmModal from "../common/ConfirmModal";
import { errorType } from "../interfaces";
import { getUserId, isUserOwnerOrEditor } from "../common/helper";
import classNames from "./ControlComments.module.scss";
import { IControl } from "./interfaces";

export interface IControlCommentsStateProps {
  userId: string;
  comments: IComment[];
  control: IControl;
  saving: boolean;
  error: string;
}

export interface IControlCommentsDispatchProps {
  editControlComment: (comment: IComment) => void;
  deleteControlComment: (comment: IComment) => void;
}

export interface IControlCommentsState {
  changingComment: IComment;
  showDeleteModal: boolean;
}

export interface IControlCommentsProps extends IControlCommentsStateProps, IControlCommentsDispatchProps {}

export class ControlComments extends React.Component<IControlCommentsProps, IControlCommentsState> {
  state: IControlCommentsState = {
    changingComment: null,
    showDeleteModal: false,
  };

  render() {
    const { comments, userId, control, saving, error } = this.props;

    if (!comments || !comments.length) return null;

    const { changingComment, showDeleteModal } = this.state;

    const canEdit = isUserOwnerOrEditor(control, userId),
      changingCommentId = changingComment && changingComment.commentId;

    return (
      <Tile title="Comments" className={classNames.root} commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <div className={classNames.editableItems}>
          {comments.map((comment) => (
            <div key={"cm" + comment.commentId} className={classNames.item}>
              <div className={classNames.itemHeader}>
                {(canEdit || comment.modifiedBy === userId) && !saving && (
                  <div className={classNames.itemActions}>
                    <Icon
                      className={classNames.itemAction}
                      iconName="Edit"
                      title="Edit Comment"
                      onClick={() => this.onEditComment(comment)}
                    />
                    <Icon
                      className={classNames.itemAction}
                      iconName="Delete"
                      title="Delete Comment"
                      onClick={() => this.setState({ changingComment: comment, showDeleteModal: true })}
                    />
                  </div>
                )}
                <ContactLink alias={comment.modifiedBy} className={classNames.itemContact} />
                <span className={classNames.itemTime}>
                  {getFormatContent(comment.modifiedOn, ContentDisplayType.relativeTime)}
                </span>
                {saving && comment.commentId === changingCommentId && (
                  <Spinner
                    styles={{
                      root: classNames.itemSpinner,
                      circle: classNames.itemSpinnerCircle,
                      label: classNames.itemSpinnerLabel,
                    }}
                    size={SpinnerSize.xSmall}
                  />
                )}
              </div>
              <div className={classNames.itemText}>{comment.text}</div>
            </div>
          ))}
        </div>
        {showDeleteModal && (
          <ConfirmModal
            content="Are you sure you want to delete this comment?"
            saving={saving}
            error={error}
            onCommit={() => this.onDeleteComment()}
            onCancel={() => this.setState({ showDeleteModal: false })}
          />
        )}
      </Tile>
    );
  }

  onDeleteComment = () => {
    this.props.deleteControlComment(this.state.changingComment);
    this.setState({ showDeleteModal: false });
  };

  onEditComment = (comment) => {
    this.setState({ changingComment: comment });
    this.props.editControlComment(comment);
  };
}

const mapStateToProps = (state: IState) => {
  const control = state.modules.selected_control;
  return {
    userId: getUserId(state.app.login_user),
    comments: control?.comments,
    control: control,
    saving: state.modules.saving_control_comment,
    error: state.modules.errors[errorType.controlComment],
  };
};

const mapDispatchToProps = {
  editControlComment: actionCreator.editControlComment,
  deleteControlComment: actionCreator.deleteControlComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlComments);
