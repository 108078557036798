import { IField, FieldType, IDataContext } from "../../../shared/components/DataForm";
import { makeOption } from "../common/helper";
import { IDropdownOption } from "@fluentui/react";
import { ConfigItemType } from "../../../api/radarApi";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { IConfigItem } from "../../common/interfaces";

export interface IConfigDefs {
  id: string;
  name: string;
  configType: ConfigItemType;
  import: boolean;
  fields?: IField[];
}

export interface IConfigDefField {
  name: string;
  fieldType: FieldType | Function;
  fields?: IField[];
}

export const defaultControl: IConfigDefs = {
  id: "new",
  name: "",
  import: false,
  configType: ConfigItemType.ComplianceDocs,
};

const getConfigTypeOptions = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in ConfigItemType) {
    options.push(makeOption(ConfigItemType[value]));
  }
  return options;
};

const getFieldTypes = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in FieldType) {
    if (typeof FieldType[value] !== "string") {
      options.push(makeOption(value));
    }
  }
  return options;
};

let changeCallSemiphore: boolean = false;

export const importConfig = (configType: ConfigItemType): IField[] => {
  switch (configType) {
    // This is the place to add temporary code for importing a config from the getFields method
    // Just add the case for the config type, then add a return of the specific getFields (e.g. getComplianceFields)
    default:
      return [];
  }
};

export const convertFromConfigToField = (data: IField) => {
  const field = {
    ...data,
    fieldType: FieldType[data.fieldType as any] || FieldType[FieldType.text],
    fields: Array.isArray(data?.fields) ? data.fields.map(convertFromConfigToField) : undefined,
  };
  return field;
};

const importAndUpdateConfig = (
  configItem: IConfigItem,
  configDef: IConfigDefs,
  onConfigImport: (configItem: IConfigItem, field: string, value: any) => void
) => {
  const newConfigData = importConfig(configDef.configType);
  const convertedFields = newConfigData.map(convertFromConfigToField);
  onConfigImport && onConfigImport(configItem, "fields", convertedFields);
};

const getFields = (level?: number): IField => {
  const fields = {
    fieldName: "fields",
    fieldType: FieldType.items,
    itemDisplayView: ItemDisplayView.list,
    itemTypeName: "Fields",
    label: "Fields",
    collapsible: true,
    collapsed: false,
    fields: [
      {
        fieldName: "label",
        label: "Name",
      },
      {
        fieldName: "fieldName",
        label: "Field Name",
        hints: "The name of the field as it will be saved. Should be camelCased with no spaces",
      },
      {
        fieldName: "fieldType",
        label: "Field Type",
        fieldType: FieldType.enumeration,
        options: getFieldTypes(),
      },
    ],
  };
  const advanced: IField = {
    fieldName: "Advanced",
    label: "Advanced",
    fieldType: FieldType.container,
    collapsed: true,
    collapsible: true,
    fields: [
      {
        fieldName: "lastInRow",
        label: "Last In Row",
        hints: "If this is set to true, the next control will start on a new line",
        fieldType: FieldType.boolean,
      },
      {
        fieldName: "fillWidth",
        label: "Fill Width",
        hints: "If this is set to true, the control will fill up remaining space in row",
        fieldType: FieldType.boolean,
      },
      {
        fieldName: "collapsible",
        label: "Collapsible",
        hints: "Sets if a container is collasible in the UI",
        fieldType: FieldType.boolean,
      },
      {
        fieldName: "collapsed",
        label: "Collapsed",
        hints: "Sets if a container is collased by default",
        fieldType: FieldType.boolean,
      },
    ],
  };
  if (!level) {
    (advanced.fields as IField[]).push(getFields(1));
  }
  (fields.fields as IField[]).push(advanced);
  return fields;
};

export const getGeneralFields = (
  onConfigImport: (configItem: IConfigItem, fieldName: string, newValue: any) => void,
  configItem: IConfigDefs
): IField[] => {
  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "ID",
    },
    {
      fieldName: "name",
      label: "Name",
    },
    {
      fieldName: "configType",
      label: "Config Type",
      fieldType: FieldType.enumeration,
      options: getConfigTypeOptions(),
      valueWidth: "200px",
    },
    {
      fieldName: "import",
      label: "Import",
      fieldType: FieldType.boolean,
      lastInRow: true,
      fillWidth: false,
      valueWidth: "200px",
      onValueChange: (dataContext: IDataContext, newValue: boolean) => {
        if (!changeCallSemiphore && newValue && dataContext?.context?.configType && dataContext?.onFieldValueChange) {
          changeCallSemiphore = true;
          importAndUpdateConfig(configItem, dataContext.context, onConfigImport);
          changeCallSemiphore = false;
        }
      },
    },
    getFields(),
  ];
};
