import { checkForValue } from "../../utilities/miscHelper";
import { INode, ILink, Position } from "./interfaces";

export const defaultNodeWidth = 140;
export const defaultNodeHeight = 42;
export const nodeMetricHeight = 18;
export const linkPosAdjust = 0;
export const angleAdjust = 0.25; // This adjusts the angle to be more preferable for top-down flow.
export const angles = {
  n: Math.PI / 2,
  e: Math.PI,
  s: (Math.PI / 2) * 3,
  w: 0,
  r180: Math.PI,
  r360: Math.PI * 2,
  nw: Math.PI / 4 - angleAdjust,
  ne: (Math.PI / 4) * 3 + angleAdjust,
  se: (Math.PI / 4) * 5 - angleAdjust,
  sw: (Math.PI / 4) * 7 + angleAdjust,
};

// Translate measurements into CSS style settings.
export const getStyleByMeasurements = (x?: number, y?: number, w?: number, h?: number, style?: React.CSSProperties) => {
  return {
    top: y !== undefined ? y + "px" : "auto",
    left: x !== undefined ? x + "px" : "auto",
    width: w !== undefined ? w + "px" : "auto",
    height: h !== undefined ? h + "px" : style?.height || "auto",
  };
};

// Calculate the nodes' default width and height if not already defined.
// TODO: Calculate the position of nodes, if not already defined, with layout logic.
export const calcNodePlacements = (
  nodes: INode[],
  defaultWidth = defaultNodeWidth,
  defaultHeight = defaultNodeHeight
): INode[] =>
  nodes &&
  nodes.map((node, index) => ({
    ...node,
    id: node.id || "n" + index,
    width: node.width === undefined ? defaultWidth : node.width,
    height: node.height === undefined ? defaultHeight : node.height,
  }));

// Calculate the links' position and size if fromNodeId and toNodeId are defined.
export const calcLinkPlacements = (links: ILink[], nodes: INode[]): ILink[] =>
  links &&
  links.map((link) => {
    if (link.fromNodeId && link.toNodeId) {
      let fromNode = nodes && nodes.find((n) => link.fromNodeId === n.id),
        toNode = nodes && nodes.find((n) => link.toNodeId === n.id);

      // Both nodes are found.
      if (fromNode && toNode) {
        let linkCopy = { ...link },
          fromCenter = getNodeCenterPos(fromNode),
          toCenter = getNodeCenterPos(toNode),
          fromAngle = calcAngle(fromCenter, toCenter, link.fromPosition),
          toAngle = calcAngle(toCenter, fromCenter, link.toPosition),
          fromLinkPos = getLinkPosByAngle(fromAngle, fromNode, fromCenter),
          toLinkPos = getLinkPosByAngle(toAngle, toNode, toCenter);

        // Set the "from" point of the link.
        linkCopy.x1 = fromLinkPos.x;
        linkCopy.y1 = fromLinkPos.y;

        // Set the "to" point of the link.
        linkCopy.x2 = toLinkPos.x;
        linkCopy.y2 = toLinkPos.y;

        return linkCopy;
      }
    }

    return link;
  });

const getTopLeft = (objA: INode | ILink): IPosition => {
  const xValues: number[] = [];
  const yValues: number[] = [];

  if (checkForValue(objA.x)) xValues.push(objA.x);
  if (checkForValue(objA.x1)) xValues.push(objA.x1);
  if (checkForValue(objA.x2)) xValues.push(objA.x2);

  if (checkForValue(objA.y)) yValues.push(objA.y);
  if (checkForValue(objA.y1)) yValues.push(objA.y1);
  if (checkForValue(objA.y2)) yValues.push(objA.y2);

  return {
    x: Math.min(...xValues),
    y: Math.min(...yValues),
  };
};

const sortByPosition = (aPos: IPosition, bPos: IPosition) => {
  if (aPos.y !== bPos.y) {
    return aPos.y < bPos.y ? -1 : 1;
  } else if (aPos.x !== bPos.x) {
    return aPos.x < bPos.x ? -1 : 1;
  } else {
    return 0;
  }
};

export const setTabIndex = (nodes: INode[], links: ILink[]) => {
  let combinedList: ILink[] | INode[] = links
    ?.map((link, index) => ({ ...link, index }))
    .concat(nodes?.map((node, index) => ({ ...node, index })));
  if (!combinedList?.length) {
    return;
  }
  let sortedList = combinedList.sort((objA: INode | ILink, objB: INode | ILink) => {
    let aPos: IPosition = getTopLeft(objA);
    let bPos: IPosition = getTopLeft(objB);
    const retVal = sortByPosition(aPos, bPos);
    return retVal;
  });

  let tabIndex = 0;
  for (let sortedIndex = 0; sortedIndex < sortedList.length; sortedIndex++) {
    let obj = sortedList[sortedIndex];
    if (obj?.disabled === false) {
      if (obj.fromNodeId) {
        //ILink
        links[obj.index].tabIndex = tabIndex;
      } else {
        //INode
        nodes[obj.index].tabIndex = tabIndex;
      }
      tabIndex += 1;
    }
  }

  return sortedList;
};

export const calcAngle = (n1: INode, n2: INode, pos: Position): number => {
  if (pos) {
    if (pos === Position.top) return angles.n;
    if (pos === Position.bottom) return angles.s;
    if (pos === Position.left) return angles.w;
    if (pos === Position.right) return angles.e;
    if (pos === Position.topLeft) return angles.nw;
    if (pos === Position.topRight) return angles.ne;
    if (pos === Position.bottomLeft) return angles.sw;
    if (pos === Position.bottomRight) return angles.se;
  }

  let angle = Math.atan2(n1.y - n2.y, n1.x - n2.x);

  return angle < 0 ? 2 * Math.PI + angle : angle;
};

export interface IPosition {
  x: number;
  y: number;
}

// Assume the node already has valid placement numbers defined.
export const getNodeCenterPos = (node: INode): IPosition => ({
  x: node.width / 2 + node.x,
  y: node.height / 2 + node.y,
});

// Calculate the link position by the related angle (in radian).
export const getLinkPosByAngle = (angle: number, node: INode, center: IPosition): IPosition => {
  let pos: IPosition = { x: 0, y: 0 };

  if (angle >= angles.nw && angle < angles.ne) {
    // north side
    let adjust = getLinkPosAdjust(angle, angles.n, node.width);
    pos.x = center.x + adjust;
    pos.y = node.y - linkPosAdjust;
  } else if (angle >= angles.ne && angle < angles.se) {
    // east side
    let adjust = getLinkPosAdjust(angle, angles.e, node.height);
    pos.x = node.x + node.width + linkPosAdjust;
    pos.y = center.y + adjust;
  } else if (angle >= angles.se && angle < angles.sw) {
    // south side
    let adjust = getLinkPosAdjust(angle, angles.s, node.width);
    pos.x = center.x + adjust;
    pos.y = node.y + node.height + linkPosAdjust;
  } else {
    // west side
    let adjust = getLinkPosAdjust(angle, angles.w, node.height);
    pos.x = node.x - linkPosAdjust;
    pos.y = center.y + adjust;
  }

  return pos;
};

// Logic to adjust the link position so that the position is spreadout according to angle and
// multiple lines do not crowd the exact same position.
export const getLinkPosAdjust = (angle: number, targetAngle: number, length: number) => {
  // Adjust target angle if southwest region.
  targetAngle === angles.w && angle > angles.s && (targetAngle = angles.r360);

  // Calculate adjustment based on the angle and length of the node.
  let adjust = angle !== targetAngle ? (length / 2) * (Math.abs(angle - targetAngle) / angles.r180) : 0;

  // Calculate the adjustment direction.
  if (targetAngle === angles.n || targetAngle === angles.e) {
    angle < targetAngle && (adjust *= -1);
  } else {
    angle > targetAngle && (adjust *= -1);
  }
  return adjust;
};

export const getContentStyle = (nodes: INode[], links: ILink[], autoSizing: boolean): React.CSSProperties => {
  let maxWidth = 10,
    maxHeight = 10,
    maxMetricHeight = 0,
    hasMetricsAtBottom = false;

  if (autoSizing) {
    nodes?.forEach((node) => {
      maxMetricHeight = Math.max(
        maxMetricHeight,
        node.metrics?.length ? node.metrics.length * nodeMetricHeight : nodeMetricHeight
      );

      node.infoPane?.position === Position.bottom && (hasMetricsAtBottom = true);
    });
  }

  const metricHeightAdjust = hasMetricsAtBottom ? maxMetricHeight : nodeMetricHeight;

  nodes?.forEach((node) => {
    !isNaN(node.x) && !isNaN(node.width) && (maxWidth = Math.max(maxWidth, node.x + node.width));
    !isNaN(node.y) &&
      !isNaN(node.height) &&
      (maxHeight = Math.max(maxHeight, node.y + node.height + maxMetricHeight + metricHeightAdjust));
    node.y = node.y + maxMetricHeight;
  });

  links?.forEach((link) => {
    !isNaN(link.x1) && !isNaN(link.x2) && (maxWidth = Math.max(maxWidth, link.x1, link.x2));
    !isNaN(link.y1) && !isNaN(link.y2) && (maxHeight = Math.max(maxHeight, link.y1, link.y2));
    !isNaN(link.y1) && (link.y1 = link.y1 + maxMetricHeight);
    !isNaN(link.y2) && (link.y2 = link.y2 + maxMetricHeight);
  });

  return {
    width: maxWidth + "px",
    height: maxHeight + "px",
  };
};
