import React, { useMemo } from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import { parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { IState } from "../../../reducers/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

const defaultStreamSchedule = {};

export interface IMyStreamScheduleEditStateProps extends IConfigItemEditProps {}

export interface IMyStreamScheduleEditDispatchProps extends IConfigItemEditDispatchProps {}

export const MyStreamScheduleEdit = (props: IMyStreamScheduleEditStateProps & IMyStreamScheduleEditDispatchProps) => {
  const { configValue } = props;

  const configItem = useMemo(() => parseJsonSafe(configValue), [configValue]);

  return (
    <>
      <ConfigItemEdit
        {...props}
        configItem={configItem}
        configItemName="Stream Schedule"
        configItemType={ConfigItemType.StreamSchedules}
        appInsightsPageName="MyStreamScheduleEditPage"
        leftNavUrl={leftNavUrls.management.streamSchedule}
        defaultConfigItem={defaultStreamSchedule}
        pivotItems={[]}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
});

const mapDispatchToProps: IMyStreamScheduleEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadStreamScheduleConfigItem,
  updateConfigItem: actionCreator.updateStreamScheduleConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStreamScheduleEdit);
