import React from "react";
import IncidentsReport from "../incidents/IncidentsReport";

export interface IIncidentViewReportProps {
  incidentViewId: string;
}

export const IncidentViewReport = (props: IIncidentViewReportProps) => {
  const { incidentViewId } = props;

  return (
    <IncidentsReport
      displayAsReport={true}
      match={{ params: { viewId: incidentViewId }, isExact: null, url: null, path: null }}
      history={undefined}
      location={undefined}
    />
  );
};

export default IncidentViewReport;
