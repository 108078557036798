import React, { Component } from "react";
import { connect } from "react-redux";
import { IState } from "../../../reducers/interfaces";
import { IControlResult, IControl } from "../interfaces";
import OutlierControlSummary from "./OutlierControlSummary";
import GroupingTile from "../../../shared/components/Tile/GroupingTile";
import { controlType } from "../HealthReport.helper";
import { actionCreator } from "../../duck";
import { getFormatContent } from "../../../shared/utilities/miscHelper";
import { VisualTileList } from "../../../shared/components/Tile/VisualTileList";
import ControlResultSummary from "./ControlResultSummary";
import { errorType } from "../../interfaces";
import classNames from "./ControlSummary.module.scss";

export interface IControlSummaryStateProps {
  items: IControlResult[];
  loadingItems: boolean;
  loadingTiles: boolean;
  loadingHistory: boolean;
  control: IControl;
  selectedResultId: string;
  tilesError: string;
}

export interface IControlSummaryDispatchProps {
  loadControlTiles: (id: string, selectedValue: string) => void;
  loadControlResultHistory: (id: string, timeAgo: string) => void;
  loadControlResultItems: (control: IControlResult, controlResultId?: string) => void;
  selectControlResult: (controlResultId: string) => void;
}

export class ControlSummary extends Component<IControlSummaryStateProps & IControlSummaryDispatchProps> {
  render() {
    const {
      items,
      loadingItems,
      loadingTiles,
      loadingHistory,
      control,
      loadControlResultHistory,
      selectedResultId,
      tilesError,
    } = this.props;

    if (!control || loadingItems) return null;

    const { tiles, tileResults, dateFieldDisplayType, selectedDrilldownValue } = control;

    const titleForTile = selectedDrilldownValue
      ? `For ${getFormatContent(selectedDrilldownValue, dateFieldDisplayType)}`
      : "";

    const totalPassTitle = control.passedTitle,
      totalFailTitle = control.failedTitle || control.type === controlType.Outlier ? "Total Outliers" : undefined;

    const builtInSummaryTiles =
      control.type === controlType.Outlier && items && !!items.length ? (
        <OutlierControlSummary items={items} control={control} onValueSelect={this.onValueSelect} />
      ) : null;

    const hasCustomTiles = !!builtInSummaryTiles || (tiles && tileResults && !!tiles.length && !!tileResults.length);

    const finalTiles =
      tiles &&
      tiles.map((tile, index) => {
        var tileResult = tileResults && tileResults.length > index && tileResults[index];

        if (tileResult && typeof tileResult === "string") {
          tile["error"] = tileResult;
        }
        tile.headerLevel = 3;
        return tile;
      });

    return (
      <div className={classNames.root}>
        <ControlResultSummary
          items={items}
          control={control}
          totalPassTitle={totalPassTitle}
          totalFailTitle={totalFailTitle}
          loadingHistory={loadingHistory}
          selectedResultId={selectedResultId}
          onTimeSelect={loadControlResultHistory}
          onResultSelect={this.onResultSelect}
        />
        {tilesError && <pre className={classNames.error}>{tilesError}</pre>}
        {hasCustomTiles && !tilesError && (
          <GroupingTile title="Control Analytics" className={classNames.groupingTile} classNames={classNames}>
            {builtInSummaryTiles}
            <VisualTileList
              tiles={finalTiles}
              dataList={tileResults}
              tileTitleSuffix={titleForTile}
              loadingTiles={loadingTiles}
            />
          </GroupingTile>
        )}
      </div>
    );
  }

  onValueSelect = (selectedValue) => {
    const { control, loadControlTiles } = this.props;

    loadControlTiles(control.id, selectedValue && new Date(selectedValue).toISOString());
  };

  onResultSelect = (controlResultId) => {
    const { control, loadControlResultItems, selectControlResult } = this.props;

    loadControlResultItems(control, controlResultId);
    selectControlResult(controlResultId);
  };
}

const mapStateToProps = (state: IState) => ({
  control: state.modules.selected_control,
  items: state.modules.control_report && state.modules.control_report.items,
  selectedResultId: state.modules.selected_control_result_id,
  loadingItems: state.modules.loading_control_result_items || state.modules.loading_control_results,
  loadingTiles: state.modules.loading_control_tiles,
  loadingHistory: state.modules.loading_control_result_history,
  tilesError: state.modules.errors[errorType.controlTiles],
});

const mapDispatchToProps = {
  loadControlTiles: actionCreator.loadControlTiles,
  loadControlResultHistory: actionCreator.loadControlResultHistory,
  loadControlResultItems: actionCreator.loadControlResultItems,
  selectControlResult: actionCreator.selectControlResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlSummary);
