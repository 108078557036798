import { combineReducers } from "redux";
import appReducer from "../app/duck";
import appModulesReducer from "../appModules/duck";
import { IState } from "./interfaces";

const reducers = combineReducers<IState>({
  app: appReducer,
  modules: appModulesReducer,
});

export default reducers;
