import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import classNames from "./DevOpsView.module.scss";
import AppModuleHeader from "../common/AppModuleHeader";
import { getCommandBarProps, getWorkItemGroups, featureRequestsViewId, getDevOpsFilterItems } from "./helper";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { actionCreator as appActionCreator } from "../../app/duck";
import { IDevOpsItem, IDevOpsView, IFilterItem } from "../common/interfaces";
import {
  SelectionMode,
  CheckboxVisibility,
  IColumn,
  IGroupDividerProps,
  IDetailsListProps,
  DetailsHeader,
  DetailsList,
} from "@fluentui/react/lib/DetailsList";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { errorType } from "../interfaces";
import { generateColumns, renderColumn } from "../../shared/utilities/tableHelper";
import { leftNavUrls } from "../../app/LeftNav.helper";
import {
  IDetailsListItemsColumns,
  onDetailsListColumnClick,
  getSortedItemsColumns,
} from "../../shared/utilities/tableHelper";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import DevOpsViewFilters from "./DevOpsViewFilters";
import { Account } from "msal/lib-es6";
import ConfirmModal from "../common/ConfirmModal";
import { IconButton, ActionButton } from "@fluentui/react/lib/Button";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { getExcelDownloadCommandBarProps } from "../common/helper";

export interface IDevOpsViewProps {
  isFeatureRequests?: boolean;
}

export interface IDevOpsViewStateProps {
  isSmallScreen: boolean;
  user: Account;
  devOpsViewId: string;
  devOpsItems: IDevOpsItem[];
  devOpsView: IDevOpsView;
  loading: boolean;
  error: string;
  downloadingEmail: boolean;
  downloadEmailError: string;
  deletingItem: boolean;
  deleteItemError: string;
}

export interface IDevOpsViewDispatchProps {
  loadDevOpsView: (devOpsViewId: string, filters?: IFilterItem[], refresh?: boolean) => void;
  loadDevOpsViews: (devOpsViewId?: string, refresh?: boolean) => void;
  selectLeftNavLinkByUrl: (url: string) => void;
  downloadEmailFile: (devOpsId: string) => void;
  deleteDevOpsWorkItem: (devOpsViewId: string, devOpsId: string, includeDescendants: boolean) => void;
  downloadDataToExcel: (data: any[], worksheetName: string) => void;
}

export interface IDevOpsViewState extends IDetailsListItemsColumns {
  selectedFilters: IFilterItem[];
  showDeleteModal: boolean;
  deletingDevOpsId: string;
}

export interface IMatchParams {
  devOpsViewId?: string;
}

type DevOpsViewProps = IDevOpsViewProps &
  IDevOpsViewStateProps &
  IDevOpsViewDispatchProps &
  RouteComponentProps<IMatchParams>;

export class DevOpsView extends React.Component<DevOpsViewProps, IDevOpsViewState> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getNewState(props),
      selectedFilters: [],
      showDeleteModal: false,
      deletingDevOpsId: null,
    };
  }

  componentDidMount() {
    let {
      isFeatureRequests,
      devOpsViewId,
      devOpsView,
      loadDevOpsView,
      loadDevOpsViews,
      selectLeftNavLinkByUrl,
      match,
    } = this.props;
    let viewId;

    if (isFeatureRequests) {
      viewId = featureRequestsViewId;
    } else {
      viewId = devOpsView?.isFeatureRequests ? null : devOpsViewId;
    }

    if (match?.params?.devOpsViewId) {
      viewId = match.params.devOpsViewId;
    }

    if (devOpsView && viewId !== devOpsView.id) {
      devOpsView = null;
    }

    if (!devOpsView) {
      loadDevOpsViews(viewId, true);
    } else {
      var selectedFilters = getDevOpsFilterItems(devOpsView.filters);
      this.setState({ selectedFilters });
      loadDevOpsView(viewId, selectedFilters);
    }

    selectLeftNavLinkByUrl(
      (devOpsView && devOpsView.isFeatureRequests) || isFeatureRequests
        ? leftNavUrls.works.featureRequests
        : leftNavUrls.works.home
    );
  }

  componentDidUpdate(prevProps: DevOpsViewProps) {
    const { devOpsItems, devOpsView, loadDevOpsViews, selectLeftNavLinkByUrl, match } = this.props;

    if (match?.params?.devOpsViewId && prevProps?.match?.params?.devOpsViewId !== match?.params?.devOpsViewId) {
      // If the url has a different devOpsViewId than what is loaded in Redux, go with the url's devOpsViewId
      loadDevOpsViews(match?.params?.devOpsViewId);
    } else if (prevProps.devOpsItems !== devOpsItems) {
      var selectedFilters = this.state.selectedFilters;

      if ((!selectedFilters || !selectedFilters.length) && devOpsView) {
        selectedFilters = getDevOpsFilterItems(devOpsView.filters);
      }

      this.setState({
        ...this.getNewState(this.props),
        selectedFilters,
      });

      devOpsView &&
        devOpsView.isFeatureRequests !== undefined &&
        selectLeftNavLinkByUrl(
          devOpsView.isFeatureRequests ? leftNavUrls.works.featureRequests : leftNavUrls.works.home
        );
    }
  }

  render() {
    const { loading, error, downloadingEmail, downloadEmailError, deletingItem, deleteItemError } = this.props;
    const { items: devOpsItems, columns, selectedFilters, showDeleteModal, deletingDevOpsId } = this.state;

    let devOpsView = this.props.devOpsView || ({} as IDevOpsView);

    const name = devOpsView.name,
      description = devOpsView.description,
      includeDescendants = devOpsView.includeDescendants,
      isAllGroupsCollapsed = devOpsView.isAllGroupsCollapsed,
      hasItems = devOpsItems && devOpsItems.length > 0,
      groups = getWorkItemGroups(devOpsView, devOpsItems),
      collapseGroups = (includeDescendants && isAllGroupsCollapsed) || (groups && groups.length > 1);

    groups && groups.forEach((group) => collapseGroups && (group.isCollapsed = true));

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader commandBarProps={getCommandBarProps(this.props, this.state)} />
        {downloadingEmail && (
          <Spinner
            styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
            label="Downloading Email file..."
          />
        )}
        {!downloadingEmail && downloadEmailError && <div className={classNames.error}>{downloadEmailError}</div>}
        {deletingItem && (
          <Spinner
            styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
            label="Deleting work item and its descendants..."
          />
        )}
        {!deletingItem && deleteItemError && <div className={classNames.error}>{deleteItemError}</div>}
        {loading && (
          <Spinner
            styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
            label="Loading DevOps items..."
          />
        )}
        {!loading && !error && (
          <div className={classNames.content}>
            <DevOpsViewFilters selectedFilters={selectedFilters} onFilterChange={this.onFilterChange} />
            {devOpsView.isFeatureRequests && (
              <Tile
                title="Criteria To Include Feature Requests in Commerce Radar"
                contentCollapsed={true}
                commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}
              >
                <div className={classNames.criteriaTileContent}>
                  A new capability or system design, such as a new reporting or functionality, required to close the
                  books with completeness and accuracy for an existing, in-market process. A new capability required to
                  perform a control to provide assurance around financial risk for an existing, in-market process.
                  <p>
                    <b>Threshold for Commerce Radar</b>
                    <ul>
                      <li>
                        &gt;$1M actual or expected financial impact in the current and/or next subsequent quarter.
                      </li>
                    </ul>
                  </p>
                </div>
              </Tile>
            )}
            {hasItems && devOpsView && (
              <Tile
                title={name}
                subtitle={description}
                commandBarProps={this.getDevOpsListTileCommandBarProps()}
                commandBarItemKeys={[BuiltInCommandBarItemKey.showFullScreen]}
              >
                <DetailsList
                  items={devOpsItems}
                  columns={columns}
                  groups={groups}
                  selectionMode={SelectionMode.none}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  groupProps={{
                    onRenderHeader: this.onRenderHeader,
                    isAllGroupsCollapsed: collapseGroups,
                    showEmptyGroups: true,
                  }}
                  onRenderDetailsHeader={this.onRenderDetailsHeader}
                />
              </Tile>
            )}
          </div>
        )}
        {!loading && !hasItems && error && (
          <div className={classNames.error}>{error["message"] || error.toString()}</div>
        )}
        {!loading && !hasItems && !error && <div>No DevOps item is found.</div>}
        {showDeleteModal && (
          <ConfirmModal
            content={`Are you sure you want to delete work item #${deletingDevOpsId}?`}
            onCommit={this.onWorkItemDelete}
            onCancel={() => this.setState({ showDeleteModal: false, deletingDevOpsId: null })}
          />
        )}
      </div>
    );
  }

  onRenderDetailsHeader: IDetailsListProps["onRenderDetailsHeader"] = (props) => {
    return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={"Toggle selection"} />;
  };

  onRenderHeader = (props: IGroupDividerProps): JSX.Element => {
    const { downloadEmailFile, user } = this.props;
    const { key, name, count, level, isCollapsed, data } = props.group;
    const item = data && data.item;
    const columns = data && data.columns;
    const workItemType = item && (item["System.WorkItemType"] || item["WorkItemType"]);
    const assignedTo = item && item["System.AssignedTo"];
    const headerStyle =
        level > 0
          ? { fontWeight: 400 }
          : { fontSize: "16px", backgroundColor: "#eee", borderTop: "1px solid #ccc", lineHeight: "16px" },
      idStyle = {
        paddingLeft: level * 10 + "px",
      };

    return (
      <div className={classNames.header} style={headerStyle}>
        <ActionButton
          className={classNames.headerToggle}
          iconProps={{
            className: classNames.headerToggleIcon,
            iconName: count === 0 ? "Remove" : isCollapsed ? "ChevronRight" : "ChevronDown",
          }}
          onClick={() => props.onToggleCollapse(props.group)}
          ariaLabel="Collapse/Expand children rows"
        />
        {key && (
          <TooltipHost content={`Link to VSO item with id ${key}`}>
            <a
              className={classNames.headerId}
              style={idStyle}
              href={`https://microsoft.visualstudio.com/OSGS/_workitems/edit/${key}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {key}
            </a>
          </TooltipHost>
        )}
        <div className={classNames.headerTitle}>{`${name} (${count})`}</div>
        <div className={classNames.headerFields}>
          {columns &&
            !!columns.length &&
            columns.map((column, index) => (
              <div key={`work-item-${key}-${index}`} className={classNames.headerField}>
                {renderColumn(item, column)}
              </div>
            ))}
        </div>
        <div className={classNames.headerActions}>
          {workItemType === "Deliverable" && (
            <TooltipHost content="Download As Email">
              <IconButton
                className={classNames.headerAction}
                iconProps={{ iconName: "EditMail", title: "Download As Email" }}
                onClick={() => downloadEmailFile(key)}
                ariaLabel="Download As Email"
              />
            </TooltipHost>
          )}
          {assignedTo === user.name && (
            <IconButton
              className={classNames.headerAction}
              iconProps={{ iconName: "More", title: "More Actions" }}
              menuIconProps={{ iconName: "", style: { display: "none" } }}
              menuProps={{
                items: [
                  {
                    key: "deleteWorkItem",
                    text: "Delete item",
                    title: "Delete this item and its descendants",
                    ariaLabel: "Delete item",
                    onClick: () => this.setState({ showDeleteModal: true, deletingDevOpsId: key }),
                    iconProps: {
                      iconName: "Delete",
                    },
                  },
                ],
                calloutProps: {
                  calloutWidth: 120,
                },
              }}
              ariaLabel="More Actions"
            />
          )}
        </div>
      </div>
    );
  };

  onColumnClick = (ev: React.MouseEvent, column: IColumn) => {
    this.setState({
      ...onDetailsListColumnClick(column, this.state),
    });
  };

  getNewState = (props: IDevOpsViewStateProps): IDetailsListItemsColumns => {
    const { devOpsItems: items, devOpsView } = props;
    const columnDefs = devOpsView && devOpsView.columns;
    const columns = generateColumns(items, columnDefs);

    // Add a blank last column for header actions to line up with.
    columns.push({ key: "actions", name: "Actions", ariaLabel: "Actions", minWidth: 40 });

    return getSortedItemsColumns({ columns, items }, this.onColumnClick);
  };

  onFilterChange = (name: string, value: string) => {
    const { devOpsViewId, loadDevOpsView } = this.props;
    let { selectedFilters } = this.state,
      filterIndex = selectedFilters.findIndex((filter) => filter.name === name);

    if (filterIndex >= 0) {
      selectedFilters[filterIndex] = { name, value };
    } else {
      selectedFilters.push({ name, value });
    }
    this.setState({ selectedFilters });
    loadDevOpsView(devOpsViewId, selectedFilters, true);
  };

  onWorkItemDelete = () => {
    const { deleteDevOpsWorkItem, devOpsView } = this.props;
    const { deletingDevOpsId } = this.state;

    deleteDevOpsWorkItem(devOpsView.id, deletingDevOpsId, true);
    this.setState({ showDeleteModal: false });
  };

  getDevOpsListTileCommandBarProps = (): ICommandBarProps => {
    const { downloadDataToExcel, devOpsView } = this.props;
    const { items } = this.state;

    return getExcelDownloadCommandBarProps(downloadDataToExcel, items, devOpsView && devOpsView.name);
  };
}

const mapStateToProps = (state: IState): IDevOpsViewStateProps => ({
  isSmallScreen: state.app.is_small_screen,
  user: state.app.login_user,
  devOpsViewId: state.modules.selected_devops_view_id,
  loading: state.modules.loading_devops_items || state.modules.loading_devops_views,
  devOpsItems: state.modules.filtered_devops_items,
  devOpsView: state.modules.selected_devops_view,
  error: state.modules.errors[errorType.devOpsItems] || state.modules.errors[errorType.devOpsViews],
  downloadingEmail: state.modules.downloading_email_file,
  downloadEmailError: state.modules.errors[errorType.devOpsEmailDownload],
  deletingItem: state.modules.deleting_devops_workitem,
  deleteItemError: state.modules.errors[errorType.devOpsWorkItemDelete],
});

const mapDispatchToProps = {
  loadDevOpsView: actionCreator.loadDevOpsView,
  loadDevOpsViews: actionCreator.loadDevOpsViews,
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  downloadEmailFile: actionCreator.downloadDevOpsEmailFile,
  deleteDevOpsWorkItem: actionCreator.deleteDevOpsWorkItem,
  downloadDataToExcel: actionCreator.downloadDataToExcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevOpsView));
