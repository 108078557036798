import React from "react";
import TimelineChart, { ITimelineData } from "../../shared/components/TimelineChart";
import { SelectionMode, IColumn, CheckboxVisibility } from "@fluentui/react/lib/DetailsList";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { getTimelineGroups, statusColors, getDownloadData } from "./IncidentsReport.helper";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { IIncident, IIncidentView } from "./interfaces";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import IncidentFilters from "./IncidentFilters";
import IncidentListSummary from "./IncidentListSummary";
import { TimeSelector } from "../../shared/components/TimeSelector";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { History } from "react-router-dom/node_modules/history";
import { StyleClass, IStyleClass } from "../../shared/components/StyleClass";
import { TimeSelectorValue, calcDate } from "../../shared/components/TimeSelector";
import classNames from "./IncidentList.module.scss";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { getExcelDownloadCommandBarProps, addDetailsPageLinkColumn } from "../common/helper";
import TileDetailsList from "../../shared/components/Tile/TileDetailsList";

// TODO: This is just a guess number based on the current settings. Better get the actual state from TimelineChart component.
const chartItemsThresholdForScrolling = 26;
const executiveViewId = "0";

export interface IIncidentListProps {
  incidents: IIncident[];
  loading: boolean;
  incidentsError: string;
  history: History;
  loadIncidentDetails: Function;
  onTimeSelection: any;
  startTime?: TimeSelectorValue;
  isSmallScreen?: boolean;
  columns: IColumn[];
  view?: IIncidentView;
  loadingTiles?: boolean;
  searchText?: string;
  selectedIncidentViewId?: string;
  defaultColumns?: IColumn[];
  displayAsReport?: boolean;
  onSortOrderChange?: (key: string) => void;
  downloadIncidentsDataToExcel?: (data: any[], incidentViewId: string) => void;
}

export interface IIncidentListState {
  displayMoreChartItems: boolean;
}

export class IncidentList extends React.Component<IIncidentListProps, IIncidentListState> {
  onClickColumnOptions: any;
  onClickFilterBar: any;

  setOnClickColumnOptionsFunction = (onClick: () => void) => {
    this.onClickColumnOptions = onClick;
  };

  setOnClickFilterBarFunction = (onClick: () => void) => {
    this.onClickFilterBar = onClick;
  };

  constructor(props: IIncidentListProps) {
    super(props);

    this.state = {
      displayMoreChartItems: false,
    };
  }

  render() {
    const {
      loading,
      incidentsError,
      startTime,
      onTimeSelection,
      isSmallScreen,
      incidents,
      view,
      history,
      loadingTiles,
      columns,
      searchText,
      selectedIncidentViewId,
      displayAsReport,
    } = this.props;

    const { displayMoreChartItems } = this.state;

    const timelineGroups = getTimelineGroups(incidents);

    const styleClass: IStyleClass = {
      headerImpactStarted: {
        color: statusColors.active,
      },
      headerDiscovered: {
        color: statusColors.mitigating,
      },
      headerMitigated: {
        color: statusColors.mitigated,
      },
      headerResolved: {
        color: statusColors.resolved,
      },
    };

    const startDate = calcDate(startTime),
      viewName = view?.name || "";

    const finalColumns =
      view?.hideDetailsPage || displayAsReport ? columns : addDetailsPageLinkColumn(columns, this.onSelectIncident);

    const showAllViewSearchText = !searchText && !selectedIncidentViewId;

    const incidentCommandBarItems = this.getIncidentListTileCommandBarProps();

    return (
      <div className={classNames.root}>
        <IncidentFilters history={history} displayAsReport={displayAsReport} />
        {/* Show spinner if it's loading the incidents list */}
        {loading && (
          <Spinner
            styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
            size={SpinnerSize.large}
            label="Loading incidents..."
            aria-label="Loading incidents"
            role="alert"
          />
        )}
        {/* Show message if no incident is returned by API */}
        {!loading &&
          (!incidents || !incidents.length) &&
          (showAllViewSearchText ? (
            <div className={classNames.message}>Type in an incident ID in the search box.</div>
          ) : incidentsError ? (
            <pre className={classNames.error}>{incidentsError}</pre>
          ) : (
            <div className={classNames.message}>No incidents found.</div>
          ))}
        {/* Show incidents if they are returned by API */}
        {!loading && incidents && !!incidents.length && (
          <div>
            <StyleClass styleClass={styleClass} />
            {view && view.id === executiveViewId && (
              <Tile
                title="Definition of Financial Livesite Incident"
                contentCollapsed={true}
                commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}
              >
                <div className={classNames.definitionTileContent}>
                  When Commerce services + configuration results in a financial impact – understated or overstated
                  financials or other key performance indicators used in financial reporting or analysis (e.g. monthly
                  active users for Xbox/Office).
                  <p>
                    <b>Thresholds for Executive View</b>
                    <ul>
                      <li>&gt;= $5M OR</li>
                      <li>
                        Apply Judgment: Financial impact is less than $5M, but should be reported out to executives due
                        to sensitivity, reoccurrence, high impact to non-financial disclosures, potential future impact,
                        or potential for impact above $5M.
                      </li>
                    </ul>
                  </p>
                </div>
              </Tile>
            )}
            <IncidentListSummary incidents={incidents} view={view} loadingTiles={loadingTiles} />
            <Tile
              className={classNames.chartTile}
              classNames={classNames}
              title={`${viewName} Incident Timelines`}
              applyContentStyle={true}
              contentCollapsed={!!view?.timelinesCollapseByDefault}
              menuContent={
                <div className={classNames.chartMenuContent}>
                  {incidents.length > chartItemsThresholdForScrolling && (
                    <Toggle
                      defaultChecked={displayMoreChartItems}
                      className={classNames.chartDisplayToggle}
                      inlineLabel={true}
                      label="Display All"
                      onText="on"
                      offText="off"
                      onClick={() => this.setState({ displayMoreChartItems: !displayMoreChartItems })}
                    />
                  )}
                  <TimeSelector selectedValue={startTime} onValueSelect={onTimeSelection} />
                </div>
              }
              commandBarProps={this.getTimelinesTileCommandBarProps()}
              commandBarItemKeys={[BuiltInCommandBarItemKey.showFullScreen, BuiltInCommandBarItemKey.toggleContent]}
            >
              <TimelineChart
                groups={timelineGroups}
                leftYAxisPadding={20}
                rightYAxisPadding={isSmallScreen ? 200 : 400}
                onDataClick={this.onTimelineDataClick}
                startTime={startDate}
                maxChartHeight={displayMoreChartItems ? 10000 : undefined}
              />
            </Tile>
            <TileDetailsList
              title={`${viewName} Incident List`}
              commandBarProps={incidentCommandBarItems}
              nameForSave="IncidentList"
              classNameForDetailsList={classNames.list}
              items={incidents}
              columns={finalColumns}
              selectionMode={SelectionMode.none}
              checkboxVisibility={CheckboxVisibility.hidden}
              noItemText="No incidents found"
            />
          </div>
        )}
      </div>
    );
  }

  onTimelineDataClick = (data: ITimelineData) => {
    const { incidents } = this.props;

    let incident = incidents.find((i) => i.incidentId === data.id);

    incident && this.onSelectIncident(incident);
  };

  onSelectIncident = (incident: IIncident) => {
    if (incident) {
      const { loadIncidentDetails, history, view } = this.props;
      loadIncidentDetails(incident);

      const incidentUrl = view?.id
        ? `${leftNavUrls.all.financialIncidents}/${view?.id}/${incident.incidentId}`
        : `${leftNavUrls.all.financialIncidents}/incident/${incident.incidentId}`;
      history?.push(incidentUrl);
    }
  };

  getTimelinesTileCommandBarProps = (): any => {
    const { columns, onSortOrderChange } = this.props;
    const sortMenuItems =
      columns &&
      columns.map((column) => ({
        key: column.key,
        name: column.name,
        ariaLabel: column.name,
        onClick: (ev: any) => onSortOrderChange(column.key),
      }));

    return {
      items: [
        {
          key: "sort",
          title: "Sort By",
          ariaLabel: "Sort By",
          iconProps: {
            iconName: "SortLines",
          },
          iconOnly: true,
          subMenuProps: {
            items: [{ key: "sortBy", name: "Sort By", itemType: DropdownMenuItemType.Header }, ...sortMenuItems],
          },
        },
      ],
    };
  };

  getIncidentListTileCommandBarProps = (): ICommandBarProps => {
    const { downloadIncidentsDataToExcel, view, incidents, columns } = this.props;

    let items = getDownloadData(incidents, columns);

    return getExcelDownloadCommandBarProps(downloadIncidentsDataToExcel, items, view && view.id);
  };
}

export default IncidentList;
