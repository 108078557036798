import React from "react";
import classNames from "./TimeSelector.module.scss";

export enum TimeSelectorValue {
  sevenDays = "7d",
  fourteenDays = "14d",
  thirtyDays = "30d",
  sixtyDays = "60d",
  ninetyDays = "90d",
  oneMonth = "1m",
  twoMonths = "2m",
  threeMonths = "3m",
  sixMonths = "6m",
  nineMonths = "9m",
  oneYear = "1y",
  twoYears = "2y",
  threeYears = "3y",
  fiveYears = "5y",
  max = "max",
}

export interface ITimeSelectorProps {
  label?: string;
  availableValues?: TimeSelectorValue[];
  selectedValue?: TimeSelectorValue | string;
  returnDateOnSelection?: boolean;
  labelPosition?: "top" | "left";
  onValueSelect?: (selectedValue: TimeSelectorValue | Date) => void;
}

export class TimeSelector extends React.Component<ITimeSelectorProps> {
  static defaultProps: ITimeSelectorProps = {
    label: "Time",
    availableValues: [
      TimeSelectorValue.max,
      TimeSelectorValue.oneYear,
      TimeSelectorValue.sixMonths,
      TimeSelectorValue.threeMonths,
      TimeSelectorValue.oneMonth,
    ],
    selectedValue: TimeSelectorValue.sixMonths,
    returnDateOnSelection: false,
  };

  render() {
    const { label, labelPosition = "left", availableValues, selectedValue } = this.props;

    return (
      <div className={classNames.root}>
        {label &&
          (labelPosition === "top" ? (
            <div className={classNames.labelTop}>{label}</div>
          ) : (
            <span className={classNames.label}>{label}: </span>
          ))}
        {availableValues &&
          availableValues.map((value) => {
            let isSelected = value === selectedValue,
              valueClassName = isSelected ? classNames.selectedValue : classNames.value;

            return (
              <span
                key={"ts" + value}
                className={valueClassName}
                tabIndex={0}
                role="button"
                aria-pressed={isSelected}
                aria-label={`time selection, ${value}`}
                onClick={() => this.onValueSelect(value)}
                onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && this.onValueSelect(value)}
              >
                {value}
              </span>
            );
          })}
      </div>
    );
  }

  onValueSelect = (value: TimeSelectorValue) => {
    const { onValueSelect, returnDateOnSelection } = this.props;

    if (onValueSelect) {
      if (returnDateOnSelection) {
        onValueSelect(calcDate(value));
      } else {
        onValueSelect(value);
      }
    }
  };
}

export default TimeSelector;

export const calcDate = (value: TimeSelectorValue): Date => {
  const date = new Date();

  switch (value) {
    case TimeSelectorValue.sevenDays:
      setDate(date, 7);
      break;
    case TimeSelectorValue.fourteenDays:
      setDate(date, 14);
      break;
    case TimeSelectorValue.thirtyDays:
      setDate(date, 30);
      break;
    case TimeSelectorValue.sixtyDays:
      setDate(date, 60);
      break;
    case TimeSelectorValue.ninetyDays:
      setDate(date, 90);
      break;
    case TimeSelectorValue.oneMonth:
      setMonth(date, 1);
      break;
    case TimeSelectorValue.twoMonths:
      setMonth(date, 2);
      break;
    case TimeSelectorValue.threeMonths:
      setMonth(date, 3);
      break;
    case TimeSelectorValue.sixMonths:
      setMonth(date, 6);
      break;
    case TimeSelectorValue.nineMonths:
      setMonth(date, 9);
      break;
    case TimeSelectorValue.oneYear:
      setYear(date, 1);
      break;
    case TimeSelectorValue.twoYears:
      setYear(date, 2);
      break;
    case TimeSelectorValue.threeYears:
      setYear(date, 3);
      break;
    case TimeSelectorValue.fiveYears:
      setYear(date, 5);
      break;
  }

  return value !== TimeSelectorValue.max ? date : null;
};

export const setDate = (date: Date, lastDateCount: number) => {
  date.setDate(date.getDate() - lastDateCount);
};

export const setMonth = (date: Date, lastMonthCount: number) => {
  date.setMonth(date.getMonth() - lastMonthCount);
  date.setDate(1);
};

export const setYear = (date: Date, lastYearCount: number) => {
  date.setFullYear(date.getFullYear() - lastYearCount);
  date.setDate(1);
};

export const convertToTimeSpanInDays = (timeValue: TimeSelectorValue): string => {
  let valueText = timeValue.toString(),
    number = Number(valueText.substring(0, valueText.length - 1)),
    unitText = valueText.substring(valueText.length - 1);

  if (unitText === "m") {
    return number * 30 + "d"; // Approximation
  } else if (unitText === "y") {
    return number * 365 + "d";
  } else if (timeValue === TimeSelectorValue.max) {
    return null;
  }
  return timeValue.toString();
};
