import React from "react";
import Diagram, { IDiagram } from "../Diagram";

export interface IDiagramVisualProps {
  ariaLabel: string;
  diagram: IDiagram;
  data?: any[];
  idFieldName?: string;
  metricNameFieldName?: string;
  metricValueFieldName?: string;
  diagramNodesQuery?: string;
  diagramNodesQueryData?: any[];
  diagramLinksQuery?: string;
  diagramLinksQueryData?: any[];
}

export class DiagramVisual extends React.Component<IDiagramVisualProps> {
  render() {
    const {
      ariaLabel,
      data,
      idFieldName = "id",
      metricNameFieldName = "metricName",
      metricValueFieldName = "metricValue",
      diagramNodesQueryData,
      diagramLinksQueryData,
    } = this.props;

    let diagram = this.props.diagram || {};

    if (diagramNodesQueryData?.length) {
      diagram = { ...diagram, nodes: [...(diagram.nodes || []), ...diagramNodesQueryData] };
    }

    if (diagramLinksQueryData?.length) {
      diagram = { ...diagram, links: [...(diagram.links || []), ...diagramLinksQueryData] };
    }

    const mergedDiagram = mergeMetricDataIntoDiagram(
      diagram,
      data,
      idFieldName,
      metricNameFieldName,
      metricValueFieldName
    );

    return <Diagram ariaLabel={ariaLabel} diagram={mergedDiagram} />;
  }
}

export default DiagramVisual;

const mergeMetricDataIntoDiagram = (
  diagram: IDiagram,
  data: any[],
  idFieldName: string,
  metricNameFieldName: string,
  metricValueFieldName: string
): IDiagram => {
  if (!data || !data.length) return diagram;

  var mergedDiagram = { ...diagram };

  if (mergedDiagram.nodes) {
    mergedDiagram.nodes.forEach((node) => {
      node.metrics = [];
      data.forEach((item) => {
        if (item[idFieldName] === node.id) {
          node.metrics.push({ ...item, name: item[metricNameFieldName], value: item[metricValueFieldName] });
        }
      });
    });
  }

  return mergedDiagram;
};
