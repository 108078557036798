import { INavLink } from "@fluentui/react/lib/Nav";
import { getLeftNavLinkGroups } from "./LeftNav.helper";
import { stagingQueryStringKey } from "../api/apiBase";

export const leftNavCollapsedStateName = "leftNavCollapsed";

export const smallDeviceWidth = 840;
export const mediumDeviceWidth = 1024;

export const getSmallScreenStatus = (): boolean => window.innerWidth <= smallDeviceWidth;
export const getMediumScreenStatus = (): boolean => window.innerWidth <= mediumDeviceWidth;

enum leftNavLinkSearchKey {
  key,
  url,
}

export const isStaging = (): boolean => {
  if (window.location.search.indexOf(stagingQueryStringKey) >= 0) {
    return true;
  }

  let queryIndex = window.location.hash.indexOf("?");

  if (queryIndex >= 0 && window.location.hash.indexOf(stagingQueryStringKey, queryIndex) >= 0) {
    return true;
  }

  return localStorage[stagingQueryStringKey] === "true" || localStorage[stagingQueryStringKey] === true;
};

export const getLeftNavLink = (value: string, searchKey: leftNavLinkSearchKey, navGroups = null): INavLink => {
  const leftNavLinkGroups = navGroups || getLeftNavLinkGroups(isStaging());

  if (!leftNavLinkGroups || !leftNavLinkGroups.length || !value) {
    return null;
  }

  for (let i = 0; i < leftNavLinkGroups.length; i++) {
    let group = leftNavLinkGroups[i],
      navLink = group && group.links && group.links.length && findNavLink(group.links, value, searchKey);

    if (navLink) {
      return navLink;
    }
  }

  return null;
};

export const getDefaultLeftNavLink = (): INavLink => {
  let urlHash = window.location.hash;

  if (!urlHash || urlHash.length === 1) {
    return null; // Default to home page.
  }

  return getLeftNavLink(urlHash.substr(1), leftNavLinkSearchKey.url);
};

export const getLeftNavLinkByKey = (key: string, navGroups = null): INavLink =>
  getLeftNavLink(key, leftNavLinkSearchKey.key, navGroups);

export const getLeftNavLinkByUrl = (url: string, navGroups = null): INavLink =>
  getLeftNavLink(url, leftNavLinkSearchKey.url, navGroups);

export const getLeftNavLinkParents = (navLink: INavLink, isStaging: boolean = true): string[] => {
  const parents: string[] = [],
    leftNavLinkGroups = getLeftNavLinkGroups(isStaging);

  if (!leftNavLinkGroups || !leftNavLinkGroups.length || !navLink) {
    return parents;
  }

  for (let i = 0; i < leftNavLinkGroups.length; i++) {
    let group = leftNavLinkGroups[i];
    parents.push(group.name);

    let found = group && group.links && group.links.length && findNavLinkParents(group.links, navLink.key, parents);

    if (found) {
      break;
    }

    parents.pop();
  }

  return parents;
};

const findNavLinkParents = (links: INavLink[], targetKey: string, parents: string[]): boolean => {
  for (let j = 0; j < links.length; j++) {
    let link = links[j];

    if (link && link.key === targetKey) {
      return true;
    }

    if (link && link.links && link.links.length) {
      parents.push(link.name);

      let found = findNavLinkParents(link.links, targetKey, parents);

      if (found) {
        return true;
      }

      parents.pop();
    }
  }

  return false;
};

const findNavLink = (links: INavLink[], value: string, searchKey: leftNavLinkSearchKey): INavLink => {
  for (let j = 0; j < links.length; j++) {
    let link = links[j];

    if (!link) {
      continue;
    }

    if (searchKey === leftNavLinkSearchKey.url && link.url && link.url.toLowerCase() === value.toLowerCase()) {
      return link;
    } else if (searchKey === leftNavLinkSearchKey.key && link.key && link.key.toLowerCase() === value.toLowerCase()) {
      return link;
    }

    let navLink = link.links && link.links.length && findNavLink(link.links, value, searchKey);

    if (navLink) {
      return navLink;
    }
  }

  return null;
};
