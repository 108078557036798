import JsonSchema from "jsonschema";

const sourceEntitySchema = {
  id: "/sourceEntity",
  type: "object",
  properties: {
    type: { type: "string" },
    idAttribute: { type: "string" },
    versionAttribute: { type: "string" },
    valueAttribute: { type: "string" },
  },
};

const filterSetSchema = {
  id: "/filterSetSchema",
  type: "object",
  properties: {},
};

const filtersSchema = {
  id: "/filters",
  type: "object",
  properties: {
    attribute: { type: "string" },
    filterSet: { type: "string" },
    value: {
      oneOf: [{ type: "string" }, { type: "number" }, { type: "boolean" }],
    },
  },
};

const conditionsSchema = {
  id: "/conditions",
  type: "conditions",
  properties: {
    filters: {
      type: "array",
      items: { $ref: "/filters" },
    },
    value: {
      oneOf: [{ type: "string" }, { type: "number" }, { type: "boolean" }],
    },
  },
};

const attributesSchema = {
  id: "/attributes",
  type: "object",
  properties: {
    name: { type: "string" },
    path: { type: "string" },
    // Validate against specific types of operators
    operator: { type: "string" },
    conditions: {
      type: "array",
      items: { $ref: "/conditions" },
    },
    parentPath: { type: "string" },
    pathRegex: { type: "string" },
    pathRegexOperation: { type: "string" },
    pathRegexOperationValue: { type: "string" },
    required: { type: "boolean" },
    sourceEntity: { $ref: "/sourceEntity" },
    type: { type: "string" },
    value: {
      oneOf: [{ type: "string" }, { type: "number" }, { type: "boolean" }],
    },
  },
  required: ["name"],
};

const attributesMap = {
  id: "/attributeMapping",
  type: "object",
  properties: {
    schemaVersion: { type: "string" },
    attributes: {
      type: "array",
      items: { $ref: "/attributes" },
    },
  },
  required: ["attributes"],
};

var validationSchema = {
  id: "/validation",
  type: "object",
  properties: {
    attribute: { type: "string" },
    sourceAttribute: { type: "string" },
    targetAttribute: { type: "string" },
  },
};

var validationTestSchema = {
  id: "/validationTest",
  type: "object",
  properties: {
    validationType: { type: "string" },
    sourceEntityType: { type: "string" },
    slaTimestampAttribute: { type: "string" },
    validations: {
      type: "array",
      items: { $ref: "/validation" },
    },
  },
  required: ["validationType", "sourceEntityType"],
};

export const configSchema = {
  id: "/serviceContentsConfig",
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    owner: { type: "string" },
    editors: {
      type: "array",
      items: { type: "string" },
    },
    attributeMappings: {
      type: "array",
      items: { $ref: "/attributeMapping" },
    },
    validationTests: {
      type: "array",
      items: { $ref: "/validationTest" },
    },
    description: { type: "string" },
    isProcessValidation: { type: "boolean" },
    validationCompleteInDays: { type: "number" },
    _modifiedBy: { type: "string" },
    _rid: { type: "string" },
    _self: { type: "string" },
    _etag: { type: "string" },
    _attachments: { type: "string" },
    _ts: { type: "number" },
  },
  required: ["id", "name", "owner"],
};

export const ConfigSchemaValidator = new JsonSchema.Validator();
ConfigSchemaValidator.addSchema(filtersSchema);
ConfigSchemaValidator.addSchema(filterSetSchema);
ConfigSchemaValidator.addSchema(conditionsSchema);
ConfigSchemaValidator.addSchema(attributesSchema);
ConfigSchemaValidator.addSchema(attributesMap);
ConfigSchemaValidator.addSchema(validationSchema);
ConfigSchemaValidator.addSchema(validationTestSchema);
ConfigSchemaValidator.addSchema(sourceEntitySchema);

export const getErrorsFromSchema = (schemaErrors: JsonSchema.ValidationError[]) => {
  let message = "";
  schemaErrors.forEach((schemaError) => {
    var schema: { type: string; items?: { type: string } } = schemaError.schema as any;
    var objectInError = schemaError.property.replace("instance.", "");
    var subType = schema?.type === "array" && schema?.items?.type ? schema.items.type : "";
    var errorMessage = subType ? schemaError.message.replace("array", `${subType}[]`) : schemaError.message;
    message = `${message}"${objectInError}" ${errorMessage}</br>`;
  });
  return message;
};
