import { IField, FieldType } from "../../../shared/components/DataForm";
import { IEntityView } from "../../common/interfaces";
import { getDiagramNodeFields, getDiagramLinkFields, getDiagramAdvancedFields } from "../common/helper";

export const defaultEntityView: IEntityView = {
  id: "new",
};

export const getFields = (entityView: IEntityView): IField[] => {
  if (!entityView) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      fieldType: FieldType.displayOnly,
      value: entityView.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "name",
      label: "Name",
      value: entityView.name,
      required: true,
      width: "300px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: entityView.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: entityView.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: entityView.description,
      lastInRow: true,
    },
    getDiagramAdvancedFields(entityView),
    getDiagramNodeFields(entityView.nodes),
    getDiagramLinkFields(entityView.links),
  ];
};
