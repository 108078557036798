import React, { useCallback, useEffect, useState } from "react";
import { Spinner, TextField } from "@fluentui/react";
import classNames from "./TestDataSourceModal.module.scss";
import { testDataSource } from "../../../api/radarApi";
import ConfirmModal from "../../common/ConfirmModal";

export interface ITestDataSourceModalProps {
  hidden?: boolean;
  dataSource?: object;
  onDismiss?: () => void;
}

export const TestDataSourceModal = (props: ITestDataSourceModalProps) => {
  const { hidden = true, dataSource, onDismiss } = props;
  const [testDataSourceResult, setTestDataSourceResult] = useState<any>();
  const [loadingTestData, setLoadingTestData] = useState<boolean>(false);
  const [query, setQuery] = useState<string>();

  useEffect(() => {
    !hidden && setTestDataSourceResult(undefined);
  }, [hidden]);

  useEffect(() => {
    if (dataSource && dataSource["query"]) {
      setQuery(dataSource["query"]);
    }
  }, [dataSource]);

  const onTestDataSourceStart = useCallback(() => {
    if (dataSource) {
      setLoadingTestData(true);
      setTestDataSourceResult(undefined);

      let newDataSource = {
        ...dataSource,
        query,
      };

      testDataSource(newDataSource)
        .then((result) => setTestDataSourceResult(result || []))
        .catch((error) => setTestDataSourceResult(error))
        .finally(() => setLoadingTestData(false));
    }
  }, [dataSource, query]);

  const onCancel = useCallback(() => {
    onDismiss && onDismiss();
  }, [onDismiss]);

  return (
    <>
      {!hidden && (
        <ConfirmModal
          className={classNames.root}
          onCommit={onTestDataSourceStart}
          onCancel={onCancel}
          title="Test Data Source"
          commitButtonText="Start"
          disableCommit={!dataSource || loadingTestData}
          modalRootClassName={classNames.modalRoot}
        >
          {!dataSource ? (
            <div className={classNames.error}>No data source is defined.</div>
          ) : (
            <>
              <h1>Query:</h1>
              <TextField
                multiline
                rows={Math.min(query?.split("\n")?.length ?? 1, 10)}
                className={classNames.query}
                value={query}
                spellCheck={false}
                onChange={(ev, newValue) => setQuery(newValue)}
              />
              {loadingTestData && (
                <div className={classNames.spinnerContainer}>
                  <Spinner className={classNames.spinner} label="Loading test data..." />
                </div>
              )}
              {!loadingTestData && testDataSourceResult && !testDataSourceResult["error"] && (
                <>
                  <h1>Query Result:</h1>
                  <pre className={classNames.testResult}>
                    {testDataSourceResult["result"]?.length
                      ? JSON.stringify(testDataSourceResult["result"], null, 2)
                      : "No query result is returned."}
                  </pre>
                </>
              )}
              {!loadingTestData && testDataSourceResult && testDataSourceResult["error"] && (
                <>
                  <h1>Error:</h1>
                  <div className={classNames.testResult}>{JSON.stringify(testDataSourceResult["error"], null, 2)}</div>
                </>
              )}
            </>
          )}
        </ConfirmModal>
      )}
    </>
  );
};

export default TestDataSourceModal;
