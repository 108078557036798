import React from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IVisualProps } from "./Visual";
import { VisualType } from "./Visual.helper";

export class Submit extends React.Component<IVisualProps> {
  render() {
    const defaultStyle = {
      marginTop: "24px",
    };
    const { label = "Submit", style = defaultStyle, filterName = VisualType.submit, onValueSelect } = this.props;

    return <PrimaryButton style={style} onClick={() => onValueSelect(filterName, filterName)} text={label} />;
  }
}

export default Submit;
