import { getItemField } from "../../utilities/miscHelper";

const maxValueFontSize = 40;
const valueFontSizeFactor = 1.5;

export enum VisualType {
  actionButton = "actionButton",
  area = "area",
  area2 = "area2",
  bar = "bar",
  bar2 = "bar2",
  candlestick = "candlestick",
  column = "column",
  column2 = "column2",
  comboBox = "comboBox",
  datePicker = "datePicker",
  diagram = "diagram",
  dropdown = "dropdown",
  kpi = "kpi",
  label = "label",
  line = "line",
  line2 = "line2",
  pie = "pie",
  submit = "submit",
  table = "table",
  template = "template",
  textbox = "textbox",
  timeAgoSelector = "timeAgoSelector",
}

export const calcFontSize = (width: number, textLength: number): string =>
  Math.min(maxValueFontSize, Math.round(width / textLength) * valueFontSizeFactor) + "px";

export const isInputField = (visualType: VisualType) =>
  visualType === VisualType.actionButton ||
  visualType === VisualType.datePicker ||
  visualType === VisualType.comboBox ||
  visualType === VisualType.dropdown ||
  visualType === VisualType.label ||
  visualType === VisualType.submit ||
  visualType === VisualType.textbox ||
  visualType === VisualType.timeAgoSelector;

export const allowNoData = (visualType: VisualType) => isInputField(visualType) || visualType === VisualType.diagram;

export const getGroupNames = (data: any[], groupFieldNames: string[], seriesSortOrder?): string[] => {
  let groupNames = [],
    hasGroupFields = true;

  groupFieldNames.forEach((groupFieldName) => {
    if (!getItemField(data[0], groupFieldName)) {
      hasGroupFields = false;
    }
  });

  if (hasGroupFields) {
    data.forEach((item) => {
      let groupName = "";

      groupFieldNames.forEach((groupFieldName, index) => {
        index > 0 && (groupName += ",");
        groupName += getItemField(item, groupFieldName);
      });

      if (groupNames.indexOf(groupName) < 0) {
        groupNames.push(groupName);
      }
    });

    if (seriesSortOrder) {
      groupNames = groupNames.sort();

      if (seriesSortOrder === "desc") {
        groupNames = groupNames.reverse();
      }
    }
  }

  return groupNames;
};
