import React from "react";
import { IControlResult, IControlReport, IControlView, IControlViewList, IControl } from "./interfaces";
import { INode, ILink } from "../../shared/components/Diagram";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { Icon } from "@fluentui/react/lib/Icon";
import { TooltipHost, TooltipOverflowMode } from "@fluentui/react/lib/Tooltip";
import { ContactLink } from "../../shared/components/ContactLink";
import { getContactMessageSubject, controlType } from "./HealthReport.helper";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { getImpactScore, getImpactScoreColor, renderImpactScoreByControl } from "../common/helper";
import classNames from "./ControlItemList.module.scss";
import moment from "moment";

const controlOverdueText = "This control is overdue for control result run.";

export const getControlListColumns = (
  controlResults: IControlResult[],
  selectedControlViews?: IControlView[]
): IColumn[] => {
  let columns = [
    {
      key: "impactScore",
      name: "Impact Score",
      fieldName: "impactScore",
      minWidth: 30,
      maxWidth: 70,
      isResizable: true,
      className: classNames.impactScoreColumn,
      onRender: (item) =>
        item.offline ? (
          <div
            data-is-focusable="true"
            className={`${classNames.impactScore} impactScore`}
            aria-label={item.offlineReason}
            title={item.offlineReason}
            style={{ backgroundColor: "#707070" }}
          >
            <Icon iconName="WarningSolid" style={{ color: "goldenrod" }} />
          </div>
        ) : item.isOverdue ? (
          <div
            data-is-focusable="true"
            className={`${classNames.impactScore} impactScore`}
            aria-label={controlOverdueText}
            title={controlOverdueText}
            style={{ backgroundColor: getImpactScoreColor(getImpactScore(item)) }}
          >
            <Icon iconName="WarningSolid" style={{ color: "goldenrod" }} />
          </div>
        ) : (
          renderImpactScoreByControl(item)
        ),
    },
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      data: "number",
    },
    {
      key: "name",
      name: "Control Name",
      fieldName: "name",
      minWidth: 120,
      maxWidth: 280,
      isResizable: true,
    },
    {
      key: "soxCompliance",
      name: "SOX",
      fieldName: "soxCompliance",
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => (item.soxCompliance ? "Yes" : "No"),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => (
        <ContactLink alias={item.owner} messageSubject={getContactMessageSubject(item as IControl)} />
      ),
    },
    {
      key: "owningTeam",
      name: "Team",
      fieldName: "owningTeam",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => (
        <ContactLink alias={item.owningTeam} messageSubject={getContactMessageSubject(item as IControl)} />
      ),
    },
    {
      key: "description",
      name: "Description",
      fieldName: "description",
      minWidth: 200,
      isResizable: true,
    },
  ];

  let hasDeltaValues = controlResults?.find((control) => control.cumulativeDelta || control.cumulativeDelta === 0),
    isMECloseColumnIndex = 4;

  if (hasDeltaValues) {
    let deltaColumn = {
      key: "cumulativeDelta",
      name: "Delta",
      fieldName: "cumulativeDelta",
      minWidth: 60,
      maxWidth: 70,
      isResizable: true,
      onRender: (item) =>
        (item.cumulativeDelta || item.cumulativeDelta === 0) && !item.offline
          ? getFormatContent(item, ContentDisplayType.shortCurrency, "cumulativeDelta")
          : "",
    };

    columns.splice(1, 0, deltaColumn);
    isMECloseColumnIndex = 5;
  }

  if (selectedControlViews?.length && selectedControlViews[0].includeMEControlColumn) {
    let meControlColumn = {
      key: "isMEClose",
      name: "ME Control",
      fieldName: "isMEClose",
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onRender: (item) => (item.isMEClose ? "Yes" : "No"),
    };

    columns.splice(isMECloseColumnIndex, 0, meControlColumn);
  }

  const hasNextRunInfo = controlResults?.find(
    (control) => (control.isManaged && control.monitorFrequency && control.lastIngestedOn) || control.nextRunTime
  );

  if (hasNextRunInfo) {
    let nextRunTimeColumn: any = {
      key: "nextRunTime",
      name: "Est. Next Run Time",
      fieldName: "nextRunTime",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
      onRender: (item) => {
        if (item.offline) {
          return <span style={{ color: "darkgrey" }}>Offline</span>;
        }

        if (item.isOverdue) {
          return <span style={{ color: "red" }}>Overdue</span>;
        }

        if (item.isManaged) {
          let runFreq = item.monitorFrequency,
            runFreqNumber = runFreq && Number(runFreq.substring(0, runFreq.length - 1)),
            runFreqUnit = runFreq && runFreq[runFreq.length - 1].toLowerCase(),
            startTime = item.monitorStartTime,
            nextRun = undefined;

          if (!startTime?.match(/([0-1][0-9]|[2][0-3]):([0-5][0-9])/)) {
            startTime = undefined;
          }

          if (runFreqUnit === "m") {
            nextRun = moment(item.lastIngestedOn).add(runFreqNumber, "M");
          } else if (runFreqUnit === "d") {
            nextRun = moment(item.lastIngestedOn).add(runFreqNumber, "d");
          } else if (runFreqUnit === "h") {
            nextRun = moment(item.lastIngestedOn).add(runFreqNumber, "h");
          }

          if (nextRun) {
            if (startTime) {
              const nextRunTime = nextRun.format("HH:mm");
              if (nextRunTime < startTime) {
                let startHour = Number(startTime.substring(0, 2)),
                  newTime = startHour > 12 ? startHour - 12 + "pm" : (startHour === 0 ? "12" : startHour) + "am";

                return nextRun.format("YYYY-MM-DD ") + newTime;
              }
            }
            return nextRun.format("YYYY-MM-DD ha").toString();
          }
        }

        return item.nextRunTime ? moment(item.nextRunTime).format("YYYY-MM-DD ha").toString() : "n/a";
      },
    };

    columns.push(nextRunTimeColumn);
  }

  return columns;
};

export const onControlResultColumnRender = (item: any, column: any, control: IControlResult) => {
  let content = getFormatContent(
    item,
    column.displayType,
    column.fieldName,
    column.template,
    column.passStatusColor || control.passStatusColor,
    column.failStatusColor || control.failStatusColor,
    column.warningStatusColor || control.warningStatusColor,
    column.locale || control.locale,
    column.localeFieldName || control.localeFieldName,
    column.currency || control.currency,
    column.currencyFieldName || control.currencyFieldName,
    column.decimal !== undefined ? column.decimal : control.decimal,
    column.decimalFieldName || control.decimalFieldName
  );
  let tooltip = column.displayType === ContentDisplayType.json ? <pre>{content}</pre> : content;
  return (
    <TooltipHost content={tooltip} overflowMode={TooltipOverflowMode.Parent} calloutProps={{ calloutMaxWidth: 1000 }}>
      {content}
    </TooltipHost>
  );
};

export const managedReconDefaultLineItemColumns: any[] = [
  {
    fieldName: "status",
    name: "Status",
    displayType: "status",
    maxWidth: 60,
    minWidth: 60,
  },
  {
    fieldName: "key",
    name: "Key",
    maxWidth: 200,
    minWidth: 160,
  },
  {
    fieldName: "dataSourceValues",
    name: "Data Values",
    displayType: "json",
  },
];

export const getControlResultListColumn = (controlResults: IControlResult[], control: IControlResult): IColumn[] => {
  let { lineItemColumns } = control;

  // Set default line item columns if it's a managed control and lineItemColumns aren't defined.
  if (control.isManaged && (!lineItemColumns || !lineItemColumns.length)) {
    if (control.type === controlType.Recon) {
      lineItemColumns = managedReconDefaultLineItemColumns.slice();
    } else if (
      control.type === controlType.Dynamic &&
      controlResults &&
      controlResults.length &&
      controlResults[0].lineItemDetail &&
      controlResults[0].lineItemDetail.length
    ) {
      let resultObject = JSON.parse(controlResults[0].lineItemDetail);
      let finalColumns = [];
      Object.keys(resultObject).forEach(function (key, index) {
        if (index === 0) {
          let firstColumn = {
            fieldName: "status",
            name: "Status",
            displayType: "status",
            maxWidth: 60,
            minWidth: 60,
          };
          finalColumns.push(firstColumn);
        }

        let column = {
          fieldName: key,
          displayType: typeof resultObject[key] == "number" ? "number" : "text",
          name: key,
          maxWidth: 200,
          minWidth: 60,
        };
        finalColumns.push(column);
      });
      if (finalColumns.length) {
        lineItemColumns = finalColumns;
      }
    }
  }

  return (
    lineItemColumns &&
    lineItemColumns.map((column: any, index) => {
      return {
        ...column,
        key: "crl" + index,
        isResizable: true,
        onRender: (item: any) => onControlResultColumnRender(item, column, control),
      };
    })
  );
};

export const getControlReport = (
  controlResults: IControlResult[],
  selectedControl?: IControlResult,
  selectedControlViews?: IControlView[],
  totalCount?: number
): IControlReport => {
  return {
    columns: getControlReportColumns(controlResults, selectedControl, selectedControlViews),
    items: controlResults,
    totalCount,
  };
};

export const getControlReportColumns = (
  controlResults: IControlResult[],
  selectedControl?: IControlResult,
  selectedControlViews?: IControlView[]
): IColumn[] => {
  let controlType = selectedControl?.type;
  return controlType
    ? getControlResultListColumn(controlResults, selectedControl)
    : getControlListColumns(controlResults, selectedControlViews);
};

export const getControlReportByLink = (controlResults: IControlResult[], link: ILink): IControlReport => {
  let items: IControlResult[] =
    controlResults && link
      ? controlResults.filter((r) => r.sourceId === link.fromNodeId && r.targetId === link.toNodeId)
      : controlResults;

  return {
    columns: getControlListColumns(items),
    items,
  };
};

export const getControlReportByNode = (controlResults: IControlResult[], node: INode): IControlReport => {
  let items: IControlResult[] =
    controlResults && node
      ? controlResults.filter(
          (r) => r.sourceId === node.id || r.targetId === node.id || (r.entityIds && r.entityIds.indexOf(node.id) >= 0)
        )
      : controlResults;

  return {
    columns: getControlListColumns(items),
    items,
  };
};

export const getControlResults = (controlViews: IControlView[], controls: IControlResult[]): IControlResult[] => {
  if (!controlViews || !controlViews.length) return controls;

  let controlNodes: any = {};

  controlViews &&
    controlViews.forEach((controlView) => {
      if (controlView && controlView.nodes && controlView.nodes.length) {
        controlView.nodes.forEach((node) => {
          controlNodes[node.id] = true;
        });
      }
    });

  const nodeIds = Object.keys(controlNodes);

  return (
    controls &&
    controls.filter((control) => {
      if (controlNodes[control.sourceId] || controlNodes[control.targetId]) {
        return true;
      }

      if (control.entityIds && control.entityIds.length) {
        for (var i = 0; i < nodeIds.length; i++) {
          var nodeId = nodeIds[i];

          if (control.entityIds.indexOf(nodeId) >= 0) {
            return true;
          }
        }
      }

      return false;
    })
  );
};

export const getSelectedControlViews = (
  controlViews: IControlViewList,
  selectedViewIdsString: string
): IControlView[] => {
  let selectedViewIds = selectedViewIdsString?.split(","),
    selectedControlViews: IControlView[] = [];

  controlViews &&
    Object.keys(controlViews).forEach((id) => {
      let controlView = controlViews[id];
      if (selectedViewIds?.indexOf(controlView.id) >= 0) {
        selectedControlViews.push(controlView);
      }
    });

  return selectedControlViews;
};
