import { IColumn } from "@fluentui/react/lib/DetailsList";
import { IVisualProps } from "../components/Visual";
import { getFormatContent, startCase, camelCase, ContentDisplayType, checkForValue } from "./miscHelper";

export const columnCellClassName = "columnCellClass";
export const columnHeaderClassName = "columnHeaderClass";

export interface IDetailsListItemsColumns {
  columns: IColumn[];
  items: any[];
  isSortedDescending?: boolean;
  sortedColumnKey?: string;
  searchText?: string;
  announcedMessage?: string;
}

export const onDetailsListColumnClick = (
  column: IColumn,
  itemsColumns: IDetailsListItemsColumns
): IDetailsListItemsColumns => {
  const { columns, items } = itemsColumns;
  const newColumns: IColumn[] = columns.slice();
  const currColumn: IColumn = newColumns.filter((currCol) => column.fieldName === currCol.fieldName)[0];

  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending;
      currColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });

  let fieldName = currColumn.fieldName;

  if (items && items.length > 0 && !items[0].hasOwnProperty(fieldName)) {
    fieldName = camelCase(currColumn.fieldName);
  }

  const newItems = copyAndSortItems(
    items,
    fieldName,
    currColumn.isSortedDescending,
    currColumn["displayType"] || currColumn["data"]
  );

  return {
    columns: newColumns,
    items: newItems,
    sortedColumnKey: column.fieldName,
    isSortedDescending: currColumn.isSortedDescending,
  };
};

export const copyAndSortItems = (items: any[], key: string, isSortedDescending?: boolean, dataType?: string): any[] => {
  return items.slice(0).sort((a, b) => {
    let aValue = checkForValue(a[key]) ? a[key] : a[camelCase(key)],
      bValue = checkForValue(b[key]) ? b[key] : b[camelCase(key)];

    if (!checkForValue(aValue) && !checkForValue(bValue)) return 0;

    if (dataType === "number") {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }

    if (isSortedDescending) {
      return !checkForValue(aValue) ? 1 : !checkForValue(bValue) ? -1 : aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    } else {
      return !checkForValue(bValue) ? 1 : !checkForValue(aValue) ? -1 : aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    }
  });
};

export const updateColumns = (columns: IColumn[], columnProps: any): IColumn[] => {
  return columns && columns.map((column) => ({ ...column, ...columnProps }));
};

export const generateColumns = (
  data: any[],
  columns: IColumn[],
  keyPrefix: string = "table-column-",
  id?: string,
  includeUndefinedColumns?: boolean,
  visualProps?: IVisualProps
): IColumn[] => {
  let result: IColumn[] = [],
    resultColumnKeys = [];

  // If columns are defined, use the supplied definition.
  if (columns && columns.length) {
    result = columns.map((column: IColumn, columnIndex) => {
      column.fieldName && resultColumnKeys.push(column.fieldName.toLowerCase());

      if (column["displayType"] === ContentDisplayType.dropdown && visualProps.onColumnValueSelect) {
        column["onColumnValueSelect"] = visualProps.onColumnValueSelect;
      }

      return {
        ...column,
        key: keyPrefix + columnIndex,
        isResizable: true,
        className: columnCellClassName + id + column.fieldName + columnIndex,
        headerClassName: columnHeaderClassName + id + column.fieldName + columnIndex,
        onRender: (item: any) => renderColumn(item, column),
      };
    });

    if (!includeUndefinedColumns) {
      return result;
    }
  }

  // If columns are not defined, use first row of data to create best-guess columns definition.
  if (!data || !data.length) return result;

  let firstRow = data[0],
    columnKeys = Object.keys(firstRow).filter((key) => resultColumnKeys.indexOf(key && key.toLowerCase()) < 0);

  columnKeys.forEach((key, index) => {
    let defaultWidth =
      firstRow[key] && firstRow[key].toString ? Math.min(firstRow[key].toString().length * 8, 200) : 60;

    result.push({
      key: keyPrefix + index,
      name: startCase(key),
      fieldName: key,
      isResizable: true,
      minWidth: defaultWidth,
      maxWidth: index < columnKeys.length - 1 ? defaultWidth * 2 : undefined,
    });
  });

  return result;
};

export const renderColumn = (item: any, column: any) =>
  getFormatContent(
    item,
    column.displayType,
    column.fieldName,
    column.template,
    null,
    null,
    null,
    column.locale,
    column.localeFieldName,
    column.currency,
    column.currencyFieldName,
    column.decimal,
    column.decimalFieldName,
    column
  );

export const getSortedItemsColumns = (
  itemsColumns: IDetailsListItemsColumns,
  onColumnClick?: (ev, column: IColumn) => void,
  searchText?: string
): IDetailsListItemsColumns => {
  if (!itemsColumns || !itemsColumns.columns || !itemsColumns.items) {
    return { items: [], columns: [] };
  }

  let sortedColumn = itemsColumns.columns.find((column) => column.isSorted === true),
    items = itemsColumns.items;

  // Filter by the search text if defined.
  if (searchText) {
    items = searchItems(items, searchText);
  }

  if (sortedColumn) {
    items = copyAndSortItems(
      items,
      sortedColumn.fieldName,
      sortedColumn.isSortedDescending,
      sortedColumn["displayType"] || sortedColumn["data"]
    );
  }

  return {
    items,
    columns: updateColumns(itemsColumns.columns, { onColumnClick }),
    searchText,
  };
};

export const searchItems = (items: any[], searchText: string): any[] => {
  if (items?.length && searchText) {
    searchText = searchText.trim().toLowerCase();

    return items.filter((item) => {
      let found = false,
        fieldNames = Object.keys(item);

      for (var i = 0; i < fieldNames.length; i++) {
        let fieldName = fieldNames[i],
          field = item[fieldName];

        if (field) {
          if (
            (typeof field === "object" || Array.isArray(field)) &&
            JSON.stringify(field).toLowerCase().indexOf(searchText) >= 0
          ) {
            found = true;
            break;
          } else if (field.toString && field.toString().toLowerCase().indexOf(searchText) >= 0) {
            found = true;
            break;
          }
        }
      }

      return found;
    });
  }
  return items;
};
