import { ILink, INode } from "../../../shared/components/Diagram";
import { actionOutputPrefix } from "./MyControlEdit.helper";

const defaultNodeWidth = 180;
const defaultXWidth = 220;
const defaultY = 0;

export const getInputNodes = (controlSdkSettings, controlSdk): INode[] => {
  let inputNodes =
    controlSdkSettings?.entities?.map((entity, index) => ({
      id: entity,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Entities")?.Sources.find(
        (source) => source.Id === entity
      )?.DisplayName,
      x: index * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "darkblue" },
    })) || [];

  let nodeCount = inputNodes?.length || 0;

  inputNodes = inputNodes.concat(
    controlSdkSettings?.datasets?.map((dataset, index) => ({
      id: dataset,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Datasets")?.Sources.find(
        (source) => source.Id === dataset
      )?.DisplayName,
      x: (nodeCount + index) * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "crimson" },
    })) || []
  );

  inputNodes = inputNodes.concat(
    controlSdkSettings?.dimensions?.map((dimension, index) => ({
      id: dimension,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Dimensions")?.Sources.find(
        (source) => source.Id === dimension
      )?.DisplayName,
      x: (nodeCount + index) * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "blueviolet" },
    })) || []
  );

  return inputNodes;
};

export const getActionNodesAndLinks = (controlSdkSettings, controlSdk): [INode[], ILink[], number] => {
  let actionTrainCount = 0,
    actionNodes = [],
    actionLinks = [];

  controlSdkSettings?.actions?.forEach((action, index) => {
    let hasInputFromActionOutput = action.input_keys?.find((input) => input.startsWith(actionOutputPrefix))?.length > 0,
      x = (index - actionTrainCount + 1) * defaultXWidth,
      y = defaultY;

    // If no input is from other action output, the action is considered an "anchor" action of a new "train".
    if (!hasInputFromActionOutput) {
      y = actionTrainCount * 60 + defaultY;
      x = 0;
      actionTrainCount++;
    } else {
      action.input_keys?.forEach((input) => {
        let inputActionNumber = Number(input.replace(actionOutputPrefix, ""));
        let parentNode = actionNodes[inputActionNumber - 1];
        y = parentNode.y;
        x = parentNode.x + defaultXWidth;

        actionLinks.push({ fromNodeId: "action" + (inputActionNumber - 1), toNodeId: "action" + index });
      });
    }

    actionNodes.push({
      id: "action" + index,
      name: action.customName || controlSdk?.Actions?.find((sdkAction) => sdkAction.Name === action.name)?.DisplayName,
      x,
      y,
      width: defaultNodeWidth,
      style: { backgroundColor: "darkgoldenrod" },
    });
  });

  return [actionNodes, actionLinks, actionTrainCount];
};

export const getOutputNodes = (controlSdkSettings, controlSdk): INode[] => {
  return controlSdkSettings?.Outputs?.map((output, index) => ({
    id: "output" + index,
    name: controlSdk?.Outputs?.find((sdkOutput) => sdkOutput.Id === output.Id)?.DisplayName,
    x: index * defaultXWidth,
    y: defaultY,
    width: defaultNodeWidth,
    style: { backgroundColor: "teal" },
  }));
};
