import { IField, FieldType } from "../../../shared/components/DataForm";
import { IChecklist } from "../../common/interfaces";

export const defaultChecklist: IChecklist = {
  id: "new",
  name: "",
  soxCompliance: true,
};

export const parameterDataTypeOptions = [
  { key: "date", text: "date" },
  { key: "email", text: "email" },
  { key: "text", text: "text" },
];

export const getFields = (checklist: IChecklist): IField[] => {
  if (!checklist) return null;

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "DevOps View ID",
      value: checklist.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "scenarioId",
      label: "Scenario ID",
      required: true,
      value: checklist.scenarioId,
    },
    {
      fieldName: "name",
      label: "View Name",
      required: true,
      lastInRow: true,
      value: checklist.name,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: checklist.owner,
      required: true,
      hints: "Alias of subscription owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: checklist.editors,
      required: true,
      lastInRow: true,
      allowFullEditor: true,
      hints: "Comma separated list of aliases who can edit this subscripton",
    },
    {
      fieldName: "emailTo",
      fieldType: FieldType.textArray,
      label: "Email To",
      width: "100%",
      value: checklist.emailTo,
      allowFullEditor: true,
      hints: "Comma separated list of aliases to whom this checklist will email to",
    },
    {
      fieldName: "emailCC",
      fieldType: FieldType.textArray,
      label: "Email CC",
      width: "100%",
      value: checklist.emailCC,
      allowFullEditor: true,
      hints: "Comma separated list of aliases to whom this checklist will email to as Cc",
    },
    {
      fieldName: "project",
      label: "Project",
      width: "100%",
      value: checklist.project,
      required: true,
      allowFullEditor: true,
    },
    {
      fieldName: "areaPath",
      label: "Area Path",
      width: "100%",
      value: checklist.areaPath,
      required: true,
      allowFullEditor: true,
    },
    {
      fieldName: "useAnchorDate",
      fieldType: FieldType.boolean,
      label: "Use Anchor Date",
      value: checklist.useAnchorDate,
      lastInRow: true,
      doNotExpandWidth: true,
      hints:
        "Indicate whether to ask for the offset anchor date when generating work items for this checklist.  If false, the last day of the current month will be used.",
    },
    {
      fieldName: "deliverableTemplate",
      fieldType: FieldType.context,
      value: checklist.deliverableTemplate,
      collapsed: false,
      fields: [
        {
          fieldName: "title",
          label: "Deliverable Title",
          width: "100%",
          allowFullEditor: true,
        },
        {
          fieldName: "description",
          label: "Deliverable Description",
          width: "100%",
          allowFullEditor: true,
        },
        {
          fieldName: "areaPath",
          label: "Deliverable Area Path",
          width: "100%",
          allowFullEditor: true,
          hints: "If no value is supplied, then it will inherit the Area Path from the top level of the configuration.",
        },
        {
          fieldName: "assignTo",
          label: "Deliverable Assign To",
          width: "300px",
        },
        {
          fieldName: "riskAssessment",
          label: "Initial Status",
          width: "150px",
          regEx: "^(On Track|Not On Track|At Risk)?$",
          hints: "Must be one of On Track, At Risk, or Not On Track.  Default value is On Track.",
        },
        {
          fieldName: "tags",
          label: "Deliverable Tags",
          width: "100px",
          regEx: "^([a-zA-Z0-9]*)([;][a-zA-Z0-9]+)*$",
          hints: "Multiple tags must be separated by a semicolon (;).",
        },
        {
          fieldName: "taskGroupTemplates",
          fieldType: FieldType.items,
          label: "Task Group Templates",
          itemTypeName: "Task Group",
          collapsed: false,
          fields: [
            {
              fieldName: "title",
              label: "Title",
              width: "100%",
              allowFullEditor: true,
            },
            {
              fieldName: "description",
              label: "Description",
              width: "100%",
              allowFullEditor: true,
            },
            {
              fieldName: "areaPath",
              label: "Area Path",
              width: "100%",
              allowFullEditor: true,
              hints: "If no value is supplied, then it will inherit the Area Path from the Deliverable.",
            },
            {
              fieldName: "assignTo",
              label: "Assign To",
              width: "300px",
            },
            {
              fieldName: "startOffset",
              label: "Start Date Offset",
              width: "100px",
              regEx: "^([-]?[0-9]+([.][0-9]+)?[dh])?$",
              hints:
                "A string represents the start date/time offset from the end of the month, e.g. -1d for one day before the last day of the month, or 1h for one hour after the current time.",
            },
            {
              fieldName: "dueOffset",
              label: "Due Date Offset",
              width: "100px",
              regEx: "^([-]?[0-9]+([.][0-9]+)?[dh])?$",
              hints:
                "A number represents the due date/time offset from the end of the month, , e.g. 0d for the last day of the month, or 1h for one hour after the current time.",
            },
            {
              fieldName: "rank",
              fieldType: FieldType.number,
              width: "100px",
              label: "Rank",
              hints: "This must be unique across all Task Groups within the Deliverable.",
            },
            {
              fieldName: "section",
              width: "300px",
              label: "Section",
            },
            {
              fieldName: "riskAssessment",
              label: "Initial Status",
              width: "150px",
              regEx: "^(On Track|Not On Track|At Risk)?$",
              hints: "Must be one of On Track, At Risk, or Not On Track.  Default value is On Track.",
            },
            {
              fieldName: "tags",
              label: "Tags",
              width: "100px",
              regEx: "^([a-zA-Z0-9]*)([;][a-zA-Z0-9]+)*$",
              hints: "Multiple tags must be separated by a semicolon (;).",
            },
            {
              fieldName: "taskTemplates",
              fieldType: FieldType.items,
              label: "Task Templates",
              itemTypeName: "Task",
              collapsed: false,
              fields: [
                {
                  fieldName: "title",
                  label: "Title",
                  width: "100%",
                  allowFullEditor: true,
                },
                {
                  fieldName: "description",
                  label: "Description",
                  width: "100%",
                  allowFullEditor: true,
                },
                {
                  fieldName: "areaPath",
                  label: "Area Path",
                  width: "100%",
                  allowFullEditor: true,
                  hints: "If no value is supplied, then it will inherit the Area Path from the Task Group.",
                },
                {
                  fieldName: "assignTo",
                  label: "Assign To",
                  width: "300px",
                },
                {
                  fieldName: "startOffset",
                  label: "Start Date Offset",
                  width: "100px",
                  regEx: "^([-]?[0-9]+([.][0-9]+)?[dh])?$",
                  hints:
                    "A string represents the start date/time offset from the end of the month, e.g. -1d for one day before the last day of the month, or 1h for one hour after the current time.",
                },
                {
                  fieldName: "dueOffset",
                  label: "Due Date Offset",
                  width: "100px",
                  regEx: "^([-]?[0-9]+([.][0-9]+)?[dh])?$",
                  hints:
                    "A number represents the due date/time offset from the end of the month, , e.g. 0d for the last day of the month, or 1h for one hour after the current time.",
                },
                {
                  fieldName: "remainingDays",
                  width: "100px",
                  label: "Estimate",
                  regEx: "([\\d]*[.])?[\\d]+",
                  hints:
                    "The number of days it is estimated to complete the task.  This can be a whole number or decimal value.",
                },
                {
                  fieldName: "rank",
                  fieldType: FieldType.number,
                  width: "100px",
                  label: "Rank",
                  hints: "This must be unique across all Tasks within the Task Group.",
                },
                {
                  fieldName: "riskAssessment",
                  label: "Initial Status",
                  width: "150px",
                  regEx: "^(On Track|Not On Track|At Risk)?$",
                  hints: "Must be one of On Track, At Risk, or Not On Track.  Default value is On Track.",
                },
                {
                  fieldName: "tags",
                  label: "Tags",
                  width: "100px",
                  regEx: "^([a-zA-Z0-9]*)([;][a-zA-Z0-9]+)*$",
                  hints: "Multiple tags must be separated by a semicolon (;).",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      fieldName: "parameters",
      fieldType: FieldType.items,
      label: "Parameters",
      collapsed: false,
      hints: "Optional parameters will be prompted for the user who generates the corresponding work items.",
      fields: [
        {
          fieldName: "prompt",
          label: "Prompt Text",
          hints: "Prompt text for the parameter",
        },
        {
          fieldName: "name",
          label: "Parameter Name",
          regEx: "^[a-zA-Z][a-zA-Z0-9]*$",
          hints:
            "Placeholder name to use in your config.  When used in your config, enclose the parameter name with curly brackets, e.g. if the parameter name is assignTo, put {assignTo} in the corresponding field value(s) of your config.",
        },
        {
          fieldName: "dataType",
          fieldType: FieldType.enumeration,
          label: "Data Type",
          options: parameterDataTypeOptions,
        },
      ],
    },
  ];
};
