import React, { Component } from "react";
import { KpiTile, Tile, BuiltInCommandBarItemKey } from "../../../shared/components/Tile";
import { Timeline, TimeLabelType } from "../../../shared/components/Timeline";
import { IControlResult, IControl } from "../interfaces";
import { controlType } from "../HealthReport.helper";
import GroupingTile from "../../../shared/components/Tile/GroupingTile";
import TimeSelector, { TimeSelectorValue, convertToTimeSpanInDays } from "../../../shared/components/TimeSelector";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { getImpactScore, getImpactScoreColor } from "../../common/helper";
import classNames from "./ControlResultSummary.module.scss";
import moment from "moment";
import { Icon, Label, Stack } from "@fluentui/react";

export const defaultControlHistoryTimeSelectorValue = TimeSelectorValue.oneMonth;

export interface IControlResultSummaryProps {
  items: IControlResult[];
  control: IControl;
  totalPassTitle?: string;
  totalFailTitle?: string;
  loadingHistory?: boolean;
  selectedResultId?: string;
  onTimeSelect?: (id: string, timeAgo: string) => void;
  onResultSelect?: (controlResultId: string) => void;
}

export interface IControlResultSummaryState {
  selectedTimeValue: TimeSelectorValue;
  selectedResultStatus: string;
}

export class ControlResultSummary extends Component<IControlResultSummaryProps, IControlResultSummaryState> {
  state: IControlResultSummaryState = {
    selectedTimeValue: defaultControlHistoryTimeSelectorValue,
    selectedResultStatus: localStorage["selectedResultStatus"] || "",
  };

  render() {
    const {
      control,
      totalPassTitle = "Total Passes",
      totalFailTitle = "Total Failures",
      loadingHistory,
      selectedResultId,
    } = this.props;
    const {
      passStatusColor = "green",
      failStatusColor = "red",
      showFailuresOnly,
      dateFieldDisplayType = "date",
      dateRangeStartDateTime,
      dateRangeEndDateTime,
      resultHistory,
      passed,
      failed,
      deltaAmountUSD,
      deltaAmountUSDTitle,
      deltaAmountPercentage,
      deltaAmountPercentageTitle,
      controlResultId = selectedResultId,
      resultTiles,
      lastIngestedOn,
    } = control;

    let maxDate = null,
      minDate = null;

    if (dateRangeStartDateTime || dateRangeEndDateTime) {
      minDate = dateRangeStartDateTime;
      maxDate = dateRangeEndDateTime;
    }

    const passedTileConfig = getTileConfig(resultTiles, "passed"),
      failedTileConfig = getTileConfig(resultTiles, "failed"),
      deltaAmountUSDTileConfig = getTileConfig(resultTiles, "deltaAmountUSD"),
      deltaAmountPercentageTileConfig = getTileConfig(resultTiles, "deltaAmountPercentage");

    const minDateString = minDate && getFormatContent(minDate, dateFieldDisplayType),
      maxDateString = minDate && getFormatContent(maxDate, dateFieldDisplayType),
      isSingleDate = maxDateString === minDateString,
      deltaAmount =
        deltaAmountUSD &&
        getFormatContent(
          Math.round(deltaAmountUSD),
          deltaAmountUSDTileConfig.displayType || ContentDisplayType.shortCurrency
        ),
      deltaPercent =
        deltaAmountPercentage &&
        getFormatContent(
          Math.round(deltaAmountPercentage * 10000) / 100,
          deltaAmountPercentageTileConfig.displayType || ContentDisplayType.number
        ) + "%";

    const { selectedTimeValue } = this.state;
    const historyTileMenuContent = (
      <TimeSelector
        availableValues={timeSelectorValues}
        selectedValue={selectedTimeValue}
        onValueSelect={this.onTimeValueSelect}
      />
    );

    const hasResultHistory = resultHistory && !!resultHistory.length;

    const finalResultTiles = this.getFinalResultTiles(control);

    let tileTitle = "Control Result";

    if (controlResultId) {
      tileTitle += ` &nbsp;<span style='font-size:10pt;font-weight:100;'>(<b>ID:</b> ${controlResultId}, <b>Ingested On:</b> ${new Date(
        lastIngestedOn
      ).toLocaleString()})</span>`;
    }

    return (
      <GroupingTile
        title={tileTitle}
        className={classNames.root}
        classNames={classNames}
        menuContent={this.getGroupingTileMenuContent()}
      >
        <div className={classNames.groupingContent} about={`ControlResultId=${controlResultId}`}>
          {!!minDateString && (
            <KpiTile
              title="Status Based On"
              value={isSingleDate ? minDateString : `${minDateString} - ${maxDateString}`}
              valueColor="grey"
              style={{ minWidth: isSingleDate ? "200px" : "260px" }}
            />
          )}
          {!showFailuresOnly && passed !== undefined && (
            <KpiTile
              title={totalPassTitle}
              value={getFormatContent(passed, passedTileConfig.displayType || ContentDisplayType.number)}
              valueColor={passed ? passStatusColor : "grey"}
              className={classNames.kpiTile}
            />
          )}
          {failed !== undefined && (
            <KpiTile
              title={totalFailTitle}
              value={getFormatContent(failed, failedTileConfig.displayType || ContentDisplayType.number)}
              valueColor={failed ? failStatusColor : "grey"}
              className={classNames.kpiTile}
            />
          )}
          {!!deltaAmount && (
            <KpiTile
              title={deltaAmountUSDTileConfig.name || deltaAmountUSDTitle || "Delta Amount USD"}
              value={deltaAmount}
              valueColor={deltaAmountUSDTileConfig.color || failStatusColor}
              className={classNames.kpiTile}
            />
          )}
          {!!deltaPercent && (
            <KpiTile
              title={deltaAmountPercentageTileConfig.name || deltaAmountPercentageTitle || "Delta Percentage"}
              value={deltaPercent}
              valueColor={deltaAmountPercentageTileConfig.color || failStatusColor}
              className={classNames.kpiTile}
            />
          )}
          {finalResultTiles &&
            !!finalResultTiles.length &&
            finalResultTiles.map((tile, index) => {
              const fieldName = tile.fieldName,
                fixedValue = tile.fixedValue;

              if (
                tile.template ||
                fixedValue ||
                (fieldName &&
                  fieldName !== "passed" &&
                  fieldName !== "failed" &&
                  fieldName !== "deltaAmountUSD" &&
                  fieldName !== "deltaAmountPercentage")
              ) {
                const value = fixedValue
                  ? getFormatContent(fixedValue, tile.displayType)
                  : getFormatContent(control, tile.displayType, fieldName, tile.template);

                return (
                  <KpiTile
                    key={`rt-${fieldName}-${index}`}
                    title={tile.name || fieldName}
                    value={value}
                    valueColor={tile.color}
                    className={classNames.kpiTile}
                  />
                );
              }

              return null;
            })}
          <Tile
            title="Control Result History"
            applyContentStyle
            className={classNames.timelineTile}
            commandBarItemKeys={[BuiltInCommandBarItemKey.showFullScreen]}
            menuContent={historyTileMenuContent}
            onResize={this.onControlResultHistoryTileResize}
          >
            {loadingHistory && (
              <Spinner
                styles={{
                  root: classNames.spinner,
                  circle: classNames.spinnerCircle,
                  label: classNames.spinnerLabel,
                }}
              />
            )}
            {!loadingHistory && !hasResultHistory && (
              <div className={classNames.error}>
                No result history is found with the selected timeframe.{" "}
                {selectedTimeValue !== TimeSelectorValue.max
                  ? " Try selecting max timeframe to see if older control results can be found."
                  : ""}
              </div>
            )}
            {!loadingHistory && hasResultHistory && (
              <Timeline
                extendTimeRange={true}
                showMarkerFlags={false}
                showMarkers={true}
                minHeight={60}
                tailWidth={20}
                headWidth={20}
                timelineHeight={20}
                markerSize={4}
                xAxisPadding={16}
                xAxisLabelFontSize={10}
                timeLabelType={TimeLabelType.day}
                markers={resultHistory
                  .sort(
                    (a, b) =>
                      a.dateRangeEndDateTime?.localeCompare(b.dateRangeEndDateTime) ||
                      a.lastIngestedOn?.localeCompare(b.lastIngestedOn)
                  )
                  .map((result) => {
                    const impactScore = getImpactScore(result);
                    return {
                      time: result.dateRangeEndDateTime,
                      name: this.getResultText(result),
                      color: getImpactScoreColor(impactScore),
                      value: impactScore,
                      showValueText: true,
                    };
                  })}
                currentMarker={{ time: dateRangeEndDateTime, name: "Current Result" }}
              />
            )}
          </Tile>
        </div>
      </GroupingTile>
    );
  }

  onTimeValueSelect = (selectedTimeValue) => {
    const { control, onTimeSelect } = this.props;
    this.setState({ selectedTimeValue });

    let timeSpanInDays = convertToTimeSpanInDays(selectedTimeValue);
    onTimeSelect(control.id, timeSpanInDays);
  };

  getGroupingTileMenuContent = (): JSX.Element => {
    const { control, selectedResultId } = this.props;
    const { selectedResultStatus } = this.state;
    const { resultHistory } = control;

    if (!resultHistory || resultHistory.length <= 1) {
      return null;
    }

    let filteredResultHistory = resultHistory.filter(
      (result) =>
        !isNaN(new Date(result.dateRangeEndDateTime).valueOf()) &&
        (!selectedResultStatus || result.resultStatus?.toLowerCase() === selectedResultStatus?.toLowerCase())
    );

    let endDateList = [],
      resultHistoryOptions: IDropdownOption[] = [],
      resultStatusOptions: IDropdownOption[] = [
        { key: "", text: "All" },
        { key: "fail", text: "Fail" },
        { key: "pass", text: "Pass" },
      ],
      selectedKey =
        selectedResultId ||
        control.controlResultId ||
        (filteredResultHistory?.length && filteredResultHistory[0].controlResultId);

    filteredResultHistory
      .sort(
        (a, b) =>
          a.dateRangeEndDateTime?.localeCompare(b.dateRangeEndDateTime) ||
          a.lastIngestedOn?.localeCompare(b.lastIngestedOn)
      )
      .reverse()
      .forEach((result) => {
        if (!control.showLatestResultOnly || endDateList.indexOf(result.dateRangeEndDateTime) < 0) {
          resultHistoryOptions.push({
            key: result.controlResultId,
            text: this.getResultText(result),
            data: {
              icon: result.resultStatus === "pass" ? "CompletedSolid" : "Blocked2Solid",
              color: result.resultStatus === "pass" ? "green" : "red",
            },
          });

          endDateList.push(result.dateRangeEndDateTime);
        }
      });

    const onRenderHistoryOption = (option: IDropdownOption): JSX.Element => {
      return (
        <Stack horizontal verticalAlign="center">
          {option.data && option.data.icon && (
            <Icon
              style={{ marginRight: "6px", marginTop: "2px", color: option.data.color }}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <Label className={classNames.resultHistoryDropdownLabel}>{option.text}</Label>
        </Stack>
      );
    };

    return (
      <div className={classNames.resultMenuPane}>
        <Dropdown
          aria-label="Select result status type"
          className={classNames.resultStatusDropdown}
          options={resultStatusOptions}
          selectedKey={selectedResultStatus}
          dropdownWidth={70}
          onChange={this.onResultStatusChange}
        />
        <Dropdown
          aria-label="Select result to view"
          className={classNames.resultHistoryDropdown}
          options={resultHistoryOptions}
          selectedKey={selectedKey}
          onChange={this.onResultChange}
          onRenderOption={onRenderHistoryOption}
          styles={{
            title: {
              fontFamily: "monospace, monospace",
              fontVariantNumeric: "tabular-nums",
              selectors: {
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  width: 130,
                  background: "white",
                },
              },
            },
          }}
          dropdownWidth={280}
        />
      </div>
    );
  };

  getResultText = (result): string => {
    let date = new Date(result.dateRangeEndDateTime);
    if (!isNaN(date.valueOf())) {
      return `${moment(date).format("YYYY-MM-DD hh:mm A")} (${result?.controlResultId?.substring(0, 8)}...)`;
    }
    return null;
  };

  onResultChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    const { onResultSelect } = this.props;
    let selectedResultId = item.key.toString();

    onResultSelect(selectedResultId);
  };

  onResultStatusChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    let selectedResultStatus = item.key.toString();
    this.setState({ selectedResultStatus });
    localStorage["selectedResultStatus"] = selectedResultStatus;
  };

  onControlResultHistoryTileResize = () => {
    this.forceUpdate();
  };

  getFinalResultTiles = (control: IControl): any[] => {
    let { resultTiles } = control;

    // Set final resultTiles if it's a managed control of Dynamic and resultTiles aren't defined.
    if (control.isManaged && (!resultTiles || !resultTiles.length)) {
      if (control.type === controlType.Dynamic && control.resultDetail && control.resultDetail.length) {
        let resultObject = JSON.parse(control.resultDetail);
        let finalTiles = [];
        Object.keys(resultObject).forEach(function (key) {
          let tile = {
            name: key,
            fieldName: key,
            displayType: typeof resultObject[key] == "number" ? "number" : "text",
          };
          finalTiles.push(tile);
        });
        if (finalTiles.length) {
          resultTiles = finalTiles;
        }
      }
    }
    return resultTiles;
  };
}

export default ControlResultSummary;

export const timeSelectorValues: TimeSelectorValue[] = [
  TimeSelectorValue.max,
  TimeSelectorValue.threeMonths,
  TimeSelectorValue.oneMonth,
  TimeSelectorValue.sevenDays,
];

const getTileConfig = (resultTiles: any[], fieldName: string) =>
  (resultTiles && resultTiles.find((tile) => tile.fieldName === fieldName)) || {};
