import React, { useCallback, useContext } from "react";
import { connect } from "react-redux";
import { History } from "react-router-dom/node_modules/history";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IState } from "../../reducers/interfaces";
import { IFilter } from "../common/interfaces";
import { getFilterOptionsByUrl } from "../common/helper";
import { actionCreator } from "../duck";
import { IControlViewList, IControl } from "./interfaces";
import { leftNavUrls } from "../../app/LeftNav.helper";
import classNames from "./ControlViewFilters.module.scss";
import { ControlContext } from "./HealthReport";
import { IUserInfo } from "../../app/interfaces";

export interface IControlViewFilterProps {
  history: History;
}

export interface IControlViewFiltersStateProps {
  filters: IFilter[];
  controlViews: IControlViewList;
  controls: IControl[];
  userInfo: IUserInfo;
}

export interface IControlViewFiltersDispatchProps {
  selectControlFilter: Function;
  loadControlViews: Function;
}

export const ControlViewFilters = (
  props: IControlViewFilterProps & IControlViewFiltersStateProps & IControlViewFiltersDispatchProps
) => {
  const { viewId } = useContext(ControlContext);
  const { filters, controlViews, selectControlFilter, loadControlViews, controls, history, userInfo } = props;

  const onFilterChange = useCallback(
    (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
      let controlViewId = item.key;

      if (controlViews[controlViewId]) {
        selectControlFilter(controlViewId);
      } else {
        loadControlViews(controlViewId, false, controls && controls.length);
      }

      history.push(`${leftNavUrls.all.controls}/${controlViewId}`);
    },
    [controlViews, controls, history, loadControlViews, selectControlFilter]
  );

  if (!filters || !filters.length) {
    return null;
  }

  const filterOptions = getFilterOptionsByUrl(filters, userInfo);

  if (!filterOptions?.length) {
    return null;
  }

  return (
    <div className={classNames.root}>
      <Dropdown
        className={classNames.filters}
        placeholder="Select a view"
        label="Select a view"
        ariaLabel="Select a view"
        selectedKey={viewId}
        options={filterOptions}
        responsiveMode={ResponsiveMode.large}
        onChange={onFilterChange}
      />
    </div>
  );
};

const mapStateToProps = (state: IState): IControlViewFiltersStateProps => ({
  filters: state.modules.control_view_filters,
  controlViews: state.modules.control_views,
  controls: state.modules.controls,
  userInfo: state.app.login_user_info,
});

const mapDispatchToProps = {
  selectControlFilter: actionCreator.selectControlFilter,
  loadControlViews: actionCreator.loadControlViews,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlViewFilters);
