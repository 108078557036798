import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { useCallback } from "react";
import classNames from "./DateSelector.module.scss";

export interface IDateSelectorProps {
  date: Date;
  onDateChange: (date: Date) => void;
}

export const DateSelector = (props: IDateSelectorProps) => {
  const { date, onDateChange } = props;
  const currentYear = new Date().getFullYear();
  const selectedYear = date ? date.getFullYear() : -1;
  const selectedMonth = date ? date.getMonth() : -1;
  const selectedDay = date ? date.getDate() : -1;
  const yearOptions = [{ key: -1, text: "" }];
  const monthOptions = [{ key: -1, text: "" }];
  const dayOptions = [{ key: -1, text: "" }];

  for (let i = currentYear; i >= 2000; i--) {
    yearOptions.push({ key: i, text: i.toString() });
  }

  for (let i = 0; i <= 11; i++) {
    monthOptions.push({ key: i, text: (i + 1).toString() });
  }

  for (let i = 1; i <= 31; i++) {
    dayOptions.push({ key: i, text: i.toString() });
  }

  const onYearChange = useCallback(
    (e, option: IDropdownOption) => {
      let newDate = date ?? new Date();
      newDate.setFullYear(Number(option.key));
      onDateChange(newDate);
    },
    [date, onDateChange]
  );

  const onMonthChange = useCallback(
    (e, option: IDropdownOption) => {
      let newDate = date ?? new Date();
      newDate.setMonth(Number(option.key));
      onDateChange(newDate);
    },
    [date, onDateChange]
  );

  const onDayChange = useCallback(
    (e, option: IDropdownOption) => {
      let newDate = date ?? new Date();
      newDate.setDate(Number(option.key));
      onDateChange(newDate);
    },
    [date, onDateChange]
  );

  return (
    <div className={classNames.root}>
      <Dropdown
        className={classNames.yearDropdown}
        options={yearOptions}
        selectedKey={selectedYear}
        dropdownWidth={100}
        onChange={onYearChange}
      />
      <Dropdown
        className={classNames.monthDropdown}
        options={monthOptions}
        selectedKey={selectedMonth}
        dropdownWidth={80}
        onChange={onMonthChange}
      />
      <Dropdown
        className={classNames.dayDropdown}
        options={dayOptions}
        selectedKey={selectedDay}
        dropdownWidth={80}
        onChange={onDayChange}
      />
    </div>
  );
};

export default DateSelector;
