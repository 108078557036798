import React, { CSSProperties } from "react";
import { ContentDisplayType } from "../../shared/utilities/miscHelper";
import { IVisualProps } from "../../shared/components/Visual";
import { ContentType } from "./PowerbiReport";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { IDiagram, IMetric } from "../../shared/components/Diagram";
import { IFilterItem as IFilterItemBase } from "../../shared/components/common/interfaces";
import { IHealthMetric } from "../home/HealthDashboard.types";

export interface IFilterItem extends IFilterItemBase {
  hideForSox?: boolean;
}

export interface IFilter {
  name?: string;
  linkUrl?: string;
  items?: IFilterItem[];
}

export interface IFilterField {
  fieldName: string;
  displayName: string;
  items?: IFilterItem[];
}

export interface IComment {
  commentId?: string;
  text?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  targetStartTime?: string;
  targetEndTime?: string;
  controlIds?: string;
  controlResultIds?: string;
  entityIds?: string;
  incidentIds?: string;
  isPurged?: boolean;
}

export enum LinkedItemType {
  DevOps = "DevOps",
  IcM = "IcM",
}

export enum FilterOperator {
  Equals,
  NotEquals,
  Contains,
  NotContains,
  StartsWith,
  LessThan,
  LessThanOrEquals,
  GreaterThan,
  GreaterThanOrEquals,
  Default,
}

export interface ILinkedItem {
  linkedItemId?: string;
  externalId?: string;
  itemType?: LinkedItemType;
  linkUrl?: string;
  title?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  controlIds?: string;
  controlResultIds?: string;
  isPurged?: boolean;
  status?: string;
  settings?: string;
}

export enum ReportType {
  radar = "radar",
  powerbi = "powerbi",
  incidentView = "incidentView",
}

export interface IConfigItem {
  id?: string;
  owner?: string;
  editors?: string[];
  soxCompliance?: boolean;
  name?: string;
  description?: string;
}

export interface IReport extends IConfigItem, IDataSource {
  type?: ReportType;
  viewers?: string[];
  tiles?: ITile[];
  contentType?: ContentType;
  contentId?: string;
  groupId?: string;
  hideForViewers?: boolean;
  isRestricted?: boolean;
  useSubmitButton?: boolean;
  [customProp: string]: any;
}

export interface IDataSource {
  cluster?: string;
  database?: string;
  dataSourceType?: string;
  query?: string;
  endpointUrl?: string;
  container?: string;
  connectionString?: string;
  keyVaultTenant?: string;
  connectionStringSecretUri?: string;
  appId?: string;
  apiKey?: string;
  workspaceId?: string;
  keyVaultSecretName?: string;
  targetTileId?: string;
  dataFields?: string;
  selectedId?: string;
  timeAgo?: string;
  partitionKey?: string;
  returnRowCount?: boolean;
  rowCountFieldName?: string;
  kqlMQueryReturn?: string;
  missingDataBehavior?: string;
}

export interface ITile extends IVisualProps, IDataSource {
  tileId?: string;
  name?: string;
  displayType?: ContentDisplayType;
  compact?: boolean;
  [propName: string]: any;
}

export interface IReportGroup extends IConfigItem {
  viewers?: string[];
  owningTeam?: string;
  reportIds?: string[];
  reports?: IReport[];
  hideGroupHeader?: boolean;
  hideReportHeader?: boolean;
  hideLeftPaneOnLoad?: boolean;
  useReportGroupNameAsHeader?: boolean;
  doesUserHasFullMSSalesAccess?: boolean;
}

export interface IRecurrence {
  type?: string;
  days?: string[];
  dates?: number[];
  hours?: number[];
}

export interface ISubscriber {
  id?: string;
  email?: string;
  recurrence?: IRecurrence;
  targetUrl?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}

export interface IAppSetting extends IConfigItem {
  mappings?: IMapping[];
  settings?: any;
  templates?: ITemplate[];
}

export interface ITemplate {
  name: string;
  value: string;
}

export interface IMetricFilter extends ITemplate {}

export interface IMapping {
  source: string;
  target: string;
}

export interface IEventSubscription extends IConfigItem {
  enabled?: boolean;
  objectType?: string;
  objectIds?: string[];
  notificationMethods?: string[];
  eventTypes?: string[];
  subscribers?: string[];
  icmConnectorId?: string;
  icmTeamPublicId?: string;
  icmSeverity?: number;
  runFrequency?: string;
  runStartTime?: string;
}

export interface IEntity extends IConfigItem {
  entityType: string;
  version?: string;
  schemaVersion?: string;
  entity?: IEntity;
  sources?: IEntity[];
  targets?: IEntity[];
  [propName: string]: any;
}

export interface IEntityTest {
  financialEntityType: string;
  financialEntityId: string;
  financialEntityVersion: string;
  validationResult: string;
  validationDetails: string;
  [propName: string]: any;
}

export interface IEntityMetric {
  entityType: string;
  metrics?: IMetric[];
  [propName: string]: any;
}

export interface IEntityTile {
  entityType: string;
  tiles?: ITile[];
  [propName: string]: any;
}

export enum EntityValidationType {
  AttributeRecon = "AttributeRecon",
  TargetEntitySla = "TargetEntitySla",
  AggregateRecon = "AggregateRecon",
}

export interface IFinancialEntity {
  type: string;
  id: string;
  version: string;
  timeStamp: Date;
  schemaVersion: string;
  attributes: any;
  [propName: string]: any;
}

export interface IFinancialEntityCount {
  type: string;
  count: number;
}

export interface IFinancialEntitySearch {
  startTime: Date;
  endTime: Date;
}

export interface IEntityTraceMetric {
  type: string;
  id: string;
  version: string;
  metrics?: IMetric[];
  [propName: string]: any;
}

export interface IDevOpsItem {
  Id: string;
  ParentId?: string;
  [propName: string]: any;
}

export interface IDevOpsView extends IConfigItem {
  columns?: IColumn[];
  parentColumns?: IColumn[];
  includeDescendants?: boolean;
  isAllGroupsCollapsed?: boolean;
  testOnly?: boolean;
  isFeatureRequests?: boolean;
  filters?: IDevOpsViewFilter[];
  orgName?: string;
  [propName: string]: any;
}

export interface IDevOpsViewFilter {
  filterType?: string;
  defaultValue?: any;
  fieldName?: string;
  label?: string;
  multiSelect?: boolean;
  options?: IDropdownOption[];
  filterFields?: IFilterField[];
  [propName: string]: any;
}

export interface IDevOpsOrg {
  name?: string;
  patSecretName?: string;
  orgUrl?: string;
}

export interface IEvent extends IConfigItem {
  objectType?: string;
  enabled?: boolean;
  subUIDisplay?: boolean;
  [propName: string]: any;
}

export interface IChecklist extends IConfigItem {
  scenarioId?: string;
  emailTo?: string[];
  emailCC?: string[];
  project?: string;
  areaPath?: string;
  deliverableTemplate?: IDeliverableTemplate;
  deliverables?: IDeliverable[];
  useAnchorDate?: boolean;
  parameters?: IParameter[];
}

export interface IChecklistDevOpsItem {
  checklistId: string;
  deliverableId: string;
}

export interface IDeliverableTemplate {
  title?: string;
  description?: string;
  assignTo?: string;
  taskGroupTemplates?: ITaskGroupTemplate[];
}

export interface ITaskGroupTemplate {
  title?: string;
  description?: string;
  assignTo?: string;
  startOffset?: string;
  dueOffset?: string;
  rank?: number;
  section?: string;
  taskTemplates?: ITaskTemplate[];
}

export interface ITaskTemplate {
  title?: string;
  description?: string;
  assignTo?: string;
  startOffset?: string;
  dueOffset?: string;
  rank?: number;
}

export interface IIcMConnector {
  id: string;
  serviceName: string;
}

export interface IDeliverable {
  deliverableId: string;
  status?: string;
}

export interface IParameter {
  prompt: string;
  name: string;
  dataType?: ParameterDataType;
  optional?: boolean;
  rows?: number;
  width?: React.ReactText;
}

export interface IAnomalyTest extends IConfigItem {
  [propName: string]: any;
}

export interface IContentType {
  contentName: string;
}
export interface ISourceCallType {
  type: string;
  contentTypes?: IContentType[];
}
export interface ICollectorService extends IConfigItem {
  lineItemApiAddress?: string;
  apiVersion?: string;
  lineItemPath?: string;
  pageSizeCount?: number;
  nextToken?: string;
  isTrigger?: boolean;
  startTime?: number;
  hasLineItems?: boolean;
  getNonTestOnly?: boolean;
  feTypePath?: string;
  isJournalDataArray?: boolean;
  sourceCallTypes?: ISourceCallType[];
  tenantId?: string;
  clientId?: string;
  clientSecretKVPropName?: string;
}

export type DataType = "string" | "number" | "date";
export type LogicalOperator = "and" | "or";

export enum SeriesType {
  dailyTrend = "dailyTrend",
  monthlyTrend = "monthlyTrend",
}

export enum TimeComponent {
  MTD,
  QTD,
  FYTD,
}

export enum ThresholdType {
  FailureAmount = "Failure Amount",
  FailureCount = "Failure Count",
}

export interface ISearchField {
  attribute: string;
  name?: string;
  dataType?: DataType;
  indexed?: boolean;
}

export interface ISearchFilter {
  attribute?: string;
  value?: string;
  operator?: string;
  operatorText?: string;
  dataType?: DataType;
  logicalOperator?: LogicalOperator;
  filters?: ISearchFilter[];
  indexed?: boolean;
  entityTypes?: string[];
}

export interface IEntityView extends IConfigItem, IDiagram {
  maxVersionOnly?: boolean;
  useMonthPicker?: boolean;
  monthValueDiff?: number;
  anchorDateAttribute?: string;
  defaultEntitySubPaneKey?: string;
}

export interface IEntityTraceView extends IConfigItem, IDiagram {}

export interface ITeam extends IConfigItem {
  sortOrder?: number;
  healthMetricsSortOrder?: number;
  reportId?: number;
  report?: IReport;
  ignoreOkrs?: boolean;
  ignoreFmeCloseIssues?: boolean;
  okrs?: IOkr[];
  okrMetrics?: IOkrMetric[];
  fmeCloseIssues?: IFmeCloseIssue[];
  healthMetrics?: IHealthMetric[];
  upStreams?: IUpStream[];
  healthMetricHeaderStyle?: CSSProperties;
  links?: IHealthLink[];
  streamSource?: IStreamSource;
  teamDataSources?: any[];
  teamTitle?: string;
  teamNote?: string;
  teamNoteForEmail?: string;
  streamEnabled?: boolean;
  metricSetName?: string;
  metricSet?: number;
  doNotShowMetricSetName?: boolean;
  disabled?: boolean;
}

export interface IStreamSource {
  runFrequency?: string;
  runStartTime?: string;
}

export interface IHealthLink {
  url: string;
  name?: string;
}

export interface IOkr {
  id?: string;
  name: string;
  owner: string;
  slaNote?: string;
  target?: number;
  targetOverride?: string;
  targetTemplate?: string;
  targetDigit?: number;
  valueDisplayType?: ContentDisplayType;
  valueOverrides?: IOkrValueOverride[];
}

export interface IOkrValueOverride {
  startTime: string;
  endTime: string;
  value?: number;
  valueOverride?: string;
  valueTemplate?: string;
  valueColor?: string;
  valueDisplayType?: ContentDisplayType;
  valueDigit?: number;
  lastValue?: number;
  lastValueOverride?: string;
  lastValueTemplate?: string;
  lastValueColor?: string;
  lastValueDisplayType?: ContentDisplayType;
  lastValueDigit?: number;
  changeValueOverride?: string;
  changeValueTemplate?: string;
  changeValueColor?: string;
  changeValueDisplayType?: ContentDisplayType;
  changeValueDigit?: number;
  changeValueChevronType?: ValueChevronType;
  comment?: string;
}

export interface IOkrMetric extends IOkrValueOverride {
  id?: string;
}

export interface IFmeCloseIssue {
  createdDate: string;
  issueOwner?: string;
  area?: string;
  issue?: string;
  resolutionDate?: string;
  status?: string;
}

export interface IUpStream {
  teamId: string;
  streamId?: string;
}

export interface ISearchResultItem {
  link: string;
  title: string;
  description?: string;
  tags?: string[];
}

export enum SubscriptionObjectType {
  Control = "Control",
  Entity = "Entity",
  HealthDashboard = "HealthDashboard",
  IncidentView = "IncidentView",
}

export enum SubscriptionNotificationMethodType {
  Email = "Email",
  IcM = "IcM",
}

export enum ParameterDataType {
  date = "date",
  email = "email",
  text = "text",
}

export enum DataTypeOptions {
  date = "date",
  number = "number",
  boolean = "boolean",
  string = "",
}

export enum SubscriptionEventNameType {
  ControlFail = "CommerceRadar.Base.Control.Fail",
  ControlPass = "CommerceRadar.Base.Control.Pass",
  ControlBadData = "CommerceRadar.Base.Control.BadData",
  ControlUserAction = "CommerceRadar.Base.Control.UserAction",
  EntitySummary = "CommerceRadar.Base.Entity.Summary",
  HealthMonthCloseStatus = "CommerceRadar.Base.Health.MonthCloseStatus",
  HealthExecCloseRbpStatus = "CommerceRadar.Base.Health.ExecCloseRbpStatus",
  IncidentView = "CommerceRadar.Base.IncidentView",
  IncidentViewHygiene = "CommerceRadar.Base.IncidentView.Hygiene",
}

export enum ValueChevronType {
  none = "none",
  up = "up",
  down = "down",
}
