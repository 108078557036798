import React, { CSSProperties } from "react";
import classNames from "./ContactLink.module.scss";

export interface IContactLinkProps {
  alias: string | string[];
  messageRecipients?: string;
  messageSubject?: string;
  messageBody?: string;
  compact?: boolean;
  className?: string;
  style?: CSSProperties;
}

export class ContactLink extends React.Component<IContactLinkProps> {
  render() {
    const { alias, compact = true, className, style } = this.props;

    if (!alias) return null;

    let contacts = typeof alias === "string" ? alias.split(",") : alias;

    contacts = contacts.map((contact) => {
      const domainIndex = contact.indexOf("@");
      return compact && domainIndex >= 0 ? contact.substring(0, domainIndex) : contact.trim();
    });

    return (
      <div className={`${classNames.root} ${className}`}>
        {contacts.map((contact, index) => (
          <div className={classNames.contact} key={contact + index}>
            <a href={this.getHref(contact)} style={style} tabIndex={0}>
              {contact}
            </a>
          </div>
        ))}
      </div>
    );
  }

  getHref = (contact: string) => {
    const { alias, messageRecipients, messageSubject, messageBody } = this.props;

    let mailToUrl = `mailto:${contact || messageRecipients || alias}?`;

    messageSubject && (mailToUrl += `subject=${encodeURIComponent(messageSubject)}`);
    messageBody && (mailToUrl += `&body=${encodeURIComponent(messageBody)}`);

    return mailToUrl;
  };
}

export default ContactLink;
