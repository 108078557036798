import { IField, FieldType } from "../../../shared/components/DataForm";
import { IDevOpsView, IDevOpsOrg } from "../../common/interfaces";
import { getColumnFields } from "../common/helper";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { DevOpsViewFilterType } from "../../works/helper";

export const defaultDevOpsView: IDevOpsView = {
  id: "new",
  name: "",
  soxCompliance: true,
};

export const getFilterTypeOptions = () => {
  let filterTypes = [];

  for (var filterType in DevOpsViewFilterType) {
    if (isNaN(Number(filterType))) {
      filterTypes.push({ key: DevOpsViewFilterType[filterType], text: filterType });
    }
  }

  return filterTypes;
};

export const getFields = (devOpsView: IDevOpsView, devOpsOrgs?: IDevOpsOrg[]): IField[] => {
  if (!devOpsView) return null;

  const devOpsOrgOptions = [{ key: "", text: "" }];

  devOpsOrgs && devOpsOrgs.forEach((devOpsOrg) => devOpsOrgOptions.push({ key: devOpsOrg.name, text: devOpsOrg.name }));

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "DevOps View ID",
      value: devOpsView.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "name",
      label: "View Name",
      required: true,
      width: "300px",
      value: devOpsView.name,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: devOpsView.owner,
      width: "200px",
      required: true,
      hints: "Alias of subscription owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: devOpsView.editors,
      required: true,
      lastInRow: true,
      allowFullEditor: true,
      hints: "Comma separated list of aliases who can edit this subscripton",
    },
    {
      fieldName: "description",
      label: "Description",
      width: "100%",
      value: devOpsView.description,
      allowFullEditor: true,
    },
    {
      fieldName: "query",
      label: "Query",
      width: "100%",
      value: devOpsView.query,
      allowFullEditor: true,
      hints:
        "Specify your WIQL query statement. For more info, see https://docs.microsoft.com/en-us/azure/devops/boards/queries/wiql-syntax",
    },
    {
      fieldName: "includeDescendants",
      fieldType: FieldType.boolean,
      label: "Include Descendants",
      value: devOpsView.includeDescendants,
    },
    {
      fieldName: "isAllGroupsCollapsed",
      fieldType: FieldType.boolean,
      label: "All Groups Collapsed",
      value: devOpsView.isAllGroupsCollapsed,
      hints: "Check to collapse all groups by default",
    },
    {
      fieldName: "testOnly",
      fieldType: FieldType.boolean,
      label: "Test Only",
      value: devOpsView.testOnly,
      hints: "Check to hide this view in Production",
    },
    {
      fieldName: "isFeatureRequests",
      fieldType: FieldType.boolean,
      label: "Feature Requests",
      value: devOpsView.isFeatureRequests,
      hints: "Check if this DevOps View should be considered under Feature Requests",
    },
    {
      fieldName: "orgName",
      fieldType: FieldType.enumeration,
      label: "DevOps Organization",
      options: devOpsOrgOptions,
      value: devOpsView.orgName,
      hints: "By default, it uses Microsoft DevOps org",
    },
    { ...getColumnFields(), collapsed: false, itemDisplayView: ItemDisplayView.list },
    {
      ...getColumnFields(),
      fieldName: "parentColumns",
      label: "Parent Columns",
      collapsed: false,
      itemDisplayView: ItemDisplayView.list,
    },
    {
      fieldName: "filters",
      fieldType: FieldType.items,
      label: "Filters",
      itemTypeName: "Filter",
      value: devOpsView.filters,
      itemDisplayView: ItemDisplayView.list,
      collapsed: false,
      fields: [
        {
          fieldName: "filterType",
          fieldType: FieldType.enumeration,
          label: "Filter Type",
          options: getFilterTypeOptions(),
          required: true,
        },
        {
          fieldName: "fieldName",
          label: "Field Name",
          required: true,
        },
        {
          fieldName: "defaultValue",
          label: "Default Value",
          width: "300px",
          visible: "this.filterType !== 'Toggle'",
        },
        {
          fieldName: "defaultValue",
          fieldType: FieldType.boolean,
          label: "Default Checked",
          visible: "this.filterType === 'Toggle'",
        },
        {
          fieldName: "options",
          fieldType: FieldType.json,
          label: "Options",
          lastInRow: true,
          visible: "this.filterType === 'Dropdown'",
          hints: 'Array of dropdown option items, with key and text fields, e.g. [{"key": 123, "text": "Option 1"}]',
        },
        {
          fieldName: "multiSelect",
          fieldType: FieldType.boolean,
          label: "Multi-Select",
          visible: "this.filterType === 'Dropdown'",
          hints: "Allow multiple selections of values",
        },
        {
          fieldName: "filterFields",
          fieldType: FieldType.json,
          label: "Filter Fields",
          lastInRow: true,
          visible: "this.filterType === 'Dropdown'",
          hints:
            'Array of field names used as filtering values for the dropdown with fieldName and displayName fields, e.g. [{"fieldName": "System.State", "displayName": "State"}]\nWhen this field is specified, filtering is applied to the existing result set instead of the query.',
        },
        {
          fieldName: "onText",
          label: "On Text",
          visible: "this.filterType === 'Toggle'",
        },
        {
          fieldName: "onValue",
          label: "On Value",
          visible: "this.filterType === 'Toggle'",
        },
        {
          fieldName: "offText",
          label: "Off Text",
          visible: "this.filterType === 'Toggle'",
        },
        {
          fieldName: "offValue",
          label: "Off Value",
          visible: "this.filterType === 'Toggle'",
        },
      ],
    },
  ];
};
