import React from "react";
import { connect } from "react-redux";
import AppModuleHeader from "../common/AppModuleHeader";
import OkrList from "./OkrList";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { actionCreator } from "../duck";
import { actionCreator as appActionCreator } from "../../app/duck";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { Spinner } from "@fluentui/react/lib/Spinner";
import classNames from "./OkrHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { IOkrMetric, ITeam, ITile } from "../common/interfaces";
import { getCommandBarProps } from "./helper";
import { RouteComponentProps } from "react-router-dom";
import { ReportBase } from "../common/ReportBase";
import MonthDropdown from "../../shared/components/Visual/MonthDropdown";

export interface IMatchParams {
  teamName?: string;
}
export interface IOkrHomeStateProps {
  teams: ITeam[];
  teamTiles: ITile[];
  teamOkrMetrics: IOkrMetric[];
  selectedTeamId: string;
  teamSelectedDate: string;
  loadingTeams: boolean;
  loadingTeamTiles: boolean;
  loadingTeamOkrMetrics: boolean;
  isSmallScreen: boolean;
}

export interface IOkrHomeDispatchProps {
  selectLeftNavLinkByUrl: (url: string) => void;
  loadTeams: (refreshData: boolean, callback: Function) => void;
  loadTeamTiles: (selectedTeamId?: string, reportId?: string, refreshData?: boolean) => void;
  loadTeamOkrMetrics: (selectedTeamId?: string, refreshData?: boolean) => void;
  changeTeamSelectedKey: (selectedTeamId: string) => void;
  changeTeamSelectedDate: (selectedDate: string) => void;
  downloadReportDataToExcel: (reportId: string) => void;
}

export interface IOkrHomeProps extends IOkrHomeStateProps, IOkrHomeDispatchProps, RouteComponentProps<IMatchParams> {}

export class OkrHome extends React.Component<IOkrHomeProps> {
  componentDidMount() {
    const { match, loadTeams, loadTeamTiles, loadTeamOkrMetrics, selectLeftNavLinkByUrl } = this.props;

    loadTeams(false, (teams) => {
      if (teams && match?.params?.teamName) {
        let selectedTeam = teams.find((team) => team.name?.toLowerCase() === match?.params?.teamName?.toLowerCase());

        selectedTeam && this.onTeamChange(selectedTeam.id);
      } else {
        loadTeamOkrMetrics();
        loadTeamTiles();
      }
    });

    selectLeftNavLinkByUrl(leftNavUrls.all.okr);
  }

  render() {
    const {
      teams,
      teamTiles,
      teamOkrMetrics,
      teamSelectedDate,
      loadingTeams,
      loadingTeamTiles,
      loadingTeamOkrMetrics,
      selectedTeamId,
      changeTeamSelectedDate,
    } = this.props;

    if (loadingTeams || loadingTeamOkrMetrics) {
      return <Spinner className={classNames.spinner} label="Loading teams data..." role="alert" />;
    }

    if (!teams?.length) {
      return <div className={classNames.info}>No team data is found.</div>;
    }

    var selectedTeam = teams.find((team) => team.id === selectedTeamId);
    var report = selectedTeam?.report;

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader commandBarProps={getCommandBarProps(this.props, report)} />
        <MonthDropdown selectedKey={teamSelectedDate} onValueSelect={(name, value) => changeTeamSelectedDate(value)} />
        <Pivot className={classNames.pivot} selectedKey={selectedTeamId} onLinkClick={this.onPivotLinkClick}>
          {teams
            .filter((team: ITeam) => !team.ignoreOkrs)
            .map((team: ITeam) => {
              if (team.id === selectedTeamId) {
                team["okrMetrics"] = teamOkrMetrics;
              }
              return (
                <PivotItem key={team.id} itemKey={team.id} className={classNames.pivotItem} headerText={team.name}>
                  <OkrList team={team} selectedDate={teamSelectedDate} />
                </PivotItem>
              );
            })}
        </Pivot>
        <ReportBase report={report} tiles={teamTiles} loading={loadingTeamTiles} className={classNames.report} />
      </div>
    );
  }

  onTeamChange = (selectedTeamId) => {
    const { loadTeamTiles, loadTeamOkrMetrics, changeTeamSelectedKey } = this.props;
    changeTeamSelectedKey(selectedTeamId);
    loadTeamOkrMetrics(selectedTeamId);
    loadTeamTiles(selectedTeamId);
  };

  onPivotLinkClick = (item: PivotItem) => {
    const newSelectedTeamId = item.props.itemKey;

    this.onTeamChange(newSelectedTeamId);
  };
}

const mapStateToProps = (state: IState): IOkrHomeStateProps => ({
  teams: state.modules.teams,
  teamSelectedDate: state.modules.team_selected_date,
  loadingTeams: state.modules.loading_config_items,
  loadingTeamTiles: state.modules.loading_team_tiles,
  teamTiles: state.modules.team_tiles,
  selectedTeamId: state.modules.selected_team_id,
  teamOkrMetrics: state.modules.team_okr_metrics,
  loadingTeamOkrMetrics: state.modules.loading_team_okr_metrics,
  isSmallScreen: state.app.is_small_screen,
});

const mapDispatchToProps: IOkrHomeDispatchProps = {
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  loadTeams: actionCreator.loadTeamConfigItems,
  loadTeamTiles: actionCreator.loadTeamTiles,
  loadTeamOkrMetrics: actionCreator.loadTeamOkrMetrics,
  changeTeamSelectedKey: actionCreator.changeTeamSelectedKey,
  changeTeamSelectedDate: actionCreator.changeTeamSelectedDate,
  downloadReportDataToExcel: actionCreator.downloadReportDataToExcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(OkrHome);
