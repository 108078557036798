import React, { useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { TextField } from "@fluentui/react/lib/TextField";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { GraphSearchFilterButtons } from "./GraphSearchFilterButtons";
import classNames from "./GraphSearch.module.scss";
import { ISearchField, ISearchFilter } from "../common/interfaces";
import { IGraphSearchFilterActions } from "./helper";
import { Icon } from "@fluentui/react/lib/Icon";

export interface IGraphSearchEntityProps extends IGraphSearchFilterActions {
  selectedEntityType: string;
  entityTypeOptions: IDropdownOption[];
  selectedFilterIndexes: number[];
  searchFields: ISearchField[];
  searchFilters: ISearchFilter[];
  defaultSearchFilters: ISearchFilter[];
  searchBatchSize: number;
  searchRandomTop: boolean;
  setSearchEntityType: (entityType: string) => void;
  onSearchEntity: () => void;
  onChangeSearchBatchSize: (size: number) => void;
  onChangeSearchRandomTop: (randomTop: boolean) => void;
}

export const GraphSearchEntity = ({
  selectedEntityType,
  entityTypeOptions,
  selectedFilterIndexes,
  searchFields,
  searchFilters,
  defaultSearchFilters,
  searchBatchSize,
  searchRandomTop,
  setSearchEntityType,
  onSearchEntity,
  onAddFilterClick,
  onEditFilterClick,
  onRemoveFilterClick,
  onGroupFilterClick,
  onUngroupFilterClick,
  onClearAllFiltersClick,
  onRestoreFiltersClick,
  onChangeSearchBatchSize,
  onChangeSearchRandomTop,
}: IGraphSearchEntityProps) => {
  const [isAdvancedOptionExpanded, setIsAdvancedOptionExpanded] = useState(false);

  const onTopNChange = (ev, newValue: string) => {
    if (!isNaN(Number(newValue))) {
      onChangeSearchBatchSize(Number(newValue));
    }
  };

  return (
    <div className={classNames.searchInputs}>
      <Dropdown
        className={classNames.typeDropdown}
        label="Entity Type"
        options={entityTypeOptions}
        selectedKey={selectedEntityType}
        placeholder="Select Entity Type"
        onChange={(ev, option) => setSearchEntityType(option.key.toString())}
      />
      <PrimaryButton
        className={classNames.searchButton}
        text="Find Entities"
        onClick={onSearchEntity}
        disabled={!selectedEntityType}
      />
      <GraphSearchFilterButtons
        searchFields={searchFields}
        searchFilters={searchFilters}
        defaultSearchFilters={defaultSearchFilters}
        onAddFilterClick={onAddFilterClick}
        onEditFilterClick={onEditFilterClick}
        onRemoveFilterClick={onRemoveFilterClick}
        onGroupFilterClick={onGroupFilterClick}
        onUngroupFilterClick={onUngroupFilterClick}
        onClearAllFiltersClick={onClearAllFiltersClick}
        onRestoreFiltersClick={onRestoreFiltersClick}
        selectedFilterIndexes={selectedFilterIndexes}
      />
      <Icon
        className={classNames.advancedOptionsIcon}
        iconName={isAdvancedOptionExpanded ? "DoubleChevronLeft" : "DoubleChevronRight"}
        title={`${isAdvancedOptionExpanded ? "Hide" : "Show"} Advanced Options`}
        onClick={() => setIsAdvancedOptionExpanded(!isAdvancedOptionExpanded)}
      />
      {isAdvancedOptionExpanded && (
        <>
          <TextField
            label="Top N"
            className={classNames.topNTextbox}
            value={searchBatchSize.toString()}
            onChange={onTopNChange}
          />
          <Toggle
            label="Random"
            className={classNames.randomTopToggle}
            checked={!!searchRandomTop}
            onText="On"
            offText="Off"
            title="Return randomized result of top N items"
            onChange={(ev, checked) => onChangeSearchRandomTop(checked)}
          />
        </>
      )}
    </div>
  );
};
