import React, { useCallback, useEffect, useState } from "react";
import classNames from "./AuditOfAuditsPage.module.scss";
import { ConfigItemType, fetchConfigItem, fetchControlResults, fetchControls } from "../../api/radarApi";
import { ChoiceGroup, Icon, Spinner } from "@fluentui/react";
import { getFullDateWithShortRelativeTime } from "../../shared/utilities/miscHelper";
import { controlType, getOnboardingStatusText } from "../controls/HealthReport.helper";
import ProgressBar from "../../shared/components/Visual/ProgressBar";

enum ViewType {
  FullView = "Full View",
  OnboardStatus = "Onboard Status",
  PendingOffline = "Pending/Offline",
  Sox = "SOX",
  Fail = "Fail",
}

const choiceGroupStyles = {
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "-10px",
    marginRight: "10px",
    whiteSpace: "nowrap",
  },
};

export interface IAuditOfAuditsProps {
  showOnboardStatus?: boolean;
}

export const AuditOfAudits = (props: IAuditOfAuditsProps) => {
  const { showOnboardStatus = false } = props;
  const [controlMetadata, setControlMetadata] = useState<any>();
  const [liveControls, setLiveControls] = useState<any[]>();
  const [stagingControls, setStagingControls] = useState<any[]>();
  const [controlResults, setControlResults] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [selectedViewType, setSelectedViewType] = useState<string>(ViewType.FullView);

  useEffect(() => {
    setLoading(true);
    fetchConfigItem(ConfigItemType.AppSettings, "ControlMetadata", null, true)
      .then((configItem: any) => {
        setControlMetadata(configItem?.settings);

        fetchControls(true, true) // Retrieve staging controls.
          .then((controls: any) => {
            setStagingControls(controls);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });

        fetchControls(true, false) // Retrieve live controls.
          .then((controls: any) => {
            setLiveControls(controls);
            fetchControlResults(true, false)
              .then((controlResults: any) => {
                setControlResults(controlResults); // Retrieve live control results.
              })
              .catch((error) => setError(error))
              .finally(() => setLoading(false));
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const getControlCategoryContent = (kpiType, kpiSubType) => {
    return controlMetadata?.controlCategories?.map((category) => {
      let stagingControlsForCategory = stagingControls?.filter(
          (control) =>
            control.controlCategory === category.id &&
            control.financialKpiType === kpiType.id &&
            control.financialKpiSubType === kpiSubType.id
        ),
        liveControlsForCategory = liveControls?.filter(
          (control) =>
            control.controlCategory === category.id &&
            control.financialKpiType === kpiType.id &&
            control.financialKpiSubType === kpiSubType.id
        );

      const onboardControls = showOnboardStatus
        ? liveControlsForCategory?.filter((control) => isOnboarded(control))
        : null;

      const controlCount = stagingControlsForCategory?.length || 0; // denominator is always all staging controls.
      const onboardCount = onboardControls?.length || 0; // numerator is based on live controls that are onboarded.
      const onboardPercent = Math.round((onboardCount * 100) / controlCount);

      const targetControls = showOnboardStatus ? stagingControlsForCategory : liveControlsForCategory;

      return (
        <td className={classNames.controlsCell}>
          {showOnboardStatus &&
            selectedViewType !== ViewType.PendingOffline &&
            selectedViewType !== ViewType.Fail &&
            selectedViewType !== ViewType.Sox &&
            controlCount > 0 && (
              <div
                className={classNames.onboardingStatus}
                title={`${onboardPercent}% of such controls are "In Production" and not offline in Live mode.`}
              >
                <div className={classNames.leftPane}>
                  {onboardCount} of {controlCount} (<span className={classNames.percentText}>{onboardPercent}%</span>)
                </div>
                <div className={classNames.rightPane}>
                  Onboarded &nbsp;
                  <ProgressBar progressPercent={onboardPercent} />
                </div>
              </div>
            )}
          {selectedViewType !== ViewType.OnboardStatus && (
            <div className={classNames.controls}>
              {targetControls?.map((control) => {
                let controlResult = controlResults?.find((result) => result.controlId === control.id);

                if (!showOnboardStatus && (!controlResult?.resultStatus || !isOnboarded(control))) {
                  return null;
                }

                if (selectedViewType === ViewType.PendingOffline && isOnboarded(control)) {
                  return null;
                }

                if (selectedViewType === ViewType.Fail && controlResult?.resultStatus.toLowerCase() !== "fail") {
                  return null;
                }

                if (selectedViewType === ViewType.Sox && !control.soxCompliance) {
                  return null;
                }

                const liveControl = liveControlsForCategory?.find((liveControl) => liveControl.id === control.id);

                const statusColor =
                  control.type === controlType.External
                    ? "#2b88d8"
                    : !isOnboarded(control)
                    ? "#aaa"
                    : controlResult?.resultStatus.toLowerCase() === "pass"
                    ? "green"
                    : controlResult?.resultStatus.toLowerCase() === "fail"
                    ? "red"
                    : "#aaa";

                let tooltip = `Control ID: ${control.id}\nControl Result: ${controlResult?.resultStatus || undefined}`;

                if (showOnboardStatus) {
                  tooltip += `\nLive Offline Status: ${
                    liveControl?.offline || false
                  }\nLive Onboarding Status: ${getOnboardingStatusText(liveControl)}`;
                }

                tooltip += `\nLast Control Result: ${
                  controlResult?.createdOn && getFullDateWithShortRelativeTime(controlResult?.createdOn)
                }`;

                return (
                  <div className={classNames.control}>
                    <div className={classNames.status} style={{ backgroundColor: `${statusColor}` }} />
                    {control.soxCompliance && (
                      <div className={classNames.soxTag} title="This is a SOX compliance control">
                        SOX
                      </div>
                    )}
                    <a
                      className={classNames.controlName}
                      href={`/#/control/${control.id}${!liveControl ? "?staging" : ""}`}
                      target="_blank"
                      rel="noreferrer"
                      title={tooltip}
                    >
                      {control.shortName || control.name}
                    </a>
                    {control.adoLink && !isOnboarded(control) && (
                      <a
                        className={classNames.adoLink}
                        href={control.adoLink}
                        target="_blank"
                        rel="noreferrer"
                        title="Related ADO"
                      >
                        (ADO)
                      </a>
                    )}
                    {control.type === controlType.External && (
                      <a href={control.externalUrl} target="_blank" rel="noreferrer" title="External Control Link">
                        <Icon className={classNames.linkIcon} iconName="Link" />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </td>
      );
    });
  };

  const isOnboarded = useCallback((control) => control.onboardingStatus === 4 && !control.offline, []);

  return (
    <div className={classNames.content}>
      {loading && <Spinner label="Loading data..." />}
      {!loading && error && <div className={classNames.error}>{error}</div>}
      {!loading && !error && stagingControls?.length && (
        <>
          <div className={classNames.topArea}>
            <div className={classNames.legends}>
              <span className={classNames.dot} style={{ backgroundColor: "green" }} />
              Pass
              <span className={classNames.dot} style={{ backgroundColor: "red" }} />
              Fail
              {showOnboardStatus && (
                <>
                  <span className={classNames.dot} style={{ backgroundColor: "#aaa" }} />
                  Pending Development / Offline
                  <span className={classNames.dot} style={{ backgroundColor: "#2b88d8" }} />
                  External
                </>
              )}
            </div>
            <div className={classNames.actions}>
              <ChoiceGroup
                className={classNames.toggle}
                styles={choiceGroupStyles}
                options={Object.values(ViewType)
                  .filter((value) => showOnboardStatus || value !== ViewType.OnboardStatus)
                  .map((value) => ({ key: value, text: value }))}
                selectedKey={selectedViewType}
                onChange={(ev, option) => setSelectedViewType(option.key)}
              />
            </div>
          </div>
          <table className={classNames.table} cellSpacing={0}>
            <tr>
              <th>Financial KPI Type</th>
              <th>Sub Type</th>
              {controlMetadata?.controlCategories?.map((category) => (
                <th>{category.name}</th>
              ))}
            </tr>
            {controlMetadata?.financialKpiTypes?.map((kpiType) => (
              <>
                <tr>
                  <td className={classNames.type} rowSpan={kpiType.subTypes?.length}>
                    {kpiType.name}
                  </td>
                  {kpiType.subTypes?.length && (
                    <>
                      <td className={classNames.subType}>{kpiType.subTypes[0].name}</td>
                      {getControlCategoryContent(kpiType, kpiType.subTypes[0])}
                    </>
                  )}
                </tr>
                {kpiType.subTypes?.length > 1 &&
                  kpiType.subTypes.map((subType, index) => {
                    if (index >= 1) {
                      return (
                        <tr>
                          <td className={classNames.subType}>{subType.name}</td>
                          {getControlCategoryContent(kpiType, subType)}
                        </tr>
                      );
                    }
                    return null;
                  })}
              </>
            ))}
          </table>
        </>
      )}
    </div>
  );
};

export default AuditOfAudits;
