import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyControlList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListStateProps,
  IConfigItemListDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";
import { IState } from "../../../reducers/interfaces";

export class MyControlList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Control"
        appInsightsPageName="MyControlListPage"
        leftNavUrl={leftNavUrls.management.control}
      />
    );
  }
}

const mapStateToProps = (state: IState): IConfigItemListStateProps => ({
  ...mapStateToPropsBase(state),
  loading: state.modules.loading_controls,
  configItems: state.modules.controls,
});

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadControlsOnly,
  deleteConfigItem: actionCreator.deleteControl,
  publishConfigItem: actionCreator.publishControlConfigItem,
  unpublishConfigItem: actionCreator.unpublishControlConfigItem,
  revertConfigItem: actionCreator.revertControlConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyControlList);
