import React, { useState, useEffect } from "react";
import { ISearchFilter } from "../common/interfaces";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { Icon } from "@fluentui/react/lib/Icon";
import classNames from "./GraphSearch.module.scss";

export const bubbleShownFlagName = "filterListBubbleShown";
export interface IGraphSearchFilterListProps {
  searchFilters: ISearchFilter[];
  selectedFilterIndexes: number[];
  showNonIndexedAttributeWarning: boolean;
  selectSearchFilter: (index: number) => void;
  onTeachingBubbleDismiss: () => void;
}

export const GraphSearchFilterList = ({
  searchFilters,
  selectedFilterIndexes,
  showNonIndexedAttributeWarning,
  selectSearchFilter,
  onTeachingBubbleDismiss,
}: IGraphSearchFilterListProps) => {
  const [showTeachingBubble, setShowTeachingBubble] = useState(false);

  useEffect(() => {
    if (searchFilters?.length && (!localStorage[bubbleShownFlagName] || showNonIndexedAttributeWarning)) {
      setTimeout(() => {
        setShowTeachingBubble(true);
        localStorage[bubbleShownFlagName] = true;
      }, 2000);
    } else if (showTeachingBubble) {
      setTimeout(() => setShowTeachingBubble(false), 0);
    }
  });

  if (searchFilters?.length <= 0) return null;

  let index = 0;

  return (
    <div id="filterList" className={classNames.filterList}>
      {searchFilters?.map((filter) => {
        if (filter.filters?.length) {
          let groupFilters = [];

          filter.filters.forEach((groupFilter, groupIndex) => {
            let showStartGroupOperator = groupIndex === 0,
              showEndGroupOperator = groupIndex === filter.filters.length - 1,
              filterClassNames = getFilterClassNames(selectedFilterIndexes, index);

            groupFilters.push(
              <GraphSearchFilter
                {...{
                  filter: groupFilter,
                  index: index++,
                  filterClassNames,
                  selectSearchFilter,
                  showStartGroupOperator,
                  showEndGroupOperator,
                }}
              />
            );
          });

          return groupFilters;
        }

        const filterClassNames = getFilterClassNames(selectedFilterIndexes, index);

        return (
          <GraphSearchFilter
            key={`searchFilter_${index}`}
            {...{
              filter,
              index: index++,
              filterClassNames,
              selectSearchFilter,
            }}
          />
        );
      })}
      {showTeachingBubble && (
        <TeachingBubble
          target="#filterList"
          primaryButtonProps={{ children: "Got it", onClick: onTeachingBubbleDismiss }}
          onDismiss={onTeachingBubbleDismiss}
        >
          {showNonIndexedAttributeWarning
            ? "You have selected a non-indexed attribute as filter. Query performance could be slow."
            : "Hint: Select filter(s) to edit, delete or grouping"}
        </TeachingBubble>
      )}
    </div>
  );
};

export const GraphSearchFilter = ({
  filter,
  index,
  filterClassNames,
  selectSearchFilter,
  showStartGroupOperator = false,
  showEndGroupOperator = false,
}) => {
  if (!filter) return null;
  return (
    <>
      {index > 0 && <span className={classNames.logicalOperator}>{filter.logicalOperator || "and"}</span>}
      {showStartGroupOperator && <span className={classNames.groupOperator}>(</span>}
      <div className={filterClassNames.join(" ")} onClick={() => selectSearchFilter(index)}>
        {filter.attribute} {filter.operatorText || filter.operator} <b>{filter.value}</b>
        {filter.icon && <Icon iconName="InfoSolid" className={classNames.filterIcon} {...filter.icon} />}
      </div>
      {showEndGroupOperator && <span className={classNames.groupOperator}>)</span>}
    </>
  );
};

const getFilterClassNames = (selectedFilterIndexes, index) => {
  let filterClassNames = [classNames.filter];

  if (selectedFilterIndexes?.indexOf(index) >= 0) {
    filterClassNames.push(classNames.selected);
  }

  return filterClassNames;
};
