import React from "react";

export interface IStyleClass {
  [className: string]: React.CSSProperties;
}

export interface IStyleClassProps {
  styleClass: IStyleClass;
}

export class StyleClass extends React.Component<IStyleClassProps> {
  render() {
    const { styleClass } = this.props;

    return (
      <style>
        {styleClass &&
          Object.keys(styleClass).map(
            (className) => `.${className} ${JSON.stringify(styleClass[className]).replace(/"/g, "").replace(/,/g, ";")}`
          )}
      </style>
    );
  }
}

export default StyleClass;
