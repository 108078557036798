import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  IDialogProps,
  Link,
  List,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import classNames from "./ItemsPicker.module.scss";
import { IItem } from "./ItemsPicker";

export interface IItemsPickerDialog extends IDialogProps {
  items: IItem[];
  selectedKeys: string;
  label?: string;
  hideListActions?: boolean;
  showSearchbox?: boolean;
  onCommitClick: (selectedKeys: string) => void;
}

export const ItemsPickerDialog = (props: IItemsPickerDialog) => {
  const { items, selectedKeys, label, hideListActions, showSearchbox, onDismiss, onCommitClick } = props;
  const [newSelectedKeys, setNewSelectedKeys] = useState<string>(selectedKeys);
  const [searchText, setSearchText] = useState<string>();
  const selectedKeyValues = useMemo(() => newSelectedKeys?.split(","), [newSelectedKeys]);
  const dialogContentProps = {
    title: "Select " + (label || "Items"),
  };

  useEffect(() => document.querySelector("#itemListPane")?.scrollTo({ top: 0 }), [items, newSelectedKeys]);

  const finalItems = useMemo(
    () =>
      items?.map((item) => ({
        ...item,
        selected: selectedKeyValues?.indexOf(item?.key?.toString()) >= 0,
      })),
    [items, selectedKeyValues]
  );

  const onItemCheckboxChange = (item: IItem, checked: boolean) => {
    let newKeyValues = [...selectedKeyValues],
      itemKey = item.key.toString();

    if (checked) {
      newKeyValues.push(itemKey);
    } else {
      let index = newKeyValues.indexOf(itemKey);
      newKeyValues.splice(index, 1);
    }

    setNewSelectedKeys(newKeyValues.join(","));
  };

  const onOkClick = () => {
    onCommitClick && onCommitClick(newSelectedKeys);
    onDismiss && onDismiss();
  };

  const onSelectAllClick = () => {
    setNewSelectedKeys(items?.map((item) => item.key).join(","));
  };

  const onClearAllClick = () => {
    setNewSelectedKeys("");
  };

  const onSearchTextChange = (ev, newValue) => {
    setSearchText(newValue);
  };

  const onRenderCell = (item: IItem, index: number): JSX.Element => {
    return (
      <div key={item.key + index.toString()} className={classNames.item}>
        <Checkbox
          label={item.text}
          checked={item.selected}
          onChange={(ev, checked) => onItemCheckboxChange(item, checked)}
        />
      </div>
    );
  };

  return (
    <Dialog
      modalProps={{ className: classNames.dialog }}
      {...props}
      dialogContentProps={dialogContentProps}
      minWidth={540}
    >
      {!hideListActions && (
        <div className={classNames.listActionPane}>
          <Link onClick={onSelectAllClick}>Select all</Link>
          <Link onClick={onClearAllClick}>Clear all</Link>
        </div>
      )}
      {showSearchbox && (
        <TextField
          className={classNames.searchText}
          value={searchText}
          onChange={onSearchTextChange}
          placeholder="Search items"
        />
      )}
      <div id="itemListPane" className={classNames.itemListPane} data-is-scrollable="true">
        <List className={classNames.itemList} items={finalItems} onRenderCell={onRenderCell} />
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onOkClick} text="Ok" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default ItemsPickerDialog;
