import React, { CSSProperties } from "react";
import Tile, { BuiltInCommandBarItemKey } from "./Tile";
import { Visual, isInputField, VisualType } from "../Visual";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { getFormatContent, ContentDisplayType } from "../../utilities/miscHelper";
import classNames from "./VisualTileList.module.scss";
import { ITile } from "../../../appModules/common/interfaces";
import { getColumnOptionsButton } from "./helper";

const kpiTileDefaultSize = "160px";
const standardDefaultTileHeight = 300;
const titleCompactHeight = 35;
const titleRegularHeight = 45;
const subTitleHeight = 25;

export interface IVisualTileListProps {
  tiles: any[];
  dataList?: any[];
  loadingTiles?: boolean;
  tileTitleSuffix?: string;
  noDataText?: string;
  defaultHeight?: number | string;
  defaultWidth?: number | string;
  useSubmitButton?: boolean;
  style?: CSSProperties;
  tileStyle?: CSSProperties;
  className?: string;
}

export interface IVisualTileListState {
  tiles: any[];
}

type ISetOnClickButtonFunction = (index: number) => (onClick: () => void) => void;

export class VisualTileList extends React.Component<IVisualTileListProps, IVisualTileListState> {
  state = {
    tiles: this.props.tiles,
  };

  columnOptionsIndex: number = 0;
  onClickColumnOptions: any[] = [];

  onClickColumnOptionsButton = (index) => {
    return () => {
      if (this.onClickColumnOptions?.length && this.onClickColumnOptions.length > index) {
        this.onClickColumnOptions[index]();
      }
    };
  };

  setOnClickColumnOptionsFunction: ISetOnClickButtonFunction = (index: number) => {
    return (onClick: () => void) => {
      // For safe measure, add elements until the correct one is added, then set it
      // Also prevents setting it twice
      while (this.onClickColumnOptions.length <= index) {
        this.onClickColumnOptions.push(() => {
          return; //No Op filler so it doesn't crash
        });
      }
      this.onClickColumnOptions[index] = () => {
        onClick();
      };
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.tiles !== prevProps.tiles) {
      this.setState({ tiles: this.props.tiles });
    }
  }

  render() {
    const { dataList, style, className } = this.props;
    const { tiles } = this.state;

    if (!tiles || !tiles.length) return null;

    let visualTiles = null;

    if (dataList && dataList.length) {
      visualTiles = dataList.map((data, index) => {
        let tile = tiles[index];

        var tileDataSourceData = getTileDataSourceData(tile, tiles, dataList);
        return this.renderTile(tile, tileDataSourceData || data, index);
      });
    } else {
      visualTiles = tiles.map((tile, index) => {
        var tileDataSourceData = getTileDataSourceData(tile, tiles);
        return this.renderTile(tile, tileDataSourceData || tile.data, index);
      });
    }

    return (
      <div className={`${classNames.root} ${className}`} style={style}>
        {visualTiles}
      </div>
    );
  }

  renderTile = (tile, data, index) => {
    if (!tile || tile.visible === false) {
      return null;
    }

    const {
      loadingTiles,
      tileTitleSuffix = "",
      defaultHeight = standardDefaultTileHeight,
      defaultWidth = "auto",
      noDataText,
      useSubmitButton,
      tileStyle = {},
    } = this.props;

    let valueFieldNames = Array.isArray(tile.valueFieldNames)
        ? tile.valueFieldNames
        : tile.valueFieldNames
        ? tile.valueFieldNames.split(",")
        : undefined,
      title = tile.name || tileTitleSuffix ? `${tile.name || ""} ${tileTitleSuffix}` : "",
      subtitle = tile.subtitle;

    if (valueFieldNames && valueFieldNames.length) {
      valueFieldNames = valueFieldNames.map((name: string) => name.trim());
    }

    let tileContent = null,
      key = this.getTileKey(index),
      tileCommandBarItems: { items: any[] };

    if (isInputField(tile.visualType)) {
      tileContent = (
        <div key={key} className={classNames.inputVisual} style={{ ...tileStyle, ...tile.style }}>
          <Visual {...tile} id={`v_${index}`} useSubmitButton={useSubmitButton} />
        </div>
      );
    } else {
      let tileCommands = [];

      if (tile.visualType !== VisualType.kpi) {
        tileCommands.push(BuiltInCommandBarItemKey.showFullScreen);

        if (
          (tile.visualTypeOrig && tile.visualTypeOrig !== VisualType.table) ||
          (!tile.visualTypeOrig && tile.visualType !== VisualType.table)
        ) {
          tileCommands.unshift(BuiltInCommandBarItemKey.changeVisualType);
        }
        if (tile.visualType === VisualType.table) {
          tileCommandBarItems = {
            ...tileCommandBarItems,
            items: [getColumnOptionsButton(this.onClickColumnOptionsButton(index))],
          };
        }
      }

      const defaultTileWidth = tile.visualType === VisualType.kpi ? kpiTileDefaultSize : defaultWidth,
        defaultTileHeight = tile.visualType === VisualType.kpi ? kpiTileDefaultSize : defaultHeight,
        tileWidth = tile.width ? (isNaN(tile.width) ? tile.width : tile.width + "px") : defaultTileWidth,
        tileHeight = tile.height ? (isNaN(tile.height) ? tile.height : tile.height + "px") : defaultTileHeight;

      // Adjust visual height as needed.
      let titleHeight = tile.compact ? titleCompactHeight : titleRegularHeight,
        visualHeight =
          (tile.visualProps && tile.visualProps.height) || Number(tile.height || defaultTileHeight) - titleHeight;
      subtitle && (visualHeight -= subTitleHeight);

      tileContent = (
        <Tile
          {...tile}
          key={key}
          className={classNames.customTile}
          title={title}
          subtitle={subtitle}
          style={{
            width: tileWidth,
            height: tileHeight,
            ...tile.style,
          }}
          commandBarItemKeys={tileCommands}
          commandBarProps={tileCommandBarItems}
          onVisualTypeChange={(visualType) => this.onVisualTypeChange(key, visualType)}
        >
          {loadingTiles && (
            <Spinner
              styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
            />
          )}
          {!loadingTiles && (
            <Visual
              {...tile}
              setOnClickColumnOptionsFunction={this.setOnClickColumnOptionsFunction(index)}
              id={key}
              key={`v_${index}`}
              data={data}
              noDataText={noDataText}
              valueFieldNames={valueFieldNames}
              visualProps={{
                ...tile.visualProps,
                height: visualHeight,
                onXAxisTickFormat: (v) => getFormatContent(v, tile.categoryDisplayType),
                onYAxisTickFormat: (v) => getFormatContent(v, tile.valueDisplayType || ContentDisplayType.shortNumber),
              }}
            />
          )}
        </Tile>
      );
    }

    if (tile.lastInRow) {
      return [tileContent, <div key="lastInRow" className={classNames.lastInRowField} />];
    }
    return tileContent;
  };

  onVisualTypeChange = (key: string, visualType: VisualType) => {
    const { tiles } = this.state;

    const newTiles =
      tiles &&
      tiles.map((tile, index) => {
        if (key === this.getTileKey(index)) {
          return {
            ...tile,
            visualTypeOrig: tile.visualTypeOrig || tile.visualType,
            visualType,
          };
        }
        return tile;
      });

    this.setState({ tiles: newTiles });
  };

  getTileKey = (index: number): string => "ct" + index;
}

const getTileDataSourceData = (tile: ITile, tiles: ITile[], dataList: any[] = null) => {
  // Check if the tile uses "tileDataSource" as data source type, use the data from the target tile.
  if (tile.dataSourceType === "tileDataSource") {
    var targetTileId = tile.targetTileId;

    if (targetTileId) {
      var targetTileIndex = tiles.findIndex((t) => t.tileId === targetTileId);

      if (targetTileIndex >= 0) {
        if (dataList && targetTileIndex < dataList.length) {
          return dataList[targetTileIndex];
        } else {
          return tiles[targetTileIndex].data;
        }
      }
    }
  }

  return null;
};

export default VisualTileList;
