import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ConfigItemType, fetchConfigItem } from "../../api/radarApi";
import { actionCreator } from "../../app/duck";
import { leftNavUrls } from "../../app/LeftNav.helper";
import AppModuleHeader from "../common/AppModuleHeader";
import { getCommandBarProps, getCopilotDataText, getCopilotExtraChatMessages } from "./HealthDashboard.helper";
import { downloadHealthDataToExcel } from "../../api/radarApi";
import HealthDashboard from "./HealthDashboard";
import classNames from "./HealthDashboard.module.scss";
import { appInsights } from "../../app/telemetryHelper";
import { RouteChildrenProps } from "react-router";
import { IState } from "../../reducers/interfaces";
import { IHealthMetricGroup } from "./HealthDashboard.types";
import { AppModuleContext } from "../common/AppModule";
import { AppContext } from "../../app/App";
import { ChatRole, IChatMessage } from "../../shared/components/Chat/Chat.types";
import { shouldRefreshCopilotCloseStatus } from "../common/helper";

const getCopilotInitialMessages = (data): IChatMessage[] => [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  {
    role: ChatRole.system,
    content: `Enter what you would like to know about ${data?.currentMonthName} Month Close, or select one of the following common questions.`,
  },
];

export interface IMatchParams {
  metricGroupId?: string;
}

export interface IHomePageDispatchProps {
  selectLeftNavLinkByUrl: Function;
}

export interface IHealthDashboardPageStateProps {
  hasMsSalesFullAccess: boolean;
  error?: string;
}

export const HealthDashboardPage = (
  props: IHealthDashboardPageStateProps & IHomePageDispatchProps & RouteChildrenProps<IMatchParams>
) => {
  const { selectLeftNavLinkByUrl, match, history, hasMsSalesFullAccess, error } = props;
  const [healthMetricGroups, setHealthMetricGroups] = useState<IHealthMetricGroup[]>([]);
  const defaultMetricGroupId =
    Number(match?.params?.metricGroupId) || (healthMetricGroups?.length && healthMetricGroups[0].id) || 1;
  const [selectedMetricGroupId, setSelectedMetricGroupId] = useState<number>(defaultMetricGroupId);
  const [downloadData, setDownloadData] = useState<any>();
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  const [copilotDataText, setCopilotDataText] = useState<string>();
  const [copilotSuggestions, setCopilotSuggestions] = useState<string[]>([]);
  const [copilotInitialMessageCount, setCopilotInitialMessageCount] = useState<number>();
  const [copilotFollowupMessageCount, setCopilotFollowupMessageCount] = useState<number>();
  const { changeAppModuleState } = useContext(AppModuleContext);
  const { appState, changeAppState } = useContext(AppContext);
  const { featureFlags } = appState;
  const refresh = window.location.hash.indexOf("?refresh") >= 0;
  const selectedMetricGroup = useMemo(
    () => healthMetricGroups?.find((metricGroup) => metricGroup.id === selectedMetricGroupId),
    [healthMetricGroups, selectedMetricGroupId]
  );
  const subscriptionUrl = selectedMetricGroup?.subscriptionUrl;

  useEffect(() => {
    appInsights.trackEvent({ name: "onHealthDashboardPageLoad" });

    if (shouldRefreshCopilotCloseStatus()) {
      setTimeout(() => window.location.reload(), 15 * 60 * 1000); // Reload page every 15 mins.
    }
  }, []);

  useEffect(() => {
    if (downloadData?.currentMonthName) {
      fetchConfigItem(ConfigItemType.AppSettings, "CopilotSuggestions", null, refresh).then((configItem: any) => {
        let appModuleName = leftNavUrls.financialHealth.close.replace(/[^a-zA-Z0-9]/g, ""),
          suggestionsSettings = configItem?.settings[appModuleName];

        if (suggestionsSettings?.suggestions?.length) {
          let suggestions = suggestionsSettings?.suggestions.map((suggestion) => {
            // eslint-disable-next-line
            return suggestion.replace("${this.currentMonthName}", downloadData.currentMonthName);
          });
          setCopilotSuggestions(suggestions);
        }

        if (suggestionsSettings?.initialDisplayCount) {
          setCopilotInitialMessageCount(suggestionsSettings?.initialDisplayCount);
        }

        if (suggestionsSettings?.followupDisplayCount) {
          setCopilotFollowupMessageCount(suggestionsSettings?.followupDisplayCount);
        }
      });
    }
  }, [downloadData?.currentMonthName, refresh]);

  useEffect(() => {
    if (copilotDataText) {
      changeAppModuleState({
        copilotDataText,
        copilotInitialMessages: getCopilotInitialMessages(downloadData),
        copilotSuggestions,
        copilotInitialMessageCount,
        copilotFollowupMessageCount,
        copilotExtraChatMessages: getCopilotExtraChatMessages(downloadData),
      });
    }
  }, [
    copilotDataText,
    downloadData,
    copilotSuggestions,
    copilotInitialMessageCount,
    copilotFollowupMessageCount,
    changeAppState,
    changeAppModuleState,
  ]);

  useEffect(() => {
    selectLeftNavLinkByUrl(leftNavUrls.financialHealth);

    fetchConfigItem(ConfigItemType.AppSettings, "HealthMetricGroups", null, refresh).then((configItem: any) =>
      setHealthMetricGroups(configItem?.settings)
    );
  }, [selectLeftNavLinkByUrl, refresh]);

  const onDownloadDataChange = useCallback((data) => {
    if (data?.metrics?.length && data.metrics[0].tableName) {
      setDownloadData(data);

      data?.currentMonthName && setCopilotDataText(getCopilotDataText(data));
    }
  }, []);

  const onExcelExportClick = useCallback(() => {
    setDownloadingExcel(true);
    downloadHealthDataToExcel(downloadData).finally(() => setDownloadingExcel(false));
  }, [downloadData]);

  const onSubscribeClick = () => window.open(subscriptionUrl, "_blank");

  const onSelectMetricGroupId = useCallback((metricGroupId) => setSelectedMetricGroupId(metricGroupId), []);

  if (error) {
    return <div className={classNames.error}>{error}</div>;
  }

  return (
    <div className={`${classNames.healthDashboardRoot} ${classNames.appModuleContent}`}>
      {featureFlags && !featureFlags.disableHealthDashboard ? (
        <>
          <AppModuleHeader
            headerTextOverride={selectedMetricGroup?.pageHeaderText}
            commandBarProps={getCommandBarProps(
              onExcelExportClick,
              downloadingExcel,
              onSubscribeClick,
              subscriptionUrl
            )}
          />
          <HealthDashboard
            selectedMetricGroupId={selectedMetricGroupId}
            selectedMetricGroup={selectedMetricGroup}
            history={history}
            hasMsSalesFullAccess={hasMsSalesFullAccess}
            healthMetricGroups={healthMetricGroups}
            onDownloadDataChange={onDownloadDataChange}
            onSelectMetricGroupId={onSelectMetricGroupId}
          />
        </>
      ) : featureFlags?.disableHealthDashboard ? (
        <div className={classNames.error}>This feature is currently disabled.</div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  hasMsSalesFullAccess: state.app.login_user_info?.hasMsSalesFullAccess,
  error: state.app.login_user_info?.error,
});

const mapDispatchToProps: IHomePageDispatchProps = {
  selectLeftNavLinkByUrl: actionCreator.selectLeftNavLinkByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthDashboardPage);
