import React, { FormEvent } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import classNames from "./Textbox.module.scss";
import { IconButton } from "@fluentui/react/lib/Button";
import { IVisualProps } from "./Visual";

export interface ITextboxProps extends IVisualProps {
  value?: string;
  showSearchIcon?: boolean;
  showSubmitButton?: boolean;
  submitButtonText?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  onValueSelect?: (value: string) => void;
  onValueSubmit?: (name: string, value: string) => void;
}

export interface ITextboxState {
  value: string;
}

export class Textbox extends React.Component<ITextboxProps, ITextboxState> {
  state = {
    value: this.props.value,
  };

  render() {
    const {
      label,
      width = "auto",
      placeholder,
      showSearchIcon = true,
      multiline,
      rows,
      maxLength,
      showSubmitButton,
      submitButtonText = "Submit",
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classNames.root}>
        <TextField
          label={label}
          value={value}
          style={{ width: width }}
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          maxLength={maxLength}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        {showSearchIcon && (
          <IconButton iconProps={{ iconName: "Search" }} title="Click to search" onClick={this.selectValue} />
        )}
        {showSubmitButton && (
          <IconButton
            iconProps={{ iconName: "SkypeCircleCheck" }}
            title={submitButtonText}
            onClick={this.submitValue}
          />
        )}
      </div>
    );
  }

  onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
    const { showSearchIcon, showSubmitButton, onValueSelect } = this.props;

    this.setState({ value: newValue });

    !showSearchIcon && !showSubmitButton && onValueSelect(newValue);
  };

  onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      this.selectValue();
    }
  };

  selectValue = () => {
    const { onValueSelect } = this.props;
    const { value } = this.state;

    onValueSelect(value);
  };

  submitValue = () => {
    const { filterName, onValueSubmit } = this.props;
    const { value } = this.state;

    onValueSubmit(filterName, value);
  };
}

export default Textbox;
