import React from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import classNames from "./GraphSearch.module.scss";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { GraphSearchMetrics } from "./GraphSearchMetrics";
import { GraphSearchEntity } from "./GraphSearchEntity";
import { GraphSearchEntityLookup } from "./GraphSearchEntityLookup";
import { GraphSearchFilterModal } from "./GraphSearchFilterModal";
import { IEntityMetric, IEntityView, ISearchField, ISearchFilter } from "../common/interfaces";
import { GraphSearchFilterList } from "./GraphSearchFilterList";
import { SearchItemKey, getSearchFilter } from "./helper";
import { IVisualProps } from "../../shared/components/Visual";

export interface IGraphSearchProps {
  className?: string;
}

export interface IGraphSearchStateProps {
  entityTypes: string[];
  allEntityTypes: string[];
  selectedEntityType: string;
  selectedEntityId: string;
  selectedEntityVersion: string;
  startTime?: Date;
  endTime?: Date;
  selectedDateType: string;
  searchItemKey?: string;
  selectedEntityViewId?: string;
  metricSearchFields?: ISearchField[];
  metricSearchFilters?: ISearchFilter[];
  entitySearchFields?: ISearchField[];
  entitySearchFilters?: ISearchFilter[];
  entityMetrics?: IEntityMetric[];
  defaultSearchFilters?: ISearchFilter[];
  entityViews?: IEntityView[];
  searchViewFields?: IVisualProps[];
  searchViewFilters?: ISearchFilter[];
  searchBatchSize?: number;
  searchRandomTop?: boolean;
  isProduction: boolean;
}

export interface IGraphSearchDispatchProps {
  setSearchEntityType: (entityType: string) => void;
  setSearchEntityId: (entityId: string) => void;
  setSearchEntityVersion: (entityVersion: string) => void;
  loadEntity: (entityType: string, entityId: string, entityVersion: string) => void;
  changeSearchEntityStartTime: (startTime: Date) => void;
  changeSearchEntityEndTime: (endTime: Date) => void;
  changeSearchEntityMonth: (date: Date) => void;
  changeSearchEntitySelectedKey: (key: string) => void;
  changeSearchEntityDateType: (dateType: string) => void;
  loadEntityMetrics: (startTime?: Date, endTime?: Date, refresh?: boolean) => void;
  loadFinancialEntities: () => void;
  addSearchFilter: (searchFilter: ISearchFilter) => void;
  editSearchFilter: (searchFilter: ISearchFilter, selectedFilterIndex: number) => void;
  removeSearchFilter: (selectedFilterIndexes: number[]) => void;
  groupSearchFilter: (selectedFilterIndexes: number[]) => void;
  ungroupSearchFilter: (selectedFilterIndexes: number[]) => void;
  clearAllSearchFilters: () => void;
  restoreSearchFilters: () => void;
  searchEntities: () => void;
  changeSearchViewFilter: (viewField: IVisualProps, value) => void;
  changeSearchBatchSize: (size: number) => void;
  changeSearchRandomTop: (randomTop: boolean) => void;
}

export interface IGraphSearchState {
  selectedFilter: ISearchFilter;
  selectedFilterIndexes: number[];
  showSearchFilterModel: boolean;
  showNonIndexedAttributeWarning: boolean;
}

export class GraphSearch extends React.Component<
  IGraphSearchProps & IGraphSearchStateProps & IGraphSearchDispatchProps,
  IGraphSearchState
> {
  state = {
    selectedFilter: null,
    selectedFilterIndexes: [],
    showSearchFilterModel: false,
    showNonIndexedAttributeWarning: false,
  };

  render() {
    const {
      allEntityTypes,
      selectedEntityType,
      selectedEntityId,
      selectedEntityVersion,
      setSearchEntityType,
      setSearchEntityId,
      setSearchEntityVersion,
      className,
      startTime,
      endTime,
      changeSearchEntityStartTime,
      changeSearchEntityEndTime,
      changeSearchEntityMonth,
      changeSearchEntityDateType,
      changeSearchBatchSize,
      changeSearchRandomTop,
      selectedDateType,
      searchItemKey = SearchItemKey.metricSearch,
      selectedEntityViewId,
      metricSearchFields,
      metricSearchFilters,
      entitySearchFields,
      entitySearchFilters,
      defaultSearchFilters,
      entityViews,
      searchViewFields,
      searchViewFilters,
      searchBatchSize,
      searchRandomTop,
      isProduction,
    } = this.props;

    const { selectedFilter, showSearchFilterModel, selectedFilterIndexes, showNonIndexedAttributeWarning } = this.state;

    const entityTypeOptions = allEntityTypes?.map((entityType) => ({
      key: entityType,
      text: entityType,
      ariaLabel: entityType,
    }));

    const selectedEntityView = entityViews?.find((view) => view.id === selectedEntityViewId);

    return (
      <div className={`${classNames.root} ${className}`}>
        <Pivot selectedKey={searchItemKey} className={classNames.pivot} onLinkClick={this.onPivotLinkClick}>
          {!isProduction && (
            <PivotItem
              headerText="Metric Search"
              className={`${classNames.pivotItem} ${classNames.contentBox}`}
              itemKey={SearchItemKey.metricSearch}
            >
              <GraphSearchMetrics
                {...{
                  selectedEntityViewId,
                  selectedEntityType,
                  selectedEntityView,
                  selectedDateType,
                  startTime,
                  endTime,
                  entityTypeOptions,
                  selectedFilterIndexes,
                  searchFields: metricSearchFields,
                  searchFilters: metricSearchFilters,
                  searchViewFields,
                  searchViewFilters,
                  defaultSearchFilters,
                  setSearchEntityType,
                  changeSearchEntityDateType,
                  changeSearchEntityStartTime,
                  changeSearchEntityEndTime,
                  changeSearchEntityMonth,
                  onSearchAll: this.onSearchMetric,
                  onAddFilterClick: this.onAddFilterClick,
                  onEditFilterClick: this.onEditFilterClick,
                  onRemoveFilterClick: this.onRemoveFilterClick,
                  onGroupFilterClick: this.onGroupFilterClick,
                  onUngroupFilterClick: this.onUngroupFilterClick,
                  onClearAllFiltersClick: this.onClearAllFiltersClick,
                  onRestoreFiltersClick: this.onRestoreFiltersClick,
                  onSearchViewFilterChange: this.onChangeSearchViewFilter,
                }}
              />
              <GraphSearchFilterList
                searchFilters={metricSearchFilters}
                selectedFilterIndexes={selectedFilterIndexes}
                showNonIndexedAttributeWarning={showNonIndexedAttributeWarning}
                selectSearchFilter={this.onSelectFilter}
                onTeachingBubbleDismiss={this.onTeachingBubbleDismiss}
              />
            </PivotItem>
          )}
          {!isProduction && (
            <PivotItem
              headerText="Entity Search"
              className={`${classNames.pivotItem} ${classNames.contentBox}`}
              itemKey={SearchItemKey.entitySearch}
            >
              <GraphSearchEntity
                {...{
                  selectedEntityType,
                  entityTypeOptions,
                  selectedFilterIndexes,
                  searchFields: entitySearchFields,
                  searchFilters: entitySearchFilters,
                  defaultSearchFilters,
                  searchBatchSize,
                  searchRandomTop,
                  setSearchEntityType,
                  onSearchEntity: this.onSearchEntity,
                  onAddFilterClick: this.onAddFilterClick,
                  onEditFilterClick: this.onEditFilterClick,
                  onRemoveFilterClick: this.onRemoveFilterClick,
                  onGroupFilterClick: this.onGroupFilterClick,
                  onUngroupFilterClick: this.onUngroupFilterClick,
                  onClearAllFiltersClick: this.onClearAllFiltersClick,
                  onRestoreFiltersClick: this.onRestoreFiltersClick,
                  onChangeSearchBatchSize: changeSearchBatchSize,
                  onChangeSearchRandomTop: changeSearchRandomTop,
                }}
              />
              <GraphSearchFilterList
                searchFilters={entitySearchFilters}
                selectedFilterIndexes={selectedFilterIndexes}
                showNonIndexedAttributeWarning={showNonIndexedAttributeWarning}
                selectSearchFilter={this.onSelectFilter}
                onTeachingBubbleDismiss={this.onTeachingBubbleDismiss}
              />
            </PivotItem>
          )}
          <PivotItem
            headerText="Entity Lookup"
            className={`${classNames.pivotItem} ${classNames.contentBox}`}
            itemKey={SearchItemKey.entityLookup}
          >
            <GraphSearchEntityLookup
              {...{
                selectedEntityId,
                selectedEntityType,
                selectedEntityVersion,
                entityTypeOptions,
                setSearchEntityType,
                setSearchEntityId,
                setSearchEntityVersion,
                onKeyDown: this.onKeyDown,
                onSearchEntity: this.onLookupEntity,
              }}
            />
          </PivotItem>
        </Pivot>
        {showSearchFilterModel && (
          <GraphSearchFilterModal
            selectedFilter={selectedFilter}
            searchFields={searchItemKey === SearchItemKey.entitySearch ? entitySearchFields : metricSearchFields}
            searchFilters={searchItemKey === SearchItemKey.entitySearch ? entitySearchFilters : metricSearchFilters}
            onCancel={this.onAddFilterCancel}
            onCommit={this.onAddFilterCommit}
          />
        )}
      </div>
    );
  }

  onPivotLinkClick = (item: PivotItem) => {
    const newSearchItemKey = item.props.itemKey;
    const { changeSearchEntitySelectedKey, loadEntityMetrics, entityMetrics } = this.props;

    changeSearchEntitySelectedKey(newSearchItemKey);

    if (newSearchItemKey === SearchItemKey.metricSearch && !entityMetrics) {
      loadEntityMetrics();
    }
  };

  onSearchMetric = () => {
    const { loadEntityMetrics, startTime, endTime } = this.props;

    loadEntityMetrics(startTime, endTime);
  };

  onSearchEntity = () => {
    const { searchEntities } = this.props;

    searchEntities();
  };

  onLookupEntity = () => {
    const { loadEntity, selectedEntityType, selectedEntityId, selectedEntityVersion } = this.props;

    selectedEntityType && selectedEntityId && loadEntity(selectedEntityType, selectedEntityId, selectedEntityVersion);
  };

  onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      this.onLookupEntity();
    }
  };

  onFilterCancel = () => {
    this.setState({ showSearchFilterModel: false, selectedFilterIndexes: [] });
  };

  onAddFilterClick = () => {
    this.setState({ selectedFilter: null, selectedFilterIndexes: [], showSearchFilterModel: true });
  };

  onAddFilterCancel = () => {
    this.onFilterCancel();
  };

  onAddFilterCommit = (searchFilter: ISearchFilter) => {
    const { addSearchFilter, editSearchFilter } = this.props;
    const { selectedFilter, selectedFilterIndexes } = this.state;

    selectedFilter ? editSearchFilter(searchFilter, selectedFilterIndexes[0]) : addSearchFilter(searchFilter);
    this.onAddFilterCancel();

    if (searchFilter.indexed !== true) {
      this.setState({ showNonIndexedAttributeWarning: true });
    }
  };

  onEditFilterClick = () => {
    const { selectedFilterIndexes } = this.state;

    if (selectedFilterIndexes?.length === 1) {
      const searchFilters = this.getSearchFilters(),
        selectedIndex = selectedFilterIndexes[0],
        selectedFilter = getSearchFilter(searchFilters, selectedIndex);

      this.setState({
        selectedFilter,
        showSearchFilterModel: true,
      });
    }
  };

  onRemoveFilterClick = () => {
    const { removeSearchFilter } = this.props;
    const { selectedFilterIndexes } = this.state;

    if (selectedFilterIndexes?.length >= 1) {
      removeSearchFilter(selectedFilterIndexes);
      this.onFilterCancel();
    }
  };

  onClearAllFiltersClick = () => {
    this.setState({ selectedFilterIndexes: [] });
    this.props.clearAllSearchFilters();
  };

  onRestoreFiltersClick = () => {
    this.setState({ selectedFilterIndexes: [] });
    this.props.restoreSearchFilters();
  };

  onGroupFilterClick = () => {
    const { groupSearchFilter } = this.props;
    const { selectedFilterIndexes } = this.state;

    if (selectedFilterIndexes?.length >= 1) {
      groupSearchFilter(selectedFilterIndexes);
      this.onFilterCancel();
    }
  };

  onUngroupFilterClick = () => {
    const { ungroupSearchFilter } = this.props;
    const { selectedFilterIndexes } = this.state;

    if (selectedFilterIndexes?.length >= 1) {
      ungroupSearchFilter(selectedFilterIndexes);
      this.onFilterCancel();
    }
  };

  onSelectFilter = (index: number) => {
    let selectedFilterIndexes = this.state.selectedFilterIndexes.slice(),
      selectionIndex = selectedFilterIndexes.indexOf(index);

    if (selectionIndex >= 0) {
      selectedFilterIndexes.splice(selectionIndex, 1);
    } else {
      selectedFilterIndexes.push(index);
      selectedFilterIndexes.sort();
    }

    this.setState({ selectedFilterIndexes });
  };

  getSearchFilters = () => {
    const { searchItemKey, metricSearchFilters, entitySearchFilters } = this.props;
    return searchItemKey === SearchItemKey.entitySearch ? entitySearchFilters : metricSearchFilters;
  };

  onTeachingBubbleDismiss = () => {
    this.setState({ showNonIndexedAttributeWarning: false });
  };

  onChangeSearchViewFilter = (filterName: string, value) => {
    const { searchViewFields, changeSearchViewFilter } = this.props;

    var viewField = searchViewFields?.find((field) => field.filterName === filterName);

    if (viewField) {
      changeSearchViewFilter(viewField, value);
    }
  };
}

const mapStateToProps = (state: IState): IGraphSearchStateProps => ({
  entityTypes: state.modules.entity_types,
  allEntityTypes: state.modules.all_entity_types,
  selectedEntityType: state.modules.search_entity_type,
  selectedEntityId: state.modules.search_entity_id,
  selectedEntityVersion: state.modules.search_entity_version,
  startTime: state.modules.search_entity_start_time,
  endTime: state.modules.search_entity_end_time,
  selectedDateType: state.modules.search_entity_date_type,
  searchItemKey: state.modules.search_item_key,
  selectedEntityViewId: state.modules.selected_entity_view_id,
  metricSearchFields: state.modules.metric_search_fields,
  metricSearchFilters: state.modules.metric_search_filters,
  entitySearchFields: state.modules.entity_search_fields,
  entitySearchFilters: state.modules.entity_search_filters,
  entityMetrics: state.modules.entity_metrics_raw,
  defaultSearchFilters: state.modules.default_search_filters,
  entityViews: state.modules.entity_views,
  searchViewFields: state.modules.search_view_fields,
  searchViewFilters: state.modules.search_view_filters,
  searchBatchSize: state.modules.search_batch_size,
  searchRandomTop: state.modules.search_random_top,
  isProduction: state.app.is_production,
});

const mapDispatchToProps = {
  setSearchEntityType: actionCreator.setSearchEntityType,
  setSearchEntityId: actionCreator.setSearchEntityId,
  setSearchEntityVersion: actionCreator.setSearchEntityVersion,
  loadEntity: actionCreator.loadEntity,
  changeSearchEntityStartTime: actionCreator.changeSearchEntityStartTime,
  changeSearchEntityEndTime: actionCreator.changeSearchEntityEndTime,
  changeSearchEntityMonth: actionCreator.changeSearchEntityMonth,
  changeSearchEntitySelectedKey: actionCreator.changeSearchEntitySelectedKey,
  changeSearchEntityDateType: actionCreator.changeSearchEntityDateType,
  loadFinancialEntities: actionCreator.loadFinancialEntities,
  loadEntityMetrics: actionCreator.loadEntityMetrics,
  addSearchFilter: actionCreator.addSearchFilter,
  editSearchFilter: actionCreator.editSearchFilter,
  removeSearchFilter: actionCreator.removeSearchFilter,
  groupSearchFilter: actionCreator.groupSearchFilter,
  ungroupSearchFilter: actionCreator.ungroupSearchFilter,
  clearAllSearchFilters: actionCreator.clearAllSearchFilters,
  restoreSearchFilters: actionCreator.restoreSearchFilters,
  searchEntities: actionCreator.searchEntities,
  changeSearchViewFilter: actionCreator.changeSearchViewFilter,
  changeSearchBatchSize: actionCreator.changeSearchBatchSize,
  changeSearchRandomTop: actionCreator.changeSearchRandomTop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphSearch);
