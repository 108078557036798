import React from "react";
import { IIncident } from "./interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { SelectionMode, IColumn, DetailsList } from "@fluentui/react/lib/DetailsList";
import { ITimelineMarker } from "../../shared/components/TimelineChart/interfaces";
import { getTimelineMarkers } from "./IncidentsReport.helper";
import { Timeline } from "../../shared/components/Timeline";
import { ContactLink } from "../../shared/components/ContactLink";
import { getContactMessageSubject } from "./IncidentsReport.helper";
import classNames from "./IncidentTimeline.module.scss";

const emptyMarkerContent = "-";

export interface IIncidentTimelineProps {
  incident: IIncident;
  isSmallScreen: boolean;
}

export class IncidentTimeline extends React.Component<IIncidentTimelineProps> {
  render() {
    const { incident } = this.props;

    let markers = getTimelineMarkers(incident);

    if (!markers.length) {
      return null;
    }

    let items = this.transformMarkers(markers),
      columns = this.getColumns();

    return (
      <Tile title="Timeline" commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <div className={classNames.contentRoot}>
          <div className={classNames.timeline}>
            <Timeline markers={markers} />
          </div>
          <div className={classNames.timelineTable}>
            <DetailsList items={items} columns={columns} compact={true} selectionMode={SelectionMode.none} />
          </div>
        </div>
      </Tile>
    );
  }

  transformMarkers = (markers: ITimelineMarker[]): any[] => {
    let items: any[] = [];

    markers &&
      markers.forEach((marker) => {
        items.push({
          ...marker,
          by:
            marker.fields && marker.fields.length && marker.fields[0].name === "By"
              ? marker.fields[0].value
              : emptyMarkerContent,
        });
      });

    return items;
  };

  getColumns = (): IColumn[] => {
    const { incident } = this.props;

    return [
      {
        key: "c1",
        name: "Event",
        fieldName: "name",
        minWidth: 120,
        maxWidth: 200,
        onRender: (item: any) =>
          item.color ? <span style={{ color: `${item.color}`, fontWeight: 600 }}>{item.name}</span> : item.name,
      },
      {
        key: "c2",
        name: "Time",
        fieldName: "time",
        minWidth: 80,
        maxWidth: 200,
        onRender: (item: any) => item.time && new Date(item.time).toLocaleDateString(),
      },
      {
        key: "c3",
        name: "By",
        fieldName: "by",
        minWidth: 80,
        onRender: (item: any) =>
          item.by && item.by !== emptyMarkerContent ? (
            <ContactLink alias={item.by} messageSubject={getContactMessageSubject(incident)} />
          ) : (
            item.by
          ),
      },
    ];
  };
}

export default IncidentTimeline;
