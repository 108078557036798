import React from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import classNames from "./CollapseToggleIcon.module.scss";

export enum ToggleDirection {
  horizontal,
  vertical,
}

export enum TogglePosition {
  top,
  right,
}

export interface ICollapseToggleIconProps {
  isCollapsed: boolean;
  className?: string;
  direction?: ToggleDirection;
  togglePosition?: TogglePosition;
  onClick: () => void;
}

export class CollapseToggleIcon extends React.Component<ICollapseToggleIconProps> {
  render() {
    const {
      isCollapsed,
      className,
      direction = ToggleDirection.horizontal,
      togglePosition = TogglePosition.top,
      onClick,
    } = this.props;

    const collapseText = isCollapsed && direction === ToggleDirection.horizontal ? "ChevronLeft" : "ChevronDown",
      expandText = !isCollapsed && direction === ToggleDirection.horizontal ? "ChevronRight" : "ChevronUp",
      icon = isCollapsed ? collapseText : expandText,
      tooltip = (isCollapsed ? "Expand" : "Collapse") + " Pane";

    const iconClassNames = [classNames.collapseToggleIcon, className];

    if (togglePosition === TogglePosition.right) {
      iconClassNames.push(classNames.collapseToggleIconRight);
    }

    return (
      <Icon className={iconClassNames.join(" ")} role="button" iconName={icon} title={tooltip} onClick={onClick} />
    );
  }
}
