import React from "react";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import { IServiceConfig } from "../../common/serviceContentInterfaces";
import { getAttributeMappingFields } from "./MyServiceContentsEdit.helper";
import { IPanelProps, IRenderFunction, Panel, PrimaryButton } from "@fluentui/react";
import { MenuClick, MyServiceContentsAddAttributes } from "./MyServiceContentsAddAttributes";

interface IProps {
  serviceConfig: IServiceConfig;
  serviceConfigs: IServiceConfig[];
  editConfigItem: (configValue: string) => void;
}

interface IState {
  loadedText?: string;
  loadedSample?: object;
  listOfAttributes?: string[];
  panelOpen: boolean;
  panelSelectedName: string;
  panelSelectedPath: string;
  panelSelectedKey: string;
}

export class MyServiceContentAttributesEdit extends React.Component<IProps, IState> {
  private attributesEditForm: React.RefObject<any> = React.createRef();

  onMenuClick: MenuClick = (name: string, path: string, key: string) => {
    this.setState({
      panelSelectedName: name,
      panelSelectedPath: path,
      panelSelectedKey: key,
    });
  };

  showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      if (typeof text === "string") {
        try {
          const parsed = JSON.parse(text);
          this.setState({
            loadedText: text,
            loadedSample: parsed,
            panelOpen: true,
          });
        } catch (error) {
          console.log(error);
        }
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  addClick = () => {
    const { panelSelectedName: name, panelSelectedPath: path } = this.state;
    let { attributeMappings } = this.props?.serviceConfig;
    // Init the attributeMappings object as needed
    if (!attributeMappings) {
      attributeMappings = [];
    }
    if (!attributeMappings.length) {
      attributeMappings.push({ attributes: [] });
    }
    if (!attributeMappings[0].attributes?.length) {
      attributeMappings[0].attributes = [];
    }
    // Push the new value into it (assuming index 0 until `schemaVersion` is fully implemented)
    attributeMappings[0].attributes.push({
      name,
      path,
    });
    this.onFieldValueChange("attributeMappings", attributeMappings);
  };

  renderFooterForPanel: IRenderFunction<IPanelProps> = () => {
    return <PrimaryButton onClick={this.addClick}>Add</PrimaryButton>;
  };

  onFieldValueChange = (fieldName: string, newValue: any) => {
    const { serviceConfig, editConfigItem } = this.props;

    if (serviceConfig) {
      let configObject = serviceConfig;

      if (newValue === "" || newValue === null || newValue === undefined) {
        delete configObject[fieldName];
      } else {
        configObject[fieldName] = newValue;
      }

      editConfigItem && editConfigItem(JSON.stringify(serviceConfig, null, 2));
    }
  };

  render() {
    const { serviceConfig, serviceConfigs, editConfigItem } = this.props;

    return (
      <>
        <label>
          Use sample to get attributes list to find attributes not yet added:
          <input type="file" onChange={(e) => this.showFile(e)} />
        </label>

        <Panel
          isOpen={this.state?.panelOpen}
          onDismiss={() => this.setState({ panelOpen: false })}
          headerText="Select an attribute and click Add"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={this.renderFooterForPanel}
          isBlocking={false}
          isFooterAtBottom={true}
        >
          <MyServiceContentsAddAttributes sampleData={this.state?.loadedSample} onClick={this.onMenuClick} />
        </Panel>
        <ConfigItemEditForm
          configItem={serviceConfig}
          fields={[...getAttributeMappingFields(serviceConfig, serviceConfigs)]}
          editConfigItem={editConfigItem}
          ref={this.attributesEditForm}
        />
      </>
    );
  }

  getErrors = () => {
    var attributesEditFormErrors =
      this.attributesEditForm && this.attributesEditForm.current && this.attributesEditForm.current.getErrors();

    return { ...attributesEditFormErrors };
  };
}
