import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IConfigItemEditProps } from "../common/ConfigItemEdit";
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  TextField,
  Dropdown,
  PrimaryButton,
  IGroup,
  Icon,
  DetailsList,
} from "@fluentui/react";
import { errorType } from "../../interfaces";
import { IState } from "../../../reducers/interfaces";
import classNames from "./MyServiceContentEdit.module.scss";
import { AttributeWithStatus, getConfigAttributeValues, getStatusForAttributes } from "./ValidationParsingHelper";
import { IAttribute } from "../../common/serviceContentInterfaces";
import { makeOption } from "../common/helper";

export interface IConfigItemEditStateProps {
  configAttributeValues: {};
  loadingFile: boolean;
  configError: object;
  loadingFinancialEntityJournalItems: boolean;
  loadingFinancialEntityJournalItemsSuccess: boolean;
  financialEntityJournalItems;
}

interface ValidateServiceDataForConfigState {
  indexToUse: string;
  numberOfRecordsToLoad: string;
  validationType: OptionsForValidation;
}

export interface IConfigItemEditDispatchProps extends IConfigItemEditStateProps {
  loadConfigAttributeValuesFromDoc: (serviceConfig: any, document: any, schemaVersion: string, uid: string) => void;
  loadFinancialEntityJournalItems: (
    financialRecordType: string,
    numberToSelect: number,
    uid: string,
    ageInDays: number
  ) => void;
}

type IProps = IConfigItemEditProps & IConfigItemEditDispatchProps & IConfigItemEditStateProps;

enum OptionsForValidation {
  File = "File",
  Single = "Single",
  Multiple = "Multiple",
}

// This is a hard-coded value for this control that it will use to know which calls it makes vs. if something else made them
// This functionality will be re-evaluated during the next part of the feature's implementation
const UniqueIndex = "1";

export class ValidateServiceDataForConfig extends React.Component<IProps, ValidateServiceDataForConfigState> {
  constructor(props: IProps) {
    super(props);
    const configId = props?.configItemId;
    this.state = {
      indexToUse: `${UniqueIndex}_${configId}`,
      numberOfRecordsToLoad: "5",
      validationType: OptionsForValidation.Single,
    };
  }

  showFile = async (e) => {
    e.preventDefault();
    const { configItem, loadConfigAttributeValuesFromDoc } = this.props;
    const { indexToUse } = this.state;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      if (typeof text === "string") {
        try {
          const parsed = JSON.parse(text);
          getConfigAttributeValues(parsed, configItem, `${indexToUse}_0`, loadConfigAttributeValuesFromDoc);
        } catch (error) {
          console.log(error);
        }
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  loadSampleDocs = async (e, numberOfRecordsToLoad: string) => {
    e.preventDefault();
    const { configItem, loadFinancialEntityJournalItems } = this.props;
    const { indexToUse } = this.state;
    const ageInDays = 1; // Hardcoding for now until I create the UI
    // Add checking for numbers only
    loadFinancialEntityJournalItems(configItem.name, parseInt(numberOfRecordsToLoad), indexToUse, ageInDays);
  };

  columns: IColumn[] = [
    { key: "name", name: "Name", fieldName: "name", minWidth: 25, maxWidth: 200, isResizable: true },
    { key: "status", name: "Status", fieldName: "status", minWidth: 40, maxWidth: 90, isResizable: true },
    { key: "path", name: "Path", fieldName: "path", minWidth: 50, maxWidth: 200, isResizable: true },
    { key: "required", name: "Required", fieldName: "required", minWidth: 50, maxWidth: 60, isResizable: false },
    { key: "value", name: "Value", fieldName: "value", minWidth: 150, maxWidth: 600, isResizable: true },
  ];

  onRenderItemColumn = (item: AttributeWithStatus, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof AttributeWithStatus]?.toString();
    let className = classNames.tableStatus;

    switch (item?.status) {
      case "Failed":
        className = classNames.tableError;
        break;
      case "Warning":
        className = classNames.tableWarning;
        break;
      case "Passed":
        className = classNames.tablePass;
        break;
    }

    switch (column.key) {
      case "status":
        return (
          <span data-selection-disabled={true} className={className}>
            {fieldContent}
            {item.tooltip && <Icon className={classNames.hints} role="button" iconName={"Info"} title={item.tooltip} />}
          </span>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  };

  onFieldValueChange = (fieldName: string, newValue: string) => {
    this.setState({ [fieldName]: newValue } as any);
  };

  componentDidUpdate() {
    const {
      configItem,
      configAttributeValues,
      loadingFile,
      loadingFinancialEntityJournalItems,
      loadingFinancialEntityJournalItemsSuccess,
      financialEntityJournalItems,
      loadConfigAttributeValuesFromDoc,
    } = this.props;
    const { indexToUse, validationType } = this.state;
    let valuesLoaded = true;
    const journalRecords = financialEntityJournalItems?.hasOwnProperty(indexToUse)
      ? financialEntityJournalItems[indexToUse]
      : [];
    for (let index = 0; index < journalRecords.length && valuesLoaded; index++) {
      let recordIndex = `${indexToUse}_${index}`;
      valuesLoaded = valuesLoaded && configAttributeValues?.hasOwnProperty(recordIndex);
    }

    if (
      !loadingFile &&
      !valuesLoaded &&
      (validationType === OptionsForValidation.Single || validationType === OptionsForValidation.Multiple) &&
      !loadingFinancialEntityJournalItems &&
      loadingFinancialEntityJournalItemsSuccess
    ) {
      if (journalRecords?.length) {
        console.log(journalRecords);
        journalRecords.forEach((record, index: number) => {
          const uid = `${indexToUse}_${index}`;
          getConfigAttributeValues(record.data, configItem, uid, loadConfigAttributeValuesFromDoc);
        });
      }
    }
  }

  render() {
    const {
      configItem,
      configAttributeValues,
      loadingFile,
      loadingFinancialEntityJournalItems,
      loadingFinancialEntityJournalItemsSuccess,
      financialEntityJournalItems,
    } = this.props;
    const { indexToUse: indexPrefix, validationType, numberOfRecordsToLoad } = this.state;
    let indexToUse = `${indexPrefix}_0`;
    const valuesLoaded = configAttributeValues?.hasOwnProperty(indexToUse);
    const attributeMappings = configItem?.attributeMappings;
    const attributes: IAttribute[] = attributeMappings?.length ? attributeMappings[0].attributes : {};
    let items: AttributeWithStatus[] = [];
    const journalRecordsLoaded = financialEntityJournalItems?.hasOwnProperty(indexPrefix);
    const journalRecords = journalRecordsLoaded ? financialEntityJournalItems[indexPrefix] : [];

    if (!loadingFile && attributes?.length && valuesLoaded) {
      items = getStatusForAttributes(
        configAttributeValues,
        indexToUse,
        attributes,
        journalRecords?.length ? journalRecords[0] : null
      );
    }

    let validationOption = null;
    let spinner =
      ((loadingFinancialEntityJournalItems || (journalRecords?.length && loadingFile)) && (
        <Spinner
          styles={{
            root: classNames.spinner,
            circle: classNames.spinnerCircle,
            label: classNames.spinnerLabel,
          }}
          label="Validating Against Service Data..."
        />
      )) ||
      null;
    let display =
      (!loadingFile && !loadingFinancialEntityJournalItems && items?.length && (
        <DetailsList
          items={items}
          columns={this.columns}
          onRenderItemColumn={this.onRenderItemColumn}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          styles={{ root: classNames.tableForValidationResults }}
        />
      )) ||
      null;

    switch (validationType) {
      case OptionsForValidation.File:
        validationOption = (
          <>
            <label>Validate against sample document: </label>
            <input type="file" onChange={(e) => this.showFile(e)} />
          </>
        );
        spinner = loadingFile && (
          <Spinner
            styles={{
              root: classNames.spinner,
              circle: classNames.spinnerCircle,
              label: classNames.spinnerLabel,
            }}
            label="Validating Against Sample File..."
          />
        );

        break;
      case OptionsForValidation.Multiple:
        validationOption = (
          <>
            <label className={`${classNames.entryField} ${classNames.validateButton}`}>
              <TextField
                label="Validate against specified number of financial entities:"
                onChange={(ev, newValue) => this.onFieldValueChange("numberOfRecordsToLoad", newValue)}
                value={numberOfRecordsToLoad}
              />
            </label>
            <PrimaryButton className={classNames.button} onClick={(e) => this.loadSampleDocs(e, numberOfRecordsToLoad)}>
              Load
            </PrimaryButton>
          </>
        );

        let allItems: AttributeWithStatus[][] = [];
        if (!loadingFile && attributes?.length && valuesLoaded) {
          for (let allItemIndex = 0; allItemIndex < journalRecords.length; allItemIndex++) {
            const uid = `${indexPrefix}_${allItemIndex}`;
            const item = getStatusForAttributes(configAttributeValues, uid, attributes, journalRecords[allItemIndex]);
            allItems.push(item);
          }
        }

        let groups: IGroup[] = [];
        let rows: AttributeWithStatus[] = [];
        if (items?.length) {
          for (let columnIndex = 0; columnIndex < items.length; columnIndex++) {
            const startIndex = rows.length;
            let collapseGroup = true;
            for (let rowIndex = 0; rowIndex < allItems.length; rowIndex++) {
              const singleItem = allItems[rowIndex][columnIndex];
              rows.push(singleItem);
              if (singleItem?.status !== "Passed") {
                collapseGroup = false;
              }
            }
            groups.push({
              key: `group_${items[columnIndex].name}`,
              name: items[columnIndex].name,
              startIndex,
              count: rows.length - startIndex,
              level: 0,
              isCollapsed: collapseGroup,
            });
          }

          display = (
            <DetailsList
              items={rows}
              columns={this.columns}
              onRenderItemColumn={this.onRenderItemColumn}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              groups={groups}
              styles={{ root: classNames.tableForValidationResults }}
            />
          );
        } else {
          display = null;
        }
        break;
      case OptionsForValidation.Single:
        validationOption = (
          <label className={`${classNames.entryField} ${classNames.validateButton}`}>
            <PrimaryButton onClick={(e) => this.loadSampleDocs(e, "1")}>Start Validation</PrimaryButton>
          </label>
        );
        break;
    }

    if (
      !loadingFile &&
      !valuesLoaded &&
      validationType === OptionsForValidation.Single &&
      !loadingFinancialEntityJournalItems &&
      loadingFinancialEntityJournalItemsSuccess &&
      journalRecordsLoaded &&
      !journalRecords?.length
    ) {
      display = <span className={classNames.error}>Error: No records found.</span>;
    }

    return (
      <>
        <Dropdown
          label="How would you like to do the validation?"
          options={[
            makeOption(OptionsForValidation.File, "Validate Against Sample Document"),
            makeOption(OptionsForValidation.Single, "Validate Against Single Financial Entity"),
            makeOption(OptionsForValidation.Multiple, "Validate Against Multiple Financial Entities"),
          ]}
          styles={{ root: { maxWidth: "400px" } }}
          selectedKey={validationType}
          onChange={(ev, newValue) => this.onFieldValueChange("validationType", newValue?.key?.toString())}
        ></Dropdown>
        {validationOption}
        {spinner}
        {display}
      </>
    );
  }
}

const mapStateToProps = (state: IState): IConfigItemEditStateProps => {
  return {
    configAttributeValues: state.modules.config_attribute_values,
    loadingFile: state.modules.loading_config_attribute_values,
    configError: state.modules.errors[errorType.configAttributeValues],
    loadingFinancialEntityJournalItems: state.modules.loading_financial_entity_journal_items,
    loadingFinancialEntityJournalItemsSuccess: state.modules.loading_financial_entity_journal_items_success,
    financialEntityJournalItems: state.modules.financial_entity_journal_items,
  };
};

const mapDispatchToProps = {
  loadConfigAttributeValuesFromDoc: actionCreator.loadConfigAttributeValuesFromDoc,
  loadFinancialEntityJournalItems: actionCreator.loadFinancialEntityJournalItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateServiceDataForConfig);
