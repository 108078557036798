import { actionCreator } from "./duck";
import { authHelper } from "../api/apiBase";
import { getMsGraphMyPhoto } from "../api/msGraphApi";
import { initializeTelemetry } from "./telemetryHelper";
import * as radarApi from "../api/radarApi";
import { Dispatch } from "redux";

export const initializeApp = () => (dispatch: Dispatch) => {
  // Do not login again for the AAD callback for the access token.
  if (window.location.hash.startsWith("#access_token")) return;

  authHelper.login().then((userAccount) => {
    if (userAccount) {
      radarApi
        .fetchUserInfo()
        .then((userInfo: any) => {
          userInfo.isAirgapEnvironment = userInfo.appEnvironment === "AirCAPI"; // Add other airgap clouds here when ready.
          dispatch(actionCreator.receivedLoginUserInfo(userInfo));
        })
        .catch((error) => dispatch(actionCreator.receivedLoginUserInfo({ error })));

      initializeTelemetry(userAccount);

      dispatch(actionCreator.receivedLoginUser(userAccount));

      getMsGraphMyPhoto().then((photoUrl) => {
        dispatch(actionCreator.receivedLoginUserPhoto(photoUrl));
      });
    }
  });

  // Automatically reload page at midnight to make sure latest client bits are loaded.
  setPageReloadTimer();
};

export const loadSystemMessages = () => (dispatch: Dispatch) => {
  radarApi
    .fetchConfigItem(radarApi.ConfigItemType.AppSettings, "SystemMessages")
    .then((systemMessages: any) => {
      dispatch(actionCreator.loadSystemMessagesCompleted(systemMessages));
    })
    .catch((error) => dispatch(actionCreator.loadSystemMessagesFailed(error)));
};

export const loadHealthMetricTypes = () => (dispatch: Dispatch) => {
  radarApi.fetchConfigItem(radarApi.ConfigItemType.AppSettings, "HealthMetricTypes").then((healthMetricTypes: any) => {
    dispatch(actionCreator.loadHealthMetricTypesCompleted(healthMetricTypes.settings));
  });
};

export const loadHealthMetricGroups = () => (dispatch: Dispatch) => {
  radarApi
    .fetchConfigItem(radarApi.ConfigItemType.AppSettings, "HealthMetricGroups")
    .then((healthMetricGroups: any) => {
      dispatch(actionCreator.loadHealthMetricGroupsCompleted(healthMetricGroups.settings));
    });
};

export const loadHealthMetricSets = () => (dispatch: Dispatch) => {
  radarApi.fetchConfigItem(radarApi.ConfigItemType.AppSettings, "HealthMetricSets").then((healthMetricSets: any) => {
    dispatch(actionCreator.loadHealthMetricSetsCompleted(healthMetricSets.settings));
  });
};

const setPageReloadTimer = () => {
  let now = new Date(),
    h = now.getHours(),
    m = now.getMinutes(),
    s = now.getSeconds(),
    secTillMidNight = 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;
  setTimeout(() => {
    window.location.reload();
  }, secTillMidNight * 1000);
};
