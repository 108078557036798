import { IField, FieldType } from "../../../shared/components/DataForm";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { IEntity } from "../../controls/interfaces";

export const defaultEntity: IEntity = {
  id: "",
  soxCompliance: true,
};

export const getFields = (entity: IEntity): IField[] => {
  if (!entity) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      value: entity.id,
      required: true,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: entity.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: entity.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "relatedEntities",
      label: "Related Entities",
      fieldType: FieldType.items,
      value: entity.relatedEntities,
      itemTypeName: "Entity",
      itemDisplayView: ItemDisplayView.list,
      collapsed: true,
      fields: [
        {
          fieldName: "id",
          label: "Entity ID",
          required: true,
        },
        {
          fieldName: "relationshipType",
          label: "Relationship Type",
        },
      ],
    },
  ];
};
