import React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import classNames from "./GraphSearch.module.scss";
import { dynamicEntityViewId, IGraphSearchFilterActions } from "./helper";
import { IEntityView, ISearchField, ISearchFilter } from "../common/interfaces";
import { GraphSearchDateFilter } from "./GraphSearchDateFilter";
import { GraphSearchViewFilters } from "./GraphSearchViewFilters";
import { IVisualProps } from "../../shared/components/Visual";

export interface IGraphSearchMetricsProps extends IGraphSearchFilterActions {
  selectedEntityViewId: string;
  selectedEntityType: string;
  selectedEntityView: IEntityView;
  selectedDateType: string;
  startTime: Date;
  endTime: Date;
  entityTypeOptions: IDropdownOption[];
  selectedFilterIndexes: number[];
  searchFields: ISearchField[];
  searchFilters: ISearchFilter[];
  defaultSearchFilters: ISearchFilter[];
  searchViewFields: IVisualProps[];
  searchViewFilters: ISearchFilter[];
  setSearchEntityType: (entityType: string) => void;
  changeSearchEntityStartTime: (startTime: Date) => void;
  changeSearchEntityEndTime: (endTime: Date) => void;
  changeSearchEntityMonth: (date: Date) => void;
  changeSearchEntityDateType: (dateType: string) => void;
  onSearchAll: () => void;
  onSearchViewFilterChange: (filterName: string, value) => void;
}

export const GraphSearchMetrics = ({
  selectedEntityViewId,
  selectedEntityType,
  selectedEntityView,
  selectedDateType,
  startTime,
  endTime,
  entityTypeOptions,
  searchViewFields,
  searchViewFilters,
  setSearchEntityType,
  changeSearchEntityDateType,
  changeSearchEntityStartTime,
  changeSearchEntityEndTime,
  changeSearchEntityMonth,
  onSearchAll,
  onSearchViewFilterChange,
}: IGraphSearchMetricsProps) => {
  return (
    <div className={classNames.searchInputs}>
      {selectedEntityViewId === dynamicEntityViewId && (
        <Dropdown
          ariaLabel="Entity Type"
          label="Entity Type"
          className={classNames.typeDropdown}
          options={entityTypeOptions}
          selectedKey={selectedEntityType}
          placeholder="Select Entity Type"
          onChange={(ev, option) => setSearchEntityType(option.key.toString())}
        />
      )}
      <GraphSearchDateFilter
        {...{
          selectedDateType,
          startTime,
          endTime,
          selectedEntityView,
          changeSearchEntityDateType,
          changeSearchEntityStartTime,
          changeSearchEntityEndTime,
          changeSearchEntityMonth,
        }}
      />
      <GraphSearchViewFilters
        searchViewFields={searchViewFields}
        searchViewFilters={searchViewFilters}
        onSearchViewFilterChange={onSearchViewFilterChange}
      />
      <PrimaryButton className={classNames.searchButton} text="Load Metrics" onClick={onSearchAll} />
    </div>
  );
};
