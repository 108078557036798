import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyEventList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyEventList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Event"
        appInsightsPageName="MyEventListPage"
        leftNavUrl={leftNavUrls.management.event}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadEventConfigItems,
  deleteConfigItem: actionCreator.deleteEventConfigItem,
  publishConfigItem: actionCreator.publishEventConfigItem,
  unpublishConfigItem: actionCreator.unpublishEventConfigItem,
  revertConfigItem: actionCreator.revertEventConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEventList);
