import React from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { fetchUserRoles, checkIsUserInGroup } from "../../api/radarApi";
import { Account } from "msal/lib-es6";
import { impactScoreColors } from "../common/helper";
import { appInsights } from "../../app/telemetryHelper";
import { TextField } from "@fluentui/react/lib/TextField";
import { Icon } from "@fluentui/react/lib/Icon";
import classNames from "./DebugPage.module.scss";

export interface IDebugPageStateProps {
  user: Account;
}

export interface IDebugPageState {
  userRoles?: string;
  groupName?: string;
  groupNameStatus?: boolean;
}

export class DebugPage extends React.Component<IDebugPageStateProps, IDebugPageState> {
  state = {
    userRoles: null,
    groupName: null,
    groupNameStatus: undefined,
  };

  componentDidMount() {
    fetchUserRoles().then((data) => this.setState({ userRoles: JSON.stringify(data, null, 4) }));
    appInsights.trackPageView({ name: "DebugPage" });
  }

  render() {
    const { user } = this.props;
    const { userRoles, groupName, groupNameStatus } = this.state;

    return (
      <div className={classNames.root + " " + classNames.appModuleContent}>
        <h1>Debug Page</h1>
        <div className={classNames.content}>
          <div className={classNames.field}>
            <div className={classNames.fieldName}>
              Is <b>{user && user.userName}</b> in Security Group
            </div>
            <div className={`${classNames.fieldValue} ${classNames.groupNamePane}`}>
              <TextField
                value={groupName}
                placeholder="Enter a SG name"
                onChange={this.onGroupNameChange}
                onKeyDown={this.onGroupNameKeyDown}
              />
              {groupNameStatus !== undefined && (
                <Icon
                  className={classNames.groupNameStatusIcon}
                  iconName={groupNameStatus ? "SkypeCircleCheck" : "StatusErrorFull"}
                  style={{ color: groupNameStatus ? "green" : "red" }}
                />
              )}
            </div>
          </div>
          <div className={classNames.field}>
            <div className={classNames.fieldName}>User Info:</div>
            <pre className={classNames.fieldValue}>{JSON.stringify(user, null, 4)}</pre>
          </div>
          <div className={classNames.field}>
            <div className={classNames.fieldName}>User Roles:</div>
            <pre className={classNames.fieldValue}>{userRoles}</pre>
          </div>
        </div>
        <div>
          {impactScoreColors.map((color, index) => (
            <div key={`color-${index}`} className={classNames.impactScoreColor} style={{ backgroundColor: color }}>
              {index} - {color}
            </div>
          ))}
        </div>
      </div>
    );
  }

  onGroupNameChange = (ev, newValue: string) => {
    this.setState({ groupName: newValue });
  };

  onGroupNameKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      const { groupName } = this.state;

      this.setState({ groupNameStatus: undefined });

      checkIsUserInGroup(groupName).then((status: any) => this.setState({ groupNameStatus: status }));
    }
  };
}

const mapStateToProps = (state: IState): IDebugPageStateProps => ({
  user: state.app.login_user,
});

export default connect(mapStateToProps)(DebugPage);
