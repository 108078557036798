import { IColumn, IDropdownOption } from "@fluentui/react";
import { startCase } from "../../shared/utilities/miscHelper";

export enum ControlSearchType {
  ControlLineItem = 1,
  ControlResult,
}

export const getControlSearchTypeOptions = (): IDropdownOption[] =>
  Object.keys(ControlSearchType)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({ key: ControlSearchType[key], text: startCase(key) }));

export const getStatusOptions = (): IDropdownOption[] =>
  ["all", "pass", "fail"].map((status) => ({ key: status, text: status }));

export const controlResultColumns: IColumn[] = [
  {
    key: "dateId",
    fieldName: "dateId",
    name: "Date ID",
    minWidth: 50,
    maxWidth: 60,
  },
  {
    key: "createdOn",
    fieldName: "createdOn",
    name: "Run Time",
    minWidth: 120,
    maxWidth: 180,
  },
  {
    key: "controlResultId",
    fieldName: "controlResultId",
    name: "Control Result ID",
    minWidth: 200,
    maxWidth: 220,
  },
  {
    key: "resultStatus",
    fieldName: "resultStatus",
    name: "Status",
    minWidth: 50,
  },
];

export const controlLineItemColumns: IColumn[] = [
  {
    key: "dateId",
    fieldName: "dateId",
    name: "Date ID",
    minWidth: 50,
    maxWidth: 60,
  },
  {
    key: "controlResultId",
    fieldName: "controlResultId",
    name: "Control Result ID",
    minWidth: 200,
    maxWidth: 220,
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 40,
    maxWidth: 50,
  },
];

export const processLineItems = (lineItems: any[]): any[] => {
  return lineItems?.map((item) => {
    let lineItemDetail = {};

    try {
      lineItemDetail = JSON.parse(item.lineItemDetail);
    } catch {}

    return { ...item, ...lineItemDetail };
  });
};
