import React, { CSSProperties } from "react";
import { IVisualProps } from "./Visual";
import { calcFontSize } from "./Visual.helper";
import { templateFormat, getFormatContent } from "../../utilities/miscHelper";
import classNames from "./TemplateVisual.module.scss";

export interface ITemplateVisualState {
  width: number;
}

export interface ITemplateVisualProps {
  valueStyle?: CSSProperties;
  categoryStyle?: CSSProperties;
  valueColor?: string;
  fontSize?: string;
  template?: string;
  prefixContent?: string;
  suffixContent?: string;
}

export class TemplateVisual extends React.Component<IVisualProps, ITemplateVisualState> {
  rootElement: HTMLElement = null;

  state: ITemplateVisualState = {
    width: 0,
  };

  componentDidMount() {
    if (this.rootElement) {
      let width = this.rootElement.clientWidth;

      if (width && width !== this.state.width) {
        this.setState({ width });
      }
    }
  }

  render() {
    const {
      data,
      visualProps,
      valueDisplayType,
      categoryDisplayType,
      categoryFieldName = "category",
      valueFieldNames = ["value"],
      decimal,
    } = this.props;

    if (!data || !data.length) return null;

    const {
      valueStyle = {},
      categoryStyle = {},
      fontSize,
      template,
      valueColor,
      prefixContent,
      suffixContent,
    } = visualProps as ITemplateVisualProps;
    const { width } = this.state;

    const valueFieldName = valueFieldNames[0];
    const maxTextLength = getMaxTextLength(data, categoryFieldName, valueFieldName),
      finalFontSize = fontSize || calcFontSize(width, maxTextLength),
      finalValueStyle = {
        color: valueColor,
        fontSize: finalFontSize,
        ...valueStyle,
      },
      finalCategoryStyle = {
        fontSize: finalFontSize,
        ...categoryStyle,
      };

    return (
      <div className={classNames.root} ref={(element) => (this.rootElement = element)}>
        {prefixContent && (
          <div className={classNames.prefixContent} dangerouslySetInnerHTML={{ __html: prefixContent }} />
        )}
        <div className={classNames.valueContent}>
          {data.map((item, index) => {
            let category = item[categoryFieldName];
            item["value"] = item[valueFieldName];
            item["decimal"] = item["decimal"] || decimal;

            return (
              <div className={classNames.item} key={"templateVisual" + index}>
                {template && <div dangerouslySetInnerHTML={{ __html: templateFormat(template, item) }} />}
                {!template && category && (
                  <div className={classNames.category} style={finalCategoryStyle}>
                    {getFormatContent(category, categoryDisplayType)}:{" "}
                  </div>
                )}
                {!template && (
                  <div className={classNames.value} style={finalValueStyle}>
                    {getFormatContent(item, item.valueDisplayType || valueDisplayType, "value")}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {suffixContent && (
          <div className={classNames.suffixContent} dangerouslySetInnerHTML={{ __html: suffixContent }} />
        )}
      </div>
    );
  }
}

export default TemplateVisual;

const getMaxTextLength = (data: any[], categoryFieldName: string, valueFieldName: string): number => {
  let maxLength = 1;

  data.forEach((item) => {
    let categoryText = item[categoryFieldName] || "",
      itemText = `${categoryText}${categoryText && ": "}${item[valueFieldName] || ""}`;
    maxLength = Math.max(maxLength, itemText.length);
  });

  return maxLength;
};
