import React from "react";
import HealthDashboardMetricsTable, { IHealthDashboardMetricsTableProps } from "./HealthDashboardMetricsTable";
import classNames from "./HealthDashboard.module.scss";
import { IHealthMetricSet } from "./HealthDashboard.types";
import { ITeam } from "../common/interfaces";

export interface IHealthDashboardMetricSetsProps extends IHealthDashboardMetricsTableProps {
  tableName?: string;
  metricSets?: IHealthMetricSet[];
}

export const HealthDashboardMetricSets = (props: IHealthDashboardMetricSetsProps) => {
  const {
    tableName,
    metricSets,
    teams,
    metricTypes,
    healthMetrics,
    standardTemplates,
    metricSubTypes,
    metricGroup,
    trendCharts,
  } = props;
  const metricsTables: JSX.Element[] = [];
  let metricSetIndex = 0,
    targetTeam = teams?.length && teams[0];

  do {
    const metricSet = metricSets?.length > metricSetIndex && metricSets[metricSetIndex];

    const metricSetTeam = metricSet?.teams?.find((team) => team.id === targetTeam?.id),
      isMetricSetDisabled = metricSet?.disabled || metricSetTeam?.disabled;

    if (!isMetricSetDisabled) {
      const finalTableName = getFinalTableName(tableName, metricSet, targetTeam);
      const finalMetricTypes = metricTypes?.filter(
        (metricType) => !metricSet || metricType.metricSet === metricSet?.id
      );
      const finalMetricSubTypes = metricSubTypes?.filter(
        (subType) =>
          !subType.teams?.length ||
          subType.teams.find((team) => team?.id === targetTeam?.id)?.metricSet === metricSet?.id
      );

      metricsTables.push(
        <>
          {finalTableName && <div className={classNames.metricTableHeader}>{finalTableName}</div>}
          <div className={classNames.metricTable}>
            <HealthDashboardMetricsTable
              teams={teams}
              metricTypes={finalMetricTypes}
              healthMetrics={healthMetrics}
              standardTemplates={standardTemplates}
              metricSubTypes={finalMetricSubTypes}
              metricGroup={metricGroup}
              trendCharts={trendCharts}
              metricSet={metricSet}
            />
          </div>
        </>
      );
    }

    metricSetIndex++;

    if (metricSetIndex < metricSets?.length) {
      metricsTables.push(<div className={classNames.tableSpacer} />);
    }
  } while (metricSetIndex < metricSets?.length);

  return metricsTables.length ? <>{metricsTables}</> : null;
};

export default HealthDashboardMetricSets;

const getFinalTableName = (tableName: string, metricSet: IHealthMetricSet, targetTeam: ITeam): string => {
  let finalTableName = tableName,
    metricSetName = "",
    showMetricSetName = true;

  if (metricSet) {
    if (metricSet.teams?.length && targetTeam) {
      let metricSetTeam = metricSet.teams.find((team) => team.id === targetTeam.id);

      showMetricSetName = !metricSetTeam.doNotShowMetricSetName;

      if (showMetricSetName && metricSetTeam?.metricSetName) {
        metricSetName = metricSetTeam?.metricSetName;
      }
    }

    if (showMetricSetName && !metricSetName) {
      metricSetName = metricSet.name;
    }
  }

  if (metricSetName && showMetricSetName) {
    finalTableName += " - " + metricSetName;
  }

  return finalTableName;
};
