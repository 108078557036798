import React from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType, convertUtcToLocalTime } from "../../../shared/utilities/miscHelper";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getOnboardingStatusText } from "../../controls/HealthReport.helper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      data: "number",
      onRender: (item) => <a href={`/#${leftNavUrls.all.control}/${item.id}`}>{item.id}</a>,
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
    },
    {
      key: "name",
      name: "Control Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "onboardingStatus",
      name: "Onboarding Status",
      fieldName: "onboardingStatus",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => getOnboardingStatusText(item),
    },
    {
      key: "soxCompliance",
      name: "SOX",
      fieldName: "soxCompliance",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      onRender: (item) => (item.soxCompliance ? "Yes" : "No"),
    },
    {
      key: "publishedOn",
      name: "Last Published",
      fieldName: "publishedOn",
      minWidth: 120,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) =>
        getFormatContent(item.publishedOn && convertUtcToLocalTime(item.publishedOn), ContentDisplayType.datetime),
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onRender: (item) => getFormatContent(item._modifiedBy, ContentDisplayType.contact),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 40,
      maxWidth: 40,
      isResizable: true,
      onRender: (item) => getFormatContent(item.owner, ContentDisplayType.contact),
    },
    {
      key: "editors",
      name: "Editors",
      fieldName: "editors",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => getFormatContent(item.editors, ContentDisplayType.contact),
    },
  ];
};
