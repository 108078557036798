import { INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { IUserInfo } from "./interfaces";
import { isUserFinanceOrLT, isUserSoxAuditor } from "./App.helper";

export const leftNavUrls = {
  home: "/",
  healthDashboardTest: "/healthdashboardtest",
  financialHealth: {
    default: "/financialHealth",
    close: "/financialHealth/close",
    platform: "/financialHealth/2",
    tier1: "/financialHealth/tier1",
  },
  streams: "/streams",
  all: {
    financialIncidents: "/incidents/financial",
    financialIncidentsMonthEnd: "/incidents/financialMonthEnd",
    controls: "/controls",
    complianceDocs: "/complianceDocs",
    configDefs: "/configDefs",
    controlsCompliance: "/controls/compliance",
    controlsMsSales: "/controls/mssales",
    controlsOperational: "/controls/operational",
    control: "/control",
    graph: "/graph",
    graphEntityLookup: "/graph/entitylookup",
    okr: "/okr",
    fmecloseissues: "/fmecloseissues",
    search: "/search",
    auditOfAudits: "/auditofaudits",
    reprocessFinancialEntities: "/reprocessFinancialEntities",
    servicebusMessageExplorer: "/servicebusMessageExplorer",
    services: {
      acr: "/services/acr",
      billing: {
        default: "/services/billing",
        taxService: "/services/billing/taxservice",
        vertex: "/services/billing/vertex",
        saptax: "/services/billing/saptax",
      },
      enterpriseCommerce: {
        default: "/services/enterpriseCommerce",
      },
      financialsPipeline: "/services/financialspipeline",
      invoicing: {
        default: "/services/invoicing",
        modern: "/services/invoicing/modern",
        centaur: "/services/invoicing/centaur",
        ea: "/services/invoicing/ea",
        ctp: "/services/invoicing/ctp",
        oem: "/services/invoicing/oem",
      },
      financials: {
        mrr: "/services/financials/mrr",
        billedRevenue: "/services/financials/billedrevenue",
        tldBilled: "/services/financials/tldbilled",
      },
      dunning: "/services/dunning",
      modernFinancials: "/services/modernfinancials",
      tax: "/services/tax",
    },
    reportgroup: "/reportgroup",
  },
  detect: {
    anomalies: "/anomalies",
    anomaliesControls: "/anomalies/controls",
    anomaliesMetrics: "/anomalies/metrics",
  },
  management: {
    anomalyTest: "/mgmt/myanomalytest",
    appSetting: "/mgmt/myappsetting",
    checklist: "/mgmt/checklist",
    collectorService: "/mgmt/mycollectorservice",
    complianceDocs: "/mgmt/myComplianceDocs",
    configDefs: "/mgmt/myConfigDefs",
    control: "/mgmt/mycontrol",
    controlView: "/mgmt/mycontrolview",
    devOpsView: "/mgmt/mydevopsview",
    entity: "/mgmt/myentity",
    entityView: "/mgmt/myentityView",
    entityTraceView: "/mgmt/myentityTraceView",
    event: "/mgmt/myevent",
    incidentView: "/mgmt/myincidentview",
    report: "/mgmt/myreport",
    eventSubscription: "/mgmt/myeventsubscription",
    serviceContent: "/mgmt/myservicecontent",
    team: "/mgmt/myteam",
    configHistory: "/mgmt/confighistory",
    streamRun: "/mgmt/mystreamrun",
    streamSchedule: "/mgmt/mystreamschedule",
  },
  analytics: {
    radar: "/analytics/radar",
    launchmetrics: "/analytics/launchmetrics",
    ecBizInsights: "/analytics/ecbizinsights",
    tokens: {
      default: "/analytics/tokens",
      csv: "/analytics/tokens/csv",
      subscription: "/analytics/tokens/subscription",
    },
  },
  works: {
    home: "/works",
    featureRequests: "/works/featurerequests",
  },
  systemAdmin: {
    debug: "/sysadmin/debug",
  },
  salesMotions: {
    newCommerce: "/salesmotions/newcommerce",
    fieldLed: "/salesmotions/fieldled",
    partnerLed: "/salesmotions/partnerled",
    bfgBizReview: "/salesmetrics/bfgBizReview",
  },
};

export const getLeftNavLinkGroups = (isStaging: boolean, userInfo: IUserInfo = null): INavLinkGroup[] => {
  if (userInfo?.isAirgapEnvironment || isUserSoxAuditor(userInfo)) {
    return [
      {
        name: "Controls",
        isExpanded: true,
        links: [
          {
            key: leftNavUrls.all.controlsCompliance,
            name: "Compliance",
            url: `#${leftNavUrls.all.controlsCompliance}`,
          },
        ],
      },
    ];
  }

  let result: INavLinkGroup[] = [
    {
      name: "Monitor",
      links: [
        {
          key: leftNavUrls.financialHealth.close,
          name: "Close Dashboard",
          url: `#${leftNavUrls.financialHealth.close}`,
        },
        {
          key: leftNavUrls.all.financialIncidents,
          name: "Financial Livesite",
          url: `#${leftNavUrls.all.financialIncidents}`,
        },
        {
          name: "Controls",
          url: `#${leftNavUrls.all.auditOfAudits}`,
          isExpanded: false,
          links: [
            {
              key: leftNavUrls.all.controlsCompliance,
              name: "Compliance",
              url: `#${leftNavUrls.all.controlsCompliance}`,
            },
            {
              key: leftNavUrls.all.controlsMsSales,
              name: "Audits",
              url: `#${leftNavUrls.all.controlsMsSales}`,
            },
            {
              key: leftNavUrls.all.controlsOperational,
              name: "Operational",
              url: `#${leftNavUrls.all.controlsOperational}`,
            },
          ],
        },
        {
          key: leftNavUrls.all.okr,
          name: "Process SLA",
          url: `#${leftNavUrls.all.okr}`,
          isExpanded: false,
          links: [
            {
              key: leftNavUrls.financialHealth.platform,
              name: "Current Month",
              url: `#${leftNavUrls.financialHealth.platform}`,
            },
            {
              key: leftNavUrls.financialHealth.tier1,
              name: "FUN Tier 1",
              url: `#${leftNavUrls.financialHealth.tier1}`,
            },
          ],
        },
        {
          name: "Business Performance",
          url: null,
          isExpanded: false,
          links: [
            {
              key: leftNavUrls.salesMotions.newCommerce,
              name: "NCE Offers",
              url: `#${leftNavUrls.salesMotions.newCommerce}`,
            },
          ],
        },
        {
          key: leftNavUrls.streams,
          name: "Streams",
          url: `#${leftNavUrls.streams}`,
        },
      ],
    },
    {
      name: "Detect",
      links: [
        {
          name: "Anomalies",
          url: null,
          isExpanded: false,
          links: [
            {
              key: leftNavUrls.detect.anomaliesControls,
              name: "Controls",
              url: `#${leftNavUrls.detect.anomaliesControls}`,
            },
            {
              key: leftNavUrls.detect.anomaliesMetrics,
              name: "Metrics",
              url: `#${leftNavUrls.detect.anomaliesMetrics}`,
            },
          ],
        },
      ],
    },
    {
      name: "Analyze",
      links: [
        {
          key: leftNavUrls.all.graphEntityLookup,
          name: "Entity Lookup",
          url: `#${leftNavUrls.all.graphEntityLookup}`,
          alwaysReload: true,
        },
        ...(!isUserFinanceOrLT(userInfo)
          ? [
              {
                key: leftNavUrls.all.services.acr,
                name: "ACR",
                url: `#${leftNavUrls.all.services.acr}`,
              },
              {
                name: "Billing",
                url: null,
                isExpanded: false,
                links: [
                  {
                    key: leftNavUrls.all.services.billing.taxService,
                    name: "Tax Service",
                    url: "https://vnext.s360.msftcloudes.com/blades/quality/overview?blade=sloType:North%20Star%20SLO~sloCategory:Latency;Success%20Rate;Availability;Capacity~customer:All%20Customers~period:1~_loc:Overview&peopleBasedNodes=shawnbe_team&global=@SHAWNBE%2BShawn%20Becker%20(HE%2FHIM)%20(SHAWNBE)",
                    target: "_blank",
                  },
                  {
                    key: leftNavUrls.all.services.billing.vertex,
                    name: "Vertex",
                    url: "https://portal.microsoftgeneva.com/dashboard/billingtax-metrics-prod/Vertex%20V9%20Dashboard?overrides=%5b%7b%22query%22:%22//*%5bid%3D%27callerRegion%27%5d%22,%22key%22:%22value%22,%22replacement%22:%22centralus,southcentralus,westus2%22%7d,%7b%22query%22:%22//*%5bid%3D%27cluster%27%5d%22,%22key%22:%22value%22,%22replacement%22:%22%22%7d%5d",
                    target: "_blank",
                  },
                  {
                    key: leftNavUrls.all.services.billing.saptax,
                    name: "SAP Tax",
                    url: `#${leftNavUrls.all.services.billing.saptax}`,
                  },
                ],
              },
            ]
          : []),
        {
          name: "Invoicing",
          url: null,
          isExpanded: false,
          links: [
            {
              key: leftNavUrls.all.services.invoicing.modern,
              name: "NCE",
              url: `#${leftNavUrls.all.services.invoicing.modern}`,
            },
            ...(!isUserFinanceOrLT(userInfo)
              ? [
                  {
                    key: leftNavUrls.all.services.invoicing.ea,
                    name: "EA Overage",
                    url: `#${leftNavUrls.all.services.invoicing.ea}`,
                  },
                  {
                    key: leftNavUrls.all.services.invoicing.ctp,
                    name: "CTP",
                    url: `#${leftNavUrls.all.services.invoicing.ctp}`,
                  },
                ]
              : []),
          ],
        },
        ...(!isUserFinanceOrLT(userInfo)
          ? [
              {
                name: "Financials",
                url: null,
                isExpanded: false,
                links: [
                  {
                    key: leftNavUrls.all.services.financials.mrr,
                    name: "MRR",
                    url: `#${leftNavUrls.all.services.financials.mrr}`,
                  },
                  {
                    key: leftNavUrls.all.services.financials.billedRevenue,
                    name: "Billed Revenue",
                    url: `#${leftNavUrls.all.services.financials.billedRevenue}`,
                  },
                  {
                    key: leftNavUrls.all.services.financials.tldBilled,
                    name: "TLD Billed",
                    url: `#${leftNavUrls.all.services.financials.tldBilled}`,
                  },
                ],
              },
              {
                key: leftNavUrls.all.services.dunning,
                name: "Dunning",
                url: `#${leftNavUrls.all.services.dunning}`,
              },
              {
                key: leftNavUrls.analytics.tokens.default,
                name: "Tokens",
                url: `#${leftNavUrls.analytics.tokens.default}`,
              },
            ]
          : []),
      ],
    },
    ...(!isUserFinanceOrLT(userInfo)
      ? [
          {
            name: "Admin Settings",
            collapseByDefault: true,
            links: [
              {
                key: leftNavUrls.analytics.radar,
                name: "Radar Usage",
                url: `#${leftNavUrls.analytics.radar}`,
              },
              {
                key: leftNavUrls.management.anomalyTest,
                name: "My Anomaly Tests",
                url: `#${leftNavUrls.management.anomalyTest}`,
              },
              {
                key: leftNavUrls.management.appSetting,
                name: "My App Settings",
                url: `#${leftNavUrls.management.appSetting}`,
              },
              {
                key: leftNavUrls.management.collectorService,
                name: "My Collector Services",
                url: `#${leftNavUrls.management.collectorService}`,
              },
              {
                key: leftNavUrls.management.control,
                name: "My Controls",
                url: `#${leftNavUrls.management.control}`,
              },
              {
                key: leftNavUrls.management.controlView,
                name: "My Control Views",
                url: `#${leftNavUrls.management.controlView}`,
              },
              {
                key: leftNavUrls.management.entity,
                name: "My Entities",
                url: `#${leftNavUrls.management.entity}`,
              },
              {
                key: leftNavUrls.management.entityView,
                name: "My Entity Views",
                url: `#${leftNavUrls.management.entityView}`,
              },
              {
                key: leftNavUrls.management.entityTraceView,
                name: "My Entity Trace Views",
                url: `#${leftNavUrls.management.entityTraceView}`,
              },
              {
                key: leftNavUrls.management.event,
                name: "My Events",
                url: `#${leftNavUrls.management.event}`,
              },
              {
                key: leftNavUrls.management.incidentView,
                name: "My Incident Views",
                url: `#${leftNavUrls.management.incidentView}`,
              },
              {
                key: leftNavUrls.management.report,
                name: "My Reports",
                url: `#${leftNavUrls.management.report}`,
              },
              {
                key: leftNavUrls.management.serviceContent,
                name: "My Service Contents",
                url: `#${leftNavUrls.management.serviceContent}`,
              },
              {
                key: leftNavUrls.management.streamRun,
                name: "My Stream Runs",
                url: `#${leftNavUrls.management.streamRun}`,
              },
              {
                key: leftNavUrls.management.streamSchedule,
                name: "My Stream Schedules",
                url: `#${leftNavUrls.management.streamSchedule}`,
              },
              {
                key: leftNavUrls.management.eventSubscription,
                name: "My Subscriptions",
                url: `#${leftNavUrls.management.eventSubscription}`,
              },
              {
                key: leftNavUrls.management.team,
                name: "My Teams",
                url: `#${leftNavUrls.management.team}`,
              },
              ...(userInfo?.isSuperAdmin
                ? [
                    {
                      key: leftNavUrls.all.reprocessFinancialEntities,
                      name: "Reprocess FE",
                      url: `#${leftNavUrls.all.reprocessFinancialEntities}`,
                    },
                    {
                      key: leftNavUrls.all.servicebusMessageExplorer,
                      name: "ServiceBus Explorer",
                      url: `#${leftNavUrls.all.servicebusMessageExplorer}`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  if (isStaging) {
    removeInvalidLinkGroups(result, liveOnlyLinkGroups, liveOnlyLinks);
  } // For live mode, show all config types.

  return result;
};

export const stagingOnlyLinkGroups = []; // Currently, no link group falls into this scenario.
export const stagingOnlyLinks = [
  leftNavUrls.management.anomalyTest,
  leftNavUrls.management.collectorService,
  leftNavUrls.management.complianceDocs,
  leftNavUrls.management.configDefs,
  leftNavUrls.management.control,
  leftNavUrls.management.controlView,
  leftNavUrls.management.devOpsView,
  leftNavUrls.management.entity,
  leftNavUrls.management.entityView,
  leftNavUrls.management.entityTraceView,
  leftNavUrls.management.incidentView,
  leftNavUrls.management.report,
  leftNavUrls.management.serviceContent,
];

export const liveOnlyLinkGroups = []; // Currently, no link group falls into this scenario.
export const liveOnlyLinks = [
  leftNavUrls.management.appSetting,
  leftNavUrls.management.event,
  leftNavUrls.management.eventSubscription,
  leftNavUrls.management.streamRun,
  leftNavUrls.management.streamSchedule,
  leftNavUrls.management.team,
];

const removeInvalidLinkGroups = (
  result: INavLinkGroup[],
  linkGroupsToBeRemoved: string[],
  linksToBeRemoved: string[]
) => {
  for (var i = result.length - 1; i >= 0; i--) {
    var linkGroup = result[i];

    if (linkGroupsToBeRemoved.indexOf(linkGroup.name) >= 0) {
      result.splice(i, 1);
    } else {
      linkGroup.links && removeInvalidLinks(linkGroup.links, linksToBeRemoved);
    }
  }
};

const removeInvalidLinks = (links: INavLink[], linksToBeRemoved: string[]) => {
  for (var j = links.length - 1; j >= 0; j--) {
    var link = links[j];

    if (linksToBeRemoved.indexOf(link.key) >= 0) {
      links.splice(j, 1);
    }

    link.links && removeInvalidLinks(link.links, linksToBeRemoved);
  }
};
