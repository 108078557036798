import { getBasicPageCommandBarItems, getFilterOptions } from "../common/helper";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { IDevOpsViewStateProps, IDevOpsViewDispatchProps, IDevOpsViewState } from "./DevOpsView";
import { IDevOpsView, IDevOpsItem, IDevOpsViewFilter, IFilterItem, IFilter } from "../common/interfaces";
import { IGroup, IDropdownOption } from "@fluentui/react";

export const leafWorkItemTypes = ["Task", "Bug"];
export const featureRequestsViewId = "1";

export enum DevOpsViewFilterType {
  Dropdown = "Dropdown",
  Toggle = "Toggle",
}

export const getCommandBarProps = (
  props: IDevOpsViewStateProps & IDevOpsViewDispatchProps,
  state: IDevOpsViewState
): ICommandBarProps => {
  const { isSmallScreen, loadDevOpsViews, devOpsViewId } = props;

  var basicPageCommandBarActions = {
    refresh: () => loadDevOpsViews(devOpsViewId, true),
  };

  let commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions)],
  };

  return commandBarProps;
};

export const getWorkItemGroups = (devOpsView: IDevOpsView, devOpsItems: IDevOpsItem[]): IGroup[] => {
  if (devOpsView && devOpsView.includeDescendants !== true) return undefined;

  let groups: IGroup[] = [],
    itemTreeLookup = {},
    rootIdList = [],
    parentColumns = devOpsView && devOpsView.parentColumns;

  // First loop: build a lookup table for item to children mapping.
  devOpsItems &&
    devOpsItems.forEach((item, index) => {
      // Create its own item in the tree.
      if (!itemTreeLookup.hasOwnProperty(item.Id)) {
        itemTreeLookup[item.Id] = { ...item, index, children: [] };
      } else if (!itemTreeLookup[item.Id].Id) {
        itemTreeLookup[item.Id] = { ...itemTreeLookup[item.Id], ...item, index };
      }

      if (item.ParentId) {
        if (!itemTreeLookup.hasOwnProperty(item.ParentId)) {
          itemTreeLookup[item.ParentId] = { children: [] };
        }

        itemTreeLookup[item.ParentId].children.push({ ...item, index });
      } else {
        rootIdList.push(item.Id);
      }
    });

  // Then, recursively look for any group and its child groups.
  rootIdList.length &&
    rootIdList.forEach((rootId) => {
      var rootItem = itemTreeLookup[rootId],
        hasChildren = !!rootItem.children && rootItem.children.length > 0;

      if (hasChildren) {
        let group = getGroup(rootItem, 0, itemTreeLookup, parentColumns);
        groups.push(group);
      }
    });

  return groups;
};

const getGroup = (item, level: number, itemTreeLookup: object, parentColumns: IColumn[]) => {
  let children = itemTreeLookup[item.Id].children,
    hasChildren = children && children.length > 0,
    firstChild = hasChildren && children[0],
    allChildrenAreLeaves = true,
    workItemId = item["Id"],
    workItemType = item["System.WorkItemType"],
    workItemTitle = item["System.Title"];

  // Skip any leaf work item types.
  if (leafWorkItemTypes.indexOf(workItemType) <= 0) {
    children &&
      children.forEach((child) => {
        let grandChildren = itemTreeLookup[child.Id].children;
        if (grandChildren && grandChildren.length) {
          allChildrenAreLeaves = false;
        }
      });

    let key = workItemId,
      name = `${workItemType}: ${workItemTitle}`,
      childGroups = [],
      startIndex = 0,
      count = 0,
      data = { item, columns: parentColumns };

    if (allChildrenAreLeaves) {
      startIndex = firstChild ? firstChild.index : item.index;
      count = children ? children.length : 0;
    } else if (children) {
      children.forEach((child, index) => {
        let childGroup = getGroup(child, level + 1, itemTreeLookup, parentColumns);

        if (index === 0) {
          startIndex = child.index;
        }

        if (childGroup) {
          childGroups.push(childGroup);
          count += childGroup.count;
        } else {
          count++;
        }
      });
    }

    return { key, level, name, startIndex, count, children: childGroups, data };
  }

  return null;
};

export const getDevOpsFilterItems = (devOpsViewFilters: IDevOpsViewFilter[]): IFilterItem[] =>
  devOpsViewFilters
    ? devOpsViewFilters.map((filter) => {
        var value =
          filter.filterType !== DevOpsViewFilterType.Toggle
            ? filter.defaultValue
            : filter.defaultValue
            ? filter.onValue
            : filter.offValue;

        return {
          name: filter.fieldName,
          value,
        };
      })
    : [];

export const getFilteredDevOpsItems = (
  devOpsItems: IDevOpsItem[],
  searchText: string,
  viewColumnFieldNames: string[],
  inlineFilters: string[] = []
): IDevOpsItem[] => {
  if (!devOpsItems || !viewColumnFieldNames || !viewColumnFieldNames.length) return devOpsItems;

  searchText = searchText && searchText.toLowerCase();

  let filteredItemIds = [];

  devOpsItems &&
    devOpsItems.forEach((item) => {
      // If the item's parent is already in filtered list, select the item.
      if (item.ParentId && filteredItemIds.find((filteredItemId) => filteredItemId === item.ParentId)) {
        filteredItemIds.push(item.Id);
      } else {
        let isSearchTextFound = false,
          isInlineFilterFound = false;

        // Otherwise, see if the search text matches any of the item's field values.
        for (var i = 0; i < viewColumnFieldNames.length; i++) {
          let fieldName = viewColumnFieldNames[i],
            field = item[fieldName],
            fieldValue = field && field.toString && field.toString();

          if (searchText && fieldValue && fieldValue.toLowerCase().indexOf(searchText) >= 0) {
            isSearchTextFound = true;
          }

          if (inlineFilters && inlineFilters.length && inlineFilters.indexOf(fieldValue) >= 0) {
            isInlineFilterFound = true;
          }
        }

        // Check if it's in inline filters as well.
        if ((!searchText || isSearchTextFound) && (!inlineFilters || !inlineFilters.length || isInlineFilterFound)) {
          filteredItemIds.push(item.Id);
        }
      }
    });

  // Finally, if an item is filtered, all its ascendants should be filtered.
  for (var i = devOpsItems.length - 1; i >= 0; i--) {
    let devOpsItem = devOpsItems[i];

    if (!devOpsItem.ParentId) continue;

    var filterIndex = filteredItemIds.findIndex((itemId) => itemId === devOpsItem.Id);

    if (filterIndex >= 0) {
      var parentIndex = filteredItemIds.findIndex((itemId) => itemId === devOpsItem.ParentId);

      if (parentIndex < 0) {
        filteredItemIds.push(devOpsItem.ParentId);
      }
    }
  }

  return devOpsItems.filter((item) => filteredItemIds.indexOf(item.Id) >= 0);
};

export const getDevOpsViewColumnFieldNames = (columns: IColumn[]): string[] =>
  columns && columns.map((column) => column.fieldName);

export const getDropdownFilterOptions = (filter: IDevOpsViewFilter, devOpsItems: IDevOpsItem[]): IDropdownOption[] => {
  if (!filter.filterFields || !filter.filterFields.length) {
    return filter.options || [];
  }

  if (!devOpsItems || !devOpsItems.length) {
    return [];
  }

  let filters: IFilter[] = [];

  filter.filterFields.forEach((field) => {
    let filter = { name: field.displayName, items: [] };

    devOpsItems.forEach((devOpsItem) => {
      if (devOpsItem.hasOwnProperty(field.fieldName)) {
        let value = devOpsItem[field.fieldName];
        value = value.toString && value.toString();

        if (value && filter.items.findIndex((item) => item.value === value) < 0) {
          filter.items.push({ name: value, value });
        }
      }
    });

    if (filter.items.length) {
      filter.items = filter.items.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      filters.push(filter);
    }
  });

  return getFilterOptions(filters);
};

export const getDevOpsInlineFilters = (inlineFilters: string[], newValue: string): string[] => {
  let filters = inlineFilters ? inlineFilters.slice() : [],
    index = filters.indexOf(newValue);

  if (index >= 0) {
    filters.splice(index, 1);
  } else {
    filters.push(newValue);
  }

  return filters;
};

export const getDefaultDevOpsInlineFilters = (devOpsView: IDevOpsView): string[] => {
  let filters = [];

  devOpsView &&
    devOpsView.filters &&
    devOpsView.filters.forEach((filter) => {
      if (filter.defaultValue && typeof filter.defaultValue === "string") {
        filters = filters.concat(filter.defaultValue.split(","));
      }
    });

  return filters;
};
