import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyServiceContentList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyServiceContentList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Service Content"
        appInsightsPageName="MyServiceContentListPage"
        leftNavUrl={leftNavUrls.management.serviceContent}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadServiceContentConfigItems,
  deleteConfigItem: actionCreator.deleteServiceContentConfigItem,
  publishConfigItem: actionCreator.publishServiceContentConfigItem,
  unpublishConfigItem: actionCreator.unpublishServiceContentConfigItem,
  revertConfigItem: actionCreator.revertServiceContentConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyServiceContentList);
