import React from "react";
import { IconButton } from "@fluentui/react/lib/Button";
import classNames from "./GraphSearch.module.scss";
import { ISearchField, ISearchFilter } from "../common/interfaces";
import { IGraphSearchFilterActions } from "./helper";

export interface IGraphSearchEntityProps extends IGraphSearchFilterActions {
  selectedFilterIndexes: number[];
  searchFields: ISearchField[];
  searchFilters: ISearchFilter[];
  defaultSearchFilters: ISearchFilter[];
}

export const GraphSearchFilterButtons = ({
  selectedFilterIndexes,
  searchFields,
  searchFilters,
  defaultSearchFilters,
  onAddFilterClick,
  onEditFilterClick,
  onRemoveFilterClick,
  onGroupFilterClick,
  onUngroupFilterClick,
  onClearAllFiltersClick,
  onRestoreFiltersClick,
}: IGraphSearchEntityProps) => {
  if (searchFields?.length <= 0) return null;

  let pluralText = selectedFilterIndexes?.length > 1 ? "s" : "";
  let consecutiveTopLevelFiltersSelected = areConsecutiveTopLevelFiltersSelected(searchFilters, selectedFilterIndexes);
  let consecutiveGroupFiltersSelected = areConsecutiveGroupFiltersSelected(searchFilters, selectedFilterIndexes);

  return (
    <div className={classNames.filterButtonContainer}>
      <span className={classNames.filterButtonLabel}>Filter</span>
      <div className={classNames.filterButtonRoot}>
        <IconButton
          className={classNames.filterButton}
          iconProps={{ iconName: "Add" }}
          title="Add New Filter"
          onClick={onAddFilterClick}
        />
        {searchFilters?.length > 0 && (
          <IconButton
            className={classNames.filterButton}
            iconProps={{ iconName: "ClearFilter" }}
            title={`Clear All Filters`}
            onClick={onClearAllFiltersClick}
          />
        )}
        {defaultSearchFilters?.length > 0 && (
          <IconButton
            className={classNames.filterButton}
            iconProps={{ iconName: "FilterSettings" }}
            title={`Restore Default Filters`}
            onClick={onRestoreFiltersClick}
          />
        )}
        {selectedFilterIndexes?.length >= 1 && (
          <>
            <IconButton
              className={classNames.filterButton}
              iconProps={{ iconName: "Edit" }}
              title="Edit Selected Filter"
              disabled={selectedFilterIndexes?.length > 1}
              onClick={onEditFilterClick}
            />
            <IconButton
              className={classNames.filterButton}
              iconProps={{ iconName: "Delete" }}
              title={`Delete Selected Filter${pluralText}`}
              onClick={onRemoveFilterClick}
            />
            <IconButton
              className={classNames.filterButton}
              iconProps={{ iconName: "GroupObject" }}
              title="Group Selected Filters"
              disabled={selectedFilterIndexes?.length < 2 || !consecutiveTopLevelFiltersSelected}
              onClick={onGroupFilterClick}
            />
            <IconButton
              className={classNames.filterButton}
              iconProps={{ iconName: "UngroupObject" }}
              title={`Ungroup Selected Filter${pluralText}`}
              disabled={!consecutiveGroupFiltersSelected}
              onClick={onUngroupFilterClick}
            />
          </>
        )}{" "}
      </div>
    </div>
  );
};

const areConsecutiveTopLevelFiltersSelected = (searchFilters: ISearchFilter[], selectedIndexes: number[]): boolean => {
  if (!areFiltersConsecutive(selectedIndexes)) {
    return false;
  }

  let selectionIndex = 0;

  for (var i = 0; i < searchFilters?.length; i++) {
    var filter = searchFilters[i];

    if (filter.filters?.length) {
      for (var j = 0; j < filter.filters.length; j++) {
        if (selectedIndexes?.indexOf(selectionIndex++) >= 0) {
          return false;
        }
      }
    } else {
      selectionIndex++;
    }
  }

  return true;
};

const areConsecutiveGroupFiltersSelected = (searchFilters: ISearchFilter[], selectedIndexes: number[]): boolean => {
  if (!areFiltersConsecutive(selectedIndexes)) {
    return false;
  }

  let selectionIndex = 0;

  for (var i = 0; i < searchFilters?.length; i++) {
    var filter = searchFilters[i];

    if (filter.filters?.length) {
      for (var j = 0; j < filter.filters.length; j++) {
        selectionIndex++;
      }
    } else if (selectedIndexes?.indexOf(selectionIndex++) >= 0) {
      return false;
    }
  }

  return true;
};

const areFiltersConsecutive = (selectedIndexes: number[]): boolean => {
  if (selectedIndexes?.length >= 2) {
    for (var i = 1; i < selectedIndexes.length; i++) {
      if (selectedIndexes[i] - selectedIndexes[i - 1] !== 1) {
        return false;
      }
    }
  }
  return true;
};
