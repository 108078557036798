import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { ISystemMessage } from "../../app/interfaces";

export interface ISystemMessagesProps {
  systemMessages: ISystemMessage[];
}

export class SystemMessages extends React.Component<ISystemMessagesProps> {
  render() {
    const { systemMessages } = this.props;

    if (!systemMessages || !systemMessages.length) {
      return null;
    }

    return (
      <div>
        {systemMessages.map((systemMessage) => (
          <MessageBar messageBarType={getMessageBarType(systemMessage.messageType)}>{systemMessage.message}</MessageBar>
        ))}
      </div>
    );
  }
}

const getMessageBarType = (messageType: string): MessageBarType => {
  switch (messageType && messageType.toLowerCase()) {
    case "blocked":
      return MessageBarType.blocked;
    case "error":
      return MessageBarType.error;
    case "info":
      return MessageBarType.info;
    case "severewarning":
      return MessageBarType.severeWarning;
    case "success":
      return MessageBarType.success;
    case "warning":
      return MessageBarType.warning;
    default:
      return undefined;
  }
};
