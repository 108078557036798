import React, { CSSProperties } from "react";
import { getFormatContent } from "../../utilities/miscHelper";
import { IMetric } from "./interfaces";
import classNames from "./Node.module.scss";

export interface IMetricProps {
  metric: IMetric;
  className?: string;
  itemClassName?: string;
  style?: CSSProperties;
}

export class Metric extends React.Component<IMetricProps> {
  render() {
    const { metric, className, itemClassName, style } = this.props;

    return (
      <div className={`${classNames.metric} ${className}`} style={style}>
        <div className={`${classNames.metricName} ${itemClassName}`}>{metric.shortName || metric.name}:</div>
        <div className={`${classNames.metricValue} ${itemClassName}`} style={{ color: metric.color || "inherit" }}>
          {getFormatContent(metric, metric.displayType, "value", metric.template)}
        </div>
      </div>
    );
  }
}

export default Metric;
