import React from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import classNames from "./EntityDocModal.module.scss";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { AlertModal } from "../common/AlertModal";
import { IEntity, IEntityTest } from "../common/interfaces";
import Editor, { OnMount } from "@monaco-editor/react";
import { errorType } from "../interfaces";
import { setEditorLineDecorations } from "./jsonEditorHelper";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { BaseButton } from "@fluentui/react/lib/Button";
import { copyToClipboard } from "../../shared/utilities/miscHelper";

const MaxIdDisplayLength = 20;

export interface IEntityDocModalStateProps {
  showEntityDetails: boolean;
  loadingEntityDetails: boolean;
  entityDetails: object;
  entityDetailsError: string;
  entityTests: IEntityTest[];
  selectedEntity: IEntity;
}

export interface IEntityDocModalDispatchProps {
  hideEntityDetails: () => void;
  loadEntityDetails: (entityType: string, entityId: string, entityVersion: string) => void;
}

export interface IEntityDocModalState {
  entityType: string;
  entityId: string;
  entityVersion: string;
}

export class EntityDocModal extends React.Component<
  IEntityDocModalStateProps & IEntityDocModalDispatchProps,
  IEntityDocModalState
> {
  private editor;
  private monaco;

  constructor(props: IEntityDocModalStateProps & IEntityDocModalDispatchProps) {
    super(props);

    const { selectedEntity } = props;

    this.state = {
      entityType: selectedEntity?.entity?.entityType,
      entityId: selectedEntity?.entity?.id,
      entityVersion: selectedEntity?.entity?.version,
    };
  }

  componentDidUpdate() {
    const { showEntityDetails, entityDetails, entityTests } = this.props;
    const { entityType, entityId, entityVersion } = this.state;

    if (this.editor && showEntityDetails && entityDetails && entityTests) {
      try {
        setEditorLineDecorations(
          this.editor,
          this.monaco,
          classNames,
          entityTests,
          entityType,
          entityId,
          entityVersion
        );
      } catch {
        console.error("Fail to color related texts for validations.");
      }
    }
  }

  render() {
    const { showEntityDetails, selectedEntity, loadingEntityDetails, entityDetails, entityDetailsError } = this.props;

    if (!selectedEntity || !showEntityDetails) return null;

    const relatedEntities = getRelatedEntities(selectedEntity);

    const copyButton = <BaseButton className={classNames.button} onClick={this.onCopyContent} text="Copy" />;

    return (
      <AlertModal
        title="Entity Details"
        onCommit={this.onCloseModal}
        className={classNames.modal}
        modalRootClassName={classNames.modalRoot}
        modalContentClassName={classNames.modalContent}
        additionalButtons={copyButton}
      >
        <Pivot
          className={classNames.pivot}
          onLinkClick={this.onLinkClick}
          styles={{ root: { overflowY: "hidden", overflowX: "auto", maxWidth: "100vw" } }}
        >
          <PivotItem
            className={classNames.pivotItem}
            headerText={getEntityHeaderText(selectedEntity?.entity)}
            itemKey={getEntityItemKey(selectedEntity?.entity)}
          >
            <EntityDocContent
              entityDetails={entityDetails}
              entityDetailsError={entityDetailsError}
              loadingEntityDetails={loadingEntityDetails}
              targetEntity={selectedEntity}
              editorDidMount={this.editorDidMount}
            />
          </PivotItem>
          {relatedEntities?.map((relatedEntity, index) => {
            let key = getEntityItemKey(relatedEntity, index.toString());
            return (
              <PivotItem
                className={classNames.pivotItem}
                headerText={getEntityHeaderText(relatedEntity)}
                itemKey={key}
                key={key}
              >
                <EntityDocContent
                  entityDetails={entityDetails}
                  entityDetailsError={entityDetailsError}
                  loadingEntityDetails={loadingEntityDetails}
                  targetEntity={relatedEntity}
                  editorDidMount={this.editorDidMount}
                />
              </PivotItem>
            );
          })}
        </Pivot>
      </AlertModal>
    );
  }

  editorDidMount = (editor, monaco) => {
    this.editor = editor;
    this.monaco = monaco;
  };

  onLinkClick = (item: PivotItem) => {
    const { loadEntityDetails } = this.props,
      entity = item.props.itemKey.split(",");

    loadEntityDetails(entity[0], entity[1], entity[2]);
    this.setState({ entityType: entity[0], entityId: entity[1], entityVersion: entity[2] });
  };

  onCloseModal = () => {
    const { hideEntityDetails } = this.props;
    this.setState({ entityType: null, entityId: null, entityVersion: null });
    hideEntityDetails && hideEntityDetails();
  };

  onCopyContent = () => {
    const { entityDetails } = this.props;
    copyToClipboard(JSON.stringify(entityDetails, null, 2));
  };
}

const mapStateToProps = (state: IState): IEntityDocModalStateProps => ({
  showEntityDetails: state.modules.show_entity_details,
  loadingEntityDetails: state.modules.loading_entity_details,
  entityDetails: state.modules.entity_details,
  entityDetailsError: state.modules.errors[errorType.entityDetails],
  entityTests: state.modules.entity_tests,
  selectedEntity: state.modules.selected_entity,
});

const mapDispatchToProps = {
  hideEntityDetails: actionCreator.hideEntityDetails,
  loadEntityDetails: actionCreator.loadEntityDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityDocModal);

export interface IEntityDocContentProps {
  loadingEntityDetails: boolean;
  entityDetails: object;
  entityDetailsError: string;
  targetEntity: IEntity;
  editorDidMount: OnMount;
}

export const EntityDocContent = ({
  loadingEntityDetails,
  entityDetailsError,
  entityDetails,
  editorDidMount,
  targetEntity,
}: IEntityDocContentProps) => (
  <div className={classNames.entityDocContent}>
    {loadingEntityDetails ? (
      <Spinner
        styles={{
          root: classNames.spinner,
          circle: classNames.spinnerCircle,
          label: classNames.spinnerLabel,
        }}
      />
    ) : entityDetailsError ? (
      <div className={classNames.error}>
        No entity document is found for <b>{targetEntity.entityType}</b> with ID <b>{targetEntity.id}</b> and version{" "}
        <b>{targetEntity.version || 1}</b>
      </div>
    ) : (
      <Editor
        language="json"
        value={JSON.stringify(entityDetails, null, 2)}
        onMount={editorDidMount}
        options={{ minimap: { enabled: false } }}
      />
    )}
  </div>
);

export const getRelatedEntities = (selectedEntity: IEntity): IEntity[] => [
  ...(selectedEntity?.sources || []),
  ...(selectedEntity?.targets || []),
];

export const getEntityHeaderText = (entity: IEntity): string => {
  const entityType = entity.entityType,
    entityId = entity.id?.length > MaxIdDisplayLength ? entity.id?.substring(0, MaxIdDisplayLength) + "..." : entity.id,
    entityVersion = entity.version || 1;
  return `${entityType}, ${entityId}, v${entityVersion}`;
};

export const getEntityItemKey = (entity: IEntity, extraKey = ""): string =>
  `${entity.entityType},${entity.id},${entity.version || 1},${extraKey}`;
