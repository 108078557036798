import React from "react";
import { connect } from "react-redux";
import classNames from "./GraphHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { SelectionMode, CheckboxVisibility, DetailsList } from "@fluentui/react/lib/DetailsList";
import { Spinner } from "@fluentui/react/lib/Spinner";
import {
  getRelationItems,
  getRelationGroups,
  getFinancialEntityGroups,
  getRelationColumns,
  getFinancialEntityColumns,
} from "./EntityRelationships.helper";
import { IEntity, IFinancialEntity } from "../common/interfaces";
import { SearchItemKey } from "./helper";
import { searchItems } from "../../shared/utilities/tableHelper";
import { Tile } from "../../shared/components/Tile";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { getExcelDownloadCommandBarProps } from "../common/helper";
import memoize from "memoize-one";

export interface IEntityRelationshipsStateProps {
  selectedEntity: IEntity;
  financialEntities: IFinancialEntity[];
  selectedEntityType: string;
  loading: boolean;
  searchItemKey: SearchItemKey;
  serviceContentConfigs: any[];
}

export interface IEntityRelationshipsDispatchProps {
  loadEntity: (entityType: string, entityId: string, entityVersion: string) => void;
  downloadDataToExcel?: (data: any[], worksheetName: string) => void;
}

export interface IEntityRelationshipsState {
  searchText?: string;
}

export class EntityRelationships extends React.Component<
  IEntityRelationshipsStateProps & IEntityRelationshipsDispatchProps,
  IEntityRelationshipsState
> {
  state = { searchText: null };

  getItems = memoize((searchItemKey, selectedEntity, financialEntities, selectedEntityType): any[] => {
    let isEntityLookup = searchItemKey === SearchItemKey.entityLookup;

    return isEntityLookup ? getRelationItems(selectedEntity) : getEntitiesByType(financialEntities, selectedEntityType);
  });

  render() {
    const { loading, selectedEntity, financialEntities, selectedEntityType, searchItemKey, serviceContentConfigs } =
      this.props;
    const { searchText } = this.state;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading related entities..."
        />
      );
    }

    let isEntityLookup = searchItemKey === SearchItemKey.entityLookup,
      items = this.getItems(searchItemKey, selectedEntity, financialEntities, selectedEntityType);

    if (!items || !items.length) {
      return <div className={classNames.message}>No related entity is available.</div>;
    }

    if (searchText) {
      items = searchItems(items, searchText);
    }

    const serviceContentConfig = serviceContentConfigs?.find(
        (config) => config.name.toLowerCase() === selectedEntityType.toLowerCase()
      ),
      groups = isEntityLookup ? getRelationGroups(items) : getFinancialEntityGroups(items),
      columns = isEntityLookup
        ? getRelationColumns(this.onSelect)
        : getFinancialEntityColumns(this.onSelect, serviceContentConfig);

    return (
      <Tile
        title={`Related Entities for ${selectedEntityType}`}
        className={classNames.relationsTile}
        showSearchBox
        commandBarProps={this.getCommandBarProps()}
        onSearchChange={(newValue) => this.setState({ searchText: newValue })}
      >
        <DetailsList
          items={items}
          columns={columns}
          groups={groups}
          compact={true}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      </Tile>
    );
  }

  onSelect = (entity) => {
    if (entity) {
      const { loadEntity, searchItemKey } = this.props;
      let entityType = searchItemKey === SearchItemKey.entityLookup ? entity["entityType"] : entity["type"];
      loadEntity(entityType, entity["id"], entity["version"]);
    }
  };

  getCommandBarProps = (): ICommandBarProps => {
    const { searchItemKey, selectedEntity, financialEntities, selectedEntityType, downloadDataToExcel } = this.props;
    const items = this.getItems(searchItemKey, selectedEntity, financialEntities, selectedEntityType);

    return getExcelDownloadCommandBarProps(downloadDataToExcel, items, "Financial Entities");
  };
}

const mapStateToProps = (state: IState) => ({
  selectedEntity: state.modules.selected_entity,
  financialEntities:
    state.modules.search_item_key === SearchItemKey.entitySearch
      ? state.modules.entity_search_entities
      : state.modules.metric_search_entities,
  selectedEntityType: state.modules.search_entity_type,
  searchItemKey: state.modules.search_item_key,
  loading: state.modules.loading_entity_relationships || state.modules.loading_financial_entities,
  serviceContentConfigs: state.modules.service_content_configs,
});

const mapDispatchToProps = {
  loadEntity: actionCreator.loadEntity,
  downloadDataToExcel: actionCreator.downloadDataToExcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityRelationships);

const getEntitiesByType = (financialEntities: IFinancialEntity[], selectedEntityType: string): IFinancialEntity[] => {
  let result = financialEntities && financialEntities.filter((fe) => fe.type === selectedEntityType);
  return result && result.length ? result : financialEntities;
};
