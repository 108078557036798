import { IField, FieldType, IDataContext } from "../../../shared/components/DataForm";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { ICollectorService, ISourceCallType } from "../../common/interfaces";
import { checkIfUnique } from "../common/helper";

export const defaultCollectorService: ICollectorService = {
  id: "new",
};

export enum SourceCallType {
  AdlsGen1,
  AdlsGen2,
  configSource,
  DataLake,
  httpCall,
  EventHub,
  Kusto,
  ServiceBus,
  Storage,
}

export const getSourceCallTypeOptions = () => {
  let options = [];

  for (var sourceCallType in SourceCallType) {
    if (isNaN(Number(sourceCallType))) {
      options.push({ key: sourceCallType, text: sourceCallType, ariaLabel: sourceCallType });
    }
  }

  return options;
};

const getSourceCallFields = (parentDataContext: IDataContext): IField[] => {
  const context: ISourceCallType = parentDataContext.context;
  let sourceCallType: SourceCallType = SourceCallType[context.type];

  const contentTypeTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const sourceCallTypes: ISourceCallType = dataContext?.context;
    const contentTypes = sourceCallTypes?.contentTypes;
    return contentTypes?.length > index ? contentTypes[index].contentName : "";
  };

  const fields: IField[] = [
    {
      fieldName: "type",
      label: "Type",
      width: "120px",
      fieldType: FieldType.enumeration,
      options: getSourceCallTypeOptions(),
      required: true,
      checkIfValid: (dataContext: IDataContext, value: any) =>
        checkIfUnique(dataContext, value, "sourceCallTypes", "type"),
    },
  ];
  const contentTypes: IField[] = [
    {
      fieldName: "contentName",
      label: "Content Name",
      required: true,
      checkIfValid: (dataContext: IDataContext, value: any) =>
        checkIfUnique(dataContext, value, "contentTypes", "contentName"),
    },
  ];
  switch (sourceCallType) {
    case SourceCallType.AdlsGen1:
    case SourceCallType.AdlsGen2:
    case SourceCallType.DataLake:
      fields.push(
        ...[
          {
            fieldName: "accountName",
            label: "Account Name",
            width: "320px",
          },
          {
            fieldName: "sourceDataType",
            label: "Source Data Type",
            valueWidth: "60px",
          },
        ]
      );

      if (sourceCallType === SourceCallType.AdlsGen2) {
        fields.push(
          ...[
            {
              fieldName: "fileSystemName",
              label: "File System Name",
              width: "200px",
            },
          ]
        );
      }

      fields.push(
        ...[
          {
            fieldName: "filePath",
            label: "File Path",
            width: "100%",
          },
        ]
      );

      contentTypes.push(
        ...[
          {
            fieldName: "eventType",
            label: "Event Type",
          },
          {
            fieldName: "fileNameRegex",
            label: "File Name Regex",
            width: "200px",
          },
          {
            fieldName: "fields",
            label: "Fields",
            width: "100%",
            fieldType: FieldType.textArray,
            allowFullEditor: true,
            firstInRow: true,
          },
        ]
      );
      break;
    case SourceCallType.EventHub:
      fields.push(
        ...[
          {
            fieldName: "eventhubName",
            label: "Eventhub Name",
            width: "400px",
          },
          {
            fieldName: "eventhubNamespace",
            label: "Eventhub Namespace",
            width: "400px",
            lastInRow: true,
          },
        ]
      );
      break;
    case SourceCallType.ServiceBus:
    case SourceCallType.httpCall:
      fields.push(
        ...[
          {
            fieldName: "serviceApiAddress",
            label: "Service Api Address",
            width: "400px",
            required: true,
          },
          {
            fieldName: "requestUri",
            label: "Request Uri",
            width: "200px",
          },
          {
            fieldName: "journalStreamName",
            label: "Journal Stream Name",
            width: "200px",
            required: true,
          },
        ]
      );
      break;
    case SourceCallType.Storage:
      fields.push(
        ...[
          {
            fieldName: "storageUrl",
            label: "Storage URL",
            width: "400px",
            required: true,
          },
          {
            fieldName: "storageContainerName",
            label: "Storage Container Name",
            required: true,
          },
          {
            fieldName: "useUtc",
            label: "Use UTC",
            fieldType: FieldType.boolean,
          },
          {
            fieldName: "filePath",
            label: "File Path",
            required: true,
          },
          {
            fieldName: "sourceDataType",
            label: "Source Data Type",
            required: true,
          },
        ]
      );
      contentTypes.push({
        fieldName: "eventType",
        label: "Event Type",
      });

      break;
    case SourceCallType.Kusto:
      fields.push(
        ...[
          {
            fieldName: "tokenDateField",
            label: "Token Date Field",
          },
        ]
      );
      contentTypes.push(
        ...[
          {
            fieldName: "eventType",
            label: "Event Type",
          },
          {
            fieldName: "tableName",
            label: "Table Name",
          },
        ]
      );
      break;
    case SourceCallType.configSource:
      // This one requires additional implementation and coordination with Jacky if it is needed
      // It changes what is at the root level config, not just what is in the sourceCallTypes object
      break;
  }

  fields.push({
    fieldName: "contentTypes",
    label: "Content Types",
    fieldType: FieldType.items,
    collapsed: false,
    itemDisplayView: ItemDisplayView.list,
    itemTypeName: "Content Type",
    fields: contentTypes,
    customTitle: contentTypeTitle,
    customTabHeader: contentTypeTitle,
  });

  return fields;
};

export const getCollectorServiceFields = (collectorService: ICollectorService): IField[] => {
  if (!collectorService) return null;
  const generalFields = getGeneralFields(collectorService);
  const sourceCallTypes = collectorService.sourceCallTypes;
  let foundConfigSource = false;
  if (sourceCallTypes?.length && sourceCallTypes.find) {
    foundConfigSource = !!sourceCallTypes.find((sourceCallType) => sourceCallType.type === "configSource");
  }

  if (foundConfigSource) {
    generalFields.push(
      ...[
        {
          fieldName: "configSource",
          label: "Config Sources",
          fieldType: FieldType.context,
          collapsed: false,
          itemTypeName: "Config Source",
          fields: [
            {
              fieldName: "sourceStorageType",
              label: "Source Storage Type",
            },
            {
              fieldName: "sourceDataType",
              label: "Source Data Type",
            },
            {
              fieldName: "sourceStorageUrl",
              label: "Source Storage URL",
            },
            {
              fieldName: "sourceStorageContainerName",
              label: "Source Storage Container Name",
            },
            {
              fieldName: "sourceFilePath",
              label: "Source File Path",
            },
            {
              fieldName: "isFirstLineHeader",
              label: "Is First Line Header",
            },
          ],
        },
        {
          fieldName: "tenantId",
          label: "Tenant ID",
          width: "285px",
        },
        {
          fieldName: "clientId",
          label: "Client ID",
          width: "285px",
        },
        {
          fieldName: "processTimeInterval",
          label: "Process Time Interval",
          width: "150px",
        },
        {
          fieldName: "startTime",
          label: "Start Time",
          width: "150px",
        },
      ]
    );
  }

  return generalFields;
};

const getGeneralFields = (collectorService: ICollectorService): IField[] => {
  if (!collectorService) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      fieldType: FieldType.displayOnly,
      value: collectorService.id,
    },
    {
      fieldName: "name",
      label: "Name",
      value: collectorService.name,
      required: true,
      width: "300px",
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "isTrigger",
      fieldType: FieldType.boolean,
      label: "Is Trigger",
      value: collectorService.isTrigger,
      lastInRow: true,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: collectorService.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: collectorService.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: collectorService.description,
      lastInRow: true,
    },
    {
      fieldName: "hasLineItems",
      fieldType: FieldType.boolean,
      label: "Has Line Items",
      value: collectorService.hasLineItems,
    },
    {
      fieldName: "getNonTestOnly",
      fieldType: FieldType.boolean,
      label: "Get Non-Test Only",
      value: collectorService.getNonTestOnly,
    },
    {
      fieldName: "isJournalDataArray",
      fieldType: FieldType.boolean,
      label: "Is Journal Data Array",
      value: collectorService.isJournalDataArray,
    },
    {
      fieldName: "feTypePath",
      label: "Entity Type Path",
      value: collectorService.feTypePath,
    },
    {
      fieldName: "apiVersion",
      label: "API Version",
      value: collectorService.apiVersion,
      width: "100px",
    },
    {
      fieldName: "lineItemApiAddress",
      label: "Line Item API Address",
      value: collectorService.lineItemApiAddress,
      lastInRow: true,
    },
    {
      fieldName: "tenantId",
      label: "Tenant ID",
      value: collectorService.tenantId,
      width: "300px",
      hints: "Tenant ID for data source",
    },
    {
      fieldName: "clientId",
      label: "Client ID",
      value: collectorService.clientId,
      width: "300px",
      hints: "Client ID for data source",
    },
    {
      fieldName: "clientSecretKVPropName",
      label: "KV Prop Name for Client Secret",
      value: collectorService.clientSecretKVPropName,
      width: "300px",
      hints: "KeyVault prop name for Client Secret",
    },
    {
      fieldName: "sourceCallTypes",
      label: "Source Call Types",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.list,
      itemTypeName: "Source Call Type",
      fields: getSourceCallFields,
    },
    {
      fieldName: "lineItemPath",
      label: "Line Item Path",
      width: "400px",
      value: collectorService.lineItemPath,
    },
    {
      fieldType: FieldType.container,
      label: "Miscellaneous Settings",
      collapsed: false,
      fields: [
        {
          fieldName: "pageSizeCount",
          fieldType: FieldType.number,
          label: "Page Size Count",
          value: collectorService.pageSizeCount,
          valueWidth: "60px",
        },
        {
          fieldName: "nextToken",
          label: "Next Token",
          value: collectorService.nextToken,
          valueWidth: "100px",
        },
        {
          fieldName: "startTime",
          fieldType: FieldType.number,
          label: "Start Time",
          value: collectorService.startTime,
          valueWidth: "40px",
        },
      ],
    },
  ];
};
