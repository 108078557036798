import React from "react";
import { connect } from "react-redux";
import monitorIcon from "../../images/MonitorIcon.svg";
import detectIcon from "../../images/DetectIcon.svg";
import analyzeIcon from "../../images/AnalyzeIcon.svg";
import manageIcon from "../../images/ManageIcon.svg";
import arrowRightIcon from "../../images/ArrowRight.svg";
import { Link } from "react-router-dom";
import { actionCreator } from "../../app/duck";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { appInsights } from "../../app/telemetryHelper";
import classNames from "./HomePage.module.scss";
import { IState } from "../../reducers/interfaces";
import { IUserInfo } from "../../app/interfaces";
import { Spinner } from "@fluentui/react";

export interface IHomePageState {
  scrollTop: number;
  initialized: boolean;
}

export interface IHomePageStateProps {
  userInfo?: IUserInfo;
}

export interface IHomePageDispatchProps {
  selectLeftNavLinkByUrl: Function;
}

export class HomePage extends React.Component<IHomePageStateProps & IHomePageDispatchProps, IHomePageState> {
  private whatsNewSection: HTMLDivElement;
  private appContentDiv: HTMLDivElement;

  constructor(props: IHomePageDispatchProps) {
    super(props);
    this.state = {
      scrollTop: 0,
      initialized: false,
    };
  }

  componentDidMount() {
    const { selectLeftNavLinkByUrl } = this.props;

    appInsights.trackPageView({ name: "RadarHomePage" });
    selectLeftNavLinkByUrl(null);
  }

  componentDidUpdate() {
    if (!this.state.initialized) {
      this.setState({
        initialized: true,
      });
    }
  }

  render() {
    const { selectLeftNavLinkByUrl, userInfo } = this.props;
    const { scrollTop } = this.state;

    const headerContentStyle = {
      backgroundPositionY: scrollTop / -3 + "px",
    };

    if (!userInfo) {
      return <Spinner className={classNames.spinner} />;
    }

    return (
      <div
        className={classNames.appModuleContent}
        onScroll={this.onContentScroll}
        ref={(el) => (this.appContentDiv = el)}
      >
        <div className={classNames.headerContent} style={headerContentStyle}>
          <div className={classNames.appModuleContentCenter}>
            <div className={classNames.headerPane}>
              <div className={classNames.title}>Commerce Radar</div>
              <div className={classNames.headerText}>
                Tracking the systematic and financial health of Microsoft commerce systems
              </div>
            </div>
          </div>
        </div>
        {userInfo?.error && <div className={classNames.error}>{userInfo?.error}</div>}
        <div className={classNames.contentSection}>
          <div className={`${classNames.subjectContentPane} ${classNames.appModuleContentCenter}`}>
            <div className={classNames.subjectPane}>
              <img className={classNames.subjectImage} src={monitorIcon} alt="Monitor" />
              <div className={classNames.subjectTitle}>Monitor</div>
              <div className={classNames.subjectText}>
                Monitor the flow of information across our commerce systems, processes and services
              </div>
            </div>
            <div className={classNames.arrowPane}>
              <img className={classNames.arrowImage} src={arrowRightIcon} alt="Arrow Right" />
            </div>
            <div className={classNames.subjectPane}>
              <img className={classNames.subjectImage} src={detectIcon} alt="Detect" />
              <div className={classNames.subjectTitle}>Detect</div>
              <div className={classNames.subjectText}>Detect and predict anomalies in our financial pipelines</div>
            </div>
            <div className={classNames.arrowPane}>
              <img className={classNames.arrowImage} src={arrowRightIcon} alt="Arrow Right" />
            </div>
            <div className={classNames.subjectPane}>
              <img className={classNames.subjectImage} src={analyzeIcon} alt="Analyze" />
              <div className={classNames.subjectTitle}>Analyze</div>
              <div className={classNames.subjectText}>
                Analyze the flow of information across systems through reports and graphs
              </div>
            </div>
            <div className={classNames.arrowPane}>
              <img className={classNames.arrowImage} src={arrowRightIcon} alt="Arrow Right" />
            </div>
            <div className={classNames.subjectPane}>
              <img className={classNames.subjectImage} src={manageIcon} alt="Manage" />
              <div className={classNames.subjectTitle}>Manage</div>
              <div className={classNames.subjectText}>Create and manage reports, controls, tasks and views</div>
            </div>
          </div>
        </div>
        <div className={`${classNames.contentSection} ${classNames.systemContentSection}`}>
          <div className={`${classNames.systemContentPane} ${classNames.appModuleContentCenter}`}>
            <div className={classNames.systemPane}>
              <div className={classNames.systemTitle}>Controls &amp; Recons</div>
              <div className={classNames.systemText}>
                Transaction level reconciliations and summary level financial controls provide holistic view into
                Commerce systematic and financial health
              </div>
              <Link
                className={classNames.viewLink}
                to={leftNavUrls.all.controlsCompliance}
                aria-label="View Controls and Recons page now"
                onClick={() => selectLeftNavLinkByUrl(leftNavUrls.all.controlsCompliance)}
              >
                View now &gt;
              </Link>
            </div>
            <div className={classNames.systemPane}>
              <div className={classNames.systemTitle}>Financial Incidents</div>
              <div className={classNames.systemText}>Up-to-date view of all financial incidents in one place</div>
              <Link
                className={classNames.viewLink}
                to={leftNavUrls.all.financialIncidents}
                aria-label="View Financial Incidents page now"
                onClick={() => selectLeftNavLinkByUrl(leftNavUrls.all.financialIncidents)}
              >
                View now &gt;
              </Link>
            </div>
            <div className={classNames.systemPane}>
              <div className={classNames.systemTitle}>Process Flows</div>
              <div className={classNames.systemText}>
                A view into our Commerce processes, tracking data flow and evaluating key business metrics to guage our
                monthly performance{" "}
              </div>
              <Link
                className={classNames.viewLink}
                to={leftNavUrls.analytics.launchmetrics}
                aria-label="View Process Flows page now"
                onClick={() => selectLeftNavLinkByUrl(leftNavUrls.analytics.launchmetrics)}
              >
                View now &gt;
              </Link>
            </div>
            <div className={classNames.systemPane}>
              <div className={classNames.systemTitle}>Commerce Graphs</div>
              <div className={classNames.systemText}>
                Track and validate Commerce data at a line item level and perform root cause analyis across the stack.
              </div>
              <Link
                className={classNames.viewLink}
                to={leftNavUrls.all.graph}
                aria-label="View Commerce Graph page now"
                onClick={() => selectLeftNavLinkByUrl(leftNavUrls.all.graph)}
              >
                View now &gt;
              </Link>
            </div>
          </div>
        </div>
        {/* <div className={classNames.whatsNewSection} ref={(el) => (this.whatsNewSection = el)}>
          {this.whatsNewContent(selectLeftNavLinkByUrl)}
        </div> */}
        <div className={classNames.footer}>
          Microsoft Internal Use Only &copy; {new Date().getFullYear()} Microsoft |{" "}
          <a href="https://go.microsoft.com/fwlink/?LinkId=518021" target="_blank" rel="noopener noreferrer">
            Privacy
          </a>
        </div>
      </div>
    );
  }

  whatsNewContent = (selectLeftNavLinkByUrl) => (
    <>
      <div className={classNames.firstCell}>&nbsp;</div>
      <div className={classNames.contentCell}>
        <div className={classNames.headerPane}>What's New?</div>
        <div className={classNames.itemPane}>
          <div className={classNames.title}>Process SLA</div>
          <div className={classNames.text}>
            Monthly Operational KPIs and insights of Revenue and Billing Platform financial systems
          </div>
          <Link
            className={classNames.viewLink}
            to={leftNavUrls.all.okr}
            aria-label="View Process SLA page now"
            onClick={() => selectLeftNavLinkByUrl(leftNavUrls.all.okr)}
          >
            View now &gt;
          </Link>
        </div>
        <div className={classNames.itemPane}>
          <div className={classNames.title}>MSSales Audits</div>
          <div className={classNames.text}>
            Audits for early detection and correction to deliver accurate MSSales reporting
          </div>
          <Link
            className={classNames.viewLink}
            to={leftNavUrls.all.controlsMsSales}
            aria-label="View MSSales Audits page now"
            onClick={() => selectLeftNavLinkByUrl(leftNavUrls.all.controlsMsSales)}
          >
            View now &gt;
          </Link>
        </div>
        <div className={classNames.itemPane}>
          <div className={classNames.title}>NCE Adoption</div>
          <div className={classNames.text}>
            Get insights for CSP to NCE Adoption (Revenue, Partner TPID and End Customer TPID)
          </div>
          <Link
            className={classNames.viewLink}
            to={leftNavUrls.salesMotions.partnerLed}
            aria-label="View NCE Adoption page now"
            onClick={() => selectLeftNavLinkByUrl(leftNavUrls.salesMotions.partnerLed)}
          >
            View now &gt;
          </Link>
        </div>
      </div>
      <div className={classNames.lastCell}>&nbsp;</div>
    </>
  );

  onContentScroll = (ev: React.UIEvent<HTMLDivElement>) => {
    let element = ev.target as HTMLDivElement;

    this.setState({ scrollTop: element?.scrollTop });
  };
}

const mapStateToProps = (state: IState): IHomePageStateProps => ({
  userInfo: state.app.login_user_info,
});

const mapDispatchToProps: IHomePageDispatchProps = {
  selectLeftNavLinkByUrl: actionCreator.selectLeftNavLinkByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
