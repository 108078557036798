import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Account } from "msal/lib-es6";

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      window.location.hostname === "radar.microsoft.com"
        ? "09f7444f-f412-43cf-a8aa-31aff8b49d28"
        : "f8a6a991-4a7a-46e2-a7dc-06334e6631ea",
  },
});

appInsights.loadAppInsights();

export const initializeTelemetry = (user?: Account) => {
  if (user) {
    appInsights.setAuthenticatedUserContext(user.userName);
  }
};
