import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyEntityViewList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyEntityViewList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Entity View"
        appInsightsPageName="MyEntityViewListPage"
        leftNavUrl={leftNavUrls.management.entityView}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadEntityViewConfigItems,
  deleteConfigItem: actionCreator.deleteEntityViewConfigItem,
  publishConfigItem: actionCreator.publishEntityViewConfigItem,
  unpublishConfigItem: actionCreator.unpublishEntityViewConfigItem,
  revertConfigItem: actionCreator.revertEntityViewConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEntityViewList);
