import React from "react";
import { Label as UIFabricLabel } from "@fluentui/react/lib/Label";
import { IVisualProps } from "./Visual";
import { getFormatContent, ContentDisplayType } from "../../utilities/miscHelper";
import classNames from "./Visual.module.scss";

export class Label extends React.Component<IVisualProps> {
  render() {
    const {
      label,
      data,
      categoryFieldName = "category",
      valueFieldNames = ["value"],
      labelStyle,
      valueStyle,
      style,
    } = this.props;

    const dataItem = (data && data.length && data[0]) || {},
      labelText = dataItem[categoryFieldName] || label,
      valueText = valueFieldNames.length && dataItem[valueFieldNames[0]];

    return (
      <div className={classNames.root} style={style}>
        {labelText && (
          <UIFabricLabel style={labelStyle}>
            {getFormatContent({ labelText }, ContentDisplayType.html, null, labelText)}
          </UIFabricLabel>
        )}
        {valueText && (
          <UIFabricLabel className={classNames.labelValue} style={valueStyle}>
            {getFormatContent({ valueText }, ContentDisplayType.html, null, valueText)}
          </UIFabricLabel>
        )}
      </div>
    );
  }
}

export default Label;
