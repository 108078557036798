import { IField, FieldType } from "../../../shared/components/DataForm";
import { IEvent } from "../../common/interfaces";

export const defaultEvent = {
  id: "",
};

export const getFields = (event: IEvent): IField[] => {
  if (!event) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      value: event.id,
      lastInRow: true,
      required: true,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: event.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: event.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "objectType",
      label: "Object Type",
      fieldType: FieldType.enumeration,
      value: event.objectType,
      options: ["", "Control", "Entity", "Notification", "Unknown"].map((objectType) => ({
        key: objectType,
        text: objectType,
      })),
      required: true,
    },
    {
      fieldName: "enabled",
      label: "Enabled",
      fieldType: FieldType.boolean,
      value: event.enabled,
    },
  ];
};
