import React, { useState } from "react";
import classNames from "./Chat.module.scss";
import { ChatAlign, ChatRole, IChatMessage } from "./Chat.types";
import { Icon } from "@fluentui/react";
import ReactMarkdown from "react-markdown";
import { ChatMessageActions } from "./ChatMessageActions";
import { getSystemActionIcons } from "./ChatMessage.helper";
import { copyToClipboard } from "../../utilities/miscHelper";

export const LinkRenderer = (props: any) => (
  <a href={props.href} target="_blank" rel="noreferrer">
    {props.children}
  </a>
);

export const BoldTextRenderer = (props: any) => {
  const { children } = props;
  const text = children?.length && children[0];
  const color =
    text === "on track" ? "green" : text === "at slight risk" ? "golenrod" : text === "at risk" ? "red" : undefined;

  return color ? <strong style={{ color: color }}>{text}</strong> : <strong>{text}</strong>;
};

export interface IChatMessageProps {
  message: IChatMessage;
  userPhoto?: string;
  convertMarkdown?: boolean;
}

export const ChatMessage = (props: IChatMessageProps) => {
  const { message, userPhoto, convertMarkdown } = props;
  const [isMessageHovered, setIsMessageHovered] = useState<boolean>(false);
  const isRightAlign = message.align === ChatAlign.right || message.role === ChatRole.user;

  const messageRowClassNames = [classNames.messageRow];
  const messageClassNames = [classNames.contentBox, classNames.message];
  let actionIcons = undefined;

  const onMessageCopy = () => {
    copyToClipboard(message.content);
  };

  const onMessageHandled = (message: string): string =>
    message.replace(/on track|at slight risk|at risk/gi, (matched) => `__${matched}__`);

  if (isRightAlign) {
    messageRowClassNames.push(classNames.messageRowRightAlign);
    messageClassNames.push(classNames.messageRightAlign);
  } else {
    messageRowClassNames.push(classNames.messageRowLeftAlign);
    messageClassNames.push(classNames.messageLeftAlign);
    actionIcons = getSystemActionIcons(onMessageCopy);
  }

  const showActionIcons = isMessageHovered && !!actionIcons?.length && message.role === ChatRole.assistant;
  const messageContent = onMessageHandled(message.content);

  return (
    <div
      className={messageRowClassNames.join(" ")}
      onMouseEnter={() => setIsMessageHovered(true)}
      onMouseLeave={() => setIsMessageHovered(false)}
    >
      {!isRightAlign && <Icon iconName="ChatBot" className={classNames.systemIcon} />}
      <div className={messageClassNames.join(" ")}>
        {convertMarkdown ? (
          <ReactMarkdown children={messageContent} components={{ a: LinkRenderer, strong: BoldTextRenderer }} />
        ) : (
          <div>{messageContent}</div>
        )}
        {showActionIcons && <ChatMessageActions actionIcons={actionIcons} />}
      </div>
      {isRightAlign && userPhoto ? (
        <img src={userPhoto} alt="User" className={classNames.userImage} />
      ) : (
        <Icon iconName="User" className={classNames.userIcon} />
      )}
    </div>
  );
};

export default ChatMessage;
