import React from "react";

import { Label } from "@fluentui/react";

interface props {
  id: string;
  readonly?: boolean;
  label?: string;
  onChange?: (string) => void;
  value: string;
  fieldProps?: object;
}

class RichTextEditor extends React.Component<props> {
  render() {
    const { id, label, fieldProps } = this.props;

    return (
      <>
        {label ? <Label id={`${id}-label`}>{label}</Label> : null}
        <textarea {...fieldProps} />
      </>
    );
  }
}

export default RichTextEditor;
