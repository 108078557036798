import React from "react";
import { connect } from "react-redux";
import AppModuleHeader from "../common/AppModuleHeader";
import { actionCreator } from "../duck";
import classNames from "./SearchHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { ISearchResultItem } from "../common/interfaces";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Checkbox, Spinner } from "@fluentui/react";
import SearchResult from "./SearchResult";

export interface ISearchHomeStateProps {
  searchResult: ISearchResultItem[];
  loading: boolean;
  isSmallScreen: boolean;
}

export interface ISearchHomeDispatchProps {
  loadSearchResult: () => void;
  loadSearchSuggestions: () => void;
}

export interface ISearchHomeProps extends ISearchHomeStateProps, ISearchHomeDispatchProps {}

export class SearchHome extends React.Component<ISearchHomeProps> {
  render() {
    const { loading, searchResult } = this.props;

    return (
      <div className={classNames.appModuleContent}>
        <AppModuleHeader headerTextOverride="Commerce Search" />
        <div className={classNames.searchPane}>
          <SearchBox className={classNames.searchBox} />
          <div className={classNames.optionPane}>
            <Checkbox className={classNames.optionCheckbox} label="Controls" checked />
            <Checkbox className={classNames.optionCheckbox} label="Incidents" checked />
            <Checkbox className={classNames.optionCheckbox} label="Reports" checked />
          </div>
        </div>
        {loading ? (
          <Spinner className={classNames.spinner} label="Loading search result..." role="alert" />
        ) : (
          <SearchResult searchResult={searchResult} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState): ISearchHomeStateProps => ({
  searchResult: testSearchResult, //state.modules.search_result,
  loading: state.modules.loading_search_result,
  isSmallScreen: state.app.is_small_screen,
});

const mapDispatchToProps: ISearchHomeDispatchProps = {
  loadSearchResult: actionCreator.loadSearchResult,
  loadSearchSuggestions: actionCreator.loadSearchSuggestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHome);

const testSearchResult = [
  {
    link: `${window.location.protocol}//${window.location.host}/control/162`,
    title: "Control: CO4.1b-MOD_Collector_to_Ledger_Recon",
    description: "Total transaction dollar amounts are reconciled between ChargeAggregator++ and Ledger.",
  },
];
