import React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import classNames from "./GraphSearch.module.scss";
import { TextField } from "@fluentui/react/lib/TextField";

export interface IGraphSearchEntityProps {
  selectedEntityId: string;
  selectedEntityType: string;
  selectedEntityVersion: string;
  entityTypeOptions: IDropdownOption[];
  setSearchEntityType: (entityType: string) => void;
  setSearchEntityId: (entityId: string) => void;
  setSearchEntityVersion: (entityVersion: string) => void;
  onKeyDown: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearchEntity: () => void;
}

export const GraphSearchEntityLookup = ({
  selectedEntityId,
  selectedEntityType,
  selectedEntityVersion,
  entityTypeOptions,
  setSearchEntityType,
  setSearchEntityId,
  setSearchEntityVersion,
  onKeyDown,
  onSearchEntity,
}: IGraphSearchEntityProps) => {
  return (
    <div className={classNames.searchInputs}>
      <Dropdown
        className={classNames.typeDropdown}
        label="Entity Type"
        options={entityTypeOptions}
        selectedKey={selectedEntityType}
        placeholder="Select Entity Type"
        onChange={(ev, option) => setSearchEntityType(option.key.toString())}
      />
      <TextField
        className={classNames.idField}
        label="Entity ID"
        value={selectedEntityId}
        onChange={(ev, newValue) => setSearchEntityId(newValue)}
        onKeyDown={onKeyDown}
        placeholder="Enter Entity ID"
      />
      <TextField
        className={classNames.versionField}
        label="Version (optional)"
        value={selectedEntityVersion}
        onChange={(ev, newValue) => setSearchEntityVersion(newValue)}
        onKeyDown={onKeyDown}
        placeholder="Enter Version"
      />
      <PrimaryButton
        className={classNames.searchButton}
        text="Find Entity"
        onClick={onSearchEntity}
        disabled={!selectedEntityType || !selectedEntityId}
      />
    </div>
  );
};
