import { Pivot, PivotItem, Spinner } from "@fluentui/react";
import React, { useCallback, useContext, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { ConfigItemType, fetchConfigItems, fetchStreamSchedules, fetchStreamRuns } from "../../api/radarApi";
import { useBrowserState, useInterval } from "../../shared/utilities/hooks";
import AppModuleHeader from "../common/AppModuleHeader";
import { ITeam } from "../common/interfaces";
import { dataRefreshIntervalInMinutes } from "./StreamsBoard";
import { getUpStreamRuns, getDownStreamRuns, getCopilotDataText, processStreamRuns } from "./StreamsHome.helper";
import classNames from "./StreamsHome.module.scss";
import StreamsTeam from "./StreamsTeam";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { AppModuleContext } from "../common/AppModule";
import { AppContext } from "../../app/App";

export interface IMatchParams {
  teamId?: string;
}

export interface IStreamsHomeProps extends RouteChildrenProps<IMatchParams> {}

export const StreamsHome = (props: IStreamsHomeProps) => {
  const { match, history } = props;
  const [teamId, setTeamId] = useState<string>(match?.params?.teamId || "");
  const [upStreamRuns, setUpStreamRuns] = useState<any[]>(undefined);
  const [downStreamRuns, setDownStreamRuns] = useState<any[]>(undefined);
  const [lastRefreshTime, setLastRefreshTime] = useBrowserState("lastRefreshTime");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(undefined);
  const [teams, setTeams] = useState<ITeam[]>();
  const [selectedTeam, setSelectedTeam] = useState<ITeam>(undefined);
  const { changeAppModuleState } = useContext(AppModuleContext);
  const { changeAppState } = useContext(AppContext);

  const loadStreamsData = useCallback(() => {
    // Retrieve teams data.
    setLoading(true);
    setSelectedTeam(undefined);
    setUpStreamRuns(undefined);
    setDownStreamRuns(undefined);
    setError(undefined);

    fetchConfigItems(ConfigItemType.Teams)
      .then((teams: any) => {
        setTeams(teams);

        let team = teams?.find((team) => team.id === teamId);
        team && setSelectedTeam(team);

        // Retrieve schedules data.
        fetchStreamSchedules()
          .then((streamSchedules: any) => {
            // Retrieve runs data.
            fetchStreamRuns()
              .then((streamRuns: any) => {
                processStreamRuns(streamRuns);

                let upStreamRuns = getUpStreamRuns(streamRuns, team, streamSchedules),
                  downStreamRuns = getDownStreamRuns(streamRuns, team, streamSchedules);

                setUpStreamRuns(upStreamRuns);
                setDownStreamRuns(downStreamRuns);
                setLastRefreshTime(new Date().toLocaleString());

                // Enable copilot and supply stream runs data to it.
                let copilotDataText = getCopilotDataText(downStreamRuns, upStreamRuns);
                changeAppState({ useCopilot: true });
                changeAppModuleState({ copilotDataText });
              })
              .catch((error) => setError(error))
              .finally(() => setLoading(false));
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [teamId, setLastRefreshTime, changeAppState, changeAppModuleState]);

  const onPivotLinkClick = useCallback(
    (item: PivotItem) => {
      let selectedTeamId = item.props.itemKey;

      setTeamId(selectedTeamId);
      history.push(`${leftNavUrls.streams}/${selectedTeamId}`);
    },
    [history]
  );

  // Refresh data automatically.
  useInterval(loadStreamsData, dataRefreshIntervalInMinutes * 60000);

  const headerText = selectedTeam ? `StreamAware for ${selectedTeam.name}` : "StreamAware";

  return (
    <div className={classNames.appModuleContent}>
      <AppModuleHeader headerTextOverride={headerText} />
      {error && <div className={classNames.error}>{error}</div>}
      {loading && <Spinner label="Loading stream data..." />}
      {!loading && teams && (
        <Pivot linkSize="large" className={classNames.pivot} selectedKey={teamId} onLinkClick={onPivotLinkClick}>
          <PivotItem key="streamTeamAll" headerText="All Teams" itemKey={""}>
            <StreamsTeam teamId="" {...{ selectedTeam, upStreamRuns, downStreamRuns, lastRefreshTime }} />
          </PivotItem>
          {teams
            ?.filter((team) => team.streamEnabled)
            .map((team) => (
              <PivotItem key={`streamTeam${team.id}`} headerText={team.name} itemKey={team.id}>
                <StreamsTeam teamId={teamId} {...{ selectedTeam, upStreamRuns, downStreamRuns, lastRefreshTime }} />
              </PivotItem>
            ))}
        </Pivot>
      )}
    </div>
  );
};

export default StreamsHome;
