export const getQueryParam = (paramName: string, location: any = window.location) => {
  var urlParams = new URLSearchParams(location.search);
  return urlParams.get(paramName);
};

export const setQueryParam = (
  paramName: string,
  paramValue: string,
  location: any = window.location,
  history: any = window.history
) => {
  var urlParams = new URLSearchParams(location.search);
  urlParams.set(paramName, paramValue);

  var url = new URL(location.href);
  url.search = urlParams.toString();

  history.pushState && history.pushState(null, document.title, url.href);
};
