import { IFmeCloseIssuesHomeProps } from "./FmeCloseIssuesHome";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { getBasicPageCommandBarItems } from "../common/helper";
import moment from "moment";
import classNames from "./FmeCloseIssuesList.module.scss";

export const issueColumns = [
  {
    key: "createdDate",
    fieldName: "createdDate",
    name: "Created Date",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
    isSortedDescending: true,
    isSorted: true,
    onRender: (item) => item["createdDate"] && new Date(item["createdDate"]).toISOString().substring(0, 10),
  },
  {
    key: "resolutionDate",
    fieldName: "resolutionDate",
    name: "Resolution Date",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => item["resolutionDate"] && new Date(item["resolutionDate"]).toISOString().substring(0, 10),
  },
  {
    key: "area",
    fieldName: "area",
    name: "Area",
    minWidth: 80,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "issueOwner",
    fieldName: "issueOwner",
    name: "Issue Owner",
    minWidth: 80,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "issue",
    fieldName: "issue",
    name: "Issue",
    minWidth: 300,
    isResizable: true,
    isMultiline: true,
    onRender: (item) => <div dangerouslySetInnerHTML={{ __html: item["issue"] }} />,
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => (
      <span
        className={classNames.status}
        style={{ backgroundColor: item["status"] === "Yellow" ? "#8B8000" : item["status"] }}
      >
        {item["status"]}
      </span>
    ),
  },
  {
    key: "statusNote",
    fieldName: "statusNote",
    name: "Note",
    minWidth: 60,
    isResizable: true,
    isMultiline: true,
    onRender: (item) => <div dangerouslySetInnerHTML={{ __html: item["statusNote"] }} />,
  },
];

export const getCommandBarProps = (props: IFmeCloseIssuesHomeProps): ICommandBarProps => {
  const { loadTeams, isSmallScreen } = props;

  var basicPageCommandBarActions = {
    refresh: () => loadTeams(true),
  };

  return {
    items: [],
    farItems: [...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions)],
  };
};

const startingDate = "2022-03-01";
const maxMonths = 14;

export const getMonthPickerOptions = () => {
  var options = [];
  var now = new Date();
  var currMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  for (var i = 0; i < maxMonths; i++) {
    var key = currMonth.toISOString().substring(0, 10),
      text = moment(currMonth).format("MMMM YYYY");

    options.push({ key, text, ariaLabel: text });

    currMonth.setMonth(currMonth.getMonth() - 1);

    var lastMonth = currMonth.toISOString().substring(0, 10);

    if (lastMonth < startingDate) {
      break;
    }
  }

  return options;
};
