import { entityBackgroundColor } from "./helper";
import { IDiagram, Position } from "../../shared/components/Diagram";
import { ContentDisplayType } from "../../shared/utilities/miscHelper";
import { IEntityTraceMetric } from "../common/interfaces";
import { IEntityTraceView } from "../common/interfaces";

const nodeWidth = 200;
const nodeHeight = 40;

const entityTraceDiagram: IDiagram = {
  defaultNodeProps: {
    draggable: false,
    height: nodeHeight,
    width: nodeWidth,
    style: {
      fontSize: "12px",
      backgroundColor: "#777",
      color: "white",
      borderWidth: "0px",
      borderRadius: "0px",
      boxShadow: "0 2px 3px #ccc",
      textOverflow: "hidden",
    },
    infoPane: { position: Position.top },
  },
  defaultLinkProps: {
    lineMarkerType: 1,
    style: {
      strokeWidth: "1px",
    },
    animation: true,
  },
  autoSizing: true,
};

export const getEntityTraceDiagram = (
  entityTraceMetrics: IEntityTraceMetric[],
  entityTraceView: IEntityTraceView
): IDiagram => {
  if (!entityTraceView) {
    return null;
  }

  let { nodes, links } = entityTraceView;
  // Keep original view so we can re-use when switching entities
  let nodesNew = JSON.parse(JSON.stringify(nodes));
  let diagram: IDiagram = {
    ...entityTraceView,
    ...entityTraceDiagram,
  };

  diagram.nodes = nodesNew;
  diagram.links = links;

  // Associate the related metrics to the entity trace nodes.
  diagram.nodes &&
    diagram.nodes.forEach((node) => {
      if (node.id.endsWith("Failures")) {
        let failures =
          entityTraceMetrics &&
          entityTraceMetrics.filter(
            (e) =>
              e.logType !== "Event" && e.logName.startsWith(node.id.substring(0, 3)) && !e.logName.includes("-DLQ-")
          );

        if (failures && failures.length) {
          let beginEvent = null;
          let beginEvents = null;
          if (node.id.startsWith("DC-")) {
            beginEvent = entityTraceMetrics.find(
              (e) => e.logName.endsWith("UpdateJournalWithFinancialEntity") && e.logType === "Event"
            );
          } else if (node.id.startsWith("VP-")) {
            beginEvents = entityTraceMetrics.filter(
              (e) => e.logName.startsWith("VP-") && e.logName.endsWith("-MQ-MessageReceived") && e.logType === "Event"
            );
          } else {
            beginEvent = entityTraceMetrics.find((e) => e.logName === "EP-MQ-MessageReceived" && e.logType === "Event");
          }
          let beginTime = beginEvent && beginEvent["timeStamp"];

          failures.forEach((failure) => {
            if (beginEvents) {
              beginEvent = beginEvents.find((e) => e.logName.includes(failure["logName"].split("-")[1]));
              beginTime = beginEvent && beginEvent["timeStamp"];
            }

            // events are sorted by max timestamp to remove duplicates
            // So we will only show failures which occurs after the first associated processing event
            if (beginTime && failure["timeStamp"] >= beginTime) {
              if (!node.metrics) {
                node.style = {
                  ...node.style,
                  visibility: "visible",
                };
                node.metrics = [];
              }

              node.metrics.push({
                ...failure,
                name: failure["logType"],
                value: getFailureReason(failure),
              });

              // TODO: build a custom dialog window and data structure to show failure details
              if (failure["logType"] === "Exception") {
                node.metrics.push({
                  ...failure,
                  name: "ExceptionDetails",
                  value: JSON.parse(failure["details"])["exception"],
                  hidden: true,
                });
              }
            }
          });
        }
      } else {
        let targetEntity =
          entityTraceMetrics && entityTraceMetrics.find((e) => e.logName === node.id && e.logType === "Event");

        if (targetEntity) {
          node.style = { backgroundColor: entityBackgroundColor };
          node.metrics = [];
          node.metrics.push({
            ...targetEntity,
            name: "Ts",
            value: targetEntity["timeStamp"],
            displayType: ContentDisplayType.datetime,
          });
        } else {
          node.metrics = null;
        }
      }
    });

  return diagram;
};

const getFailureReason = (failure: IEntityTraceMetric) => {
  var details = JSON.parse(failure["details"]);
  var logName = failure["logName"].split("-");
  var reason = null;
  switch (failure["logType"]) {
    case "Exception": {
      if (details["processName"]) {
        reason = details["processName"];
      } else {
        reason = logName[logName.length - 1];
      }
      break;
    }
    case "PlatformException": {
      reason = details["platformExceptionType"];
      break;
    }
    case "ProcessFailure": {
      reason = details["failureReason"];
      break;
    }
    default:
      break;
  }

  if (reason && logName[0] === "VP") {
    reason = logName[1] + "-" + reason;
  }
  return reason;
};
