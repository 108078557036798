import { MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import * as radarApi from "../../api/radarApi";
import { DataForm, FieldType } from "../../shared/components/DataForm";
import AppModuleHeader from "../common/AppModuleHeader";
import ControlItemList from "../controls/ControlItemList";
import { IControlReport } from "../controls/interfaces";
import classNames from "./ServicebusMessageExplorer.module.scss";
import { AppContext } from "../../app/App";

export interface ISVBConfig {
  endpoint: string;
  topic: string;
  subscription: string;
}

const ServicebusMessageExplorer: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { featureFlags } = appState;
  const [isPeekLoading, setIsPeekLoading] = useState<boolean>(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(false);
  const [peekError, setPeekError] = useState<string>("");
  const [summaryError, setSummaryError] = useState<string>("");
  const defaultSVBConfig: ISVBConfig = { endpoint: "", topic: "", subscription: "" };
  const [svbconfig, setSvbConfig] = useState<ISVBConfig>(defaultSVBConfig);
  const [topics, setTopics] = useState<[]>([]);
  const [subscriptions, setSubscriptions] = useState<[]>([]);
  const defaultPeekResults: IControlReport = {
    columns: [],
    items: [],
    isSortedDescending: false,
    sortedColumnKey: "",
    searchText: "",
    announcedMessage: "",
    totalCount: 0,
  };
  const [peekResults, setPeekResults] = useState<IControlReport>(defaultPeekResults);

  const [summaryResults, setSummaryResults] = useState<IControlReport>(defaultPeekResults);

  useEffect(() => {
    radarApi.getServiceBusTopic(svbconfig.endpoint).then((topics: any) => {
      setTopics(topics);
    });
  }, [svbconfig.endpoint]);

  useEffect(() => {
    setSvbConfig({ endpoint: svbconfig.endpoint, topic: svbconfig.topic, subscription: "" });
    radarApi.getServiceBusSubscriptionByTopic(svbconfig.endpoint, svbconfig.topic).then((subscriptions: any) => {
      setSubscriptions(subscriptions);
    });
  }, [svbconfig.endpoint, svbconfig.topic]);

  const showQueryFormatWarning =
    peekResults &&
    peekResults.items &&
    peekResults.items.length > 0 &&
    (!peekResults.items[0].MessageId || !peekResults.items[0].Content.toString());

  const getTopicOptions = () => {
    let topicValue = [];
    if (topics) {
      for (var topic in topics) {
        topicValue.push({ key: topics[topic], text: topics[topic] });
      }
    }
    return topicValue;
  };

  const getSubscriptionOptions = () => {
    let subscriptionValue = [];
    if (subscriptions) {
      for (var index in subscriptions) {
        subscriptionValue.push({ key: subscriptions[index], text: subscriptions[index] });
      }
    }
    return subscriptionValue;
  };

  const onCheckSummary = () => {
    setIsSummaryLoading(true);
    setSummaryResults(defaultPeekResults);
    setSummaryError("");
  };

  const onCheckPeek = () => {
    setIsPeekLoading(true);
    setPeekResults(defaultPeekResults);
    setPeekError("");
  };

  const checkSummary = () => {
    onCheckSummary();

    radarApi
      .getServiceBusMessageSummary(svbconfig)
      .then((summaryResults: any) => {
        summaryResults = [summaryResults];

        setSummaryResults((prevState) => ({
          ...prevState,
          columns:
            summaryResults.length > 0
              ? [
                  {
                    key: "endpoint",
                    name: "EndPoint",
                    fieldName: "endpoint",
                    minWidth: 300,
                    maxWidth: 400,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.EndPoint,
                  },
                  {
                    key: "topicpath",
                    name: "TopicPath",
                    fieldName: "topicpath",
                    minWidth: 120,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.TopicPath,
                  },
                  {
                    key: "subscriptionname",
                    name: "SubscriptionName",
                    fieldName: "subscriptionname",
                    minWidth: 120,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.SubscriptionName,
                  },
                  {
                    key: "activeMessagecount",
                    name: "ActiveMessageCount",
                    fieldName: "activeMessagecount",
                    minWidth: 120,
                    maxWidth: 160,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.ActiveMessageCount,
                  },
                  {
                    key: "dlqmessagecount",
                    name: "DLQMessageCount",
                    fieldName: "dlqmessagecount",
                    minWidth: 120,
                    maxWidth: 160,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.DLQMessageCount,
                  },
                  {
                    key: "accessedat",
                    name: "AccessedAt",
                    fieldName: "accessedat",
                    minWidth: 60,
                    maxWidth: 100,
                    isResizable: true,
                    onRender: (summaryResults: any) => summaryResults.AccessedAt,
                  },
                ]
              : [],
          items: summaryResults,
          totalCount: summaryResults.length,
        }));
      })
      .catch((error) => {
        setSummaryError(error);
      })
      .finally(() => {
        setIsSummaryLoading(false);
      });
  };

  const checkPeek = () => {
    onCheckPeek();

    radarApi
      .peekServiceBusMessage(svbconfig)
      .then((peekResults: any) => {
        peekResults = peekResults.map((item) => {
          const newItem = {};
          for (const key in item) {
            if (typeof item[key] === "object") {
              newItem[key] = JSON.stringify(item[key]);
            } else {
              newItem[key] = item[key];
            }
          }
          return newItem;
        });

        setPeekResults((prevState) => ({
          ...prevState,
          columns:
            peekResults.length > 0
              ? [
                  {
                    key: "messageid",
                    name: "MessageId",
                    fieldName: "messageid",
                    minWidth: 30,
                    maxWidth: 100,
                    isResizable: true,
                    onRender: (peekResults: any) => peekResults.MessageId,
                  },
                  {
                    key: "content",
                    name: "Content",
                    fieldName: "content",
                    minWidth: 60,
                    isResizable: true,
                    onRender: (peekResults: any) => peekResults.Content,
                  },
                  {
                    key: "enqueuedtimeutc",
                    name: "EnqueuedTimeUtc",
                    fieldName: "enqueuedtimeutc",
                    minWidth: 30,
                    maxWidth: 100,
                    isResizable: true,
                    onRender: (peekResults: any) => peekResults.EnqueuedTimeUtc,
                  },
                ]
              : [],
          items: peekResults,
          totalCount: peekResults.length,
        }));
      })
      .catch((error) => {
        setPeekError(error);
      })
      .finally(() => {
        setIsPeekLoading(false);
      });
  };

  return featureFlags?.disableServiceBusMessageExplorer ? (
    <div className="appModuleContent">
      <h1>ServiceBus Message Explorer</h1>
      <p>ServiceBus Message Explorer is currently disabled.</p>
    </div>
  ) : (
    <div className="appModuleContent">
      <AppModuleHeader headerTextOverride="ServiceBus Message Explorer" />
      <div className={classNames.servicebusMessageExplorer}>
        <p>
          Admins can use this page to peek ServiceBus message. You can specify endpoint,topic and subscription to return
          certain servicebus meesages, endpoint is optional, default is environment specific servicebus endpoint.
        </p>
        <p>
          Sample configuration: EndPoint:asb-commerceradar-processor-int.servicebus.windows.net;
          Topic:topic-financialentityfact; Subscription:sub-financialEntityFact.
        </p>
        <DataForm
          fields={[
            {
              fieldName: "endpoint",
              label: "EndPoint (optional)",
              className: classNames.servicebusMessageExplorerConfig,
              width: "400px",
              hints: "ServiceBus Endpoint sample:asb-commerceradar-processor-int.servicebus.windows.net",
            },
            {
              fieldName: "topic",
              label: "Topic",
              fieldType: FieldType.enumeration,
              options: getTopicOptions(),
              width: "400px",
              hints: "ServiceBus Topic, sample:topic-financialentityfact",
            },
            {
              fieldName: "subscription",
              label: "Subscription",
              fieldType: FieldType.enumeration,
              options: getSubscriptionOptions(),
              width: "400px",
              hints: "ServiceBus Subscription under this topic, sample:sub-financialEntityFact",
            },
            {
              fieldName: "count",
              label: "Count",
              fieldType: FieldType.number,
              width: "60px",
              hints: "Specific expected count when peeking from ServiceBus, default count chosen is 10.",
            },
          ]}
          context={svbconfig}
          onFieldValueChange={(fieldName, newValue) => {
            let newConfig = { ...svbconfig };
            newConfig[fieldName] = newValue;
            setSvbConfig(newConfig);
          }}
        />
        <div className={classNames.divcontent}>
          <PrimaryButton
            text="Check Subscription Status"
            className={classNames.checkButton}
            onClick={checkSummary}
            disabled={!svbconfig}
          />
          <PrimaryButton
            text="Peek Message"
            className={classNames.checkButton}
            onClick={checkPeek}
            disabled={!svbconfig}
          />
        </div>

        {showQueryFormatWarning && (
          <MessageBar messageBarType={MessageBarType.warning}>
            The results do not contain the required fields from servicebus message .
          </MessageBar>
        )}

        <ControlItemList
          className={classNames.servicebusMessageSummaryResults}
          controlReport={summaryResults}
          title="Servicebus Current Status"
          loading={isSummaryLoading}
          error={summaryError}
          noItemText="<No results / start new servibus config>"
        />

        <ControlItemList
          className={classNames.servicebusMessagePeekResults}
          controlReport={peekResults}
          title="Servicebus Message Peek Results"
          loading={isPeekLoading}
          error={peekError}
          noItemText="<No results / start new servibus config>"
        />
      </div>
    </div>
  );
};

export default ServicebusMessageExplorer;
