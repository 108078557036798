import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyCollectorServiceList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyCollectorServiceList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Collector Service"
        appInsightsPageName="MyCollectorServiceListPage"
        leftNavUrl={leftNavUrls.management.collectorService}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadCollectorServiceConfigItems,
  deleteConfigItem: actionCreator.deleteCollectorServiceConfigItem,
  publishConfigItem: actionCreator.publishCollectorServiceConfigItem,
  unpublishConfigItem: actionCreator.unpublishCollectorServiceConfigItem,
  revertConfigItem: actionCreator.revertCollectorServiceConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCollectorServiceList);
