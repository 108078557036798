import { IEntityTest } from "../common/interfaces";
import * as jsonPath from "jsonpath";

export const setEditorLineDecorations = (
  editor,
  monaco,
  classNames,
  entityTests: IEntityTest[],
  entityType: string,
  entityId: string,
  entityVersion: string
) => {
  let highlights = [],
    firstLineNo = 0,
    editorValue = editor.getValue(),
    editorJson = editorValue && JSON.parse(editorValue);

  editorJson &&
    entityTests?.length &&
    entityTests.forEach((test) => {
      let testResult = test.validationResult,
        isTestPassed = testResult?.toLowerCase() === "pass",
        testDetails = test.validationDetails,
        testDetailsJson = null,
        attributeMappingsText = null;

      try {
        testDetailsJson = JSON.parse(testDetails);
      } catch {}

      if (!testDetailsJson) return;

      if (
        !entityType ||
        (test.financialEntityType === entityType &&
          test.financialEntityId === entityId &&
          test.financialEntityVersion === entityVersion)
      ) {
        attributeMappingsText = testDetailsJson["targetAttributeMapping"];
      } else {
        // For source documents, only handle tests that are matching the source entity.
        let sourceEntity = testDetailsJson["sourceEntity"];

        if (
          sourceEntity &&
          sourceEntity.type === entityType &&
          sourceEntity.id === entityId &&
          sourceEntity.version === entityVersion
        ) {
          attributeMappingsText = testDetailsJson["sourceAttributeMapping"];
        }
      }

      let attributeMappings = attributeMappingsText?.split(";");

      attributeMappings?.forEach((attributeMapping) => {
        if (!attributeMapping) return;

        let attributePaths = jsonPath.paths(editorJson, attributeMapping),
          lineNo = 0;

        attributePaths?.length > 0 &&
          attributePaths.forEach((path) => {
            path.length > 0 &&
              path.forEach((node, index) => {
                if (node !== "$") {
                  if (!isNaN(node as any)) {
                    if (node > 0) {
                      for (var i = 0; i < node; i++) {
                        lineNo = getLineNoOfNextArrayItem(editorValue, lineNo);
                      }
                    }
                  } else {
                    let searchString = `"${node}": `;

                    lineNo = getLineNo(editorValue, searchString, lineNo);

                    if (index === path.length - 1 && lineNo > 0) {
                      highlights.push({
                        range: new monaco.Range(lineNo, 1, lineNo, 1000),
                        options: {
                          isWholeLine: true,
                          linesDecorationsClassName: isTestPassed
                            ? classNames.editorLinePass
                            : classNames.editorLineFail,
                          className: isTestPassed ? classNames.editorInlinePass : classNames.editorInlineFail,
                        },
                      });

                      firstLineNo === 0 && (firstLineNo = lineNo);
                    }
                  }
                }
              });
          });
      });
    });

  highlights.length && editor.deltaDecorations([], highlights);
  firstLineNo && editor.revealLineInCenter(firstLineNo);
};

const getLineNo = (editorValue: string, searchString: string, lineNo: number = 0): number => {
  let startPosition = getPosition(editorValue, lineNo);

  let index = editorValue.indexOf(searchString, startPosition),
    contentUpToIndex = editorValue.substring(0, index);

  return contentUpToIndex.split("\n").length;
};

const getLineNoOfNextArrayItem = (editorValue: string, lineNo: number): number => {
  let position = getPosition(editorValue, lineNo),
    openCurlyCount = 0;

  do {
    let openCurlyPos = editorValue.indexOf("{", position),
      closeCurlyPos = editorValue.indexOf("}", position);

    if (openCurlyPos < lineNo && closeCurlyPos < lineNo) {
      return lineNo;
    }

    if (openCurlyPos < closeCurlyPos) {
      openCurlyCount++;
      position = openCurlyPos + 1;
    } else {
      openCurlyCount--;
      position = closeCurlyPos + 1;
    }
  } while (openCurlyCount > 0);

  let contentUpToIndex = editorValue.substring(0, position);
  return contentUpToIndex.split("\n").length;
};

const getPosition = (editorValue: string, lineNo: number): number => {
  let position = 0;
  if (lineNo) {
    for (var i = 0; i < lineNo; i++) {
      position = editorValue.indexOf("\n", position) + 1;
    }
  }
  return position;
};
