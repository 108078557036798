import React, { useMemo } from "react";
import { useStyle } from "../../utilities/hooks";
import { ContentDisplayType, getFormatContent } from "../../utilities/miscHelper";
import classNames from "./Gauge.module.scss";
import { Icon, TooltipHost } from "@fluentui/react";

export interface IGaugeProps {
  value?: number;
  className?: string;
  maxValue?: number;
  minValue?: number;
  valueDisplayType?: ContentDisplayType;
  showValueText?: boolean;
  showMinMaxValueText?: boolean;
  color?: string;
  colorDomain?: string;
  size?: number;
  thickness?: number;
  valueTextStyle?: React.CSSProperties;
  circleStyle?: React.CSSProperties;
  needleStyle?: React.CSSProperties;
  topLabel?: string;
  topLabelStyle?: React.CSSProperties;
  topLabelTooltip?: string;
  bottomLabel?: string;
  bottomLabelStyle?: React.CSSProperties;
  bottomLabelTooltip?: string;
}

export const Gauge = (props: IGaugeProps) => {
  const {
      value = 50,
      size = 200,
      thickness = 20,
      color = "gray",
      colorDomain,
      minValue = 0,
      maxValue = 100,
      showValueText = true,
      showMinMaxValueText = true,
      valueDisplayType = ContentDisplayType.percentage,
      topLabel,
      topLabelTooltip,
      bottomLabel,
      bottomLabelTooltip,
      valueTextStyle,
      needleStyle,
      circleStyle,
      topLabelStyle,
      bottomLabelStyle,
      className,
    } = props,
    radius = size / 2,
    innerRadius = radius - thickness,
    circumference = innerRadius * 2 * Math.PI,
    arc = circumference * 0.5,
    dashArray = `${arc} ${circumference}`,
    transform = `rotate(180, ${radius}, ${radius})`,
    valueDegree = !isNaN(value) ? (Number(value) * 180) / (maxValue - minValue) : 0,
    valueRadian = valueDegree * (Math.PI / 180) + Math.PI,
    markerWidth = Math.min(Math.round(size * 0.1), 10),
    markerHeight = Math.min(Math.round(size * 0.07), 7),
    needleLength = innerRadius - thickness,
    needleX2 = Math.cos(valueRadian) * needleLength + radius,
    needleY2 = Math.sin(valueRadian) * needleLength + radius,
    minMaxValueTextTop = radius + 15,
    minMaxValueTextPad = size * 0.05,
    colorValues = color.split(","), // Assume at least one color is defined.
    colorDomainValues = colorDomain?.split(","),
    colorDomainPortion = 1 / colorValues.length;

  const finalNeedleStyle = {
    animation: `${classNames.needleRotate} 1s`,
    transformOrigin: `${radius}px ${radius}px`,
    ...needleStyle,
  };

  const keyframes = useMemo(
    () => `@keyframes ${classNames.needleRotate} { 
      from { transform: rotate(${-1 * valueDegree}deg)} 
      to { transform: rotate(0deg)}
    }`,
    [valueDegree]
  );

  const getLabelContent = (className, style, label, labelTooltip) =>
    label && (
      <div className={className} style={style}>
        {labelTooltip ? (
          <TooltipHost
            id={`labelTooltip-${className}`}
            tooltipProps={labelTooltip && getLabelTooltipProps(labelTooltip)}
          >
            <div>
              <span>{label}</span>
              <Icon iconName="Info" className={classNames.tooltipIcon} tabIndex={0} />
            </div>
          </TooltipHost>
        ) : (
          label
        )}
      </div>
    );

  useStyle(keyframes, [valueDegree]);

  return (
    <div className={`${classNames.root} ${className}`} style={{ width: `${size}px` }}>
      {getLabelContent(classNames.topLabelText, topLabelStyle, topLabel, topLabelTooltip)}
      <svg height={radius + 15} width={size}>
        <defs>
          <marker
            id="arrowhead"
            markerWidth={markerWidth}
            markerHeight={markerHeight}
            refX="0"
            refY={markerHeight / 2}
            orient="auto"
          >
            <polygon points={`0 0, ${markerWidth} ${markerHeight / 2}, 0 ${markerHeight}`} />
          </marker>
        </defs>
        {colorValues.reverse().map((colorValue, index) => {
          let colorDomainValue =
            index < colorDomainValues?.length ? Number(colorDomainValues[index]) : index * colorDomainPortion;
          return (
            <circle
              className={classNames.circleBase}
              style={circleStyle}
              cx={radius}
              cy={radius}
              fill="transparent"
              r={innerRadius}
              stroke={colorValue}
              strokeDashoffset={arc * colorDomainValue}
              strokeDasharray={dashArray}
              strokeWidth={thickness}
              transform={transform}
            />
          );
        })}
        {value >= 0 && (
          <>
            <line
              className={classNames.needle}
              x1={radius}
              y1={radius}
              x2={needleX2}
              y2={needleY2}
              stroke="#888"
              strokeWidth="1"
              markerEnd="url(#arrowhead)"
              style={finalNeedleStyle}
            />
            <circle
              className={classNames.needleBasePoint}
              cx={radius}
              cy={radius}
              fill="transparent"
              r={2}
              stroke="#333"
              strokeWidth={1}
            />
          </>
        )}
        {showMinMaxValueText && (
          <>
            <text
              className={classNames.minMaxValueText}
              x={minMaxValueTextPad}
              y={minMaxValueTextTop}
              textAnchor="start"
            >
              {getFormatContent({ item: minValue, displayType: valueDisplayType, decimal: 0 })}
            </text>
            <text
              className={classNames.minMaxValueText}
              x={size - minMaxValueTextPad}
              y={minMaxValueTextTop}
              textAnchor="end"
            >
              {getFormatContent({ item: maxValue, displayType: valueDisplayType, decimal: 0 })}
            </text>
          </>
        )}
      </svg>
      {showValueText && (
        <div className={classNames.valueText} style={valueTextStyle}>
          {getFormatContent(value, valueDisplayType)}
        </div>
      )}
      {getLabelContent(classNames.bottomLabelText, bottomLabelStyle, bottomLabel, bottomLabelTooltip)}
    </div>
  );
};

const getLabelTooltipProps = (tooltipContent) => ({
  onRenderContent: () => <div className={classNames.tooltip} dangerouslySetInnerHTML={{ __html: tooltipContent }} />,
});
