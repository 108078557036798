import React from "react";
import { IConfigHistory } from "./ConfigHistory.helper";
import classNames from "./ConfigHistory.module.scss";
import { DisplayStyle, JsonEditor } from "./JsonEditor";
import { trimBase64Source } from "../../../shared/utilities/miscHelper";
import { IconButton, IModalProps, Modal } from "@fluentui/react";

export interface IConfigHistoryModalProps extends IModalProps {
  selectedItems: any[];
  historyItems: IConfigHistory[];
}

export const ConfigHistoryModal = (props: IConfigHistoryModalProps) => {
  const { selectedItems, historyItems, isOpen, onDismiss } = props;

  let modalTitle = selectedItems?.length > 1 ? "Comparing Revisions" : "Revision Details",
    firstItem = selectedItems?.length ? selectedItems[0] : { revision: 1 },
    secondItem = selectedItems?.length > 1 ? selectedItems[1] : undefined,
    leftHistory: IConfigHistory,
    rightHistory: IConfigHistory,
    leftContent = "",
    rightContent = "",
    iconButtonStyles = {
      root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        float: "right",
      },
    };

  historyItems?.forEach((history) => {
    if (history.revision === firstItem?.revision) {
      leftHistory = history;
    }
    if (history.revision === secondItem?.revision) {
      rightHistory = history;
    }
  });

  if (leftHistory && rightHistory) {
    if (leftHistory.revision > rightHistory.revision) {
      rightContent = leftHistory.config;
      leftContent = rightHistory.config;
    } else {
      leftContent = leftHistory.config;
      rightContent = rightHistory.config;
    }
  } else if (leftHistory) {
    leftContent = leftHistory.config;
  }

  return (
    <Modal isOpen={isOpen} containerClassName={classNames.root} styles={{}}>
      <h1 id="modalTitle" className={classNames.title}>
        {modalTitle}
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </h1>
      {rightContent ? (
        <JsonEditor
          configHistory={historyItems}
          displayStyle={DisplayStyle.diffWithOriginal}
          leftSide={trimBase64Source(leftContent)}
          rightSide={trimBase64Source(rightContent)}
          isSuperAdmin={false}
          enableUiValidationToggle={false}
        />
      ) : (
        <JsonEditor
          configHistory={historyItems}
          displayStyle={DisplayStyle.single}
          leftSide={trimBase64Source(leftContent)}
          isSuperAdmin={false}
          enableUiValidationToggle={false}
        />
      )}
    </Modal>
  );
};

export default ConfigHistoryModal;
