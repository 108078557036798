import React from "react";
import { DetailsList, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { getCurrentOkrs, columns } from "./helper";
import classNames from "./OkrList.module.scss";
import { ITeam } from "../common/interfaces";

export interface IOkrListProps {
  team: ITeam;
  selectedDate: string;
}

export class OkrList extends React.Component<IOkrListProps> {
  render() {
    const { team, selectedDate } = this.props;
    const finalOkrs = getCurrentOkrs(team, selectedDate);

    if (!finalOkrs.length) {
      return <div className={classNames.error}>No OKR is defined for the selected month of this team.</div>;
    }

    return (
      <div className={classNames.root}>
        <DetailsList items={finalOkrs} columns={columns} selectionMode={SelectionMode.none} />
      </div>
    );
  }
}

export default OkrList;
