import React from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import classNames from "./EntityMetricFilter.module.scss";
import { IEntityMetric } from "../common/interfaces";
import { SearchItemKey } from "./helper";

export interface IEntityMetricFilterStateProps {
  selectedMetricIds: string[];
  entityMetrics: IEntityMetric[];
  loading: boolean;
  isMetricSearch: boolean;
}

export interface IEntityMetricFilterDispatchProps {
  changeEntityMetricSelection: (metricId: string) => void;
}

export class EntityMetricFilter extends React.Component<
  IEntityMetricFilterStateProps & IEntityMetricFilterDispatchProps
> {
  render() {
    const { selectedMetricIds, entityMetrics, loading, isMetricSearch, changeEntityMetricSelection } = this.props;

    if (loading || !isMetricSearch) {
      return null;
    }

    const options = [];

    entityMetrics?.forEach((em) => {
      em.metrics &&
        em.metrics.forEach((m) => {
          if (!options.find((o) => o.key === m.id)) {
            options.push({ key: m.id, text: m.name, arialLabel: m.name });
          }
        });
    });

    if (!options.length) {
      return null;
    }

    return (
      <div className={classNames.root}>
        <Dropdown
          className={classNames.dropdown}
          options={options}
          multiSelect={true}
          selectedKeys={selectedMetricIds}
          placeholder="Select metrics"
          ariaLabel="Select metrics"
          styles={{ dropdown: { maxWidth: 200 } }}
          dropdownWidth={200}
          onChange={(ev, item: IDropdownOption) => changeEntityMetricSelection(item.key.toString())}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IState): IEntityMetricFilterStateProps => ({
  selectedMetricIds: state.modules.selected_metric_ids,
  entityMetrics: state.modules.entity_metrics_by_selected_view,
  loading: state.modules.loading_entity_relationships || state.modules.loading_entity_metrics,
  isMetricSearch: state.modules.search_item_key === SearchItemKey.metricSearch,
});

const mapDispatchToProps = {
  changeEntityMetricSelection: actionCreator.changeEntityMetricSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityMetricFilter);
