import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultIncidentView, getFields } from "./MyIncidentViewEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { ConfigItemType } from "../../../api/radarApi";

export class MyIncidentViewEdit extends React.Component<IConfigItemEditProps> {
  private editForm: React.RefObject<any> = React.createRef();

  render() {
    const { configValue, editConfigItem } = this.props;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Incident View"
        configItemType={ConfigItemType.IncidentViews}
        appInsightsPageName="MyIncidentViewEditPage"
        leftNavUrl={leftNavUrls.management.incidentView}
        defaultConfigItem={defaultIncidentView}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem)}
              editConfigItem={editConfigItem}
              ref={this.editForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => this.editForm && this.editForm.current && this.editForm.current.getErrors();
}

const mapDispatchToProps: IConfigItemEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadIncidentView,
  updateConfigItem: actionCreator.updateIncidentView,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIncidentViewEdit);
