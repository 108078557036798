import { Checkbox, IColumn, ICommandBarItemProps } from "@fluentui/react";
import * as radarApi from "../../../api/radarApi";
import { ContentDisplayType, getFormatContent } from "../../../shared/utilities/miscHelper";
import classNames from "./ConfigHistory.module.scss";

export interface IConfigHistory {
  revision: number;
  configItemId: number;
  configContainer: string;
  changedOn: string;
  changedBy: string;
  config: string;
  businessJustification: string;
  publishedOn: string;
  publishedBy: string;
  publishDevOpsUrl: string;
  published: boolean;
}

export interface IPublishHistory {
  revision: number;
  configItemId: number;
  configContainer: string;
  publishedOn: string;
  publishedBy: string;
  config: string;
  businessJustification: string;
  publishDevOpsUrl: string;
}

export const liveHistoryColumns: IColumn[] = [
  {
    minWidth: 60,
    maxWidth: 80,
    key: "revision",
    name: "Revision",
    fieldName: "revision",
    isSortedDescending: true,
    isSorted: true,
  },
  {
    minWidth: 140,
    maxWidth: 180,
    key: "publishedOn",
    name: "Published On",
    fieldName: "publishedOn",
    onRender: (item) => item["publishedOn"] && getFormatContent(item["publishedOn"], ContentDisplayType.datetime),
  },
  {
    minWidth: 100,
    maxWidth: 140,
    key: "publishedBy",
    name: "Published By",
    fieldName: "publishedBy",
  },
  {
    minWidth: 200,
    maxWidth: 500,
    key: "businessJustification",
    name: "Business Justification",
    fieldName: "businessJustification",
  },
];

export const stagingHistoryColumns: IColumn[] = [
  {
    minWidth: 60,
    maxWidth: 80,
    key: "revision",
    name: "Revision",
    fieldName: "revision",
    isSortedDescending: true,
    isSorted: true,
  },
  {
    minWidth: 140,
    maxWidth: 180,
    key: "changedOn",
    name: "Changed On",
    fieldName: "changedOn",
    onRender: (item) => getFormatContent(item["changedOn"], ContentDisplayType.datetime),
  },
  {
    minWidth: 100,
    maxWidth: 140,
    key: "changedBy",
    name: "Changed By",
    fieldName: "changedBy",
  },
  {
    minWidth: 200,
    maxWidth: 300,
    key: "publishedStatus",
    name: "Published Status",
    fieldName: "publishedStatus",
  },
];

export const deployHistoryColumns: IColumn[] = [
  {
    minWidth: 60,
    maxWidth: 80,
    key: "revision",
    name: "Revision",
    fieldName: "revision",
    isSortedDescending: true,
    isSorted: true,
  },
  {
    minWidth: 140,
    maxWidth: 180,
    key: "publishedOn",
    name: "Deployed On",
    fieldName: "publishedOn",
    onRender: (item) => item["publishedOn"] && getFormatContent(item["publishedOn"], ContentDisplayType.datetime),
  },
  {
    minWidth: 100,
    maxWidth: 140,
    key: "publishedBy",
    name: "Deployed By",
    fieldName: "publishedBy",
  },
];

export const getConfigType = (configItemType: string): radarApi.ConfigItemType => {
  if (!configItemType) return;

  let configType: radarApi.ConfigItemType = radarApi.ConfigItemType[configItemType];
  if (!configType) {
    configType = radarApi.ConfigItemType[`${configItemType?.slice(0, 1).toUpperCase()}${configItemType.slice(1)}s`];
    if (!configType) {
      Object.keys(radarApi.ConfigItemType).forEach((type) => {
        if (type.toLowerCase().startsWith(configItemType.toLocaleLowerCase())) {
          configType = radarApi.ConfigItemType[type];
          return;
        }
      });
    }
  }

  return configType;
};

export const getFinalColumns = (columns: IColumn[], additionalColumns: IColumn[], isLiveOnly: boolean): IColumn[] => {
  let finalColumns = columns?.slice();

  if (additionalColumns?.length) {
    finalColumns = additionalColumns.concat(finalColumns);
  }

  if (isLiveOnly) {
    finalColumns = finalColumns.filter((column) => column.key !== "publishedStatus");
  }

  return finalColumns;
};

export const getSelectionColumn = (onSelectionChange: Function, selectedItems: any[]): IColumn => {
  return {
    key: "select",
    name: "Select",
    minWidth: 40,
    maxWidth: 40,
    onRender: (item) => (
      <Checkbox
        checked={selectedItems?.findIndex((selectedItem) => selectedItem.revision === item.revision) >= 0}
        onChange={(ev, checked) => onSelectionChange(item, checked)}
        className={classNames.selectColumn}
      />
    ),
  };
};

export const getCompareButton = (onClick: () => void, selectedCount: number): ICommandBarItemProps => {
  const compareButtonTitle = selectedCount > 1 ? "Compare" : "See Revision";
  return {
    key: "compare",
    title: compareButtonTitle,
    ariaLabel: compareButtonTitle,
    disabled: !selectedCount,
    text: compareButtonTitle,
    iconProps: {
      iconName: "BranchCompare",
    },
    iconOnly: false,
    onClick,
  };
};

export const getSwitchHistoryTypeButton = (
  onClick: () => void,
  showChangeHistory: boolean,
  isGlobalEnv: boolean
): ICommandBarItemProps => {
  const switchButtonTitle = !showChangeHistory ? "Change History" : isGlobalEnv ? "Publish History" : "Deploy History";
  return {
    key: "switchHistoryType",
    title: switchButtonTitle,
    ariaLabel: switchButtonTitle,
    text: switchButtonTitle,
    iconProps: {
      iconName: "Switch",
    },
    iconOnly: false,
    onClick,
  };
};

export const addPublishedStatusToChangeHistory = (
  changeHistory: IConfigHistory[],
  publishHistory: IPublishHistory[]
) => {
  if (!changeHistory?.length || !publishHistory?.length) return;

  const publishedDate = publishHistory.map((publishItem) => publishItem.publishedOn).sort();

  changeHistory
    .sort((a, b) => (a.changedOn < b.changedOn ? -1 : a.changedOn > b.changedOn ? 1 : 0))
    .forEach((changeItem, index) => {
      const targetChangeDate = changeItem.changedOn;
      const nextChangeDate = index < changeHistory.length - 1 ? changeHistory[index + 1].changedOn : undefined;
      const relatedPublishedDate = publishedDate.find(
        (pubdate) => pubdate >= targetChangeDate && (!nextChangeDate || pubdate < nextChangeDate)
      );

      if (relatedPublishedDate) {
        changeItem["publishedStatus"] = `Published on ${new Date(relatedPublishedDate).toLocaleString()}`;
      }
    });
};
