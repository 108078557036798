import React from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IIncident, IIncidentView } from "./interfaces";
import { ITimelineGroup, ITimeline, ITimelineMarker } from "../../shared/components/TimelineChart";
import { IIncidentsReportStateProps, IIncidentsReportDispatchProps } from "./IncidentsReport";
import { RouteComponentProps } from "react-router";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { getPagingCommandBarItems, getBasicPageCommandBarItems } from "../common/helper";
import { renderStatusIcon, getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { IFilter, IFilterField } from "../common/interfaces";
import { ContactLink } from "../../shared/components/ContactLink";
import classNames from "./IncidentsReport.module.scss";
import { Spinner } from "@fluentui/react";

export enum SearchFlags {
  team = "team:",
}

export const getIncidents = (icmData: any): IIncident[] => {
  const incidents: IIncident[] = [];

  icmData &&
    icmData.forEach((incident: IIncident) => {
      let statusColor = getStatusColor(incident["status"]),
        finImpact = incident["financialImpact"] || incident["financialImpactUSD"];

      incidents.push({
        ...incident,
        financialImpact: finImpact !== undefined && finImpact !== null ? parseFloat(finImpact) : finImpact,
        impactedLOB: getImpactedLOB(incident),
        statusColor,
        statusIcon: getStatusIcon(statusColor),
      });
    });

  return incidents;
};

export const getStatusColor = (status: string): string => {
  status = status.toUpperCase();
  let isResolved = status === "RESOLVED",
    isMitigated = status === "MITIGATED",
    isMitigating = status === "MITIGATING",
    isClosed = status === "CLOSED";
  return isResolved
    ? statusColors.resolved
    : isMitigated
    ? statusColors.mitigated
    : isMitigating
    ? statusColors.mitigating
    : isClosed
    ? statusColors.closed
    : statusColors.active;
};

export const getStatusIcon = (statusColor: string): string =>
  statusColor === statusColors.resolved ? "SkypeCircleCheck" : "SkypeCircleMinus";

export const statusColors = {
  resolved: "#008600",
  tentativeResolve: "#707070",
  mitigated: "#986200",
  mitigating: "#BF2200",
  active: "#93000A",
  closed: "#707070",
};

export const defaultFilterFields: IFilterField[] = [
  {
    fieldName: "status",
    displayName: "Status",
    items: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Mitigated",
        value: "mitigated",
      },
      {
        name: "Resolved",
        value: "resolved",
      },
    ],
  },
  { fieldName: "impactedLOB", displayName: "Impacted LOB" },
  { fieldName: "engineeringDirector", displayName: "Director" },
  {
    fieldName: "statusChanged",
    displayName: "Status Changed",
    items: [
      {
        name: "Last 1 month",
        value: "1m",
      },
      {
        name: "Last 3 months",
        value: "3m",
      },
      {
        name: "Last 6 months",
        value: "6m",
      },
      {
        name: "Last 1 year",
        value: "12m",
      },
    ],
  },
];

export const getFilteredIncidentIds = (
  incidents: IIncident[],
  filters: IFilter[],
  selectedFilters: string[],
  searchText?: string,
  searchFieldNames?: string[],
  incidentView?: IIncidentView
): string[] => {
  // First find out how many filter fields are selected.
  let selectedFilterFields = 0;
  filters &&
    filters.forEach((filter) => {
      if (filter.items && filter.items.find((i) => selectedFilters.indexOf(i.value) >= 0)) {
        selectedFilterFields++;
      }
    });

  // Now, find incidents that have the same match values for each selected field.
  const nowTime = new Date().valueOf();
  searchText = searchText?.toLowerCase();

  if (searchText?.startsWith(SearchFlags.team)) {
    searchFieldNames = ["owningTeamId"];
    searchText = searchText.replace(SearchFlags.team, "").trim();
  }

  let finalFilterFields = incidentView?.filters || defaultFilterFields;

  return (
    (incidents &&
      incidents
        .filter((incident) => {
          let found = 0;
          finalFilterFields.forEach((field) => {
            if (field.fieldName === "statusChanged") {
              let impactStartDate = (incident.impactStartDate && new Date(incident.impactStartDate).valueOf()) || 0,
                discoveredDate = (incident.discoveredDate && new Date(incident.discoveredDate).valueOf()) || 0,
                mitigateDate = (incident.mitigateDate && new Date(incident.mitigateDate).valueOf()) || 0,
                resolveDate = (incident.resolveDate && new Date(incident.resolveDate).valueOf()) || 0,
                statusChangedDate = Math.max(impactStartDate, discoveredDate, mitigateDate, resolveDate);

              if (statusChangedDate) {
                let timeDiff = nowTime - statusChangedDate,
                  monthDiff = timeDiff / 2592000000; // Divided by the number of millseconds in months.

                if (
                  (selectedFilters.indexOf("1m") >= 0 && monthDiff <= 1) ||
                  (selectedFilters.indexOf("3m") >= 0 && monthDiff <= 3) ||
                  (selectedFilters.indexOf("6m") >= 0 && monthDiff <= 6) ||
                  (selectedFilters.indexOf("12m") >= 0 && monthDiff <= 12)
                ) {
                  found++;
                }
              }
            } else if (
              field.fieldName &&
              selectedFilters.find((filter) => {
                let isOthersSelected = filter === `_others_${field.fieldName}_`,
                  incidentFieldValue = incident[field.fieldName]?.toString().toLowerCase();

                if (isOthersSelected && field.items?.length) {
                  return !field.items.find((item) => item.value.toLowerCase() === incidentFieldValue);
                } else {
                  return filter.toLowerCase() === incidentFieldValue;
                }
              })
            ) {
              found++;
            }
          });

          return found === selectedFilterFields && isSearchTextFound(incident, searchText, searchFieldNames);
        })
        .map((incident) => incident.incidentId)) ||
    []
  );
};

export const isSearchTextFound = (incident: IIncident, searchText: string, searchFieldNames: string[]): boolean => {
  let result = true;

  if (searchText) {
    result = false;
    let fieldNames = searchFieldNames || Object.keys(incident);

    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i],
        field = incident[fieldName];

      if (field) {
        let fieldText = field.toString && field.toString().toLowerCase();

        if (fieldText && (fieldText === searchText || fieldText.indexOf(searchText) >= 0)) {
          result = true;
          break;
        }
      }
    }
  }

  return result;
};

export const getIncidentsFilters = (incidents: IIncident[], incidentView: IIncidentView): IFilter[] => {
  const filters: IFilter[] = [],
    filterValues: any = {};

  let finalFilterFields = incidentView?.filters || defaultFilterFields;

  incidents &&
    incidents.forEach((incident) => {
      finalFilterFields.forEach((field) => {
        if (!field.items?.length) {
          let fieldName = field.fieldName,
            value: any = incident[fieldName];

          if (value) {
            if (!filterValues.hasOwnProperty(fieldName)) {
              filterValues[fieldName] = [value.toString()];
            } else if ((filterValues[fieldName] as string[]).findIndex((s) => s === value) < 0) {
              filterValues[fieldName].push(value);
            }
          }
        }
      });
    });

  finalFilterFields.forEach((field) => {
    if (field.items?.length) {
      filters.push({
        name: field.displayName,
        items: field.items,
      });
    } else {
      let values: string[] = filterValues[field.fieldName];
      if (values && values.length > 0) {
        values.sort();
        filters.push({
          name: field.displayName,
          items: values.map((s) => ({ name: s, value: s })),
        });
      }
    }
  });

  return filters;
};

export const getFilteredIncidents = (incidents: IIncident[], filteredIds: string[]): IIncident[] =>
  !filteredIds
    ? incidents
    : filteredIds.length
    ? incidents && incidents.filter((i) => filteredIds.indexOf(i.incidentId) >= 0)
    : [];

export const getTimelineGroups = (incidents: IIncident[]): ITimelineGroup[] => {
  let timelines: ITimeline[] = [],
    groups: ITimelineGroup[] = [{ timelines }];

  incidents &&
    incidents.forEach((incident) => {
      let color = incident.statusColor,
        markers = getTimelineMarkers(incident);

      timelines.push({
        name: incident.title,
        data: [
          {
            id: incident.incidentId,
            startTime: getStartTime(incident),
            endTime: getEndTime(incident),
            color,
            markers,
          },
        ],
      });
    });

  return groups;
};

const getStartTime = (incident: IIncident): number => {
  let today = new Date().valueOf(),
    impactStartDate = new Date(incident.impactStartDate).valueOf() || today,
    resolveDate = new Date(incident.resolveDate).valueOf() || today,
    mitigateDate = new Date(incident.mitigateDate).valueOf() || today,
    discoveredDate = new Date(incident.discoveredDate).valueOf() || today;

  return Math.min(impactStartDate, resolveDate, mitigateDate, discoveredDate);
};

const getEndTime = (incident: IIncident): number => {
  let today = new Date().valueOf(),
    resolveDate = new Date(incident.resolveDate).valueOf() || today,
    mitigateDate = new Date(incident.mitigateDate).valueOf() || today,
    discoveredDate = new Date(incident.discoveredDate).valueOf() || today,
    targetResolutionDate = new Date(incident.targetResolutionDate).valueOf() || today;

  return Math.max(resolveDate, mitigateDate, discoveredDate, targetResolutionDate);
};

export const getTimelineMarkers = (incident: IIncident): ITimelineMarker[] => {
  let markers: ITimelineMarker[] = [];

  incident.impactStartDate &&
    markers.push({
      time: new Date(incident.impactStartDate).setHours(0, 0, 0, 0),
      name: "Impact Started",
      color: statusColors.active,
    });
  incident.discoveredDate &&
    markers.push({
      time: new Date(incident.discoveredDate).setHours(0, 0, 0, 0),
      name: "Incident Discovered",
      color: statusColors.mitigating,
    });
  incident.targetResolutionDate &&
    markers.push({
      time: new Date(incident.targetResolutionDate).setHours(0, 0, 0, 0),
      name: "Target Resolution",
      color: statusColors.tentativeResolve,
    });
  incident.mitigateDate &&
    incident.status !== "Active" &&
    markers.push({
      time: new Date(incident.mitigateDate).setHours(0, 0, 0, 0),
      name: "Incident Mitigated",
      color: statusColors.mitigated,
      fields: [{ name: "By", value: incident.mitigatedBy }],
    });
  incident.resolveDate &&
    incident.status !== "Active" &&
    markers.push({
      time: new Date(incident.resolveDate).setHours(0, 0, 0, 0),
      name: "Incident Resolved",
      color: statusColors.resolved,
      fields: [{ name: "By", value: incident.resolvedBy }],
    });

  markers = markers.filter((marker) => !isNaN(Number(marker.time)));

  return markers.sort((a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf());
};

export const getFilteredIncidentsByProps = (
  props: IIncidentsReportStateProps & IIncidentsReportDispatchProps,
  incidents: IIncident[]
): IIncident[] => {
  const { filteredIncidentIds } = props;
  return getFilteredIncidents(incidents, filteredIncidentIds);
};

export const formatDateString = (date: string): string => date && new Date(date).toLocaleDateString();

export const backupDefaultColumns: IColumn[] = [
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 60,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: any) => (
      <div className={classNames.status}>
        {renderStatusIcon(item.statusIcon, item.statusColor)}
        <span className={classNames.statusText}>{item.status}</span>
      </div>
    ),
  },
  {
    key: "incidentId",
    name: "Incident ID",
    fieldName: "incidentId",
    minWidth: 70,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => (
      <a
        title={`Click here to go to the IcM site for incident ${item.incidentId}`}
        className={classNames.buttonAsHyperlink}
        aria-label={`Click here to go to the IcM site for incident ${item.incidentId}`}
        href={`https://portal.microsofticm.com/imp/v3/incidents/details/${item.incidentId}/home`}
        target="_blank'"
        tabIndex={0}
      >
        {item.incidentId}
      </a>
    ),
  },
  {
    key: "severity",
    name: "Severity",
    fieldName: "severity",
    minWidth: 40,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "title",
    name: "Incident Title",
    fieldName: "title",
    minWidth: 200,
    maxWidth: 840,
    isMultiline: true,
    isResizable: true,
  },
  {
    key: "financialImpact",
    name: "Financial Impact",
    fieldName: "financialImpactUSD",
    minWidth: 90,
    maxWidth: 100,
    isResizable: true,
    data: "number",
    onRender: (item: any) => (
      <span title={item.financialImpactDescription}>
        {item.financialImpactUSD !== undefined && item.financialImpactUSD !== "" && !isNaN(item.financialImpactUSD)
          ? getFormatContent(item.financialImpactUSD, ContentDisplayType.shortCurrency)
          : ""}
      </span>
    ),
  },
  {
    key: "owningContactAlias",
    name: "Owner",
    fieldName: "owningContactAlias",
    minWidth: 60,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: any) => (
      <ContactLink alias={item.owningContactAlias} messageSubject={getContactMessageSubject(item)} />
    ),
  },
  {
    key: "owningTeamId",
    name: "Owning Team",
    fieldName: "owningTeamId",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "impactStartDate",
    name: "Impact Started",
    fieldName: "impactStartDate",
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    headerClassName: "headerImpactStarted",
    onRender: (item: any) => formatDateString(item.impactStartDate),
  },
  {
    key: "createDate",
    name: "Created",
    fieldName: "createDate",
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    isSorted: true,
    isSortedDescending: true,
    onRender: (item: any) => formatDateString(item.createDate),
  },
  {
    key: "discoveredDate",
    name: "Discovered",
    fieldName: "discoveredDate",
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    headerClassName: "headerDiscovered",
    onRender: (item: any) => formatDateString(item.discoveredDate),
  },
  {
    key: "targetResolutionDate",
    name: "Target Resolution",
    fieldName: "targetResolutionDate",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    headerClassName: "headerTentative",
    onRender: (item: any) => formatDateString(item.targetResolutionDate),
  },
  {
    key: "mitigateDate",
    name: "Mitigated",
    fieldName: "mitigateDate",
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    headerClassName: "headerMitigated",
    onRender: (item: any) => formatDateString(item.mitigateDate),
  },
  {
    key: "resolveDate",
    name: "Resolved",
    fieldName: "resolveDate",
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    headerClassName: "headerResolved",
    onRender: (item: any) => formatDateString(item.resolveDate),
  },
];

export const getColumns = (
  onColumnClick: any,
  isSortedDescending: boolean,
  sortedColumnKey: string,
  selectedView: IIncidentView,
  defaultColumns: IColumn[] = backupDefaultColumns
): IColumn[] => {
  let finalColumns: IColumn[],
    hasCustomColumns = selectedView?.columns?.length,
    extraColumns = selectedView?.columns?.filter((column) => column["isExtraColumn"]),
    areAllExtraColumns = hasCustomColumns && extraColumns?.length === selectedView.columns.length;

  if (hasCustomColumns && !areAllExtraColumns) {
    finalColumns = finalizeColumns(selectedView.columns, isSortedDescending, sortedColumnKey, onColumnClick);
  } else {
    // Use default columns if no custom column is defined for the incident view.
    finalColumns = defaultColumns.map((column) => {
      let onRender = undefined;

      if (column.fieldName.endsWith("Date")) {
        onRender = (item) => formatDateString(item[column.fieldName]);
      } else if (column.fieldName === "status") {
        onRender = (item) => (
          <div className={classNames.status}>
            {renderStatusIcon(item.statusIcon, item.statusColor)}
            <span className={classNames.statusText}>{item.status}</span>
          </div>
        );
      } else if (column.fieldName === "incidentId") {
        onRender = (item) => (
          <a
            title={`Click here to go to the IcM site for incident ${item.incidentId}`}
            className={classNames.buttonAsHyperlink}
            aria-label={`Click here to go to the IcM site for incident ${item.incidentId}`}
            href={`https://portal.microsofticm.com/imp/v3/incidents/details/${item.incidentId}/home`}
            target="_blank'"
            tabIndex={0}
          >
            {item.incidentId}
          </a>
        );
      } else if (column.fieldName === "financialImpactUSD") {
        onRender = (item) => (
          <span title={item.financialImpactDescription}>
            {item.financialImpactUSD !== undefined && item.financialImpactUSD !== "" && !isNaN(item.financialImpactUSD)
              ? getFormatContent(item.financialImpactUSD, ContentDisplayType.shortCurrency)
              : ""}
          </span>
        );
      } else if (column.fieldName === "owningContactAlias") {
        onRender = (item) => (
          <ContactLink alias={item.owningContactAlias} messageSubject={getContactMessageSubject(item)} />
        );
      } else if (column.fieldName === "openAiSummary") {
        onRender = (item) =>
          item["loadingOpenAiSummary"] ? <Spinner label="Loading OpenAI data..." /> : item["openAiSummary"];
      } else if (
        column.fieldName === "targetResolutionHygiene" ||
        column.fieldName === "engineeringRootCauseHygiene" ||
        column.fieldName === "repairItemHygiene" ||
        column.fieldName === "postmortemStatus"
      ) {
        onRender = (item) => {
          let hygieneFieldValue = item[column.fieldName],
            hygieneFieldProps = item[column.fieldName + "Props"];

          if (hygieneFieldValue) {
            let backgroundColor = hygieneFieldProps?.backgroundColor || "darkgrey",
              title = hygieneFieldProps?.title;

            return (
              <div className={classNames.colorLabel} style={{ backgroundColor }} title={title}>
                {hygieneFieldValue}
              </div>
            );
          } else {
            return "";
          }
        };
      }

      return {
        ...column,
        isSortedDescending,
        isSorted: sortedColumnKey === column.key,
        onColumnClick,
        onRender,
      };
    });

    if (extraColumns?.length) {
      let finalExtraColumns = finalizeColumns(extraColumns, isSortedDescending, sortedColumnKey, onColumnClick);

      finalExtraColumns.forEach((extraColumn) => {
        let columnPosition = extraColumn["columnPosition"];

        // If no column position is defined for the extra column, add it to the end.  Otherwise, insert it to the specific position.
        if (
          (!columnPosition && columnPosition !== 0) ||
          columnPosition <= 0 ||
          columnPosition >= finalColumns?.length
        ) {
          finalColumns.push(extraColumn);
        } else {
          finalColumns.splice(columnPosition - 1, 0, extraColumn);
        }
      });
    }
  }

  if (!selectedView?.showOpenAiSummary) {
    let openAiSummaryIndex = finalColumns.findIndex((column) => column.key === "openAiSummary");

    if (openAiSummaryIndex >= 0) {
      finalColumns.splice(openAiSummaryIndex, 1);
    }
  }

  return finalColumns;
};

export const getCommandBarProps = (
  props: IIncidentsReportStateProps & IIncidentsReportDispatchProps & RouteComponentProps,
  incidents: IIncident[]
): ICommandBarProps => {
  const {
    isSmallScreen,
    selectedIncident,
    filteredIncidents,
    unselectIncident,
    selectedIncidentIndex,
    loadIncidentViews,
    loadIncidentDetails,
    history,
    selectedViewId,
    selectedView,
  } = props;

  const disableRefresh = selectedView && selectedView.disableRefresh;

  const basicPageCommandBarActions = disableRefresh
    ? null
    : {
        refresh: () =>
          selectedIncident
            ? loadIncidentDetails(selectedIncident, true)
            : loadIncidentViews(null, selectedViewId, null, true),
      };

  let commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions)],
  };

  if (selectedIncident && selectedView) {
    commandBarProps.farItems = [
      ...commandBarProps.farItems,
      ...getPagingCommandBarItems(
        history,
        "Incident",
        unselectIncident,
        `${leftNavUrls.all.financialIncidents}/${selectedViewId}`,
        filteredIncidents,
        selectedIncidentIndex,
        loadIncidentDetails
      ),
    ];
  }

  return commandBarProps;
};

export const getImpactedLOB = (item: any): string => {
  let result = "";

  if (item.financialImpactUSD === undefined) {
    result += getImpactedLOBValue(item, result, "isModern");
    result += getImpactedLOBValue(item, result, "isLegacy");
    result += getImpactedLOBValue(item, result, "isVL");
    result += getImpactedLOBValue(item, result, "isMBS");
    result += getImpactedLOBValue(item, result, "isOEM");
  } else {
    var impactedLOBs = item.impactedLOB && item.impactedLOB.split(",");
    impactedLOBs &&
      impactedLOBs.forEach((impactedLOB) => {
        let itemResult = "";
        itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "1", "Modern");
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "2", "Legacy"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "3", "VL"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "4", "OEM"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "5", "MBS"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "6", "N/A"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "7", "Dynamics CRM"));
        !itemResult && (itemResult = getImpactedLOBValueByNumber(itemResult, impactedLOB, "8", "CTP"));
        result += (result.length ? ", " : "") + (itemResult || impactedLOB);
      });
  }

  return result;
};

const getImpactedLOBValueByNumber = (
  result: string,
  actualValue: string,
  expectedValue: string,
  displayText: string
): string => {
  if (actualValue === expectedValue) {
    if (result.length) {
      result += ", ";
    }

    result += displayText;
  }

  return result;
};

const getImpactedLOBValue = (item: any, result: string, key: string): string => {
  let newResult = "";
  let value = item[key];
  if (value && value === "1") {
    if (result.length) {
      newResult = ", ";
    }
    return newResult + key.substring(2);
  }
  return newResult;
};

export const getContactMessageSubject = (incident: IIncident): string =>
  `Commerce Radar Incident ${incident.incidentId}: ${incident.title}`;

export const getDownloadData = (incidents: IIncident, columns: any[]): any[] => {
  let data = [];

  incidents &&
    incidents.forEach((incident) => {
      let item = {};

      columns &&
        columns.forEach((column) => {
          let value = incident[column.fieldName];
          value = column.displayType === ContentDisplayType.date ? getFormatContent(value, column.displayType) : value;
          item[column.fieldName] = value || value === 0 ? value : "";
        });

      data.push(item);
    });

  return data;
};

export const hasAnyData = (fieldName: string, incidents: IIncident[]): boolean => {
  if (incidents?.length) {
    for (let i = 0; i < incidents.length; i++) {
      if (incidents[i] && incidents[i][fieldName] !== undefined) {
        return true;
      }
    }
  }

  return false;
};

export const finalizeColumns = (
  columns: IColumn[],
  isSortedDescending: boolean,
  sortedColumnKey: string,
  onColumnClick: Function
): IColumn[] =>
  columns.map((column: any) => ({
    ...column,
    key: column.fieldName,
    isResizable: true,
    isSortedDescending,
    isSorted: sortedColumnKey === column.fieldName,
    onColumnClick,
    onRender: (item) => {
      if ((column.fieldName === "mitigateDate" || column.fieldName === "resolveDate") && item.status === "Active") {
        return "";
      }

      return getFormatContent(
        item,
        column.displayType,
        column.fieldName,
        column.template,
        "green",
        "red",
        "orange",
        column.locale,
        column.localeFieldName,
        column.currency,
        column.currencyFieldName
      );
    },
  }));
