import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { getColumns } from "./MyEntityList.helper";
import ConfigItemList, {
  IConfigItemListBaseProps,
  IConfigItemListDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemList";

export class MyEntityList extends React.Component<IConfigItemListBaseProps> {
  render() {
    return (
      <ConfigItemList
        {...this.props}
        columns={getColumns()}
        configItemName="Entity"
        appInsightsPageName="MyEntityListPage"
        leftNavUrl={leftNavUrls.management.entity}
      />
    );
  }
}

const mapDispatchToProps: IConfigItemListDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItems: actionCreator.loadEntityConfigItems,
  deleteConfigItem: actionCreator.deleteEntityConfigItem,
  publishConfigItem: actionCreator.publishEntityConfigItem,
  unpublishConfigItem: actionCreator.unpublishEntityConfigItem,
  revertConfigItem: actionCreator.revertEntityConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEntityList);
