import React from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import { IEntity, IEntityMetric, IEntityTile } from "../common/interfaces";
import { actionCreator } from "../duck";
import { EntityDetailSelectedItem } from "./EntityDetailSelectedEntity";
import { EntityDetailMetrics } from "./EntityDetailMetrics";
import classNames from "./EntityDetail.module.scss";
import { SearchItemKey } from "./helper";
import { History } from "react-router-dom/node_modules/history";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { getUserId } from "../common/helper";

export interface IEntityDetailsProps {
  history: History;
}

export interface IEntityDetailStateProps {
  selectedEntity: IEntity;
  selectedEntityType: string;
  entityMetrics: IEntityMetric[];
  entityTiles: IEntityTile[];
  selectedMetricIds: string[];
  loading: boolean;
  searchItemKey: SearchItemKey;
  selectedServiceContentConfig: any;
  userId: string;
  isSuperAdmin: boolean;
}

export interface IEntityDetailDispatchProps {
  showEntityDetails: () => void;
  loadEntityDetails: (entityType: string, entityId: string, entityVersion: string) => void;
  selectEntityMetric: (entityType: string, metricId: string) => void;
  clearConfigItems: () => void;
}

export class EntityDetail extends React.Component<
  IEntityDetailsProps & IEntityDetailStateProps & IEntityDetailDispatchProps
> {
  render() {
    const {
      selectedEntity,
      selectedEntityType,
      entityMetrics,
      loading,
      selectedMetricIds,
      entityTiles,
      searchItemKey,
    } = this.props;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
        />
      );
    }

    const finalEntityMetrics = selectedEntityType
        ? entityMetrics && entityMetrics.filter((em) => em.entityType === selectedEntityType)
        : entityMetrics,
      hasEntityMetrics = finalEntityMetrics && !!finalEntityMetrics.length;

    const noMetricsSelected = selectedEntityType && hasEntityMetrics && !selectedMetricIds?.length,
      finalMetricIds = noMetricsSelected ? finalEntityMetrics[0].metrics?.map((m) => m.id) : selectedMetricIds;

    const selectedEntityTiles =
      selectedEntityType &&
      entityTiles?.filter((et) => et.entityType === selectedEntityType && et.tiles?.length).map((et) => et.tiles);

    const tiles = selectedEntityTiles?.length && selectedEntityTiles[0];

    const isEntityLookup = searchItemKey === SearchItemKey.entityLookup,
      isMetricSearch = searchItemKey === SearchItemKey.metricSearch,
      isEntitySearch = searchItemKey === SearchItemKey.entitySearch;

    const commandBarItems = this.getCommandBarItems();

    return (
      <div className={classNames.root}>
        {commandBarItems?.length > 0 ? (
          <CommandBar items={[]} farItems={commandBarItems} ariaLabel="Entity Command Bar" />
        ) : (
          <div style={{ height: "20px" }} />
        )}
        {isMetricSearch && !hasEntityMetrics && (
          <div className={classNames.message}>
            {selectedEntityType ? (
              <>
                No info is available for the selected entity type <b>{selectedEntityType}</b>.
              </>
            ) : (
              <>
                No entity is selected. Please select one of the metrics in the table below graph diagram, or go to the{" "}
                <b>Individual Entity Search</b> and look for a valid entity by specifying an entity type and ID.
              </>
            )}
          </div>
        )}
        {isMetricSearch && hasEntityMetrics && (
          <EntityDetailMetrics
            entityMetrics={finalEntityMetrics}
            tiles={tiles}
            selectedMetricIds={finalMetricIds}
            allowShowAllMetrics={!noMetricsSelected && !!selectedEntityType}
            onMetricSelect={this.onMetricSelect}
          />
        )}
        {isEntitySearch && (
          <div className={classNames.message}>
            Apply filters to search for entities. A maximum of 10000 items will be retrieved.
          </div>
        )}
        {isEntityLookup && selectedEntity && <EntityDetailSelectedItem entity={selectedEntity} />}
      </div>
    );
  }

  onMetricSelect = (metricId: string) => {
    const { selectedEntityType, selectEntityMetric } = this.props;

    selectEntityMetric(selectedEntityType, metricId);
  };

  getCommandBarItems = (): ICommandBarItemProps[] => {
    const {
      selectedEntity: entity,
      searchItemKey,
      selectedEntityType,
      selectedServiceContentConfig,
      userId,
      isSuperAdmin,
    } = this.props;

    let items = [],
      canEditConfig =
        isSuperAdmin ||
        selectedServiceContentConfig?.owner?.indexOf(userId) >= 0 ||
        selectedServiceContentConfig?.editors?.indexOf(userId) >= 0;

    selectedEntityType &&
      canEditConfig &&
      items.push({
        key: "editEntityConfig",
        name: "Edit Config",
        title: "Edit entity config, e.g. add/edit recon validations",
        iconProps: {
          iconName: "ConfigurationSolid",
        },
        role: "menuitem",
        onClick: this.editConfig,
      });

    searchItemKey === SearchItemKey.entityLookup &&
      entity &&
      items.push({
        key: "showEntityDetails",
        name: "Show Details",
        title: "Show more details about this entity",
        iconProps: {
          iconName: "TextDocument",
        },
        role: "menuitem",
        onClick: this.showDetails,
      });

    return items;
  };

  showDetails = () => {
    const { selectedEntity: entity, showEntityDetails, loadEntityDetails } = this.props;

    loadEntityDetails(entity.entityType, entity.id, entity.version);
    showEntityDetails();
  };

  editConfig = () => {
    const { selectedServiceContentConfig, history, clearConfigItems } = this.props;

    const configItemId = selectedServiceContentConfig?.id;

    if (configItemId) {
      clearConfigItems();
      history.push(`${leftNavUrls.management.serviceContent}/${configItemId}`);
    }
  };
}

const mapStateToProps = (state: IState): IEntityDetailStateProps => ({
  selectedEntity: state.modules.selected_entity && state.modules.selected_entity.entity,
  loading: state.modules.loading_entity,
  selectedEntityType: state.modules.search_entity_type,
  entityMetrics: state.modules.entity_metrics_by_selected_view,
  selectedMetricIds: state.modules.selected_metric_ids,
  entityTiles: state.modules.entity_tiles,
  searchItemKey: state.modules.search_item_key,
  selectedServiceContentConfig: state.modules.service_content_configs?.find(
    (config) => config.name === state.modules.search_entity_type
  ),
  userId: getUserId(state.app.login_user),
  isSuperAdmin: state.app.login_user_info && state.app.login_user_info.isSuperAdmin,
});

const mapDispatchToProps = {
  showEntityDetails: actionCreator.showEntityDetails,
  loadEntityDetails: actionCreator.loadEntityDetails,
  selectEntityMetric: actionCreator.selectEntityMetric,
  clearConfigItems: actionCreator.clearConfigItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetail);
