import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultReportGroup, getFields } from "./MyReportGroupEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IReport } from "../../common/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

export interface IMyReportGroupEditState {
  selectedReport?: IReport;
}

export class MyReportGroupEdit extends React.Component<IConfigItemEditProps, IMyReportGroupEditState> {
  private generalEditForm: React.RefObject<any> = React.createRef();
  private reportsEditForm: React.RefObject<any> = React.createRef();

  state = { selectedReport: null };

  render() {
    const { configValue, editConfigItem } = this.props;
    const { selectedReport } = this.state;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);

      configItem.tiles &&
        configItem.tiles.forEach((tile, index) => {
          // Inject tile id if not already defined.
          if (!tile.tileId) {
            tile.tileId = new Date().valueOf() + "." + index;
          }
        });
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Report Group"
        configItemType={ConfigItemType.ReportGroups}
        appInsightsPageName="MyReportGroupEditPage"
        leftNavUrl={leftNavUrls.management.report}
        defaultConfigItem={defaultReportGroup}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem, selectedReport)}
              editConfigItem={editConfigItem}
              onFormItemSelected={this.onFormItemSelected}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    var generalEditFormErrors =
      this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
    var reportsEditFormErrors =
      this.reportsEditForm && this.reportsEditForm.current && this.reportsEditForm.current.getErrors();

    return { ...generalEditFormErrors, ...reportsEditFormErrors };
  };

  onFormItemSelected = (fieldName: string, value: any) => {
    if (fieldName === "reports" && value) {
      this.setState({ selectedReport: value });
    }
  };
}

const mapDispatchToProps: IConfigItemEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadReportGroup,
  updateConfigItem: actionCreator.updateReportGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportGroupEdit);
