import { IField, FieldType } from "../../../shared/components/DataForm";
import {
  IEventSubscription,
  SubscriptionObjectType,
  SubscriptionEventNameType,
  SubscriptionNotificationMethodType,
  IIcMConnector,
} from "../../common/interfaces";
import { getTileDataSourceFields } from "../common/helper";

export const defaultEventSubscription: IEventSubscription = {
  id: "new",
  name: "",
  enabled: true,
  notificationMethods: [SubscriptionNotificationMethodType.Email],
  eventTypes: [],
};

export const getObjectTypeOptions = () => {
  let objectTypes = [];

  for (var objectType in SubscriptionObjectType) {
    objectTypes.push({ key: objectType, text: objectType });
  }

  return objectTypes;
};

export const getNotificationMethodOptions = () => {
  let notificationMethods = [];

  for (var notificationMethod in SubscriptionNotificationMethodType) {
    notificationMethods.push({ key: notificationMethod, text: notificationMethod });
  }

  return notificationMethods;
};

export const getEventTypeOptions = () => {
  let eventTypes = [];

  for (var eventType in SubscriptionEventNameType) {
    let eventTypeValue = SubscriptionEventNameType[eventType];
    eventTypes.push({ key: eventTypeValue, text: eventTypeValue });
  }

  return eventTypes;
};

export const getFields = (subscription: IEventSubscription, icmConnectors: IIcMConnector[]): IField[] => {
  if (!subscription) return null;

  let isEmailNotification =
      subscription.notificationMethods &&
      subscription.notificationMethods.indexOf(SubscriptionNotificationMethodType.Email) >= 0,
    isIcmNotification =
      subscription.notificationMethods &&
      subscription.notificationMethods.indexOf(SubscriptionNotificationMethodType.IcM) >= 0,
    isEmailSubscribersRequired =
      subscription.eventTypes?.length !== 1 ||
      subscription.eventTypes?.indexOf(SubscriptionEventNameType.IncidentViewHygiene) < 0;

  let icmConnectorOptions = [{ key: "", text: "" }];

  icmConnectors.forEach((connector) => {
    icmConnectorOptions.push({ key: connector.id, text: connector.serviceName });
  });

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "Subscription ID",
      value: subscription.id,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: subscription.owner,
      width: "200px",
      required: true,
      hints: "Alias of subscription owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: subscription.editors,
      required: true,
      lastInRow: true,
      allowFullEditor: true,
      hints: "Comma separated list of aliases who can edit this subscripton",
    },
    {
      fieldName: "enabled",
      label: "Enabled",
      fieldType: FieldType.boolean,
      value: subscription.enabled === undefined || subscription.enabled === true,
    },
    {
      fieldName: "objectType",
      label: "Object Type",
      fieldType: FieldType.enumeration,
      required: true,
      value: subscription.objectType,
      width: "160px",
      options: getObjectTypeOptions(),
    },
    {
      fieldName: "objectIds",
      label: "Object IDs",
      fieldType: FieldType.textArray,
      value: subscription.objectIds,
      allowFullEditor: true,
      visible: subscription.objectType !== SubscriptionObjectType.HealthDashboard,
      hints: "Comma separated list of EntityName or ControlIds",
    },
    {
      fieldName: "notificationMethods",
      label: "Notification Methods",
      fieldType: FieldType.enumeration,
      required: true,
      value: subscription.notificationMethods,
      multiSelect: true,
      firstInRow: true,
      options: getNotificationMethodOptions(),
      width: "140px",
    },
    {
      fieldName: "eventTypes",
      label: "Event Types",
      fieldType: FieldType.enumeration,
      required: true,
      value: subscription.eventTypes,
      allowFullEditor: true,
      lastInRow: true,
      multiSelect: true,
      options: getEventTypeOptions(),
    },
    {
      fieldName: "subscribers",
      label: "Subscribers",
      required: isEmailNotification && isEmailSubscribersRequired,
      fieldType: FieldType.textArray,
      value: subscription.subscribers,
      allowFullEditor: true,
      lastInRow: true,
      visible: isEmailNotification,
      hints: "Comma separated list of Alias or FullEmailId",
    },
    {
      fieldName: "icmConnectorId",
      fieldType: FieldType.enumeration,
      label: "IcM Connector",
      options: icmConnectorOptions,
      value: subscription.icmConnectorId,
      width: "200px",
      visible: isIcmNotification,
      required: isIcmNotification,
      hints:
        "Selected a supported IcM Connector Service.  If your IcM service is not listed here, please contact crhelp for onboarding info.",
    },
    {
      fieldName: "icmTeamPublicId",
      label: "IcM Team Public ID",
      value: subscription.icmTeamPublicId,
      width: "300px",
      visible: isIcmNotification,
      required: isIcmNotification,
      hints:
        "Specify the IcM Team Public ID, usually in form of TenantName/TeamName, e.g. COMMERCERADAR\\CommerceDecisionSupportSystem. Go to the IcM portal > Administration > Manage Teams to find out the Team Public ID for your specific team.",
    },
    {
      fieldName: "icmSeverity",
      fieldType: FieldType.number,
      label: "IcM Severity",
      value: subscription.icmSeverity,
      regEx: "[01234]",
      width: "100px",
      valueWidth: "60px",
      visible: isIcmNotification,
      hints: "Specify the IcM incident severity, a number from 0 to 4",
    },
    {
      fieldName: "runFrequency",
      label: "Frequency",
      value: subscription.runFrequency,
      width: "80px",
      valueWidth: "60px",
      regEx: "^[0-9]+[mdh]$",
      visible: isEmailNotification && subscription.objectType !== SubscriptionObjectType.Control,
      firstInRow: true,
      hints: "Run frequency of the subscription, e.g. 1h for every hour, 1d for every day, 1m for every month",
    },
    {
      fieldName: "runStartTime",
      label: "Start Time",
      value: subscription.runStartTime,
      width: "80px",
      valueWidth: "60px",
      fillWidth: false,
      regEx: "^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])$",
      visible: isEmailNotification && subscription.objectType !== SubscriptionObjectType.Control,
      lastInRow: true,
      hints: "Run start time of the subscription in PST military time, e.g. 08:00, 15:30",
    },
    {
      fieldName: "teamIds",
      fieldType: FieldType.textArray,
      label: "Team IDs",
      width: "80px",
      valueWidth: "60px",
      visible: subscription.eventTypes?.indexOf("CommerceRadar.Base.Health.MonthCloseStatus") >= 0,
      hints: "A comma-separated list of target team IDs",
    },
    {
      fieldName: "metricGroupId",
      label: "Metric Group ID",
      width: "110px",
      valueWidth: "60px",
      visible: subscription.eventTypes?.indexOf("CommerceRadar.Base.Health.MonthCloseStatus") >= 0,
      hints: "Target metric group ID",
    },
    {
      fieldName: "incidentViewId",
      label: "Incident View ID",
      width: "110px",
      valueWidth: "60px",
      visible: subscription.eventTypes?.indexOf("CommerceRadar.Base.Health.MonthCloseStatus") >= 0,
      hints: "Target incident view ID",
    },
    {
      fieldName: "sendOnlyWhenAlerts",
      fieldType: FieldType.boolean,
      label: "Send Only When Alerts Exist",
      visible: subscription.eventTypes?.indexOf("CommerceRadar.Base.Health.MonthCloseStatus") >= 0,
      hints: "Send the related notifications only when Close metric alerts exist.",
    },
    {
      label: "Config Data Source",
      fieldType: FieldType.container,
      collapsible: true,
      fields: [
        {
          fieldName: "configDataSource",
          fieldType: FieldType.context,
          fields: [
            ...getTileDataSourceFields(),
            {
              fieldName: "query",
              label: "Query",
              width: "100%",
              allowFullEditor: true,
              hints: "The query result is expected to return dataset based on the expected schema.",
            },
          ],
        },
      ],
    },
  ];
};
