/// <reference path="../../../typings/global.d.ts" />

import React, { Component } from "react";
import { ILink, LineMarkerType } from "./interfaces";
import { Icon, defaultIconProps, defaultIconSize } from "./Icon";
import classNames from "./Link.module.scss";

const minContentWidth = 100;
const minContentHeight = 40;

export interface ILinkProps {
  diagramId?: string;
  link: ILink;
  onClick?: (link: ILink) => void;
  disabled?: boolean;
}

export class Link extends Component<ILinkProps> {
  render() {
    const { diagramId = "", link, onClick } = this.props;

    if (!link) return null;

    const {
      x1,
      y1,
      x2,
      y2,
      content,
      text,
      textStyle,
      className,
      classNames: customClassNames = {},
      style = {},
      lineMarkerType,
      animation,
      selected,
      fromValue,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fromValueTooltip,
      toValue,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      toValueTooltip,
      fromNodeId,
      toNodeId,
      disabled,
    } = link;

    const {
      imageUrl,
      iconName,
      style: iconStyle,
      x,
      y,
      height: iconHeight = defaultIconSize,
      width: iconWidth = defaultIconSize,
    } = link.icon || defaultIconProps;

    const rootClassNames = [classNames.root, customClassNames.link, className],
      lineClassNames = [classNames.line, customClassNames.line],
      lineStyle = style;

    const enabled = !disabled && !!onClick;

    if (enabled) {
      rootClassNames.push(classNames.clickable);
      rootClassNames.push(customClassNames.clickable);
    }

    if (animation) {
      lineClassNames.push(classNames.animation);

      if (!lineStyle.strokeDasharray) {
        lineStyle.strokeDasharray = Math.hypot(x1 - x2, y1 - y2) + " 2";
      }
    }

    const iconPlacement = {
      x: x !== undefined ? x : (x1 + x2 - iconWidth) / 2,
      y: y !== undefined ? y : (y1 + y2 - iconHeight) / 2,
      height: iconHeight,
      width: iconWidth,
    };

    const contentWidth = Math.max(Math.abs(x2 - x1), minContentWidth),
      contentHeight = Math.max(Math.abs(y2 - y1), minContentHeight),
      contentPlacement = {
        x: x !== undefined ? x : (x1 + x2 - contentWidth) / 2,
        y: y !== undefined ? y : (y1 + y2 - contentHeight) / 2,
        height: contentHeight,
        width: contentWidth,
      };

    const lineCenter = {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2,
    };

    const valueTextXAdjust = 10 * Math.abs(Math.sin(Math.atan2(y2 - y1, x2 - x1))),
      valueTextYAdjust = 10 * Math.abs(Math.cos(Math.atan2(y2 - y1, x2 - x1)));

    const markerUrl = `url(#diagram-arrow${diagramId})`;
    const markerEnd =
      lineMarkerType === LineMarkerType.arrowEnd || lineMarkerType === LineMarkerType.arrowBoth ? markerUrl : "";

    const markerStart =
      lineMarkerType === LineMarkerType.arrowStart || lineMarkerType === LineMarkerType.arrowBoth ? markerUrl : "";

    const selectionStyle = selected ? { opacity: 1, stroke: "black" } : {};

    let baseProperties = {
      role: "presentation",
      className: rootClassNames.join(" "),
    };
    if (enabled) {
      baseProperties = {
        ...baseProperties,
        ...{
          "aria-label": `From ${fromNodeId} to ${toNodeId}`,
          role: "button",
          tabIndex: 0,
          onClick: () => onClick && onClick(link),
          onKeyUp: (ev: React.KeyboardEvent) => ev.which === 13 && onClick && onClick(link),
        },
      };
    }

    return (
      <g {...baseProperties}>
        <line
          role="presentation"
          className={lineClassNames.join(" ")}
          style={{ ...lineStyle, ...selectionStyle }}
          x1={x1}
          x2={x2}
          y1={y1}
          y2={y2}
          markerEnd={markerEnd}
          markerStart={markerStart}
        />
        {imageUrl && (
          <image className={[classNames.image, customClassNames.image].join(" ")} href={imageUrl} {...iconPlacement} />
        )}
        {!imageUrl && iconName && (
          <foreignObject
            className={[classNames.icon, customClassNames.icon, classNames.foreignObjectOnFocusStyle].join(" ")}
            {...iconPlacement}
            style={{ ...iconStyle, ...style, ...selectionStyle }}
            role="presentation"
          >
            <Icon icon={link.icon} />
          </foreignObject>
        )}
        {(text || content) && (
          <foreignObject {...contentPlacement}>{text ? <div style={textStyle}>{text}</div> : content}</foreignObject>
        )}
        {fromValue && (
          <text
            className={classNames.valueText}
            style={{ ...style, ...selectionStyle }}
            aria-labelledby="mytooltiphost"
            textAnchor="middle"
            alignmentBaseline="central"
            x={(lineCenter.x + x1) / 2 - valueTextXAdjust}
            y={(lineCenter.y + y1) / 2 - valueTextYAdjust}
          >
            {fromValue}
          </text>
        )}
        {toValue && (
          <text
            className={classNames.valueText}
            style={{ ...style, ...selectionStyle }}
            textAnchor="middle"
            alignmentBaseline="middle"
            x={(lineCenter.x + x2) / 2 - valueTextXAdjust}
            y={(lineCenter.y + y2) / 2 - valueTextYAdjust}
          >
            {toValue}
          </text>
        )}
      </g>
    );
  }
}

export default Link;
