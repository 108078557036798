import React from "react";
import classNames from "./SearchResult.module.scss";
import { ISearchResultItem } from "../common/interfaces";
import { Link } from "react-router-dom";

export interface ISearchResultProps {
  searchResult: ISearchResultItem[];
}

export class SearchResult extends React.Component<ISearchResultProps> {
  render() {
    const { searchResult } = this.props;

    if (!searchResult) {
      return null;
    }

    if (!searchResult.length) {
      return <div className={classNames.info}>No search result is found. Please try again.</div>;
    }

    return (
      <div className={classNames.root}>
        {searchResult.map((item) => (
          <div className={classNames.item}>
            <Link to={item.link}>
              <div className={classNames.link}>{item.link}</div>
              <div className={classNames.title}>{item.title}</div>
            </Link>
            {item.description && <div className={classNames.description}>{item.description}</div>}
          </div>
        ))}
      </div>
    );
  }
}

export default SearchResult;
