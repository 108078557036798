import React from "react";
import { IIncident } from "./interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import classNames from "./IncidentPostmortem.module.scss";

export interface IIncidentPostmortemProps {
  incident: IIncident;
}

export interface IPostmortem {
  rootCauseMitigation?: string;
  customerImpact?: string;
  receivedImpact?: string;
  qosImpact?: string;
  impactDuration?: string;
  postMortemServiceResponsible?: string;
  postMortemServiceImpacted?: string;
  repeatOutage?: string;
  detection?: { Source?: string; Details?: string };
  howFixed?: string;
  whys?: string;
}

class IncidentPostmortem extends React.Component<IIncidentPostmortemProps> {
  render() {
    const { incident } = this.props;
    const postmortem = incident as IPostmortem;

    if (
      !postmortem.receivedImpact &&
      !postmortem.howFixed &&
      !postmortem.qosImpact &&
      !postmortem.postMortemServiceImpacted
    ) {
      return null;
    }

    const whys: [{ Title: string; Content: string }] = postmortem.whys && JSON.parse(postmortem.whys);
    const whyEntries = whys?.map((why, index) => {
      return (
        <div className={classNames.why} key={`why${index}`}>
          <div className={classNames.title}>{why.Title}</div>
          <div className={classNames.content} dangerouslySetInnerHTML={{ __html: why.Content }} />
        </div>
      );
    });

    return (
      <Tile title="Postmortem" applyContentStyle={true} commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}>
        <div className={classNames.root}>
          <h3 className={classNames.headingAtTop}>Impact</h3>
          {postmortem.impactDuration && (
            <div className={classNames.field}>
              <div className={classNames.name}>Duration</div>
              <div className={classNames.value}>{postmortem.impactDuration}</div>
            </div>
          )}
          {(postmortem.customerImpact || postmortem.receivedImpact) && (
            <div className={classNames.field}>
              <div className={classNames.name}>Customer Impact</div>
              {/* Not sure if the customerImpact is HTML or plain text, so assuming HTML like the recievedImpact */}
              {postmortem.customerImpact && (
                <div
                  className={classNames.valueWithTrailingSpace}
                  dangerouslySetInnerHTML={{ __html: postmortem.customerImpact }}
                />
              )}
              {postmortem.receivedImpact && (
                <div
                  className={classNames.embeddedHtmlNoMargins}
                  dangerouslySetInnerHTML={{ __html: postmortem.receivedImpact }}
                />
              )}
            </div>
          )}
          {postmortem.qosImpact && (
            <div className={classNames.field}>
              <div className={classNames.name}>QOS/SLA Impact</div>
              <div
                className={classNames.embeddedHtmlNoMargins}
                dangerouslySetInnerHTML={{ __html: postmortem.qosImpact }}
              />
            </div>
          )}
          {postmortem.postMortemServiceResponsible && (
            <div className={classNames.field}>
              <div className={classNames.name}>Service Responsible</div>
              <div className={classNames.value}>{postmortem.postMortemServiceResponsible}</div>
            </div>
          )}
          {postmortem.postMortemServiceResponsible && (
            <div className={classNames.field}>
              <div className={classNames.name}>Services Impacted</div>
              <div className={classNames.value}>{postmortem.postMortemServiceImpacted}</div>
            </div>
          )}
          {postmortem.repeatOutage && (
            <div className={classNames.field}>
              <div className={classNames.name}>Repeat Outage</div>
              <div className={classNames.value}>{postmortem.repeatOutage ? "Yes" : "No"}</div>
            </div>
          )}
        </div>
        {whyEntries && (
          <div className={classNames.root}>
            <h3 className={classNames.heading}>5 Whys</h3>
            {whyEntries}
          </div>
        )}
        <div className={classNames.root}>
          <h3 className={classNames.heading}>Detection and Mitigation</h3>
          <div className={classNames.field}>
            <div className={classNames.name}>Detection Source</div>
            <div className={classNames.value}>{postmortem.detection?.Source}</div>
          </div>
          <div className={classNames.field}>
            <div className={classNames.name}>Detection Details</div>
            <div
              className={classNames.embeddedHtmlNoMargins}
              dangerouslySetInnerHTML={{ __html: postmortem.detection?.Details }}
            />
          </div>
          {postmortem.rootCauseMitigation && (
            <div className={classNames.field}>
              <h4 className={classNames.heading}>Mitigation Steps</h4>
              <div
                className={classNames.embeddedHtmlNoMargins}
                dangerouslySetInnerHTML={{ __html: postmortem.rootCauseMitigation }}
              />
            </div>
          )}
        </div>
        {postmortem.howFixed && (
          <div className={classNames.root}>
            <div className={classNames.field}>
              <h3 className={classNames.heading}>Fix</h3>
              <div
                className={classNames.embeddedHtmlNoMargins}
                dangerouslySetInnerHTML={{ __html: postmortem.howFixed }}
              />
            </div>
          </div>
        )}
      </Tile>
    );
  }
}

export default IncidentPostmortem;
