import React from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";
import { ConfigItemType } from "../../../api/radarApi";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { Icon } from "@fluentui/react/lib/Icon";
import { IMyChecklistListProps } from "./MyChecklistList";

export const getColumns = (props: IMyChecklistListProps, classNames: any): IColumn[] => {
  const { isStaging, history } = props;

  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      data: "number",
    },
    {
      key: "name",
      name: "Checklist Name",
      fieldName: "name",
      minWidth: 200,
      isResizable: true,
    },
    {
      key: "childConfigId",
      name: "DevOps View ID",
      fieldName: "childConfigId",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) =>
        item.childConfigType === ConfigItemType.DevOpsViews && item.childConfigId ? (
          <div className={classNames.editableItems}>
            <div className={classNames.text}>
              <button
                onClick={() => history.push(`${leftNavUrls.works.home}/${item.childConfigId}`)}
                className={classNames.buttonAsHyperlink}
              >
                {item.childConfigId}
              </button>
            </div>
            <Icon
              iconName="View"
              title={`View Related Work Items`}
              className={classNames.itemAction}
              onClick={() => history.push(`${leftNavUrls.works.home}/${item.childConfigId}`)}
            />
            {isStaging && (
              <Icon
                iconName="Edit"
                title={`Edit Related DevOps View`}
                className={classNames.itemAction}
                onClick={() => history.push(`${leftNavUrls.management.devOpsView}/${item.childConfigId}`)}
              />
            )}
          </div>
        ) : null,
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item._modifiedBy, ContentDisplayType.contact),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item.owner, ContentDisplayType.contact),
    },
    {
      key: "editors",
      name: "Editors",
      fieldName: "editors",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => getFormatContent(item.editors, ContentDisplayType.contact),
    },
  ];
};
