import React from "react";
import KpiTile from "../../shared/components/Tile/KpiTile";
import { Tile } from "../../shared/components/Tile";
import GroupingTile from "../../shared/components/Tile/GroupingTile";
import { IIncident, IIncidentView } from "./interfaces";
import { XYPlot, XAxis, YAxis, VerticalBarSeries, Hint, DiscreteColorLegend } from "react-vis";
import { statusColors } from "./IncidentsReport.helper";
import { VisualTileList } from "../../shared/components/Tile/VisualTileList";
import classNames from "./IncidentListSummary.module.scss";

const colorRange = [statusColors.active, statusColors.mitigating, statusColors.mitigated, statusColors.resolved];

export interface IIncidentListSummaryProps {
  incidents: IIncident[];
  view: IIncidentView;
  loadingTiles: boolean;
}

export interface IIncidentListSummaryState {
  hoveredValue: any;
}

export class IncidentListSummary extends React.Component<IIncidentListSummaryProps, IIncidentListSummaryState> {
  state: IIncidentListSummaryState = {
    hoveredValue: false,
  };

  render() {
    const { incidents, view, loadingTiles } = this.props;

    if (!incidents || !incidents.length) return null;

    const {
      avgImpactTime,
      avgDiscoverTime,
      avgMitigateTime,
      avgResolveTime,
      impactChartData,
      discoverChartData,
      mitigateChartData,
      resolveChartData,
      legendItems,
    } = calcMetrics(incidents);

    const { hoveredValue } = this.state;

    return (
      <div className={classNames.root}>
        <GroupingTile title={`${(view && view.name) || ""} Incident Analytics`}>
          <KpiTile title="# of Selected Incidents" value={incidents.length} />
          {avgDiscoverTime && (
            <KpiTile title="Avg Time To Discover (days)" value={convertTimeToDays(avgDiscoverTime)} />
          )}
          {avgMitigateTime && (
            <KpiTile title="Avg Time To Mitigate (days)" value={convertTimeToDays(avgMitigateTime)} />
          )}
          {avgResolveTime && <KpiTile title="Avg Time To Resolve (days)" value={convertTimeToDays(avgResolveTime)} />}
          <KpiTile title="Avg # Incident Started Per Month" value={Math.round(avgImpactTime)} />
          <Tile title="Incidents Per Month (last 12m)" className={classNames.chartTile} compact={true}>
            <XYPlot
              className={classNames.chart}
              xType="ordinal"
              height={112}
              width={15 * impactChartData.length + 80}
              margin={{ left: 40, right: 10, top: 10, bottom: 40 }}
              stackBy="y"
              colorDomain={[0, 1, 2, 3]}
              colorRange={colorRange}
              onMouseLeave={() => this.setState({ hoveredValue: false })}
            >
              <XAxis tickLabelAngle={-90} tickFormat={(v) => v.substring(0, 3)} />
              <YAxis tickTotal={4} />
              <VerticalBarSeries data={impactChartData} onValueMouseOver={(d) => this.setState({ hoveredValue: d })} />
              <VerticalBarSeries
                data={discoverChartData}
                onValueMouseOver={(d) => this.setState({ hoveredValue: d })}
              />
              <VerticalBarSeries
                data={mitigateChartData}
                onValueMouseOver={(d) => this.setState({ hoveredValue: d })}
              />
              <VerticalBarSeries data={resolveChartData} onValueMouseOver={(d) => this.setState({ hoveredValue: d })} />
              {hoveredValue && (
                <Hint value={hoveredValue}>
                  <div className={classNames.tooltip}>
                    <div className={classNames.month}>{hoveredValue.x}</div>
                    <div className={classNames.value}>
                      {hoveredValue.category}: {hoveredValue.y - (hoveredValue.y0 || 0)}
                    </div>
                  </div>
                </Hint>
              )}
            </XYPlot>
            <DiscreteColorLegend className={classNames.legends} items={legendItems} />
          </Tile>
          {view && view.tiles && !!view.tiles.length && (
            <div className={classNames.customTilesPane}>
              <VisualTileList tiles={view.tiles} dataList={view.tileResults} loadingTiles={loadingTiles} />
            </div>
          )}
        </GroupingTile>
      </div>
    );
  }
}

export default IncidentListSummary;

const convertTimeToDays = (time: number): string | number => Math.round(time / 86400000);

const getMonthKey = (date: Date): string =>
  `${date.toLocaleDateString("en-us", { month: "short" })} ${date.getFullYear()}`;

const calcMetrics = (incidents: IIncident[]) => {
  let avgImpactTime,
    avgDiscoverTime,
    avgMitigateTime,
    avgResolveTime,
    discoverTimeCount = 0,
    mitigateTimeCount = 0,
    resolveTimeCount = 0,
    impactMonthMap = {},
    discoverMonthMap = {},
    mitigateMonthMap = {},
    resolveMonthMap = {};

  incidents.forEach((incident) => {
    if (incident.impactStartDate) {
      let impactDate = new Date(incident.impactStartDate),
        impactTime = impactDate.valueOf(),
        impactMonthKey = getMonthKey(impactDate),
        discoveredDate = new Date(incident.discoveredDate),
        discoverMonthKey = getMonthKey(discoveredDate),
        mitigateDate = new Date(incident.mitigateDate),
        mitigateMonthKey = getMonthKey(mitigateDate),
        resolveDate = new Date(incident.resolveDate),
        resolveMonthKey = getMonthKey(resolveDate);

      if (incident.impactStartDate) {
        impactMonthMap[impactMonthKey] = impactMonthMap[impactMonthKey] ? impactMonthMap[impactMonthKey] + 1 : 1;
      }

      if (incident.discoveredDate) {
        let discoverTime = Math.abs(new Date(incident.discoveredDate).valueOf() - impactTime);
        avgDiscoverTime = avgDiscoverTime ? avgDiscoverTime + discoverTime : discoverTime;
        discoverTimeCount++;

        discoverMonthMap[discoverMonthKey] = discoverMonthMap[discoverMonthKey]
          ? discoverMonthMap[discoverMonthKey] + 1
          : 1;
      }

      if (incident.mitigateDate) {
        let mitigateTime = Math.abs(new Date(incident.mitigateDate).valueOf() - impactTime);
        avgMitigateTime = avgMitigateTime ? avgMitigateTime + mitigateTime : mitigateTime;
        mitigateTimeCount++;

        mitigateMonthMap[mitigateMonthKey] = mitigateMonthMap[mitigateMonthKey]
          ? mitigateMonthMap[mitigateMonthKey] + 1
          : 1;
      }

      if (incident.resolveDate) {
        let resolveTime = Math.abs(new Date(incident.resolveDate).valueOf() - impactTime);
        avgResolveTime = avgResolveTime ? avgResolveTime + resolveTime : resolveTime;
        resolveTimeCount++;

        resolveMonthMap[resolveMonthKey] = resolveMonthMap[resolveMonthKey] ? resolveMonthMap[resolveMonthKey] + 1 : 1;
      }
    }
  });

  avgDiscoverTime && (avgDiscoverTime = avgDiscoverTime / discoverTimeCount);
  avgMitigateTime && (avgMitigateTime = avgMitigateTime / mitigateTimeCount);
  avgResolveTime && (avgResolveTime = avgResolveTime / resolveTimeCount);

  // Calculate average impact started per month. Ignore months that do not have any.
  Object.keys(impactMonthMap).forEach((key) => {
    avgImpactTime = avgImpactTime ? avgImpactTime + impactMonthMap[key] : impactMonthMap[key];
  });

  avgImpactTime && (avgImpactTime = avgImpactTime / Object.keys(impactMonthMap).length);

  // Prepare data for the impact started per month chart.
  let maxTime = new Date().valueOf(),
    minTime = new Date().setFullYear(new Date().getFullYear() - 1),
    currentTime = minTime,
    impactChartData = [],
    discoverChartData = [],
    mitigateChartData = [],
    resolveChartData = [],
    legendItems = [],
    maxCount = 100,
    itemCount = 0;

  while (currentTime <= maxTime && itemCount++ < maxCount) {
    let currentDate = new Date(currentTime),
      monthKey = getMonthKey(currentDate),
      impactValue = impactMonthMap[monthKey] || 0,
      discoverValue = discoverMonthMap[monthKey] || 0,
      mitigateValue = mitigateMonthMap[monthKey] || 0,
      resolveValue = resolveMonthMap[monthKey] || 0;

    impactChartData.push({ x: monthKey, y: impactValue, color: 0, category: "Impact Started" });
    discoverChartData.push({ x: monthKey, y: discoverValue, color: 1, category: "Incidents Discovered" });
    mitigateChartData.push({ x: monthKey, y: mitigateValue, color: 2, category: "Incidents Mitigated" });
    resolveChartData.push({ x: monthKey, y: resolveValue, color: 3, category: "Incidents Resolved" });

    currentDate.setMonth(currentDate.getMonth() + 1);
    currentTime = new Date(currentDate).valueOf();
  }

  Object.keys(resolveMonthMap).length &&
    legendItems.push({ title: "Incident Resolved", color: colorRange[3], strokeWidth: 5 });
  Object.keys(mitigateMonthMap).length &&
    legendItems.push({ title: "Incident Mitigated", color: colorRange[2], strokeWidth: 5 });
  Object.keys(discoverMonthMap).length &&
    legendItems.push({ title: "Incident Discovered", color: colorRange[1], strokeWidth: 5 });
  Object.keys(impactMonthMap).length &&
    legendItems.push({ title: "Impact Started", color: colorRange[0], strokeWidth: 5 });

  return {
    avgImpactTime,
    avgDiscoverTime,
    avgMitigateTime,
    avgResolveTime,
    impactChartData,
    discoverChartData,
    mitigateChartData,
    resolveChartData,
    legendItems,
  };
};
