import React, { CSSProperties } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  XAxis,
  YAxis,
  LineSeries,
  VerticalBarSeries,
  HorizontalBarSeries,
  AreaSeries,
  Hint,
  Crosshair,
} from "react-vis";
import CandlestickSeries from "./CandlestickSeries";
import { IVisualProps } from "./Visual";
import { getGroupNames, VisualType } from "./Visual.helper";
import { getItemField } from "../../utilities/miscHelper";
import PieChart from "./PieChart";
import { DISCRETE_COLOR_RANGE, EXTENDED_DISCRETE_COLOR_RANGE } from "react-vis/dist/theme";
import classNames from "./ChartVisual.module.scss";
import { getColorFromText } from "../../utilities/colorHelper";

const defaultWidth = 300;
const defaultHeight = 240;
const defaultMaxWidth = 600;
const defaultLegendWidth = 100;
const defaultLegendHeight = 36;
const defaultSeriesItemWidth = 15;
const defaultTickLabelAngel = -90;
const defaultChartMargin = { left: 50, right: 10, top: 10, bottom: 50 };
const xAxisLabelMinWidth = 14;
const defaultColors = DISCRETE_COLOR_RANGE.concat(EXTENDED_DISCRETE_COLOR_RANGE.slice().reverse());

export interface IChartVisualBaseProps {
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  seriesItemWidth?: number;
  colors?: string[];
  tickLabelAngle?: number;
  margin?: any;
  xDomain?: any[];
  yDomain?: any[];
  yDomainMin?: number;
  yDomainMax?: number;
  seriesProps?: any[];
  legendPosition?: "top" | "bottom" | "left" | "right";
  legendWidth?: number;
  legendHeight?: number;
  legendProps?: any;
  tooltipProps?: any;
  hideZeroValuesInTooltip?: boolean;
  xAxisStyle?: CSSProperties;
  xAxisProps?: any;
  yAxisStyle?: CSSProperties;
  yAxisProps?: any;
  alwaysHideLegend?: boolean;
  alwaysShowLegend?: boolean;
  doNotFillInMissingValues?: boolean;
  showValueLabels?: boolean;
  valueLabelsProps?: any;
  useColorByName?: boolean;
}

export interface IChartVisualProps extends IChartVisualBaseProps {
  onXAxisTickFormat?: (v) => any;
  onYAxisTickFormat?: (v) => any;
}

export interface IChartVisualState {
  hoveredValue: any;
}

export class ChartVisual extends React.Component<IVisualProps, IChartVisualState> {
  state: IChartVisualState = {
    hoveredValue: false,
  };

  componentDidCatch(error, info) {}

  render() {
    const {
      data,
      visualType = VisualType.column,
      visualProps = {},
      categoryFieldName = "category",
      valueFieldNames = ["value"],
      groupFieldNames = ["group"],
      seriesSortOrder,
      highFieldName = "high",
      lowFieldName = "low",
      openFieldName = "open",
      closeFieldName = "close",
      stacked = false,
      valueDisplayType,
      categoryDisplayType,
      doNotShowAxes,
    } = this.props;

    if (!data || !data.length) return null;

    const { hoveredValue } = this.state;

    const {
      tickLabelAngle = defaultTickLabelAngel,
      onYAxisTickFormat,
      onXAxisTickFormat,
      width = this.props.width || defaultWidth,
      maxWidth = defaultMaxWidth,
      height = Number(this.props.height || defaultHeight),
      seriesItemWidth = defaultSeriesItemWidth,
      margin: origMargin = {},
      useColorByName = false,
      colors = !useColorByName && defaultColors,
      xDomain,
      yDomainMin,
      yDomainMax,
      yDomain,
      seriesProps: visualSeriesProps,
      legendPosition = "top",
      legendWidth = defaultLegendWidth,
      legendHeight = defaultLegendHeight,
      hideZeroValuesInTooltip,
      xAxisStyle,
      xAxisProps,
      yAxisStyle,
      yAxisProps,
      alwaysHideLegend,
      alwaysShowLegend,
      doNotFillInMissingValues,
    } = visualProps as IChartVisualProps;

    const margin = {
      ...defaultChartMargin,
      ...origMargin,
    };

    let transformedData = [],
      isReversedAxis = visualType === VisualType.bar,
      groupNames = getGroupNames(data, groupFieldNames, seriesSortOrder),
      categoryValues = [],
      minValue = 0,
      maxValue = 0,
      yMaxValueMap = {}; // Used to calculate y max value based on the same x/category value.

    if (!doNotFillInMissingValues) {
      data.forEach((item) => {
        let categoryValue = getItemField(item, categoryFieldName);

        if (categoryValues.indexOf(categoryValue) < 0) {
          categoryValues.push(categoryValue);
        }
      });
    }

    const categoryCount = categoryValues.length || data?.length || 0;
    const onXAxisTickFormatEnhanced: any = (value, index) =>
      this.transformOnXAxisTickFormat(value, index, this.props, width, categoryCount, onXAxisTickFormat);

    if (groupNames.length) {
      groupNames.forEach((groupName) => {
        let groupValues = groupName.split(","),
          seriesName = groupName.replace(/,/g, " - ");

        valueFieldNames.forEach((valueFieldName) => {
          let seriesData = [];

          data.forEach((item) => {
            groupFieldNames.forEach((groupFieldName, index) => {
              if (getItemField(item, groupFieldName) === groupValues[index]) {
                let seriesDataItem = this.transformSeriesData(
                  seriesName,
                  item,
                  isReversedAxis,
                  valueFieldName,
                  categoryFieldName,
                  highFieldName,
                  lowFieldName,
                  openFieldName,
                  closeFieldName
                );

                var existingSeriesDataItem = seriesData.find(
                    (item) => item.seriesName === seriesDataItem.seriesName && item.x === seriesDataItem.x
                  ),
                  yValue = seriesDataItem.y;

                if (existingSeriesDataItem) {
                  existingSeriesDataItem.y += seriesDataItem.y;
                  yValue = existingSeriesDataItem.y;
                } else {
                  seriesData.push(seriesDataItem);
                }

                yMaxValueMap[seriesDataItem.x] = stacked
                  ? (Number(yMaxValueMap[seriesDataItem.x]) || 0) + seriesDataItem.y
                  : yValue;

                minValue = Math.min(minValue, yValue);
                maxValue = Math.max(maxValue, yValue);
              }
            });
          });

          // Fill in any missing category values unless it's opted out from visual props.
          if (!doNotFillInMissingValues) {
            categoryValues.forEach((categoryValue, index) => {
              var isCategoryValueFound = seriesData.find(
                (item) => item["x"] === categoryValue || item["y"] === categoryValue
              );

              if (!isCategoryValueFound) {
                var zeroValueItem = isReversedAxis
                  ? { seriesName, x: 0, y: categoryValue }
                  : { seriesName, x: categoryValue, y: 0 };
                seriesData.splice(index, 0, zeroValueItem);
              }
            });
          }

          transformedData.push(seriesData);
        });
      });

      if (stacked) {
        Object.keys(yMaxValueMap).forEach((key) => {
          maxValue = Math.max(maxValue, yMaxValueMap[key]);
        });
      }
    } else {
      valueFieldNames.forEach((valueFieldName) => {
        transformedData.push(
          data.map((item) => {
            let seriesDataItem = this.transformSeriesData(
              valueFieldName,
              item,
              isReversedAxis,
              valueFieldName,
              categoryFieldName,
              highFieldName,
              lowFieldName,
              openFieldName,
              closeFieldName
            );

            minValue = Math.min(minValue, seriesDataItem.y);
            maxValue = Math.max(maxValue, seriesDataItem.y);
            return seriesDataItem;
          })
        );
      });
    }

    let chartHeight = Number(height) || defaultHeight,
      chartWidth = Number(width) || Math.min(maxWidth, seriesItemWidth * data.length + margin.left + margin.right),
      isPieVisual = visualType === VisualType.pie,
      showAxis = !isPieVisual && !doNotShowAxes,
      showLegend = !alwaysHideLegend && (transformedData.length > 1 || alwaysShowLegend);

    let yDomainFinal = yDomain || getDefaultYDomain(yDomainMin, yDomainMax, minValue, maxValue);

    let chartClassName = "";

    // Check if legends are displayed (when more than one series of data are being displayed)
    if (showLegend) {
      // Adjust the chart width or height based on the maxiumn width and height of the legend pane.
      if (legendPosition === "left" || legendPosition === "right") {
        chartClassName = classNames.chartWithVerticalLegend;
        chartWidth -= legendWidth;
      } else {
        chartHeight -= legendHeight;
      }
    }

    return (
      <div className={classNames.visualContainer}>
        {showLegend &&
          legendPosition === "top" &&
          renderLegends(transformedData, colors, width, legendHeight, classNames.horizontalLegend)}
        {showLegend &&
          legendPosition === "left" &&
          renderLegends(transformedData, colors, legendWidth, height, classNames.verticalLegend)}
        {isPieVisual && (
          <PieChart
            // TODO: Task 37701258: PieChart error: Type 'Ref<HTMLDivElement>' is not assignable to type 'LegacyRef<PieChart>'
            {...(visualProps as any)}
            height={chartHeight}
            width={chartWidth}
            data={data}
            categoryFieldName={categoryFieldName}
            valueFieldName={valueFieldNames[0]}
            valueDisplayType={valueDisplayType}
            categoryDisplayType={categoryDisplayType}
          />
        )}
        {!isPieVisual && (
          <div className={chartClassName}>
            <XYPlot
              xType={isReversedAxis ? "linear" : "ordinal"}
              yType={isReversedAxis ? "ordinal" : "linear"}
              height={chartHeight}
              width={chartWidth}
              margin={margin}
              stackBy={stacked ? (isReversedAxis ? "x" : "y") : undefined}
              colorType="category"
              onMouseLeave={() => this.setState({ hoveredValue: false })}
              xDomain={xDomain}
              yDomain={yDomainFinal}
            >
              {showAxis && (
                <XAxis
                  height={margin.bottom}
                  tickLabelAngle={tickLabelAngle}
                  tickFormat={isReversedAxis ? onYAxisTickFormat : onXAxisTickFormatEnhanced}
                  style={xAxisStyle}
                  {...xAxisProps}
                />
              )}
              {showAxis && (
                <YAxis
                  width={margin.left}
                  tickFormat={isReversedAxis ? onXAxisTickFormatEnhanced : onYAxisTickFormat}
                  style={yAxisStyle}
                  {...yAxisProps}
                />
              )}
              {transformedData.map((dataSeries, index) => {
                let customSeriesProps =
                    visualSeriesProps && visualSeriesProps.length > index && visualSeriesProps[index],
                  seriesVisualType = (customSeriesProps && customSeriesProps.visualType) || visualType,
                  title = dataSeries[0].seriesName,
                  seriesProps = {
                    ...customSeriesProps,
                    key: "chartVisual" + index,
                    data: dataSeries,
                    color: colors ? colors?.length > index && colors[index] : getColorFromText(title),
                    onNearestX: !isReversedAxis
                      ? (d, { index }) =>
                          this.setState({ hoveredValue: transformedData.map((series) => series[index]) })
                      : null,
                    onValueMouseOver: isReversedAxis
                      ? (d) => {
                          var hoveredValues = [];
                          transformedData.forEach((series) => {
                            series.forEach((dataItem) => dataItem.y === d.y && hoveredValues.push(dataItem));
                          });
                          this.setState({ hoveredValue: hoveredValues });
                        }
                      : null,
                    onValueMouseOut: isReversedAxis ? () => this.setState({ hoveredValue: null }) : null,
                    colorType: "literal",
                  };

                if (seriesVisualType === VisualType.bar) {
                  return <HorizontalBarSeries {...seriesProps} />;
                } else if (seriesVisualType === VisualType.column) {
                  return <VerticalBarSeries {...seriesProps} />;
                } else if (seriesVisualType === VisualType.line) {
                  return <LineSeries {...seriesProps} />;
                } else if (seriesVisualType === VisualType.area) {
                  return <AreaSeries {...seriesProps} />;
                } else if (seriesVisualType === VisualType.candlestick) {
                  return <CandlestickSeries colorType="literal" opacityType="literal" data={dataSeries} x={0} y={0} />;
                }
                return null;
              })}
              {hoveredValue && hoveredValue.length === 1 && hoveredValue[0] && (
                <Hint value={hoveredValue[0]}>
                  <div className={classNames.tooltip}>
                    <span className={classNames.category}>
                      {onXAxisTickFormat && onXAxisTickFormat(isReversedAxis ? hoveredValue[0].y : hoveredValue[0].x)}:
                    </span>
                    <span className={classNames.value}>
                      {onYAxisTickFormat && onYAxisTickFormat(isReversedAxis ? hoveredValue[0].x : hoveredValue[0].y)}
                    </span>
                  </div>
                </Hint>
              )}
              {hoveredValue && hoveredValue.length > 1 && (
                <Crosshair values={hoveredValue}>
                  <div className={classNames.tooltip}>
                    <div className={classNames.category}>
                      {onXAxisTickFormat &&
                        hoveredValue[0] &&
                        onXAxisTickFormat(isReversedAxis ? hoveredValue[0].y : hoveredValue[0].x)}
                    </div>
                    {hoveredValue.map &&
                      hoveredValue
                        .filter((value) => !hideZeroValuesInTooltip || (isReversedAxis ? value.x > 0 : value.y > 0))
                        .map((value) =>
                          value ? (
                            <div key={`tooltipvalue-${value.seriesName}`}>
                              <span className={classNames.seriesName}>{value.seriesName}:</span>
                              <span className={classNames.value}>
                                {onYAxisTickFormat && onYAxisTickFormat(isReversedAxis ? value.x : value.y)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                  </div>
                </Crosshair>
              )}
            </XYPlot>
          </div>
        )}
        {showLegend &&
          legendPosition === "bottom" &&
          renderLegends(transformedData, colors, width, legendHeight, classNames.horizontalLegend)}
        {showLegend &&
          legendPosition === "right" &&
          renderLegends(transformedData, colors, legendWidth, height, classNames.verticalLegend)}
      </div>
    );
  }

  transformSeriesData = (
    seriesName,
    item,
    isReversedAxis,
    valueFieldName,
    categoryFieldName,
    highFieldName,
    lowFieldName,
    openFieldName,
    closeFieldName
  ) => ({
    seriesName,
    x: isReversedAxis ? getItemField(item, valueFieldName) : getItemField(item, categoryFieldName),
    y: isReversedAxis ? getItemField(item, categoryFieldName) : getItemField(item, valueFieldName),
    color: getItemField(item, "color"),
    yHigh: getItemField(item, highFieldName),
    yLow: getItemField(item, lowFieldName),
    yOpen: getItemField(item, openFieldName),
    yClose: getItemField(item, closeFieldName),
  });

  transformArcSeriesData = (item, valueFieldName, categoryFieldName, startingAngle) => ({
    angle0: startingAngle,
    angle: startingAngle + 1,
    radius0: 0,
    radius: 1,
  });

  transformOnXAxisTickFormat = (value, index, tile, tileWidth, dataCount = 1, onXAxisTickFormat) => {
    if (!onXAxisTickFormat) return null;

    let autoLabelGap =
        !tile.visualProps || tile.visualProps.autoLabelGap === undefined || tile.visualProps.autoLabelGap,
      labelWidthRatio = tile.visualProps?.autoLabelRatio || (xAxisLabelMinWidth * dataCount) / parseInt(tileWidth),
      showLabel = labelWidthRatio <= 1 ? true : index % Math.ceil(labelWidthRatio) === 0;

    return !autoLabelGap || showLabel ? onXAxisTickFormat(value) : "";
  };
}

export default ChartVisual;

const getDefaultYDomain = (yDomainMin, yDomainMax, minValue, maxValue) => {
  if (!yDomainMin && !yDomainMax && !minValue && !maxValue) return undefined;

  return [yDomainMin || minValue, yDomainMax || Math.ceil(maxValue / 5) * 5]; // Ceiling max value to the nearest 5.
};

const renderLegends = (transformedData: any[], colors, width, height, className: string = "") => {
  return (
    <div
      style={{ width: width && Number(width), height: height && Number(height) }}
      tabIndex={0}
      className={`${classNames.legendContainer} ${className}`}
    >
      {transformedData.map((series, index) => {
        var title = series[0].seriesName,
          color = colors ? colors?.length > index && colors[index] : getColorFromText(title);

        return (
          <div className={`${classNames.legendItem}`} key={`chartLegend-${title}-${index}`}>
            <div className={classNames.legendBullet} style={{ backgroundColor: color }} />
            <div className={classNames.legendTitle}>{title}</div>
          </div>
        );
      })}
    </div>
  );
};
