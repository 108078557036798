import React from "react";
import { getBasicPageCommandBarItems } from "../common/helper";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IGraphHomeStateProps, IGraphHomeDispatchProps } from "./GraphHome";
import { IColumn, IGroup } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { IconButton } from "@fluentui/react/lib/Button";
import { IEntityMetric, IFinancialEntity, ISearchField, ISearchFilter, ITile } from "../common/interfaces";
import { IAppModuleState, searchDateType } from "../interfaces";
import { IMetric } from "../../shared/components/Diagram";
import classNames from "./GraphHome.module.scss";
import { getDateTime, getFirstOfMonth, getLastOfMonth } from "../../shared/utilities/miscHelper";
import { IEntityView } from "../common/interfaces";
import { IVisualProps } from "../../shared/components/Visual";

export const dynamicEntityViewId = "dynamicView";
export const entityBackgroundColor = "#0078d4";
export const entityBorderColor = "#D986C2";
export const relatedEntityBackgroundColor = "#005a9e";

export enum SearchItemKey {
  metricSearch = "0",
  entitySearch = "1",
  entityLookup = "2",
}

export enum EntityRelationshipType {
  Parent = "Parent",
  Source = "Source",
}

export enum EntitySubPaneKey {
  Analytics = "Analytics",
  Entities = "Entities",
  History = "History",
  Metrics = "Metrics",
  Tests = "Tests",
  TraceMetrics = "TraceMetrics",
}

export enum BuiltInMetricId {
  EntityCount = "EntityCount",
  EntityMaxTimestamp = "EntityMaxTimestamp",
  SlaExpected = "SlaExpected",
  SlaPending = "SlaPending",
  SlaPendingIn = "SlaPendingIn",
  SlaPendingOut = "SlaPendingOut",
  SlaCompleted = "SlaCompleted",
  SlaCompletedIn = "SlaCompletedIn",
  SlaCompletedOut = "SlaCompletedOut",
  ValidationPass = "ValidationPass",
  ValidationFail = "ValidationFail",
}

export const booleanFilterOperators = [
  { key: "=", text: "=" },
  { key: "!=", text: "!=" },
];

export const stringFilterOperators = [
  { key: "=", text: "=" },
  { key: "!=", text: "!=" },
  { key: "LIKE", text: "contains" },
  { key: "NOT LIKE", text: "not contain" },
];

export const numberFilterOperators = [
  { key: "=", text: "=" },
  { key: "!=", text: "!=" },
  { key: ">", text: ">" },
  { key: ">=", text: ">=" },
  { key: "<", text: "<" },
  { key: "<=", text: "<=" },
];

export const dateFilterOperators = [
  { key: "=", text: "on" },
  { key: "!=", text: "not on" },
  { key: ">", text: "after" },
  { key: ">=", text: "on and after" },
  { key: "<", text: "before" },
  { key: "<=", text: "on and before" },
];

export const logicalOperators = ["and", "or"];

export interface IGraphSearchFilterActions {
  onAddFilterClick: () => void;
  onEditFilterClick: () => void;
  onRemoveFilterClick: () => void;
  onGroupFilterClick: () => void;
  onUngroupFilterClick: () => void;
  onClearAllFiltersClick: () => void;
  onRestoreFiltersClick: () => void;
}

export const getCommandBarProps = (props: IGraphHomeStateProps & IGraphHomeDispatchProps): ICommandBarProps => {
  const {
    isSmallScreen,
    loadEntity,
    loadEntityMetrics,
    loadServiceContentConfigItems,
    loadEntityTraceViewConfigItems,
    selectedEntity,
    searchItemKey,
    startTime,
    endTime,
  } = props;
  var basicPageCommandBarActions,
    entity = selectedEntity && selectedEntity["entity"];

  basicPageCommandBarActions = {
    refresh: () => {
      loadServiceContentConfigItems(true);
      loadEntityTraceViewConfigItems(true);

      searchItemKey === SearchItemKey.metricSearch
        ? loadEntityMetrics(startTime, endTime, true)
        : searchItemKey === SearchItemKey.entitySearch
        ? loadEntityMetrics(null, null, true)
        : entity && loadEntity(entity.entityType, entity.id, entity.version, true);
    },
  };

  let commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions)],
  };

  return commandBarProps;
};

const getRelatedEntityTitle = (item) => `Show related entities for metric ${item.name} of ${item.entityType}`;

export const getEntityMetricColumns = (selectEntityMetric: (entityType, metricId) => void): IColumn[] => [
  {
    key: "relatedEntities",
    name: "Select",
    minWidth: 40,
    maxWidth: 40,
    isResizable: true,
    onRender: (item) => (
      <IconButton
        iconProps={{ iconName: "TextDocument" }}
        title={getRelatedEntityTitle(item)}
        aria-label={getRelatedEntityTitle(item)}
        className={classNames.itemAction}
        onClick={() => selectEntityMetric(item.entityType, item.id)}
      />
    ),
  },
  {
    key: "name",
    fieldName: "name",
    name: "Metric Name",
    minWidth: 200,
    maxWidth: 240,
    isResizable: true,
  },
  {
    key: "value",
    fieldName: "value",
    name: "Value",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    onRender: (item) => (
      <span
        style={{
          color: `${item.color || "black"}`,
        }}
      >
        {getFormatContent(item.template ? item.metricValues : item.value, item.displayType, undefined, item.template)}
      </span>
    ),
  },
];

export const flattenEntityMetrics = (entityMetrics: IEntityMetric[]): any[] => {
  let result = [];

  entityMetrics &&
    entityMetrics.forEach((entity) => {
      entity.metrics &&
        entity.metrics.forEach((metric) => {
          result.push({
            entityType: entity.entityType,
            ...metric,
          });
        });
    });

  return result;
};

export const getEntityMetricGroups = (items: any[]): IGroup[] => {
  let groups = [],
    prevType = null,
    startIndex = 0;

  items = items.sort((a, b) => (a.entityType > b.entityType ? 1 : a.entityType < b.entityType ? -1 : 0));

  items.forEach((item, index) => {
    let entityType = item.entityType;

    if (entityType !== prevType) {
      let count = index - startIndex;

      prevType && pushEntityMetricGroup(groups, prevType, startIndex, count);
      startIndex = index;
      prevType = entityType;
    }
  });

  pushEntityMetricGroup(groups, prevType, startIndex, items.length - startIndex);

  return groups;
};

const pushEntityMetricGroup = (groups: IGroup[], type: string, startIndex: number, count: number) => {
  groups.push({ key: type, name: type, startIndex, count });
};

export const renderValidationTestResult = (fail: number, pass: number) => {
  let style = { padding: "2px 4px" },
    failStyle = { ...style, backgroundColor: "#FCC" },
    passStyle = { ...style, backgroundColor: "#CFC" };

  return fail && pass ? (
    <div>
      <span style={failStyle}>{fail}</span> fail, <span style={passStyle}>{pass}</span> pass
    </div>
  ) : pass ? (
    <div>
      all <span style={passStyle}>{pass}</span> pass
    </div>
  ) : fail ? (
    <div>
      all <span style={failStyle}>{fail}</span> fail
    </div>
  ) : null;
};

export const getSelectedEntityTypes = (
  state: IAppModuleState,
  entityViewId?: string,
  returnLimitedDynamicItems?: boolean,
  searchEntityType?: string
): string[] => {
  let entityTypes = [],
    entityViews = state.entity_views,
    selectedView =
      entityViews && entityViews.find((view) => view.id === (entityViewId || state.selected_entity_view_id));

  if (!selectedView) {
    return entityTypes;
  }

  // For dynamic view, only include entity types of immediate source and target.
  if (
    returnLimitedDynamicItems &&
    searchEntityType &&
    selectedView.id === dynamicEntityViewId &&
    selectedView.nodes &&
    selectedView.links
  ) {
    selectedView.nodes.forEach((node) => {
      if (node.id === searchEntityType) {
        entityTypes.indexOf(node.id) < 0 && entityTypes.push(node.id);
      } else {
        selectedView.links.forEach((link) => {
          if (link.toNodeId === searchEntityType) {
            entityTypes.indexOf(link.fromNodeId) < 0 && entityTypes.push(link.fromNodeId);
          }
          if (link.fromNodeId === searchEntityType) {
            entityTypes.indexOf(link.toNodeId) < 0 && entityTypes.push(link.toNodeId);
          }
        });
      }
    });
  } else {
    selectedView.nodes &&
      selectedView.nodes.forEach((node) => entityTypes.indexOf(node.id) < 0 && entityTypes.push(node.id));
  }

  return entityTypes.sort();
};

export const getFinancialEntitiesByEntityView = (
  financialEntities: IFinancialEntity[],
  entityTypes: string[]
): IFinancialEntity[] => {
  if (!financialEntities || !financialEntities.length || !entityTypes || !entityTypes.length) {
    return null;
  }

  let result = [];

  financialEntities.forEach((entity) => {
    if (entityTypes.indexOf(entity.type) >= 0) {
      result.push(entity);
    }
  });

  return result;
};

export const transformEntityMetrics = (entityMetrics: IEntityMetric[]): IEntityMetric[] =>
  entityMetrics &&
  entityMetrics.map((entityMetric) => {
    const metricValues = getMetricValues(entityMetric.metrics), // This is needed for metric with template that could refer to values of all other metrics.
      entityCount = getMetric(entityMetric.metrics, BuiltInMetricId.EntityCount),
      entityMaxTimestamp = getMetric(entityMetric.metrics, BuiltInMetricId.EntityMaxTimestamp),
      slaExpected = getMetric(entityMetric.metrics, BuiltInMetricId.SlaExpected),
      slaPending = getMetric(entityMetric.metrics, BuiltInMetricId.SlaPending),
      slaPendingOut = getMetric(entityMetric.metrics, BuiltInMetricId.SlaPendingOut),
      slaCompleted = getMetric(entityMetric.metrics, BuiltInMetricId.SlaCompleted),
      slaCompletedOut = getMetric(entityMetric.metrics, BuiltInMetricId.SlaCompletedOut),
      validationPass = getMetric(entityMetric.metrics, BuiltInMetricId.ValidationPass),
      validationFail = getMetric(entityMetric.metrics, BuiltInMetricId.ValidationFail);

    let result: IEntityMetric = {
      entityType: entityMetric.entityType,
      metrics: [],
    };

    if (entityCount && !entityCount.hidden) {
      result.metrics.push({ ...entityCount, name: "Count" });
    }

    if (slaExpected?.value && !slaExpected.hidden) {
      result.metrics.push({ ...slaExpected, name: "Expected" });
    }

    if (slaPending && slaPendingOut && !slaPending.hidden && !slaPendingOut.hidden) {
      result.metrics.push({
        name: "Pending",
        template:
          // eslint-disable-next-line no-useless-escape
          `$\{this.${BuiltInMetricId.SlaPending}\} &nbsp; <span style='font-weight:300'>(<span style='color:#e50000;font-weight:700'>$\{this.${BuiltInMetricId.SlaPendingOut}\}</span> overdue)</span>`,
        displayType: ContentDisplayType.html,
        ...metricValues,
      });
    }

    if (slaCompleted && slaCompletedOut && !slaCompleted.hidden && !slaCompletedOut.hidden) {
      result.metrics.push({
        name: "Completed",
        template:
          // eslint-disable-next-line no-template-curly-in-string,no-useless-escape
          `$\{this.${BuiltInMetricId.SlaCompleted}\} &nbsp; <span style='font-weight:300'>(<span style='color:#050;font-weight:700'>$\{(parseInt(this.${BuiltInMetricId.SlaCompleted}.replace(/,/g, \"\")) * 100 / parseInt(this.${BuiltInMetricId.SlaExpected}.replace(/,/g, \"\"))).toFixed(2)\}</span>%, <span style='color:#8e6b12;font-weight:700'>$\{this.${BuiltInMetricId.SlaCompletedOut}\}</span> &gt; SLA)</span>`,
        displayType: ContentDisplayType.html,
        ...metricValues,
      });
    }

    if (validationPass && validationFail && !validationPass.hidden && !validationFail.hidden) {
      result.metrics.push({
        name: "Validation",
        template:
          // eslint-disable-next-line no-template-curly-in-string,no-useless-escape
          `<span style='font-weight:300'><span style='color:#050;font-weight:700'>$\{this.${BuiltInMetricId.ValidationPass}\}</span> pass, <span style='color:#e50000;font-weight:700'>$\{this.${BuiltInMetricId.ValidationFail}\}</span> fail</span>`,
        displayType: ContentDisplayType.html,
        ...metricValues,
      });
    }

    // Look for custom metrics that are specific for graph display.
    entityMetric?.metrics?.forEach((metric) => {
      metric.showInGraph &&
        !metric.hidden &&
        result.metrics.push({ ...metric, ...metricValues, value: metric.value || 0 });
    });

    if (entityMaxTimestamp) {
      result.metrics.push({ ...entityMaxTimestamp, name: "Latest On" });
    }

    // Sort metrics descendingly by sortOrder.
    result.metrics = result.metrics.sort((a, b) =>
      (a.sortOrder || 0) < (b.sortOrder || 0) ? 1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? -1 : 0
    );

    return result;
  });

export const getMetric = (metrics: IMetric[], metricId: string): IMetric => {
  return metrics && metrics.find((m) => m.id === metricId);
};

export const getMetricValues = (metrics: IMetric[]) => {
  var result = {};
  metrics?.forEach((metric) => {
    result[metric.id] = metric.value ? getFormatContent(metric.value, metric.displayType) : "0";
  });

  return result;
};

export const getNewSelectionHistoryForAggregateSearch = (state: IAppModuleState) => {
  let startTime = state.search_entity_start_time,
    endTime = state.search_entity_end_time,
    selectionHistory = state.entity_selection_history.slice(),
    selectedIndex = selectionHistory.findIndex(
      (item) => item["startTime"] === startTime && item["endTime"] === endTime
    );

  selectedIndex >= 0 && selectionHistory.splice(selectedIndex, 1);
  selectionHistory.unshift({ startTime: startTime, endTime: endTime });
  return selectionHistory;
};

export const getStartEndDates = (dateType: string, date: Date = new Date()) => {
  let startDate = null,
    endDate = null;

  switch (dateType) {
    case searchDateType.thisMonth:
      startDate = getFirstOfMonth();
      endDate = getLastOfMonth();
      break;
    case searchDateType.lastMonth:
      startDate = getFirstOfMonth(-1);
      endDate = getLastOfMonth(-1);
      break;
    case searchDateType.last7Days:
      startDate = getDateTime(-7);
      endDate = getDateTime(0, true);
      break;
    case searchDateType.customMonth:
      startDate = getFirstOfMonth(0, date);
      endDate = getLastOfMonth(0, date);
      break;
  }

  return { startDate, endDate };
};

export const getAnchorEntityTypes = (entityViews, selectedViewId, entityTypes): string[] => {
  const selectedView = entityViews && entityViews.find((view) => view.id === selectedViewId);

  if (!selectedView?.links?.length || !entityTypes?.length) return null;

  let anchors = [],
    sourceTypes = [],
    targetTypes = [];

  selectedView.links.forEach((link) => {
    link.fromNodeId && sourceTypes.push(link.fromNodeId);
    link.toNodeId && targetTypes.push(link.toNodeId);
  });

  entityTypes.forEach((entityType) => {
    if (sourceTypes.indexOf(entityType) >= 0 && targetTypes.indexOf(entityType) < 0) {
      anchors.push(entityType);
    }
  });

  return anchors;
};

export const getDefaultTileMargin = (tile: ITile) => {
  return {
    left: tile?.valueDisplayType === "shortCurrency" ? 70 : 50,
  };
};

export const getSearchFields = (entityTypes: string[], serviceContentConfigs: any[]): ISearchField[] => {
  if (!entityTypes?.length || !serviceContentConfigs?.length) return null;

  let searchFields = [];

  serviceContentConfigs
    .filter((config) => entityTypes.indexOf(config.name) >= 0)
    .forEach((config) => {
      config.attributeMappings?.forEach((attributeMapping) => {
        attributeMapping.attributes?.forEach((attribute) => {
          if (
            (attribute?.indexed === true || attribute["name"] === "isTest") &&
            searchFields.findIndex((field) => field["name"] === attribute["name"]) < 0
          ) {
            searchFields.push(attribute);
          }
        });
      });
    });

  return searchFields.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
};

export const getDefaultSearchFilters = (
  searchFields: ISearchField[],
  entityViews?: IEntityView[],
  entityViewId?: string
): ISearchFilter[] => processSearchFilters(searchFields, entityViews, entityViewId);

export const processSearchFilters = (
  searchFields: ISearchField[],
  entityViews?: IEntityView[],
  entityViewId?: string
): ISearchFilter[] => {
  let result = [],
    entityViewFilters = entityViews?.find((view) => view.id === entityViewId)?.searchFilters;

  // By default, add isTest = false filter to load non-test records only unless the isTest filter is already explicitly defined by the users.
  if (!entityViewFilters?.find((f) => f.attribute.toLowerCase() === "istest")) {
    result.push({ attribute: "isTest", operator: "=", value: "False", indexed: true });
  }

  // Add entity view filters to the list unless the filter of the same attribute name is already explicitly defined by the users.
  entityViewFilters?.forEach((entityViewFilter: ISearchFilter) => {
    if (
      !result.find((f) => f.attribute.toLowerCase() === entityViewFilter.attribute.toLowerCase()) ||
      entityViewFilter.entityTypes?.length
    ) {
      var filterCopy = JSON.parse(JSON.stringify(entityViewFilter)); // Make deep copy by stringify/parse the values.
      var filterField = getField(searchFields, filterCopy.attribute);
      filterCopy = {
        ...filterCopy,
        dataType: filterField?.dataType,
        indexed: filterField?.indexed,
        icon: getFilterIcon(filterCopy),
      };

      result.push(filterCopy);
    }
  });

  return result;
};

const getFilterIcon = (searchFilter: ISearchFilter) => {
  var typeCount = searchFilter?.entityTypes?.length;

  if (typeCount) {
    var typeList = searchFilter?.entityTypes.join(", ");
    var infoText = `This filter applies only to th${typeCount > 1 ? "ese" : "is"} entity type${
      typeCount > 1 ? "s" : ""
    }: ${typeList}`;
    return { iconName: "InfoSolid", title: infoText, ariaLabel: infoText };
  }

  return undefined;
};

export const getSearchFilter = (searchFilters: ISearchFilter[], index: number): ISearchFilter => {
  if (index >= 0) {
    let selectionIndex = 0;

    for (let i = 0; i < searchFilters?.length; i++) {
      let topFilter = searchFilters[i];

      if (topFilter.filters?.length) {
        for (let j = 0; j < topFilter.filters.length; j++) {
          if (selectionIndex++ === index) {
            return topFilter.filters[j];
          }
        }
      } else {
        if (selectionIndex++ === index) {
          return searchFilters[i];
        }
      }
    }
  }

  return null;
};

export const getSearchViewFields = (entityViews, selectedViewId): IVisualProps[] => {
  const selectedView = entityViews && entityViews.find((view) => view.id === selectedViewId);

  if (!selectedView?.viewFilters?.length) return null;

  return selectedView?.viewFilters;
};

export const getSearchViewFilters = (searchViewFields: IVisualProps[]): ISearchFilter[] => {
  var searchFilters = [];

  if (searchViewFields?.length) {
    searchViewFields.forEach((viewField) => {
      var selectedValues = viewField.visualProps && viewField.visualProps["selectedValue"]?.split(",");
      if (selectedValues?.length) {
        var subFilters = [];
        selectedValues.forEach((selectedValue, index) => {
          subFilters.push(getViewFilter(viewField, selectedValue, index));
        });
        searchFilters.push({ filters: subFilters });
      }
    });
  }

  return searchFilters;
};

export const changeSearchViewFilters = (
  searchViewFilters: ISearchFilter[],
  viewField: IVisualProps,
  value: string
): ISearchFilter[] => {
  var filterFound = false,
    newFilters = [],
    selectedValues = (value && value.split(",")) || [];

  searchViewFilters?.forEach((viewFilter) => {
    if (viewFilter.filters?.length && viewFilter.filters[0].attribute === viewField.filterName) {
      filterFound = true;

      // Create new view filters for the selected field.
      createViewFilters(newFilters, selectedValues, viewField);
    } else {
      // Include other filters not matching the selected filter name.
      newFilters.push(viewFilter);
    }
  });

  if (!filterFound) {
    // Create the new filter if it's not currently selected with any value.
    createViewFilters(newFilters, selectedValues, viewField);
  }

  return newFilters;
};

export const createViewFilters = (newFilters: ISearchFilter[], selectedValues: string[], viewField: IVisualProps) => {
  if (!selectedValues?.length) return;

  var subFilters = [];

  selectedValues.forEach((value, index) => {
    subFilters.push(getViewFilter(viewField, value, index));
  });

  newFilters.push({ filters: subFilters });
};

export const getViewFilter = (viewField: IVisualProps, value, index) => ({
  attribute: viewField.filterName,
  operator: viewField.operator || "=",
  value: value,
  logicalOperator: index === 0 ? "and" : "or",
});

export const getDisplayColumns = (entityType: string, serviceContentConfigs: any[]): string[] => {
  if (!entityType || !serviceContentConfigs) return null;

  var serviceContentConfig = serviceContentConfigs.find(
    (config) => config.name?.toLowerCase() === entityType.toLowerCase()
  );

  if (!serviceContentConfig) return null;

  var displayColumns = [];

  serviceContentConfig.attributeMappings?.forEach((attributeMapping) => {
    attributeMapping.attributes?.forEach((attribute) => {
      if (attribute.displayColumn && displayColumns.indexOf(attribute.name) < 0) {
        displayColumns.push(attribute.name);
      }
    });
  });

  return displayColumns.length ? displayColumns : null;
};

export const getIndexedAttributes = (anchorEntityTypes: string[], serviceContentConfigs: any[]): string[] => {
  var indexedAttributes = [];

  anchorEntityTypes?.forEach((entityType) => {
    var serviceContentConfig = serviceContentConfigs?.find(
      (config) => config.name?.toLowerCase() === entityType.toLowerCase()
    );

    if (serviceContentConfig) {
      serviceContentConfig.attributeMappings?.forEach((attributeMapping) => {
        attributeMapping.attributes?.forEach((attribute) => {
          if (attribute.indexed && indexedAttributes.indexOf(attribute.name) < 0) {
            indexedAttributes.push(attribute.name);
          }
        });
      });
    }
  });

  return indexedAttributes.length ? indexedAttributes : null;
};

export const getField = (searchFields: ISearchField[], attributeName: string) => {
  return searchFields?.find((field) => field.name === attributeName);
};
