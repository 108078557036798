import React from "react";
import { connect } from "react-redux";
import { Diagram, INode, ILink } from "../../shared/components/Diagram";
import { actionCreator } from "../duck";
import { IState } from "../../reducers/interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { errorType } from "../interfaces";
import { IControlView, IEntityList } from "./interfaces";
import classNames from "./HealthDiagram.module.scss";

export interface IHealthDiagramStateProps {
  controlViews: IControlView[];
  loading: boolean;
  error: string;
  selectedNode: INode;
  selectedLink: ILink;
  isNextViewCollapsed: boolean;
  controlEntities: IEntityList;
}

export interface IHealthDiagramDispatchProps {
  updateDiagramNodePosition: (viewId: string, node: INode) => void;
  selectSystemNode: (node: INode) => void;
  selectSystemLink: (link: ILink) => void;
  toggleNextView: () => void;
}

export const HealthDiagram = (props: IHealthDiagramStateProps & IHealthDiagramDispatchProps) => {
  const {
    controlViews,
    loading,
    error,
    selectedNode,
    selectedLink,
    updateDiagramNodePosition,
    selectSystemNode,
    selectSystemLink,
    isNextViewCollapsed,
    controlEntities,
  } = props;

  if (loading) {
    return (
      <Spinner
        styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
        size={SpinnerSize.large}
        label={"Loading control view..."}
      />
    );
  } else if (error) {
    return <pre className={classNames.error}>{error}</pre>;
  } else if (!controlViews || !controlViews.length) {
    return null;
  }

  const selectedIds: string[] = [];

  selectedNode && selectedIds.push(selectedNode.id);
  selectedLink && selectedIds.push(selectedLink.id, selectedLink.fromNodeId, selectedLink.toNodeId);

  if (
    selectedNode &&
    controlEntities &&
    controlEntities[selectedNode.id] &&
    controlEntities[selectedNode.id].relatedEntities
  ) {
    controlEntities[selectedNode.id].relatedEntities.forEach((entity) => {
      selectedIds.push(entity.id);
    });
  }

  return (
    <div className={classNames.root}>
      {controlViews.map((controlView, index) => {
        if (!controlView || controlView.hideDiagram || (isNextViewCollapsed && index > 0)) {
          return null;
        }

        const commandBarProps = controlViews.length > 1 ? getCommandBarProps(index, props) : null;

        return (
          <Tile
            key={"cvt" + controlView.id}
            className={classNames.diagramTile}
            classNames={classNames}
            title={controlView.name}
            commandBarProps={commandBarProps}
            commandBarItemKeys={[BuiltInCommandBarItemKey.showFullScreen, BuiltInCommandBarItemKey.toggleContent]}
            applyContentStyle
          >
            <Diagram
              ariaLabel={controlView.name}
              classNames={classNames}
              diagram={controlView}
              selectedIds={selectedIds}
              onNodeClick={(node) => selectSystemNode(node)}
              onNodeMoved={(node) => updateDiagramNodePosition(controlView.id, node)}
              onLinkClick={(link) => selectSystemLink(link)}
            />
          </Tile>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  controlViews: state.modules.selected_control_views,
  loading: state.modules.loading_control_view,
  error: state.modules.errors[errorType.controlView],
  selectedNode: state.modules.selected_control_node,
  selectedLink: state.modules.selected_control_link,
  isNextViewCollapsed: state.modules.is_next_view_collapsed,
  controlEntities: state.modules.control_entities,
});

const mapDispatchToProps = {
  updateDiagramNodePosition: actionCreator.updateDiagramNodePosition,
  selectSystemNode: actionCreator.selectSystemNode,
  selectSystemLink: actionCreator.selectSystemLink,
  toggleNextView: actionCreator.toggleNextView,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthDiagram);

const getCommandBarProps = (
  index: number,
  props: IHealthDiagramStateProps & IHealthDiagramDispatchProps
): ICommandBarProps => {
  const { isNextViewCollapsed, toggleNextView } = props;

  if (index > 0) return null;

  return {
    items: [
      {
        key: "toggleNextView",
        title: (isNextViewCollapsed ? "Show" : "Hide") + " Next View",
        iconProps: {
          iconName: isNextViewCollapsed ? "ClosePaneMirrored" : "ClosePane",
        },
        iconOnly: true,
        onClick: (ev: any) => toggleNextView(),
      },
    ],
  };
};
