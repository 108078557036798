import { IControlResult, IControlView } from "./interfaces";
import { checkForValue, getStatus } from "../../shared/utilities/miscHelper";
import { getImpactScoreColor, getImpactScore } from "../common/helper";
import { ILink } from "../../shared/components/Diagram/interfaces";
import { IUserInfo } from "../../app/interfaces";
import { filterControlResults } from "./HealthReport.helper";

export const updateControlViews = (
  controlViews: IControlView[],
  controlResults: IControlResult[],
  userInfo: IUserInfo
): IControlView[] => {
  let updatedControlViews: IControlView[] = [];

  controlViews &&
    controlViews.forEach((controlView) => {
      updatedControlViews.push(updateControlView(controlView, controlResults, userInfo));
    });

  return updatedControlViews;
};

const setOfflineState = (controlNodeOffline: object, key: string, reason: string) => {
  if (!controlNodeOffline.hasOwnProperty(key)) {
    controlNodeOffline[key] = { count: 0, reasons: [] };
  }

  // Increment the control count for the node.
  controlNodeOffline[key].count++;

  // Add the reason only if it hasn't been defined yet.
  if (reason && controlNodeOffline[key].reasons.indexOf(reason) < 0) {
    controlNodeOffline[key].reasons.push(reason);
  }
};

const incrementStateCount = (controlNodeState: object, key: string) => {
  if (!controlNodeState.hasOwnProperty(key)) {
    controlNodeState[key] = 0;
  }

  // Increment the count for the node.
  controlNodeState[key]++;
};

export const updateControlView = (
  controlView: IControlView,
  controlResults: IControlResult[],
  userInfo: IUserInfo
): IControlView => {
  let links = (controlView && controlView.links) || [],
    nodes = (controlView && controlView.nodes) || [],
    controlLinks: any = {}, // Keep track of unique control links.
    controlNodes: any = {}, // Keep track of unique control nodes.
    controlNodeStatus: any = {}, // Keep track of maximum impact score for each control node.
    controlNodeOffline: any = {}, // Keep track of offline state for each control node.
    controlNodeOverdue: any = {}, // Keep track of overdue state for each control node.
    controlNodeCount: any = {}; // Keep track of control count for each control node.

  const newLinks: ILink[] = [];

  if (controlView && controlView.nodes && controlView.nodes.length) {
    controlView.nodes.forEach((node) => {
      controlNodes[node.id] = true;
    });
  }

  if (controlResults && controlResults.length) {
    const filteredControlResults = filterControlResults(controlResults, userInfo);

    for (var i = 0; i < filteredControlResults.length; i++) {
      let controlResult = filteredControlResults[i],
        sourceId = controlResult.sourceId,
        targetId = controlResult.targetId,
        status = controlResult.status !== undefined ? !!getStatus(controlResult.status) : undefined,
        offline = controlResult.offline,
        isOverdue = controlResult.isOverdue;

      sourceId && incrementStateCount(controlNodeCount, sourceId);
      targetId && incrementStateCount(controlNodeCount, targetId);

      if (offline) {
        sourceId && setOfflineState(controlNodeOffline, sourceId, controlResult.offlineReason);
        targetId && setOfflineState(controlNodeOffline, targetId, controlResult.offlineReason);
      } else if (isOverdue) {
        sourceId && incrementStateCount(controlNodeOverdue, sourceId);
        targetId && incrementStateCount(controlNodeOverdue, targetId);
      }

      let impactScore = getImpactScore(controlResult);

      if (controlNodes[sourceId] && controlNodes[targetId] && sourceId !== targetId) {
        let linkKey = sourceId + "_" + targetId;

        if (!controlLinks[linkKey]) {
          controlLinks[linkKey] = {
            fromNodeId: sourceId,
            toNodeId: targetId,
            impactScore,
            status,
          };
        } else if (status === true || status === false) {
          controlLinks[linkKey].impactScore = Math.max(controlLinks[linkKey].impactScore, impactScore);
          controlLinks[linkKey].status = status && controlLinks[linkKey].status;
        }
      }

      // If control is not in offline state, find the max impact score for the same control node.

      if (controlNodes[sourceId] && !offline) {
        if (controlNodeStatus[sourceId] === undefined) {
          controlNodeStatus[sourceId] = impactScore;
        } else {
          controlNodeStatus[sourceId] = Math.max(controlNodeStatus[sourceId], impactScore);
        }
      }

      if (controlNodes[targetId] && !offline) {
        if (controlNodeStatus[targetId] === undefined) {
          controlNodeStatus[targetId] = impactScore;
        } else {
          controlNodeStatus[targetId] = Math.max(controlNodeStatus[targetId], impactScore);
        }
      }

      if (controlResult.entityIds && controlResult.entityIds.length) {
        let entityIds =
          typeof controlResult.entityIds == "string" ? controlResult.entityIds.split(",") : controlResult.entityIds;

        entityIds.forEach((entityId) => {
          if (controlNodes[entityId] && !offline) {
            if (controlNodeStatus[entityId] === undefined) {
              controlNodeStatus[entityId] = impactScore;
            } else {
              controlNodeStatus[entityId] = Math.max(controlNodeStatus[entityId], impactScore);
            }
          }

          incrementStateCount(controlNodeCount, entityId);

          if (offline) {
            setOfflineState(controlNodeOffline, entityId, controlResult.offlineReason);
          } else if (isOverdue) {
            incrementStateCount(controlNodeOverdue, entityId);
          }
        });
      }
    }
  }

  Object.keys(controlLinks).forEach((linkKey) => {
    let impactScore = controlLinks[linkKey].impactScore,
      status = controlLinks[linkKey].status,
      icon: any =
        status === true
          ? { iconName: "SkypeCircleCheck" }
          : status === false
          ? { iconName: "SkypeCircleMinus" }
          : undefined;

    if (icon) {
      icon.style = { color: getImpactScoreColor(impactScore) };
    }

    newLinks.push({
      ...controlLinks[linkKey],
      icon,
      disabled: !checkForValue(status),
    });
  });

  links.forEach((link) => {
    let id = `${link.fromNodeId}_${link.toNodeId}`;
    if (!(controlLinks && controlLinks[id])) {
      newLinks.push({
        ...link,
        disabled: true,
      });
    }
  });

  nodes = nodes.map((node) => {
    let impactScore = controlNodeStatus[node.id],
      offline = controlNodeOffline[node.id],
      isOverdue = controlNodeOverdue[node.id],
      controlCount = controlNodeCount[node.id],
      allOffline = offline?.count === controlCount,
      warning = offline || isOverdue,
      icon = undefined,
      tooltip = undefined;
    const hasImpactScore = checkForValue(impactScore);

    // If any controls of a node is in warning state, show the warning UI.
    if (warning) {
      icon = { iconName: "WarningSolid", style: { color: "goldenrod" } };
      tooltip = "This item has ";

      if (offline) {
        tooltip += `${offline.count} control${offline.count > 1 ? "s" : ""} in offline state`;

        if (isOverdue) {
          tooltip += " and ";
        }
      }

      if (isOverdue) {
        tooltip += `${isOverdue} control${isOverdue > 1 ? "s" : ""} in overdue state`;
      }

      tooltip += ".\nClick this item to see all related controls below.";
    }

    return {
      ...node,
      disabled: !hasImpactScore && !warning,
      style: {
        backgroundColor: hasImpactScore && !allOffline ? getImpactScoreColor(impactScore) : "#707070",
      },
      icon,
      tooltip,
    };
  });

  return {
    ...controlView,
    links: newLinks,
    nodes,
  };
};
