import React from "react";
import { IDataContext, IField } from "./interfaces";
import { getCollapsiblePaneStyle } from "./DataForm.helper";
import { Icon } from "@fluentui/react/lib/Icon";
import DataFormContent from "./DataFormContent";
import classNames from "./DataForm.module.scss";

export interface IFieldContainerProps {
  id: string;
  label: string;
  field: IField;
  fields: IField[];
  collapsible?: boolean;
  collapsed?: boolean;
  compact?: boolean;
  context?: object;
  /** This is the same as context, but helps maintain compatibilty of using the parentContext object */
  parentDataContext?: IDataContext;
  style?: React.CSSProperties;
  onFieldValueChange: (fieldName: string, newValue: any) => void;
}

export interface IFieldContainerState {
  collapsed: boolean;
}

export class FieldContainer extends React.Component<IFieldContainerProps, IFieldContainerState> {
  state = {
    collapsed: this.props.collapsed !== false,
  };

  render() {
    const { id, label, fields, collapsible, compact, context, style, onFieldValueChange, parentDataContext, field } =
      this.props;
    const { collapsed } = this.state;

    var dataContext: IDataContext = {
      context,
      parentDataContext,
      field,
      onFieldValueChange,
    };

    const rootClassNames = [classNames.containerRoot];

    compact && rootClassNames.push(classNames.compact);

    const fieldsStyle = getCollapsiblePaneStyle(collapsed, "flex");

    return (
      <div className={rootClassNames.join(" ")} style={style}>
        <div className={classNames.containerLabel}>
          {label}
          {collapsible && (
            <Icon
              iconName={collapsed ? "ChevronDown" : "ChevronUp"}
              className={classNames.collapsedIcon}
              title="Toggle Content"
              onClick={() => this.setState({ collapsed: !collapsed })}
            />
          )}
        </div>
        {collapsed ? null : (
          <div className={`${classNames.fields} ${classNames.content}`} style={fieldsStyle}>
            <DataFormContent
              id={id}
              field={field}
              fields={fields}
              context={context}
              onFieldValueChange={onFieldValueChange}
              parentDataContext={dataContext}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FieldContainer;
