import { ResponseDataType, fetchDataWithAuth } from "./apiBase";

const msGraphBaseUrl = "https://graph.microsoft.com/beta/";
const msGraphScope = ["user.read"];

export const apiEndpoints = {
  msGraphMyProfile: msGraphBaseUrl + "me",
  msGraphMyPhoto: msGraphBaseUrl + "me/photo/$value",
};

export const fetchMsGraphData = (endpoint: string, dataType: ResponseDataType = ResponseDataType.json): Promise<any> =>
  fetchDataWithAuth(endpoint, msGraphScope, "Fail to load MS Graph data.", dataType);

export const getMsGraphMyPhoto = (): Promise<string> => {
  return fetchMsGraphData(apiEndpoints.msGraphMyPhoto, ResponseDataType.blob).then((blob) => URL.createObjectURL(blob));
};

export const getMsGraphMyProfile = (): Promise<any> => {
  return fetchMsGraphData(apiEndpoints.msGraphMyProfile);
};
