import {
  ICommandBarProps,
  Checkbox,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
} from "@fluentui/react";
import { useContext, useState } from "react";
import * as radarApi from "../../api/radarApi";
import { DataForm } from "../../shared/components/DataForm";
import { pascalCase } from "../../shared/utilities/miscHelper";
import AppModuleHeader from "../common/AppModuleHeader";
import ControlItemList from "../controls/ControlItemList";
import { IControlReport } from "../controls/interfaces";
import classNames from "./ReprocessFinancialEntities.module.scss";
import { AppContext } from "../../app/App";

const ReprocessFinancialEntities: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { featureFlags } = appState;
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReprocessLoading, setIsReprocessLoading] = useState<boolean>(false);
  const [hasCurrentQueryResults, setHasCurrentQueryResults] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [reprocessResults, setReprocessResults] = useState<IReprocessResults>(null);
  const defaultResults: IControlReport = {
    columns: [],
    items: [],
    isSortedDescending: false,
    sortedColumnKey: "",
    searchText: "",
    announcedMessage: "",
    totalCount: 0,
  };
  const [results, setResults] = useState<IControlReport>(defaultResults);

  const knownColumnFixedMaxWidths = {
    id: 375,
    type: 175,
    version: 75,
    storageUri: 950,
    processedDateTime: 150,
  };

  interface IReprocessResults {
    status: MessageBarType;
    message: string;
  }

  const showQueryFormatWarning =
    results &&
    results.items &&
    results.items.length > 0 &&
    (!results.items[0].id ||
      !results.items[0].type ||
      !results.items[0].version ||
      !results.items[0].storageUri ||
      !results.items[0].processedDateTime);

  interface IReprocessFlags {
    isBillingGroupGoDaddy: boolean;
  }

  const [reprocessFlags, setReprocessFlags] = useState<IReprocessFlags>({
    isBillingGroupGoDaddy: false,
  });

  const getCommandBarProps = (): ICommandBarProps => {
    return {
      items: [
        {
          key: "reprocessItems",
          text: "Reprocess Items",
          iconProps: { iconName: "RepeatAll" },
          onClick: runReprocessing,
          disabled: !hasCurrentQueryResults || showQueryFormatWarning,
          title: "Run a query and click here to reprocess those items",
        },
      ],
    };
  };

  const onQueryChange = () => {
    setHasCurrentQueryResults(false);
  };

  const onRunQuery = () => {
    setIsLoading(true);
    setResults(defaultResults);
    setHasCurrentQueryResults(false);
    setError("");
    setReprocessResults(null);
  };

  const runQuery = () => {
    onRunQuery();

    radarApi
      .queryReprocessFinancialEntities(query)
      .then((results: any) => {
        results = results.map((item) => {
          const newItem = {};
          for (const key in item) {
            if (typeof item[key] === "object") {
              newItem[key] = JSON.stringify(item[key]);
            } else {
              newItem[key] = item[key];
            }
          }
          return newItem;
        });

        setResults((prevState) => ({
          ...prevState,
          columns:
            results.length > 0
              ? Object.keys(results[0]).map((key) => ({
                  key: key,
                  name: pascalCase(key),
                  fieldName: key,
                  minWidth: 100,
                  maxWidth: knownColumnFixedMaxWidths[key] ?? 200,
                  isResizable: true,
                }))
              : [],
          items: results,
          totalCount: results.length,
        }));
        results.length && setHasCurrentQueryResults(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const runReprocessing = () => {
    setReprocessResults(null);
    setIsReprocessLoading(true);

    const selectedReprocessFlags = Object.entries(reprocessFlags).reduce((flags, [key, value]) => {
      if (value === true) {
        flags[key] = true;
      }
      return flags;
    }, {});

    radarApi
      .reprocessFinancialEntities(results.items, selectedReprocessFlags)
      .then((results: any) => {
        console.log(results);
        setReprocessResults({
          status: MessageBarType.success,
          message: "Entities submitted for reprocessing.",
        });
      })
      .catch((error) => {
        setReprocessResults({
          status: MessageBarType.error,
          message: error,
        });
      })
      .finally(() => {
        setIsReprocessLoading(false);
      });
  };

  return featureFlags?.disableReprocessFinancialEntities ? (
    <div className="appModuleContent">
      <h1>Reprocess Financial Entities</h1>
      <p>Reprocessing financial entities is currently disabled.</p>
    </div>
  ) : (
    <div className="appModuleContent">
      <AppModuleHeader headerTextOverride="Reprocess Financial Entities" commandBarProps={getCommandBarProps()} />
      <div className={classNames.reprocessFinancialEntities}>
        <p>
          Admins can use this page to reprocess financial entities. You can specify a query to return the list of
          financial entities to reprocess.
        </p>
        <p>
          The results should contain the following fields: <b>Id, Type, Version, StorageUri, ProcessedDateTime</b>. Once
          you've verified the results you can submit the query for reprocessing.
        </p>
        <DataForm
          fields={[
            {
              fieldName: "financialEntitiesQuery",
              label: "Financial Entities Query",
              className: classNames.reprocessFinancialEntitiesQuery,
              width: "100%",
              value: query,
              allowFullEditor: true,
              hints:
                "Specific the query on returning the list of financial entities to reprocess " +
                "containing the following fields: id, type, version, storageUri, processedDateTime.",
            },
          ]}
          onFieldValueChange={(fieldName, newValue) => {
            if (fieldName === "financialEntitiesQuery") {
              setQuery(newValue);
              onQueryChange();
            }
          }}
        />

        <div className={classNames.flags}>
          Optional flags to send for reprocessing:
          <Checkbox
            label="isBillingGroupGoDaddy"
            checked={reprocessFlags.isBillingGroupGoDaddy}
            onChange={(ev, checked) =>
              setReprocessFlags((prevFlags) => ({ ...prevFlags, isBillingGroupGoDaddy: !!checked }))
            }
          />
        </div>

        <PrimaryButton text="Run Query" className={classNames.runQueryButton} onClick={runQuery} disabled={!query} />

        {showQueryFormatWarning && (
          <MessageBar messageBarType={MessageBarType.warning}>
            The results do not contain the required fields: id, type, version, storageUri, processedDateTime.
          </MessageBar>
        )}
        {reprocessResults && (
          <MessageBar messageBarType={reprocessResults.status}>{reprocessResults.message}</MessageBar>
        )}
        {isReprocessLoading && <ProgressIndicator />}
        <ControlItemList
          className={classNames.reprocessFinancialEntitiesResults}
          controlReport={results}
          title="Financial entities to reprocess"
          loading={isLoading}
          error={error}
          noItemText="<No results / start a query>"
        />
      </div>
    </div>
  );
};

export default ReprocessFinancialEntities;
