import React, { Component } from "react";
import { IControlResult, IControl } from "../interfaces";
import {
  getFormatContent,
  ContentDisplayType,
  getStatus,
  getItemField,
  getShortNumber,
} from "../../../shared/utilities/miscHelper";
import OutlierChartTile from "./OutlierChartTile";
import classNames from "./OutlierControlSummary.module.scss";

export interface IOutlierControlSummaryProps {
  items: IControlResult[];
  control: IControl;
  onValueSelect?: (v) => void;
}

export interface IOutlierControlSummaryState {
  selectedValue: any;
}

export class OutlierControlSummary extends Component<IOutlierControlSummaryProps, IOutlierControlSummaryState> {
  constructor(props: IOutlierControlSummaryProps) {
    super(props);

    this.state = {
      selectedValue: null,
    };
  }

  render() {
    const { items, control } = this.props;
    const {
      dateFieldName,
      valueFieldName,
      lowerBoundFieldName,
      upperBoundFieldName,
      dateFieldDisplayType = "date",
      chartValueInversion,
    } = control;

    if (!items || !items.length) {
      return null;
    }

    const { selectedValue } = this.state;

    const sortedItems = items
      .slice()
      .sort((a, b) => new Date(a[dateFieldName]).valueOf() - new Date(b[dateFieldName]).valueOf());

    const firstItem = sortedItems[0],
      firstDate = getFormatContent(firstItem, dateFieldDisplayType, dateFieldName);

    const valueData = sortedItems
        .map((item) => ({
          x: getItemField(item, dateFieldName),
          y: chartValueInversion
            ? -1 * Number(getItemField(item, valueFieldName))
            : Number(getItemField(item, valueFieldName)),
          color: getStatus(item.status),
          category: getFormatContent(item, dateFieldDisplayType, dateFieldName),
          value: getFormatContent(item, ContentDisplayType.number, valueFieldName),
          lowerBound: getFormatContent(item, ContentDisplayType.number, lowerBoundFieldName),
          upperBound: getFormatContent(item, ContentDisplayType.number, upperBoundFieldName),
        }))
        .filter((item) => item.x !== undefined && item.y !== undefined),
      lowerBoundData = sortedItems
        .map((item) => ({
          x: getItemField(item, dateFieldName),
          y: chartValueInversion
            ? -1 * Number(getItemField(item, lowerBoundFieldName))
            : Number(getItemField(item, lowerBoundFieldName)),
        }))
        .filter((item) => item.x !== undefined && item.y !== undefined),
      upperBoundData = sortedItems
        .map((item) => ({
          x: getItemField(item, dateFieldName),
          y: chartValueInversion
            ? -1 * Number(getItemField(item, upperBoundFieldName))
            : Number(getItemField(item, upperBoundFieldName)),
        }))
        .filter((item) => item.x !== undefined && item.y !== undefined);

    return (
      <div className={classNames.root}>
        <OutlierChartTile
          className={classNames.outlierTile}
          control={control}
          firstDate={firstDate}
          valueData={valueData}
          lowerBoundData={lowerBoundData}
          upperBoundData={upperBoundData}
          tickLabelAngle={-90}
          selectedValue={selectedValue}
          onXAxisTickFormat={(v) =>
            new Date(v).toLocaleString("en-us", { month: "short" }) +
            " " +
            (dateFieldDisplayType === "date" ? new Date(v).getDate() : "")
          }
          onYAxisTickFormat={(v) => getShortNumber(chartValueInversion ? -1 * v : v)}
          onValueSelect={this.onValueSelect}
        />
      </div>
    );
  }

  onValueSelect = (selectedValue) => {
    const { onValueSelect } = this.props;

    this.setState({ selectedValue });

    onValueSelect && onValueSelect(selectedValue);
  };
}

export default OutlierControlSummary;
