import React from "react";
import { Tile, BuiltInCommandBarItemKey } from "../../../shared/components/Tile";
import "../../../../node_modules/react-vis/dist/style.css";
import { XYPlot, XAxis, YAxis, LineSeries, VerticalBarSeries, Hint } from "react-vis";
import { IControl } from "../interfaces";
import classNames from "./OutlierChartTile.module.scss";

export interface IOutlierChartTileProps {
  control: IControl;
  firstDate?: string;
  valueData?: any[];
  className?: string;
  lowerBoundData?: any[];
  upperBoundData?: any[];
  tickLabelAngle?: number;
  selectedValue?: any;
  onXAxisTickFormat?: (v) => string;
  onYAxisTickFormat?: (v) => string;
  onValueSelect?: (v) => void;
}

export interface IOutlierChartTileState {
  hoveredValue: any;
}

export class OutlierChartTile extends React.Component<IOutlierChartTileProps, IOutlierChartTileState> {
  constructor(props: IOutlierChartTileProps) {
    super(props);

    this.state = {
      hoveredValue: false,
    };
  }

  render() {
    const {
      control,
      firstDate,
      valueData,
      lowerBoundData,
      upperBoundData,
      onXAxisTickFormat,
      onYAxisTickFormat,
      tickLabelAngle,
      className,
      selectedValue,
    } = this.props;

    const { hoveredValue } = this.state;

    const {
      outlierChartTitle,
      passStatusColor = "green",
      failStatusColor = "red",
      tiles,
      dateFieldDisplayType,
      valueSeriesDisplayType = dateFieldDisplayType === "month" ? "column" : "line",
    } = control;

    if (!valueData || !valueData.length) return null;

    const colors = [failStatusColor, passStatusColor, "grey"],
      hasTiles = tiles && tiles.length;

    const newValueData = valueData.map((value) => {
      if (selectedValue && value.x === selectedValue) {
        return {
          ...value,
          color: 2,
          className: classNames.selectedValue,
        };
      } else {
        return value;
      }
    });

    const valueSeriesProps = {
      data: newValueData,
      colorType: "category",
      colorDomain: [0, 1, 2],
      colorRange: colors,
      onNearestX: (d) => this.setState({ hoveredValue: d }),
      onValueClick: (v) => hasTiles && this.onValueSelect(v),
      className: hasTiles ? classNames.valueSeriesSelectable : "",
    };

    return (
      <Tile
        title={outlierChartTitle || `Outlier History Since ${firstDate}`}
        applyContentStyle
        className={className}
        commandBarItemKeys={[BuiltInCommandBarItemKey.showFullScreen]}
        headerLevel={3}
      >
        <XYPlot
          xType="ordinal"
          height={243}
          width={12 * valueData.length + 100}
          margin={{ left: 50, bottom: 50 }}
          onMouseLeave={() => this.setState({ hoveredValue: false })}
        >
          <XAxis tickLabelAngle={tickLabelAngle} tickFormat={onXAxisTickFormat} />
          <YAxis width={50} tickFormat={onYAxisTickFormat} />
          {valueSeriesDisplayType === "column" && <VerticalBarSeries {...valueSeriesProps} />}
          {valueSeriesDisplayType === "line" && <LineSeries {...valueSeriesProps} />}
          {lowerBoundData && !!lowerBoundData.length && <LineSeries data={lowerBoundData} />}
          {upperBoundData && !!upperBoundData.length && <LineSeries data={upperBoundData} />}
          {hoveredValue && (
            <Hint value={hoveredValue}>
              <div className={classNames.hint}>
                <div className={classNames.valuePane}>
                  <span className={classNames.category}>{hoveredValue.category}:</span>
                  <span className={classNames.value} style={{ backgroundColor: colors[hoveredValue.color] }}>
                    {hoveredValue.value}
                  </span>
                </div>
                <div className={classNames.upperBoundPane}>
                  <span className={classNames.category}>Upper bound:</span>
                  <span className={classNames.value}>{hoveredValue.upperBound}</span>
                </div>
                <div className={classNames.lowerBoundPane}>
                  <span className={classNames.category}>Lower bound:</span>
                  <span className={classNames.value}>{hoveredValue.lowerBound}</span>
                </div>
              </div>
            </Hint>
          )}
        </XYPlot>
      </Tile>
    );
  }

  onValueSelect = (v) => {
    const { onValueSelect, selectedValue } = this.props;

    let newValue = v;

    if (selectedValue && newValue.x === selectedValue) {
      newValue = null;
    }

    onValueSelect && onValueSelect(newValue && newValue.x);
  };
}

export default OutlierChartTile;
