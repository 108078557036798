import React from "react";
import { IEntity } from "../common/interfaces";
import classNames from "./EntityDetail.module.scss";
import { startCase, ContentDisplayType, getFormatContent } from "../../shared/utilities/miscHelper";
import { Link } from "@fluentui/react/lib/Link";

export interface IEntityDetailSelectedItemProps {
  entity: IEntity;
}

export const EntityDetailSelectedItem = ({ entity }: IEntityDetailSelectedItemProps) => {
  let attributes;

  try {
    attributes = entity && JSON.parse(entity["attributes"]);
  } catch {}

  return (
    <div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Entity Type</div>
        <div className={classNames.fieldValue}>{entity.entityType}</div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Entity ID</div>
        <div className={classNames.fieldValue} title={entity.id}>
          {entity.id}
        </div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Entity Version</div>
        <div className={classNames.fieldValue}>{entity.version}</div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Schema Version</div>
        <div className={classNames.fieldValue}>{entity.schemaVersion}</div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Timestamp</div>
        <div className={classNames.fieldValue}>{getFormatContent(entity.timeStamp, ContentDisplayType.datetime)}</div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Processed Datetime</div>
        <div className={classNames.fieldValue}>
          {getFormatContent(entity.processedDateTime, ContentDisplayType.datetime)}
        </div>
      </div>
      <div className={classNames.field}>
        <div className={classNames.fieldLabel}>Storage URI</div>
        <div className={classNames.fieldValue}>
          <Link href={entity.storageUri}>link</Link>
        </div>
      </div>
      {Object.keys(entity)
        .filter(
          (propName) =>
            propName !== "entityType" &&
            propName !== "type" &&
            propName !== "id" &&
            propName !== "version" &&
            propName !== "timeStamp" &&
            propName !== "schemaVersion" &&
            propName !== "attributes" &&
            propName !== "processedDateTime" &&
            propName !== "processedDataIdentifier" &&
            propName !== "storageUri"
        )
        .map((propName) => renderAttributes({ [propName]: entity[propName] }))}
      {attributes && renderAttributes(attributes)}
    </div>
  );
};

const renderAttributes = (attributes) => {
  let attributeKeys = Object.keys(attributes);

  return attributeKeys?.map((propName) => {
    let propValue = attributes[propName];

    propName = startCase(propName);

    if (typeof propValue === "object") {
      propValue = JSON.stringify(propValue);
    }

    return (
      <div key={"entity-detail-" + propName} className={classNames.field}>
        <div className={classNames.fieldLabel}>{propName}</div>
        <div className={classNames.fieldValue} title={propValue?.toString()}>
          {propValue?.toString()}
        </div>
      </div>
    );
  });
};
