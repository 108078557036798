import React from "react";
import { connect } from "react-redux";
import { IReport, ITile, IFilterItem } from "./interfaces";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../../appModules/duck";
import { Spinner } from "@fluentui/react/lib/Spinner";
import VisualTileList from "../../shared/components/Tile/VisualTileList";
import { VisualType } from "../../shared/components/Visual";
import classNames from "./Report.module.scss";

export interface IReportProps {
  reportId: string;
}

export interface IReportStateProps {
  report: IReport;
  loading: boolean;
  error: string;
  filters: IFilterItem[];
}

export interface IReportDispatchProps {
  loadReport: (reportId: string) => void;
  selectReport: (reportId: string) => void;
  reloadReportWithFilter: (
    reportId: string,
    filters: IFilterItem[],
    doNotLoadReport: boolean,
    refreshData?: boolean
  ) => void;
  loadReportTile: (reportId: string, tileIndex: number, value: string, refreshData?: boolean) => void;
  loadReportTileColumn: (
    reportId: string,
    tileIndex: number,
    name: string,
    value: string,
    filters: IFilterItem[],
    refreshData?: boolean
  ) => void;
  submitReportTileData: (
    reportId: string,
    tileIndex: number,
    name: string,
    value: string,
    filters: IFilterItem[]
  ) => void;
}

export class Report extends React.Component<IReportProps & IReportStateProps & IReportDispatchProps> {
  componentDidMount() {
    const { reportId, report, loadReport, selectReport } = this.props;

    if (!report) {
      loadReport(reportId);
    } else {
      selectReport(reportId);
    }
  }

  render() {
    const { reportId, report, loading, error, filters } = this.props;

    if (loading) {
      return (
        <Spinner
          styles={{
            root: classNames.spinner,
            circle: classNames.spinnerCircle,
            label: classNames.spinnerLabel,
          }}
          label="Loading report data..."
          role="alert"
        />
      );
    }

    if (error) {
      return <pre className={classNames.error}>{error}</pre>;
    }

    if (!reportId || !report || !report.tiles || !report.tiles.length) {
      return <div>No report is found.</div>;
    }

    report.tiles.forEach((tile: ITile, index: number) => {
      tile.onValueInit = this.onValueInit;
      tile.onValueSelect = this.onValueSelect;
      tile.onValuesSelect = this.onValuesSelect;
      tile.onValueReload = (value) => this.onValueReload(index, value);
      tile.onValueSubmit = (name, value) => this.onValueSubmit(index, name, value);
      tile.onColumnValueSelect = (name, value) => this.onColumnValueSelect(index, name, value);

      let selectedValueName = tile.filterName || tile.categoryFieldName || tile.visualType,
        selectedFilter = filters && filters.find((filter) => filter.name === selectedValueName);

      if (selectedFilter) {
        tile.selectedValue = selectedFilter.value;
      }

      tile.filters = filters;
    });

    return (
      <div className={classNames.root}>
        {report.description && (
          <div className={classNames.reportDescription} dangerouslySetInnerHTML={{ __html: report.description }} />
        )}
        <VisualTileList tiles={report.tiles} useSubmitButton={report.useSubmitButton} />
      </div>
    );
  }

  onValueSelect = (name: string, value: any) => {
    this.onValuesSelect([{ name, value }]);
  };

  onValuesSelect = (newFilters: IFilterItem[]) => {
    const { reportId, report, filters, reloadReportWithFilter } = this.props;

    let firstFilter = newFilters && newFilters.length ? newFilters[0] : {},
      { name, value } = firstFilter,
      isActionButton = name === VisualType.actionButton && value === VisualType.actionButton,
      isSubmitButton = name === VisualType.submit,
      doNotLoadReport = report && report.useSubmitButton && !isSubmitButton && !isActionButton,
      areNewFiltersExist = this.areNewFiltersAlreadyExist(newFilters, filters);

    report?.tiles?.forEach((tile) => {
      let selectedValueName = tile.filterName || tile.categoryFieldName || tile.visualType,
        nameSections = name?.split(".");

      if (
        tile.ignoreSubmitButton &&
        !areNewFiltersExist &&
        (name === selectedValueName || nameSections[0] === selectedValueName)
      ) {
        doNotLoadReport = false;
      }

      // Clear selection(s) on submit by adding the selection(s) to the filters list (where updateFilters in thunk will remove the related filters)
      if ((isSubmitButton || isActionButton) && tile.clearSelectionOnSubmit) {
        newFilters.push({ name: selectedValueName, value: undefined });

        if (tile.columnsAsSelectedValues?.length) {
          tile.columnsAsSelectedValues.forEach((selectedColumnValueName) => {
            newFilters.push({ name: `${selectedValueName}.${selectedColumnValueName}`, value: undefined });
          });
        }
      }
    });

    reloadReportWithFilter(reportId, newFilters, doNotLoadReport, isActionButton);
  };

  onValueInit = (name: string, value: any) => {
    const { reportId, reloadReportWithFilter } = this.props;
    const doNotLoadReport = true,
      filters = [{ name, value }];

    reloadReportWithFilter(reportId, filters, doNotLoadReport);
  };

  onValueReload = (tileIndex: number, value: any) => {
    const { reportId, loadReportTile } = this.props;

    loadReportTile(reportId, tileIndex, value, true);
  };

  onValueSubmit = (tileIndex: number, name: string, value: any) => {
    const { reportId, filters, submitReportTileData } = this.props;

    submitReportTileData(reportId, tileIndex, name, value, filters);
  };

  onColumnValueSelect = (tileIndex: number, name: string, value: any) => {
    const { reportId, filters, loadReportTileColumn } = this.props;

    loadReportTileColumn(reportId, tileIndex, name, value, filters, true);
  };

  areNewFiltersAlreadyExist = (newFilters: IFilterItem[], filters: IFilterItem[]): boolean => {
    let result = true;

    newFilters?.forEach((newFilter) => {
      let filter = filters?.find((filter) => filter.name === newFilter.name && filter.value === newFilter.value);

      if (!filter) {
        result = false;
      }
    });

    return result;
  };
}

const mapStateToProps = (state: IState, props: IReportProps): IReportStateProps => ({
  report: state.modules.reports.hasOwnProperty(props.reportId) && state.modules.reports[props.reportId],
  loading: state.modules.report_loading,
  error: state.modules.report_error,
  filters: state.modules.selected_report_filters,
});

const mapDispatchToProps: IReportDispatchProps = {
  loadReport: actionCreator.loadReport,
  selectReport: actionCreator.selectReport,
  reloadReportWithFilter: actionCreator.reloadReportWithFilter,
  loadReportTile: actionCreator.loadReportTile,
  loadReportTileColumn: actionCreator.loadReportTileColumn,
  submitReportTileData: actionCreator.submitReportTileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
