import { checkForValue, compareDates, parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { IAttribute } from "../../common/serviceContentInterfaces";

export type configValueStatus = "Failed" | "Passed" | "Warning" | "Info";

export type AttributeWithStatus = IAttribute & {
  status: configValueStatus;
  value: string;
  tooltip?: string;
};

export function getConfigAttributeValues(
  document: any,
  configItem: any,
  index: string,
  loadConfigAttributeValuesFromDoc
) {
  const schemaVersion = "";
  try {
    loadConfigAttributeValuesFromDoc(configItem, document, schemaVersion, index);

    return {
      configItem,
      document,
      schemaVersion,
      index,
    };
  } finally {
    return {
      configItem: {},
      document,
      schemaVersion,
      index,
    };
  }
}

export function getStatusForAttributes(
  configAttributeValues,
  loadedId: string,
  attributes: IAttribute[],
  journalRecords?
) {
  const UsingCachedValue = "Value could not be computed so using value from previous run to display";
  const NoCachedValue = "Value could not be computed and no value from previous run to display";
  const configValueAttribute = configAttributeValues[loadedId];
  const items: AttributeWithStatus[] = [];
  if (configValueAttribute) {
    for (let index = 0; index < attributes.length; index++) {
      const attribute = attributes[index];
      const sqlValues = journalRecords?.sqlValues;
      let value: string = configValueAttribute[attribute.name];
      const sqlValueAttributes = parseJsonSafe(sqlValues?.attributes);
      const sqlValue: string =
        sqlValueAttributes &&
        (checkForValue(sqlValueAttributes[attribute.name])
          ? sqlValueAttributes[attribute.name]
          : sqlValues[attribute.name]);
      let path: string = attribute.path;
      let status: configValueStatus = "Passed";
      let tooltip: string = null;
      if (value === "undefined") {
        value = "";
      }
      if (path && path.indexOf("@") > 0) {
        if (checkForValue(sqlValue)) {
          value = sqlValue;
          status = "Info";
          tooltip = UsingCachedValue;
        } else {
          value = "Warning: Unable to test value due to it requiring parameters";
          tooltip = NoCachedValue;
          status = "Warning";
        }
      } else if (typeof value === "string" && value.startsWith("~ERROR~")) {
        value = value.slice(7);
        status = attribute.required ? "Failed" : "Warning";
      }
      if (!checkForValue(value)) {
        if (checkForValue(sqlValue)) {
          value = sqlValue;
          status = "Info";
          tooltip = UsingCachedValue;
        } else {
          value = "{missing}";
          tooltip = NoCachedValue;
          status = attribute.required ? "Failed" : "Warning";
        }
      }
      if (checkForValue(sqlValue) && status === "Passed") {
        if (`${sqlValue}`.toLowerCase() !== `${value}`.toLowerCase()) {
          let passing = false;
          try {
            const gmtOffset = 8;
            if (
              (!isNaN(parseFloat(sqlValue)) && parseFloat(sqlValue) === parseFloat(value)) ||
              compareDates(new Date(sqlValue), value, 0, gmtOffset) === 0
            ) {
              passing = true;
            }
          } catch {}
          if (!passing) {
            status = "Info";
            tooltip = `The value from the previous run was '${sqlValue}' and the value from this run is '${value}'`;
          }
        }
      }
      items.push({
        ...attribute,
        value,
        status,
        tooltip,
      });
    }
  }
  return items;
}
