import React from "react";
import DateRangeCalloutContent, { IDateRangeCalloutContentProps } from "./DateRangeCalloutContent";
import { ICalloutButtonProps, IconCalloutButton } from "../CalloutButton/IconCalloutButton";
import moment from "moment";

export interface IDateRangePickerProps extends ICalloutButtonProps, IDateRangeCalloutContentProps {
  showSelectedDateRange?: boolean;
}

export const DateRangePicker = (props: React.PropsWithChildren<IDateRangePickerProps>) => {
  const { showSelectedDateRange = true, startDate, endDate } = props;
  const startDateText = moment(startDate).format("MM/DD/yyyy");
  const endDateText = moment(endDate).format("MM/DD/yyyy");

  const valueText =
    showSelectedDateRange && startDate && endDate
      ? startDateText === endDateText
        ? startDateText
        : `${startDateText} - ${endDateText}`
      : undefined;

  return (
    <IconCalloutButton iconName="Calendar" title="Select date range" {...props} valueText={valueText}>
      <>
        {props.children}
        <DateRangeCalloutContent {...props} />
      </>
    </IconCalloutButton>
  );
};
