import React from "react";
import { IColumn, IGroup } from "@fluentui/react/lib/DetailsList";
import { IEntity, IFinancialEntity } from "../common/interfaces";
import { startCase } from "../../shared/utilities/miscHelper";
import { IconButton } from "@fluentui/react/lib/Button";
import classNames from "./GraphHome.module.scss";

export const MaxGroupItemCount = 10000;

const getSelectItemTitle = (item) =>
  `Show entity details of ${item.entityType} with ID ${item.id} and version ${item.version}`;

export const getSelectionColumn = (onSelect: Function): IColumn[] => [
  {
    key: "selectItem",
    name: "Select",
    minWidth: 40,
    maxWidth: 40,
    isResizable: true,
    onRender: (item) => (
      <IconButton
        iconProps={{ iconName: "TextDocument" }}
        title={getSelectItemTitle(item)}
        aria-label={getSelectItemTitle(item)}
        className={classNames.itemAction}
        onClick={() => onSelect(item)}
      />
    ),
  },
];

export const getRelationColumns = (onSelect: Function): IColumn[] => [
  ...getSelectionColumn(onSelect),
  {
    key: "relationType",
    fieldName: "relationType",
    name: "Relation Type",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "id",
    fieldName: "id",
    name: "Entity ID",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "version",
    fieldName: "version",
    name: "Entity Version",
    minWidth: 80,
    maxWidth: 120,
    isResizable: true,
  },
];

export const getFinancialEntityColumns = (onSelect: Function, serviceContentConfig): IColumn[] => [
  ...getSelectionColumn(onSelect),
  {
    key: "id",
    fieldName: "id",
    name: "Entity ID",
    minWidth: 160,
    isResizable: true,
  },
  {
    key: "version",
    fieldName: "version",
    name: "Version",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
  },
  ...getDisplayColumns(serviceContentConfig),
];

export const getDisplayColumns = (serviceContentConfig): IColumn[] => {
  var displayColumns = [];

  serviceContentConfig?.attributeMappings?.forEach((attributeMapping) => {
    attributeMapping.attributes?.forEach((attribute) => {
      var attributeName = attribute.name;
      if (attribute.displayColumn && !displayColumns.find((column) => column.key === attributeName)) {
        displayColumns.push({
          key: attributeName,
          fieldName: attributeName,
          name: startCase(attributeName),
          isResizable: true,
        });
      }
    });
  });

  return displayColumns;
};

// =========================================================================================================================
export const getRelationItems = (
  selectedEntity: IEntity,
  root: IEntity = selectedEntity?.entity,
  items = [],
  level: number = 1,
  startIndex: number = 0
) => {
  if (!selectedEntity) return null;

  const { sources, targets } = selectedEntity;

  pushRelationItems(items, sources, level, root);
  pushRelationItems(items, targets, level, root, true);

  const newItemCount = items.length - startIndex;

  if (newItemCount > 0) {
    let nextLevelItems: any[] = sources || [];
    nextLevelItems = nextLevelItems.concat(targets || []);

    nextLevelItems &&
      nextLevelItems.forEach((nextLevelItem) => getRelationItems(nextLevelItem, root, items, level + 1, items.length));
  }

  return items;
};

// =========================================================================================================================
const pushRelationItems = (items: any[], relations, level: number, root, isTarget = false) =>
  relations &&
  relations.forEach((item) => {
    if (item.entityType !== root.entityType || item.id !== root.id) {
      if (!items.find((i) => i.entityType === item.entityType && i.id === item.id)) {
        let relationType =
          level > 1 ? "Indirect" : isTarget ? translateRelationType(item.relationType) : item.relationType;
        items.push({ ...item, level, relationType });
      }
    }
  });

// =========================================================================================================================
export const getRelationGroups = (items: any[]) => {
  var groups = [],
    prevEntityType = "",
    startIndex = 0;

  // Sort by entity type first.
  items = items.sort((a, b) => (a.entityType > b.entityType ? 1 : a.entityType < b.entityType ? -1 : 0));

  items.forEach((item, index) => {
    if (index > 0 && item.entityType !== prevEntityType) {
      let count = index - startIndex;

      pushRelationGroup(groups, prevEntityType, startIndex, count);
      startIndex = index;
    }
    prevEntityType = item.entityType;
  });

  pushRelationGroup(groups, prevEntityType, startIndex, items.length - startIndex);

  return groups;
};

// =========================================================================================================================
const pushRelationGroup = (groups: IGroup[], entityType: string, startIndex: number, count: number) => {
  groups.push({ key: entityType, name: entityType, startIndex, count });
};

// =========================================================================================================================
const translateRelationType = (origType: string): string =>
  origType === "Source" ? "Target" : origType === "Parent" ? "Child" : origType;

// =========================================================================================================================
export const getFinancialEntityGroups = (items: IFinancialEntity[]): IGroup[] => {
  let groups = [],
    prevType = null,
    startIndex = 0;

  items = items.sort((a, b) => (a.type > b.type ? 1 : a.type < b.type ? -1 : 0));

  items.forEach((item, index) => {
    let type = item.type;

    if (type !== prevType) {
      let count = index - startIndex;

      prevType && pushFinancialEntityGroup(groups, prevType, startIndex, count);
      startIndex = index;
      prevType = type;
    }
  });

  pushFinancialEntityGroup(groups, prevType, startIndex, items.length - startIndex);

  return groups;
};

// =========================================================================================================================
const pushFinancialEntityGroup = (groups: IGroup[], type: string, startIndex: number, count: number) => {
  groups.push({ key: type, name: type, startIndex, count, hasMoreData: count >= MaxGroupItemCount });
};
