import React, { CSSProperties } from "react";
import * as pbi from "powerbi-client";
import { authHelper } from "../../api/apiBase";

var powerbi = window["powerbi"];

export enum ContentType {
  dashboard = "dashboard",
  report = "report",
}

export interface IPowerbiReportProps {
  contentType: ContentType;
  contentId: string;
  groupId: string;
  className?: string;
  style?: CSSProperties;
  autoHeight?: boolean;
}

export interface IPowerbiReportState {
  height: number;
}

export class PowerbiReport extends React.Component<IPowerbiReportProps, IPowerbiReportState> {
  private powerbiReportContainer: HTMLElement;

  state = {
    height: 800,
  };

  componentDidMount() {
    this.loadPowerbiContent();
  }

  componentDidUpdate() {
    this.loadPowerbiContent();
  }

  render() {
    const { className, style, autoHeight = true } = this.props;
    const { height } = this.state;
    const finalStyle = autoHeight
      ? {
          height: height + "px",
          ...style,
        }
      : style;

    return <div ref={(element) => (this.powerbiReportContainer = element)} className={className} style={finalStyle} />;
  }

  loadPowerbiContent = () => {
    if (this.powerbiReportContainer) {
      const { contentType, contentId, groupId } = this.props;

      authHelper
        .acquireAccessToken([
          "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
          "https://analysis.windows.net/powerbi/api/Report.Read.All",
        ])
        .then((accessToken) => {
          var embedConfiguration = {
            type: contentType,
            id: contentId,
            embedUrl: `https://msit.powerbi.com/${contentType}Embed?${contentType}Id=${contentId}&groupId=${groupId}`,
            pageView: "fitToWidth",
            tokenType: pbi.models.TokenType.Aad,
            accessToken,
          };

          powerbi.embed(this.powerbiReportContainer, embedConfiguration);
        })
        .catch((error) => console.log(error));

      // Set the container height according to the window height.
      let height = window.innerHeight - this.powerbiReportContainer.offsetTop - 85;

      if (this.state.height !== height) {
        this.setState({ height });
      }
    }
  };
}

export default PowerbiReport;
