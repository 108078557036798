import React from "react";
import { Modal, IModalProps } from "@fluentui/react/lib/Modal";
import { PrimaryButton, BaseButton } from "@fluentui/react/lib/Button";
import { Spinner } from "@fluentui/react/lib/Spinner";
import classNames from "./BasicModal.module.scss";

export interface IBasicModalProps extends IModalProps {
  title?: string;
  saving?: boolean;
  disableCommit?: boolean;
  error?: string;
  className?: string;
  modalRootClassName?: string;
  modalContentClassName?: string;
  showCancel?: boolean;
  additionalButtons?: React.ReactNode;
  commitButtonText?: string;
  onCommit?: (data: any) => void;
  onCancel?: () => void;
}

export class BasicModal extends React.Component<IBasicModalProps> {
  render() {
    const {
      title,
      children,
      saving,
      error,
      className,
      modalRootClassName,
      modalContentClassName,
      showCancel = true,
      disableCommit,
      additionalButtons,
      commitButtonText = "Ok",
      onCommit,
      onCancel,
    } = this.props;

    return (
      <Modal
        className={className}
        isOpen
        titleAriaId="modalTitle"
        onDismiss={onCancel}
        containerClassName={[classNames.root, modalRootClassName].join(" ")}
        layerProps={{ eventBubblingEnabled: true }}
      >
        {title && (
          <div id="modalTitle" className={classNames.title}>
            {title}
          </div>
        )}
        <div className={[classNames.fieldsPane, modalContentClassName].join(" ")}>{children}</div>
        <div className={classNames.buttonsPane}>
          {error && <div className={classNames.error}>{error}</div>}
          {saving && (
            <Spinner
              styles={{
                root: classNames.spinner,
                circle: classNames.spinnerCircle,
                label: classNames.spinnerLabel,
              }}
            />
          )}
          <>{additionalButtons}</>
          <PrimaryButton className={classNames.button} onClick={onCommit} disabled={saving || disableCommit}>
            {commitButtonText}
          </PrimaryButton>
          {showCancel && (
            <BaseButton className={classNames.button} onClick={onCancel} disabled={saving}>
              Cancel
            </BaseButton>
          )}
        </div>
      </Modal>
    );
  }
}

export default BasicModal;
