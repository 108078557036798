import React from "react";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { IConfigHistory } from "./ConfigHistory.helper";
import classNames from "./JsonEditor.module.scss";

export enum DisplayStyle {
  single = "Single",
  diffWithOriginal = "Changes",
}

interface props {
  jsonEditorToolbar?: JSX.Element;
  isSuperAdmin: boolean;
  enableUiValidationToggle: boolean;
  configHistory: IConfigHistory[];
  displayStyle: DisplayStyle;
  leftSide?: string;
  rightSide?: string;
}

interface state {
  editor: null;
}
export class JsonEditor extends React.Component<props, state> {
  onEditorMount = (editor) => {
    this.setState({ editor });
    editor.focus();
  };

  render() {
    let { displayStyle, leftSide, rightSide, jsonEditorToolbar, isSuperAdmin, enableUiValidationToggle } = this.props;

    let baselineConfig = leftSide;
    let configValue = rightSide;

    return (
      <>
        <div className={classNames.upperTabStrip}>
          <div className={classNames.rightToolsArea}>{jsonEditorToolbar}</div>
          <div className={classNames.extraSpacingRightToolsArea}>{isSuperAdmin ? enableUiValidationToggle : null}</div>
        </div>
        <div className={classNames.editor}>
          {displayStyle === DisplayStyle.single ? (
            <Editor
              language="json"
              value={baselineConfig}
              onMount={this.onEditorMount}
              options={{ automaticLayout: true, find: { loop: true, autoFindInSelection: "always" } }}
            />
          ) : (
            <DiffEditor
              language="json"
              original={baselineConfig}
              modified={configValue}
              onMount={this.onEditorMount}
              options={{ readOnly: true }}
            />
          )}
        </div>
      </>
    );
  }
}
