import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import LeftNav from "./LeftNav";
import AppModule from "../appModules/common/AppModule";
import { IState } from "../reducers/interfaces";
import { Account } from "msal/lib-es6";
import { actionCreator as appActionCreator } from "./duck";
import { actionCreator } from "../appModules/duck";
import { Theme, setTheme } from "../shared/utilities/themeHelper";
import { HashRouter as Router } from "react-router-dom";
import classNames from "./App.module.scss";
import { SystemMessages } from "../appModules/common/SystemMessages";
import { IEnvironment, IFeatureFlags, ISystemMessage, copilotFeatureFlagStorageName } from "./interfaces";
import {
  ConfigItemType,
  fetchAntiforgeryToken,
  fetchConfigItem,
  fetchUserSettings,
  saveUserSettings,
} from "../api/radarApi";
import { useBrowserState } from "../shared/utilities/hooks";

const resizerWidth = 3;
const leftNavDefaultWidth = 220;
const leftNavMinWidth = 100;
const leftNavMaxWidth = 400;
const leftNavCollapsedWidth = 36;

export interface IAppStateProps {
  loginUser: Account | null;
  leftNavCollapsed: boolean;
  isSmallScreen: boolean;
  systemMessages: ISystemMessage[];
  isStaging: boolean;
}

export interface IAppDispatchProps {
  resizeWindow: () => void;
  loadSystemMessages: () => void;
  setEnvironments: (environments: IEnvironment[]) => void;
}

export interface IAppState {
  useCopilot?: boolean;
  showCopilot?: boolean;
  showCopilotOverride?: boolean;
  userSettings?: any;
  isStaging?: boolean;
  featureFlags?: IFeatureFlags;
  environments?: IEnvironment[];
  selectedEnvironment?: string;
}

export interface IAppContext {
  appState?: IAppState;
  changeAppState?: (newState) => void;
  changeUserSettings?: (userSettings) => void;
}

export const AppContext = createContext<IAppContext>({});

export const App = (props: IAppStateProps & IAppDispatchProps) => {
  const {
    systemMessages,
    loginUser,
    leftNavCollapsed,
    isSmallScreen,
    isStaging,
    loadSystemMessages,
    resizeWindow,
    setEnvironments,
  } = props;
  const [appState, setAppState] = useReducer((state, newState) => ({ ...state, ...newState, isStaging }), {});
  const { useCopilot } = appState;
  const [leftNavWidth, setLeftNavWidth] = useState<number>(leftNavDefaultWidth);
  const [dragging, setDragging] = useState<boolean>(false);
  const [copilotFeatureFlag] = useBrowserState(copilotFeatureFlagStorageName, ""); // Set copilotFeatureFlag to something to turn the feature on.
  const showLeftNav = !isSmallScreen || !leftNavCollapsed;
  const globalSystemMessages = useMemo(
    () => systemMessages?.filter((message) => message.pageName === "AllPages"),
    [systemMessages]
  );
  const finalLeftNavWidth = !showLeftNav ? 0 : leftNavCollapsed ? leftNavCollapsedWidth : leftNavWidth;
  const resizeWidth = leftNavCollapsed ? 1 : resizerWidth;

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!leftNavCollapsed && e.button !== 0) return; // Only left mouse button to trigger drag.

      setDragging(true);
      e.stopPropagation();
      e.preventDefault();
    },
    [leftNavCollapsed]
  );

  const onMouseUp = useCallback((e: MouseEvent) => {
    setDragging(false);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!dragging) return;

      setLeftNavWidth(Math.min(Math.max(leftNavWidth + e.movementX, leftNavMinWidth), leftNavMaxWidth));
      e.stopPropagation();
      e.preventDefault();
    },
    [dragging, leftNavWidth]
  );

  useEffect(() => {
    window.removeEventListener("resize", resizeWindow);
    window.addEventListener("resize", resizeWindow);
  }, [resizeWindow]);

  useEffect(() => {
    // Set default landing page
    let defaultPage = localStorage.defaultPage;
    if (defaultPage && (!window.location.hash || window.location.hash === "#/")) {
      window.location = defaultPage;
      return;
    }

    // Set default theme
    let theme = localStorage.theme || Theme.light;
    setTheme(theme);

    // Setup antiforgery token
    fetchAntiforgeryToken();
  }, []);

  useEffect(() => {
    if (!systemMessages) {
      loadSystemMessages();
    }
  }, [systemMessages, loadSystemMessages]);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  const changeAppState = useCallback((newState) => setAppState(newState), []);

  useEffect(() => {
    fetchUserSettings().then((userSettings) => userSettings && setAppState({ userSettings }));

    fetchConfigItem(ConfigItemType.AppSettings, "FeatureFlags", null, true).then((featureFlags: any) => {
      setAppState({ featureFlags });
    });

    fetchConfigItem(ConfigItemType.AppSettings, "Environments", null, true).then((environments: any) => {
      // Since we still have lots of older Redux logics that require the environments settings, we will push this data to the Redux side as well.
      setEnvironments(environments?.settings);
      // Setup the hook app state.
      setAppState({ environments: environments?.settings });
    });
  }, [setEnvironments]);

  useEffect(() => {
    setAppState({ showCopilot: copilotFeatureFlag && useCopilot });
  }, [copilotFeatureFlag, useCopilot]);

  const changeUserSettings = useCallback((userSettings) => {
    saveUserSettings(userSettings);
    setAppState({ userSettings });
  }, []);

  if (!loginUser) {
    return null;
  }

  const paneResizerClassNames = [
    leftNavCollapsed ? classNames.paneResizerCollapsed : classNames.paneResizer,
    dragging ? classNames.paneResizerDragging : "",
  ];

  return (
    <AppContext.Provider value={{ appState, changeAppState, changeUserSettings }}>
      <Router>
        <div className={classNames.root}>
          <div className={classNames.headerContainer}>
            <SystemMessages systemMessages={globalSystemMessages} />
            <Header />
          </div>
          <div className={classNames.body}>
            <div
              key="leftNavPane"
              className={classNames.leftNav}
              style={{ width: finalLeftNavWidth, transition: "width 0.2s" }}
            >
              <LeftNav key="leftNav" />
            </div>
            <div
              key="resizer"
              className={paneResizerClassNames.join(" ")}
              style={{ left: finalLeftNavWidth, width: resizeWidth, transition: "left 0.2s" }}
              onMouseDown={onMouseDown}
            />
            <div
              className={classNames.appModule}
              style={{
                left: finalLeftNavWidth + resizeWidth,
                transition: "left 0.2s, filter 0.2s",
                filter: dragging && "opacity(50%)",
              }}
            >
              <AppModule />
            </div>
          </div>
        </div>
      </Router>
    </AppContext.Provider>
  );
};

const mapStateToProps = (state: IState): IAppStateProps => ({
  loginUser: state.app.login_user,
  leftNavCollapsed: state.app.left_nav_collapsed,
  isSmallScreen: state.app.is_small_screen,
  systemMessages: state.app.system_messages,
  isStaging: state.app.is_staging,
});

const mapDispatchToProps = {
  resizeWindow: appActionCreator.resizeWindow,
  loadSystemMessages: appActionCreator.loadSystemMessages,
  setEnvironments: actionCreator.setEnvironments,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
