import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType } from "../../../shared/utilities/miscHelper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      data: "number",
    },
    {
      key: "objectType",
      name: "Object Type",
      fieldName: "objectType",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "enabled",
      name: "Enabled",
      fieldName: "enabled",
      minWidth: 45,
      maxWidth: 45,
      isResizable: true,
      onRender: (item) => (item.enabled ? "Yes" : "No"),
    },
    {
      key: "objectIds",
      name: "Object IDs",
      fieldName: "objectIds",
      minWidth: 60,
      maxWidth: 70,
      isResizable: true,
      onRender: (item) => getFormatContent(item.objectIds, ContentDisplayType.text),
    },
    {
      key: "notificationMethods",
      name: "Notifications",
      fieldName: "notificationMethods",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => getFormatContent(item.notificationMethods, ContentDisplayType.text),
    },
    {
      key: "eventTypes",
      name: "Event Types",
      fieldName: "eventTypes",
      minWidth: 200,
      isResizable: true,
      onRender: (item) => getFormatContent(item.eventTypes, ContentDisplayType.text),
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item._modifiedBy, ContentDisplayType.contact),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item.owner, ContentDisplayType.contact),
    },
    {
      key: "editors",
      name: "Editors",
      fieldName: "editors",
      minWidth: 100,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(item.editors, ContentDisplayType.contact),
    },
  ];
};
