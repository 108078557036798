import { IColumn } from "@fluentui/react/lib/DetailsList";
import { getFormatContent, ContentDisplayType, convertUtcToLocalTime } from "../../../shared/utilities/miscHelper";

export const getColumns = (): IColumn[] => {
  return [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      data: "number",
    },
    {
      key: "name",
      name: "Service Content Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "soxCompliance",
      name: "SOX",
      fieldName: "soxCompliance",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      onRender: (item) => (item.soxCompliance ? "Yes" : "No"),
    },
    {
      key: "publishedOn",
      name: "Last Published",
      fieldName: "publishedOn",
      minWidth: 120,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) =>
        getFormatContent(item.publishedOn && convertUtcToLocalTime(item.publishedOn), ContentDisplayType.datetime),
    },
    {
      key: "_ts",
      name: "Last Modified",
      fieldName: "_ts",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item) => getFormatContent(Number(item._ts) * 1000, ContentDisplayType.datetime),
    },
    {
      key: "_modifiedBy",
      name: "Modified By",
      fieldName: "_modifiedBy",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item._modifiedBy, ContentDisplayType.contact),
    },
    {
      key: "owner",
      name: "Owner",
      fieldName: "owner",
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => getFormatContent(item.owner, ContentDisplayType.contact),
    },
    {
      key: "editors",
      name: "Editors",
      fieldName: "editors",
      minWidth: 140,
      maxWidth: 160,
      isResizable: true,
      onRender: (item) => getFormatContent(item.editors, ContentDisplayType.contact),
    },
  ];
};
