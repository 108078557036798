import React, { useEffect, useMemo, useState } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { checkForValue, getDebounceMethod } from "../../utilities/miscHelper";
import { IField } from "./interfaces";

interface IProps {
  id: string;
  value: string;
  field: IField;
  fieldProps: object;
  onChange: (field: IField, newValue: any) => void;
}

export default function TextFieldContent(props: IProps) {
  const { id, value, field, fieldProps, onChange } = props;
  const { isMultiLine, multiLineRows, valueWidth } = field;
  const [getText, setText] = useState(value);
  const onChangeDebounced = useMemo(() => getDebounceMethod(onChange, 500), [onChange]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeHandler = (ev, value: string) => {
    setText(value);
    onChangeDebounced && onChangeDebounced(field, value);
  };

  return (
    <TextField
      id={id}
      value={getText}
      disabled={field?.disabled}
      multiline={isMultiLine}
      rows={multiLineRows}
      styles={{ root: { width: checkForValue(valueWidth) ? valueWidth : undefined } }}
      onChange={onChangeHandler}
      {...fieldProps}
    />
  );
}
