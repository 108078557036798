import { IField, FieldType } from "../../../shared/components/DataForm";
import { IIncidentView } from "../../incidents/interfaces";
import { getTilesFields, getColumnFields } from "../common/helper";

export const defaultIncidentView: IIncidentView = {
  id: "new",
  name: "",
  soxCompliance: true,
};

export const getFields = (incidentView: IIncidentView): IField[] => {
  if (!incidentView) return null;

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "ID",
      value: incidentView.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "name",
      label: "Incident View Name",
      value: incidentView.name,
      required: true,
      width: "300px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: incidentView.owner,
      hints: "Alias of owner",
      required: true,
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: incidentView.editors,
      width: "40%",
      required: true,
      hints: "Comma separated list of aliases who can edit this incident view",
    },
    {
      fieldName: "defaultFilters",
      label: "Default Filter Values",
      fieldType: FieldType.textArray,
      value: incidentView.defaultFilters,
      lastInRow: true,
      hints: "Comma separated list of default selected values for filters",
    },
    {
      fieldName: "description",
      label: "Description",
      value: incidentView.description,
      allowFullEditor: true,
      width: "100%",
    },
    {
      fieldName: "query",
      label: "IcM Kusto Query",
      value: incidentView.query,
      allowFullEditor: true,
      width: "100%",
      hints:
        "Refer to https://icmdocs.azurewebsites.net/reporting/kusto-schema.html, e.g. IncidentsSnapshot() | where OwningTenantId == 24623 and IsPurged != 1 | project IncidentId, OwningTenantId, Title, Status, Severity, ModifiedDate, ImpactStartDate, MitigateDate, MitigatedBy, ResolveDate, ResolvedBy, OwningContactAlias",
    },
    {
      label: "Advanced",
      fieldType: FieldType.container,
      collapsible: true,
      fields: [
        {
          fieldName: "disableRefresh",
          label: "Disable Refresh",
          fieldType: FieldType.boolean,
          value: incidentView.disableRefresh,
          hints: "Check to disallow Refresh action on this view.",
        },
        {
          fieldName: "testOnly",
          label: "Test Only",
          fieldType: FieldType.boolean,
          value: incidentView.testOnly,
          hints: "Check to prevent this view to be displayed in Production",
        },
        {
          fieldName: "disableShowInRadar",
          label: "Disable ShowInRadar",
          fieldType: FieldType.boolean,
          value: incidentView.disableShowInRadar,
          hints: "Check to not apply the ShowInRadar built-in filter",
        },
        {
          fieldName: "hideDetailsPage",
          label: "Hide Details Page",
          fieldType: FieldType.boolean,
          value: incidentView.hideDetailsPage,
          hints: "Check to hide link to details page",
        },
        {
          fieldName: "showOpenAiSummary",
          label: "Show Open AI Summary",
          fieldType: FieldType.boolean,
          value: incidentView.showOpenAiSummary,
          hints: "Check to show OpenAI summary",
        },
        {
          fieldName: "enableAutoCache",
          label: "Enable Auto Cache",
          fieldType: FieldType.boolean,
          value: incidentView.enableAutoCache,
          hints: "Check to enable auto caching for this incident view",
        },
        {
          fieldName: "timelinesCollapseByDefault",
          label: "Timelines Collapsed",
          fieldType: FieldType.boolean,
          value: incidentView.timelinesCollapseByDefault,
          hints: "Check to collapse the timelines tile by default.",
        },
        {
          fieldName: "incidentRefreshLimit",
          label: "Incident Refresh Limit",
          fieldType: FieldType.number,
          value: incidentView.incidentRefreshLimit,
          width: "140px",
          valueWidth: "60px",
          hints:
            "By default, our cache service only refreshes cache data on the top 100 incidents in each view. This field can be used to change that default limit.",
        },
      ],
    },
    { ...getTilesFields(incidentView.tiles), collapsed: false },
    {
      ...getColumnFields({
        fieldName: "columns",
        label: "Incident List Columns",
        value: incidentView.columns,
        compact: false,
        collapsed: false,
        hints: "Columns definition for the incident list table",
      }),
    },
    {
      fieldName: "filters",
      fieldType: FieldType.items,
      label: "Custom Filters",
      itemTypeName: "Filter",
      value: incidentView.filters,
      compact: false,
      collapsed: false,
      hints: "Custom filters definition for the incident view",
      fields: [
        {
          fieldName: "fieldName",
          label: "Field Name",
        },
        {
          fieldName: "displayName",
          label: "Display Name",
        },
        {
          fieldName: "items",
          fieldType: FieldType.items,
          label: "Filter Value Items",
          itemTypeName: "Value Item",
          compact: true,
          collapsed: false,
          fields: [
            { fieldName: "name", label: "Name" },
            { fieldName: "value", label: "Value" },
          ],
        },
      ],
    },
  ];
};
