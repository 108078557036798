import React from "react";
import ConfirmModal, { IConfirmModalProps } from "./ConfirmModal";
import classNames from "./BasicModal.module.scss";

export interface IAlertModalProps extends IConfirmModalProps {}

export class AlertModal extends React.Component<IAlertModalProps> {
  render() {
    return (
      <ConfirmModal
        // TODO: Task 37700847: AlertModal/ConfirmModal error: Type 'Ref<HTMLDivElement>' is not assignable to type 'LegacyRef<ConfirmModal>'
        {...(this.props as any)}
        showCancel={false}
        className={`${classNames.AlertModal} ${this.props.className}`}
        title={this.props.title || ""}
      />
    );
  }
}

export default AlertModal;
