import { IReport, ITeam, ReportType, ValueChevronType } from "../common/interfaces";
import {
  ContentDisplayType,
  getFirstOfMonthISODate,
  getFormatContent,
  getLastOfMonthISODate,
} from "../../shared/utilities/miscHelper";
import { ContactLink } from "../../shared/components/ContactLink";
import classNames from "./OkrList.module.scss";
import { IOkrHomeProps } from "./OkrHome";
import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { getBasicPageCommandBarItems } from "../common/helper";

export const columns = [
  {
    key: "name",
    fieldName: "name",
    name: "OKR",
    minWidth: 200,
    maxWidth: 400,
    isResizable: true,
    isMultiline: true,
    onRender: (item) => <b>{item["name"]}</b>,
  },
  {
    key: "slaNote",
    fieldName: "slaNote",
    name: "SLA",
    minWidth: 120,
    maxWidth: 200,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "owner",
    fieldName: "owner",
    name: "Owner",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => <ContactLink alias={item.owner} messageSubject={`OKR: ${item["name"]}`} />,
  },
  {
    key: "target",
    fieldName: "target",
    name: "Target",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) =>
      item.targetOverride
        ? item.targetOverride
        : item.targetTemplate
        ? getFormatContent(item, item.valueDisplayType, "target", item.targetTemplate)
        : getOkrValueContent(item, item.valueDisplayType, "target", "targetDecimal"),
  },
  {
    key: "value",
    fieldName: "value",
    name: "This Month",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => {
      if (item.value === undefined && item.valueOverride === undefined && item.valueTemplate === undefined) {
        return null;
      }

      var color = item["valueColor"] || getColor(item["target"], item["value"], item["valueCompareType"]);
      var valueDisplayType = item.thisValueDisplayType || item.valueDisplayType;

      return (
        <b style={{ color: color }}>
          {item.valueOverride
            ? item.valueOverride
            : item.valueTemplate
            ? item.value && getFormatContent(item, valueDisplayType, "value", item.valueTemplate)
            : item.value && getOkrValueContent(item, valueDisplayType, "value", "valueDecimal")}
        </b>
      );
    },
  },
  {
    key: "lastValue",
    fieldName: "lastValue",
    name: "Last Month",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => {
      if (
        item.lastValue === undefined &&
        item.lastValueOverride === undefined &&
        item.lastValueTemplate === undefined
      ) {
        return null;
      }

      var color = item["lastValueColor"] || getColor(item["target"], item["lastValue"], item["valueCompareType"]);
      var valueDisplayType = item.lastValueDisplayType || item.valueDisplayType;

      return (
        <span style={{ color: color }}>
          {item.lastValueOverride
            ? item.lastValueOverride
            : item.lastValueTemplate
            ? item.lastValue && getFormatContent(item, valueDisplayType, "lastValue", item.lastValueTemplate)
            : item.lastValue && getOkrValueContent(item, valueDisplayType, "lastValue", "lastValueDecimal")}
        </span>
      );
    },
  },
  {
    key: "momChange",
    fieldName: "momChange",
    name: "MoM Change",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    onRender: (item) => {
      if (
        (item.value === undefined || item.lastValue === undefined) &&
        item.changeValueOverride === undefined &&
        item.changeValueTemplate === undefined
      ) {
        return null;
      }

      var valueCompareType = item["valueCompareType"];
      var thisValue = item["value"];
      var lastValue = item["lastValue"];
      var color = item["changeValueColor"] || getColor(lastValue, thisValue, valueCompareType);
      var change = thisValue - lastValue;
      var chevronType = item["changeValueChevronType"];
      var valueDisplayType = item.changeValueDisplayType || item.valueDisplayType;
      var trendIndicator = undefined;

      if (change !== 0 || chevronType === ValueChevronType.up || chevronType === ValueChevronType.down) {
        var trendUp = thisValue >= lastValue || chevronType === ValueChevronType.up;

        trendIndicator = (
          <div
            className={trendUp ? classNames.trendIndicatorUp : classNames.trendIndicatorDown}
            style={trendUp ? { borderBottomColor: color } : { borderTopColor: color }}
          />
        );
      }

      item.changeValue = change;

      return (
        <b style={{ color: color }}>
          {item.changeValueOverride
            ? item.changeValueOverride
            : item.changeValueTemplate
            ? getFormatContent(item, valueDisplayType, "changeValue", item.changeValueTemplate)
            : getOkrValueContent(item, valueDisplayType, "changeValue", "changeValueDecimal")}
          {trendIndicator}
        </b>
      );
    },
  },
  {
    key: "comment",
    fieldName: "comment",
    name: "Comment",
    isResizable: true,
    minWidth: 140,
    isMultiline: true,
    onRender: (item) => item.comment && getFormatContent(item, ContentDisplayType.html, null, item.comment),
  },
];

export const getCommandBarProps = (props: IOkrHomeProps, report): ICommandBarProps => {
  const { loadTeams, loadTeamTiles, loadTeamOkrMetrics, selectedTeamId, isSmallScreen, downloadReportDataToExcel } =
    props;
  const reportType = report?.type;
  const reportId = report?.id;

  var basicPageCommandBarActions = {
    refresh: () =>
      loadTeams(true, () => {
        loadTeamOkrMetrics(selectedTeamId, true);
        loadTeamTiles(selectedTeamId, null, true);
      }),
  };

  return {
    items: [],
    farItems: [
      {
        key: "excelExport",
        name: "Excel Export",
        title: "Export the current report data to Excel",
        iconProps: {
          iconName: "ExcelDocument",
        },
        iconOnly: isSmallScreen,
        disabled: reportType === ReportType.powerbi || !reportId,
        onClick: (ev: any) => reportId && downloadReportDataToExcel(reportId),
      },
      ...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions),
    ],
  };
};

export const getCurrentOkrs = (team: ITeam, selectedDate: string) => {
  var finalOkrs = [];

  team?.okrs?.forEach((okr) => {
    let isValueOverrideFound = false;

    okr.valueOverrides?.forEach((result) => {
      if (result.startTime && result.endTime && selectedDate >= result.startTime && selectedDate <= result.endTime) {
        finalOkrs.push({
          ...okr,
          ...result,
          owner: okr.owner || team.owner,
          valueDisplayType: okr.valueDisplayType || ContentDisplayType.number,
          thisValueDisplayType: result.valueDisplayType || okr.valueDisplayType || ContentDisplayType.number,
        });

        isValueOverrideFound = true;
      }
    });

    if (!isValueOverrideFound) {
      finalOkrs.push({
        ...okr,
        startTime: getFirstOfMonthISODate(0, new Date(selectedDate)),
        endTime: getLastOfMonthISODate(0, new Date(selectedDate)),
        owner: okr.owner || team.owner,
        valueDisplayType: okr.valueDisplayType || ContentDisplayType.number,
        thisValueDisplayType: okr.valueDisplayType || ContentDisplayType.number,
      });
    }
  });

  team?.okrMetrics?.forEach((okrMetric) => {
    team?.okrs?.forEach((okr) => {
      if (okr.id === okrMetric.id) {
        let valueOverrideIndex = finalOkrs.findIndex(
          (vo) => vo.id === okrMetric.id && vo.startTime === okrMetric.startTime && vo.endTime === okrMetric.endTime
        );

        if (valueOverrideIndex >= 0) {
          let newOkr = { ...okrMetric, ...finalOkrs[valueOverrideIndex] };
          !finalOkrs[valueOverrideIndex].lastValue && okrMetric.lastValue && (newOkr.lastValue = okrMetric.lastValue);
          !finalOkrs[valueOverrideIndex].value && okrMetric.value && (newOkr.value = okrMetric.value);
          finalOkrs.splice(valueOverrideIndex, 1, newOkr);
        } else if (selectedDate >= okrMetric.startTime && selectedDate <= okrMetric.endTime) {
          let newOkr = {
            ...okrMetric,
            ...okr,
            owner: okr.owner || team.owner,
            valueDisplayType: okr.valueDisplayType || ContentDisplayType.number,
            thisValueDisplayType: okr.valueDisplayType || ContentDisplayType.number,
          };
          !okr["lastValue"] && okrMetric.lastValue && (newOkr.lastValue = okrMetric.lastValue);
          !okr["value"] && okrMetric.value && (newOkr.value = okrMetric.value);
          finalOkrs.push(newOkr);
        }
      }
    });
  });

  // Remove OKR that doesn't have this month value.
  if (finalOkrs.length) {
    for (let i = finalOkrs.length - 1; i >= 0; i--) {
      finalOkrs[i].value === undefined && finalOkrs.splice(i, 1);
    }
  }

  return finalOkrs;
};

export const getColor = (value1: number, value2: number, valueCompareType: string = ">="): string => {
  return isNaN(value1) || isNaN(value2)
    ? "undefined"
    : (valueCompareType === ">=" && value2 >= value1) || (valueCompareType !== ">=" && value2 <= value1)
    ? "green"
    : "red";
};

export const updateTeamReport = (teams: ITeam[], teamId: string, report: IReport): ITeam[] => {
  var team = teams.find((team) => team.id === teamId);

  team && (team.report = report);

  return teams.slice();
};

export const getOkrValueContent = (
  item,
  valueDisplayType: ContentDisplayType,
  fieldName: string,
  decimalFieldName: string
) => {
  return getFormatContent(
    item,
    valueDisplayType,
    fieldName,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    decimalFieldName
  );
};
