import React from "react";
import ConfirmModal, { IConfirmModalProps } from "./ConfirmModal";
import { TextField } from "@fluentui/react/lib/TextField";
import { DatePicker } from "@fluentui/react/lib/DatePicker";
import { IParameter, ParameterDataType } from "./interfaces";
import classNames from "./BasicModal.module.scss";

export interface IConfirmParametersModalProps extends IConfirmModalProps {
  parameters: IParameter[];
  error?: string;
  onParameterChange?: (parameterValues: object) => void;
}

export interface IConfirmParametersModalState {
  parameterErrors: object;
  parameterValues: object;
}

export class ConfirmParametersModal extends React.Component<
  IConfirmParametersModalProps,
  IConfirmParametersModalState
> {
  state = {
    parameterErrors: {},
    parameterValues: {},
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parameters, content, onCommit, onCancel, error } = this.props;
    const { parameterErrors, parameterValues } = this.state;

    return (
      <ConfirmModal
        {...(this.props as any)}
        error=""
        content={
          <div>
            <div>{content}</div>
            <div className={classNames.parameters}>
              {parameters &&
                parameters.map((parameter, index) => {
                  let value = parameterValues[parameter.name],
                    error = parameterErrors && parameterErrors[parameter.name];

                  return (
                    <div key={parameter.name + index} className={classNames.parameter}>
                      <div className={classNames.prompt}>{parameter.prompt}</div>
                      <div className={classNames.field}>
                        {parameter.dataType === ParameterDataType.date && (
                          <DatePicker
                            className={classNames.dateField}
                            value={value && new Date(value + "T08:00:00.000Z")}
                            onSelectDate={(date) =>
                              this.onParameterChange(parameter.name, date.toISOString().substring(0, 10))
                            }
                          />
                        )}
                        {parameter.dataType === ParameterDataType.email && (
                          <TextField
                            className={classNames.textField}
                            value={value}
                            width={parameter.width}
                            onChange={(ev, newValue) => this.onEmailParameterChange(parameter.name, newValue)}
                          />
                        )}
                        {(!parameter.dataType || parameter.dataType === ParameterDataType.text) && (
                          <TextField
                            className={classNames.textField}
                            value={value}
                            multiline={!!parameter.rows}
                            rows={parameter.rows}
                            width={parameter.width}
                            onChange={(ev, newValue) => this.onParameterChange(parameter.name, newValue)}
                          />
                        )}
                      </div>
                      <div className={classNames.parameterError}>
                        {error && <div className={classNames.error}>{error}</div>}
                      </div>
                    </div>
                  );
                })}
            </div>
            {error && <div className={`${classNames.error} ${classNames.formError}`}>{error}</div>}
          </div>
        }
        onCommit={onCommit}
        onCancel={onCancel}
      />
    );
  }

  onEmailParameterChange = (name: string, value: string) => {
    let callback = (value) => value.match(/\S+@\S+/g);
    this.onChange(name, value, callback, "Invalid email format, e.g. abc@microsoft.com");
  };

  onParameterChange = (name: string, value: string) => {
    let callback = (value) => !!value;
    this.onChange(name, value, callback, "This field is required");
  };

  onChange = (name: string, value: string, validateCallback: (value) => boolean, errorText: string) => {
    const { onParameterChange } = this.props;

    let newState = { ...this.state };
    newState.parameterValues[name] = value;

    if (validateCallback(value)) {
      delete newState.parameterErrors[name];
    } else {
      newState.parameterErrors[name] = errorText;
    }

    onParameterChange && onParameterChange(newState.parameterValues);
    this.setState(newState);
  };
}

export default ConfirmParametersModal;
