import React, { useEffect, useMemo, useState, useCallback } from "react";
import classNames from "./StreamsHome.module.scss";
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IDetailsListStyleProps,
  IDetailsListStyles,
  IDropdownOption,
  IStyleFunctionOrObject,
  SelectionMode,
} from "@fluentui/react";
import { getStreamOptions, timeFilterOptions, getStreamColumns, isUnhealthyRun } from "./StreamsHome.helper";
import { useBrowserState } from "../../shared/utilities/hooks";

export const dataRefreshIntervalInMinutes = 5;
export const maxDaysForRuns = 2;

export interface IStreamsBoardProps {
  id: string;
  teamId: string;
  streamRuns: any[];
  lastRefreshTime: string;
  headerText?: string;
  tableStyles?: IStyleFunctionOrObject<IDetailsListStyleProps, IDetailsListStyles>;
}

export const StreamsBoard = (props: IStreamsBoardProps) => {
  const { streamRuns, lastRefreshTime, headerText, id, teamId, tableStyles } = props;
  const [teamOptions, setTeamOptions] = useState<IDropdownOption[]>();
  const [statusOptions, setStatusOptions] = useState<IDropdownOption[]>();
  const [streamIdOptions, setStreamIdOptions] = useState<IDropdownOption[]>();
  const [selectedTeam, setSelectedTeam] = useBrowserState(`streamSelectedTeam-${id}`);
  const [selectedStatus, setSelectedStatus] = useBrowserState(`streamSelectedStatus-${id}`);
  const [selectedStreamId, setSelectedStreamId] = useBrowserState(`streamSelectedStreamId-${id}`);
  const [selectedTime, setSelectedTime] = useBrowserState(
    `streamSelectedTime-${id}`,
    timeFilterOptions[0].key.toString()
  );

  const ignoreUnhealthyRun = selectedTeam || selectedStatus || selectedStreamId;

  const teamFilterHandler = useCallback(
    (run) => !selectedTeam || run.teamName === selectedTeam || isUnhealthyRun(run, ignoreUnhealthyRun),
    [selectedTeam, ignoreUnhealthyRun]
  );

  const statusFilterHandler = useCallback(
    (run) => !selectedStatus || run.status === selectedStatus || isUnhealthyRun(run, ignoreUnhealthyRun),
    [selectedStatus, ignoreUnhealthyRun]
  );

  const streamFilterHandler = useCallback(
    (run) => !selectedStreamId || run.streamId === selectedStreamId || isUnhealthyRun(run, ignoreUnhealthyRun),
    [selectedStreamId, ignoreUnhealthyRun]
  );

  const timeFilterHandler = useCallback(
    (run) => {
      const today = new Date(),
        minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - Number(selectedTime), 0, 0, 0, 0),
        maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + maxDaysForRuns);

      let scheduledStartTime = new Date(run.scheduledStartTime),
        scheduledEndTime = new Date(run.scheduledEndTime),
        actualStartTime = new Date(run.actualStartTime),
        actualEndTime = new Date(run.actualEndTime),
        sla = new Date(run.sla),
        actualSla = new Date(run.actualSla),
        eventTimestamp = new Date(run.eventTimestamp);

      // Return all records if max time range is selected.
      return (
        Number(selectedTime) === 30 ||
        (scheduledStartTime >= minDate && scheduledStartTime <= maxDate) ||
        (scheduledEndTime >= minDate && scheduledEndTime <= maxDate) ||
        (actualStartTime >= minDate && actualStartTime <= maxDate) ||
        (actualEndTime >= minDate && actualEndTime <= maxDate) ||
        (sla >= minDate && sla <= maxDate) ||
        (actualSla >= minDate && actualSla <= maxDate) ||
        (eventTimestamp >= minDate && eventTimestamp <= maxDate) ||
        isUnhealthyRun(run, ignoreUnhealthyRun)
      );
    },
    [selectedTime, ignoreUnhealthyRun]
  );

  let filteredRuns = useMemo(() => {
    let result = streamRuns?.slice();

    // Filter by selected team, status, streamId, time as needed.
    // Always include "Unhealthy" runs unless team, status or streamId filter is selected.
    if (streamRuns) {
      selectedTeam && (result = result.filter(teamFilterHandler));
      selectedStatus && (result = result.filter(statusFilterHandler));
      selectedStreamId && (result = result.filter(streamFilterHandler));
      result = result.filter(timeFilterHandler);
    }

    return result;
  }, [
    streamRuns,
    selectedTeam,
    selectedStatus,
    selectedStreamId,
    teamFilterHandler,
    statusFilterHandler,
    streamFilterHandler,
    timeFilterHandler,
  ]);

  useEffect(() => {
    // Retrieve various filter options from stream runs.
    if (streamRuns?.length) {
      let runsByTopFilters = streamRuns.filter((run) => teamFilterHandler(run) && timeFilterHandler(run));

      setTeamOptions(getStreamOptions(streamRuns, "teamName"));
      setStatusOptions(getStreamOptions(runsByTopFilters, "status"));
      setStreamIdOptions(getStreamOptions(runsByTopFilters, "streamId"));
    }
  }, [streamRuns, selectedTeam, filteredRuns, teamFilterHandler, timeFilterHandler]);

  useEffect(() => {
    setSelectedTeam("");
    setSelectedStatus("");
    setSelectedStreamId("");
  }, [teamId, setSelectedTeam, setSelectedStatus, setSelectedStreamId]);

  return (
    <>
      <div className={classNames.headerPane}>
        {filteredRuns && <div className={classNames.headerText}>{headerText}</div>}
        {streamRuns?.length > 0 && (
          <div className={classNames.filtersPane}>
            {teamOptions?.length > 2 && (
              <Dropdown
                className={classNames.teamsDropdown}
                options={teamOptions}
                label="Teams"
                selectedKey={selectedTeam?.toString() || ""}
                onChange={(ev, option) => {
                  setSelectedTeam(option.key.toString());
                  setSelectedStreamId("");
                  setSelectedStatus("");
                }}
              />
            )}
            {streamIdOptions?.length > 2 && (
              <Dropdown
                className={classNames.streamIdsDropdown}
                options={streamIdOptions}
                label="Stream Ids"
                selectedKey={selectedStreamId?.toString() || ""}
                onChange={(ev, option) => setSelectedStreamId(option.key.toString())}
              />
            )}
            {statusOptions?.length > 2 && (
              <Dropdown
                className={classNames.statusDropdown}
                options={statusOptions}
                label="Status"
                selectedKey={selectedStatus?.toString() || ""}
                onChange={(ev, option) => setSelectedStatus(option.key.toString())}
              />
            )}
            <Dropdown
              className={classNames.timeDropdown}
              options={timeFilterOptions}
              label="Time"
              selectedKey={Number(selectedTime)}
              onChange={(ev, option) => setSelectedTime(option.key.toString())}
            />
          </div>
        )}
      </div>
      {!!filteredRuns?.length && (
        <>
          <div className={classNames.contentBox} data-is-scrollable="true">
            <DetailsList
              styles={tableStyles}
              columns={getStreamColumns(filteredRuns)}
              items={filteredRuns}
              compact
              selectionMode={SelectionMode.none}
              constrainMode={ConstrainMode.unconstrained}
              layoutMode={DetailsListLayoutMode.justified}
            />
          </div>
          <div className={classNames.lastRefreshTime}>
            Last Refresh Time: {lastRefreshTime} (auto-refresh every {dataRefreshIntervalInMinutes} minutes)
          </div>
        </>
      )}
      {!filteredRuns?.length && <div className={classNames.text}>No stream data is found.</div>}
    </>
  );
};

export default StreamsBoard;
