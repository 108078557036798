import { Callout, DirectionalHint, ICalloutProps, IconButton, PrimaryButton, TextField } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { saveUserFeedback } from "../api/radarApi";
import { getUserId } from "../appModules/common/helper";
import { IState } from "../reducers/interfaces";
import classNames from "./UserFeedbackCallout.module.scss";

export interface IUserFeedbackCalloutProps extends ICalloutProps {
  onDismiss?: () => void;
}

export interface IUserFeedbackCalloutStateProps {
  userId: string;
}

export const UserFeedbackCallout = (props: IUserFeedbackCalloutProps & IUserFeedbackCalloutStateProps) => {
  const { userId, onDismiss } = props;
  const [sentiment, setSentiment] = useState<number>(undefined);
  const [comment, setComment] = useState<string>(undefined);

  const secondQuestionText =
    sentiment === 1
      ? "What do you like the most?"
      : sentiment === -1
      ? "What will make this more useful to you?"
      : "What do you use it for that we can help improve?";

  const onSentimentButtonClick = useCallback((selectedSentiment: number) => {
    setSentiment(selectedSentiment);
  }, []);

  const onCalloutDismiss = () => {
    if (sentiment !== undefined) {
      const feedbackData = {
        userAlias: userId,
        entryDateTime: new Date().toISOString(),
        pageUrl: window.location.href,
        browserInfo: navigator.userAgent,
        sentiment,
        comment,
      };

      saveUserFeedback(feedbackData).catch((error) => console.log(error));
    }

    onDismiss && onDismiss();
  };

  const onCommentChange = useCallback((ev, newValue: string) => {
    setComment(newValue);
  }, []);

  return (
    <Callout
      {...props}
      className={classNames.root}
      isBeakVisible={false}
      directionalHint={DirectionalHint.bottomRightEdge}
      setInitialFocus={true}
      gapSpace={12}
      tabIndex={0}
      onDismiss={onCalloutDismiss}
    >
      <div className={classNames.title}>User Feedback</div>
      {sentiment === undefined ? (
        <>
          <div className={classNames.question}>How would you rate the usefulness of this page?</div>
          <div className={classNames.buttonsPane}>
            <IconButton
              iconProps={{ iconName: "Emoji2" }}
              title="It is very useful to me!"
              onClick={() => onSentimentButtonClick(1)}
            />
            <IconButton
              iconProps={{ iconName: "EmojiDisappointed" }}
              title="It's not useful to me."
              onClick={() => onSentimentButtonClick(-1)}
            />
            <IconButton
              iconProps={{ iconName: "EmojiNeutral" }}
              title="I rarely use it for my needs."
              onClick={() => onSentimentButtonClick(0)}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classNames.question}>{secondQuestionText}</div>
          <TextField
            className={classNames.comment}
            rows={5}
            multiline={true}
            value={comment}
            onChange={onCommentChange}
          />
          <PrimaryButton text="Done" className={classNames.doneButton} onClick={onCalloutDismiss} />
        </>
      )}
    </Callout>
  );
};

const mapStateToProps = (state: IState): IUserFeedbackCalloutStateProps => ({
  userId: getUserId(state.app.login_user),
});

export default connect(mapStateToProps)(UserFeedbackCallout);
