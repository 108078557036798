export interface IMSSalesAccessDeniedMessageProps {
  objectTypeName: string;
}

export const MSSalesAccessDeniedMessage = (props: IMSSalesAccessDeniedMessageProps) => {
  const { objectTypeName } = props;

  return (
    <div>
      <h1>Access to this {objectTypeName} is restricted to users with the full MS Sales WW access.</h1>
      <span>
        A full MS Sales WW access can be obtained by visiting MSS Sales SharePoint and requesting access through&nbsp;
        <a href="https://microsoft.sharepoint.com/teams/MS_Sales/SitePages/Accounts.aspx">MS Sales Accounts</a> page
      </span>
    </div>
  );
};
