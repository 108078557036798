import React from "react";
import { connect } from "react-redux";
import classNames from "./GraphHome.module.scss";
import { IState } from "../../reducers/interfaces";
import { SelectionMode, CheckboxVisibility, DetailsList } from "@fluentui/react/lib/DetailsList";
import { getEntityMetricColumns, getEntityMetricGroups, flattenEntityMetrics, getMetricValues } from "./helper";
import { IEntityMetric } from "../common/interfaces";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { actionCreator } from "../duck";
import { errorType } from "../interfaces";

export interface IEntityMetricsStateProps {
  entityMetrics: IEntityMetric[];
  loadingMetrics: boolean;
  selectedEntityType: string;
  error: string;
}

export interface IEntityMetricsDispatchProps {
  selectEntityMetric: (entityType: string, metricId: string) => void;
}

export class EntityMetrics extends React.Component<IEntityMetricsStateProps & IEntityMetricsDispatchProps> {
  render() {
    const { entityMetrics, loadingMetrics, error, selectedEntityType, selectEntityMetric } = this.props;

    if (loadingMetrics) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading entity metrics..."
        />
      );
    } else if (error) {
      return <div className={classNames.error}>{error}</div>;
    }

    let entityMetricsCopy = entityMetrics?.slice();

    entityMetricsCopy?.forEach((em) => {
      let metricValues = getMetricValues(em.metrics);

      em.metrics?.forEach((m) => {
        if (m.template) {
          m["metricValues"] = metricValues;
        }
        m.value = m.value || 0;
      });
    });

    if (selectedEntityType) {
      entityMetricsCopy = entityMetricsCopy?.filter((em) => em.entityType === selectedEntityType);
    }

    if (!entityMetricsCopy || !entityMetricsCopy.length) {
      return <div className={classNames.message}>No related metric info is available.</div>;
    }

    const items = flattenEntityMetrics(entityMetricsCopy).filter((m) => !m.hidden);

    return (
      <DetailsList
        items={items}
        columns={getEntityMetricColumns(selectEntityMetric)}
        groups={getEntityMetricGroups(items)}
        compact={true}
        selectionMode={SelectionMode.none}
        checkboxVisibility={CheckboxVisibility.hidden}
      />
    );
  }
}

const mapStateToProps = (state: IState) => ({
  entityMetrics: state.modules.entity_metrics_by_selected_view,
  loadingMetrics: state.modules.loading_entity_metrics,
  selectedEntityType: state.modules.search_entity_type,
  error: state.modules.errors[errorType.financialEntities],
});

const mapDispatchToProps = {
  selectEntityMetric: actionCreator.selectEntityMetric,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityMetrics);
