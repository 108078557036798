import React from "react";
import { ITeam } from "../common/interfaces";
import StreamsBoard from "./StreamsBoard";
import { useWindowSize } from "../../shared/utilities/hooks";

export interface IStreamsTeamProps {
  selectedTeam: ITeam;
  teamId: string;
  upStreamRuns: any[];
  downStreamRuns: any[];
  lastRefreshTime: string;
}

export const StreamsTeam = (props: IStreamsTeamProps) => {
  const { selectedTeam, teamId, upStreamRuns, downStreamRuns, lastRefreshTime } = props;
  const downStreamsHeaderText = selectedTeam
    ? `Streams from ${selectedTeam.name} to Downstream Teams`
    : "Streams from Teams";
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - 400,
    tableStyles = { root: { maxHeight: selectedTeam ? contentHeight / 2 : contentHeight } };

  return (
    <>
      {selectedTeam && (
        <StreamsBoard
          id="upStreams"
          teamId={teamId}
          streamRuns={upStreamRuns}
          lastRefreshTime={lastRefreshTime}
          headerText={`Streams from Upstream Teams to ${selectedTeam.name}`}
          tableStyles={tableStyles}
        />
      )}
      <StreamsBoard
        id="downStreams"
        teamId={teamId}
        streamRuns={downStreamRuns}
        lastRefreshTime={lastRefreshTime}
        headerText={downStreamsHeaderText}
        tableStyles={tableStyles}
      />
    </>
  );
};

export default StreamsTeam;
