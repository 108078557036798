import React from "react";
import { connect } from "react-redux";
import Diagram, { IDiagram, INode } from "../../shared/components/Diagram";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IState } from "../../reducers/interfaces";
import { getEntityDiagram, getFinancialEntitiesDiagram, getGraphEntityMetrics } from "./EntityDiagram.helper";
import { actionCreator } from "../duck";
import { IEntity, IFinancialEntityCount, IEntityTest, IEntityMetric } from "../common/interfaces";
import classNames from "./EntityDiagram.module.scss";
import { IEntityView } from "../common/interfaces";
import { dynamicEntityViewId, SearchItemKey } from "./helper";

export interface IEntityDiagramStateProps {
  selectedEntity: IEntity;
  loading: boolean;
  notFound: boolean;
  financialEntityCounts: IFinancialEntityCount[];
  entityTests: IEntityTest[];
  selectedEntityView: IEntityView;
  entityMetrics: IEntityMetric[];
  selectedEntityType: string;
  searchItemKey: SearchItemKey;
  dynamicEntityView: IEntityView;
}

export interface IEntityDiagramState {
  diagram: IDiagram;
}

export interface IEntityDiagramDispatchProps {
  ariaLabel: string;
  loadEntity: (entityType: string, entityId: string, entityVersion?: string) => void;
  setSearchEntityType: (entityType: string) => void;
  searchEntities: () => void;
}

export class EntityDiagram extends React.Component<
  IEntityDiagramStateProps & IEntityDiagramDispatchProps,
  IEntityDiagramState
> {
  state = {
    diagram: null,
  };

  componentDidMount() {
    const { selectedEntity, entityMetrics, entityTests, selectedEntityView, selectedEntityType, searchItemKey } =
      this.props;

    const isEntitySearch = searchItemKey === SearchItemKey.entitySearch,
      isEntityLookup = searchItemKey === SearchItemKey.entityLookup;

    const diagram =
      selectedEntity && isEntityLookup
        ? getEntityDiagram(selectedEntity, entityTests)
        : selectedEntityView && !isEntitySearch
        ? getFinancialEntitiesDiagram(entityMetrics, selectedEntityView, selectedEntityType)
        : null;

    this.setState({ diagram });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedEntity,
      entityMetrics,
      entityTests,
      selectedEntityView,
      selectedEntityType,
      searchItemKey,
      dynamicEntityView,
    } = this.props;

    if (searchItemKey === SearchItemKey.entityLookup) {
      if (
        selectedEntity !== prevProps.selectedEntity ||
        entityTests !== prevProps.entityTests ||
        searchItemKey !== prevProps.searchItemKey
      ) {
        this.setState({ diagram: getEntityDiagram(selectedEntity, entityTests) });
      }
    } else if (searchItemKey === SearchItemKey.metricSearch || searchItemKey === SearchItemKey.entitySearch) {
      if (
        selectedEntityView !== prevProps.selectedEntityView ||
        entityMetrics !== prevProps.entityMetrics ||
        selectedEntityType !== prevProps.selectedEntityType ||
        searchItemKey !== prevProps.searchItemKey
      ) {
        if (searchItemKey === SearchItemKey.entitySearch) {
          this.setState({
            diagram: selectedEntityType
              ? getFinancialEntitiesDiagram(entityMetrics, dynamicEntityView, selectedEntityType)
              : null,
          });
        } else {
          this.setState({
            diagram: getFinancialEntitiesDiagram(entityMetrics, selectedEntityView, selectedEntityType),
          });
        }
      }
    }
  }

  render() {
    const { ariaLabel, loading, notFound, searchItemKey, selectedEntity } = this.props;
    const { diagram } = this.state;

    if (loading) {
      return (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading entity data..."
        />
      );
    }

    if (searchItemKey === SearchItemKey.entityLookup && notFound) {
      return <div className={classNames.message}>No entity found with the specific type and ID.</div>;
    }

    if (
      !diagram ||
      (searchItemKey === SearchItemKey.entityLookup && !selectedEntity) ||
      searchItemKey === SearchItemKey.entitySearch
    ) {
      return null;
    }

    return (
      <div className={`${classNames.container} ${classNames.contentBox}`}>
        <Diagram
          ariaLabel={ariaLabel}
          diagram={diagram}
          classNames={classNames}
          onNodeClick={this.onNodeClick}
          onNodeMoved={this.onNodeMoved}
        />
      </div>
    );
  }

  onNodeClick = (node: INode) => {
    const { searchItemKey, selectedEntityType, loadEntity, setSearchEntityType, searchEntities } = this.props;

    if (searchItemKey === SearchItemKey.entityLookup) {
      loadEntity(node.entityType, node.id, node.version);
    } else if (searchItemKey === SearchItemKey.entitySearch && selectedEntityType !== node.id) {
      setSearchEntityType(node.id);
      searchEntities();
    } else {
      setSearchEntityType(node.id);
    }
  };

  onNodeMoved = (selectedNode: INode) => {
    this.setState({
      diagram: {
        ...this.state.diagram,
        nodes: this.state.diagram.nodes.map((node) => (node.id === selectedNode.id ? selectedNode : node)),
      },
    });
  };
}

const mapStateToProps = (state: IState): IEntityDiagramStateProps => ({
  selectedEntity: state.modules.selected_entity,
  loading:
    state.modules.loading_config_items ||
    state.modules.loading_entity_metrics ||
    state.modules.loading_entity_tests ||
    (state.modules.selected_entity &&
      (state.modules.loading_entity_relationships || state.modules.loading_financial_entities)),
  notFound: state.modules.no_entity_found,
  financialEntityCounts: state.modules.financial_entity_counts,
  entityMetrics: getGraphEntityMetrics(state.modules),
  entityTests: state.modules.entity_tests,
  selectedEntityView:
    state.modules.entity_views &&
    state.modules.entity_views.find((view) => view.id === state.modules.selected_entity_view_id),
  selectedEntityType: state.modules.search_entity_type,
  searchItemKey: state.modules.search_item_key,
  dynamicEntityView:
    state.modules.entity_views && state.modules.entity_views.find((view) => view.id === dynamicEntityViewId),
});

const mapDispatchToProps = {
  loadEntity: actionCreator.loadEntity,
  setSearchEntityType: actionCreator.setSearchEntityType,
  searchEntities: actionCreator.searchEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityDiagram);
